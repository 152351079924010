import React from "react";
import Lottie from "lottie-react";

// assets
import MarsExogene from "../../dnaEvent/dna-event-assets/MarsExogene.png";
import DNA from "../../dnaEvent/dna-event-assets/animations/DNAStrand.json";
import Kit from "../assets/introKit.png";

// css
import "./exogenePageStyling.css";

export default function ExogeneIntro() {
  return (
    <div className="exogene-intro-container">
      <img
        alt=""
        src={MarsExogene}
        style={{
          width: "146px",
          height: "auto",
          position: "absolute",
          top: "70px",
          marginLeft: "26px",
        }}
      />
      <div className="exogene-content-wrapper" style={{ paddingTop: "110px" }}>
        <span className="exogene-title-big">난 정말 탈모일까?</span>
        <Lottie
          animationData={DNA}
          autoPlay={true}
          style={{
            transform: "rotate(-25deg)",
            pointerEvents: "none",
            position: "relative",
            width: "95vw",
            maxWidth: "450px",
            marginTop: "-55px",
          }}
        ></Lottie>
        <div
          className="exogene-title-med"
          style={{
            marginTop: "-25px",
            marginBottom: "25vh",
            position: "relative",
            width: "100%",
            maxWidth: "350px",
          }}
        >
          탈모 유전자 검사 <br /> 홈키트로 집에서 쉽게!{" "}
          <div className="exogene-price">
            <span className="exogene-price-slash">정상가 52,000원</span>
            {"  "}26,000원
            <img
              alt=""
              src={Kit}
              style={{
                position: "absolute",
                width: "83px",
                height: "146px",
                top: 0,
                right: 0,
                pointerEvents:'none'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
