import Lottie from "lottie-react";

import Loader from "../dna-event-assets/animations/DNALoader.json";

export default function DNALoader() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        background: "white",
        top: 0,
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        placeItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Lottie
        animationData={Loader}
        autoPlay={true}
        style={{ width: "200px", height: "auto" }}
      ></Lottie>
      <span style={{ marginBottom: "75px", fontWeight: 400, fontSize: "13px" }}>
        당첨 확인 중이에요
      </span>
    </div>
  );
}
