import { useEffect, useState } from 'react';
import { PRODUCTS } from 'utils/products';
import { calcPrice4 } from 'utils/util';
import { won } from 'utils/util';

function PriceTable(props) {
	const [minoxPrice, setMinoxPrice] = useState();
	const [pillPrice, setPillPrice] = useState();
	const [shampPrice, setShampPrice] = useState();

	const [total, setTotal] = useState();
	const [pillCount, setPillCount] = useState(30);
	const [minoxCount, setMinoxCount] = useState(1);

	useEffect(() => {
		let res = calcPrice4(props.pill, props.minox, props.pillMonth, props.minoxMonth, props.shamp, null, null);
		setPillPrice(res.pillPrice);
		setMinoxPrice(res.minoxPrice);
		setShampPrice(res.shampPrice);

		setTotal(res.total);
		setMinoxCount(res.minoxCount);
		setPillCount(res.pillCount);
	}, [props.pillMonth, props.minoxMonth, props.pill, props.minox, props.shamp])
	return (
		<>
			<table width={"100%"}>
				<tbody>
					<tr className="reservation-heading">
						<td>예상비용</td>
						<td className="right"><div>총 <label className="orange"><b>{won(total)}원</b></label></div></td>
					</tr>
				</tbody>
			</table>

			<table width={"100%"} style={{ marginTop: "14px" }}>
				<tbody>
					{props.pill && props.pill !== "아니요" && (
						<tr>
							<td>{PRODUCTS[props.pill].name} ({props.pillMonth * PRODUCTS[props.pill].base} {PRODUCTS[props.pill].unit})</td>
							<td className="right">{won(pillPrice)}원</td>
						</tr>
					)}
					{props.minox && props.minox !== "아니요" && (
						<tr>
							<td>{PRODUCTS[props.minox].name} ({props.minoxMonth * PRODUCTS[props.minox].base} {PRODUCTS[props.minox].unit})</td>
							<td className="right">{won(minoxPrice)}원</td>
						</tr>
					)}
					{props.shamp && (
						<tr>
							<td>{PRODUCTS[props.shamp].name} ({PRODUCTS[props.shamp].base} {PRODUCTS[props.shamp].unit})</td>
							<td className="right">{won(shampPrice)}원</td>
						</tr>
					)}


					<tr>
						<td>진료비</td>
						<td className="right">10,000원</td>
					</tr>
					<tr>
						<td>배송비</td>
						<td className="right">2,900원</td>
					</tr>

				</tbody>
			</table>
		</>)
}
export default PriceTable;