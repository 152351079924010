import { m } from "framer-motion";
import React from "react";
// reactstrap components
import { NavbarBrand, Navbar, Container } from "reactstrap";

function MarsNavbar() {
  return (
    <>
      <Navbar
        // className="fixed-top bg-white"
        style={{
          height: "48px",
          position: "fixed",
          display: "grid",
          zIndex:'999',
          width: '100%',
          justifyContent:'center'
        }}
      >
        <Container>
          {/* 
          <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
          </NavbarBrand>
          */}
          <div className="" style={{ margin: "0 auto" }}>
            <img
              height="20px"
              alt="..."
              src={require("assets/img/mars_logo_black.png")}
            ></img>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default MarsNavbar;
