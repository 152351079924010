import { buildQueryString } from "@aws-sdk/querystring-builder";
export function formatUrl(request) {
    var port = request.port, query = request.query;
    var protocol = request.protocol, path = request.path, hostname = request.hostname;
    if (protocol && protocol.substr(-1) !== ":") {
        protocol += ":";
    }
    if (port) {
        hostname += ":" + port;
    }
    if (path && path.charAt(0) !== "/") {
        path = "/" + path;
    }
    var queryString = query ? buildQueryString(query) : "";
    if (queryString && queryString[0] !== "?") {
        queryString = "?" + queryString;
    }
    return protocol + "//" + hostname + path + queryString;
}
