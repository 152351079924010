import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

// components
import MarsHeader from "../../components/Navbars/MarsNavbarWithLine";
import QuestionOne from "./QuestionOne";
import QuestionTwo from "./QuestionTwo";
import QuestionThree from "./QuestionThree";

// css import
import "./feedbackStyles.css";

export default function FeedbackMain() {
  const [feedback, setFeedback] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [toggleLast, setToggleLast] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  // data - to be sent as props
  const [goodList, setGoodList] = useState([]);
  const [badList, setBadList] = useState([]);
  const [feedbackText, setFeedbackText] = useState("");
  const [userInformation, setUserInformation] = useState();

  // push to confirmation page on button click
  const history = useHistory();

  // button disable/enable toggle depending on required fields
  useEffect(() => {
    if (feedback !== null) {
      setToggle(true);
    }
    if (toggleLast) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [feedback, toggleLast]);

  // user feedback data submit
  const handleSubmit = () => {
    const data = {
      feedbackStatus: feedback,
      posFeedback: goodList,
      negFeedback: badList,
      additionalText: feedbackText,
    };

    // insert data submission logic below
    // console.log(data);
    history.push("/feedbackConfirm");
    setFeedbackText("");
  };

  return (
    <div className="scrollable">
      <MarsHeader />
      <div className="feedback-main-header">
        <h1>
          오늘의 진료 리뷰 <span style={{ fontWeight: "200" }}>(질문3개)</span>
        </h1>
      </div>
      <div className="feedback-question-container">
        <QuestionOne
          feedback={feedback}
          setFeedback={setFeedback}
          setDisableButton={setDisableButton}
          disableButton={disableButton}
        />
        {toggle ? (
          <QuestionTwo
            feedback={feedback}
            setFeedback={setFeedback}
            toggleLast={toggleLast}
            setToggleLast={setToggleLast}
            goodList={goodList}
            setGoodList={setGoodList}
            badList={badList}
            setBadList={setBadList}
          />
        ) : (
          <></>
        )}
        {toggleLast ? (
          <QuestionThree
            feedbackText={feedbackText}
            setFeedbackText={setFeedbackText}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="feedback-button-container">
        <button
          disabled={disableButton}
          id="feedback-submit-button"
          onClick={handleSubmit}
        >
          등록
        </button>
      </div>
    </div>
  );
}
