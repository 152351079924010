const { GridLoader } = require("react-spinners");

function MarsLoaderGrid(props) {
    return (
        <>
            <div style={{
                position: "fixed",
                top: "-28px",
                left: 0,
                width: "100%",
                height: "100%"
                
            }}>
                <div className="loader-div">
                    <GridLoader color={"#6A43B8"} loading={props.isLoading} size={12} margin={6} />
                </div>
            </div>
        </>
    )
}
export default MarsLoaderGrid;