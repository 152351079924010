// import amplify authenticator ui
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

// core components
import MarsNavbar from "components/Navbars/MarsNavbar.js";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import {
  Alert,
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { getAllChartsWithUserInfo } from "utils/data";
import { getPharmaInfo } from "utils/util";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import { PRODUCTS } from "utils/products";

//images
import JojeReady from "./jojeReady.png";
import JojeIng from "./jojeIng.png";
import JojeDone from "./jojeDone.png";
import DeliDone from "./deliDone.png";
import Profile from "./profile.png";

import * as XLSX from "xlsx";
import * as mutations from "../../graphql/mutations";
import PharmaCard from "./PharmaCard";

import MarsLogoBlack from "../../assets/img/mars_logo_black.png";
import searchIcon from "./Vector.png";

import "./pharmaDataStyles.css";
import "./pharmaStyles.css";
import { getUser } from "utils/data";
import { updateUser } from "utils/data";
import { updateVenusChart } from "utils/data";
import { updateVenusOrderShipment } from "utils/data";
import { updateVenusOrderShipmentDB } from "utils/data";
import { getOrderShipmentsByProcessorID } from "utils/data";

function PharmaPage2() {
  const [iconPills, setIconPills] = useState("1");
  const [patients, setPatients] = useState([]);
  const [pName, setPName] = useState("");
  const [acceptAlert, setAcceptAlert] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [toSendList, setToSendList] = useState([]);
  const [when, setWhen] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [pharma, setPharma] = useState("");

  const [currentStatus, setCurrentStatus] = useState("joje_ready");
  const navRef = useRef();
  const [toggle, setToggle] = useState(true);

  const history = useHistory();
  // const isMobile = navigator.userAgentData.mobile;

  const date = new Date();
  // checkbox data
  const [checkAll, setCheckAll] = useState(false);
  const [displayCheck, setDisplayCheck] = useState(false);
  const [displayCheckIng, setDisplayCheckIng] = useState(false);

  //search
  const [search, setSearch] = useState("");
  const readyRef = useRef();

  // group actions
  const [jojeReadyList, setJojeReadyList] = useState([]);
  const [jojeIngList, setJojeIngList] = useState([]);
  const [jojeDoneList, setJojeDoneList] = useState([]);

  function getP2status(chart) {
    if (chart.processor_status) {
      return chart.processor_status;
    }
    if (chart.status === "paid") {
      return "joje_ready";
    }
    return "";
  }
  function convertVenus2MarsCharts(venusCharts) {
    let convertedCharts = [];
    for (let vc of venusCharts) {
      if (vc.processor !== "pharma") {
        continue;
      }
      let p2status = getP2status(vc);
      let chart = {
        id: vc.id,
        venus: true,
        addr: vc.road_address,
        addr2: vc.detail_address,
        prescription: vc.prescription,
        zip: vc.zip,
        isPaid: "yes",
        p2status: p2status,
        dstatus: "done",
        ignoreStatus: "no",
        kid: vc.kid,
        name: vc.name,
        phone: vc.phone,
        pharma: vc.processor,
        filekey: vc.prescription.s3_key,
        s3_identity: vc.prescription.s3_identity,
        createdAt: vc.createdAt,
        updatedAt: vc.updatedAt,
        yeyak_time: vc.prescription.createdAt,
        delivery_number: vc.delivery_number,
        chart_id: vc.chart_id,
      };
      convertedCharts.push(chart);
    }
    // console.log("converted Charts: ", convertedCharts)
    return convertedCharts;
  }
  async function getData(pharma) {
    setIsLoading(true);

    let filter = {
      pharma: {
        eq: pharma,
      },
    };

    const charts_ = await getAllChartsWithUserInfo("DESC", filter, 500);
    let venusShipments = await getOrderShipmentsByProcessorID(pharma);
    // console.log("oroginal: ", venusShipments);

    let venus_charts = convertVenus2MarsCharts(venusShipments);

    // setPatients([...charts_ , ...venusShipments]);
    setPatients([...charts_, ...venus_charts]);

    // let ps = [...charts];
    setIsLoading(false);
    // set csv data
    // let csv = [["받는 분 이름", "받는 분 우편번호", "받는 분 주소", "받는 분 상세주소", "받는 분 연락처"]];
    let csv = "";

    setCsvData(csv);
  }

  function populateSendList() {
    let rows = [];
    for (let p of patients) {
      if (p.p2status === "joje_done") {
        // csv += `${p.name}|${p.zip}|${p.addr}|${p.addr2}|${p.phone}|5|80|N|||||[27]의료/건강식품|의료/건강식품||||||\n`;
        // populating array of JSON for xlsx
        rows.push({
          받는분성명: p.name,
          받는분우편번호: p.zip,
          "받는분주소(전체,분할)": p.addr,
          "받는분상세주소(분할)": p.addr2,
          받는분전화번호: p.phone,
          배송메시지1: "",
          품목명: "",
          기타1: p.id,
        });
      }
    }
    setToSendList(rows);
    return rows;
  }

  // async function getDataOld() {
  //   let charts = await getAllChartsWithUserInfo("DESC", null, 450);
  //   setPatients(charts);

  //   // set csv data
  //   // let csv = [["받는 분 이름", "받는 분 우편번호", "받는 분 주소", "받는 분 상세주소", "받는 분 연락처"]];
  //   let csv = "";
  //   for (let p of charts) {
  //     if (p.pstatus === "medi_prep") {
  //       csv += `${p.name}|${p.zip}|${p.addr}|${p.addr2}|${p.phone}|5|80|N|||||[27]의료/건강식품|의료/건강식품||||||\n`;
  //       // 김선민|06006|addr|addr2|01087018796|5|80|N|||||[26]미용/화장품|화장품||||||
  //     }
  //   }
  //   setCsvData(csv);
  // }

  useEffect(() => {
    // console.log(patients)
  }, [patients]);

  useEffect(() => {
    const asyncWrapper = async () => {
      // fetch data
      let user = await Auth.currentUserInfo();
      if (["pharma1", "totheworld", "pharma77"].indexOf(user.username) === -1) {
        alert("약사로 로그인 해주세요!");
        let so = await Auth.signOut();
        return;
      }

      let pharma = user.username;
      setPharma(pharma);

      getData(user.username);
    };
    asyncWrapper();

    // subscription for onUpdateMars
    //
    const onupdateSubscription = API.graphql({
      query: subscriptions.onUpdateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).subscribe({
      next: ({ provider, value }) => {
        // console.log("onupdate mars!!: ", provider, value);
        let mars = value.data.onUpdateMars;
        if (mars && mars.p2status === "joje_ready") {
          updateLocalPatient(mars);
        }
      },
      error: (error) => console.warn(error),
    });
  }, []);

  const handleUpdate = (type, body) => {
    if (type === "when") {
      setWhen(body);
    }
  };

  const updateLocalPatientVenus = (patient) => {
    // console.log("update local patient venus:", patient);

    setPatients((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...obj,
            p2status: patient.processor_status,
            delivery_number: patient.delivery_number,
          };
        }
        return obj;
      })
    );
  };

  const updateLocalPatient = (patient) => {
    // console.log("update local patient:", patient);

    setPatients((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...obj,
            p2status: patient.p2status,
            delivery_number: patient.delivery_number,
          };
        }
        return obj;
      })
    );
  };

  const jojeStart = async (chart) => {
    // const timeStamp = new Date().toISOString();
    if (chart.venus) {
      let shipmentInfo = {
        id: chart.id,
        processor_status: "joje_ing",
        status: "processing",
      };
      const updatedShipment = await updateVenusOrderShipmentDB(shipmentInfo);
      // console.log("updatedAhipment: ", updatedShipment);
      if (updatedShipment) {
        updateLocalPatientVenus(updatedShipment);
      }
    } else {
      const patientDetails = {
        id: chart.id,
        p2status: "joje_ing",
        ignoreStatus: "yes",
        r: "n", //refresh,
        // medi_done_time: timeStamp
      };

      const updatedPatient = await API.graphql({
        query: mutations.updateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });

      if (updatedPatient && updatedPatient.data) {
        updateLocalPatient(updatedPatient.data.updateMars);
      }
    }
  };
  const jojeDone = async (chart) => {
    if (chart.venus) {
      let shipmentInfo = {
        id: chart.id,
        processor_status: "joje_done",
        status: "processing",
      };
      const updatedShipment = await updateVenusOrderShipmentDB(shipmentInfo);
      if (updatedShipment) {
        updateLocalPatientVenus(updatedShipment);
      }
    } else {
      const timeStamp = new Date().toISOString();
      const patientDetails = {
        id: chart.id,
        p2status: "joje_done",
        ignoreStatus: "yes",
        r: "n", //refresh,
        medi_done_time: timeStamp,
      };

      const updatedPatient = await API.graphql({
        query: mutations.updateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });

      if (updatedPatient && updatedPatient.data) {
        updateLocalPatient(updatedPatient.data.updateMars);
      }
    }
  };

  const changeToReady = async (chart) => {
    if (chart.venus) {
      let shipmentInfo = {
        id: chart.id,
        processor_status: "joje_ready",
        status: "processing",
      };
      const updatedShipment = await updateVenusOrderShipmentDB(shipmentInfo);
      if (updatedShipment) {
        updateLocalPatientVenus(updatedShipment);
      }
    } else {
      const patientDetails = {
        id: chart.id,
        p2status: "joje_ready",
        ignoreStatus: "yes",
        r: "n", //refresh,
      };

      const updatedPatient = await API.graphql({
        query: mutations.updateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });

      if (updatedPatient && updatedPatient.data) {
        let item = updatedPatient.data.updateMars;
        updateLocalPatient(item);
      }
    }
  };
  const handleDeliveryReady = async (patient, deliveryNumber) => {
    if (patient.venus) {
      let shipmentInfo = {
        id: patient.id,
        processor_status: "deli_done",
        status: "delivery_start",
        delivery_number: deliveryNumber,
      };
      const updatedShipment = await updateVenusOrderShipmentDB(shipmentInfo);
      if (updatedShipment) {
        updateLocalPatientVenus(updatedShipment);
      }

      if (patient.chart_id) {
        // update chart status to deli_done
        let chartInfo = {
          id: patient.chart_id,
          user_status: "deli_done",
        };
        let updatedChart = await updateVenusChart(chartInfo);
        // console.log("updated Chart: ", updatedChart);
      }
    } else {
      const patientDetails = {
        id: patient.id,
        p2status: "deli_done",
        pstatus: "deli_done",
        status: "deli_done",
        ignoreStatus: "no",
        delivery_number: deliveryNumber,
        r: "n", //refresh
      };

      try {
        let updatedPatient = await API.graphql({
          query: mutations.updateMars,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: { input: patientDetails },
        });
        if (updatedPatient && updatedPatient.data) {
          updateLocalPatient(updatedPatient.data.updateMars);

          // update chart inside user
          //

          let kid = patient.id.split("#")[1];
          let userID = `user#${kid}`;
          // console.log("kid:", kid);
          let user = await getUser(userID);
          if (user && user.charts) {
            let userCharts = JSON.parse(user.charts);
            // console.log("userCharts:", userCharts);
            let chart = userCharts.find((chart) => chart.id === patient.id);
            chart.stat = "deli_done";
            let userInfo = {
              id: userID,
              charts: JSON.stringify(userCharts),
            };
            const uuser = await updateUser(userInfo);
            console.log("updated user: ", uuser);
          }
        }
      } catch (error) {
        alert("등기번호 입력중 에러발생. 다시 시도해 주세요: " + error);
      }
    }
  };

  const handleMedicationReady = async (pid, pname) => {
    const timeStamp = new Date().toISOString();

    const patientDetails = {
      id: pid,
      pstatus: "deli_prep",
      ignoreStatus: "no",
      r: "n", //refresh,
      medi_done_time: timeStamp,
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData(pharma);
  };

  /* 결제확인 완료 택배예약 완료 버튼 핸들 */
  const handleVerifyPayment = async (pid, pname) => {
    // console.log("handle verify payment called!");
    // graphql update patient dstatus
    //
    const patientDetails = {
      id: pid,
      pstatus: "medi_prep",
      ignoreStatus: "no",
      r: "n", //refresh
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData(pharma);
  };

  const calcPatients = (type) => {
    let counter = 0;
    for (let p of patients) {
      // console.log(p);
      if (p.p2status === type) counter++;
    }
    return counter;
  };

  const handleFileInput = async (e) => {
    // console.log("event:", e);
    e.preventDefault();

    try {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      /* data is an ArrayBuffer */
      const workbook = XLSX.read(data);

      var sheet_name_list = workbook.SheetNames;
      let dnList = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[0]]
      );
      // console.log(dnList);

      if (!dnList || dnList.length < 1) {
        alert("올바른 파일을 업로드해 주세요.");
        return;
      }

      if (window.confirm(`총 ${dnList.length}명 배송완료 처리 하시겠습니까?`)) {
        for (let p of dnList) {
          let id = p["기타1"];
          let name = p["받는분"];
          let dn = p["운송장번호"];

          if (!id || !dn) {
            alert(`올바른 포맷이 아닙니다. 다시업로드해 주세요.`);
            return;
          }

          // todo check if patient exist + status is correct.
          let patient = patients.find((o) => o.id === id);
          // console.log("patient found:", patient);
          if (patient && patient.p2status === "joje_done") {
            handleDeliveryReady(patient, "CJ대한통운 " + dn);
          } else {
            alert(
              `${name}님의 고객정보가 일치하지 않습니다. 업로드한 파일을 다시 확인해 주세요.`
            );
          }
        }
        alert(`총 ${dnList.length}건 '배송완료' 상태로 변경 되었습니다.`);
      }
    } catch (error) {
      alert("파일업로드중 에러가 발생하였습니다. 올바른 파일을 선택해 주세요!");
      console.log(error);
    }
  };

  // Nav Toggle setToggle(!toggle)
  const handleNavButton = () => {
    if (toggle === false) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  // sets Header Title based on status
  const getHeaderTitle = (status) => {
    if (status === "joje_ready") {
      return "조제대기";
    } else if (status === "joje_ing") {
      return "조제중";
    } else if (status === "joje_done") {
      return "배송신청";
    } else if (status === "deli_done") {
      return "배송완료";
    }
  };

  // handle outside click / checks if touch event happens outside modal component
  useEffect(() => {
    function clickOutside(event) {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        event.pointerType === "touch"
      ) {
        setToggle(false);
      }
    }
    document.addEventListener("pointerdown", clickOutside);
    return () => {
      document.removeEventListener("pointerdown", clickOutside);
    };
  }, [navRef]);

  // Needs thorough testing / displays cards that match search input
  const checkSearch = (patient) => {
    try {
      if (
        // 'includes.()'
        JSON.stringify(patient.name).match(search) ||
        JSON.stringify(patient.phone).match(search) ||
        parsePills(patient).includes(search) ||
        parseMinox(patient).includes(search)
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  // starting string check
  const calcStartingString = (status) => {
    switch (status) {
      case "joje_ready":
        return "오늘";

      case "joje_ing":
        return "현재";

      case "joje_done":
        return "현재";

      case "deli_done":
        return " ";
    }
  };

  // title check
  const calcTitle = (status) => {
    switch (status) {
      case "joje_ready":
        return "의 조제건이 있습니다.";

      case "joje_ing":
        return "를 조제중 입니다.";

      case "joje_done":
        return "의 배송신청건이 있습니다.";

      case "deli_done":
        return "의 배송신청건이 완료 되었습니다.";
    }
  };

  const imgStyles = {
    height: "24px",
    width: "24px",
    marginRight: "10px",
  };

  // separate check mark for display & checkall state
  // if all ready state cards are selected, set allCheck(true)
  useEffect(() => {
    let readyCount = calcPatients("joje_ready");
    if (readyCount === jojeReadyList.length) {
      setDisplayCheck(true);
    } else {
      setDisplayCheck(false);
    }
  }, [jojeReadyList, displayCheck]);

  // separate check mark for display & checkall state
  // if all ing/ongoing state cards are selected, set allCheck(true)
  useEffect(() => {
    let ingCount = calcPatients("joje_ing");
    if (ingCount === jojeIngList.length) {
      setDisplayCheckIng(true);
    } else {
      setDisplayCheckIng(false);
    }
  }, [jojeIngList, displayCheckIng]);

  const parsePills = (patient) => {
    var parsed = "";
    if (patient.pill === "아니요" || undefined || null) {
      parsed += "";
    } else if (PRODUCTS[patient.pill].name !== "없음") {
      let temp =
        PRODUCTS[patient.pill].name +
        " " +
        PRODUCTS[patient.pill].base * patient.month +
        PRODUCTS[patient.pill].unit;
      return temp;
    }

    return parsed;
  };

  const parseMinox = (patient) => {
    var parsed = "";
    if (patient.minox === "아니요" || undefined || null) {
      parsed += "";
    } else if (PRODUCTS[patient.minox].name !== "없음") {
      let temp =
        PRODUCTS[patient.minox].name +
        " " +
        patient.minoxMonth * PRODUCTS[patient.minox].base +
        PRODUCTS[patient.minox].unit;
      return temp;
    }

    return parsed;
  };

  const calcDay = () => {
    var day = date.getDay();
    switch (day) {
      case 0:
        return "일요일";
      case 1:
        return "월요일";
      case 2:
        return "화요일";
      case 3:
        return "수요일";
      case 4:
        return "목요일";
      case 5:
        return "금요일";
      case 6:
        return "토요일";
      default:
        return " ";
    }
  };

  return (
    <>
      {isLoading && <MarsLoaderBasic isLoading={isLoading} />}
      <div
        className="scrollable"
        id="pharma-page-scrollable-wrapper"
        // style={{ paddingTop: "80px" }}
      >
        <Alert
          color=""
          style={{ backgroundColor: "#444444", marginTop: "-16px" }}
          isOpen={acceptAlert}
        >
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons ui-2_like"></i>
            </div>
            <b>{pName}님</b> 접수확인 완료. <b>전화 진료중</b> 목록에서 확인
            해주세요.
            <button
              type="button"
              className="close"
              onClick={() => setAcceptAlert(false)}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </Container>
        </Alert>

        {/* NAVIGATION NAVIGATION NAVIGATION */}

        <div
          ref={navRef}
          className={
            toggle
              ? "pharma-page-navigation-bar"
              : "pharma-page-navigation-bar-closed"
          }
        >
          <img
            src={MarsLogoBlack}
            style={{ width: "89px", height: "auto", marginTop: "-90px" }}
          />
          <div style={{ paddingTop: "150px" }}>
            <Nav role="tablist" tabs style={{border:'none'}}>
              <NavItem>
                <NavLink
                  id="pharma-nav-link"
                  className={iconPills === "1" ? "pharma-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("1");
                    setCurrentStatus("joje_ready");
                    setCheckAll(false);
                    setSearch("");
                  }}
                >
                  <span id="pharma-navbar-tab">
                    <img style={imgStyles} src={JojeReady} />
                    조제대기
                  </span>
                  {calcPatients("joje_ready") > 0 ? (
                    <>
                      <mark id="pharma-notification-mark">
                        {calcPatients("joje_ready")}
                      </mark>
                    </>
                  ) : (
                    // <span style={{ position: "absolute", marginTop: "2px" }}>
                    //   {`[${calcPatients("joje_ready")}]`}
                    // </span>
                    <></>
                  )}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="pharma-nav-link"
                  className={iconPills === "2" ? "pharma-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("2");
                    setCurrentStatus("joje_ing");
                    setCheckAll(false);
                    setSearch("");
                  }}
                >
                  <span id="pharma-navbar-tab">
                    <img style={imgStyles} src={JojeIng} />
                    조제중
                    <span
                      style={{ position: "absolute", marginTop: "2px" }}
                    >{`[${calcPatients("joje_ing")}]`}</span>
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="pharma-nav-link"
                  className={iconPills === "3" ? "pharma-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("3");
                    setCurrentStatus("joje_done");
                    setCheckAll(false);
                    setSearch("");
                  }}
                >
                  <span id="pharma-navbar-tab">
                    <img style={imgStyles} src={JojeDone} />
                    배송신청{" "}
                    <span
                      style={{ position: "absolute", marginTop: "2px" }}
                    >{`[${calcPatients("joje_done")}]`}</span>
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="pharma-nav-link"
                  className={iconPills === "4" ? "pharma-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("4");
                    setCurrentStatus("deli_done");
                    setCheckAll(false);
                    setSearch("");
                  }}
                >
                  <span id="pharma-navbar-tab">
                    <img
                      style={{
                        height: "16px",
                        width: "24px",
                        marginRight: "10px",
                      }}
                      src={DeliDone}
                    />
                    배송완료
                    <span
                      style={{ position: "absolute", marginTop: "2px" }}
                    >{`[${calcPatients("deli_done")}]`}</span>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <img
              alt="..."
              className="rounded-circle"
              src={Profile}
              height="64px"
              style={{
                position: "absolute",
                bottom: "100px",
                left: 50,
              }}
              id="pharma-profile-pic"
            ></img>
            <div id="pharma-navbar-texts">{getPharmaInfo(pharma).office}</div>
            {/* <div id="pharma-navbar-logout">
              <AmplifySignOut />
            </div> */}
          </div>
        </div>

        {/* TOGGLE TOGGLE */}
        <button
          id="pharma-nav-bar-toggle"
          onClick={handleNavButton}
          style={{ marginLeft: toggle ? "175px" : 0 }}
        >
          <i className={toggle ? "arrow in" : "arrow out"}></i>
        </button>

        {/* CARD / PATIENT CARD CONTAINER */}
        {/* delete margin later */}
        <TabContent
          id="pharma-page-tab-content"
          activeTab={"iconPills" + iconPills}
          // style={{ marginLeft: toggle ? "140px" : "0px" }}
        >
          {/* HEADER HEADER HEADER */}
          {/* HEADER HEADER HEADER */}
          {/* HEADER HEADER HEADER */}
          <div className="pharma-page-header">
            <div id="header-text-container">
              <p>
                {date.getUTCFullYear()}. {date.getUTCMonth() + 1}.{" "}
                {date.getUTCDate()}. {calcDay()}
                <br />
                <span>
                  {calcStartingString(currentStatus)}{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {calcPatients(currentStatus).toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                    })}
                    개
                  </span>
                  {calcTitle(currentStatus)}
                </span>
              </p>
              <div className="pharma-tab-search">
                <p id="header-text-style">{getHeaderTitle(currentStatus)}</p>
                <div>
                  <img
                    src={searchIcon}
                    style={{
                      width: "15px",
                      position: "absolute",
                      marginTop: "8px",
                      marginLeft: "8px",
                    }}
                    alt="search-icon-pharma"
                  />
                  <input
                    type="text"
                    value={search}
                    placeholder="검색"
                    onChange={(e) => setSearch(e.target.value)}
                  ></input>
                </div>
              </div>
              <div id="pharma-joje-button-container">
                {currentStatus === "joje_ready" ? (
                  <Button
                    onClick={() => {
                      // let count = calcPatients("joje_ready");
                      let count = jojeReadyList.length;
                      if (count < 1) {
                        alert("아무도 없습니다..");
                        return;
                      }
                      if (
                        window.confirm(
                          `총 ${jojeReadyList.length}건 모두 조제시작 하시겠어요?`
                        )
                      ) {
                        // for (let p of patients) {
                        //   if (p.p2status === "joje_ready") {
                        //     jojeStart(p.id);
                        //   }
                        // }
                        for (let p of jojeReadyList) {
                          jojeStart(p);
                          setJojeReadyList([]);
                        }
                        alert(
                          `총 ${jojeReadyList.length}건 모두 '조제중' 상태로 변경 되었습니다.`
                        );
                      }
                    }}
                  >
                    {/* {`전체 조제 시작 [${calcPatients("joje_ready")}명]`} */}
                    {`조제 시작 (${jojeReadyList.length.toLocaleString(
                      "en-US",
                      {
                        minimumIntegerDigits: 2,
                      }
                    )}명)`}
                  </Button>
                ) : (
                  <></>
                )}
                {currentStatus === "joje_ing" ? (
                  <Button
                    onClick={() => {
                      // let count = calcPatients("joje_ing");
                      let count = jojeIngList.length;
                      if (count < 1) {
                        alert("아무도 없습니다..");
                        return;
                      }
                      if (
                        window.confirm(
                          `총 ${jojeIngList.length}건 모두 조제완료 하시겠어요?\n(처방전을 모두 다운로드 받으셨는지 확인해 주세요)`
                        )
                      ) {
                        // for (let p of patients) {
                        //   if (p.p2status === "joje_ing") {
                        //     jojeDone(p.id);
                        //   }
                        // }
                        for (let p of jojeIngList) {
                          jojeDone(p);
                          setJojeIngList([]);
                        }
                        alert(
                          `총 ${jojeIngList.length}건 모두 '배송신청' 상태로 변경 되었습니다.`
                        );
                      }
                    }}
                  >
                    {`조제완료 (${jojeIngList.length.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                    })}명)`}
                  </Button>
                ) : (
                  <></>
                )}
                {currentStatus === "joje_done" ? (
                  <div
                    id="joje-done-button-group"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2,1fr)",
                      placeItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        let count = calcPatients("joje_done");
                        if (count < 1) {
                          alert("아무도 없습니다..");
                          return;
                        }
                        if (
                          window.confirm(
                            `총 ${count}건의 배송정보 파일을 다운로드 받으시겠습니까?`
                          )
                        ) {
                          let list = populateSendList();

                          const worksheet = XLSX.utils.json_to_sheet(list);
                          const workbook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(
                            workbook,
                            worksheet,
                            "sheet1"
                          );
                          XLSX.writeFile(
                            workbook,
                            `MARS_택배예약CJ_${new Date().toLocaleDateString()}.xlsx`,
                            { compression: true }
                          );
                        }
                      }}
                    >
                      {/* change later */}
                      {`배송파일 다운로드`.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                    </Button>
                    <label id="pharma-done-button" htmlFor="load-xlsx">
                      <div>운송장번호 업로드</div>
                    </label>
                    <input
                      id="load-xlsx"
                      hidden
                      type="file"
                      accept="*"
                      onChange={handleFileInput}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* DATA TABLE DATA TABLE DATA TABLE */}
          <TabPane id="pharma-tab-pane" tabId="iconPills1">
            <div className="pharma-header-table-head">
              {currentStatus === "joje_ready" && (
                <label id="pharma-selection-checkbox">
                  {/* changed onclick to onchange */}
                  <input
                    type="checkbox"
                    value={checkAll}
                    id="checkall"
                    name="checkAll"
                    checked={displayCheck}
                    onChange={() => {
                      {
                        setCheckAll(!checkAll);
                        setDisplayCheck(!displayCheck);
                      }
                    }}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              )}
              <p id="pharma-header-text">이름 (연락처)</p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                조제요청 일자
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                처방내용
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "280px" }}>
                {currentStatus === "deli_done" ? "운송장 번호" : ""}
              </p>
            </div>
            {patients.map((p, key) => {
              if (p.p2status === "joje_ready") {
                if (checkSearch(p)) {
                  return (
                    <div key={key} ref={readyRef}>
                      <PharmaCard
                        key={p.id}
                        patient={p}
                        onVerifyPayment={handleVerifyPayment}
                        onUpdate={handleUpdate}
                        jojeStart={jojeStart}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        jojeReadyList={jojeReadyList}
                        setJojeReadyList={setJojeReadyList}
                        checkSize={jojeReadyList.length}
                        navToggle={toggle}
                      />
                    </div>
                  );
                }
              }
            })}
          </TabPane>

          {/* 조제중.. */}
          <TabPane id="pharma-tab-pane" tabId="iconPills2">
            <div className="pharma-header-table-head">
              {currentStatus === "joje_ing" && (
                <label id="pharma-selection-checkbox">
                  {/* changed onclick to onchange */}
                  <input
                    type="checkbox"
                    value={checkAll}
                    id="checkall"
                    name="checkAll"
                    checked={displayCheckIng}
                    onChange={() => {
                      {
                        setCheckAll(!checkAll);
                        setDisplayCheckIng(!displayCheckIng);
                      }
                    }}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              )}
              <p id="pharma-header-text">이름 (연락처)</p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                조제요청 일자
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                처방내용
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "280px" }}>
                {currentStatus === "deli_done" ? "운송장 번호" : ""}
              </p>
            </div>
            {patients.map((p) => {
              if (p.p2status === "joje_ing") {
                if (checkSearch(p)) {
                  return (
                    <PharmaCard
                      key={p.id}
                      patient={p}
                      onMedicationReady={handleMedicationReady}
                      changeToReady={changeToReady}
                      jojeDone={jojeDone}
                      checkAll={checkAll}
                      setCheckAll={setCheckAll}
                      jojeIngList={jojeIngList}
                      setJojeIngList={setJojeIngList}
                      navToggle={toggle}
                    />
                  );
                }
              }
            })}
          </TabPane>

          {/* 조제완료 배송 준비 */}
          <TabPane id="pharma-tab-pane" tabId="iconPills3">
            <div className="pharma-header-table-head">
              <p id="pharma-header-text">이름 (연락처)</p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                조제요청 일자
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                처방내용
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "280px" }}>
                {currentStatus === "deli_done" ? "운송장 번호" : ""}
              </p>
            </div>
            {patients.map((p) => {
              if (p.p2status === "joje_done") {
                if (checkSearch(p)) {
                  return (
                    <PharmaCard
                      key={p.id}
                      patient={p}
                      onDeliveryReady={handleDeliveryReady}
                      jojeStart={jojeStart}
                      jojeDone={jojeDone}
                      jojeDoneList={jojeDoneList}
                      setJojeDoneList={setJojeDoneList}
                      navToggle={toggle}
                    />
                  );
                }
              }
            })}
          </TabPane>

          {/* DELI DONE */}
          <TabPane id="pharma-tab-pane" tabId="iconPills4">
            <div className="pharma-header-table-head">
              <p id="pharma-header-text">이름 (연락처)</p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                조제요청 일자
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "40px" }}>
                처방내용
              </p>
              <p id="pharma-header-text" style={{ paddingLeft: "280px" }}>
                {currentStatus === "deli_done" ? "운송장 번호" : ""}
              </p>
            </div>
            <div className="justify-content-center">
              <div md="10" xs="12">
                {patients.map((p) => {
                  if (p.p2status === "deli_done") {
                    if (checkSearch(p)) {
                      return (
                        <PharmaCard
                          key={p.id}
                          patient={p}
                          onDeliveryReady={handleDeliveryReady}
                          jojeDone={jojeDone}
                          navToggle={toggle}
                        />
                      );
                    }
                  }
                })}
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
      {/* <DarkFooter /> */}
    </>
  );
}
export default withAuthenticator(PharmaPage2);
