import { EventStreamMarshaller as EventMarshaller } from "@aws-sdk/eventstream-marshaller";
import { EventStreamMarshaller as UniversalEventStreamMarshaller } from "@aws-sdk/eventstream-serde-universal";
import { iterableToReadableStream, readableStreamtoIterable } from "./utils";
/**
 * Utility class used to serialize and deserialize event streams in
 * browsers and ReactNative.
 *
 * In browsers where ReadableStream API is available:
 * * deserialize from ReadableStream to an async iterable of output structure
 * * serialize from async iterable of input structure to ReadableStream
 * In ReactNative where only async iterable API is available:
 * * deserialize from async iterable of binaries to async iterable of output structure
 * * serialize from async iterable of input structure to async iterable of binaries
 *
 * We use ReadableStream API in browsers because of the consistency with other
 * streaming operations, where ReadableStream API is used to denote streaming data.
 * Whereas in ReactNative, ReadableStream API is not available, we use async iterable
 * for streaming data although it has lower throughput.
 */
var EventStreamMarshaller = /** @class */ (function () {
    function EventStreamMarshaller(_a) {
        var utf8Encoder = _a.utf8Encoder, utf8Decoder = _a.utf8Decoder;
        this.eventMarshaller = new EventMarshaller(utf8Encoder, utf8Decoder);
        this.universalMarshaller = new UniversalEventStreamMarshaller({
            utf8Decoder: utf8Decoder,
            utf8Encoder: utf8Encoder,
        });
    }
    EventStreamMarshaller.prototype.deserialize = function (body, deserializer) {
        var bodyIterable = isReadableStream(body) ? readableStreamtoIterable(body) : body;
        return this.universalMarshaller.deserialize(bodyIterable, deserializer);
    };
    /**
     * Generate a stream that serialize events into stream of binary chunks;
     *
     * Caveat is that streaming request payload doesn't work on browser with native
     * xhr or fetch handler currently because they don't support upload streaming.
     * reference:
     * * https://bugs.chromium.org/p/chromium/issues/detail?id=688906
     * * https://bugzilla.mozilla.org/show_bug.cgi?id=1387483
     *
     */
    EventStreamMarshaller.prototype.serialize = function (input, serializer) {
        var serialziedIterable = this.universalMarshaller.serialize(input, serializer);
        return typeof ReadableStream === "function" ? iterableToReadableStream(serialziedIterable) : serialziedIterable;
    };
    return EventStreamMarshaller;
}());
export { EventStreamMarshaller };
var isReadableStream = function (body) {
    return typeof ReadableStream === "function" && body instanceof ReadableStream;
};
