import Q from "../../assets/exogeneQ.png";
import timeline from "./faq_assets/timeline.png";

// css
import "./faqPagesStyling.css";

export default function Q3() {
  return (
    <div className="exogene-question-slide">
      <span id="exogene-q">
        <img src={Q} alt="question-placeholder" />
        <span className="exogene-question-head">
          유전자 검사는 얼마나 걸리나요?{" "}
        </span>
      </span>
      <div className="exogene-faq-wrapper">
        <div className="exogene-faq-question">
          <span className="exogene-question-detail">
            상품 주문 후 검사 결과지를 받아보시는데는 평균
            <span style={{ fontWeight: 600 }}>12일 정도의 시간</span>이
            소요됩니다.
          </span>
          <div style={{ marginTop: "70px" }}>
            <img
              src={timeline}
              alt="exogene-timeline"
              style={{ width: "329px", height: "auto" }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
