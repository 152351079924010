import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
// import $ from "jquery";

import FormAnimation from "../status-assets/StatusOne.json";
import BackupAnimation from "../status-assets/StatusTwoNew.json";
import CardAnimation from "../status-assets/StatusThree.json";
import TruckAnimation from "../status-assets/StatusFour.json";

// css
import "./statusComponentsStyle.css";

const stepContainerStyle = {
  display: "flex",
  flexWrap: "no-wrap",
  gap: "47px",
  justifyContent: "center",
  position: "relative",
  zIndex: "1",
  paddingTop: "15px",
  width: "100%",
};

const stepStyle = {
  border: "0.3px solid black",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  display: "flex",
  placeItems: "center",
  color: "white",
  overflow: "hidden",
};

/**
 *
 * @param {currentStatus/Step, user} props
 * @returns
 */
export default function StatusStatusBar({ props }) {
  const currentStep = props.step;
  const [userName, setUserName] = useState(props.user.name);
  const [statusTitle, setStatusTitle] = useState();
  const [statusBody, setStatusBody] = useState();

  // step references
  const stepOne = useRef();
  const stepTwo = useRef();
  const stepThree = useRef();
  const stepFour = useRef();

  // lottie references
  // const lottieOne = useRef();
  // const lottieTwo = useRef();
  // const lottieThree = useRef();
  // const lottieFour = useRef();

  //band-aid
  const [loopOne, setLoopOne] = useState(false);
  const [loopTwo, setLoopTwo] = useState(false);
  const [loopThree, setLoopThree] = useState(false);
  const [loopFour, setLoopFour] = useState(false);

  useEffect(() => {
    whiteOutAll();
    switch (currentStep) {
      case 1:
        setStatusTitle(userName + "님, 접수가 완료되었어요");
        setStatusBody("희망진료시간 30분 전까지 예약변경 및 취소가 가능해요.");
        setLoopOne(true);
        break;
      case 2:
        setStatusTitle(userName + "님, 처방이 완료 되었어요");
        setStatusBody(
          "통화진료 후 처방이 확정 되었습니다. 확인 후 결제해 주세요."
        );
        setLoopTwo(true);
        break;
      case 3:
        setStatusTitle("약 조제 및 배송 준비 중 입니다");
        setStatusBody(
          "결제가 완료되었어요! 약 준비 중입니다. 배송이 시작되면 연락 드릴게요."
        );
        setLoopThree(true);
        break;
      case 4:
        setStatusTitle(userName + "님, 약 배송 중이에요");
        setStatusBody(
          "약 배송이 시작 되었어요. CJ대한통운 택배로 안전하게 배송해 드릴게요. 샴푸 구매시 약과 별도로 배송됩니다."
        );
        setLoopFour(true);
        break;
      default:
        setStatusTitle("Could not retrieve user data");
        setStatusBody("Could not retrieve user information");
    }
  }, []);

  const whiteOutAll = () => {
    switch (currentStep) {
      case 1:
        stepOne.current.style.backgroundColor = "#6A43B8";
        stepTwo.current.style.backgroundColor = "black";
        stepTwo.current.style.filter = "brightness(30%) invert(100%)";
        stepTwo.current.style.border = ".5px solid white";
        stepThree.current.style.background = "black";
        stepThree.current.style.filter = "brightness(30%) invert(100%)";
        stepThree.current.style.border = ".5px solid white";
        stepFour.current.style.background = "black";
        stepFour.current.style.filter = "brightness(30%) invert(100%)";
        stepFour.current.style.border = ".5px solid white";
        break;

      case 2:
        stepOne.current.style.backgroundColor = "black";
        stepOne.current.style.filter = "brightness(30%) invert(100%)";
        stepOne.current.style.border = ".5px solid white";
        stepTwo.current.style.backgroundColor = "#6A43B8";
        stepThree.current.style.background = "black";
        stepThree.current.style.filter = "brightness(30%) invert(100%)";
        stepThree.current.style.border = ".5px solid white";
        stepFour.current.style.background = "black";
        stepFour.current.style.filter = "brightness(30%) invert(100%)";
        stepFour.current.style.border = ".5px solid white";
        break;

      case 3:
        stepOne.current.style.backgroundColor = "black";
        stepOne.current.style.filter = "brightness(30%) invert(100%)";
        stepOne.current.style.border = ".5px solid white";
        stepTwo.current.style.backgroundColor = "black";
        stepTwo.current.style.filter = "brightness(30%) invert(100%)";
        stepTwo.current.style.border = ".5px solid white";
        stepThree.current.style.background = "#6A43B8";
        stepFour.current.style.background = "black";
        stepFour.current.style.filter = "brightness(30%) invert(100%)";
        stepFour.current.style.border = ".5px solid white";
        break;

      case 4:
        stepOne.current.style.backgroundColor = "black";
        stepOne.current.style.filter = "brightness(30%) invert(100%)";
        stepOne.current.style.border = ".5px solid white";
        stepTwo.current.style.backgroundColor = "black";
        stepTwo.current.style.filter = "brightness(30%) invert(100%)";
        stepTwo.current.style.border = ".5px solid white";
        stepThree.current.style.background = "black";
        stepThree.current.style.filter = "brightness(30%) invert(100%)";
        stepThree.current.style.border = ".5px solid white";
        stepFour.current.style.background = "#6A43B8";
        break;
    }
  };

  const fixedWidth = {
    height: "80px",
    width: "auto",
  };

  return (
    <div className="status-bar-container">
      <h1 id="status-header-title">{statusTitle}</h1>
      <p id="status-header-body">{statusBody}</p>
      <div
        id="div1"
        style={{
          width: "300px",
          borderTop: "1.2px dashed gray",
          position: "absolute",
          zIndex: "0",
          // margin: "23px",
          left: "50%",
          transform: "translate(-50%, 0)",
          bottom: "33%",
        }}
      ></div>
      <div style={stepContainerStyle} className="status-icon-container">
        <div className="status-wrapper">
          <section ref={stepOne} style={stepStyle} id="step-one">
            <Lottie
              animationData={FormAnimation}
              id="form-animation"
              loop={loopOne}
            ></Lottie>
          </section>
          <p id="status-header-category">접수완료</p>
        </div>
        <div>
          <section ref={stepTwo} style={stepStyle} id="step-two">
            <Lottie
              animationData={BackupAnimation}
              style={{
                width: "35px",
                height: "auto",
                transform: "rotate(25deg)",
                marginLeft: "7px",
              }}
              loop={loopTwo}
            ></Lottie>
          </section>
          <p id="status-header-category">처방완료</p>
        </div>
        <div>
          <section ref={stepThree} style={stepStyle} id="step-three">
            <Lottie
              animationData={CardAnimation}
              style={{ height: "auto", width: "35px", marginLeft: "7px" }}
              loop={loopThree}
            ></Lottie>
          </section>
          <p id="status-header-category">결제완료</p>
        </div>
        <div>
          <section ref={stepFour} style={stepStyle} id="step-four">
            <Lottie
              animationData={TruckAnimation}
              id="truck-animation"
              style={{ height: "auto", width: "200px" }}
              loop={loopFour}
            ></Lottie>
          </section>
          <p id="status-header-category">배송중</p>
        </div>
      </div>
    </div>
  );
}
