import React, { useState } from "react";
import personal from "./PersonalInfoPolicy.png";
import "./agreementStyling.css";

function PersonalInfoPolicy() {
  const [displayYear, setDisplayYear] = useState("2023");

  const handleYearClick = (year) => {
    setDisplayYear(year);
  };

  return (
    <>
      <div
        style={{
          height: "60vh",
          overflow: "scroll",
          lineHeight: "1.1",
          textAlign: "justify",
          scale: "1",
        }}
      >
        {displayYear && displayYear === "2021" ? (
          <>
            <p>
              <strong>개인정보취급방침</strong>
            </p>
            <p>
              주식회사 마스앤비너스 (&quot;회사&quot;라 함)는 이용자의
              개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
              있도록 다음과 같이 개인정보 처리방침을 수립·공개하며, 본 개인정보
              처리방침을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나
              용이하게 보실 수 있도록 조치하고 있습니다. 개인정보 처리방침은
              정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법,
              통신비밀보호법, 전기통신사업법, 등 정보통신서비스제공자가
              준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련
              법령에 의거하며 지속적인 개선을 위하여 필요한 절차를 정하고
              있습니다.
            </p>
            <p>이용자들께서는 사이트 방문 시 수시로 확인하시기 바랍니다.</p>
            <p>
              본 개인정보처리방침은 회사가 제공하는 “홈페이지(marshealth.io/)”
              및 “카카오챗봇(마스앤비너스 채널)” (이하에서는 홈페이지 및
              카카오챗봇을 이하 ‘서비스’라 합니다.)이용에 적용되며 다음과 같은
              내용을 담고 있습니다.
            </p>
            <p>
              <strong>수집하는 개인정보의 항목</strong>
            </p>
            <p>
              (1) 회사는 서비스 운영에 필요한 최소한의 개인정보만 수집하며,
              서비스 이용을 위하여 개인정보가 추가로 필요한 경우 회원에게 별도의
              동의를 받은 후 개인정보를 수집할 예정입니다.
            </p>
            <p>수집방법 – 수집항목</p>
            <p>회원가입 – 이름, 전자우편주소</p>
            <p>
              원격진료 예약하기 – 진료시간: 원격진료 가능한 날짜 및 시간,
              증상입력 내용
            </p>
            <p>결제관리</p>
            <p>카드 선택, 카드추가하기 – 카드번호 16자리, 유효기간 월/년</p>
            <p>진료를 위한 기본정보 입력</p>
            <p>
              기본정보 입력 – 이름, 휴대폰번호, 집주소, 생년월일, 성별, 이메일
            </p>
            <p>회원탈퇴 – 이메일, 이름, 휴대전화번호, 회원탈퇴사유</p>
            <p>원격진료 신청/예약 – 원격진료 예약 및 진료신청서 작성</p>
            <p>
              이름, 생년월일, 성별, 휴대폰번호, 진료과목, 선호의약품, 진료시간,
              진료신청서, 증상내용, 진료 예약일시, 진료 취소, 진료를 위한
              기본정보 입력내용, 진료를 위한 건강정보 입력내용, 진료를 위한
              생활방식 입력내용
            </p>
            <p>화상진료 – 화상진료영상</p>
            <p>처방전</p>
            <p>본인, 가족, 기타 – 이름, 처방전 정보</p>
            <p>
              <strong>의사 회원가입 (의료기관 및 의사)</strong>
            </p>
            <p>의사 회원가입</p>
            <p>
              – 의사 이름, 의사 사진, 휴대폰 전화번호, 학력, 경력, 진료과목,
              전문분야, 병원이름, 병원주소, 우편번호, 전화번호, 계좌정보,
              예금주, 은행명, 계좌번호, 채팅 진료비용, 화상 진료비용, 전화
              진료비용
            </p>
            <p>원격진료진료 접수/예약된 진료확인 공통 (의료기관 및 의사)</p>
            <p>
              서비스 이용 시 자동수집 – 서비스 이용기록, 접속로그, 접속IP정보,
              의사정보, 진료과목, 전문분야, 진료예약 시간, 의사이름
            </p>
            <p>
              (2) 회사는 귀하의 개인정보보호를 매우 중요시하며, 『개인정보
              보호법』과 『정보통신망 이용촉진 및 정보보호 등에 관한법률』상의
              개인정보 처리방침 및 행정안전부와 정보통신부가 제정한 『개인정보
              보호지침』을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여
              귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고
              있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
              알려드립니다.
            </p>
            <p>
              (3) 회사는 개인정보 처리방침을 사이트 첫 화면과 고객센터의
              ‘개인정보 처리방침’ 항목에서 공개함으로써 이용자들이 언제나
              용이하게 보실 수 있도록 조치하고 있습니다.
            </p>
            <p>
              (4) 회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보
              처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고
              개인정보 처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된
              사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다.
            </p>
            <p>
              (5) 서비스 아이디를 이용한 부가 서비스 및 맞춤식 서비스 이용 또는
              이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은
              정보들이 수집될 수 있습니다.
            </p>
            <p>
              <strong>개인정보의 수집 및 이용 목적</strong>
            </p>
            <p>
              회사는 다음과 같이 회원님의 개인정보를 수집합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
              동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <p>
              회사가 개인정보를 수집하는 목적은 휴대폰 전화번호 및 인증번호에
              의한 개인 식별을 통해 신원이 불확실하고 불손한 행위를 하는 인터넷
              사용자가 아닌 양질의 이용자를 선별하여 ‘서비스’에서 제공하는
              양질의 맞춤화 된 서비스를 드리기 위함입니다. 또한 회원 식별 및
              확인, 회원에 대한 고지·통지·공지사항 전달, 회원 상담 처리, 마케팅
              및 이벤트 제공하고 처방 기록 관리 및 의약품 정보 제공하기
              위함입니다.
            </p>
            <p>(1) 회원가입 및 로그인, 회원관리</p>
            <p>
              회원제 서비스 제공, 개인식별, 회사의 이용약관 위반 회원에 대한
              이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스
              부정이용 행위 제재, 가입 의사 및 약사 확인, 가입 및 가입횟수 제한,
              분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달,
              회원탈퇴 의사,약사의 확인
            </p>
            <p>(2) 의사의 진료내용 저장 및 환자에게 공유</p>
            <p>
              원격진료 신청/예약 정보를 바탕으로 원격진료 진행 후 의사가
              진료내용을 작성하며 진료을 진행하고, 환자에게 진료내용을 공유하며,
              해당 내용을 바탕으로 처방전 발행을 진행함
            </p>
            <p>(3) 약사의 배달내용 저장 및 환자에게 공유</p>
            <p>
              약배달 신청/예약 정보를 바탕으로 약배달 진행 후 약사가 배달내용을
              작성하며 배달을 진행하고, 환자에게 배달내용을 공유하며, 해당
              내용을 바탕으로 배달을 진행함
            </p>
            <p>(4) 신규 서비스 개발 및 마케팅 광고에의 활용</p>
            <p>
              신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
              제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회
              제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한
              통계
            </p>
            <p>(5) 기타 문의, 서비스 요청에 대한 응대 및 처리</p>
            <p>
              병원 제휴 문의에 대한 처리, 컨텐츠 요청에 대한 응대 및 안내, 병원
              상담 요청에 대한 내용 전달
            </p>
            <p>
              <strong>수집항목</strong>
            </p>
            <p>(챗봇) 카카오톡 ID</p>
            <p>
              (챗봇) 처방날짜, 처방전 교부번호, 진료과목, 질병코드, 조제약,
              복약안내(투약량, 횟수, 일수)
            </p>
            <p>(Web/챗봇) 회원가입 및 로그인, 회원관리</p>
            <p>이름, 이메일, 휴대폰 번호, 카카오ID</p>
            <p>(챗봇) 원격진료 예약하기</p>
            <p>진료시간(날짜 및 시간), 전화번호</p>
            <p>(챗봇) 진료신청서 입력하기</p>
            <p>환자 진료설문조사를 통한 증상관련 정보입력, 사진 첨부</p>
            <p>(챗봇) 기본정보 입력하기</p>
            <p>이름, 생년월일, 성별, 휴대전화번호</p>
            <p>(챗봇) 카드정보 등록하기</p>
            <p>카드번호 앞 4자리,뒤 4자리 유효기간, 생년월일 , 이름</p>
            <p>(챗봇) 진료신청하기</p>
            <p>이름, 생년월일, 성별, 휴대폰번호, 시간</p>
            <p>(챗봇)진료신청서</p>
            <p>환자 본인여부 확인 : 본인, 가족, 기타</p>
            <p>증상입력 내용</p>
            <p>(챗봇)결제관리</p>
            <p>
              카드번호 앞 4자리,뒤 4자리 유효기간, 생년월일 , 이름, 기본결제수단
              등록 여부
            </p>
            <p>(챗봇)진료를 위한 기본정보 입력</p>
            <p>이름, 휴대폰번호, 생년월일, 성별, 이메일</p>
            <p>(챗봇)진료를 위한 증상 입력</p>
            <p>(챗봇)회원탈퇴</p>
            <p>이메일, 이름, 휴대전화번호, 회원탈퇴사유</p>
            <p>(챗봇)원격진료 신청/예약</p>
            <p>
              본인, 가족, 기타 선택, 이름, 생년월일, 성별, 휴대폰번호, 진료과목,
              진료의사,진료수단, 진료시간, 진료신청서, 증상내용, 증상과 관련된
              첨부파일, 진료 예약일시, 진료 취소, 진료를 위한 기본정보 입력내용,
              진료를 위한 증상 입력내용, 진료를 위한 생활방식 입력내용
            </p>
            <p>(챗봇) 진료내역/내용 및 처방전</p>
            <p>(챗봇) 배달 신청/예약</p>
            <p>
              본인, 가족, 기타 선택, 이름, 생년월일, 성별, 휴대폰번호, 배달과목,
              배달약사, 배달수단, 배달시간, 배달신청서, 증상내용, 증상과 관련된
              첨부파일, 배달 예약일시, 배달 취소, 배달을 위한 기본정보 입력내용,
              배달을 위한 증상 입력내용, 배달을 위한 생활방식 입력내용
            </p>
            <p>(챗봇) 진료내역/내용 및 처방전</p>
            <p>
              환자의 증상 및 첨부파일, 의사의 진료내용 및 처방의약품, 처방전에
              대한 내용(Web,챗봇) 원격진료영상
            </p>
            <p>(챗봇) 배달내역/내용 및 처방전</p>
            <p>
              환자의 증상 및 첨부파일, 약사의 배달내용 및 처방의약품, 처방전에
              대한 내용
            </p>
            <p>(Web,챗봇) 처방전 및 진료내역</p>
            <p>(Web,챗봇) 처방전 및 배달내역</p>
            <p>
              이름, 처방전 정보, 환자의 증상에 대한 의사의 진료내용 정보, 약사의
              배달내용 정보
            </p>
            <p>(Web,챗봇) 의사 회원가입 (의료기관 및 의사)</p>
            <p>(Web,챗봇) 약사 회원가입 (의료기관 및 약사)</p>
            <p>
              의사 이름, 약사 이름, 의사 사진, 약사 사진, 휴대폰 전화번호, 학력,
              경력, 진료과목, 배달과목, 전문분야, 병원이름, 약국이름, 병원주소,
              약국주소, 우편번호, 전화번호, 계좌정보, 예금주, 은행명, 계좌번호,
              화상 진료비용, 전화 진료비용, 의약품별 조제비(약국별로 자체 제공)
            </p>
            <p>
              (Web,챗봇) 원격진료 접수/예약된 진료확인 공통 (의료기관 및 의사)
            </p>
            <p>
              (Web,챗봇) 배달배달 접수/예약된 배달확인 공통 (의료기관 및 약사)
            </p>
            <p>
              서비스 이용기록, 접속로그, 접속IP정보, 의사정보, 약사정보,
              진료과목, 배달과목, 전문분야, 진료예약 시간, 배달예약 시간,
              의사이름, 약사이름, 진료내용, 배달내용, 처방전
            </p>
            <p>(Web, 챗봇) 의사 회원가입 (의료기관 및 의사)</p>
            <p>
              의사 이름, 휴대폰 전화번호, 학력, 경력, 진료과목, 전문분야,
              병원이름, 병원주소, 우편번호, 전화번호, 계좌정보, 예금주, 은행명,
              계좌번호, 채팅 진료비용, 화상 진료비용, 전화 진료비용
            </p>
            <p>
              (Web, 챗봇) 원격진료진료 접수/예약된 진료확인 공통 (의료기관 및
              의사)
            </p>
            <p>
              서비스 이용기록, 접속로그, 접속IP정보, 의사정보, 진료과목,
              전문분야, 진료예약 시간, 의사이름, 진료내용, 처방전
            </p>
            <p>
              <strong>개인정보 수집 및 이용에 대한 동의</strong>
            </p>
            <p>
              회사는 이용자들이 회사의 개인정보 보호정책 또는 이용약관의 내용에
              대하여 「동의 합니다.」버튼을 클릭할 수 있는 절차를 마련하여,
              「동의합니다.」버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한
              것으로 봅니다.
            </p>
            <p>
              <strong>개인정보의 처리 및 보유 기간</strong>
            </p>
            <p>
              (1) 이용자의 개인정보는 그 수집목적 또는 제공받은 목적이
              달성되거나 이용자가 이용계약 해지(회원탈퇴)를 요청한 경우 파기하는
              것을 원칙으로 하며, 이 경우 이용자의 개인정보는 재생할 수 없는
              방법에 의하여 시스템에서 완전히 삭제되며 어떠한 용도로도 열람 또는
              이용할 수 없도록 처리됩니다. 또한, 일시적인 목적(설문조사 등)으로
              입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로
              사후 재생이 불가능한 방법으로 처리됩니다.
            </p>
            <p>
              (2) 회사는 불량 이용자의 부정한 이용의 재발을 방지하기 위해
              이용계약 해지일(회원탈퇴일)로부터 6개월간 해당 이용자의 개인정보를
              보유할 수 있습니다. 그리고 상법, 전자상거래 등에서의 소비자보호에
              관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우
              회사는 관계법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다.
              이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
              보존기간은 아래와 같습니다.
            </p>
            <p>– 계약 또는 청약철회 등에 관한 기록 : 5년</p>
            <p>– 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
            <p>– 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>
            <p>
              – 부정거래기록 : 6개월 (부정거래의 배제 등 회사 방침에 의한 보존)
            </p>
            <p>
              (3) 이용약관 및 관계법령을 위반한 이용자의 경우 다른 이용자을
              보호하고 사법기관 수사의뢰 시 증거자료로 활용하기 위해 이용자탈퇴
              후 6개월까지 이용자정보를 보관할 수 있습니다.
            </p>
            <p>
              (4) 2021년 12월 15일 이후 가입한 이용자가 1년간 서비스 거래기록이
              없을 시, 서비스 미이용자의 개인정보는 ‘정보통신망 이용촉진 및
              정보보호등에 관한 법률 제29조’ 에 근거하여 이용자에게 사전통지하고
              개인정보를 파기하거나 별도로 분리하여 저장 관리합니다. 고객의
              요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 단,
              통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의
              관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서
              규정한 일정한 기간 동안 이용자 개인정보를 보관합니다.
            </p>
            <p>
              회사는 위의 1년의 기간 만료 30일 전까지 개인정보가 파기되거나
              분리되어 저장•관리되는 사실과 기간 만료일 및 해당 개인정보의
              항목을 전자우편 등의 방법으로 고객에게 알립니다. 이를 위해 고객은
              정확한 연락처 정보를 제공/수정하여야 합니다.
            </p>
            <p>
              <strong>개인정보의 제3자에 대한 제공</strong>
            </p>
            <p>개인정보를 제공 받는 자</p>
            <p>회원이 원격진료 접수/예약을 요청한 원격진료 서비스 제공 병원</p>
            <p>개인정보 이용 목적</p>
            <p>예약 접수 서비스 제공 및 확인</p>
            <p>제공되는 개인정보</p>
            <p>
              환자 본인 여부 정보(본인/가족/기타) 1) 이름 2) 생년월일 3) 성별 4)
              휴대폰 번호 5)환자 기본정보 6)환자 건강정보 7)환자 생활정보
              8)환자의 증상정보 9)환자의 증상과 관련된 첨부 사진 정보 10)환자의
              과거 진료내역 11) 진료영상 기록
            </p>
            <p>개인정보 보유 및 이용기간</p>
            <p>
              원격진료의 서비스를 통한 진료 목적을 달성하기 위하여 본 서비스를
              이용할 때까지 (본 서비스의 회원에서 탈퇴하거나 이용계약을 종료할
              때까지) (단, 관련법령에 따라 별도 보관되는 정보는 예외)
            </p>
            <p>동의 거부에 따른 불이익</p>
            <p>마스앤비너스 원격진료 서비스 이용 제한</p>
            <p>
              (1) 회사는 이용자들의 개인정보를 “개인정보의 수집 및 이용목적”에서
              고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를
              초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지
              않습니다. 다만 다음의 경우에는 이용자의 개인정보를 제3자에게
              제공(공유를 포함)할 수 있습니다.
            </p>
            <p>
              (2) 다음의 경우 – 이용자가 개인정보의 수집 및 이용에 대한 동의와
              별도로 제3자 제공에 사전 동의한 경우
            </p>
            <p>
              : 회사는 이용자에게 개인정보를 제공받는 자의 성명과 연락처,
              제공받는 자의 개인정보 이용 목적, 제공하는 개인정보의 항목,
              제공받는 자의 개인정보 보유 및 이용 기간, 동의 거부권이 존재한다는
              사실 및 동의 거부에 따른 불이익의 내용을 미리 알립니다.
            </p>
            <p>– 법률규정이 있거나 법령상 의무준수를 위해 불가피한 경우</p>
            <p>
              – 수사기관이 수사목적을 위해 관계법령이 정한 절차를 거쳐 요구하는
              경우
            </p>
            <p>
              – 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정
              개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
            </p>
            <p>
              <strong>개인정보 파기절차 및 방법</strong>
            </p>
            <p>
              이용자의 개인정보는 원칙적으로 보유기간의 경과, 개인정보의 수집 및
              이용목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이
              파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
            </p>
            <p>(1) 파기절차</p>
            <p>
              – 회사는 개인정보의 파기에 관한 사항을 기록하고 관리하며, 파기는
              개인정보 보호책임자의 책임하에 수행되며, 개인정보 보호책임자는
              파기 결과를 확인합니다.
            </p>
            <p>
              – 회사는 다른 법령에 따라 보존해야 하는 경우에는 예외적으로
              이용자의 개인정보를 파기하지 않을 수 있습니다.
            </p>
            <p>
              (2) 파기방법 -종이나 그 밖의 기록매체에 저장된 개인정보는
              파쇄하거나 소각합니다.
            </p>
            <ul>
              <li>
                전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법(내지
                기록을 재생할 수 없는 기술적 방법)으로 영구 삭제합니다.
              </li>
            </ul>
            <p>(3) 미파기 정보의 보존방법</p>
            <p>
              회사는 법령에 따라 개인정보를 파기하지 않고 보존하는 경우에 해당
              개인정보 또는 개인정보파일을 다른 개인정보와 분리하여 저장
              관리합니다. 회사는 별도 DB로 옮긴 개인정보를 법률에 의한 경우가
              아니고서는 보유하는 이외의 다른 목적으로 이용하지 않습니다.
            </p>
            <p>
              <strong>개인정보주체의 권리와 의무 및 그 행사방법</strong>
            </p>
            <p>
              (1) 이용자는 언제든지 회사에 “마스앤비너스” 서비스를 통하여
              등록되어 있는 자신의 개인정보, 회사가 이용자의 개인정보를
              이용하거나 제3자에게 제공한 현황, 회사에게 개인정보 수집, 이용,
              제공 등의 동의를 한 현황에 대하여 열람이나 제공을 요구할 수 있고,
              오류가 있는 경우에는 그 정정을 요구할 수 있으며, 삭제 내지
              가입해지를 요구할 수도 있습니다.
            </p>
            <p>
              (2) 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
              ‘개인정보변경’(또는 ‘이용자정보수정’ 등)을, 가입해지(동의철회)를
              위해서는 “이용자탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접
              열람, 정정 또는 탈퇴가 가능합니다.
            </p>
            <p>
              (3) 이 경우에 회사는 지체 없이 그 개인정보를 조사하여 이용자의
              요구에 따라 정정, 삭제 등 필요한 조치를 한 후 그 결과를 이용자에게
              알립니다. 회사는 필요한 조치를 할 때까지는 해당 개인정보를
              이용하거나 제공하지 않습니다.
            </p>
            <p>
              (4) 이용자는 언제든지 회사에 자신의 개인정보 처리의 정지를 요구할
              수 있으며, 이 경우에 회사는 지체 없이 그 요구에 따라 개인정보
              처리의 전부 내지 일부를 정지하며, 처리가 정지된 개인정보에 대하여
              지체 없이 그 개인정보의 파기 등 필요한 조치를 취합니다.
            </p>
            <p>
              (5) 개인정보와 관련하여 의의나 의견이 있으신 분은 개인정보
              보호책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 접수
              즉시 처리하고 결과를 안내해 드리겠습니다. 로그온(log-on)한
              상태에서는 주위의 다른 사람에게 개인정보가 유출되지 않도록 특별히
              주의를 기울이시기 바랍니다.
            </p>
            <p>
              (6) “모바일 접수/예약 서비스”는 회원(병/의원) 혹은 법정 대리인의
              요청에 의해 해지 또는 삭제된 개인정보는 “모바일 접수/예약
              서비스”가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라
              처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다.
              “모바일 접수/예약 서비스”의 회원탈퇴는 (주)마스앤비너스의 다른
              서비스의 탈퇴와 무관합니다.
            </p>
            <p>
              <strong>
                개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
              </strong>
            </p>
            <p>
              회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의
              정보를 저장하고 수시로 불러오는 ’쿠키(cookie)’를 사용합니다.
              쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
              하드디스크에 저장되기도 합니다.
            </p>
            <p>
              (1) 쿠키의 사용 목적 : 이용자들이 방문한 “마스앤비너스”의 각
              서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어,
              보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보
              제공을 위하여 사용합니다.
            </p>
            <p>(2) 쿠키의 설치/운영 및 거부 :</p>
            <p>
              ① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
              이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
              허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
              쿠키의 저장을 거부할 수도 있습니다.
            </p>
            <p>
              ② 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의
              옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
              확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            </p>
            <p>
              ③ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구
              &gt; 인터넷 옵션 &gt; 개인정보
            </p>
            <p>
              ④ 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부
              서비스는 이용에 어려움이 있을 수 있습니다.
            </p>
            <p>
              <strong>개인정보의 안전성 확보조치</strong>
            </p>
            <p>
              (1) 회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실,
              도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과
              같은 기술적 대책을 강구하고 있습니다.
            </p>
            <p>
              – 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및
              전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한
              데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            </p>
            <p>
              – 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를
              방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로
              업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시
              이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            </p>
            <p>
              – 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점
              분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.
            </p>
            <p>(2) 관리적인 대책</p>
            <p>
              – 위와 같은 노력 이외에 이용자님 스스로도 제3자에게 비밀번호 등이
              노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이 공공장소에
              설치한 PC를 통해 유출되지 않도록 항상 유의하시기 바랍니다.
              이용자님의 ID와 비밀번호는 반드시 본인만 사용하고 비밀번호를
              정기적으로 바꿔주시는 것이 좋습니다.
            </p>
            <p>
              – 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로
              제한하고 있으며 이용자를 직접 상대로 하여 마케팅 업무를 수행하는
              자, 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는
              자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을 엄격히
              제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를
              강조하고 있습니다.
            </p>
            <p>
              단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호,
              주민등록번호 등 개인정보가 유출해 발생한 문제에 대해 회사는 일체의
              책임을 지지 않습니다.
            </p>
            <p>
              <strong>개인정보 처리의 위탁</strong>
            </p>
            <p>
              (1) 회사는 서비스 향상을 위해서 이용자의 개인정보를 외부
              전문업체에 위탁하여 처리할 수 있습니다. 개인정보의 처리를 위탁하는
              경우에는 미리 그 사실을 이용자에게 공지하여 이용자의 동의를 받을
              것입니다.
            </p>
            <p>
              (2) 이용자의 개인정보의 위탁 처리하는 경우에는 위탁계약(위탁업무
              수행 목적 외 개인정보 처리 금지, 개인정보의 기술적 관리적
              보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보에 대한
              접근 제한 등 안정성 확보 조치, 위탁업무와 관련하여 보유하고 있는
              개인정보의 관리 현황 점검 등 감독, 수탁자가 준수하여야 할 의무를
              위반한 경우의 손해배상 등이 내용이 포함됨)을 통하여 서비스제공자의
              개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의
              금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을
              서면 또는 전자적으로 보관할 것입니다.
            </p>
            <p>
              (3) 이 경우에 회사는 위탁업무의 내용, 개인정보 처리업무를 위탁
              받아 처리하는 자(수탁자)를 이용자가 언제든지 쉽게 확인할 수 있도록
              회사의 ‘서비스’에 지속적으로 게재하여 공개합니다.
            </p>
            <p>
              (4) 회사는 업무 위탁으로 인하여 이용자의 개인정보가 분실, 도난,
              유출, 변조, 훼손되지 아니하도록 수탁자를 교육하고 수탁자가
              개인정보를 안전하게 처리하는 지를 감독할 것입니다.
            </p>
            <img src={personal} />
            <p>
              <strong>
                개인정보관련 의견수렴 및 침해, 불만처리에 관한 사항
              </strong>
            </p>
            <p>
              (1) 회사는 개인정보보호와 관련하여 이용자들의 의견을 수렴하고
              있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고
              있습니다. 이용자들은 하단에 명시한 개인정보 보호책임자 및 담당자”
              항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고,
              회사는 이용자들의 신고사항에 대하여 신속하게 처리하여 답변해
              드립니다.
            </p>
            <p>
              (2) 또는 정부에서 설치하여 운영 중인 아래의 기관에 불만처리를
              신청할 수 있습니다.
            </p>
            <p>– 개인정보침해신고센터 (www.1336.or.kr / 1336)</p>
            <p>– 정보보호마크인증위원회 (www.eprivacy.or.kr / 02–580–0533)</p>
            <p>– 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02–3480–2000)</p>
            <p>– 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02–393–9112)</p>
            <p>
              <strong>개인정보 보호책임자 및 담당자</strong>
            </p>
            <p>
              회사는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을
              다하고 있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에
              반하는 사고가 발생할 경우 개인정보보호책임자가 책임을 집니다.
              그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인
              네트워크 상의 위험성에 의해 발생하는 예기치 못한 사고로 인한
              개인정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에
              관해서 회사는 책임이 없습니다. 귀하의 개인정보를 취급하는 책임자
              및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고
              성실하게 답변해드리고 있습니다.
            </p>
            <p>개인정보 보호책임자</p>
            <p>– 이　　름 : 김선민</p>
            <p>– 소속/직위 : 개발총괄</p>
            <p>– 전화번호 : 010-2248-5568</p>
            <p>– 이 메 일 : contact@marsnvenus.net</p>
            <p>
              <strong>개인정보 처리방침의 변경</strong>
            </p>
            <p>
              회사는 개인정보 처리방침을 변경하는 경우에 개인정보주체인 이용자가
              그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지 쉽게
              알 수 있도록 지속적으로 ‘서비스’를 통하여 공개합니다. 이 경우에
              변경된 내용은 변경 전과 후를 비교하여 공개합니다.
            </p>
            <p>공고일자 : 2021-12-15</p>
            <p>시행일자 : 2021-12-15</p>
            <p>회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.</p>
            <p>상호: 주식회사 마스앤비너스</p>
            <p>주소: 서울시 강남구 선릉로 428, 14-115 (06192)</p>
            <p>전화번호: 010-2248-5568</p>
            <p>이메일 주소: contact@marsnvenus.net</p>
          </>
        ) : (
          ""
        )}

        {/* NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW */}

        {displayYear && displayYear === "2023" ? (
          <>
            {" "}
            <p>개인정보취급방침</p>
            <p></p>
            <p>
              주식회사 마스앤비너스 ("회사"라 함)는 이용자의 개인정보를 보호하고
              이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이
              개인정보 처리방침을 수립·공개하며, 본 개인정보 처리방침을 홈페이지
              첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록
              조치하고 있습니다. 개인정보 처리방침은 정보통신망 이용촉진 및
              정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
              전기통신사업법, 등 정보통신서비스제공자가 준수하여야 할 관련
              법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거하며
              지속적인 개선을 위하여 필요한 절차를 정하고 있습니다.
            </p>
            <p>
              <br />
            </p>
            <p>이용자들께서는 사이트 방문 시 수시로 확인하시기 바랍니다.</p>
            <p>
              본 개인정보처리방침은 회사가 제공하는
              "홈페이지(www.marsnvenus.net)" 및 "카카오챗봇(마스앤비너스 채널)"
              (이하에서는 홈페이지 및 카카오챗봇을 이하 '서비스'라 합니다.)
              이용에 적용되며 다음과 같은 내용을 담고 있습니다.
            </p>
            <p>
              <br />
            </p>
            <p>수집하는 개인정보의 항목</p>
            <p>
              (1) 회사는 서비스 운영에 필요한 최소한의 개인정보만 수집하며,
              서비스 이용을 위하여 개인정보가 추가로 필요한 경우 회원에게 별도의
              동의를 받은 후 개인정보를 수집할 예정입니다.
            </p>
            <p>
              <br />
            </p>
            <table className="agreement-table">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>수집 시점</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>수집시기</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>수집목적</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>필수항목</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>선택항목</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>[이용자]</p>
                    <p>모바일 서비스 가입</p>
                  </td>
                  <td>
                    <p>카카오 계정가입</p>
                  </td>
                  <td>
                    <p>
                      회원가입 의사 확인 및 회원제 서비스 제공에 따른 본인 식별,
                      인증, SNS 계정 연동, 서비스 부정이용 방지, 서비스 이용
                      대금 청구
                    </p>
                  </td>
                  <td>
                    <p>
                      닉네임, 프로필 사진, 카카오계정, 이메일, 전화번호,
                      카카오톡 채널
                      <br />
                      추가 상태 및 내역
                    </p>
                  </td>
                  <td>
                    <p>이름, 성별, 생일, 출생연도</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>[이용자]</p>
                    <p>비대면진료 중개 및 약배송 서비스</p>
                    <p>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>본인인증</p>
                  </td>
                  <td>
                    <p>이용자 본인 확인</p>
                  </td>
                  <td>
                    <p>
                      이동통신사 정보, 휴대폰 번호, CI, 성명, 생년월일, 성별,
                      내·외국인 여부
                    </p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>비대면진료 신청</p>
                  </td>
                  <td>
                    <p>의료기관에 비대면 진료 접수 서비스 제공</p>
                  </td>
                  <td>
                    <p>
                      이름, 휴대폰 번호, 주민등록번호(외국인인
                      <br />
                      경우 외국인등록번호), 증상문진, 진료과목
                    </p>
                  </td>
                  <td>
                    <p>증상 사진, 건강정보(진료. 투약정보, 건강검진결과)</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>약배송신청</p>
                  </td>
                  <td>
                    <p>
                      처방 약 배송
                      <br />
                      서비스 제공, 서비스 이용 대금 청구
                    </p>
                  </td>
                  <td>
                    <p>주소지, 처방전</p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>결제</p>
                  </td>
                  <td>
                    <p>진료비, 조제비, 약 배송비 결제</p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>
                      마스킹 처리된 카드번호, 유효기간, 카드 비밀번호 앞 2자리
                    </p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>[의사]</p>
                    <p>의료기관 회원가입 및 제휴계약</p>
                  </td>
                  <td>
                    <p>의사웹 가입</p>
                  </td>
                  <td>
                    <p>
                      회원가입 의사 확인 및 회원제 서비스 제공에 따른 본인 식별,
                      인증, SNS 계정 연동, 서비스 부정이용 방지, 서비스 이용
                      대금 청구
                    </p>
                  </td>
                  <td>
                    <p>
                      이름, 이메일주소, 비밀번호, 전화번호, 의사 면허증,
                      사업자등록증, 계좌번호, 통장사본
                    </p>
                  </td>
                  <td>
                    <p>전문의 자격증</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>[약사]</p>
                    <p>약국 회원가입 및 제휴계약</p>
                  </td>
                  <td>
                    <p>약사웹 가입</p>
                  </td>
                  <td>
                    <p>
                      회원가입 의사 확인 및 회원제 서비스 제공에 따른 본인 식별,
                      인증, SNS 계정 연동, 서비스 부정이용 방지, 서비스 이용
                      대금 청구
                    </p>
                  </td>
                  <td>
                    <p>
                      이름, 전화번호, 아이디, 비밀번호, 계좌번호, 약사
                      <br />
                      면허증, 약국 개설 확인증
                    </p>
                  </td>
                  <td>
                    <p>-</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>[이용자]</p>
                    <p>광고 및 마케팅</p>
                  </td>
                  <td>
                    <p>모바일 서비스 회원가입</p>
                  </td>
                  <td>
                    <p>신규 서비스, 이벤트, 광고성 정보 안내</p>
                  </td>
                  <td>
                    <ul>
                      <li></li>
                    </ul>
                  </td>
                  <td>
                    <p>이름, 휴대폰번호, 주민등록번호, 주소지, 증상 정보</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>
              (2) 회사는 귀하의 개인정보보호를 매우 중요시하며, 『개인정보
              보호법』과 『정보통신망 이용촉진 및 정보보호 등에 관한법률』상의
              개인정보 처리방침 및 행정안전부와 정보통신부가 제정한 『개인정보
              보호지침』을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여
              귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고
              있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
              알려드립니다.
            </p>
            <p>
              (3) 회사는 개인정보 처리방침을 사이트 첫 화면과 고객센터의
              '개인정보 처리방침' 항목에서 공개함으로써 이용자들이 언제나
              용이하게 보실 수 있도록 조치하고 있습니다.
            </p>
            <p>
              (4) 회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보
              처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고
              개인정보 처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된
              사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다.
            </p>
            <p>
              (5) 서비스 아이디를 이용한 부가 서비스 및 맞춤식 서비스 이용 또는
              이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은
              정보들이 수집될 수 있습니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보의 수집 및 이용 목적</p>
            <p>
              회사는 다음과 같이 회원님의 개인정보를 수집합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용
              목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
              동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <p>
              회사가 개인정보를 수집하는 목적은 휴대폰 전화번호 및 인증번호에
              의한 개인 식별을 통해 신원이 불확실하고 불손한 행위를 하는 인터넷
              사용자가 아닌 양질의 이용자를 선별하여 '서비스'에서 제공하는
              양질의 맞춤화 된 서비스를 드리기 위함입니다. 또한 회원 식별 및
              확인, 회원에 대한 고지·통지·공지사항 전달, 회원 상담 처리, 마케팅
              및 이벤트 제공하고 처방 기록 관리 및 의약품 정보 제공하기
              위함입니다.
            </p>
            <p>
              <br />
            </p>
            <p>(1) 회원가입 및 로그인, 회원관리</p>
            <p>
              회원제 서비스 제공, 개인식별, 회사의 이용약관 위반 회원에 대한
              이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스
              부정이용 행위 제재, 가입 의사 및 약사 확인, 가입 및 가입횟수 제한,
              분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달,
              회원탈퇴 의사,약사의 확인
            </p>
            <p>(2) 의사의 진료내용 저장 및 환자에게 공유</p>
            <p>
              원격진료 신청/예약 정보를 바탕으로 원격진료 진행 후 의사가
              진료내용을 작성하며 진료을 진행하고, 환자에게 진료내용을 공유하며,
              해당 내용을 바탕으로 처방전 발행을 진행함
            </p>
            <p>(3) 약사의 배달내용 저장 및 환자에게 공유</p>
            <p>
              약배달 신청/예약 정보를 바탕으로 약배달 진행 후 약사가 배달내용을
              작성하며 배달을 진행하고, 환자에게 배달내용을 공유하며, 해당
              내용을 바탕으로 배달을 진행함
            </p>
            <p>(3) 신규 서비스 개발 및 마케팅 광고에의 활용</p>
            <p>
              신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
              제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회
              제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한
              통계
            </p>
            <p>(4) 기타 문의, 서비스 요청에 대한 응대 및 처리</p>
            <p>
              병원 제휴 문의에 대한 처리, 컨텐츠 요청에 대한 응대 및 안내, 병원
              상담 요청에 대한 내용 전달
            </p>
            <p>
              <br />
            </p>
            <p>수집항목</p>
            <p>(챗봇) 카카오톡 ID</p>
            <p>
              (챗봇) 처방날짜, 처방전 교부번호, 진료과목, 질병코드, 조제약,
              복약안내(투약량, 횟수, 일수)
            </p>
            <p>(Web/챗봇) 회원가입 및 로그인, 회원관리</p>
            <p>이름, 이메일, 휴대폰 번호, 카카오ID</p>
            <p>(챗봇) 원격진료 예약하기</p>
            <p>진료시간(날짜 및 시간), 전화번호</p>
            <p>(챗봇) 진료신청서 입력하기</p>
            <p>환자 진료설문조사를 통한 증상관련 정보입력, 사진 첨부</p>
            <p>(챗봇) 기본정보 입력하기</p>
            <p>이름, 생년월일, 성별, 휴대전화번호</p>
            <p>(챗봇) 카드정보 등록하기</p>
            <p>카드번호 앞 4자리,뒤 4자리 유효기간, 생년월일 , 이름</p>
            <p>(챗봇) 진료신청하기</p>
            <p>이름, 생년월일, 성별, 휴대폰번호, 시간</p>
            <p>(챗봇)진료신청서</p>
            <p>환자 본인여부 확인 : 본인, 가족, 기타</p>
            <p>증상입력 내용</p>
            <p>(챗봇)결제관리</p>
            <p>
              카드번호 앞 4자리,뒤 4자리 유효기간, 생년월일 , 이름, 기본결제수단
              등록 여부
            </p>
            <p>(챗봇)진료를 위한 기본정보 입력</p>
            <p>이름, 휴대폰번호, 생년월일, 성별, 이메일</p>
            <p>(챗봇)진료를 위한 증상 입력</p>
            <p>(챗봇)회원탈퇴</p>
            <p>이메일, 이름, 휴대전화번호, 회원탈퇴사유</p>
            <p>(챗봇)원격진료 신청/예약</p>
            <p>
              본인, 가족, 기타 선택, 이름, 생년월일, 성별, 휴대폰번호, 진료과목,
              진료의사,진료수단, 진료시간, 진료신청서, 증상내용, 증상과 관련된
              첨부파일, 진료 예약일시, 진료 취소, 진료를 위한 기본정보 입력내용,
              진료를 위한 증상 입력내용, 진료를 위한 생활방식 입력내용
            </p>
            <p>(챗봇) 진료내역/내용 및 처방전</p>
            <p>(챗봇) 배달 신청/예약</p>
            <p>
              본인, 가족, 기타 선택, 이름, 생년월일, 성별, 휴대폰번호, 배달과목,
              배달약사, 배달수단, 배달시간, 배달신청서, 증상내용, 증상과 관련된
              첨부파일, 배달 예약일시, 배달 취소, 배달을 위한 기본정보 입력내용,
              배달을 위한 증상 입력내용, 배달을 위한 생활방식 입력내용
            </p>
            <p>(챗봇) 진료내역/내용 및 처방전</p>
            <p>
              환자의 증상 및 첨부파일, 의사의 진료내용 및 처방의약품, 처방전에
              대한 내용(Web,챗봇) 원격진료영상
            </p>
            <p>(챗봇) 배달내역/내용 및 처방전</p>
            <p>
              환자의 증상 및 첨부파일, 약사의 배달내용 및 처방의약품, 처방전에
              대한 내용
            </p>
            <p>(Web,챗봇) 처방전 및 진료내역</p>
            <p>(Web,챗봇) 처방전 및 배달내역</p>
            <p>
              이름, 처방전 정보, 환자의 증상에 대한 의사의 진료내용 정보, 약사의
              배달내용 정보
            </p>
            <p>(Web,챗봇) 의사 회원가입 (의료기관 및 의사)</p>
            <p>(Web,챗봇) 약사 회원가입 (의료기관 및 약사)</p>
            <p>
              의사 이름, 약사 이름, 의사 사진, 약사 사진, 휴대폰 전화번호, 학력,
              경력, 진료과목, 배달과목, 전문분야, 병원이름, 약국이름, 병원주소,
              약국주소, 우편번호, 전화번호, 계좌정보, 예금주, 은행명, 계좌번호,
              화상 진료비용, 전화 진료비용, 의약품별 조제비(약국별로 자체 제공)
            </p>
            <p>
              (Web,챗봇) 원격진료 접수/예약된 진료확인 공통 (의료기관 및 의사)
            </p>
            <p>
              (Web,챗봇) 배달배달 접수/예약된 배달확인 공통 (의료기관 및 약사)
            </p>
            <p>
              서비스 이용기록, 접속로그, 접속IP정보, 의사정보, 약사정보,
              진료과목, 배달과목, 전문분야, 진료예약 시간, 배달예약 시간,
              의사이름, 약사이름, 진료내용, 배달내용, 처방전
            </p>
            <p>(Web, 챗봇) 의사 회원가입 (의료기관 및 의사)</p>
            <p>
              의사 이름, 휴대폰 전화번호, 학력, 경력, 진료과목, 전문분야,
              병원이름, 병원주소, 우편번호, 전화번호, 계좌정보, 예금주, 은행명,
              계좌번호, 채팅 진료비용, 화상 진료비용, 전화 진료비용
            </p>
            <p>
              (Web, 챗봇) 원격진료진료 접수/예약된 진료확인 공통 (의료기관 및
              의사)
            </p>
            <p>
              서비스 이용기록, 접속로그, 접속IP정보, 의사정보, 진료과목,
              전문분야, 진료예약 시간, 의사이름, 진료내용, 처방전
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>민감정보의 수집 및 처리</strong>
            </p>
            <p>
              (1) 회사는 서비스 제공을 위해 이용자의 별도의 동의를 받아 아래와
              같은 민감정보를 수집하고 있으며, 목적 외의 용도로는 이용하지
              않습니다.
            </p>
            <table className="agreement-table">
              <tbody>
                <tr>
                  <td valign="middle">
                    <p>
                      <strong>수집 시점</strong>
                    </p>
                  </td>
                  <td valign="middle">
                    <p>
                      <strong>수집항목</strong>
                    </p>
                  </td>
                  <td valign="middle">
                    <p>
                      <strong>처리목적</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>[이용자]</p>
                    <p>비대면진료 신청</p>
                  </td>
                  <td valign="middle">
                    <p>
                      진료 병원, 진료 의사,
                      <br />
                      진료 과목, 진료 일시, 증상 정보, 증상 사진, 진료 기록,
                      처방전
                    </p>
                  </td>
                  <td valign="middle">
                    <p>
                      이용자가 선택한 의료기관에 비대면 진료 접수 서비스
                      <br />
                      제공
                    </p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>[이용자]</p>
                    <p>건강정보 조회</p>
                  </td>
                  <td valign="middle">
                    <p>건강보험공단 진료 및 투약정보, 건강검진결과</p>
                  </td>
                  <td valign="middle">
                    <p>진료·건강검진 내역 조회 서비스 제공</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>
              (2) 회사는 민감정보의 수집 및 이용 과정에서 「개인정보 보호법」
              제23조(민감정보 처리제한), 동법 시행령 제18조(민감정보 범위) 등
              관계법령을 준수하며, 수집한 민감정보는 본 처리방침에서 정한대로
              보유 및 관리합니다.
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>개인정보 수집 및 이용에 대한 동의</p>
            <p>
              회사는 이용자들이 회사의 개인정보 보호정책 또는 이용약관의 내용에
              대하여 「동의 합니다.」버튼을 클릭할 수 있는 절차를 마련하여,
              「동의합니다.」버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한
              것으로 봅니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보의 처리 및 보유 기간</p>
            <p>
              (1) 이용자의 개인정보는 그 수집목적 또는 제공받은 목적이
              달성되거나 이용자가 이용계약 해지(회원탈퇴)를 요청한 경우 파기하는
              것을 원칙으로 하며, 이 경우 이용자의 개인정보는 재생할 수 없는
              방법에 의하여 시스템에서 완전히 삭제되며 어떠한 용도로도 열람 또는
              이용할 수 없도록 처리됩니다. 또한, 일시적인 목적(설문조사 등)으로
              입력 받은 개인정보는 그 목적이 달성된 이후에는 동일한 방법으로
              사후 재생이 불가능한 방법으로 처리됩니다.
            </p>
            <p>
              (2) 회사는 불량 이용자의 부정한 이용의 재발을 방지하기 위해
              이용계약 해지일(회원탈퇴일)로부터 6개월간 해당 이용자의 개인정보를
              보유할 수 있습니다. 그리고 상법, 전자상거래 등에서의 소비자보호에
              관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우
              회사는 관계법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다.
              이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
              보존기간은 아래와 같습니다.
            </p>
            <p>- 계약 또는 청약철회 등에 관한 기록 : 5년</p>
            <p>- 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
            <p>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>
            <p>- 표시/광고에 관한 기록 : 6개월</p>
            <p>- 전자금융 거래에 관한 기록 : 5년</p>
            <p>
              <br />
            </p>
            <p>
              - 부정거래기록 : 6개월 (부정거래의 배제 등 회사 방침에 의한 보존)
            </p>
            <p>
              (3) 이용약관 및 관계법령을 위반한 이용자의 경우 다른 이용자을
              보호하고 사법기관 수사의뢰 시 증거자료로 활용하기 위해 이용자탈퇴
              후 6개월까지 이용자정보를 보관할 수 있습니다.
            </p>
            <p>
              (4) 2021년 12월 15일 이후 가입한 이용자가 1년간 서비스 거래기록이
              없을 시, 서비스 미이용자의 개인정보는 '정보통신망 이용촉진 및
              정보보호등에 관한 법률 제29조' 에 근거하여 이용자에게 사전통지하고
              개인정보를 파기하거나 별도로 분리하여 저장 관리합니다. 고객의
              요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 단,
              통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등의
              관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서
              규정한 일정한 기간 동안 이용자 개인정보를 보관합니다.
            </p>
            <p>
              회사는 위의 1년의 기간 만료 30일 전까지 개인정보가 파기되거나
              분리되어 저장•관리되는 사실과 기간 만료일 및 해당 개인정보의
              항목을 전자우편 등의 방법으로 고객에게 알립니다. 이를 위해 고객은
              정확한 연락처 정보를 제공/수정하여야 합니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보의 제3자에 대한 제공</p>
            <table className="agreement-table">
              <tbody>
                <tr>
                  <td valign="middle">
                    <p>개인정보를 제공 받는 자</p>
                  </td>
                  <td valign="middle">
                    <p>
                      회원이 원격진료 접수/예약을 요청한 원격진료 서비스 제공
                      병원
                    </p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>개인정보 이용 목적</p>
                  </td>
                  <td valign="middle">
                    <p>예약 접수 서비스 제공 및 확인</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>제공되는 개인정보</p>
                  </td>
                  <td valign="middle">
                    <p>
                      환자 본인 여부 정보(본인/가족/기타) 1) 이름 2) 생년월일 3)
                      성별 4) 휴대폰 번호 5)환자 기본정보 6)환자 건강정보 7)환자
                      생활정보 8)환자의 증상정보 9)환자의 증상과 관련된 첨부
                      사진 정보 10)환자의 과거 진료내역 11) 진료영상 기록
                    </p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>개인정보 보유 및 이용기간</p>
                  </td>
                  <td valign="middle">
                    <p>
                      원격진료의 서비스를 통한 진료 목적을 달성하기 위하여 본
                      서비스를 이용할 때까지 (본 서비스의 회원에서 탈퇴하거나
                      이용계약을 종료할 때까지) (단, 관련법령에 따라 별도
                      보관되는 정보는 예외)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>동의 거부에 따른 불이익</p>
            <p>마스앤비너스 원격진료 서비스 이용 제한</p>
            <p>
              (1) 회사는 이용자들의 개인정보를 "개인정보의 수집 및 이용목적"에서
              고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를
              초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지
              않습니다. 다만 다음의 경우에는 이용자의 개인정보를 제3자에게
              제공(공유를 포함)할 수 있습니다.
            </p>
            <p>
              (2) 다음의 경우 - 이용자가 개인정보의 수집 및 이용에 대한 동의와
              별도로 제3자 제공에 사전 동의한 경우
            </p>
            <p>
              : 회사는 이용자에게 개인정보를 제공받는 자의 성명과 연락처,
              제공받는 자의 개인정보 이용 목적, 제공하는 개인정보의 항목,
              제공받는 자의 개인정보 보유 및 이용 기간, 동의 거부권이 존재한다는
              사실 및 동의 거부에 따른 불이익의 내용을 미리 알립니다.
            </p>
            <p>- 법률규정이 있거나 법령상 의무준수를 위해 불가피한 경우</p>
            <p>
              - 수사기관이 수사목적을 위해 관계법령이 정한 절차를 거쳐 요구하는
              경우
            </p>
            <p>
              - 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정
              개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
            </p>
            <p>
              <br />
            </p>
            <p>개인정보 파기절차 및 방법</p>
            <p>
              이용자의 개인정보는 원칙적으로 보유기간의 경과, 개인정보의 수집 및
              이용목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이
              파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
            </p>
            <p>(1) 파기절차</p>
            <p>
              - 회사는 개인정보의 파기에 관한 사항을 기록하고 관리하며, 파기는
              개인정보 보호책임자의 책임하에 수행되며, 개인정보 보호책임자는
              파기 결과를 확인합니다.
            </p>
            <p>
              - 회사는 다른 법령에 따라 보존해야 하는 경우에는 예외적으로
              이용자의 개인정보를 파기하지 않을 수 있습니다.
            </p>
            <p>
              (2) 파기방법 -종이나 그 밖의 기록매체에 저장된 개인정보는
              파쇄하거나 소각합니다.
            </p>
            <p>
              -전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법(내지
              기록을 재생할 수 없는 기술적 방법)으로 영구 삭제합니다.
            </p>
            <p>(3) 미파기 정보의 보존방법</p>
            <p>
              회사는 법령에 따라 개인정보를 파기하지 않고 보존하는 경우에 해당
              개인정보 또는 개인정보파일을 다른 개인정보와 분리하여 저장
              관리합니다. 회사는 별도 DB로 옮긴 개인정보를 법률에 의한 경우가
              아니고서는 보유하는 이외의 다른 목적으로 이용하지 않습니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보주체의 권리와 의무 및 그 행사방법</p>
            <p>
              (1) 이용자는 언제든지 회사에 "마스앤비너스" 서비스를 통하여
              등록되어 있는 자신의 개인정보, 회사가 이용자의 개인정보를
              이용하거나 제3자에게 제공한 현황, 회사에게 개인정보 수집, 이용,
              제공 등의 동의를 한 현황에 대하여 열람이나 제공을 요구할 수 있고,
              오류가 있는 경우에는 그 정정을 요구할 수 있으며, 삭제 내지
              가입해지를 요구할 수도 있습니다.
            </p>
            <p>
              (2) 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
              '개인정보변경'(또는 '이용자정보수정' 등)을, 가입해지(동의철회)를
              위해서는 "이용자탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접
              열람, 정정 또는 탈퇴가 가능합니다.
            </p>
            <p>
              (3) 이 경우에 회사는 지체 없이 그 개인정보를 조사하여 이용자의
              요구에 따라 정정, 삭제 등 필요한 조치를 한 후 그 결과를 이용자에게
              알립니다. 회사는 필요한 조치를 할 때까지는 해당 개인정보를
              이용하거나 제공하지 않습니다.
            </p>
            <p>
              (4) 이용자는 언제든지 회사에 자신의 개인정보 처리의 정지를 요구할
              수 있으며, 이 경우에 회사는 지체 없이 그 요구에 따라 개인정보
              처리의 전부 내지 일부를 정지하며, 처리가 정지된 개인정보에 대하여
              지체 없이 그 개인정보의 파기 등 필요한 조치를 취합니다.
            </p>
            <p>
              (5) 개인정보와 관련하여 의의나 의견이 있으신 분은 개인정보
              보호책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 접수
              즉시 처리하고 결과를 안내해 드리겠습니다. 로그온(log-on)한
              상태에서는 주위의 다른 사람에게 개인정보가 유출되지 않도록 특별히
              주의를 기울이시기 바랍니다.
            </p>
            <p>
              (6) "모바일 접수/예약 서비스"는 회원(병/의원) 혹은 법정 대리인의
              요청에 의해 해지 또는 삭제된 개인정보는 "모바일 접수/예약
              서비스"가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라
              처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다.
              "모바일 접수/예약 서비스"의 회원탈퇴는 (주)마스앤비너스의 다른
              서비스의 탈퇴와 무관합니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</p>
            <p>
              회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의
              정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
              쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
              하드디스크에 저장되기도 합니다.
            </p>
            <p>
              (1) 쿠키의 사용 목적 : 이용자들이 방문한 "마스앤비너스"의 각
              서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어,
              보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보
              제공을 위하여 사용합니다.
            </p>
            <p>(2) 쿠키의 설치/운영 및 거부 :</p>
            <p>
              ① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
              이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
              허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
              쿠키의 저장을 거부할 수도 있습니다.
            </p>
            <p>
              ② 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의
              옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
              확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            </p>
            <p>
              ③ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구
              &gt; 인터넷 옵션 &gt; 개인정보
            </p>
            <p>
              ④ 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부
              서비스는 이용에 어려움이 있을 수 있습니다.
            </p>
            <p>
              ⑤ 모바일 어플리케이션과 같이 쿠키 기능을 사용할 수 없는 경우에는
              쿠키와 유사한 기능을 수행하는 기술(광고식별자 등)을 사용할 수
              있습니다.
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>모바일 앱 사용시 광고식별자 수집</strong>
            </p>
            <p>(1) 수집의 목적</p>
            <p>
              회사는 이용자의 ADID/IDFA를 수집할 수 있습니다. ADID/IDFA란 모바일
              앱 이용자의 광고 식별값으로서 사용자의 맞춤 서비스 제공이나 더
              나은 환경의 광고를 제공하기 위한 측정을 위해 수집될 수 있습니다.
            </p>
            <p>(2) 수집 거부 방법</p>
            <p>: ios 설정 -&gt; 개인정보보호 -&gt; 광고 -&gt; 광고 추적 제한</p>
            <p>
              : 안드로이드 설정 -&gt; 구글(구글설정) -&gt; 광고 -&gt; 광고
              맞춤설정 선택 해제
            </p>
            <p>
              <br />
            </p>
            <p>개인정보의 안전성 확보조치</p>
            <p>
              (1) 회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실,
              도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과
              같은 기술적 대책을 강구하고 있습니다.
            </p>
            <p>
              - 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및
              전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한
              데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            </p>
            <p>
              - 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를
              방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로
              업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시
              이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            </p>
            <p>
              - 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점
              분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.
            </p>
            <p>(2) 관리적인 대책</p>
            <p>
              - 위와 같은 노력 이외에 이용자님 스스로도 제3자에게 비밀번호 등이
              노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이 공공장소에
              설치한 PC를 통해 유출되지 않도록 항상 유의하시기 바랍니다.
              이용자님의 ID와 비밀번호는 반드시 본인만 사용하고 비밀번호를
              정기적으로 바꿔주시는 것이 좋습니다.
            </p>
            <p>
              - 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로
              제한하고 있으며 이용자를 직접 상대로 하여 마케팅 업무를 수행하는
              자, 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는
              자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을 엄격히
              제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를
              강조하고 있습니다.
            </p>
            <p>
              단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호,
              주민등록번호 등 개인정보가 유출해 발생한 문제에 대해 회사는 일체의
              책임을 지지 않습니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보 처리의 위탁</p>
            <p>
              (1) 회사는 서비스 향상을 위해서 이용자의 개인정보를 외부
              전문업체에 위탁하여 처리할 수 있습니다. 개인정보의 처리를 위탁하는
              경우에는 미리 그 사실을 이용자에게 공지하여 이용자의 동의를 받을
              것입니다.
            </p>
            <p>
              (2) 이용자의 개인정보의 위탁 처리하는 경우에는 위탁계약(위탁업무
              수행 목적 외 개인정보 처리 금지, 개인정보의 기술적 관리적
              보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보에 대한
              접근 제한 등 안정성 확보 조치, 위탁업무와 관련하여 보유하고 있는
              개인정보의 관리 현황 점검 등 감독, 수탁자가 준수하여야 할 의무를
              위반한 경우의 손해배상 등이 내용이 포함됨)을 통하여 서비스제공자의
              개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의
              금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을
              서면 또는 전자적으로 보관할 것입니다.
            </p>
            <p>
              (3) 이 경우에 회사는 위탁업무의 내용, 개인정보 처리업무를 위탁
              받아 처리하는 자(수탁자)를 이용자가 언제든지 쉽게 확인할 수 있도록
              회사의 '서비스'에 지속적으로 게재하여 공개합니다.
            </p>
            <p>
              (4) 회사는 업무 위탁으로 인하여 이용자의 개인정보가 분실, 도난,
              유출, 변조, 훼손되지 아니하도록 수탁자를 교육하고 수탁자가
              개인정보를 안전하게 처리하는 지를 감독할 것입니다.
            </p>
            <table className="agreement-table">
              <tbody>
                <tr>
                  <td valign="middle">
                    <p>위탁업체</p>
                  </td>
                  <td valign="middle">
                    <p>위탁하는 개인정보 항목</p>
                  </td>
                  <td valign="middle">
                    <p>위탁목적</p>
                  </td>
                  <td valign="middle">
                    <p>이용기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>NHN한국사이버결제 주식회사</p>
                  </td>
                  <td valign="middle">
                    <p>
                      주문을 위한 주문자 주문정보 등 주문 및 취소에 따른 관련
                      정보
                    </p>
                  </td>
                  <td valign="middle">
                    <p>결제 서비스</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>㈜페이플</p>
                  </td>
                  <td valign="middle">
                    <p>
                      주문을 위한 주문자 주문정보 등 주문 및 취소에 따른 관련
                      정보
                    </p>
                  </td>
                  <td valign="middle">
                    <p>결제 서비스</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>Amazon Web Services, Inc.</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 제공 및 분석을 위한 인프라 관리</p>
                  </td>
                  <td valign="middle">
                    <p>마스앤비너스 서비스 관리 및 운영</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>(주)카카오</p>
                  </td>
                  <td valign="middle">
                    <p>
                      카카오톡 채널을 이용한 메세지 발송, 서비스 제공 및 분석을
                      위한 인프라 관리
                    </p>
                  </td>
                  <td valign="middle">
                    <p>마스앤비너스 서비스 제공</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>(주)레이어스코스메틱</p>
                  </td>
                  <td valign="middle">
                    <p>주문을 위한 주문자 주문정보 및 배송을 위한 정보</p>
                  </td>
                  <td valign="middle">
                    <p>제품 배송</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>(주)BF생명과학</p>
                  </td>
                  <td valign="middle">
                    <p>주문을 위한 주문자 주문정보 및 배송을 위한 정보</p>
                  </td>
                  <td valign="middle">
                    <p>제품 배송</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
                <tr>
                  <td valign="middle">
                    <p>CJ 대한통운</p>
                  </td>
                  <td valign="middle">
                    <p>주문을 위한 주문자 주문정보 및 배송을 위한 정보</p>
                  </td>
                  <td valign="middle">
                    <p>택배 서비스</p>
                  </td>
                  <td valign="middle">
                    <p>서비스 가입기간</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
            </p>
            <p>개인정보관련 의견수렴 및 침해, 불만처리에 관한 사항</p>
            <p>
              (1) 회사는 개인정보보호와 관련하여 이용자들의 의견을 수렴하고
              있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고
              있습니다. 이용자들은 하단에 명시한 개인정보 보호책임자 및 담당자"
              항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고,
              회사는 이용자들의 신고사항에 대하여 신속하게 처리하여 답변해
              드립니다.
            </p>
            <p>
              (2) 또는 정부에서 설치하여 운영 중인 아래의 기관에 불만처리를
              신청할 수 있습니다.
            </p>
            <p>- 개인정보침해신고센터 (www.1336.or.kr / 1336)</p>
            <p>- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533)</p>
            <p>- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)</p>
            <p>- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-393-9112)</p>
            <p>
              <br />
            </p>
            <p>개인정보 보호책임자 및 담당자</p>
            <p>
              회사는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을
              다하고 있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에
              반하는 사고가 발생할 경우 개인정보보호책임자가 책임을 집니다.
              그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인
              네트워크 상의 위험성에 의해 발생하는 예기치 못한 사고로 인한
              개인정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에
              관해서 회사는 책임이 없습니다. 귀하의 개인정보를 취급하는 책임자
              및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고
              성실하게 답변해드리고 있습니다.
            </p>
            <p>
              <br />
            </p>
            <p>개인정보 보호책임자</p>
            <p>- 이 름 : 김선민</p>
            <p>- 소속/직위 : 개발총괄</p>
            <p>- 전화번호 : 010-8081-4230</p>
            <p>- 이 메 일 : contact@marsnvenus.net</p>
            <p>
              <br />
            </p>
            <p>개인정보 처리방침의 변경</p>
            <p>
              회사는 개인정보 처리방침을 변경하는 경우에 개인정보주체인 이용자가
              그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지 쉽게
              알 수 있도록 지속적으로 '서비스'를 통하여 공개합니다. 이 경우에
              변경된 내용은 변경 전과 후를 비교하여 공개합니다.
            </p>
            <p>시행일자 : 2023년 2월 24일</p>
            <p>
              <br />
            </p>
            <p>회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.</p>
            <p>상호: 주식회사 마스앤비너스</p>
            <p>주소: 서울시 강남구 선릉로 428, 14층 115호 (06192)</p>
            <p>전화번호: 010-2248-5568</p>
            <p>이메일 주소: contact@marsnvenus.net</p>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="agreement-buttons">
        <button
          onClick={() => {
            handleYearClick("2021");
          }}
          style={{
            background: displayYear === "2021" ? "#6A43B8" : "",
            color: displayYear === "2021" ? "white" : "",
          }}
        >
          2021
        </button>
        <button
          onClick={() => {
            handleYearClick("2023");
          }}
          style={{
            background: displayYear === "2023" ? "#6A43B8" : "",
            color: displayYear === "2023" ? "white" : "",
          }}
        >
          2023
        </button>
      </div>
    </>
  );
}

export default PersonalInfoPolicy;
