import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// pages
import ExogeneIntro from "./pages/ExogeneIntro";
import ExogeneSummary from "./pages/ExogeneSummary";
import ExogeneStep from "./pages/ExogeneStep";
import ExogeneKit from "./pages/ExogeneKit";
import ExogeneFaq from "./pages/ExogeneFaq";

// assets
import logo from "../../../assets/img/mars_logo_black.png";

// css
import "./styling/exogeneStyling.css";
import { pushMarsAnalytics } from "utils/analytics";
import { slack } from "utils/util";

export default function ExogeneContainer() {
  useEffect(() => {
    slack("[엑소진] 유전자검사 페이지 방문!");
    const asyncWrapper = async () => {
      await pushMarsAnalytics("111111", 'visit.exogene-landing', { from: "/exogene-landing", msg: "DNA검사 랜딩페이지 방문" });
    }
    asyncWrapper();
  }, []);


  const history = useHistory();

  return (
    <>
      <header
        className="exogene-header"
        style={{ background: "rgba(255, 255, 255, 0.8)" }}
      >
        <img
          src={logo}
          style={{ width: "57px", height: "auto" }}
          alt="exo-logo"
        />
      </header>
      <div className="exogene-main-container">
        <ExogeneIntro></ExogeneIntro>
        <ExogeneSummary></ExogeneSummary>
        <ExogeneStep></ExogeneStep>
        <ExogeneKit></ExogeneKit>
        <ExogeneFaq></ExogeneFaq>
      </div>
      <div className="exogene-button-container-new">
        <button
          className="exogene-button-confirm"
          onClick={() => {
            history.push("/exogene-reservation");
          }}
        >
          지금 신청하기
        </button>
      </div>
    </>
  );
}
