import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { updateChart } from "utils/data";
import { parseSurvey, slack2ops, won } from "utils/util";
import { sendAnalytics } from "utils/analytics";
import { getDoctorInfo } from "utils/util";

// css
import "./styling/hospitalStyles.css";

export default function HospitalChanger(props) {
  const {
    openHospitalModal,
    setOpenHospitalModal,
    doctor,
    setDoctor,
    patient,
  } = props;
  const toggle = () => setOpenHospitalModal(!openHospitalModal);

  return (
    <Modal
      isOpen={openHospitalModal}
      toggle={toggle}
      className="hospital-modal"
    >
      <ModalBody className="hospital-modal-body">
        <span id="hospital-title">{patient.name}님의 병원 선택</span>
        <div className="hospital-dropdown">
          <select
            id="operation-select"
            onChange={(e) => {
              setDoctor(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <option
              value="doctor2"
              selected={props.patient.doctor === "doctor2"}
            >
              보령이가정의원
            </option>
            <option
              value="doctor1"
              selected={props.patient.doctor === "doctor1"}
            >
              정연의원
            </option>
            <option
              value="gaonsamsung"
              selected={props.patient.doctor === "gaonsamsung"}
            >
              가온삼성(유재호)
            </option>
            <option
              value="doctor77"
              selected={props.patient.doctor === "doctor77"}
            >
              마스의원(TEST)
            </option>
          </select>

          <div className="hospital-buttons">
            <button
              style={{ borderRight: ".5px solid gray" }}
              onClick={() => {
                setOpenHospitalModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={async (e) => {
                e.stopPropagation();
                if (window.confirm("정말로 병원선택을 완료 하시겠습니까??")) {
                  const chartInfo = {
                    id: props.patient.id,
                    ignoreStatus: "no",
                    dstatus: "accept",
                    status: "accept",
                    doctor: doctor,
                  };

                  const updatedChart = await updateChart(chartInfo);
                  if (updatedChart) {
                    slack2ops(
                      `[운영][${props.patient.name}] 병원 선택완료[${
                        getDoctorInfo(doctor).office
                      }]`
                    );
                    sendAnalytics({
                      type: "ops",
                      kid: props.patient.kid,
                      from: "operation",
                      event: `select.doctor.${doctor}`,
                      msg: `병원 ${doctor} 선택 완료`,
                    });
                    alert(
                      `${props.patient.name}님 ${
                        getDoctorInfo(doctor).office
                      } 선택완료!`
                    );
                  }
                }
                setOpenHospitalModal(false);
              }}
              // className="btn-sm btn-primary"
            >
              확인
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
