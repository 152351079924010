import { updateMars } from "utils/data";
import ExoCustomerCard from "./ExoCustomerCard";
import PhotoChartCard from "./OperationCardNew";
import ShampooCard from "./ShampooCard";
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";
import { useEffect } from "react";

function NeshViewer(props) {
  const { shipments, status } = props;

  return (
    <>
      {/* <div> db cleanup
        <div>
          <button onClick={async () => {
            let neshes = props.neshes;
            for (let nesh of neshes) {
              if (nesh.status === "shamp_sent") {
                // console.log(nesh);
                let neshInfo = {
                  id: nesh.id,
                  status: "shamp_deli_done",
                  ignoreStatus: "yes"
                }
                let res = await updateMars(neshInfo);
                console.log(res);
              }
            }
          }}>db cleanup</button>
        </div>
      </div> */}
      <div className="scrollable2" style={{ padding: "20px" }}>
        {shipments.map((item, key) => {
          if (item.brand === "nesh" && item.processor_status === status) {
            return <ShampooCard key={key} shipment={item} />;
          } else {
            return <></>;
          }
        })}
      </div>
    </>
  );
}
export default NeshViewer;
