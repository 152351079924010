import React, { useState, useEffect } from "react";

// img
import searchIcon from "../pharma2/Vector.png";

// css
import "./styling/viewerAcceptStyles.css";
import { Pagination } from "@aws-amplify/ui-react";
import OperationCardNew from "./OperationCardNew";
import { getAllChartsWithUserInfo } from "utils/data";

function ChartViewerDeliDone(props) {
  const [searchWord, setSearchWord] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [pageSize, setPageSize] = useState(20);
  const [filteredCharts, setFilteredCharts] = useState([]);
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    const asyncWrapper = async () => {
      // let charts = await getAllChartsWithUserInfo("DESC", null, 1000);
      setCharts(props.allCharts);
      setFilteredCharts(props.allCharts);
      setTotalPage(getTotalPages(props.allCharts, pageSize));
    }
    asyncWrapper();
  }, [])

  useEffect(() => {
    setTotalPage(getTotalPages(filteredCharts, pageSize));
    // console.log("filterd Charts: ", filteredCharts);
  }, [filteredCharts])

  useEffect(() => {
    // let chartsCopy = [...charts];
    // if (searchWord === "") {
    //   setFilteredCharts(charts);
    // } else {
    //   let filteredCharts = chartsCopy.filter(
    //     (chart) => {
    //       // chart.name.indexOf(searchWord) >= 0
    //       // console.log("chart.name:", chart.name)
    //       // return chart.name.includes(searchWord)
    //       return chart.name.indexOf(searchWord) >= 0

    //     }

    //   );
    //   setFilteredCharts(filteredCharts);
    // }
  }, [searchWord])

  const getPage = (data, pageNum, pageSize) => {
    const offset = pageNum * pageSize
    return data.slice(offset, offset + pageSize)
  }

  const getTotalPages = (data, pageSize) => {
    return Math.ceil(data.length / pageSize) - 1;
  }
  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {

    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex, prevPageIndex) => {
    // console.log(
    //   `handleOnChange \n - newPageIndex: ${newPageIndex} \n - prevPageIndex: ${prevPageIndex}`
    // );
    setCurrentPageIndex(newPageIndex);
  };

  return (
    <div className="viewer-accept-container">
      <div className="viewer-accept-header">
        <div className="operation-search">
          <span style={{ width: "165px" }}>배송완료</span>
          <div>
            <img
              src={searchIcon}
              style={{
                width: "15px",
                position: "absolute",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              alt="search-icon-pharma"
            />
            <input
              type="text"
              value={searchWord}
              placeholder="검색"
              onChange={(e) => {
                setSearchWord(e.target.value)
                let chartsCopy = [...charts];

                if (e.target.value === "") {
                  setFilteredCharts(chartsCopy);
                } else {
                  let filteredCharts = chartsCopy.filter(
                    (chart) => {
                      return (
                        chart.name.includes(e.target.value) ||
                        chart.ssn.includes(e.target.value) ||
                        chart.phone.includes(e.target.value)
                      )
                    }

                  );
                  setCurrentPageIndex(0);
                  setFilteredCharts(filteredCharts);
                }
              }}
            ></input>
          </div>
        </div>

      </div>
      <div className="operation-table-header-container">
        <table className="operation-table-header">
          <th id="op-name">이름 (연락처)</th>
          <th id="op-time">희망진료시간</th>
          <th id="op-ssn">주민번호</th>
          <th id="op-type">환자타입</th>
          <th id="op-hospital">병원</th>
          {props.p2status === "joje_ing" || props.p2status === "joje_done" ? (
            <th id="op-pharma">약국</th>
          ) : (
            <></>
          )}
        </table>
      </div>
      <div className="viewer-accept-content-container">
        {getPage(filteredCharts, currentPageIndex, pageSize)
          .map((c) => {
            if (c.status === "deli_done") {
              return (<OperationCardNew patient={c} key={c.id} />)
            }
          })}
        <div>
          <Pagination
            currentPage={currentPageIndex}
            totalPages={totalPage}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </div>
  );
}
export default ChartViewerDeliDone;
