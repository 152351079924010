import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { sendAnalytics } from "utils/analytics";
import { updateChart } from "utils/data";
import { PRODUCTS } from "utils/products";
import getAvailTimes from "utils/scheduler";
import { getDoctorInfo } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { translateAnswer } from "utils/util";
import { getTimeObject } from "utils/util";
import { calcPrice4 } from "utils/util";
import { parseSurvey, slack2ops, won } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import * as mutations from "../../graphql/mutations";

export default function PhotoChartCardNew(props) {
  const [isDetail, setIsDetail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [ssn, setSSN] = useState("");

  const [cancelReason, setCancelReason] = useState("");
  const [answers, setAnswers] = useState([]);
  const [signedURL, setSignedURL] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [shampDeliveryNumber, setShampDeliveryNumber] = useState("");
  const [elapsedTime, setElapsedTime] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payMessage, setPayMessage] = useState("");
  const [message, setMessage] = useState("");
  const [payResult, setPayResult] = useState(null);
  const [type, setType] = useState("재진");
  const [doctor, setDoctor] = useState("doctor2");
  const [rtime, setRtime] = useState(0);
  const [rday, setRday] = useState("");
  const [rdate, setRdate] = useState(0);
  const [rmonth, setRmonth] = useState(0);
  const [reservationColor, setReservationColor] = useState("white");
  const [reservationTime, setReservationTime] = useState("");
  const [items, setItems] = useState([]);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [pill, setPill] = useState("propecia");
  const [minox, setMinox] = useState("minox");
  const [capil, setCapil] = useState(null);
  const [shamp, setShamp] = useState(null);
  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [pillMonth, setPillMonth] = useState();
  const [minoxMonth, setMinoxMonth] = useState();
  const [capilMonth, setCapilMonth] = useState(1);
  const [total, setTotal] = useState(0);
  const [frontURL, setFrontURL] = useState();
  const [centerURL, setCenterURL] = useState();

  const handleDeliveryReady = async (pid, deliveryNumber) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_done",
      status: "deli_done",
      ignoreStatus: "no",
      delivery_number: deliveryNumber,
      r: "n", //refresh
    };

    try {
      let updatedPatient = await API.graphql({
        query: mutations.updateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });
      if (updatedPatient) {
        // slack2ops("[pharmacy] 우편 등기번호입력 완료: " + deliveryNumber);
        // getData();
        // console.log("등기번호 입력 성공!: ", updatedPatient);
      }
    } catch (error) {
      alert("등기번호 입력중 에러발생. 다시 시도해 주세요: " + error);
    }
  };

  useEffect(() => {
    if (props.patient.reservation_time) {
      // console.log("p.reservation_time use effect!!");
      let rt = props.patient.reservation_time.split("/");
      setRmonth(parseInt(rt[0]));
      setRdate(parseInt(rt[1]));
      setRday(rt[2]);
      setRtime(parseInt(rt[3]));
    }
  }, [props.patient.reservation_time]);

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      if (p.survey_type && p.survey_type === "초진") {
        setType("초진");
      }
      if (p.survey_type && p.survey_type === "repeat") {
        setType("리필");
      }

      let a = props.patient.ans;
      setAnswers(JSON.parse(a));

      // let ans = parseSurvey(props.patient.ans, props.patient.survey_type, props.patient.survey_version);

      if (p.filekey) {
        const key = await Storage.get(p.filekey, {
          level: "protected",
          identityId: "ap-northeast-2:74dcc767-0894-4769-b167-249a11ad8cce",
        });
        setSignedURL(key);
      }

      // parse payresult
      if (p.payResult) {
        let r = JSON.parse(p.payResult);
        setPayResult(r);
        // let message = r.PCD_PAY_MSG || "";
        let amount = r.AMNT || "";

        setPayAmount(amount);
        // setPayMessage(message);
      }

      // set the elapsed time
      let min = Math.round(
        (new Date() - new Date(props.patient.createdAt.toString())) /
          (60 * 1000)
      );
      if (min < 60) {
        setElapsedTime(min + " 분");
      } else {
        let hour = Math.floor(min / 60);
        let remain = min % 60;
        setElapsedTime(hour + "시간" + remain + "분");
      }

      //set name, ssn, phone
      setName(props.patient.name);
      setSSN(props.patient.ssn);
      setPhone(props.patient.phone);

      // check if hairline or haircenter exists.
      //
      if (p.hairline_url) {
        // if (sv === "1.4") {
        // console.log("hairline url: ", p.hairline_url);
        let temp = p.hairline_url.split(":!:");
        let id = temp[0];
        // console.log("id: ", id);
        if (id === "") {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
        }

        let url = temp[1];
        if (temp.length === 1) {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
          url = temp[0];
        }
        const hairline_key = await Storage.get(url, {
          level: "protected",
          identityId: id,
        });
        setFrontURL(hairline_key);
      }
      if (p.haircenter_url) {
        let temp = p.haircenter_url.split(":!:");
        let id = temp[0];
        if (id === "") {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
        }

        let url = temp[1];
        if (temp.length === 1) {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
          url = temp[0];
        }

        const haircenter_key = await Storage.get(url, {
          level: "protected",
          identityId: id,
        });
        setCenterURL(haircenter_key);
      }

      // set the reservation time and logic
      if (p.reservation_time) {
        // set items for time slots
        setItems(getAvailTimes());

        let rt = p.reservation_time.split("/");
        setRmonth(parseInt(rt[0]));
        setRdate(parseInt(rt[1]));
        setRday(rt[2]);
        setRtime(parseInt(rt[3]));

        let year = new Date().getFullYear();
        let reservation_time = new Date(
          year,
          parseInt(rt[0]) - 1,
          parseInt(rt[1]),
          parseInt(rt[3])
        );
        setReservationTime(p.reservation_time);

        // set interval and check if buffer time is hit
        if (p.dstatus === "accept") {
          (function foo() {
            let now = new Date();
            let diff = reservation_time - now;
            // console.log("checking time diff: ", diff);
            // alert(diff);
            let bufferMin = 30;
            if (diff < 60000 * bufferMin) {
              // 30min
              setReservationColor("#00ff00");
              return;
            }
            setTimeout(foo, 60000);
          })();
        }
      }
    };
    asyncWrapper();
  }, []);

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      let pmonth, mmonth;

      setMinox(p.minox);
      setPill(p.pill);
      setCapil(p.capil);
      setShamp(p.shamp);
      setMonth(p.month);
      setPillMonth(p.month);
      setCapilMonth(p.capilMonth);
      setMinoxMonth(p.month);

      if (p.pillMonth) {
        pmonth = p.pillMonth;
        setPillMonth(p.pillMonth);
      }
      if (p.minoxMonth) {
        mmonth = p.minoxMonth;
        setMinoxMonth(p.minoxMonth);
      }

      let res = calcPrice4(
        p.pill,
        p.minox,
        pmonth,
        mmonth,
        p.shamp,
        p.capil,
        p.capilMonth
      );
      setPillPrice(res.pillPrice);
      setCapilPrice(res.capilPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);
      setDeliveryNumber(p.delivery_number);
    };
    asyncWrapper();
  }, []);

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  const handleUpdate = (time) => {
    setReservationTime(time);
    // props.onUpdateTime(time);
  };

  return (
    <>
      <div>
        <Card>
          <CardBody>
            {/* Default Row */}
            <Row>
              <Col md="2" style={{ paddingLeft: 20 }}>
                <img
                  alt="..."
                  className="rounded-circle"
                  src={require("assets/img/somebody.jpg")}
                  height="64"
                ></img>
              </Col>
              <Col
                onClick={() => {
                  setIsDetail(!isDetail);
                }}
                md="6"
                style={{
                  padding: "14px 4px 0px 0px",
                  textAlign: "left",
                }}
              >
                <div style={{ lineHeight: 1.2 }}>
                  {props.counter} <b>{props.patient.name}</b>(
                  {parsePhoneNumber(props.patient.phone)})[
                  {props.patient.survey_type}][{props.patient.doctor}]
                  <b>{props.patient.isPaid === "yes" ? "[결제완료]" : ""}</b>[
                  {props.patient.survey_version}]
                </div>
                <div style={{ fontSize: "16px" }}>
                  <label
                    style={{
                      border: "1px solid black",
                      padding: "4px",
                      backgroundColor: reservationColor,
                    }}
                  >
                    <b>
                      희망진료시간: {rmonth}월 {rdate}일 ({rday}) {rtime}:00-
                      {rtime + 1}:00
                    </b>
                  </label>
                </div>
                <div>{/* {props.patient.ans} */}</div>
                <br />
                <div>
                  {props.patient.survey_version === "1.6" && (
                    <table>
                      <tbody>
                        <tr>
                          <td>ID</td>{" "}
                          <td>
                            {
                              translateAnswer(
                                JSON.parse(props.patient.ans),
                                props.patient.survey_type,
                                props.patient.survey_version,
                                props.patient.kid
                              ).id
                            }
                          </td>
                        </tr>
                        {translateAnswer(
                          JSON.parse(props.patient.ans),
                          props.patient.survey_type,
                          props.patient.survey_version,
                          props.patient.kid
                        ).survey.map((q) => {
                          return (
                            <>
                              <tr>
                                <td>{q.q}</td>
                                <td>{q.a}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {/* {JSON.stringify(translateAnswer(JSON.parse(props.patient.ans), props.patient.survey_type, props.patient.survey_version, props.patient.kid))} */}
                </div>

                <TimeSlotModal
                  isOpen={isTimeSlotOpen}
                  items={items}
                  onClose={handleClose}
                  onUpdate={handleUpdate}
                  setIsTimeSlotOpen={setIsTimeSlotOpen}
                />
              </Col>

              <Col
                md="4"
                style={{
                  textAlign: "left",
                  marginLeft: "-8px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td>
                        <img src={frontURL} style={{ width: "80px" }} />
                      </td>
                      <td>
                        <img src={centerURL} style={{ width: "80px" }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            {isDetail && (
              <div style={{ paddingTop: 20, marginTop: "-0px" }}>
                <Row>
                  <Col md="6">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        padding: "14px",
                        minHeight: 180,
                      }}
                    >
                      <div>
                        <h5>
                          <b>환자 정보</b>
                          <Button
                            onClick={async () => {
                              if (
                                window.confirm(
                                  "정말로 " +
                                    props.patient.name +
                                    "님의 환자 정보를 수정 하시겠습니까?"
                                )
                              ) {
                                const patientDetails = {
                                  id: `user#${props.patient.kid}`,
                                  name: name,
                                  ssn: ssn,
                                  phone: phone,
                                  ignoreStatus: "refresh",
                                };

                                try {
                                  let updatedPatient = await API.graphql({
                                    query: mutations.updateMars,
                                    authMode: "AMAZON_COGNITO_USER_POOLS",
                                    variables: { input: patientDetails },
                                  });

                                  if (updatedPatient) {
                                    alert(
                                      props.patient.name +
                                        "님 환자정보 변경 완료!\n"
                                    );
                                    slack2ops(
                                      `[운영][${props.patient.name}] 환자정보 변경\n변경이름:${name} 변경번호:${phone}`
                                    );
                                  }
                                } catch (error) {
                                  alert(
                                    "에러발생 다시 시도해 주세요: " + error
                                  );
                                }
                              }
                            }}
                            className="btn-sm btn-primary"
                          >
                            환자정보 수정
                          </Button>
                        </h5>
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: "100px" }}>환자 타입:</td>
                            <td>
                              <b>{type}</b>
                              {type === "초진" &&
                                " (탈모 진료를 처음 받아보는 환자)"}
                              {type === "재진" &&
                                " (기존에 탈모약을 복용한 경험이 있는 환자)"}
                              {type === "리필" &&
                                " (기존에 마스에서 처방을 받았고 다시 리필을 받는환자)"}
                            </td>
                          </tr>
                          <tr>
                            <td>환자 이름: </td>
                            <td>
                              <input
                                type="text"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>주민 번호:</td>
                            <td>
                              <input
                                type="text"
                                value={ssn}
                                onChange={(e) => {
                                  setSSN(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>연락처:</td>
                            <td>
                              <input
                                type="text"
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <button
                                onClick={() => {
                                  setIsTimeSlotOpen(true);
                                }}
                              >
                                {reservationTime}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={async () => {
                                  if (
                                    window.confirm(
                                      "정말로 " +
                                        props.patient.name +
                                        "님의 희망진료시간을 바꾸시겠습니까?"
                                    )
                                  ) {
                                    const patientDetails = {
                                      id: props.patient.id,
                                      reservation_time: reservationTime,
                                      yeyak_time:
                                        getTimeObject(
                                          reservationTime
                                        ).toISOString(),
                                      status: "pending",
                                      dstatus: "pending",
                                      ignoreStatus: "refresh",
                                    };

                                    try {
                                      let updatedPatient = await API.graphql({
                                        query: mutations.updateMars,
                                        authMode: "AMAZON_COGNITO_USER_POOLS",
                                        variables: { input: patientDetails },
                                      });
                                      if (updatedPatient) {
                                        alert(
                                          props.patient.name +
                                            "님 진료희망시간 변경 완료!\n"
                                        );
                                        slack2ops(
                                          `[운영][${props.patient.name}] 희망진료시간 변경 완료: ${reservationTime}`
                                        );
                                      }
                                    } catch (error) {
                                      alert(
                                        "에러발생 다시 시도해 주세요: " +
                                          JSON.stringify(error)
                                      );
                                    }
                                  }
                                }}
                              >
                                진료시간 변경하기
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col md="6">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        padding: "14px",
                        paddingBottom: 0,
                        minHeight: 180,
                      }}
                    >
                      {props.patient.shamp && (
                        <div>
                          <h5>
                            <b>샴푸의 요정</b>
                          </h5>
                          <div>
                            샴푸이름: {PRODUCTS[props.patient.shamp].name}
                          </div>
                          <div>결제여부: {props.patient.isPaid}</div>
                          {props.patient.payResult && (
                            <div>
                              결제금액:{" "}
                              {won(JSON.parse(props.patient.payResult).AMNT)}원
                            </div>
                          )}
                          <div>
                            배송주소:{" "}
                            {`${props.patient.addr} ${props.patient.addr2} ${props.patient.zip}`}
                          </div>

                          <div>
                            <label>샴푸 등기번호</label>
                            <input
                              value={shampDeliveryNumber}
                              type="text"
                              onChange={(e) => {
                                setShampDeliveryNumber(e.target.value);
                              }}
                            ></input>
                            <Button
                              onClick={async () => {
                                if (shampDeliveryNumber === "") {
                                  alert("샴푸 등기번호 미입력!");
                                  return;
                                }
                                if (
                                  window.confirm(
                                    props.patient.name +
                                      "님 샴푸등기번호가 정확합니까?"
                                  )
                                ) {
                                  try {
                                    let res = await API.post(
                                      "marsrest",
                                      "/shamp-delivery",
                                      {
                                        body: {
                                          kid: props.patient.kid,
                                          addr: props.patient.address,
                                          name: props.patient.name,
                                          dn: shampDeliveryNumber,
                                        },
                                      }
                                    );
                                    if (res) {
                                      setShampDeliveryNumber("");
                                      console.log(res);
                                      alert(
                                        res.data.taskId +
                                          " : " +
                                          res.data.status
                                      );

                                      slack2ops(
                                        "[운영] 샴푸등기번호 보냄: " +
                                          props.patient.name +
                                          " [" +
                                          props.patient.kid +
                                          "] " +
                                          shampDeliveryNumber
                                      );
                                    }
                                  } catch (error) {
                                    alert(error);
                                  }
                                }
                              }}
                              style={{
                                width: "100%",
                                backgroundColor: "#444444",
                                margin: "4px 0px 0px 0px",
                              }}
                              className="btn-sm btn-primary"
                            >
                              샴푸 등기번호 입력완료
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        marginTop: "8px",
                        padding: "14px",
                        paddingBottom: 0,
                        minHeight: 180,
                      }}
                    >
                      {/* 진료 및 약 비용 */}
                      <div className="">
                        <table width={"100%"}>
                          <tr>
                            <td>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "8px",
                                }}
                              >
                                <b>청구 내역</b>
                              </div>
                            </td>
                            <td className="text-right">
                              <div
                                style={{
                                  color: "#6A43B8",
                                  fontSize: "16px",
                                  marginBottom: "8px",
                                }}
                              >
                                <b>총 {won(total)}원</b>
                              </div>
                            </td>
                          </tr>
                        </table>

                        <table width={"100%"}>
                          <tbody>
                            {pill !== "아니요" && (
                              <tr>
                                <td></td>
                                <td>
                                  {PRODUCTS[pill].name} (
                                  {pillMonth * PRODUCTS[pill].base}{" "}
                                  {PRODUCTS[pill].unit}){/* {pill} */}
                                </td>
                                <td
                                  style={{ paddingLeft: 8, textAlign: "right" }}
                                >
                                  {won(pillPrice)}원
                                </td>
                              </tr>
                            )}

                            {minox && minox !== "아니요" && (
                              <tr>
                                <td></td>
                                <td>
                                  {PRODUCTS[minox].name} (
                                  {minoxMonth * PRODUCTS[minox].base}{" "}
                                  {PRODUCTS[minox].unit}){/* {minox} */}
                                </td>
                                <td
                                  style={{ paddingLeft: 8, textAlign: "right" }}
                                >
                                  {won(minoxPrice)}원
                                </td>
                              </tr>
                            )}

                            {shamp && (
                              <tr>
                                <td></td>
                                <td>
                                  {PRODUCTS[shamp].name} ({PRODUCTS[shamp].base}{" "}
                                  {PRODUCTS[shamp].unit}){/* {pill} */}
                                </td>
                                <td
                                  style={{ paddingLeft: 8, textAlign: "right" }}
                                >
                                  {won(shampPrice)}원
                                </td>
                              </tr>
                            )}

                            {capil && (
                              <tr>
                                <td></td>
                                <td>
                                  {PRODUCTS[capil].name} (
                                  {capilMonth * PRODUCTS[capil].base}{" "}
                                  {PRODUCTS[capil].unit}){/* {pill} */}
                                </td>
                                <td
                                  style={{ paddingLeft: 8, textAlign: "right" }}
                                >
                                  {won(capilPrice)}원
                                </td>
                              </tr>
                            )}

                            <tr>
                              <td></td>
                              <td>진료비</td>
                              <td
                                style={{ paddingLeft: 8, textAlign: "right" }}
                              >
                                10,000원
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>배송비</td>
                              <td
                                style={{ paddingLeft: 8, textAlign: "right" }}
                              >
                                2,900원
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <b>[실제 페이플 결제내역]</b>
                        {payResult && (
                          <div>
                            <div>{won(payResult.AMNT)}원 결제 완료</div>
                            <div>
                              {payResult.CNAME}: {payResult.CNUM}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      style={{
                        border: "1px solid #ececec",
                        marginTop: "8px",
                        padding: "14px",
                        paddingBottom: 0,
                        minHeight: 180,
                      }}
                    >
                      {/* <Button
                        className="btn-primary"
                        onClick={async () => {
                          let path = "/agreement/" + props.patient.kid;

                          let res = await API.get("marsrest", path);
                          alert(JSON.stringify(res));
                        }}
                      >
                        동의여부 확인
                      </Button> */}

                      <Button
                        className="btn-primary"
                        onClick={async () => {
                          let path = "/chanel-status/" + props.patient.kid;

                          let res = await API.get("marsrest", path);
                          console.log(JSON.stringify(res.data));
                          alert(res.data.channels[0].relation);
                        }}
                      >
                        채널 추가여부
                      </Button>

                      <Button
                        className="btn-danger"
                        onClick={async () => {
                          if (
                            window.confirm(
                              "정말로 " +
                                props.patient.name +
                                "님의 동의링크를 끊으시겠습니까?"
                            )
                          ) {
                            let path = "/unlink/" + props.patient.kid;
                            let res = await API.get("marsrest", path);
                            alert(JSON.stringify(res));
                          }
                        }}
                      >
                        동의링크 끊기!
                      </Button>

                      <div>
                        <textarea
                          rows={8}
                          cols={40}
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        ></textarea>
                        <Button
                          onClick={async () => {
                            if (
                              !window.confirm(
                                props.patient.name +
                                  "님에게 정말로 메시지를 보내시겠습니까?"
                              )
                            ) {
                              return;
                            }
                            try {
                              let users = [
                                { type: "appUserId", id: props.patient.kid },
                              ];
                              let res = await API.post("marsrest", "/message", {
                                body: {
                                  users: users,
                                  message: message,
                                },
                              });
                              if (res) {
                                slack2ops(
                                  `[운영][${props.patient.name}] 카톡메시지 전송\n${message}`
                                );
                                setMessage("");
                                alert(
                                  res.data.taskId + " : " + res.data.status
                                );
                              }
                            } catch (error) {
                              alert(error);
                            }
                          }}
                        >
                          카톡메시지 보내기
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={async () => {
                            if (
                              !window.confirm(
                                props.patient.name +
                                  "님에게 '리필' 메시지를 보내겠습니까?"
                              )
                            ) {
                              return;
                            }
                            try {
                              let res = await API.post("marsrest", "/repeat", {
                                body: {
                                  kid: props.patient.kid,
                                  message: "리필메시지",
                                  name: props.patient.name,
                                },
                              });
                              if (res) {
                                setMessage("");
                                alert(
                                  res.data.taskId + " : " + res.data.status
                                );

                                slack2ops(
                                  "[운영] 리필메시지 보냄: " +
                                    props.patient.name +
                                    " [" +
                                    props.patient.kid +
                                    "]"
                                );
                              }
                            } catch (error) {
                              alert(error);
                            }
                          }}
                        >
                          리필메시지 보내기
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <Button
                        onClick={async () => {
                          const patientDetails = {
                            id: props.patient.id,
                          };

                          if (
                            window.confirm(
                              "!!!! 테스트 데이타만 지우세요 !!!!\n" +
                                props.patient.name +
                                "님 을 DB에서 삭제 하시겠습니까?"
                            )
                          ) {
                            try {
                              const deletedChart = await API.graphql({
                                query: mutations.deleteMars,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                variables: { input: patientDetails },
                              });
                              if (deletedChart) {
                                sendAnalytics({
                                  type: "ops",
                                  kid: props.patient.kid,
                                  from: "operation",
                                  event: `delete.chart`,
                                  msg: `${props.patient.name} 차트DB 삭제 완료`,
                                });
                                slack2ops(
                                  `${props.patient.name}님 차트가 DB에서 삭제 되었습니다.`
                                );

                                if (props.patient.nesh_id) {
                                  const deletedNesh = await API.graphql({
                                    query: mutations.deleteMars,
                                    authMode: "AMAZON_COGNITO_USER_POOLS",
                                    variables: {
                                      input: { id: props.patient.nesh_id },
                                    },
                                  });
                                  if (deletedNesh) {
                                    slack2ops(
                                      `${props.patient.name}님 NESH도 DB에서 삭제 되었습니다.`
                                    );
                                  }
                                }

                                alert(props.patient.name + "님 삭제완료!");
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }
                        }}
                      >
                        환자삭제 (테스트 데이타용)!
                      </Button>
                    </div>

                    {/* 환자취소 */}
                    {props.patient.dstatus !== "cancel" && (
                      <div>
                        <Button
                          className="btn-primary"
                          onClick={async () => {
                            const patientDetails = {
                              id: props.patient.id,
                              status: "cancel",
                              dstatus: "cancel",
                            };
                            if (
                              window.confirm(
                                props.patient.name +
                                  "님 접수취소 하시겠습니까??"
                              )
                            ) {
                              try {
                                const updatedPatient = await API.graphql({
                                  query: mutations.updateMars,
                                  authMode: "AMAZON_COGNITO_USER_POOLS",
                                  variables: { input: patientDetails },
                                });
                                if (updatedPatient) {
                                  slack2ops(
                                    `[운영][${props.patient.name}] 접수취소`
                                  );
                                  alert(
                                    props.patient.name + "님 접수취소 완료!"
                                  );
                                }
                              } catch (error) {
                                alert(error);
                                //console.log(error);
                              }
                            }
                          }}
                        >
                          <b>'{props.patient.name}'</b>님 접수취소
                        </Button>
                      </div>
                    )}

                    {props.patient.dstatus === "cancel" && (
                      <div>
                        <Button
                          className="btn-primary"
                          onClick={async () => {
                            const patientDetails = {
                              id: props.patient.id,
                              status: "accept",
                              dstatus: "accept",
                            };
                            if (
                              window.confirm(
                                props.patient.name +
                                  "님 다시 접수 하시겠습니까?"
                              )
                            ) {
                              try {
                                const updatedPatient = await API.graphql({
                                  query: mutations.updateMars,
                                  authMode: "AMAZON_COGNITO_USER_POOLS",
                                  variables: { input: patientDetails },
                                });
                                if (updatedPatient) {
                                  slack2ops(
                                    `[운영][${props.patient.name}] 접수취소 완료`
                                  );
                                  alert(
                                    props.patient.name + "님 접수취소 완료!"
                                  );
                                }
                              } catch (error) {
                                alert(error);
                                //console.log(error);
                              }
                            }
                          }}
                        >
                          <b>'{props.patient.name}'</b>님 다시 접수
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
