import { __asyncGenerator, __await, __awaiter, __generator } from "tslib";
/**
 * A util function converting ReadableStream into an async iterable.
 * Reference: https://jakearchibald.com/2017/async-iterators-and-generators/#making-streams-iterate
 */
export var readableStreamtoIterable = function (readableStream) {
    var _a;
    return (_a = {},
        _a[Symbol.asyncIterator] = function () {
            return __asyncGenerator(this, arguments, function () {
                var reader, _a, done, value;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            reader = readableStream.getReader();
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, , 9, 10]);
                            _b.label = 2;
                        case 2:
                            if (!true) return [3 /*break*/, 8];
                            return [4 /*yield*/, __await(reader.read())];
                        case 3:
                            _a = _b.sent(), done = _a.done, value = _a.value;
                            if (!done) return [3 /*break*/, 5];
                            return [4 /*yield*/, __await(void 0)];
                        case 4: return [2 /*return*/, _b.sent()];
                        case 5: return [4 /*yield*/, __await(value)];
                        case 6: return [4 /*yield*/, _b.sent()];
                        case 7:
                            _b.sent();
                            return [3 /*break*/, 2];
                        case 8: return [3 /*break*/, 10];
                        case 9:
                            reader.releaseLock();
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        },
        _a);
};
/**
 * A util function converting async iterable to a ReadableStream.
 */
export var iterableToReadableStream = function (asyncIterable) {
    var iterator = asyncIterable[Symbol.asyncIterator]();
    return new ReadableStream({
        pull: function (controller) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, done, value;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, iterator.next()];
                        case 1:
                            _a = _b.sent(), done = _a.done, value = _a.value;
                            if (done) {
                                return [2 /*return*/, controller.close()];
                            }
                            controller.enqueue(value);
                            return [2 /*return*/];
                    }
                });
            });
        },
    });
};
