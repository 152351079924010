import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { sendAnalytics } from 'utils/analytics';
import { slack } from "utils/util";
import MonthPriceCard from "../components/MonthPriceCard";
import ShampPriceCard from '../components/ShampPriceCard';
import SurveyBtnSm from "../components/SurveyBtnSm";
import SurveyNextBtn from "../components/SurveyNextBtn";
import { loadAnswerFromLocalStorage, saveAnswerToLocalStorage } from "../utils/LocalStorage";

function FVShampPrice(props) {
	const history = useHistory();
	const qnum = 3;
	const qid = "fv_shamp_price";
	const q = "샴푸 가격";
	const [answer, setAnswer] = useState({ "qid": qid, "q": q, "qnum": qnum, "ans": [""], btnState: [true, false] });
	const [month, setMonth] = useState(1);
	const [canGoNext, setCanGoNext] = useState(false);

	useEffect(() => {
		document.title = q;
		// var dataLayer = window.dataLayer = window.dataLayer || [];
		// dataLayer.push({
		// 	'event': 'pageview',
		// 	'page_location': window.location.pathname,
		// 	'page_title': q
		// });

		sendAnalytics({ "type": "log", "survey_version":props.surveyVersion, "kid": props.kid, "from": qid, "event": `visit.${qid}`, "msg": `${q} 방문` });


		// load saved answers and populate
		let ans = loadAnswerFromLocalStorage(qid);
		if (ans) {
			setAnswer(ans);
			setMonth(ans.ans[0]);
			setCanGoNext(true);
		} else { //save default 3month value
			saveAns(3, [true, false]);
		}

	}, []);

	const saveAns = (ans, status) => {
		setMonth(ans);
		let answer = { qid, q, qnum, ans: [ans], btnState: status };
		setAnswer(answer);
		saveAnswerToLocalStorage(qid, answer);
		props.selectMonth(ans);
		setCanGoNext(true);
	}

	return (
		<>
			{/* questions */}
			<div className="">
				<div className="question-title" style={{ paddingBottom: "6px" }}>Mars x nesh 샴푸만 구매시 진료 없이 바로 구매 가능합니다.</div>
				{/* <div className="question-explain">바르는 약(미녹시딜 계열)은 특히 정수리 탈모에 효과적이에요. 폼 타입은 바르기 편하고, 스프레이 타입은 가성비가 좋아요.</div> */}
				{/* <div className="question-explain orange">해당 처방은 의사 통화진료 시 <b>변경 가능</b>해요.</div> */}
			</div>
			{/* card expected price */}
			<div style={{ paddingTop: "20px" }}>
				<ShampPriceCard pill={props.pill} minox={props.minox} month={month} shamp={props.shamp} />
			</div>

			
			{/* next button */}
			<SurveyNextBtn disabled={!canGoNext} onClick={() => {
				// gtag analytics
				let detail = {'kid': props.kid, 'survey_version': props.surveyVersion, 'ans': answer.ans[0] };
				window.gtag('event', 'click_next_' + qid, detail);
				
				slack("[survey1.5][" + props.kid + "] " + answer.ans[0] + "개월 선택");
				props.changeProgress(props.val + 1, 0);
				history.push(`/${window.location.pathname.split("/")[1]}/fv-shamp-reservation`);
				
			}}>다음</SurveyNextBtn>
		</>
	)
}
export default FVShampPrice;