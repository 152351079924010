import React from "react";
// reactstrap components
import { NavbarBrand, Navbar, Container } from "reactstrap";

function MarsNavbarWithLine() {
  return (
    <>
      <Navbar
        // className="fixed-top bg-white"
        style={{
          height: "52px",
          width: "100%",
          borderBottom: "0.5px solid black",
          // marginBottom: "-55px",
          position: "fixed",
          zIndex: 999,
          background: '#F7F7F7',
        }}
      >
        <Container
        // style={{ borderBottom: "0.5px solid black", height: "100%"}}
        >
          {/*           
          <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
          </NavbarBrand>
          */}

          <div className="">
            <table
              width="100%"
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <tr>
                <td>
                  <img
                    height="20px"
                    alt="..."
                    src={require("assets/img/mars_logo_black.png")}
                  ></img>
                </td>
              </tr>
            </table>
          </div>

          {/* <div className="" style={{ margin: "0 auto" }}>
            <img height="28px" alt="..." src={require("assets/img/mars_logo_black.png")}></img>
          </div> */}
        </Container>
      </Navbar>
    </>
  );
}

export default MarsNavbarWithLine;
