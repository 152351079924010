import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import marsHeader from "../../../assets/img/shampooheader.png";
import biotinHeader from "../../../assets/img/biotinHeader.jpg";
import XSymbol from "./XSymbol.png";

// css
import "./surveyComponentStyles.css";

// shampoo = or image
// open
// setopen
export default function ReusableModal(props) {
  const toggle = () => props.setOpen(!props.open);

  return (
    <Modal
      className="reusable-modal-modal"
      style={{ position: "fixed" }}
      isOpen={props.open}
      toggle={toggle}
    >
      <div className="modal-content rounded-0">
        <ModalHeader
          className="reusable-modal-header"
          close={
            <button onClick={toggle}>
              <img style={{ width: "20px" }} src={XSymbol} />
            </button>
          }
          toggle={toggle}
          style={{ background: "transparent" }}
        >
          {props.shampoo !== "capillus" && props.shampoo !== "biotin" ? (
            <img src={marsHeader} />
          ) : (
            <></>
          )}
          {props.shampoo === "biotin" ? <img src={biotinHeader}></img> : <></>}
        </ModalHeader>
        <ModalBody className="reusable-modal-content">
          <img
            id="shampoo-modal-info"
            src={require(`../../../assets/img/${props.shampoo}New.jpg`)}
          />
        </ModalBody>
      </div>
    </Modal>
  );
}
