import { API } from "aws-amplify";
import MarsNavbar from "components/Navbars/MarsNavbar";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PRODUCTS } from "utils/products";
import { slack2ops } from "utils/util";
import { slack } from "utils/util";
import PersonalInfoPolicy from "views/agreements/PersonalInfoPolicy";
import ServiceAgreement from "views/agreements/ServiceAgreement";
import * as mutations from "../../../graphql/mutations";
import SurveyDoneButton from "./SurveyDoneButton";
import {
  Col,
  Row,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Button,
} from "reactstrap";
import { getTimeObject } from "utils/util";
import { nanoid } from "nanoid";
import { getUser } from "utils/data";
import { updateUser } from "utils/data";

import MarsLoaderNew from "views/components/MarsLoaderNew";

// const {
//   Col,
//   Row,
//   Container,
//   FormGroup,
//   Input,
//   Label,
//   Modal,
//   Button,
// } = require("reactstrap");

function MyInfoRepeat(props) {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [name, setName] = useState("");
  const [ssn, setSSN] = useState("");
  const [realSSN, setRealSSN] = useState([]);
  const [phone, setPhone] = useState("");
  const [modalLive, setModalLive] = useState(false);
  const [type, setType] = useState("service");
  const [canGoNext, setCanGoNext] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isSSNValid, setIsSSNValid] = useState(true);
  const [isServiceAgreed, setIsServiceAgreed] = useState(true);
  const [isPersonAgreed, setIsPersonAgreed] = useState(true);

  const [canDelete, setCanDelete] = useState(false);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  function calculateWorkingHours() {
    let date = new Date();
    let day = date.getDay(); // 0 = sunday
    let hour = date.getHours();
    let wh = "open";
    // if day 0 sunday => closed
    if (day === 0) {
      wh = "closed";
    }
    // if day 6 saturday, if hours >13 => closed
    if (day === 6 && hour > 13) {
      wh = "closed";
    }

    // if hours >18 ~ 24 => closed
    if (hour >= 18 && hour <= 24) {
      wh = "closed";
    }
    // if hours 1 ~ 7 = > closed
    if (hour >= 1 && hour <= 7) {
      wh = "closed";
    }
    return wh;
  }

  const submit = async (answers, name, realSSN, phone) => {
    await delay(3000);
    //generate answer + info
    let ans_info = answers.slice();
    // ans_info.name = name;
    // ans_info.ssn = realSSN.join('');
    // ans_info.phone = phone;
    let sid = `nesh#${location.state.kid}#${nanoid(6)}`;
    let chartID = `c#${location.state.kid}#${nanoid(6)}`;
    // graphql api to save ans_info
    const patientDetails = {
      id: chartID,
      // nesh_id: location.state.shamp ? sid : null,
      type: "chart",
      // name: ans_info.name || "",
      // phone: ans_info.phone || "",
      // ssn: ans_info.ssn || "",
      ans: JSON.stringify(answers),
      dstatus: "pending",
      status: "pending",
      ignoreStatus: "no",
      pstatus: "0",
      isPaid: "0",
      kid: location.state.kid,
      survey_type: location.state.type,
      pill: "",
      minox: "",
      month: "",
      total: "",
      reservation_time: location.state.time,
      yeyak_time: location.state.time,
      survey_version: "1.4",
      doctor: "",
      shamp: location.state.shamp,
      extraInfo: JSON.stringify(location.state.extraInfo),
    };

    try {
      const newPatient = await API.graphql({
        query: mutations.createMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });
      if (newPatient) {
        //testing
        // console.log("new patient info");
        // console.log(newPatient);
        // update user
        let user = await getUser(`user#${location.state.kid}`);
        let userCharts = [];
        if (user.charts) {
          // console.log("there's user charts already exists");
          userCharts = JSON.parse(user.charts);
        }
        userCharts.unshift({
          id: chartID,
          stat: "ing",
          yt: location.state.time
        });
        // check if user info beside kid exists. if not update
        //
        let userInfo = {
          id: user.id,
          charts: JSON.stringify(userCharts),
        };
        let updatedUser = await updateUser(userInfo);

        // if (location.state.shamp) {
        //   const neshDetails = {
        //     id: sid,
        //     cid: chartID,
        //     ignoreStatus: "yes",
        //     type: "nesh",
        //     name: name,
        //     phone: phone,
        //     kid: location.state.kid,
        //     status: "accept",
        //     shamp: location.state.shamp,
        //   };

        //   try {
        //     const newNesh = await API.graphql({
        //       query: mutations.createMars,
        //       authMode: "AMAZON_COGNITO_USER_POOLS",
        //       variables: { input: neshDetails },
        //     });
        //     if (newNesh) {
        //       slack2ops(
        //         `[repeat-shamp][${props.kid}][${name}] ${
        //           PRODUCTS[props.shamp].name
        //         } 샴푸신청 완료 🎉`
        //       );
        //     }
        //   } catch (error) {
        //     console.log(error);

        //     slack(`[repeat-shamp][error] ${JSON.stringify(error)}`);
        //   }
        // }

        slack("[survey/myinfo-repeat] '접수완료' 버튼 클릭!");

        history.push({
          pathname: "/status2",
          state: ans_info,
        });
      }
    } catch (error) {
      alert("죄송합니다. 에러가 발생했습니다. 다시 시도해 주세요!");
      console.log(error);
      slack("[survey/myinfo] 접수에러: ", JSON.stringify(error));
    }
  };

  useEffect(() => {
    let ans = location.state;

    setAnswers(ans);
    // setName(ans.name);
    // setSSN(ans.ssn);
    // setRealSSN(ans.ssn.split(''));
    // setPhone(ans.phone);
    // submit(ans, ans.name, ans.ssn.split(''), ans.phone);
    submit(ans, ans.name, null, ans.phone);
  }, []);

  useEffect(() => {
    if (
      isNameValid &&
      isPhoneValid &&
      isSSNValid &&
      isServiceAgreed &&
      isPersonAgreed
    ) {
      setCanGoNext(true);
    } else {
      setCanGoNext(false);
    }
  }, [isNameValid, isPhoneValid, isSSNValid, isServiceAgreed, isPersonAgreed]);

  return (
    <>
      <MarsLoaderNew />
      {/* <div style={{ paddingTop: "14px" }}>
        <MarsNavbar />
        <Container>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto" md="5" xs="12">
              <div style={{ paddingTop: "40px" }}>
                <h4>
                  <b>나의정보 입력</b>
                </h4>
              </div>
              <div style={{ marginTop: "-12px" }}>
                진료접수를 위해 아래 정보가 필요합니다.
              </div>

              <div>
                <FormGroup style={{ paddingTop: "8px" }}>
                  <Input
                    value={name}
                    placeholder="이름"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value.length > 1) {
                        setIsNameValid(true);
                      }
                    }}
                  ></Input>
                </FormGroup>

                <FormGroup>
                  <Input
                    maxLength={14}
                    value={ssn}
                    placeholder="주민번호  ' - ' 없이 숫자만 입력"
                    type="tel"
                    required
                    onChange={(e) => {
                      let num = e.target.value;

                      if (num.length === 6 && !canDelete) {
                        e.target.value += "-";
                        let ssnArray = realSSN.slice();

                        ssnArray[num.length - 1] =
                          e.target.value.split("")[num.length - 1];
                        ssnArray[num.length] = "-";

                        setRealSSN(ssnArray);
                        setSSN(e.target.value);
                        setCanDelete(true);
                        return;
                      }

                      if (num.length < 6) {
                        setCanDelete(false);
                      }

                      let idx = num.indexOf("-");
                      // console.log(idx);
                      if (num.length === 7 && num.indexOf("-") === -1) {
                        let b = "-";
                        let position = 6;
                        let output = [
                          num.slice(0, position),
                          b,
                          num.slice(position),
                        ].join("");
                        // console.log("output;", output);
                        e.target.value = output;
                      }

                      num = e.target.value;
                      let arr = num.split("");

                      // console.log("Arr:", arr);
                      let ssnArray = realSSN.slice();

                      // if (num.length > realSSN.length) {
                      let val = e.target.value.split("").pop();
                      // console.log("val:", val)
                      if (val !== "*") {
                        ssnArray[num.length - 1] = val;
                      }

                      setRealSSN(ssnArray);

                      if (num.length > 8) {
                        let ar = e.target.value.split("");
                        ar[ar.length - 2] = "*";
                        e.target.value = ar.join("");
                      }

                      if (num.length === 14) {
                        let ar = e.target.value.split("");
                        ar[ar.length - 1] = "*";
                        e.target.value = ar.join("");
                      }

                      setSSN(e.target.value);
                      if (e.target.value.length > 12) {
                        setIsSSNValid(true);
                      }
                    }}
                  ></Input>
                </FormGroup>

                <FormGroup>
                  <Input
                    maxLength={11}
                    value={phone}
                    placeholder="연락처  ' - ' 없이 숫자만 입력"
                    type="tel"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                      if (e.target.value.length > 9) {
                        setIsPhoneValid(true);
                      }
                    }}
                  ></Input>
                </FormGroup>
              </div>

              <div style={{ marginBottom: "200px" }}>
                <h4>
                  <b>서비스 이용 약관</b>
                </h4>
                <div style={{ marginTop: "-12px" }}>
                  비대면 서비스 진료예약을 위해 자사정보 제공 및 이용약관에
                  동의해주세요.
                </div>

                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked
                              type="checkbox"
                              onChange={(e) => {
                                setIsServiceAgreed(e.target.checked);
                              }}
                            ></Input>
                            서비스 이용 동의
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </td>
                      <td style={{ textAlign: "left", paddingTop: "12px" }}>
                        <div
                          onClick={() => {
                            setType("service");
                            setModalLive(true);
                          }}
                        >
                          보기
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked
                              type="checkbox"
                              onChange={(e) => {
                                setIsPersonAgreed(e.target.checked);
                              }}
                            ></Input>
                            개인정보 수집 동의
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </td>
                      <td style={{ textAlign: "left", paddingTop: "12px" }}>
                        <div
                          onClick={() => {
                            setType("personal");
                            setModalLive(true);
                          }}
                        >
                          보기
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          <SurveyDoneButton disabled={!canGoNext} clickDone={clickDone}>
            접수 완료
          </SurveyDoneButton>
        </Container>
      </div>
      <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLiveLabel">
            {type === "service" && <div>서비스 이용 약관</div>}
            {type === "personal" && <div>개인정보 수집 약관</div>}
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLive(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {type === "service" && <ServiceAgreement></ServiceAgreement>}
          {type === "personal" && <PersonalInfoPolicy></PersonalInfoPolicy>}
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={() => setModalLive(false)}
          >
            닫기
          </Button>
        </div>
      </Modal> */}
    </>
  );
}

export default MyInfoRepeat;
