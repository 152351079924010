import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SurveyBuffer from "./SurveyBuffer";

// image assets
import logo from "../../assets/img/marsSvgLogo.svg";
import main from "./surveyAssets/imgs/introMain.png";

// css
import "./styling/surveyIntroStyles.css";

export default function SurveyIntro(props) {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [showBuffer, setShowBuffer] = useState();
  const { setShowIntro } = props;

  return (
    <>
      {!showBuffer ? (
        <div className="survey-intro-container">
          <div className="survey-intro-header">
            <img src={logo} alt="mars-logo" />
          </div>
          <div className="survey-intro-primary">
            <p id="survey-intro-title">통화진료 예약하기</p>
            <p id="survey-intro-desc">
              예상비용을 먼저 확인하고
              <br /> 진료 여부를 결정할 수 있어요!
            </p>
            <img id="survey-intro-img" src={main} alt="intro-main" />
          </div>
          <div className="survey-intro-secondary">
            <ol className="survey-intro-content-container">
              <li>
                <p id="intro-content-title">간편한 1분 문진</p>
                <p id="intro-content-subtitle">
                  탈모 증상 관련 문진 후 <br />
                  원하는 통화진료 시간을 선택해요
                </p>
              </li>
              <li>
                <p id="intro-content-title">의사와 통화진료</p>
                <p id="intro-content-subtitle">
                  예약한 시간에 담당의사가 전화를
                  <br /> 드리고, 통화진료를 진행해요
                </p>
              </li>
              <li>
                <p id="intro-content-title">집으로 약 배송</p>
                <p id="intro-content-subtitle">
                  처방내용을 확인하고 결제를 하면
                  <br />
                  약이 집으로 배송돼요
                </p>
              </li>
            </ol>
          </div>
          <div className="survey-intro-button-container">
            <button
              id="survey-intro-button"
              onClick={async () => {
                setShowBuffer(true);
                await delay(2000);
                setShowIntro(false);
              }}
            >
              시작하기
            </button>
          </div>
        </div>
      ) : (
        <SurveyBuffer></SurveyBuffer>
      )}
    </>
  );
}
