import { Container } from "reactstrap";
import pauseNoticeImg from "./pause_notice.png";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import MarsNavbar from "components/Navbars/MarsNavbar";
import SurveyNextBtn from "views/survey/components/SurveyNextBtn";

export default function PauseNoticePage(props) {
 return (
  <>
   <MarsNavbar />
   <div style={{ paddingTop: "60px", paddingLeft: "20px", paddingRight: "20px" }}>
    <img
     src={pauseNoticeImg}
     style={{
      width: "100%",
      height: "auto",
      paddingTop: "0px",
     }}
    />

    


   </div>
   <div style={{position:"absolute", bottom:"10", width:"100%", padding: "20px"}}>
     {/* <StatusButton style={buttonStyle} clickDone={clickDone}> */}
     <button
      id='faq-close'
      style={{ fontWeight: "400" }}
      onClick={() => {
       // clickDone();
       window.location.href = "kakaotalk://inappbrowser/close";
      }}
     >
      닫기
     </button>
     {/* </StatusButton> */}
    </div>

   {/* <SurveyNextBtn onClick={() => { console.log("닫기") }} white={true}>
    닫기
   </SurveyNextBtn> */}


  </>
 )
} 