import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import ProgressBar from "./ProgressBar";
import MarsLogo from "../../assets/img/mars_logo_black.png";
import SpaceLanding from "../../assets/lottie/spaceLanding.json";

// input timer
/**
 *
 * @param {message<String>} props
 *
 */
export default function MarsLoaderNew(props) {
  const { message } = props;
  // const timer = 4000; //4 seconds
  // const [loaded, setLoaded] = useState(false);
  // const savedCompleted = useRef();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    if (completed >= 101) {
      setCompleted(0);
    }
    const interval = setInterval(() => {
      setCompleted(completed + 100);
    }, 1000);

    return () => clearInterval(interval);
  }, [completed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        placeItems: "center",
        textAlign: "center",
      }}
    >
      {/* PUT LOADING BAR HERE */}
      <div
        id="mars-loader-progressbar"
        style={{ width: "100vw", borderRadius: "50px" }}
      >
        {/* <ProgressBar bgcolor={"#6A43B8"} completed={completed} /> */}
      </div>
      <Lottie
        animationData={SpaceLanding}
        id="mars-loader"
        style={{
          height: "auto",
          width: "150px",
          marginTop: "150px",
          marginBottom: "15px",
        }}
      ></Lottie>
      <p style={{ fontWeight: "400", fontSize: "15px" }}>
        2만명이 선택한
        <br /> 남성탈모 전문 비대면 진료 서비스
      </p>
      <p style={{ fontWeight: "600", fontSize: "15px" }}>맨프롬마스</p>
      <img
        style={{
          width: "60px",
          height: "auto",
          position: "absolute",
          top: "5px",
          marginTop: "15px",
        }}
        src={MarsLogo}
      />
    </div>
  );
}
