import { Button } from "reactstrap";
// const { Button } = require("reactstrap");

export default function StatusPaymentButton(props) {
  const { isSavedCardSelected, setPaymentPopup, paymentPopup } = props;
  return (
    <div
      style={{
        position: "fixed",
        bottom: '15px',
        display: "grid",
        placeItems: "center",
        marginLeft: "-27px",
        width: "100vw",
        padding: "0 27px 0 27px",
        zIndex:"9999"
      }}
    >
      <Button
        style={{
          width: "100%",
          height: "55px",
          background: "black",
          fontSize: "18px",
        }}
        disabled={paymentPopup}
        onClick={() => {
          props.onClick();
          // props.clickDone()
          // if (!isSavedCardSelected) {
          //   props.clickDone();
          //   //   console.log('saved card not selected')
          // } else {
          //   setPaymentPopup(true);
          //   //   console.log('saved card selected')
          // }
        }}
      >
        <label style={{ paddingTop: "6px" }}>
          <b>{props.children}</b>
        </label>
      </Button>
    </div>
  );
}
