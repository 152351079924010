import { API } from "aws-amplify";
import { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
// @new react transition group
import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition,
} from "react-transition-group";
import { motion, AnimatePresence } from "framer-motion";

// ***************************
import * as mutations from "../../graphql/mutations";
import { getENV, slack } from "utils/util";
import SurveyFrame from "./components/SurveyFrame";
import SurveyProgressBar from "./components/SurveyProgressBar";
import FVExpectation from "./FV/FVExpectation";
import FVMonth from "./FV/FVMonth";
import FVPhoto from "./FV/FVPhoto";
import FVQBaldness from "./FV/FVQBaldness";
import FVQHeredity from "./FV/FVQHeredity";
import FVQOtherCare from "./FV/FVQOtherCare";
import FVQScalpType from "./FV/FVQScalpType";
import FVQStress from "./FV/FVQStress";
import FVQWantCopy from "./FV/FVQWantCopy";
import FVQWantMedication from "./FV/FVQWantMedication";
import FVQWhenStart from "./FV/FVQWhenStart";
import FVReservation from "./FV/FVReservation";
import FVShampSubmit from "./FV/FVShampSubmit";
import FVShampPrice from "./FV/FVShampPrice";
import FVShampReservation from "./FV/FVShampReservation";
import RefreshComponent from "./RefreshComponent";
import SurveyQ0 from "./SurveyQ0";
import SVMonth from "./SV/SVMonth";
import SurveyIntro from "./SurveyIntro";
import SVQ1 from "./SV/SVQ1";
import SVQ2 from "./SV/SVQ2";
import SVReservation from "./SV/SVReservation";
import SVExtraSubmit from "./SVExtra/SVExtraSubmit";
import SVQOtherCares from "./SVExtra/SVQOtherCares";
import SVQPastHowLong from "./SVExtra/SVQPastHowLong";
import SVQPastPill from "./SVExtra/SVQPastPill";
import SVQScalpType from "./SVExtra/SVQScalpType";
import SVQShampoo from "./SVExtra/SVQShampoo";
import SVQSideEffects from "./SVExtra/SVQSideEffects";
import { saveAnswerToLocalStorage } from "./utils/LocalStorage";
import { sendAnalytics } from "utils/analytics";
import PopUpModal from "views/components/PopUpModal";
import SVPhoto from "./SV/SVPhoto";

// images
import hairlineHow from "./surveyAssets/imgs/hairlinePlaceholder.png";
import centerHow from "./surveyAssets/imgs/centerPlaceholder.png";

import $ from "jquery";
import SVExtraDone from "./SVExtra/SVExtraDone";
import { getUser } from "utils/data";
import { slack2ops } from "utils/util";

// imgs (holidays & events & popups)
import Lunar from "../../assets/img/lunNewYear.jpg";
import { insertMarsAnalytics } from "utils/data";
import { pushMarsAnalytics } from "utils/analytics";
import { createUser } from "utils/data";
import { createUserWithKid } from "utils/data";

function Survey(props) {
  const surveyPath = "survey";
  const env = getENV();
  const surveyVersion = "1.6";
  const location = useLocation();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // analytics
  const [analTimstamp, setAnalTimeStamp] = useState();

  const [kid, setKid] = useState("");
  const [uid, setUid] = useState("");
  const [surveyType, setSurveyType] = useState("");
  const [baldType, setBaldType] = useState("");
  const [wantMedicine, setWantMedicine] = useState("");
  const [wantCopy, setWantCopy] = useState("");

  // user photo store
  const [hairLineImage, setHairLineImage] = useState(hairlineHow);
  const [hairCenterImage, setHairCenterImage] = useState(centerHow);

  // fv
  const [isShampOnly, setIsShampOnly] = useState(false);

  // sv-extra
  const [scalpType, setScalpType] = useState("");
  const [shampoo, setShampoo] = useState("");

  const [pill, setPill] = useState("");
  const [minox, setMinox] = useState("");
  const [shamp, setShamp] = useState(null);
  const [month, setMonth] = useState(3);
  const [pillMonth, setPillMonth] = useState(3);
  const [minoxMonth, setMinoxMonth] = useState(3);

  const [hairLineURL, setHairLineURL] = useState("");
  const [hairCenterURL, setHairCenterURL] = useState("");
  const [hairGeneralURL, setHairGeneralURL] = useState("");

  // check for final page
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [max, setMax] = useState(11);
  const [val, setVal] = useState(0);

  const updateBasket = (pill, minox, shamp) => {
    setIsShampOnly(false);
    setPill(pill);
    setMinox(minox);
    setShamp(shamp);
    if (shamp && !minox && !pill) {
      setIsShampOnly(true);
    }
  };

  const updateSurveyType = (type) => {
    setSurveyType(type);
  };

  const changeProgress = (val, max) => {
    if (max !== 0) {
      setMax(max);
    }
    setVal(val);
  };

  const selectBaldType = (type) => {
    if (type === "c") {
      // 정수리 want_medication no 로 자동설정
      let answer = {
        qid: "fv_want_medication",
        q: "처방약?",
        qnum: "6",
        ans: ["아니요"],
        btnState: [false, true],
      };
      saveAnswerToLocalStorage("fv_want_medication", answer);
    }
    setBaldType(type);
  };
  const selectWantMedicine = (ans) => {
    setWantMedicine(ans);
  };
  const selectWantCopy = (ans) => {
    setWantCopy(ans);
  };
  const selectPill = (ans) => {
    setPill(ans);
  };
  const selectMinox = (ans) => {
    setMinox(ans);
  };
  const selectMonth = (pmonth, mmonth) => {
    setPillMonth(pmonth);
    setMinoxMonth(mmonth);
    setMonth(pmonth);
  };
  const selectScalpType = (ans) => {
    setScalpType(ans);
  };
  const selectShampoo = (ans) => {
    setShampoo(ans);
  };

  // update hair center and line url from child
  const updateHairLineURL = (url) => {
    setHairLineURL(uid + ":!:" + url);
  };
  const updateHairCenterURL = (url) => {
    setHairCenterURL(uid + ":!:" + url);
  };
  const updateHairGeneralURL = (url) => {
    setHairGeneralURL(uid + ":!:" + url);
  };

  useEffect(() => {
    // get current url path:
    // alert(window.location.pathname);
    let path = window.location.pathname;
    path = path.substring(1);
    if (!path.startsWith(surveyPath)) {
      path = surveyPath;
    }
    // alert(path);

    // check if loged-in if not redirect to klogin.
    //

    // location.kid = "12345"
    if (!location.kid) {
      history.push({
        pathname: "/klogin",
        // state: "survey2",
        state: path,
      });
    } else {
      const asyncWrapper = async () => {
        setKid(location.kid);
        setUid(location.uid);

        // let timestamp = pushMarsAnalytics(location.kid, 'survey_start', { from: location.pathname });
        // setAnalTimeStamp(timestamp);

        sendAnalytics({
          type: "log",
          from: "survey",
          kid: location.kid,
          event: "survey.start",
        });
        let user = await getUser(`user#${location.kid}`);
        if (!user) {
          await createUserWithKid(location.kid);
        } else {
          if (
            location.pathname === `/${surveyPath}/start` ||
            location.pathname === `/${surveyPath}/sv-past-pill`
          ) {
            return;
          }
          // check user status:
          if (user.charts) {
            let userCharts = JSON.parse(user.charts);
            if (userCharts.length > 0) {
              if (userCharts[0].stat === "deli_done") {
                slack2ops(
                  `[repeatV17][${user.kid}] 완료진료존재 리필페이지로 이동!`
                );
                history.push("/repeat3");
                return;
              }

              if (userCharts[0].stat === "ing") {
                alert(
                  "현재 진행중인 진료가 있어요!\n진료현황 페이지로 이동합니다."
                );
                slack2ops(
                  `[repeatV17][${user.kid}] 진행중진료있음 진료현황페이지로 이동`
                );
                history.push("/status");
                return;
              }

              if (userCharts[0].stat === "cancel") {
                for (let uc of userCharts) {
                  if (uc.stat === "deli_done") {
                    history.push(`/repeat3`);
                    return;
                  }
                }
                history.push(`/${surveyPath}/start`);
                return;
              }
            } else {
              history.push(`/${surveyPath}/start`);
            }
          } else {
            history.push(`/${surveyPath}/start`);
          }
        }
      };
      asyncWrapper();
    }

    //gtag
    // window.gtag('event', 'visit_' + surveyPath, { 'env': getENV(), 'kid': location.kid, 'survey_version': surveyVersion });

    window.addEventListener("popstate", (event) => {
      setVal((val) => {
        // gtag analytics
        let detail = {
          env: env,
          kid: location.kid,
          survey_version: surveyVersion,
          from: val,
        };
        window.gtag("event", "back", detail);

        changeProgress(val - 1, 0);
        return val;
      });
    });
  }, []);

  // Framer animation for later use
  // const FadeInOnRender = ({ children }) => {
  //   return (
  //     <motion.div
  //       initial="false"  // to stop animation
  //       // initial="hidden"
  //       whileInView="visible"
  //       viewport={{ once: true }}
  //       variants={{
  //         visible: { opacity: 1 },
  //         hidden: { opacity: 0 },

  //       }}
  //       transition={{delay: 0.5}}
  //       // animate={{ x: [500,  0] }} // This is for slide from right
  //       // transition={{ type: "spring", stiffness: 100 }}
  //     >
  //       {children}
  //     </motion.div>
  //   );
  // };

  return (
    <>
      {/* <PopUpModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      /> */}
      <div className="survey">
        {/* <SurveyProgressBar max={max} val={val} /> */}
        <SurveyFrame>
          {!isFinalPage && <SurveyProgressBar max={max} val={val} />}
          {/* <AnimatePresence mode='wait'>
            <FadeInOnRender> */}
          <Router location={location} key={location.pathname}>
            <Switch location={location.pathname}>
              <div className="survey-frame-container" >
                <Route exact path={`/${surveyPath}/start`}>
                  <SurveyQ0
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    kid={kid}
                  />
                  {/* <SurveyIntro></SurveyIntro> */}
                </Route>
                <Route path="/status">
                  <RefreshComponent />
                </Route>
                <Route path={`/${surveyPath}/svq0`}>
                  <SurveyQ0
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    kid={kid}
                  />
                </Route>
                {/* 재 */}
                <Route path={`/${surveyPath}/svq1`}>
                  <SVQ1
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/svq2`}>
                  <SVQ2
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectMinox={selectMinox}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-month`}>
                  <SVMonth
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    pill={pill}
                    minox={minox}
                    selectMonth={selectMonth}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                {/* <Route path={`/${surveyPath}/sv-month`}><SVMonth changeProgress={changeProgress} max={max} val={val} pill={pill} minox={minox} selectMonth={selectMonth} kid={kid} surveyVersion={surveyVersion} /></Route> */}
                <Route path={`/${surveyPath}/sv-photo`}>
                  <SVPhoto
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    updateHairLineURL={updateHairLineURL}
                    updateHairCenterURL={updateHairCenterURL}
                    updateHairGeneralURL={updateHairGeneralURL}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                    surveyVersion={surveyVersion}
                    hairLineImage={hairLineImage}
                    setHairLineImage={setHairLineImage}
                    hairCenterImage={hairCenterImage}
                    setHairCenterImage={setHairCenterImage}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-reservation`}>
                  <SVReservation
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    pill={pill}
                    minox={minox}
                    pillMonth={pillMonth}
                    minoxMonth={minoxMonth}
                    kid={kid}
                    surveyVersion={surveyVersion}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                  />
                </Route>

                {/* 초진 플로우 */}
                <Route path={`/${surveyPath}/fv-baldness`}>
                  <FVQBaldness
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectBaldType={selectBaldType}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-when-start`}>
                  <FVQWhenStart
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-other-care`}>
                  <FVQOtherCare
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-scalp-type`}>
                  <FVQScalpType
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectScalpType={selectScalpType}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-stress`}>
                  <FVQStress
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-heredity`}>
                  <FVQHeredity
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    expectation={baldType}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-want-medication`}>
                  <FVQWantMedication
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectWantMedicine={selectWantMedicine}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-want-copy`}>
                  <FVQWantCopy
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectWantCopy={selectWantCopy}
                    baldType={baldType}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-expectation`}>
                  <FVExpectation
                    kid={kid}
                    expectation={baldType + wantMedicine + wantCopy}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                    scalpType={scalpType}
                    updateBasket={updateBasket}
                    isShampOnly={isShampOnly}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-month`}>
                  <FVMonth
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    pill={pill}
                    minox={minox}
                    shamp={shamp}
                    selectMonth={selectMonth}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                {/* <Route path={`/${surveyPath}/fv-shamp-price`}>
                      <FVShampPrice
                        kid={kid}
                        changeProgress={changeProgress}
                        max={max}
                        val={val}
                        pill={pill}
                        minox={minox}
                        shamp={shamp}
                        selectMonth={selectMonth}
                        surveyVersion={surveyVersion}
                      />
                    </Route> */}
                <Route path={`/${surveyPath}/fv-photo`}>
                  <FVPhoto
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    updateHairLineURL={updateHairLineURL}
                    updateHairCenterURL={updateHairCenterURL}
                    updateHairGeneralURL={updateHairGeneralURL}
                    surveyVersion={surveyVersion}
                    hairLineImage={hairLineImage}
                    setHairLineImage={setHairLineImage}
                    hairCenterImage={hairCenterImage}
                    setHairCenterImage={setHairCenterImage}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-reservation`}>
                  <FVReservation
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    pill={pill}
                    minox={minox}
                    month={month}
                    shamp={shamp}
                    surveyVersion={surveyVersion}
                    expectation={baldType + wantMedicine + wantCopy}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                    hairGeneralURL={hairGeneralURL}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-shamp-reservation`}>
                  <FVShampReservation
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    month={month}
                    shamp={shamp}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/fv-shamp-submit`}>
                  <FVShampSubmit
                    setIsFinalPage={setIsFinalPage}
                    kid={kid}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                  />
                </Route>

                {/* 재진 추가질문 */}
                <Route path={`/${surveyPath}/sv-past-pill`}>
                  <SVQPastPill
                    changeProgress={changeProgress}
                    max={6}
                    val={1}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-past-how-long`}>
                  <SVQPastHowLong
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-side-effects`}>
                  <SVQSideEffects
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-scalp-type`}>
                  <SVQScalpType
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectScalpType={selectScalpType}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-other-cares`}>
                  <SVQOtherCares
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                {/* <Route path={`/${surveyPath}/sv-photo`}><SVPhoto kid={kid} changeProgress={changeProgress} max={max} val={val} updateHairLineURL={updateHairLineURL} updateHairCenterURL={updateHairCenterURL} updateHairGeneralURL={updateHairGeneralURL} surveyVersion={surveyVersion} /></Route> */}
                <Route path={`/${surveyPath}/sv-shampoo`}>
                  <SVQShampoo
                    kid={kid}
                    scalpType={scalpType}
                    selectShampoo={selectShampoo}
                    changeProgress={changeProgress}
                    max={max}
                    val={val}
                    surveyVersion={surveyVersion}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-extra-submit`}>
                  <SVExtraSubmit
                    setIsFinalPage={setIsFinalPage}
                    changeProgress={changeProgress}
                    shampoo={shampoo}
                    scalpType={scalpType}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                  />
                </Route>
                <Route path={`/${surveyPath}/sv-extra-done`}>
                  {/* <SVExtraDone
                    setIsFinalPage={setIsFinalPage}
                    changeProgress={changeProgress}
                    shampoo={shampoo}
                    scalpType={scalpType}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                  /> */}
                  <SVExtraSubmit
                    setIsFinalPage={setIsFinalPage}
                    changeProgress={changeProgress}
                    shampoo={shampoo}
                    scalpType={scalpType}
                    max={max}
                    val={val}
                    selectPill={selectPill}
                    kid={kid}
                    surveyVersion={surveyVersion}
                    hairLineURL={hairLineURL}
                    hairCenterURL={hairCenterURL}
                  />
                </Route>
              </div>
            </Switch>
          </Router>
          {/* </FadeInOnRender>
          </AnimatePresence> */}
        </SurveyFrame>
      </div>
    </>
  );
}
export default Survey;
