import { useHistory } from "react-router";
import { Card, CardBody } from "reactstrap";
import { slack } from "utils/util";
// import React, { useEffect, useState } from "react";

function MedicationCard(props) {
  const history = useHistory();


  // FOR FUTURE USE - FETCH MEDICATION DATA AS PROPS => SET NAME, DOSAGE, ETC. AS COMPONENT STATE

  //   const [displayName, setDisplayName] = useState('')
  //   const [dosage, setDosage] = useState('')

  //   const dosageComparer = [
  // 	{name:'propecia', dosage:'1mg', korean:'프로페시아'},
  // 	{name:'aloscan', dosage:'1mg', korean:'알로스칸'},
  // 	{name:'finaon', dosage:'1mg', korean:'피나온'},
  // 	{name:'avodart', dosage:'0.5mg', korean:'아보다트 연질갭슐'},
  // 	{name:'damodart', dosage:'0.5mg', korean:'다모다트 연질갭슐'},
  // 	{name:'dutaon', dosage:'0.5mg', korean:'두타온 연질갭슐'},
  // 	{name:'minox', dosage:'60g', korean:'로게인폼 5%'},
  // 	{name:'rogaine', dosage:'60ml', korean:'마이녹실 액 5%'},
  // 	{name:'capilus', dosage:' ', korean:'카피러스캡슐'},
  //   ]

//   useEffect(() => {
//     console.log(props.medication.name);
//     for (const [name, dosage, korean] of dosageComparer.entries()) {
//       if (props.medication.name === name) {
//         setDisplayName(korean);
//         setDosage(dosage);
//       }
//     }
//   }, [props]);
  return (
    <Card
      onClick={() => {
        slack(
          "[Medication] 복약정보 클릭: " + JSON.stringify(props.medication)
        );
        history.push("/medication-detail", props.medication.img2);
      }}
      // Changed medication page card styling
      style={{width: "159px", height: "189px", borderRadius: "14px", display: 'grid', placeItems:'center', filter:'none', boxShadow:'0px 0px 5px rgba(0, 0, 0, 0.25)'}}
    >
      <CardBody>
        <div style={{ width: "159px", height: "149px", position: 'relative', right: '12%'}}>
          <img
            alt="..."
            // src={require("assets/img/" + props.medication.img1)} - old image source
            src={
              require("./medication-images/" + "new-" + props.medication.img1)
            }
            style={{ width: "100%", height:'100%', }}
          ></img>
        </div>
      </CardBody>
    </Card>
  );
}

export default MedicationCard;
