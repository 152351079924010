import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { getChartsByKid } from "utils/data";
import { getChartByID } from "utils/data";
import { updateMars } from "utils/data";
import { PRODUCTS } from "utils/products";
import getAvailTimes from "utils/scheduler";
import { getDoctorInfo } from "utils/util";
import { formatDisplayDateTime } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { parseSurvey, slack2ops, won } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import * as mutations from "../../graphql/mutations";


function ShampooCard(props) {
  const [isDetail, setIsDetail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [ssn, setSSN] = useState("");
  const [chart, setChart] = useState();
  const [nesh, setNesh] = useState();

  const [shampDeliveryNumber, setShampDeliveryNumber] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payResult, setPayResult] = useState(null);
  const [reservationTime, setReservationTime] = useState("");
  const [items, setItems] = useState([]);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;

      setNesh(p);
      setStatus(p.status);
      // parse payresult
      if (p.payResult) {
        let r = JSON.parse(p.payResult);
        setPayResult(r);
        // let message = r.PCD_PAY_MSG || "";
        let amount = r.AMNT || "";

        setPayAmount(amount);
        // setPayMessage(message);
      }

      //set name, ssn, phone
      setName(props.patient.name);
      setPhone(props.patient.phone);

      // get chart info
      if (props.patient.cid) {
        const chart = await getChartByID(props.patient.cid);
        if (chart) {
          setChart(chart);
        }
      }




      // set delivery number.
      setShampDeliveryNumber(p.delivery_number);
    }

    asyncWrapper();
  }, []);

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  const handleUpdate = (time) => {
    setReservationTime(time);
    // props.onUpdateTime(time);
  };

  return (
    <>
      <div>
        <Card>
          <CardBody>
            {/* Default Row */}
            <Row>
              <Col
                onClick={() => {
                  setIsDetail(!isDetail);
                }}
                md="9"
                style={{
                  padding: "14px 4px 0px 0px",
                  textAlign: "left",
                }}
              >
                <div style={{ lineHeight: 1.2 }}>
                  <b>{props.patient.name}</b> ({parsePhoneNumber(phone)}) [ {PRODUCTS[props.patient.shamp].name} ]
                  <div>
                    {`${props.patient.addr} ${props.patient.addr2} ${props.patient.zip}`}
                  </div>
                </div>
              </Col>

              <Col
                md="3"
                style={{
                  textAlign: "left",
                  marginLeft: "-40px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                {props.patient.status === "shamp_paid" && (
                  <button onClick={async () => {
                    let neshInfo = {
                      id: nesh.id,
                      status: "shamp_order_ready",
                      ignoreStatus: "yes"
                    }
                    let res = await updateMars(neshInfo);
                  }}>
                    배송 예약하기
                  </button>
                )}

                {props.patient.status === "shamp_order_ready" && (
                  <button onClick={async () => {
                    let neshInfo = {
                      id: nesh.id,
                      status: "shamp_order_done",
                      ignoreStatus: "yes"
                    }
                    let res = await updateMars(neshInfo);
                  }}>
                    배송신청 완료
                  </button>
                )}

                {props.patient.status === "shamp_order_done" && (
                  <div>
                    <input
                      value={shampDeliveryNumber}
                      type="text"
                      onChange={(e) => {
                        setShampDeliveryNumber(e.target.value);
                      }}
                    ></input>
                    <Button
                      onClick={async () => {
                        if (shampDeliveryNumber === "") {
                          alert("샴푸 등기번호 미입력!");
                          return;
                        }
                        if (window.confirm(props.patient.name + "님 샴푸등기번호가 정확합니까?")) {
                          const neshInfo = {
                            id: props.patient.id,
                            status: "shamp_deli_done",
                            ignoreStatus: "no",
                            delivery_number: shampDeliveryNumber,
                          };
                          const res = await updateMars(neshInfo);
                        }
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "#444444",
                        margin: "4px 0px 0px 0px",
                      }}
                      className="btn-sm btn-primary"
                    >
                      샴푸 등기번호 입력완료
                    </Button>
                  </div>
                )}

{props.patient.status === "shamp_deli_done" && (
                  <div>
                    <label>운송번호: </label>
                    <input
                      value={shampDeliveryNumber}
                      type="text"
                      onChange={(e) => {
                        setShampDeliveryNumber(e.target.value);
                      }}
                    ></input>
                    {/* <Button
                      onClick={async () => {
                        if (shampDeliveryNumber === "") {
                          alert("샴푸 등기번호 미입력!");
                          return;
                        }
                        if (window.confirm(props.patient.name + "님 샴푸등기번호가 정확합니까?")) {
                          const neshInfo = {
                            id: props.patient.id,
                            status: "shamp_deli_done",
                            ignoreStatus: "no",
                            delivery_number: shampDeliveryNumber,
                          };
                          const res = await updateMars(neshInfo);
                        }
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "#444444",
                        margin: "4px 0px 0px 0px",
                      }}
                      className="btn-sm btn-primary"
                    >
                      샴푸 등기번호 입력완료
                    </Button> */}
                  </div>
                )}

                {/* <input type={'text'} value={status} onChange={(e) => { setStatus(e.target.value) }} ></input>
                <button onClick={async () => {
                  if (window.confirm("정말로 샴푸고객 상태를 변경 하시겠습니까?")) {
                    let neshInfo = {
                      id: nesh.id,
                      status: status
                    }
                    let res = await updateMars(neshInfo);
                  }
                }}>상태변경</button> */}

              </Col>

            </Row>

            {isDetail && (
              <div style={{ paddingTop: 20, marginTop: "-0px" }}>
                <Row>
                  <Col>
                    {chart && (
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td>진료시간 : </td><td>{formatDisplayDateTime(chart.reservation_time)}</td>
                            </tr>
                            <tr>
                              <td>진료종류 : </td><td>{chart.survey_type}</td>
                            </tr>
                            <tr>
                              <td>담당의사 : </td><td>{chart.doctor}</td>
                            </tr>
                            <tr>
                              <td>처방약 : </td><td>먹는약:{chart.pill}, 바르는약: {chart.minox}, 카필러스: {chart.capil}</td>
                            </tr>
                            {chart.isPaid === "yes" && (
                              <tr>
                                <td>결제날짜 :</td><td>{JSON.parse(chart.payResult).PTIME}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Col>
                </Row>

                {/* 샴푸의 요정 row */}
                {/* <Row>
                  <Col md="6">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        padding: "14px",
                        paddingBottom: 0,
                        minHeight: 180,
                      }}
                    >
                      {props.patient.shamp && (
                        <div>
                          <h5>
                            <b>샴푸의 요정</b>
                          </h5>
                          <div>kid: {props.patient.kid}</div>
                          <div>샴푸이름: {PRODUCTS[props.patient.shamp].name}</div>
                          <div>결제여부: {props.patient.isPaid}</div>
                          {props.patient.payResult && (<div>결제금액: {won(JSON.parse(props.patient.payResult).AMNT)}원</div>)}
                          <div>배송주소: {`${props.patient.addr} ${props.patient.addr2} ${props.patient.zip}`}</div>

                          <div>
                            <label>샴푸 등기번호</label>
                            <input
                              value={shampDeliveryNumber}
                              type="text"
                              onChange={(e) => {
                                setShampDeliveryNumber(e.target.value);
                              }}
                            ></input>
                            <Button
                              onClick={async () => {
                                if (shampDeliveryNumber === "") {
                                  alert("샴푸 등기번호 미입력!");
                                  return;
                                }
                                if (window.confirm(props.patient.name + "님 샴푸등기번호가 정확합니까?")) {
                                  const neshInfo = {
                                    id: props.patient.id,
                                    status: "shamp_sent",
                                    ignoreStatus: "no",
                                    delivery_number: shampDeliveryNumber,
                                  };
                                  const res = await updateMars(neshInfo);
                                }
                              }}
                              style={{
                                width: "100%",
                                backgroundColor: "#444444",
                                margin: "4px 0px 0px 0px",
                              }}
                              className="btn-sm btn-primary"
                            >
                              샴푸 등기번호 입력완료
                            </Button>
                          </div>

                        </div>
                      )}
                    </div>
                  </Col>
                </Row> */}


              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
export default ShampooCard;
