import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Input } from "reactstrap";
import { updateMarsUtils } from "utils/data";
import { getMarsUtils } from "utils/data";
import { insertMarsUtils } from "utils/data";
import getAvailTimes from "utils/scheduler";
import getAvailTimes2 from "utils/scheduler2";
import { getScheduleFromServer } from "utils/util";
import { formatDisplayDate } from "utils/util";
import { formatDisplayTime } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import { populateFVChart } from "views/survey/utils/PopulateChart";

function SchedulerPage() {

	const [reservationTime, setReservationTime] = useState("");
	const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
	const [items, setItems] = useState([]);
	const [doctor1TimeArray, setDoctor1TimeArray] = useState([]);
	const [doctor2TimeArray, setDoctor2TimeArray] = useState([]);
	const [gaonTimeArray, setGaonTimeArray] = useState([]);
	const [compiledArray, setCompiledArray] = useState([]);
	const [numDays, setNumDays] = useState(2);

	const history = useHistory();

	useEffect(() => {
		const asyncWrapper = async () => {
			let user = await Auth.currentUserInfo();
			// console.log("user:", user);
			if (["mars1"].indexOf(user.username) === -1) {
				alert("login with operator");
				let so = await Auth.signOut();
				return;
			}
		}
		asyncWrapper();
	}, [])

	const handleClose = () => {
		setIsTimeSlotOpen(false);
	};

	const handleUpdate = (time) => {
		setReservationTime(time);
		// slack("[survey1.4][" + props.kid + "] " + time + " 희망시간 선택");
	};
	const zeroPad = (num, places) => String(num).padStart(places, '0');

	const populateDoctor1 = () => {
		let today = new Date();
		let year = today.getFullYear();
		let month = zeroPad(today.getMonth() + 1, 2);
		let date = zeroPad(today.getDate(), 2);
		let startHour = 0;
		let doctor1Times = [];

		let start = new Date(`${year}-${month}-${date}T${zeroPad(startHour, 2)}:00`);

		for (let i = 0; i <= 24 * numDays; i++) {

			let d = new Date(start.setTime(start.getTime() + 60 * 60 * 1000));
			let avail = true;
			if (d.getDay() === 0 || d.getHours() <= 8 || d.getHours() > 17) {
				avail = false;
			}

			if (d.getHours() === 13) {
				avail = false;
			}

			// sataurday 13 and up should be unavail
			if (d.getDay() === 6 && d.getHours() >= 14) {
				avail = false;
			}
			doctor1Times.push({ time: d.toISOString(), isAvail: avail });
		}
		setDoctor1TimeArray(doctor1Times);
	}

	const populateDoctor2 = () => {
		let today = new Date();
		let year = today.getFullYear();
		let month = zeroPad(today.getMonth() + 1, 2);
		let date = zeroPad(today.getDate(), 2);
		let startHour = 0;
		let doctorTimes = [];

		let start = new Date(`${year}-${month}-${date}T${zeroPad(startHour, 2)}:00`);

		for (let i = 0; i <= 24 * numDays; i++) {

			let d = new Date(start.setTime(start.getTime() + 60 * 60 * 1000));
			let avail = true;
			if (d.getDay() === 0 || d.getHours() <= 8 || d.getHours() > 17) {
				avail = false;
			}

			// sataurday 13 and up should be unavail
			if (d.getDay() === 6 && d.getHours() >= 14) {
				avail = false;
			} //end of general

			//doctor2 boryoung 1시부터 2시 점심시간빔.
			if (d.getHours() === 13) {
				avail = false;
			}

			doctorTimes.push({ time: d.toISOString(), isAvail: avail });
		}
		setDoctor2TimeArray(doctorTimes);
	}

	const populateGaonsamsung = () => {
		let today = new Date();
		let year = today.getFullYear();
		let month = zeroPad(today.getMonth() + 1, 2);
		let date = zeroPad(today.getDate(), 2);
		let startHour = 0;
		let doctorTimes = [];

		let start = new Date(`${year}-${month}-${date}T${zeroPad(startHour, 2)}:00`);

		for (let i = 0; i <= 24 * numDays; i++) {

			let d = new Date(start.setTime(start.getTime() + 60 * 60 * 1000));
			let avail = true;
			if (d.getDay() === 0 || d.getHours() <= 8 || d.getHours() > 16) {
				avail = false;
			}

			// sataurday 13 and up should be unavail
			if (d.getDay() === 6 && d.getHours() >= 14) {
				avail = false;
			} //end of general

			//doctor2 boryoung 1시부터 2시 점심시간빔.
			if (d.getHours() === 13) {
				avail = false;
			}

			// gaonsaamsng 
			doctorTimes.push({ time: d.toISOString(), isAvail: avail });
		}
		setGaonTimeArray(doctorTimes);
	}

	return (
		<>
			scheduler page
			<div>
				{/* 희망 진료시간 */}
				<div>
					<TimeSlotModal
						isOpen={isTimeSlotOpen}
						items={items}
						onClose={handleClose}
						onUpdate={handleUpdate}
						setIsTimeSlotOpen={setIsTimeSlotOpen}
					/>

					<div className="reservation-heading">희망 진료시간</div>
					<div
						className="scheduller-btn"
						onClick={() => {

							setIsTimeSlotOpen(true);
						}}
					>
						<div>
							{reservationTime.split("/").length === 2
								? "선택하기"
								: reservationTime.split("/")[0] +
								"월" +
								reservationTime.split("/")[1] +
								"일 " +
								reservationTime.split("/")[2] +
								"요일 " +
								reservationTime.split("/")[3] +
								":00-" +
								(parseInt(reservationTime.split("/")[3]) + 1) +
								":00"}

							<div style={{ float: "right" }}>
								<img
									height="18"
									style={{ float: "right", marginRight: "12px" }}
									alt="..."
									src={require("assets/img/arrow_down.png")}
								></img>
							</div>
						</div>
					</div>
				</div>{" "}
				{/* 희망 진료시간 끝 */}
			</div>


			<div className="scrollable2" style={{ paddingBottom: "200px" }}>
				<div>

					<label>몇일분?</label>
					<input type="text" value={numDays}
						onChange={(e) => {
							setNumDays(e.target.value);
						}} />

					<button onClick={async () => {
						const res = await getMarsUtils("mars_doctor_schedules");

						let data;
						if (res) {
							data = res.data.getMarsUtil;
							let doctorSchedules = JSON.parse(data.value);
							console.log(doctorSchedules);
							setDoctor1TimeArray(doctorSchedules.doctor1);
							setDoctor2TimeArray(doctorSchedules.doctor2);
							setGaonTimeArray(doctorSchedules.gaonsamsung);
						}
					}}>load time from server</button>

					<button onClick={async () => {
						let schedule = await getScheduleFromServer();
						console.log(schedule);
						setItems(schedule);
						// test branch
					}}>Scheduler Test</button>


					<button onClick={() => {
						populateDoctor1();
						populateDoctor2();
						populateGaonsamsung();
					}}>populate time</button>

					<button onClick={() => {
						if (doctor1TimeArray.length !== doctor2TimeArray.length || doctor1TimeArray.length !== gaonTimeArray.length) {
							alert("슬랏 수가 일치하지 않습니다. 선민에게 문의하세요!!.");
							return;
						}

						let compiledTimeSlots = [];

						for (let i = 0; i < doctor1TimeArray.length; i++) {
							console.log(doctor1TimeArray[i].time, doctor2TimeArray[i].time, gaonTimeArray[i].time);

							let avail = false;
							if (doctor1TimeArray[i].isAvail || doctor2TimeArray[i].isAvail || gaonTimeArray[i].isAvail) {
								avail = true;
							}

							compiledTimeSlots.push({ time: doctor1TimeArray[i].time, isAvail: avail });
						}

						console.log("compiled: ", compiledTimeSlots);
						setCompiledArray(compiledTimeSlots);

						// getavailtimes2
						setItems(getAvailTimes2(compiledTimeSlots));

					}}>스케쥴 합체</button>

					<button onClick={async () => {
						if (doctor1TimeArray.length !== doctor2TimeArray.length || doctor1TimeArray.length !== gaonTimeArray.length) {
							alert("슬랏 수가 일치하지 않습니다. 선민에게 문의하세요!!.");
							return;
						}

						let utilObj = {};
						utilObj.doctor1 = doctor1TimeArray;
						utilObj.doctor2 = doctor2TimeArray;
						utilObj.gaonsamsung = gaonTimeArray;

						// utilArray.push({ "doctor2": doctor1TimeArray });
						// utilArray.push({ "gaonsamsung": gaonTimeArray });

						const res = await insertMarsUtils({ id: "mars_doctor_schedules", value: JSON.stringify(utilObj) });
						if (!res) {
							const res2 = await updateMarsUtils({ id: "mars_doctor_schedules", value: JSON.stringify(utilObj) })
						}


					}}>스케쥴 서버에 저장하기!</button>


				</div>

				<div>
					<br />
					[합체된 스케쥴]
					{compiledArray.map((slot) => {
						if (new Date(slot.time).getHours() > 7 && new Date(slot.time).getHours() < 20) {
							return (
								<div>
									<input type="checkbox" checked={slot.isAvail}

									/>{formatDisplayDate(slot.time)}  {formatDisplayTime(slot.time)}
								</div>
							)
						}
					})}
				</div>

				<div>
					<br />
					[서울정의원 스케쥴러]
					{doctor1TimeArray.map((slot) => {
						if (new Date(slot.time).getHours() > 7 && new Date(slot.time).getHours() < 20) {
							return (
								<div>
									<input type="checkbox" checked={slot.isAvail} onChange={() => {
										// slot.isAvail = !slot.isAvail;
										setDoctor1TimeArray(current =>
											current.map(obj => {

												if (obj.time === slot.time) {
													return { ...obj, isAvail: !slot.isAvail };
												}
												return obj;
											}));

									}} />{formatDisplayDate(slot.time)}  {formatDisplayTime(slot.time)}
								</div>
							)
						}
					})}
				</div>

				<div>
					<br />
					[보령의원 스케쥴러]
					{doctor2TimeArray.map((slot) => {
						if (new Date(slot.time).getHours() > 7 && new Date(slot.time).getHours() < 20 && new Date(slot.time).getDay() !== 0) {
							return (
								<div>
									<input type="checkbox" checked={slot.isAvail} onChange={() => {
										// slot.isAvail = !slot.isAvail;
										setDoctor2TimeArray(current =>
											current.map(obj => {

												if (obj.time === slot.time) {
													return { ...obj, isAvail: !slot.isAvail };
												}
												return obj;
											}));

									}} />{formatDisplayDate(slot.time)}  {formatDisplayTime(slot.time)}
								</div>
							)
						}
					})}
				</div>

				<div>
					<br />
					[가온삼성 스케쥴러]
					{gaonTimeArray.map((slot) => {
						if (new Date(slot.time).getHours() > 7 && new Date(slot.time).getHours() < 20) {
							return (
								<div>
									<input type="checkbox" checked={slot.isAvail} onChange={() => {
										// slot.isAvail = !slot.isAvail;
										setGaonTimeArray(current =>
											current.map(obj => {

												if (obj.time === slot.time) {
													return { ...obj, isAvail: !slot.isAvail };
												}
												return obj;
											}));

									}} />{formatDisplayDate(slot.time)}  {formatDisplayTime(slot.time)}
								</div>
							)
						}
					})}
				</div>




			</div>


		</>
	)
}
export default withAuthenticator(SchedulerPage)