// assets
import Q from "../../assets/exogeneQ.png";
import imageOne from "./faq_assets/Q1IM1.png";
import imageTwo from "./faq_assets/Q1IM2.png";
import imageThree from "./faq_assets/Q1IM3.png";
import imageFour from "./faq_assets/Q1IM4.png";
import imageFive from "./faq_assets/Q1IM5.png";

// css
import "./faqPagesStyling.css";

export default function Q1() {
  return (
    <div className="exogene-question-slide">
      <span id="exogene-q">
        <img src={Q} alt="question-placeholder" />
        <span className="exogene-question-head">
          어떤 유전자를 검사 하나요?
        </span>
      </span>
      <div className="exogene-faq-wrapper">
        <div className="exogene-faq-question">
          <span className="exogene-question-title">남성형 탈모</span>
          <span className="exogene-question-detail">
            모근 세포를 파괴하는 남성탈모 유전자
          </span>
          <div>
            <img id="exogene-Q1-img" src={imageOne} alt="exo-1"></img>
            <img id="exogene-Q1-img" src={imageTwo} alt="exo-2"></img>
          </div>
        </div>
        <div className="exogene-faq-question" style={{ marginTop: "15px" }}>
          <span className="exogene-question-title">원형 탈모</span>
          <span className="exogene-question-detail">
            자가 면역 질환을 일으키는 원형탈모 유전자{" "}
          </span>
          <div>
            <img id="exogene-Q1-img" src={imageThree} alt="exo-3"></img>
            <img id="exogene-Q1-img" src={imageFour} alt="exo-4"></img>
            <img id="exogene-Q1-img" src={imageFive} alt="exo-5"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
