import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { slack } from "utils/util";

function How(props) {
  return (
    <>
      <div className="landing4 landing4-background">
        <Row className="justify-content-center">
          <Col className="text-center" md="5" xs="12">

            <div className="left heading2">
              앱이 필요없는
              <br />
              <b style={{ color: "#FA5724" }}>카카오톡 채널 서비스</b>
            </div>

            <div className="line1-white" />
          </Col>
        </Row>

        <Row className="justify-content-center" style={{paddingBottom:"50px"}}>
          <Col md="4" xs="10">
            <img alt="..." src={require("assets/img/landing4-how.png")}></img>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="5" xs="12" className="justify-content-center text-center">
            <div
              className="kakao-btn"
              onClick={() => {
                if (props.isIndex) {
                  slack("index start button clicked");
                  alert("서비스 준비 중  입니다.")
                  return;
                }
                slack("[landing4/how] 채널추가 클릭");
                window.Kakao.Channel.addChannel({
                  channelPublicId: "_fMNCb",
                });
              }}
            >
              <img alt="..." src={require("assets/img/kakao_add_icon.png")} style={{ width: "30px", marginRight: "8px" }}></img>
              카카오톡 채널 추가하기
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default How;
