import { __assign, __read } from "tslib";
import { DOT_PATTERN, getArnResources, getSuffix, getSuffixForArnEndpoint, isBucketNameOptions, isDnsCompatibleBucketName, validateAccountId, validateArnEndpointOptions, validateDNSHostLabel, validateNoDualstack, validateNoFIPS, validateOutpostService, validatePartition, validateRegion, validateS3Service, validateService, } from "./bucketHostnameUtils";
export var bucketHostname = function (options) {
    var isCustomEndpoint = options.isCustomEndpoint, baseHostname = options.baseHostname, dualstackEndpoint = options.dualstackEndpoint, accelerateEndpoint = options.accelerateEndpoint;
    if (isCustomEndpoint) {
        if (dualstackEndpoint)
            throw new Error("Dualstack endpoint is not supported with custom endpoint");
        if (accelerateEndpoint)
            throw new Error("Accelerate endpoint is not supported with custom endpoint");
    }
    return isBucketNameOptions(options)
        ? // Construct endpoint when bucketName is a string referring to a bucket name
            getEndpointFromBucketName(__assign(__assign({}, options), { isCustomEndpoint: isCustomEndpoint }))
        : // Construct endpoint when bucketName is an ARN referring to an S3 resource like Access Point
            getEndpointFromArn(__assign(__assign({}, options), { isCustomEndpoint: isCustomEndpoint }));
};
var getEndpointFromArn = function (options) {
    var isCustomEndpoint = options.isCustomEndpoint, baseHostname = options.baseHostname;
    var _a = __read(isCustomEndpoint
        ? [options.clientRegion, baseHostname]
        : // Infer client region and hostname suffix from hostname from endpoints.json, like `s3.us-west-2.amazonaws.com`
            getSuffixForArnEndpoint(baseHostname), 2), clientRegion = _a[0], hostnameSuffix = _a[1];
    var pathStyleEndpoint = options.pathStyleEndpoint, _b = options.dualstackEndpoint, dualstackEndpoint = _b === void 0 ? false : _b, _c = options.accelerateEndpoint, accelerateEndpoint = _c === void 0 ? false : _c, _d = options.tlsCompatible, tlsCompatible = _d === void 0 ? true : _d, useArnRegion = options.useArnRegion, bucketName = options.bucketName, _e = options.clientPartition, clientPartition = _e === void 0 ? "aws" : _e, _f = options.clientSigningRegion, clientSigningRegion = _f === void 0 ? clientRegion : _f;
    validateArnEndpointOptions({ pathStyleEndpoint: pathStyleEndpoint, accelerateEndpoint: accelerateEndpoint, tlsCompatible: tlsCompatible });
    // Validate and parse the ARN supplied as a bucket name
    var service = bucketName.service, partition = bucketName.partition, accountId = bucketName.accountId, region = bucketName.region, resource = bucketName.resource;
    validateService(service);
    validatePartition(partition, { clientPartition: clientPartition });
    validateAccountId(accountId);
    validateRegion(region, { useArnRegion: useArnRegion, clientRegion: clientRegion, clientSigningRegion: clientSigningRegion });
    var _g = getArnResources(resource), accesspointName = _g.accesspointName, outpostId = _g.outpostId;
    validateDNSHostLabel(accesspointName + "-" + accountId, { tlsCompatible: tlsCompatible });
    var endpointRegion = useArnRegion ? region : clientRegion;
    var signingRegion = useArnRegion ? region : clientSigningRegion;
    if (outpostId) {
        // if this is an Outpost ARN
        validateOutpostService(service);
        validateDNSHostLabel(outpostId, { tlsCompatible: tlsCompatible });
        validateNoDualstack(dualstackEndpoint);
        validateNoFIPS(endpointRegion);
        var hostnamePrefix_1 = accesspointName + "-" + accountId + "." + outpostId;
        return {
            bucketEndpoint: true,
            hostname: "" + hostnamePrefix_1 + (isCustomEndpoint ? "" : ".s3-outposts." + endpointRegion) + "." + hostnameSuffix,
            signingRegion: signingRegion,
            signingService: "s3-outposts",
        };
    }
    // construct endpoint from Accesspoint ARN
    validateS3Service(service);
    var hostnamePrefix = accesspointName + "-" + accountId;
    return {
        bucketEndpoint: true,
        hostname: "" + hostnamePrefix + (isCustomEndpoint ? "" : ".s3-accesspoint" + (dualstackEndpoint ? ".dualstack" : "") + "." + endpointRegion) + "." + hostnameSuffix,
        signingRegion: signingRegion,
    };
};
var getEndpointFromBucketName = function (_a) {
    var _b = _a.accelerateEndpoint, accelerateEndpoint = _b === void 0 ? false : _b, region = _a.clientRegion, baseHostname = _a.baseHostname, bucketName = _a.bucketName, _c = _a.dualstackEndpoint, dualstackEndpoint = _c === void 0 ? false : _c, _d = _a.pathStyleEndpoint, pathStyleEndpoint = _d === void 0 ? false : _d, _e = _a.tlsCompatible, tlsCompatible = _e === void 0 ? true : _e, _f = _a.isCustomEndpoint, isCustomEndpoint = _f === void 0 ? false : _f;
    var _g = __read(isCustomEndpoint ? [region, baseHostname] : getSuffix(baseHostname), 2), clientRegion = _g[0], hostnameSuffix = _g[1];
    if (pathStyleEndpoint || !isDnsCompatibleBucketName(bucketName) || (tlsCompatible && DOT_PATTERN.test(bucketName))) {
        return {
            bucketEndpoint: false,
            hostname: dualstackEndpoint ? "s3.dualstack." + clientRegion + "." + hostnameSuffix : baseHostname,
        };
    }
    if (accelerateEndpoint) {
        baseHostname = "s3-accelerate" + (dualstackEndpoint ? ".dualstack" : "") + "." + hostnameSuffix;
    }
    else if (dualstackEndpoint) {
        baseHostname = "s3.dualstack." + clientRegion + "." + hostnameSuffix;
    }
    return {
        bucketEndpoint: true,
        hostname: bucketName + "." + baseHostname,
    };
};
