import { escapeElement } from "./escape-element";
/**
 * Represents an XML text value.
 */
var XmlText = /** @class */ (function () {
    function XmlText(value) {
        this.value = value;
    }
    XmlText.prototype.toString = function () {
        return escapeElement("" + this.value);
    };
    return XmlText;
}());
export { XmlText };
