import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import { Button, Card, Collapse, Col, Row, Toast, ToastBody } from "reactstrap";
import { PRODUCTS } from "utils/products";
import * as mutations from "../../graphql/mutations";
import {
  calcPrice4,
  getENV,
  formatDisplayDate,
  formatDisplayTime,
  parsePhoneNumber,
  parseSurvey,
  slack,
  slack2ops,
  won,
  zeroPad,
} from "../../utils/util";
import FVChartV15 from "./FVChartV15";
// import PDFPreview from "views/doctor4/PDFPreview";
import PDFPreviewNew from "./PDFPreviewNew";
import RepeatChartV15 from "./RepeatChartV15";
import SVChartV15 from "./SVChartV15";
// import SVChartV14 from "views/doctor4/SVChartV14";
// import FVChartV14 from "views/doctor4/FVChartV14";
// import RepeatChartV14 from "views/doctor4/RepeatChartV14";
import CancelPopup from "./CancelPopup";

// img
import copy from "./doctor-assets/copy.png";

// css
import "./doctorCardStyles.css";
import "./patientInfoStyles.css";

export default function PatientCardNew(props) {
  const { patient } = props;
  /* Data Manipulation / Logic states */
  const [PDFPreviewOpen, setPDFPreviewOpen] = useState(false);
  const [hairlineKey, setHairlineKey] = useState();
  const [haircenterKey, setHaircenterKey] = useState();
  const [hairgeneralKey, setHairgeneralKey] = useState("");
  const [prescriptionURL, setPrescriptionURL] = useState("");
  const [signedURL, setSignedURL] = useState("");
  const [when, setWhen] = useState("30");
  const [cancelReason, setCancelReason] = useState(); // type of cancel (for op)
  const [answers, setAnswers] = useState();

  /* Reservation Time / Data Management states */
  const [rtime, setRtime] = useState(0); // reservation time
  const [rday, setRday] = useState(""); // reservation day
  const [rdate, setRdate] = useState(0); // reservation date formatted?
  const [rmonth, setRmonth] = useState(0); // reservation month
  const [reservationColor, setReservationColor] = useState("gray"); // reservation display text colour
  const [yeyakTime, setYeyakTime] = useState();

  /* Card data or states for display purposes */
  const [type, setType] = useState("재진"); // first or repeat patient type
  const [total, setTotal] = useState(0); // total price

  /* Card Component Functionality States */
  const [isDetail, setIsDetail] = useState(false); // Open or Close Patient Card
  const [acceptBtnDisabled, setAcceptBtnDisabled] = useState(true); // Enable or Disable Button for Accept

  /* Pills & Med & Shampoo Information States */
  const [pill, setPill] = useState("propecia"); // 복용약
  const [minox, setMinox] = useState("minox"); // 바르는 약
  const [pillPrice, setPillPrice] = useState(0); // 약 가격
  const [minoxPrice, setMinoxPrice] = useState(0); // 바르는 약 가격
  const [shampPrice, setShampPrice] = useState(0); // 샴푸 가격
  const [pillMonth, setPillMonth] = useState(); // 복용약 기간
  const [month, setMonth] = useState(3); // 복용약 기간 possible dupe?
  const [minoxMonth, setMinoxMonth] = useState(); // 바르는 약 기간
  const [message, setMessage] = useState(""); // message from patients ?

  /* Unknown / Unverified / Unused States */
  const [version, setVersion] = useState(""); // ??
  const [PDFBlob, setPDFBlob] = useState(""); // possible data manip category
  const [elapsedTime, setElapsedTime] = useState(""); // Time elapsed since registration formatted: 'HH시간 MM분'
  // Below: 100% unused states
  //   Unused States / Delete Later
  //   const [name, setName] = useState("김선민");
  //   const [timer, setTimer] = useState();

  // cancel stuff
  const [openCancel, setOpenCancel] = useState(false);
  const [showToast, setShowToast] = useState(false);

  // copyable text
  const copyPhone = document.getElementById("patient-phone");
  const copySSN = document.getElementById("patient-ssn");

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      let a = props.patient.ans;
      let ans = JSON.parse(a);

      if (p.survey_type && p.survey_type === "초진") {
        setType("초진");
      }
      if (p.survey_type && p.survey_type === "repeat") {
        setType("리필");
      }

      let sv = props.patient.survey_version;

      setVersion(sv);
      let r = parseSurvey(props.patient.ans, props.patient.survey_type, sv);
      setAnswers(ans);

      let pill, minox, month, message, pmonth, mmonth;
      if (p.pill !== "" || p.minox !== "") {
        // doctor prescription is there use the values
        pill = p.pill;
        minox = p.minox;
        month = p.month;
        pmonth = p.pillMonth;
        mmonth = p.minoxMonth;
      } else {
        pill = r.pill;
        minox = r.minox;
        month = r.pillMonth;
        pmonth = r.pillMonth;
        mmonth = r.minoxMonth;
      }
      message = r.message;

      // set answers
      setPill(pill);
      setMinox(minox);
      setMonth(month);
      setPillMonth(pmonth);
      setMinoxMonth(mmonth);
      setMessage(message);

      let res = calcPrice4(pill, minox, pmonth, mmonth, null, null, null);

      setPillPrice(res.pillPrice);
      setMinoxPrice(res.minoxPrice);
      // setShampPrice(res.shampPrice);
      setTotal(res.total);

      // set the elapsed time
      //
      let min = Math.round(
        (new Date() - new Date(props.patient.createdAt.toString())) /
          (60 * 1000)
      );
      if (min < 60) {
        setElapsedTime(min + " 분");
      } else {
        let hour = Math.floor(min / 60);
        let remain = min % 60;
        setElapsedTime(hour + "시간" + remain + "분");
      }

      if (p.hairline_url) {
        let temp = p.hairline_url.split(":!:");
        let id = temp[0];
        if (id === "") {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
        }
        let url = temp[1];
        const hairline_key = await Storage.get(url, {
          level: "protected",
          identityId: id,
        });
        setHairlineKey(hairline_key);
      }
      if (p.haircenter_url) {
        let temp = p.haircenter_url.split(":!:");
        let id = temp[0];
        if (id === "") {
          id = "ap-northeast-2:d0e74dbc-7356-4901-bfd2-ccb9e87d1b75";
        }

        let url = temp[1];
        const haircenter_key = await Storage.get(url, {
          level: "protected",
          identityId: id,
        });
        setHaircenterKey(haircenter_key);
      }
      if (p.filekey) {
        const key = await Storage.get(p.filekey, {
          level: "protected",
          download: false,
        });
        setSignedURL(key);
      }

      if (p.prescription) {
        const pre_key = await Storage.get(p.prescription, {
          level: "protected",
        });
        setPrescriptionURL(pre_key);
      }

      setYeyakTime(p.yeyak_time);

      // set interval and check if buffer time is hit
      if (p.dstatus === "accept") {
        (function foo() {
          let now = new Date();
          let diff = new Date(p.yeyak_time) - now;
          // console.log("checking time diff: ", diff);
          // alert(diff);
          let bufferMin = 30;
          if (diff < 60000 * bufferMin) {
            // 30min
            setReservationColor("#6A43B8");
            setAcceptBtnDisabled(false);
            props.onTime();
            return;
          }
          setTimeout(foo, 60000);
        })();
      }
    };
    asyncWrapper();
  }, []);

  const handlePDFPreviewClose = () => {
    setPDFPreviewOpen(false);
  };

  const handleUpdatePDFURL = async (url) => {
    const key = await Storage.get(url, {
      level: "protected",
      download: false,
    });
    setSignedURL(key);
    props.patient.filekey = url;
    // alert(url);
  };

  // TEXT PARSING / CASES
  const getPatientHeader = (type) => {
    switch (type) {
      case "초진":
        return "탈모진료를 처음받는 ";

      case "재진":
        return "MARS에서 처음 진료 받는, 탈모약 복용경험이 있는 ";

      case "repeat":
        return "MARS에서 진료받았던 ";
    }
    return "";
  };

  const handleCancel = () => {
    setOpenCancel(true);
  };

  // returns age calculated using kssn
  const calcAge = (ssn) => {
    if (!ssn) {
      return 0;
    }
    try {
      var year = new Date().getFullYear();
      var array = ssn.split("-");
      var age = 0;
      // var age = array[0].substring(0, 2);
      if (array[1].startsWith("1") || array[1].startsWith("2")) {
        age = year - (1900 + parseInt(array[0].substring(0, 2)));
      } else {
        age = year - (2000 + parseInt(array[0].substring(0, 2)));
      }
      if (age <= 0) {
        if (!array[0].startsWith(0))
          return `(${
            year - (1900 + parseInt(array[0].substring(0, 2))) + "세)" + " ⚠️"
          }`;
      } else if (array[0].startsWith(0) && !array[1].startsWith("3")) {
        return `(${
          year - (2000 + parseInt(array[0].substring(0, 2))) + "세)" + " ⚠️"
        }`;
      }
    } catch (error) {
      console.log("ssn returned null");
    }
    return `(${age + "세"})`;
  };

  const parseReason = (reason) => {
    switch (reason) {
      case "cannot_reach":
        return "통화불가 취소";

      case "deny_prescription":
        return "처방 거부 취소";
    }
    return "기타";
  };

  const copyItemPhone = () => {
    var copyText = document.getElementsByClassName(
      `${patient.id}-patient-phone`
    );
    var append = "";
    for (let i = 0; i < copyText.length; i++) {
      append = append.concat(copyText[i].innerText + "\n");
    }
    navigator.clipboard.writeText(append).then(
      () => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1500);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const copyItemSSN = () => {
    var copyText = document.getElementsByClassName(`${patient.id}-patient-ssn`);
    var append = "";
    for (let i = 0; i < copyText.length; i++) {
      append = append.concat(copyText[i].innerText + "\n");
    }
    navigator.clipboard.writeText(append).then(
      () => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1500);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const formatDocTime = (time) => {
    let yoil = ["일", "월", "화", "수", "목", "금", "토"];
    var dateObj = new Date(time);
    var date = dateObj.getDate();
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDay();
    return `${zeroPad(month, 2)}/${zeroPad(date, 2)} (${yoil[day]})`;
  };

  return (
    <>
      <PDFPreviewNew
        isOpen={PDFPreviewOpen}
        onClose={handlePDFPreviewClose}
        patient={props.patient}
        url={signedURL}
        onUpdateURL={handleUpdatePDFURL}
      />
      <div style={{ fontSize: "16px" }}>
        <Card className="patient-card-card">
          {/* Entire card content (might be redundant) - use regular div wrappers instead? */}
          {/* Row is the card before collapse */}
          {/* All details before card is open are shown on this ROW */}
          {/* ! Patient Details Start Here ! */}
          <div
            className="doctor-patient-card-content"
            onClick={() => {
              // slack("[doctorNew] 환자카드 확장/축소");
              setIsDetail(!isDetail);
            }}
          >
            {/* NAME */}
            <div
              style={{
                width: "50px",
                position: "absolute",
                display: "flex",
                placeItems: "center",
                justifyContent: "center",
                paddingLeft: "25px",
              }}
            >
              <li
                style={{
                  position: "relative",
                  color: reservationColor === "#6A43B8" ? "#6A43B8" : "white",
                  fontSize: "25px",
                }}
              ></li>
            </div>
            <div className="patient-card-data" id="patient-card-name">
              <b>
                {props.patient.name}
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  {calcAge(props.patient.ssn)}
                </span>
              </b>
            </div>
            {/* Might be used later */}
            {/* <div>접수({elapsedTime} 경과)</div> */}
            {/* DESIRED TIME */}
            <div className="patient-card-data" id="patient-desired-time">
              {/* {formatDisplayDate(yeyakTime)} */}
              {formatDocTime(yeyakTime) + " "}
              <span id="patient-purple">{formatDisplayTime(yeyakTime)}</span>
            </div>
            {/* PATIENT TYPE */}
            {/* <div className="patient-card-data" id="patient-type">
              {type}
            </div> */}
            {/* PATIENT CONTACT */}
            <div className="patient-card-data" id="patient-contact">
              <span className={`${patient.id}-patient-phone`}>
                {parsePhoneNumber(props.patient.phone)}
              </span>
              <button
                id="card-copy-button"
                onClick={(e) => {
                  e.stopPropagation();
                  copyItemPhone(patient.id);
                }}
              >
                <img src={copy} />
              </button>
            </div>
            {/* PATIENT SSN */}
            <div className="patient-card-data" id="patient-ssn">
              <span className={`${patient.id}-patient-ssn`}>
                {props.patient.ssn}
              </span>
              <button
                id="card-copy-button"
                onClick={(e) => {
                  e.stopPropagation();
                  copyItemSSN(patient.id);
                }}
              >
                <img src={copy} />
              </button>
            </div>

            {/* Main Button Functionalities Go here (based on patient status) */}
            {/* DROP DOWN */}
            <div className="patient-card-data" id="patient-action-buttons">
              {props.patient.dstatus === "accept" && (
                <div className="patient-dropdown-selector">
                  <select
                    id="patient-selector"
                    style={{ color: reservationColor }}
                    onChange={(e) => {
                      setWhen(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    defaultValue={"30"}
                    disabled={reservationColor === "#6A43B8" ? false : true}
                  >
                    <option value="5">5분내로 통화 가능</option>
                    <option value="15">15분내로 통화 가능</option>
                    <option value="30">30분내로 통화 가능</option>
                    <option value="60">60분내로 통화 가능</option>
                  </select>
                  <Button
                    disabled={acceptBtnDisabled}
                    // disabled={reservationColor === "#6A43B8" ? false : true}

                    onClick={(e) => {
                      e.stopPropagation();
                      setAcceptBtnDisabled(true);
                      let year = new Date().getFullYear();
                      let reservation_time = new Date(
                        year,
                        rmonth - 1,
                        rdate,
                        rtime
                      );

                      props.onAccept(
                        props.patient.id,
                        props.patient.name,
                        reservation_time,
                        when
                      );
                      setTimeout(function () {
                        setAcceptBtnDisabled(false);
                      }, 3000);
                    }}
                    id="patient-reg-button"
                    style={{ background: reservationColor }}
                  >
                    확인
                  </Button>
                </div>
              )}

              {props.patient.dstatus === "phone" && (
                <div>
                  <button
                    id="doctor-cancel-button"
                    onClick={(e) => {
                      setOpenCancel(!openCancel);
                      e.stopPropagation();
                    }}
                  >
                    취소하기
                  </button>
                  {/* 처방전 업로드 버튼 */}
                  <Button
                    id="doctor-complete-button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      setPDFPreviewOpen(true);
                    }}
                  >
                    처방전 업로드
                  </Button>
                </div>
              )}

              {(props.patient.dstatus === "uploaded" ||
                props.patient.dstatus === "done") && (
                <div>
                  <div>
                    <Button
                      className="btn-sm"
                      onClick={async (e) => {
                        e.stopPropagation();
                        setPDFPreviewOpen(true);
                      }}
                      style={{
                        background: "#FFFFFF",
                        border: "0.5px solid #8C8C8C",
                        borderRadius: "2px",
                        color: "black",
                        minWidth: "200px",
                        height: "30px",
                        marginRight: "48px",
                      }}
                    >
                      처방전 수정
                    </Button>
                  </div>
                  {/* <a href={signedURL} target="_blank">
                                            처방전 다운로드
                                        </a> */}
                </div>
              )}

              <span
                style={{
                  color: "#6A43B8",
                  color: "red",
                  fontSize: "13px",
                  fontWeight: 400,
                  display: "flex",
                  textAlign: "right",
                  justifyContent: "end",
                  marginRight: "44px",
                }}
              >
                {props.patient.dstatus === "cancel"
                  ? parseReason(props.patient.status)
                  : ""}
              </span>
            </div>
          </div>

          {/* Once Toggle */}
          {/* Patient details main container on collapse */}
          {/* 카드 확대 / 축소 */}
          {isDetail && (
            <div>
              {/* 환자정보 DELETED*/}
              {/* {props.patient.survey_version === "1.4" && ( */}
              {/* First Tables Container / Changes based on patient status */}
              <div className={"doctor-page-patient-info-container"}>
                <div className="doctor-page-questionnaires">
                  <div id="doctor-card-info-title">
                    <span style={{ fontWeight: "600" }}>문진요약</span>
                    <br />
                    {/* 탈모진료를 처음받는 */}
                    {getPatientHeader(props.patient.survey_type)}
                    <span id="patient-purple" style={{ fontWeight: "600" }}>
                      {props.patient.survey_type !== "repeat"
                        ? props.patient.survey_type + "환자"
                        : "리필환자"}
                    </span>
                    입니다.
                  </div>
                  {props.patient.survey_type === "재진" && (
                    <SVChartV15
                      answers={answers}
                      hairlineKey={hairlineKey}
                      haircenterKey={haircenterKey}
                      id={patient.id}
                    />
                  )}
                  {/* 초진 First-Time Patients */}
                  {props.patient.survey_type === "초진" && (
                    <FVChartV15
                      answers={answers}
                      hairlineKey={hairlineKey}
                      haircenterKey={haircenterKey}
                      hairgeneralKey={hairgeneralKey}
                      id={patient.id}
                    />
                  )}
                  {props.patient.survey_type === "repeat" && (
                    <RepeatChartV15
                      answers={answers}
                      hairlineKey={hairlineKey}
                      haircenterKey={haircenterKey}
                      hairgeneralKey={hairgeneralKey}
                      patient={patient}
                      id={patient.id}
                    />
                  )}
                </div>
                {/* 희망처방전 */}
                {/* All pricing / med information go here */}
                <div className="doctor-page-pricing-info">
                  {props.patient.survey_version && (
                    <>
                      {(props.patient.dstatus === "accept" ||
                        props.patient.dstatus === "phone" ||
                        props.patient.dstatus === "uploaded" ||
                        props.patient.dstatus === "done") && (
                        <div>
                          <div id="doctor-card-info-title">
                            <span style={{ fontWeight: "600" }}>
                              MARS 예상 처방
                            </span>
                            <br />
                            환자가 작성한 문진 기반으로 MARS가 추천하는 예상
                            처방 입니다.
                          </div>
                          <table className="doctor-page-pricing-table">
                            <tbody>
                              {pill && (
                                <tr>
                                  <li>
                                    {PRODUCTS[pill]
                                      ? PRODUCTS[pill].name
                                      : pill}
                                  </li>
                                  <td style={{ minWidth: "120px" }}>
                                    {/* {PRODUCTS[pill]
                                      ? JSON.stringify(PRODUCTS[pill].base) +
                                        "정"
                                      : pill} */}

                                    {PRODUCTS[pill]
                                      ? pillMonth * PRODUCTS[pill].base +
                                        "정" +
                                        `[${pillMonth + "개월"}]`
                                      : pill}
                                  </td>
                                  <td>{won(pillPrice)}원</td>
                                </tr>
                              )}
                              {minox !== "아니요" && (
                                <tr>
                                  <li>
                                    {PRODUCTS[minox]
                                      ? PRODUCTS[minox].name
                                      : minox}
                                  </li>
                                  <td>
                                    {minoxMonth * PRODUCTS[minox].base}{" "}
                                    {PRODUCTS[minox].unit}
                                  </td>
                                  <td>{won(minoxPrice)}원</td>
                                </tr>
                              )}
                              <tr>
                                <li>진료비:</li>
                                <td>{won(10000)}원</td>
                              </tr>
                              <tr>
                                <li>배송비:</li>
                                <td>{won(2900)}원</td>
                              </tr>
                              <tr>
                                <td style={{ marginLeft: "auto" }}>
                                  <b>총 {won(total)}원</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
      {openCancel ? (
        <CancelPopup
          openCancel={openCancel}
          setOpenCancel={setOpenCancel}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
          patient={patient}
        ></CancelPopup>
      ) : (
        <></>
      )}

      {showToast ? (
        <Toast id="addon-toast" style={{ top: "5%", width: "150px" }}>
          <ToastBody id="addon-toast-body">복사되었습니다.</ToastBody>
        </Toast>
      ) : (
        <></>
      )}
    </>
  );
}
