import PhotoChartCard from "./PhotoChartCardNew";


export default function PhotoChartViewerNew(props) {
 return (
  <>
   <div className="scrollable2" style={{ padding: "20px" }}>
    {props.charts.map((c,idx) => {
     let counter = 0;

     // if ((c.hairline_url && !c.haircenter_url) || (!c.hairline_url && c.haircenter_url)) {
      if (c.hairline_url && c.haircenter_url) {
      return (
       <PhotoChartCard
        count={counter++}
        key={c.id}
        patient={c}
       />
      );
     }
    })}
   </div>
  </>
 )
}