import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { formatDisplayDateTime } from "utils/util";
import cancel from "../../survey/repeat/repeat-assets/cancelPurple.png";

export default function PaymentConfirmPopup(props) {
  const { paymentPopup, setPaymentPopup, cardName, cardNum, clickDone } = props;
  const toggle = () => setPaymentPopup(!paymentPopup);

  const modalStyle = {
    width: "330px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "25vh",
    height: "200px",
  };

  const modalBody = {
    textAlign: "center",
    display: "grid",
    placeItems: "center",
    height: "115px",
    paddingTop: "45px",
    justifyContent: "center",
  };

  const modalFooter = {
    display: "flex",
    flexDirection: "row",
    borderTop: " 1px solid #8C8C8C",
    marginTop: "25px",
    padding: 0,
  };

  const cancelButton = {
    width: "50%",
    background: "#FFFFFF",
    color: "black",
    height: "45px",
    border: "none",
    borderTopRightRadius:'0px',
    borderBottomRightRadius:'0px',
    borderBottomLeftRadius: "10px",
    borderRight: "1px solid #8C8C8C",
    fontSize: "15px",
    fontWeight: 400,
  };

  const confirmButton = {
    width: "50%",
    background: "#FFFFFF",
    color: "black",
    height: "45px",
    border: "none",
    borderBottomRightRadius: "10px",
    color: "black",
    fontSize: "15px",
    fontWeight: 600,
  };

  const textStyle = {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "black",
    marginTop: "40px",
  };

  return (
    <Modal
      style={modalStyle}
      isOpen={paymentPopup}
      height="200px"
      toggle={toggle}
      id="reservation-modal"
    >
      <ModalBody style={modalBody}>
        <img
          style={{
            width: "10px",
            height: "auto",
            position: "absolute",
            top: "0",
            right: "0",
            margin: "15px",
          }}
          src={cancel}
          onClick={() => toggle()}
        ></img>
        <span
          style={{
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          결제를 진행하시겠어요?
        </span>
      </ModalBody>
      <ModalFooter style={modalFooter}>
        <Button style={cancelButton} onClick={() => setPaymentPopup(false)}>
          아니요
        </Button>
        <Button
          style={confirmButton}
          onClick={() => {
            // console.log("click confirm");
            clickDone();
            setPaymentPopup(false);
          }}
        >
          예
        </Button>
      </ModalFooter>
    </Modal>
  );
}
