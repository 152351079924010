import ExoCustomerCard from "./ExoCustomerCard";
import PhotoChartCard from "./OperationCardNew";

function ExogeneViewer(props) {
  return (
    <>
      <div className="scrollable2" style={{ padding: "20px" }}>
        {props.exogenes.map((e) => {
          if (e.status === props.status) {
            return (
              <ExoCustomerCard
                key={e.id}
                patient={e}
              />
            );
          }
        })}
      </div>
    </>
  )
}
export default ExogeneViewer;