import logo from "../../assets/img/mars_logo_black.png";

export default function MarsNavBarMain() {
  return (
    <div
      style={{
        background: "white",
        height: "52px",
        position: "fixed",
        top: 0,
        width: "100vw",
        display: "grid",
        placeItems: "center",
        borderBottom: ".7px solid #c8c8c8",
        zIndex: 9
      }}
    >
      <img src={logo} style={{ height: "22px", width: "auto" }}></img>
    </div>
  );
}
