import StatusButton2 from "views/status/StatusButton2";
import { Modal } from "reactstrap";
import close from '../../assets/img/closeModalX.png'

// const { Modal } = require("reactstrap")

function StatusPrescriptionModal(props) {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        style={{
          padding: 0,
          width: "90vw",
          margin: "0 auto",
          marginTop: "75px",
        }}
      >
        <a
          style={{ position: "absolute", right: 15, fontWeight: 600, top: 2 }}
          onClick={() => {
            props.closeModal();
          }}
        >
          <img style={{width:'12px', height: '12px'}} src={close}/>
        </a>
        <div
          style={{
            backgroundColor: "white",
            zIndex: 10,
            margin: "25px 15px 25px 15px",
            display: "flex",
            placeItems: "center",
          }}
        >
          {true && (
            <iframe style={{ width: "100%", height: "65vh" }} src={props.url} />
          )}
          {false && <img src={props.url} style={{ maxWidth: "100%" }}></img>}
        </div>
        {/* <div>
          <StatusButton2
            clickDone={() => {
              props.closeModal();
            }}
          >
            닫기
          </StatusButton2>
        </div> */}
      </Modal>
    </>
  );
}

export default StatusPrescriptionModal;
