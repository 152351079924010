function SurveyNextBtn(props) {
  const styles = {
    disableTransition: {
      transition: "none",
    },
  };

  return (
    <div
      className="survey-next-btn-container"
      // temp fix for button background div
      style={{ background: props.white === true ? "white" : "#f7f7f7" }}
    >
      <div
        animate="none"
        // style={styles}
        style={{ background: props.disableButton ? "gray" : "black" }}
        onClick={() => {
          if (props.disabled) {
            return;
          }
          props.onClick();
        }}
        className={
          "survey-next-btn " +
          (props.disabled ? "survey-next-btn-disabled" : "") +
          (props.disabledColor ? "survey-next-btn-disabled" : "")
        }
      >
        <div className="survey-next-btn-text">{props.children}</div>
      </div>
    </div>
  );
}
export default SurveyNextBtn;
