import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { updateChart } from "utils/data";
import { PRODUCTS } from "utils/products";
import { slack2accept } from "utils/util";
import {
  calcPrice4,
  getDoctorInfo,
  parsePhoneNumber,
  parseSurvey,
  slack,
  won,
} from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";

// status status
import StatusStatusBar from "./status-components/StatusStatusBar";

// images
import FormAnimation from "./status-assets/StatusOne.json";
import { getENV } from "utils/util";
import { getTimeObject } from "utils/util";
import { updateUser } from "utils/data";
import { formatDisplayDate } from "utils/util";
import { formatDisplayTime } from "utils/util";
import { getScheduleFromServer } from "utils/util";
import { formatDisplayDateTime } from "utils/util";

function StatusStep1(props) {
  const [minox, setMinox] = useState("minox");
  const [pill, setPill] = useState("propecia");
  const [shamp, setShamp] = useState();
  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [yeyakTime, setYeyakTime] = useState("");
  const [newYeyakTime, setNewYeyakTime] = useState("");
  const [minoxMonth, setMinoxMonth] = useState();
  const [pillMonth, setPillMonth] = useState();
  const [doctorInfo, setDoctorInfo] = useState(null);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [availTimes, setAvailTimes] = useState([]);
  const history = useHistory();

  const statusProps = {
    step: props.step,
    user: props.user,
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let ans = parseSurvey(
        props.patient.ans,
        props.patient.survey_type,
        props.patient.survey_version
      );

      setMinox(ans.minox);
      setPill(ans.pill);
      setShamp(ans.shamp);
      if (!ans.shamp && props.patient.shamp) {
        ans.shamp = props.patient.shamp;
        setShamp(props.patient.shamp);
      }
      setMonth(ans.month);
      let pmonth = ans.pillMonth ? ans.pillMonth : ans.month;
      let mmonth = ans.minoxMonth ? ans.minoxMonth : ans.month;
      setPillMonth(pmonth);
      setMinoxMonth(mmonth);

      let res = calcPrice4(
        ans.pill,
        ans.minox,
        pmonth,
        mmonth,
        ans.shamp,
        null,
        null
      );
      setPillPrice(res.pillPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);

      // get reservation time
      if (props.patient.yeyak_time) {
        setYeyakTime(props.patient.yeyak_time);
      }

      if (props.patient.doctor && props.patient.doctor !== "") {
        let doctor_info = getDoctorInfo(props.patient.doctor);
        setDoctorInfo(doctor_info);
      }
      let schedules = await getScheduleFromServer();
      setAvailTimes(schedules);

      //slack
      slack("[status/step1(접수완료)] 방문: " + props.user.name);
    };
    asyncWrapper();
  }, []);

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: FormAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div
        className="status-section-container"
        style={{ overflowX: "hidden", background: "#F7F7F7" }}
      >
        {/* questions */}
        {/* <div className="question-section">
          <div
            className="question-title"
            style={{ paddingBottom: "12px", paddingTop: "68px" }}
          >
            접수 완료
          </div>
          <div className="question-explain">
            {props.user.name}님, 접수가 완료되었어요.{" "}
            <b className="orange">희망진료시간 30분 전까지 취소가 가능해요.</b>
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "18px",
              marginBottom: "-6px",
            }}
          >
            <img
              alt="..."
              src={require("assets/img/status1.png")}
              width="100%"
              style={{ maxWidth: "400px" }}
            ></img>
          </div>
        </div> */}
        {/* 접수정보 세션 */}
        <StatusStatusBar props={statusProps} />
        <div id="status-fake-curve"></div>
        <div className="status-content-container">
          {/* 접수내용 */}
          <div style={{ paddingBottom: "25px" }}>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>나의정보</b>
              <b
                id="subtext-link"
                onClick={() => {
                  history.push("/mypagesave");
                }}
              >
                <span className="gray-underline">수정하기</span>
              </b>
            </div>
            <table width={"100%"}>
              <tbody className="status-title">
                <tr style={{fontSize:'15px'}}> 
                  <td>이름/주민번호</td>
                  <td className="text-right">
                    {props.user.name} ({props.user.ssn.slice(0, 6)}-
                    {props.user.ssn.slice(7, 8)}
                    {props.user.ssn.slice(8, 14).replace(/\d(?=\d{0})/g, "*")})
                  </td>
                </tr>
                <tr style={{fontSize:'15px'}}>
                  <td>연락처</td>
                  <td className="text-right">
                    {parsePhoneNumber(props.user.phone)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="line-orange-status" />

          {/* 진료예약시간 */}
          <div>
            {/* <div style={{ fontSize: "18px", marginBottom: "8px" }}>
							<b>진료예약시간</b> <label className="text-right">hi</label>
						</div> */}
            <table width={"100%"}>
              <tr>
                <td>
                  <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                    <b>진료예약시간</b>
                    <div
                      style={{ float: "right" }}
                      onClick={async () => {
                        let currentTime = new Date();
                        let diff =
                          (new Date(yeyakTime).getTime() -
                            currentTime.getTime()) /
                          1000 /
                          60;
                        console.log("diff:", diff);
                        if (diff < 30) {
                          alert(
                            "죄송합니다. 진료 예약시간 30분 전 까지만 예약 취소/변경이 가능해요."
                          );
                          return;
                        }

                        if (
                          window.confirm(
                            `정말로 진료 예약을 취소 하시겠습니까?`
                          )
                        ) {
                          let chartInfo = {
                            id: props.patient.id,
                            status: "cancel",
                            dstatus: "cancel",
                            ignoreStatus: "no",
                          };
                          const res = await updateChart(chartInfo);
                          if (res) {
                            if (props.user.charts) {
                              let userCharts = JSON.parse(props.user.charts);
                              // console.log("userCharts:", userCharts);
                              let chart = userCharts.find(
                                (chart) => chart.id === props.patient.id
                              );
                              chart.stat = "cancel";
                              let userInfo = {
                                id: props.user.id,
                                charts: JSON.stringify(userCharts),
                              };
                              const uuser = await updateUser(userInfo);
                              console.log("updated user: ", uuser);
                            }
                            slack2accept(
                              `[예약취소][${props.user.name}][${props.user.kid}]고객이 예약취소`
                            );
                            alert("예약이 취소 되었습니다.");
                            history.go(0);
                          }
                        }
                      }}
                    >
                      <u className="gray-underline">취소하기</u>
                    </div>
                    <div
                      style={{
                        fontSize: "13px",
                        marginTop: "5px",
                        marginBottom: "4px",
                        marginRight: "20px",
                        float: "right",
                      }}
                    >
                      <a
                        onClick={() => {
                          //check if <= 30min is left.
                          let currentTime = new Date();
                          let diff =
                            (new Date(yeyakTime).getTime() -
                              currentTime.getTime()) /
                            1000 /
                            60;
                          console.log("diff: ", diff);
                          if (diff > 30) {
                            setIsTimeSlotOpen(true);
                          } else {
                            alert(
                              "죄송합니다. 진료 예약시간 30분 전 까지만 예약 취소/변경이 가능해요."
                            );
                          }
                        }}
                      >
                        <u className="gray-underline">변경하기</u>
                      </a>
                    </div>
                  </div>
                </td>
                <td className="text-right"></td>
              </tr>
            </table>

            <table width={"100%"}>
              <tbody>
                <tr>
                  <td style={{ fontSize: "18px" }}>
                    {formatDisplayDate(yeyakTime)}{" "}
                    {formatDisplayTime(yeyakTime)} 사이
                  </td>
                </tr>
                <TimeSlotModal
                  isTimeSlotOpen={isTimeSlotOpen}
                  isOpen={isTimeSlotOpen}
                  items={availTimes}
                  setIsTimeSlotOpen={setIsTimeSlotOpen}
                  onClose={async () => {
                    if (newYeyakTime.split("T").length !== 2) {
                      alert("올바른 예약 시간을 선택해 주세요.");
                      return;
                    }
                    // confirm if the user really wants to change the reservation time
                    if (
                      window.confirm(`정말로 예약시간을 변경 하시겠습니까?`)
                    ) {
                      setYeyakTime(newYeyakTime);
                      console.log("update db change reservation time");
                      let chartInfo = {
                        id: props.patient.id,
                        reservation_time: newYeyakTime,
                        yeyak_time: newYeyakTime,
                      };
                      const res = await updateChart(chartInfo);
                      if (res) {
                        //send kakao message
                        let users = [
                          { type: "appUserId", id: props.patient.kid },
                        ];
                        let eventName =
                          "event_reservation_time_changed" +
                          (getENV() !== "prod" ? "_dev" : "");
                        console.log("event Name: ", eventName);
                        let eventData = {
                          msg: formatDisplayDateTime(newYeyakTime),
                        };
                        let res = await API.post("marsrest", "/kakao", {
                          body: { users, eventName, eventData },
                        });
                        slack2accept(
                          `[예약시간변경][${props.user.name}][${
                            props.user.kid
                          }] 변경시간:${formatDisplayDateTime(newYeyakTime)}`
                        );
                        alert("예약시간이 성공적으로 변경 되었습니다.");
                      }
                    }
                    setIsTimeSlotOpen(false);
                  }}
                  onUpdate={(time) => {
                    console.log("updating time:", time);
                    setNewYeyakTime(time);
                  }}
                />
                <tr>
                  <td>
                    <div
                      style={{
                        color: "#8C8C8C",
                        fontWeight: 300,
                        lineHeight: "18px",
                        paddingTop: "10px",
                      }}
                    >
                      {/* *예약 변경/취소는 <b className="purple">진료시간 30분</b> 전 까지만 가능합니다. 그 이후에는 카톡하단 메뉴의 ‘상담직원 연결하기’를 눌러 문의 해 주세요. */}
                      예약 변경/취소는 <b className="purple">진료시간 30분</b>{" "}
                      전 까지만 가능합니다. 그 이후에는 ‘상담직원 연결하기’
                      메뉴를 통해 문의해 주세요.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              style={{
                paddingTop: "12px",
                paddingBottom: "20px",
                paddingRight: "8px",
              }}
            ></div>
          </div>

          <div className="line-orange-status" />

          {/* 예상금액 */}
          <div className="">
            <table width={"100%"}>
              <tr>
                <td>
                  <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                    <b>예상처방내역</b>
                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        fontWeight: "400",
                      }}
                    >
                      <mark style={{ backgroundColor: "#EBFFBF" }}>
                        * 통화진료시 의사와 상담을 통해 변경 가능합니다.
                      </mark>
                    </p>
                  </div>
                </td>
                <td className="text-right">
                  {/* <div style={{ fontSize: "16px", marginBottom: "8px" }}>
                    <b>총 {won(total)}원</b>
                  </div> */}
                </td>
              </tr>
            </table>

            <table width={"100%"} style={{ marginLeft: "-3px" }}>
              <tbody className="status-title">
                {pill && pill !== "아니요" && (
                  <tr style={{fontSize:'15px'}}>
                    <td></td>
                    <td>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base}{" "}
                      {PRODUCTS[pill].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(pillPrice)}원
                    </td>
                  </tr>
                )}
                {minox && minox !== "아니요" && (
                  <tr style={{fontSize:'15px'}}>
                    <td></td>
                    <td>
                      {PRODUCTS[minox].name} (
                      {minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit}
                      ){/* {minox} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(minoxPrice)}원
                    </td>
                  </tr>
                )}
                {shamp && (
                  <tr style={{fontSize:'15px'}}>
                    <td></td>
                    <td>
                      {PRODUCTS[shamp].name} ({PRODUCTS[shamp].base}{" "}
                      {PRODUCTS[shamp].unit})
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(shampPrice)}원
                    </td>
                  </tr>
                )}
                <tr style={{fontSize:'15px'}}>
                  <td></td>
                  <td>진료비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    10,000원
                  </td>
                </tr>
                <tr style={{fontSize:'15px'}}>
                  <td></td>
                  <td>배송비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    2,900원
                  </td>
                </tr>
                &nbsp;
                <tr style={{ fontWeight: "bolder", fontSize:'15px' }}>
                  <td></td>
                  <td>총 예상금액</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    {won(total)}원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="line-orange-status" />

          {/* 담당의사 */}
          <div className="" style={{ paddingBottom: "25px" }}>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>담당의사 정보</b>
            </div>
            {doctorInfo && (
              <>
                <div>{doctorInfo.name} 의사</div>
                <div>{doctorInfo.office}</div>
                <div>{doctorInfo.phone}</div>
              </>
            )}
            {!doctorInfo && <div>담당의사 배정중 입니다.</div>}
          </div>
        </div>
        <div id="status-fake-curve-bottom"></div>
      </div>
    </>
  );
}
export default StatusStep1;
