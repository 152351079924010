import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";

import check from "../assets/greenCheck.json";

// css
import "../styling/componentStyling.css";

export default function KeyNotes() {
  const aniRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      aniRef.current.play();
    }, 2000);
  }, []);

  return (
    <div className="keynote-container">
      <div
        className="keynote-frame"
        style={{ border: ".3px solid #8c8c8c", overflow: "hidden" }}
      >
        <Lottie
          ref={aniRef}
          animationData={check}
          style={{
            width: "320px",
            height: "320px",
            position: "absolute",
            // right: "15px",
            // top: "-30px",
            right: -80,
            top: -80,
            filter: "opacity(0.1)",
          }}
          autoPlay={false}
          loop={false}
        ></Lottie>
        <div className="keynote-title">Key Notes</div>
        <ul>
          <li>
            탈모약을 처음 쓰면 일시적으로 모발이 더 빠지는 쉐딩현상이 나타날 수
            있어요.
          </li>
          <li>
            쉐딩현상은 보통 1-2주차 때 나타나게 되고, 2-3개월 정도 진행돼요.
          </li>
          <li>탈모약의 효과는 보통 3개월 이후부터 느끼실 수 있어요!</li>
        </ul>
      </div>
    </div>
  );
}
