import { useEffect, useState } from "react";
import { updateChart } from "utils/data";
import { PRODUCTS } from "utils/products";
import { getPharmaInfo } from "utils/util";
import { getDoctorInfo } from "utils/util";

import "./jungsanStyles.css"

function JungsanViewer(props) {
  const [charts, setCharts] = useState([]);
  const [range, setRange] = useState("2023-01");
  const [doctor, setDoctor] = useState("doctor1");
  const [pharma, setPharma] = useState("pharma1");
  const [doctorPayCharts, setDoctorPayCharts] = useState([]);
  const [doctorCancelCharts, setDoctorCancelCharts] = useState([]);
  const [denyCount, setDenyCount] = useState(0);
  const [pharmaCharts, setPharmaCharts] = useState([]);
  const [pharmaBucket, setPharmaBucket] = useState([]);

  useEffect(() => {
    setCharts(props.charts);
  }, [])
  return (
    <>
      <div className="scrollable2" style={{ padding: "20px" }}>
        <div>
          <input type="text" value={range} onChange={(e) => {
            setRange(e.target.value);
          }}>
          </input>

          <select onChange={(e) => {
            setDoctor(e.target.value);
          }}>
            <option selected={doctor==="doctor1"} value="doctor1">{getDoctorInfo("doctor1").office}</option>
            <option selected={doctor==="doctor2"} value="doctor2">{getDoctorInfo("doctor2").office}</option>
            <option selected={doctor==="gaonsamsung"} value="gaonsamsung">{getDoctorInfo("gaonsamsung").office}</option>
          </select>
          <button onClick={() => {
            let doctorPayArray = [];
            let doctorCancelArray = [];
            let denyCounter = 0;
            for (let c of charts) {
              if (c.yeyak_time) {
                if (c.doctor === doctor && c.yeyak_time.startsWith(range)) {
                  if (c.status === "pay" || c.status === "deli_done" || c.status === "paid" || c.status === "deny_prescription") {
                    doctorPayArray.push(c);
                    if (c.status === "deny_prescription") {
                      denyCounter++;
                    }
                  } else {
                    doctorCancelArray.push(c);
                  }
                }
              }
            }
            setDoctorPayCharts(doctorPayArray);
            setDenyCount(denyCounter);
          }}>의사정산 보기</button>
        </div>

        <div>
          <select onChange={(e) => {
            setPharma(e.target.value);
          }}>
            <option selected={pharma==="pharma1"} value="pharma1">{getPharmaInfo("pharma1").office}</option>
            <option selected={pharma==="totheworld"} value="totheworld">{getPharmaInfo("totheworld").office}</option>
          </select>

          <button onClick={() => {
            console.log("pharma clicked!")
            let pharmaArr = [];
            let bucket = {};
            for (let c of charts) {

              if (c.medi_done_time) {
                console.log(c);
                if (c.pharma === pharma && c.medi_done_time.startsWith(range)) {

                  pharmaArr.push(c);

                  bucket[`${c.pill}_${c.pillMonth}`] = bucket[`${c.pill}_${c.pillMonth}`] ? (bucket[`${c.pill}_${c.pillMonth}`] + 1) : 1;
                  bucket[`${c.minox}_${c.minoxMonth}`] = bucket[`${c.minox}_${c.minoxMonth}`] ? (bucket[`${c.minox}_${c.minoxMonth}`] + 1) : 1;
                  bucket[`${c.capil}_${c.capilMonth}`] = bucket[`${c.capil}_${c.capilMonth}`] ? (bucket[`${c.capil}_${c.capilMonth}`] + 1) : 1;
                }
              }
            }
            setPharmaCharts(pharmaArr);

            let bucketArray = [];
            for (const [key, value] of Object.entries(bucket)) {
              if (!(key.startsWith("_") || key.startsWith("null") || key.startsWith("아니요"))) {
                // console.log(`${key}: ${value}`);
                bucketArray.push({key:key, count: value});
              }

            }

            setPharmaBucket(bucketArray);
            

          }}>약사정산 보기</button>

        </div>
        {/* pharma bucket */}
        <div>
          <table className="styled-table">
            <tbody>
              <tr>
                <th>약품명</th>
                <th>개월수</th>
                <th>조제건수</th>
                <th>건당비용</th>
                <th>총비용</th>
              </tr>
              {pharmaBucket.map(c => {
                return (
                  <tr>
                    <td>{PRODUCTS[c.key.split('_')[0]].name}</td>
                    <td>{c.key.split('_')[1]} 개월</td>
                    <td>{c.count}</td>
                    <td>{PRODUCTS[c.key.split('_')[0]].price[c.key.split('_')[1]]}</td>
                    <td>{PRODUCTS[c.key.split('_')[0]].price[c.key.split('_')[1]] * c.count}</td>
                  </tr>
                )

              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="styled-table">
            <tbody>
              <tr>
                <th>조제완료시간</th>
                <th>고객이름</th>
                <th>pill</th>
                <th>pillMonth</th>
                <th>minox</th>
                <th>minoxMonth</th>
                <th>capil</th>
                <th>capilMonth</th>
              </tr>
              {pharmaCharts.map(c => {
                return (
                  <tr>
                    <td>{new Date(c.medi_done_time).toLocaleString()}</td>
                    <td>{c.name}</td>
                    <td>{c.pill}</td>
                    <td>{c.pillMonth}</td>
                    <td>{c.minox}</td>
                    <td>{c.minoxMonth}</td>
                    <td>{c.capil}</td>
                    <td>{c.capilMonth}</td>
                  </tr>
                )

              })}
            </tbody>
          </table>
        </div>

        <div>
          <div>
            {/* [정산 해드려야하는 고객] [{doctorPayCharts.length}명] */}
            <table className="styled-table">
              <tbody>
                <tr>
                  <td>기간</td><td>{range}월</td>
                </tr>
                <tr>
                  <td>진료건수</td><td>{doctorPayCharts.length} 건</td>
                </tr>
                <tr>
                  <td>진료비</td><td>건당 10,000원</td>
                </tr>
                <tr>
                  <td>총 진료비</td><td>{doctorPayCharts.length * 10000}원</td>
                </tr>
                <tr>
                  <td>카드수수료(1.7%)</td><td>{doctorPayCharts.length * 10000 * 0.017}원</td>
                </tr>
                <tr>
                  <td>지급금액</td><td>{((doctorPayCharts.length * 10000) - (doctorPayCharts.length * 10000 * 0.017))}원</td>
                </tr>
                <tr>
                  <td>기타내용</td><td>처방거부 {denyCount}건이 정산에 포함되어 있습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <table className="styled-table">
            <tbody>
              <tr>
                <th>진료시간</th>
                <th>고객이름</th>
                <th>status</th>
                <th>dstatus</th>
                <th>isPaid</th>
              </tr>
              {doctorPayCharts.map(c => {
                return (
                  <tr>
                    <td>{new Date(c.yeyak_time).toLocaleString()}</td>
                    <td>{c.name}</td>
                    <td>{c.status}</td>
                    <td>{c.dstatus}</td>
                    <td>{c.isPaid}</td>
                  </tr>
                )

              })}
            </tbody>
          </table>

        </div>
      </div>
    </>
  )
}
export default JungsanViewer;