import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "reactstrap";

// images
import ReportOne from "../dna-event-assets/Q5DNA1.png";
import ReportTwo from "../dna-event-assets/Q5DNA2.png";
import DownArrow from "../dna-event-assets/DownArrow.png";
import UpArrow from "../dna-event-assets/UpArrow.png";

// css
import "../dnaEventStyles.css";

export default function FifthQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [buttonArrow, setButtonArrow] = useState(DownArrow);
  const [titleStyle, setTitleStyle] = useState();
  const titleRef = useRef();

  const itemToView = document.getElementById("collapse-content-fifth");

  const toggle = () => {
    setCollapse(!collapse);
    setTitleStyle(titleRef.current.style.fontWeight);
    if (buttonArrow === DownArrow) {
      setButtonArrow(UpArrow);
    } else {
      setButtonArrow(DownArrow);
    }
  };

  useEffect(() => {
    if (titleStyle === "400") {
      titleRef.current.style.fontWeight = "600";
    } else {
      titleRef.current.style.fontWeight = "400";
    }

    if (collapse) {
      setTimeout(() => {
        itemToView.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 260);
    }
  }, [collapse]);

  const imageContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gridTemplateRows: "auto",
    placeItems: "center",
    gap: "5px",
    marginTop: "-15px",
    paddingBottom: "15px",
  };

  return (
    <div className="dna-question-wrapper">
      <button id="dna-toggle-button" onClick={toggle}>
        <p ref={titleRef} id="dna-main-title">
          유전자검사 결과는 어떻게 나오나요?
        </p>
        <img id="dna-button-image" src={buttonArrow} />
      </button>
      <Collapse isOpen={collapse}>
        <div id="collapse-content-fifth">
          <p id="dna-text-list-body">
            안전, 관심, 주의, 경계 4단계의 정확하고 세밀한 분석결과와 함께,
            관리방법, 예방법 등의 정보를 제공합니다.
            <br />
            <br />
            (검사결과 리포트)
          </p>
          <div style={imageContainer}>
            <img src={ReportOne} id="zoomFirst" />
            <img src={ReportTwo} id="zoomSecond" />
          </div>
          <p id="dna-text-disclaimer">
            본 검사는 검사 결과가 갖는 임상적 의미가 확립되지 않았으며, 이에
            따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직
            부족합니다. 본 분석결과는 탈모치료 시 참고정보로 활용해 주세요! 검사
            후 MARS로 비대면 진료시 담당의사가 해당내용도 참고하여 진료를
            진행합니다.
          </p>
        </div>
      </Collapse>
    </div>
  );
}
