
import { updateMars } from "utils/data";
import ExoCustomerCard from "./ExoCustomerCard";
import PhotoChartCard from "./OperationCardNew";
import ShampooCard from "./ShampooCard";
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";

function NeshViewer(props) {
  return (
    <>
      {/* <div> db cleanup
        <div>
          <button onClick={async () => {
            let neshes = props.neshes;
            for (let nesh of neshes) {
              if (nesh.status === "shamp_sent") {
                // console.log(nesh);
                let neshInfo = {
                  id: nesh.id,
                  status: "shamp_deli_done",
                  ignoreStatus: "yes"
                }
                let res = await updateMars(neshInfo);
                console.log(res);
              }
            }
          }}>db cleanup</button>
        </div>
      </div> */}
      
      <div className="scrollable2" style={{ padding: "20px" }}>
        {props.neshes.map((e) => {
          if (e.status === props.status) {
            return (
              <ShampooCard
                key={e.id}
                patient={e}
              />
            );
          }
        })}
      </div>
    </>
  )
}
export default NeshViewer;