import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useHistory } from "react-router";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import { PRODUCTS } from "utils/products";

// assets
import profileCamera from "../assets/profileCamera.json";
import arrowBack from "../assets/arrowBack.png";

export default function UserPhotos(props) {
  const { userID, kid } = props.location.state;
  const [userPhotos, setUserPhotos] = useState();
  const history = useHistory();

  useEffect(() => {
    // set user photos state here
    var mockData = [
      { pictureURL: "", date: "2022. 01. 01" },
      { pictureURL: "", date: "2022. 04. 01" },
      { pictureURL: "", date: "2022. 07. 01" },
    ];
    setUserPhotos(mockData);
  }, []);

  return (
    <div className="user-container">
      <MarsNavBarMain></MarsNavBarMain>
      <button
        className="profile-return-button"
        onClick={() => {
          history.push("/myPageNew");
        }}
      >
        <img style={{ marginLeft: "22px" }} src={arrowBack} />
      </button>
      <div className="user-info-display">
        <div className="user-info-title">
          <span>사진기록</span>
          <Lottie
            animationData={profileCamera}
            autoPlay={true}
            id="profile-lottie"
          ></Lottie>
        </div>
      </div>
      <div className="user-history-container">
        {userPhotos && userPhotos.length > 0 ? (
          userPhotos.map((item, key) => {
            return (
              <div className="user-history-card" key={key}>
                {item.date}
                <div>
                  <img src={""} />
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <div className="user-data-button">
        <button
          onClick={() => {
            history.push("/myPageNew");
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
}
