import LightFooter from "components/Footers/LightFooter";
import React, { useState } from "react";
import { slack } from "utils/util";
import First from "./First";
import Gene from "./Gene";
import How from "./How";
import LandingHeader from "./LandingHeader";
import Partner from "./Partner";
import Review from "./Review";
import When from "./When";
import Price from "./Price";
import { sendAnalytics } from "utils/analytics";


function LandingPage5(props) {
  const [isIndex, setIsIndex] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  React.useEffect(() => {    
    if (props.location.pathname == "/index") {
      setIsIndex(false);
    }
    sendAnalytics({ "type": "log", "from": props.location.pathname, "event": "landing.visit" });
    slack("[Landing-Page:" + props.location.pathname + "] 방문");
    
  }, [])
  return (
    <>
      {/* <MarsNavbarLanding />*/}
      <div className="wrapper scrollable">
        <LandingHeader isIndex={isIndex} />
        <When isIndex={isIndex} />
        <How isIndex={isIndex} />
        <Gene isIndex={isIndex} />
        <Price isIndex={isIndex} />
        <First isIndex={isIndex} />
        <Review isIndex={isIndex} />
        <Partner isIndex={isIndex} />
        {/* <Examples /> */}
        <LightFooter />
      </div>
      {/* <LightFooter /> */}
    </>
  );
}
export default LandingPage5;
