import React from "react";
import { motion } from "framer-motion";

// assets
import strand from "../assets/dnaStrand.png";

// css
import "./exogenePageStyling.css";

export default function ExogeneStep() {
  return (
    <div className="exogene-step-container">
      <div className="exogene-content-wrapper">
        <img src={strand} id="dna-strand-step" alt="exo-dna-strand" />
        <span
          className="exogene-title-med"
          style={{ color: "#333D96", marginBottom: "43px" }}
        >
          유전자 검사 절차
        </span>
        <ul className="exogene-list">
          <div id="exogene-place-bg"></div>
          <motion.li
            initial={{ x: "60vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className="exogene-circle"
            style={{ marginTop: "15px" }}
          >
            <span id="exogene-number">1</span>
            <span id="exogene-desc-default">MARS에서 신청하기</span>
          </motion.li>
          <motion.li
            initial={{ x: "60vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2, delay: 0.4 }}
            className="exogene-circle"
          >
            <span id="exogene-number">2</span>
            <span id="exogene-desc-default">유전자 홈키트 택배로 수령</span>
          </motion.li>
          <motion.li
            initial={{ x: "60vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2, delay: 0.6 }}
            className="exogene-circle"
          >
            <span id="exogene-number">3</span>
            <span id="exogene-desc-default">시료 채취 및 반송</span>
          </motion.li>
          <motion.li
            initial={{ x: "60vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2, delay: 0.8 }}
            className="exogene-circle"
          >
            <span id="exogene-number">4</span>
            <span id="exogene-desc-default">유전자 검사 및 분석</span>
          </motion.li>
          <motion.li
            initial={{ x: "60vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.2, delay: 1 }}
            className="exogene-circle"
          >
            <span id="exogene-number">5</span>
            <span id="exogene-desc-default">MARS에서 검사결과 확인</span>
          </motion.li>
        </ul>
      </div>
    </div>
  );
}
