import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import MarsLogoBlack from "../../../assets/img/mars_logo_black.png";

import DNAFail from "../dnaEvent/dna-event-assets/animations/DNAFail.json";

// assets

export default function DnaExpired() {
  return (
    <div
      style={{
        background: "white",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        placeItems: "center",
        justifyContent: "center",
      }}
    >
      <header
        style={{
          height: "52px",
          position: "absolute",
          top: 0,
          borderBottom: ".5px solid gray",
          width: "100vw",
          display: "grid",
          placeItems: "center",
        }}
      >
        <img style={{ width: "57px", height: "auto" }} src={MarsLogoBlack} />
      </header>
      <Lottie
        animationData={DNAFail}
        autoPlay={true}
        style={{ width: "100px", height: "auto" }}
      ></Lottie>
      <span style={{ fontWeight: 600, fontSize: "18px" }}>
        마감 된 이벤트 입니다
      </span>
    </div>
  );
}
