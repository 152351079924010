// img
import Q from "../../assets/exogeneQ.png";
import imageOne from "./faq_assets/Q2IM1.png";
import imageTwo from "./faq_assets/Q2IM2.png";

// css
import "./faqPagesStyling.css";

export default function Q2() {
  return (
    <div className="exogene-question-slide">
      <span id="exogene-q">
        <img src={Q} alt="question-placeholder" />
        <span className="exogene-question-head">
          유전자 검사 결과는 어떻게 나오나요?{" "}
        </span>
      </span>
      <div className="exogene-faq-wrapper">
        <div className="exogene-faq-question">
          <span className="exogene-question-detail">
            안전, 관심, 주의, 경계 4단계의 정확하고 세밀한 분석 결과와 함께,
            관리 방법, 예방법 등의 정보를 제공합니다.
          </span>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              id="exogene-Q2-img"
              src={imageOne}
              style={{ marginLeft: "-2px" }}
              alt="exo-one"
            ></img>
            <img id="exogene-Q2-img" src={imageTwo} alt="exo-two"></img>
          </div>
          <span className="exogene-question-disclaimer">
            본 검사는 검사 결과가 갖는 임상적 의미가 확립되지 않았으며, 이에
            따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직
            부족합니다. 본 분석결과는 탈모치료 시 참고정보로 활용해 주세요! 검사
            후 MARS로 비대면 진료시 담당의사가 해당내용도 참고하여 진료를
            진행합니다.
          </span>
        </div>
      </div>
    </div>
  );
}
