import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { slack } from "utils/util";


// core components

function Gene(props) {
  return (
    <>
      <div className="landing4 landing4-background-white">

        <Row className="justify-content-center">
          <Col md="6" xs="12" >
            <Row className="text-center justify-content-center">
              <Col md="10" xs="12">
                {/* <img width="100" alt="..." src={require("assets/img/capsules-solid.svg")}></img> */}
                <div className="heading2" style={{ textAlign: "left", paddingBottom: "29px" }}>
                  DNA검사도 가능한<br />
                  <b style={{ color: "#FA5724" }}>탈모 올인원</b> 서비스
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={10} xs={12}>
                <div style={{ marginBottom:"-60px"}}>
                  <img alt="..." src={require("assets/img/exogene-landing.png")} style={{ width: "100%" }}></img>
                </div>
              </Col>
            </Row>

            {/* 카카오 시작하기 버튼 */}
            <Row className="justify-content-center">
              <Col md={10} xs={12}>
                <div
                style={{marginTop:"-40px"}}
                  className="kakao-btn"
                  onClick={() => {
                    if (props.isIndex) {
                      slack("index start button clicked");
                      alert("서비스 준비 중  입니다.")
                      return;
                    }
                    slack("[landing4/why] 시작버튼 클릭");
                    window.Kakao.Channel.addChannel({
                      channelPublicId: "_fMNCb",
                    });
                  }}
                >
                  <img alt="..." src={require("assets/img/kakao_logo.png")} style={{ width: "20px", marginRight: "8px" }}></img>
                  카카오로 시작하기
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}
export default Gene;
