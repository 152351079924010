import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

// img
import logo from "../../assets/img/marsSvgLogo.svg";
import loader from "./surveyAssets/lottie/bufferSpinner.json";

// css
import "./styling/surveyBufferStyles.css";

export default function SurveyBuffer() {
  return (
    <div className="survey-buffer-container">
      <div className="survey-buffer-header">
        <img src={logo} alt="mars-logo" />
      </div>
      <div className="survey-buffer-content-container">
        <Lottie animationData={loader}></Lottie>
        <p id="survey-buffer-subtitle">
          환영해요:) <br /> 문진을 시작합니다!
        </p>
      </div>
    </div>
  );
}
