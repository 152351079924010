import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function CardConfirm(props) {
  const { isOpen, setOpenConfirm, cardName, cardNum, handleRemove } = props;
  const toggle = () => setOpenConfirm(!isOpen);

  const modalStyle = {
    width: "330px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "25vh",
    height: "200px",
  };

  const modalBody = {
    textAlign: "center",
    display: "grid",
    placeItems: "center",
    height: "115px",
    paddingTop: "45px",
    justifyContent: "center",
  };

  const modalFooter = {
    display: "flex",
    flexDirection: "row",
    borderTop: " 0.5px solid #8C8C8C",
    marginTop: "25px",
    padding: 0,
  };

  const cancelButton = {
    width: "50%",
    background: "#FFFFFF",
    color: "black",
    height: "45px",
    border: "none",
    borderBottomLeftRadius: "10px",
    borderRight: "0.5px solid #8C8C8C",
    fontSize: "15px",
    fontWeight: 400,
  };

  const confirmButton = {
    width: "50%",
    background: "#FFFFFF",
    color: "black",
    height: "45px",
    border: "none",
    borderBottomRightRadius: "10px",
    color: "black",
    fontSize: "15px",
    fontWeight: 600,
  };

  const textStyle = {
    fontFamily: "NanumBarunGothic",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "25px",
  };

  //   const parseCardInfo = () => {
  //     return card
  //   };
  const parseCardNum = (num) => {
    return num.substr(num.length - 4);
  };

  return (
    <Modal
      style={modalStyle}
      isOpen={isOpen}
      toggle={toggle}
      id="reservation-modal"
      height="200px"
    >
      <ModalBody style={modalBody}>
        <p style={textStyle}>
          등록하신{" "}
          <span style={{ color: "#6A43B8" }}>
            {cardName} {parseCardNum(cardNum)}
          </span>
          를 삭제하시겠어요?
        </p>
      </ModalBody>
      <ModalFooter style={modalFooter}>
        <Button onClick={() => setOpenConfirm(false)} style={cancelButton}>
          아니요
        </Button>
        <Button
          style={confirmButton}
          onClick={() => {
            handleRemove();
            setOpenConfirm(false);
          }}
        >
          예
        </Button>
      </ModalFooter>
    </Modal>
  );
}
