import React from "react";

// assets
import logo from "../../../../assets/img/mars_logo_black.png";

import "../styling/componentStyling.css";

export default function Nav(props) {
  const { title } = props;
  return (
    <div className="pretotype-nav">
      <img src={logo} />
      <div>{title}</div>
    </div>
  );
}
