import React, { useState, useEffect } from "react";

// css
import "./doctorTableStyles.css";

/**
 *
 * @param {answers, hairlineKey, haircenterKey, hairgeneralKey} props
 *
 */
export default function FVChartV15(props) {
  // FV Use Questionnaire / Answers States for display
  const { answers, id } = props;
  const [fvBaldness, setFvBaldness] = useState();
  const [fvWhenStart, setFvWhenStart] = useState();
  const [fvOtherCare, setFvOtherCare] = useState();
  const [fvStress, setFvStress] = useState();
  const [fvHeredity, setFvHeredity] = useState();
  const [fvWantMedication, setFvWantMedication] = useState();
  const [fvWantCopy, setFvWantCopy] = useState();
  const [fvMessage, setFvMessage] = useState();

  const [scalpType, setScalpType] = useState();

  useEffect(() => {
    // parse answers
    let ans = props.answers;
    try {
      setFvBaldness(ans.fv_baldness.ans[0]);
      setFvWhenStart(ans.fv_when_start.ans[0]);
      setFvOtherCare(ans.fv_other_care.ans[0]);
      setFvStress(ans.fv_stress.ans[0]);
      setFvHeredity(ans.fv_heredity.ans[0]);
      setFvWantMedication(ans.fv_want_medication.ans[0]);
      setFvWantCopy(ans.fv_want_copy.ans[0]);
      setFvMessage(ans.fv_message.ans);
      setScalpType(ans.fv_scalp_type.ans[0]);
    } catch (error) {
      console.log("fvchart parsing error: ", error);
    }
  }, []);

  const fixStress = (type) => {
    switch (type) {
      case "네, 있어요":
        return "있어요";
      case "아니요":
        return "없어요";
    }
    return "";
  };

  const fixWantMed = (type) => {
    switch (type) {
      case "네":
        return "있어요";
      case "아니요":
        return "없어요";
    }
    return "";
  };

  const fixPref = (type) => {
    switch (type) {
      case "정품약":
        return "정품약";
      case "가성비":
        return "카피약";
    }
    return "";
  };

  const fixHered = (type) => {
    switch (type) {
      case "네, 친가에 있어요":
        return "친가에 있어요";
      case "네, 외가에 있어요":
        return "외가에 있어요";
      case "네, 친가 외가 모두 있어요":
        return "친가, 외가 모두 있어요";
      case "아니요, 탈모 가족력은 없어요":
        return "없어요";
    }
    return "";
  };

  const fixSupp = (type) => {
    switch (type) {
      case "해당사항 없음":
        return "없음";
      case "미녹시딜 계열의 바르는약":
        return "미녹시딜";
      case "모발 영양제 (비오틴, 쏠파메토 등)":
        return "영양제";
      case "탈모샴푸, 컨디셔너, 토너":
        return "탈모샴푸";
      case "모발이식":
        return "모발이식";
      case "탈모병원 탈모치료 (MTS등)":
        return "탈모치료 MTS";
      case "두피관리 마사지 (스케일링)":
        return "스케일링";
    }
    return "";
  };

  const copyItem = () => {
    var copyText = document.getElementsByClassName(id);
    var append = "";
    for (let i = 0; i < copyText.length; i++) {
      append = append.concat(" - " + copyText[i].innerText + "\n");
    }
    navigator.clipboard.writeText(append).then(
      () => {
        alert("문진 복사되었습니다");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      {/* doctor nth-child using odd colors */}
      <button id="copy-button-doctor" onClick={copyItem}>
        문진복사
      </button>
      <table className="fv-chart-table">
        <tbody>
          <tr>
            <li className={id}>
              탈모처방약을
              <span id="doctor-patient-answer"> 복용한 경험이 없어요</span>
            </li>
          </tr>
          <tr>
            <li className={id}>
              <span id="doctor-patient-answer">{fvWhenStart} </span>
              <span id="doctor-patient-answer">{fvBaldness}</span> 진행
            </li>
          </tr>
          <tr>
            <li className={id}>
              {" "}
              최근 평소보다 심한{" "}
              <span id="doctor-patient-answer">
                스트레스가 {fixStress(fvStress)}
              </span>
            </li>
          </tr>
          <tr>
            <li className={id}>
              탈모처방약을{" "}
              <span id="doctor-patient-answer">
                <mark style={{ background: "#E6FFA0" }}>
                  복용할 의향이 {fixWantMed(fvWantMedication)}
                </mark>
              </span>
            </li>
          </tr>
        </tbody>
        <tbody>
          {fvWantMedication && (
            <tr>
              <li className={id}>
                {fvWantCopy ? (
                  <span id="doctor-patient-answer">
                    {"처방시 " + fixPref(fvWantCopy) + "을 더 선호해요"}
                  </span>
                ) : (
                  "처방약과 일반의약품의 효과 차이를 설명해 주세요"
                )}
              </li>
            </tr>
          )}
          <tr>
            <li className={id}>
              탈모{" "}
              <span id="doctor-patient-answer">
                가족력이 {fixHered(fvHeredity)}
              </span>
            </li>
          </tr>
          <tr>
            <li className={id}>
              두피타입:{" "}
              <span id="doctor-patient-answer">
                {answers.fv_scalp_type.ans[0]}
              </span>
            </li>
          </tr>
          <tr>
            {fvOtherCare !== "해당사항 없음" ? (
              <li
                style={{
                  lineHeight: "18px",
                  textIndent: "-20px",
                  paddingLeft: "30px",
                }}
                className={id}
              >
                {" "}
                {/* <span id="doctor-patient-answer">{fixSupp(fvOtherCare)}</span> */}
                <span id="doctor-patient-answer">
                  {answers.fv_other_care.ans.map((item) => {
                    return fixSupp(item) + ",";
                  })}
                </span>
                (으)로 탈모증상 관리 중
              </li>
            ) : (
              <></>
            )}
          </tr>
        </tbody>
      </table>
      <div className="fv-chart-additional-info">
        <div>
          <p
            style={{
              color: "#585260",
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            추가메시지
          </p>
          <div
            style={{
              marginTop: "-15px",
              background: "#FFFFFF",
              border: "0.5px solid #B3B3B3",
              width: "300px",
              height: "122px",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <span style={{ color: "#333333", fontSize: "15px" }}>
              {fvMessage || "없음"}
            </span>
          </div>
        </div>

        <div>
          <p
            style={{
              color: "#585260",
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            사진첨부
          </p>
          <div
            style={{
              background: "red",
              width: "100%",
              marginTop: "-15px",
              background: "#FFFFFF",
              border: "0.5px solid #B3B3B3",
              width: "300px",
              minWidth: "300px",
              height: "122px",
              display: "flex",
              justifyContent: "space-evenly",
              padding: "12px 5px 0px 5px",
              borderRadius: "4px",
            }}
          >
            {props.hairlineKey || props.haircenterKey ? (
              <td>
                <tr>
                  {/* <td>
                    <a href={props.hairgeneralKey} target="_blank">
                      <img
                        src={props.hairgeneralKey}
                        style={{
                          width: "94px",
                          height: "94px",
                          borderRadius: "5px",
                          marginRight: "2px",
                        }}
                      />
                    </a>
                  </td> */}
                  <td>
                    <a href={props.hairlineKey} target="_blank">
                      <img
                        src={props.hairlineKey}
                        style={{
                          width: "94px",
                          height: "94px",
                          borderRadius: "5px",
                          marginRight: "20px",
                        }}
                      />
                    </a>
                  </td>
                  <td>
                    <a href={props.haircenterKey} target="_blank">
                      <img
                        src={props.haircenterKey}
                        style={{
                          width: "94px",
                          height: "94px",
                          borderRadius: "5px",
                        }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <a href={props.hairgeneralKey} target="_blank">
                      {/* 확대하기 */}
                    </a>
                  </td>
                  <td className="text-center">
                    <a href={props.hairlineKey} target="_blank">
                      {/* 확대하기 */}
                    </a>
                  </td>
                  <td className="text-center">
                    <a href={props.haircenterKey} target="_blank">
                      {/* 확대하기 */}
                    </a>
                  </td>
                </tr>
              </td>
            ) : (
              <tr style={{ marginRight: "auto", marginLeft: "5px" }}>없음</tr>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
