// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * This Symbol is used to reference an internal-only PubSub provider that
 * is used for AppSync/GraphQL subscriptions in the API category.
 */
var hasSymbol = typeof Symbol !== 'undefined' && typeof Symbol.for === 'function';
/**
 * @deprecated Unused, all usecases have migrated to INTERNAL_AWS_APPSYNC_REALTIME_PUBSUB_PROVIDER
 */
export var INTERNAL_AWS_APPSYNC_PUBSUB_PROVIDER = hasSymbol
    ? Symbol.for('INTERNAL_AWS_APPSYNC_PUBSUB_PROVIDER')
    : '@@INTERNAL_AWS_APPSYNC_PUBSUB_PROVIDER';
export var INTERNAL_AWS_APPSYNC_REALTIME_PUBSUB_PROVIDER = hasSymbol
    ? Symbol.for('INTERNAL_AWS_APPSYNC_REALTIME_PUBSUB_PROVIDER')
    : '@@INTERNAL_AWS_APPSYNC_REALTIME_PUBSUB_PROVIDER';
export var USER_AGENT_HEADER = 'x-amz-user-agent';
