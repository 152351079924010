import MarsNavbar from "components/Navbars/MarsNavbar";
import background from "assets/img/event_landing_bg.png";
import { useEffect, useState } from "react";
import MarsLoaderSm from "views/components/MarsLoaderSm";
import { Auth, Storage } from "aws-amplify";
import { FormGroup, Input, Label } from "reactstrap";

function PhotoEventDone(props) {


  useEffect(() => {

  }, []);



  return (
    <>
      <div className="scrollable">
        <div onClick={()=>{
           window.location.href = "kakaotalk://inappbrowser/close";
        }}>
          <img alt="..." src={require("assets/img/photo_event_landing_done.png")} style={{ width: "100vw", marginTop: "-4px" }}></img>
        </div>

      </div>
    </>
  )

}
export default PhotoEventDone;