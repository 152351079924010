import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { updateChart } from "utils/data";
import { parseSurvey, slack2ops, won } from "utils/util";
import { sendAnalytics } from "utils/analytics";
import { getDoctorInfo } from "utils/util";
import * as mutations from "../../../graphql/mutations";
import { parsePhoneNumber } from "utils/util";
import { maskSSN } from "utils/util";
import { API, Storage } from "aws-amplify";
import { getENV } from "utils/util";

// css
import "./styling/profileEditorStyles.css";

export default function AddressEditor(props) {
  const { openAddressEdit, setOpenAddressEdit, profileEditor } = props;
  const toggle = () => setOpenAddressEdit(!openAddressEdit);

  return (
    <Modal isOpen={openAddressEdit} toggle={toggle}>
      <ModalBody className="op-profile-info-container">
        <p style={{ placeSelf: "center", fontSize: "15px", fontWeight: 600 }}>
          주소정보 수정
        </p>
        <div id="edit-name" style={{ display: "none" }}>
          <div id="edit-info">이름: </div>
          <input
            type="text"
            value={profileEditor.name}
            onChange={(e) => {
              profileEditor.setName(e.target.value);
            }}
            disabled={true}
          />
        </div>
        <div id="edit-ssn" style={{ display: "none" }}>
          <div id="edit-info">주민번호:</div>
          <input
            type="text"
            value={profileEditor.ssn}
            onChange={(e) => {
              profileEditor.setSSN(e.target.value);
            }}
            disabled={true}
          />
        </div>
        <div id="edit-phone" style={{ display: "none" }}>
          <div id="edit-info">연락처:</div>
          <input
            type="text"
            value={profileEditor.phone}
            onChange={(e) => {
              profileEditor.setPhone(e.target.value);
            }}
            disabled={true}
          />
        </div>

        <div id="edit-addr">
          <div id="edit-info">주소:</div>
          <textarea
            rows={4}
            type="textarea"
            value={profileEditor.addr}
            onChange={(e) => {
              profileEditor.setAddr(e.target.value);
            }}
          />
        </div>

        <div id="edit-addr2">
          <div id="edit-info">상세주소:</div>
          <input
            type="text"
            value={profileEditor.addr2}
            onChange={(e) => {
              profileEditor.setAddr2(e.target.value);
            }}
          />
        </div>

        <div id="edit-zip">
          <div id="edit-info">우편번호</div>
          <input
            type="text"
            value={profileEditor.zip}
            onChange={(e) => {
              profileEditor.setZip(e.target.value);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="op-profile-footer">
        <button
          onClick={() => {
            setOpenAddressEdit(false);
          }}
          id="profile-edit-cancel-button"
        >
          취소
        </button>
        <button
          onClick={async () => {
            if (
              window.confirm(
                "정말로 " +
                profileEditor.patient.name +
                "님의 환자 정보를 수정 하시겠습니까?"
              )
            ) {
              const patientDetails = {
                id: `user#${profileEditor.patient.kid}`,
                addr: profileEditor.addr,
                addr2: profileEditor.addr2,
                zip: profileEditor.zip,
                ignoreStatus: "refresh",
              };

              try {
                let updatedPatient = await API.graphql({
                  query: mutations.updateMars,
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                  variables: { input: patientDetails },
                });

                if (updatedPatient) {
                  //send kakao message
                  let users = [
                    { type: "appUserId", id: profileEditor.patient.kid },
                  ];
                  let eventName =
                    "event_user_addr_changed" +
                    (getENV() !== "prod" ? "_dev" : "");
                  let eventData = {
                    msg: `${profileEditor.name}님의 주소가 변경 되었어요.\n\n주소:${profileEditor.addr} ${profileEditor.addr2}, ${profileEditor.zip}`,
                  };
                  let res = await API.post("marsrest", "/kakao", {
                    body: { users, eventName, eventData },
                  });

                  alert(profileEditor.patient.name + "님 주소정보 변경 완료!\n");
                  slack2ops(
                    `[운영][${profileEditor.name}] 주서정보 변경\n변경주소:${profileEditor.addr} ${profileEditor.addr2} ${profileEditor.zip}`
                  );
                }
              } catch (error) {
                console.log("에러발생 다시 시도해 주세요: " + error);
              }
            }
            setOpenAddressEdit(false);
          }}
          id="profile-edit-submit-button"
        >
          주소정보 수정
        </button>
      </ModalFooter>
    </Modal>
  );
}
