import { Button, Modal } from "reactstrap";
import React, { useRef, useEffect, useState } from "react";
import Picker from "rmc-picker";

export default function OperationTimeModal(props) {
  const [disableButton, setDisableButton] = useState(true);
  const ref = useRef(null);

  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setIsTimeSlotOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

  return (
    <Modal
      style={{
        animation: "slideUp 1s",
        marginTop: "calc(35vh + 70px)",
        width: "300px",
      }}
      isOpen={props.isOpen}
    >
      <div
        ref={ref}
        style={{
          height: "350px",
          marginTop: "15px",
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
          justifyContent: "center",
        }}
      >
        {/* todo:find a better way to disable button */}
        <Picker
          selectedValue={1}
          onValueChange={(e) => {
            props.onUpdate(e);
            let split = e.split("T");
            if (split.length === 1) {
              setDisableButton(true);
            } else {
              setDisableButton(false);
            }
          }}
        >
          {props.items}
        </Picker>
        <Button
          style={{
            width: "80%",
            fontSize: "14px",
            color: "white",
            height: "42px",
            background: 'black'
          }}
          disabled={disableButton}
          onClick={() => {
            props.onClose();
            try {
              props.setSelectedTime(true);
            } catch (error) {
              console.log("no time prop");
            }
          }}
        >
          선택 완료
        </Button>
      </div>
    </Modal>
  );
}
