import React, { useEffect, useState } from "react";
import { PRODUCTS } from "utils/products";
import { getScheduleFromServer } from "utils/util";
import { formatDisplayDate } from "utils/util";
import { formatDisplayTime } from "utils/util";
import { useHistory } from "react-router";
import {
  calcPrice4,
  getDoctorInfo,
  parsePhoneNumber,
  parseSurvey,
  slack,
  won,
} from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import "./styling/statusStyles.css";

function StatusStepCancel(props) {
  const [minox, setMinox] = useState("minox");
  const [pill, setPill] = useState("propecia");
  const [shamp, setShamp] = useState();
  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [yeyakTime, setYeyakTime] = useState("");
  const [minoxMonth, setMinoxMonth] = useState();
  const [pillMonth, setPillMonth] = useState();
  const [doctorInfo, setDoctorInfo] = useState(null);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [availTimes, setAvailTimes] = useState([]);
  const history = useHistory();

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  const handleUpdate = (time) => {
    setYeyakTime(time);
    props.updateReservationTime(time);
    // slack("[survey1.4][" + props.kid + "] " + time + " 희망시간 선택");
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let ans = parseSurvey(
        props.patient.ans,
        props.patient.survey_type,
        props.patient.survey_version
      );

      setMinox(ans.minox);
      setPill(ans.pill);
      setShamp(ans.shamp);
      setMonth(ans.month);
      let pmonth = ans.pillMonth ? ans.pillMonth : ans.month;
      let mmonth = ans.minoxMonth ? ans.minoxMonth : ans.month;
      setPillMonth(pmonth);
      setMinoxMonth(mmonth);

      let res = calcPrice4(
        ans.pill,
        ans.minox,
        pmonth,
        mmonth,
        ans.shamp,
        null,
        null
      );
      setPillPrice(res.pillPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);

      if (props.patient.doctor && props.patient.doctor !== "") {
        let doctor_info = getDoctorInfo(props.patient.doctor);
        setDoctorInfo(doctor_info);
      }

      //slack
      slack("[status/step0(접수취소)] 방문: " + props.user.name);
    };
    asyncWrapper();
  }, []);

  useEffect(() => {
    const fetchSchedule = async () => {
      let schedules = await getScheduleFromServer();
      setAvailTimes(schedules);
    };
    fetchSchedule();
  }, []);

  // if timeslot isn't selected -> change disable button to true
  useEffect(() => {
    yeyakTime && yeyakTime.split("T").length > 1
      ? props.setDisableButton(false)
      : props.setDisableButton(true);
  }, [yeyakTime]);

  return (
    <>
      <div
        className="status-section-container"
        style={{ paddingLeft: "25px", paddingRight: "25px", paddingTop: 0 }}
      >
        {/* questions */}
        <div className="question-section">
          <div
            className="question-title"
            style={{ paddingBottom: "12px", paddingTop: "68px" }}
          >
            진료예약이 취소 되었어요
          </div>
          {/* <div className="question-title">접수완료</div> */}
          <div
            className="question-explain"
            style={{ fontSize: "13px", marginTop: "-5px" }}
          >
            다시 예약을 원하시면 아래에서 시간을 선택해 주세요.
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "18px",
              marginBottom: "-6px",
            }}
          ></div>
        </div>

        {/* 접수정보 세션 */}
        <div
          id="status-fake-curve"
          style={{ width: "100vw", marginLeft: "-26px", marginTop: "-5px" }}
        ></div>
        <div
          style={{
            marginBottom: "120px",
            paddingTop: "20px",
            paddingBottom: "0px",
          }}
        >
          {/* 접수내용 */}
          <div>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>나의정보</b>
              <b
                id="subtext-link"
                onClick={() => {
                  history.push("/mypagesave");
                }}
              >
                수정하기
              </b>
            </div>
            <table width={"100%"}>
              <tbody>
                {/* <tr>
									<td>진료 예약시간</td>
									<td className="text-right">{reservationTime !== "" ? reservationTime.split("/")[0] + "월 " + reservationTime.split("/")[1] + "일(" + reservationTime.split("/")[2] + ") " + reservationTime.split("/")[3] + ":00-" + (parseInt(reservationTime.split("/")[3]) + 1) + ":00" : ""}사이</td>
								</tr> */}
                <tr>
                  <td style={{ fontWeight: 400, fontSize: "13px" }}>
                    이름/주민번호
                  </td>
                  <td
                    className="text-right"
                    style={{ fontWeight: 300, fontSize: "13px" }}
                  >
                    {props.user.name}{" "}
                    <span>
                      {props.user.ssn
                        ? "(" +
                          props.user.ssn.slice(0, props.user.ssn.length - 6) +
                          "******" +
                          ")"
                        : ""}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 400, fontSize: "13px" }}>연락처</td>
                  <td
                    className="text-right"
                    style={{ fontWeight: 300, fontSize: "13px" }}
                  >
                    {parsePhoneNumber(props.user.phone)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="line-orange-status" />
          {/* 진료예약시간 */}
          <div>
            <TimeSlotModal
              isOpen={isTimeSlotOpen}
              items={availTimes}
              onClose={handleClose}
              onUpdate={handleUpdate}
              setIsTimeSlotOpen={setIsTimeSlotOpen}
            />

            <div className="reservation-heading">희망 진료시간</div>
            <div
              className="scheduller-btn"
              onClick={() => {
                slack(
                  "[survey1.4][" + props.kid + "] " + "취소에서 희망시간 클릭"
                );
                setIsTimeSlotOpen(true);
              }}
              id="sv-reservation-modal"
              style={{
                background:
                  yeyakTime && yeyakTime.split("T").length > 1 ? "white" : "",
                color:
                  yeyakTime && yeyakTime.split("T").length > 1 ? "#6A43B8" : "",
              }}
            >
              <div>
                {yeyakTime.split("T").length === 1
                  ? "선택하기"
                  : formatDisplayDate(yeyakTime) +
                    " " +
                    formatDisplayTime(yeyakTime)}
              </div>
            </div>
          </div>{" "}
          {/* 희망 진료시간 끝 */}
          <div className="line-orange-status" />
          {/* 예상금액 */}
          <div className="">
            <table width={"100%"}>
              <tr>
                <td>
                  <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                    <b>총 예상금액</b>
                  </div>
                </td>
                <td className="text-right">
                  <div
                    style={{
                      color: "#6A43B8",
                      fontSize: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    <b>총 {won(total)}원</b>
                  </div>
                </td>
              </tr>
            </table>

            <table width={"100%"}>
              <tbody>
                {pill && pill !== "아니요" && (
                  <tr>
                    <td></td>
                    <td style={{ fontWeight: 400, fontSize: "13px" }}>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base}{" "}
                      {PRODUCTS[pill].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(pillPrice)}원
                    </td>
                  </tr>
                )}

                {minox && minox !== "아니요" && (
                  <tr>
                    <td></td>
                    <td style={{ fontWeight: 400, fontSize: "13px" }}>
                      {PRODUCTS[minox].name} (
                      {minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit}
                      ){/* {minox} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(minoxPrice)}원
                    </td>
                  </tr>
                )}

                {shamp && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[shamp].name} ({PRODUCTS[shamp].base}{" "}
                      {PRODUCTS[shamp].unit})
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(shampPrice)}원
                    </td>
                  </tr>
                )}

                <tr>
                  <td></td>
                  <td style={{ fontWeight: 400, fontSize: "13px" }}>진료비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    10,000원
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ fontWeight: 400, fontSize: "13px" }}>배송비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    2,900원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default StatusStepCancel;
