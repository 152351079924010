import Q from "../../assets/exogeneQ.png";
import image from "./faq_assets/Q4IM1.png";

// css
import "./faqPagesStyling.css";

export default function Q3() {
  return (
    <div className="exogene-question-slide">
      <span id="exogene-q">
        <img src={Q} alt="question-placeholder" />
        <span className="exogene-question-head">
          유전자 검사는 어디서 분석하나요?{" "}
        </span>
      </span>
      <div className="exogene-faq-wrapper">
        <div className="exogene-faq-question">
          <span className="exogene-question-detail">
            개인별 맞춤 유전자 검사로 웰빙라이프를 실현하는 BF 생명과학
            연구소에서 분석합니다.
          </span>
          <div>
            <img
              src={image}
              alt="bf-rnd"
              style={{ width: "329px", height: "auto", marginTop: "47px" }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
