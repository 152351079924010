import { API, Auth } from "aws-amplify";
import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import { listPatients } from "graphql/queries";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { sendAnalytics } from "utils/analytics";
import { getChartsByKid } from "utils/data";
import { getUser } from "utils/data";
import { updateChart } from "utils/data";
import { getMarsByKid } from "utils/data";
import { slack2accept } from "utils/util";
import { slack } from "utils/util";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import SurveyNextBtn from "views/survey/components/SurveyNextBtn";
import StatusButton from "./StatusButton";
import StatusStep1 from "./StatusStep1";
import StatusStep2 from "./StatusStep2";
import StatusStep3 from "./StatusStep3";
import StatusStep4 from "./StatusStep4";
import StatusStepCancel from "./StatusStepCancel";

import { Col, Row, Container } from "reactstrap";

// status status
import StatusStatusBar from "./status-components/StatusStatusBar";
import { getTimeObject } from "utils/util";
import { getChartByID } from "utils/data";
import { updateUser } from "utils/data";
import StatusNoRecord from "./StatusNoRecord";
import { calcPrice4 } from "utils/util";

function Status() {
  // start step @ 1
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [kid, setKid] = useState("");
  const [pid, setPid] = useState("");
  const [ktoken, setKtoken] = useState("");
  const [user, setUser] = useState();
  const [currentPatient, setCurrentPatient] = useState({});
  const [isLoading, setIsLoading] = useState("false");
  const [yeyakTime, setYeyakTime] = useState("");
  const [shamp, setShamp] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const asyncWrapper = async () => {
      setIsLoading(true);
      var KakaoID;
      // check if loged-in if not redirect to klogin.
      //
      // location.kid = "12345"
      if (location.state && location.state.kid) {
        location.kid = location.state.kid;
      }
      if (location.state && location.state.analytics) {
        sendAnalytics(location.state.analytics);
      }

      if (!location.kid) {
        history.push({
          pathname: "/klogin",
          state: "status",
        });
      } else {
        // console.log("kid: " , location.kid);
        setKid(location.kid);

        // get the user
        const user = await getUser(`user#${location.kid}`);
        if (!user) {
          setStep(-1);
          setIsLoading(false);
          return;
          // alert("진료 정보가 존재하지 않습니다. 진료를 먼저 신청해 주세요!");
          // history.push("/survey");
          // return;
        }
        setUser(user);

        // let userCharts = JSON.parse(user.charts).sort((a, b) =>
        //   new Date(b.yeyak_time) - new Date(a.yeyak_time)
        // );
        let userCharts = JSON.parse(user.charts);

        let cp;
        if (userCharts && userCharts.length > 0) {
          cp = await getChartByID(userCharts[0].id);
        } else {
          setStep(-1);
          setIsLoading(false);
          return;
        }

        // iterate and get the most recently updatd patient and set it as cp(current patient);

        setCurrentPatient(cp);
        setPid(cp.id);
        if (cp.status === "pay") {
          setStep(2);
        }
        if (cp.status === "paid") {
          setStep(3);
        }
        if (cp.status === "deli_done") {
          setStep(4);
        }
        if (cp.status === "cannot_reach" || cp.status === "cancel") {
          setStep(0); // 0 - cancel state.
        }

        setIsLoading(false);
      }
    };
    asyncWrapper();
  }, []);

  const getResult = (res) => {
    if (res.PCD_PAY_RST === "success") {
      let payResult = res;
      // window.alert(res.PCD_PAY_MSG);

      // 전달받은 결제 파라미터값을 state에 저장 후  '/react/order_result'로 이동
      history.push({
        pathname: "/address",
        state: { payResult: payResult, pid: pid },
      });
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG + " 결제를 다시 시도해 주세요.");
      history.push({
        pathname: "/status",
      });
    }
  };

  const updateReservationTime = (time) => {
    setYeyakTime(time);
  };

  const submitNewReservation = async () => {
    if (yeyakTime.split("T").length !== 2) {
      alert("예약시간을 선택해 주세요.");
      return;
    }
    // alert("정말로 다시 예약하시겠습니까?");

    let chartInfo = {
      id: currentPatient.id,
      reservation_time: yeyakTime,
      yeyak_time: yeyakTime,
      createdAt: new Date().toISOString(),
      status: "pending",
      dstatus: "re_pending",
      ignoreStatus: "no",
    };
    const res = await updateChart(chartInfo);
    if (res) {
      if (user && user.charts) {
        let userCharts = JSON.parse(user.charts);
        // console.log("userCharts:", userCharts);
        let chart = userCharts.find((chart) => chart.id === currentPatient.id);
        chart.stat = "ing";
        let userInfo = {
          id: user.id,
          charts: JSON.stringify(userCharts),
        };
        const uuser = await updateUser(userInfo);
        // console.log("updated user: ", uuser);
      }
      slack2accept(`[재접수][${currentPatient.name}][${currentPatient.kid}]`);
      alert("재접수 완료 되었습니다.");
      history.go(0);
    }
  };

  const clickDone = async () => {
    // 나머지들은 닫기버튼 누르면 인앱브라우져 닫기
    if (step === 1 || step === 3 || step === 4) {
      window.location.href = "kakaotalk://inappbrowser/close";
    }

    // address and payment
    if (step === 2) {
      let cp = currentPatient;

      // if shamp is null it's removed.

      let priceInfo = calcPrice4(
        cp.pill,
        cp.minox,
        cp.pillMonth,
        cp.minoxMonth,
        shamp,
        cp.capil,
        cp.capilMonth
      );

      // remove shamp form patient.
      let chartInfo = {
        id: currentPatient.id,
        ignoreStatus: "yes",
        shamp: shamp,
        total: priceInfo.total,
      };
      const res = await updateChart(chartInfo);
      cp.shamp = shamp;
      cp.total = priceInfo.total;

      if (res) {
        history.push({
          pathname: "/address",
          state: { cp: cp, pid: pid, shamp: shamp },
        });
      }
    }
    // Step 1
    //window.close();
    //window.location.href = 'kakaotalk://inappbrowser/close';
    // window.location = "kakaotalk://inappbrowser/close"
  };
  const updateAnswer = (answer) => {
    let temp = [...answers];
    temp[answer.id - 1] = answer;
    setAnswers(temp);
    // console.log(answers);
  };

  return (
    <div>
      <MarsNavbarWithLine />
      <div>
        {isLoading && <MarsLoaderBasic isLoading={isLoading} />}

        {!isLoading && (
          <div>
            <Container>
              <Row className="justify-content-center">
                <Col className="ml-auto mr-auto" md="5" xs="12">
                  {step === 1 && (
                    <>
                      <StatusStep1
                        updateAnswer={updateAnswer}
                        patient={currentPatient}
                        user={user}
                        step={step}
                      />
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <StatusStep2
                        updateAnswer={updateAnswer}
                        patient={currentPatient}
                        user={user}
                        setShamp={setShamp}
                        step={step}
                      />
                    </>
                  )}
                  {step === 3 && (
                    <StatusStep3
                      updateAnswer={updateAnswer}
                      patient={currentPatient}
                      user={user}
                      step={step}
                    />
                  )}
                  {step === 4 && (
                    <StatusStep4
                      updateAnswer={updateAnswer}
                      patient={currentPatient}
                      user={user}
                      step={step}
                    />
                  )}

                  {step === 0 && (
                    <StatusStepCancel
                      updateAnswer={updateAnswer}
                      updateReservationTime={updateReservationTime}
                      patient={currentPatient}
                      user={user}
                      step={step}
                      setDisableButton={setDisableButton}
                    />
                  )}

                  {step === -1 && (
                    <StatusNoRecord
                      patient={currentPatient}
                      user={user}
                      step={step}
                    />
                  )}
                </Col>
              </Row>

              <Row
                className="justify-content-center"
                style={{
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                  marginLeft: "-5px",
                }}
              >
                <Col>
                  {(step === 1 || step === 3 || step === 4) && (
                    // <StatusButton clickDone={clickDone}>닫기</StatusButton>
                    <SurveyNextBtn onClick={clickDone} white={true}>
                      닫기
                    </SurveyNextBtn>
                  )}
                  {step === 2 && (
                    <SurveyNextBtn onClick={clickDone} white={true}>
                      주소입력 및 결제하기
                    </SurveyNextBtn>
                  )}
                  {step === 0 && (
                    <SurveyNextBtn
                      onClick={submitNewReservation}
                      white={true}
                      disableButton={disableButton}
                    >
                      다시 예약하기
                    </SurveyNextBtn>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
}

export default Status;
