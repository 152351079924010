import React, { useState } from "react";
// reactstrap components
import {
  Button, Col, FormGroup, Input, Row
} from "reactstrap";
import { slack } from "utils/util";

function Partner(props) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [pname, setPname] = useState("");
  const [pphone, setPphone] = useState("");
  return (
    <>
      <div className="landing4 landing4-background-white">
        <Row className="justify-content-center">
          <Col className="text-center" md="6" xs="12">
            <div>
              <div className="left heading2">
                MARS의 <b style={{ color: "#FA5724" }}>파트너</b>가 되어주세요
              </div>
            </div>

            <div className="line1-white" /> 

            <div
              className="left"
              style={{ lineHeight: 1.4, paddingBottom: "8px", fontWeight: 100 }}
            >
              맨프롬마스는 남성형 탈모에 중점을 둔 비대면 진료 서비스 입니다. 안전하고 투명하고 편리한 원격진료를 위해 병원과 약국을 모집합니다.
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="6" xs="12">
            <FormGroup>
              <Input
                className="square"
                defaultValue=""
                placeholder="병원명/약국명"
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                className="square"
                defaultValue=""
                placeholder="병원/약국 주소"
                type="text"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                className="square"
                defaultValue=""
                placeholder="병원/약국 연락처"
                type="text"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                className="square"
                defaultValue=""
                placeholder="담당자 직책과 성함"
                type="text"
                onChange={(e) => {
                  setPname(e.target.value);
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                className="square"
                defaultValue=""
                placeholder="담당자 이메일"
                type="text"
                onChange={(e) => {
                  setPphone(e.target.value);
                }}
              ></Input>
            </FormGroup>

            <Button
              onClick={() => {
                if (name !== "" && pname !== "" && pphone !== "") {
                  slack(
                    "[파트너 신청접수]\n병원/약국명: " +
                    name +
                    "\n병원/약국 연락처: " +
                    phone +
                    "\n병원/약국 주소: " +
                    address +
                    "\n신청자 이름: " +
                    pname +
                    "\n신청자 연락처: " +
                    pphone
                  );
                  alert("파트너 신청 완료! 확인후 연락 드리겠습니다.");
                } else {
                  alert(
                    "약국/의사 정보와 연락받으실 연락처를 입력해 주세요."
                  );
                }
              }}
              className="btn-lg btn-primary"
              style={{ width: "100%", height: "51px" }}
            >
              파트너 신청하기
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="5" xs="11">
            <div
              style={{
                lineHeight: 1.3,
                fontSize: "12px",
                color: "gray",
                paddingBottom: "4px",
                fontWeight: 100
              }}
            >
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Partner;
