import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { updateUser } from "utils/data";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import Lottie from "lottie-react";
import arrowBack from "../assets/arrowBack.png";

// assets
import cardLottie from "../assets/profilePay.json";
import exit from "../assets/exit.png";

// css
import "../styling/componentStyling.css";

export default function UserCard(props) {
  const { cardInfo, userID, kid } = props.location.state;
  const [cardName, setCardName] = useState();
  const [cardNum, setCardNum] = useState();
  const [cardClick, setCardClick] = useState(false);
  const [ID, setID] = useState();
  const history = useHistory();

  useEffect(() => {
    try {
      setCardName(cardInfo[0]);
      setCardNum(cardInfo[1]);
      setID(userID);
    } catch (error) {
      console.log("unable to retrieve card info");
    }
  }, []);

  // currently replaces card array with an empty array
  const handleRemove = async (card) => {
    if (window.confirm("등록 된 카드를 삭제하시겠어요?")) {
      try {
        // splice card later for multiple deletion
        // this empties array for now since we will only record one
        // user.cards = [];
        let userInfo = {
          id: ID,
          cards: [],
        };
        var res = await updateUser(userInfo);
        if (res) {
          alert("카드가 삭제 되었습니다");
          history.push("/myPageNew");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  };

  return (
    <div className="user-container" style={{ background: "white" }}>
      <MarsNavBarMain></MarsNavBarMain>
      <button
        className="profile-return-button"
        onClick={() => {
          history.push("/myPageNew");
        }}
      >
        <img src={arrowBack} />
      </button>
      <div className="user-info-display">
        <div className="user-info-title">
          <span>카드정보</span>
          <Lottie
            animationData={cardLottie}
            autoPlay={true}
            id="profile-lottie"
          ></Lottie>
        </div>
      </div>
      <div className="user-card-display">
        {cardInfo && cardInfo.length >= 1 ? (
          <span>등록 된 나의 카드정보</span>
        ) : (
          <span style={{ placeSelf: "center" }}>
            등록 된 카드정보가 없습니다.
          </span>
        )}
        {cardInfo && cardInfo.length ? (
          <div
            className="user-card-container"
            onClick={() => {
              setCardClick(!cardClick);
            }}
          >
            <button id="radio-button">
              <span
                id="radio-button-circle"
                style={{ display: cardClick ? "block" : "none" }}
              ></span>
            </button>
            <span>
              {cardName} {cardNum}
            </span>
            {cardClick ? (
              <a
                style={{ marginLeft: "auto", height: "25px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove();
                }}
              >
                <img src={exit} />
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="user-data-button">
        <button
          onClick={(e) => {
            e.stopPropagation();
            history.push("/myPageNew");
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
}
