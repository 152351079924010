import React, { useState, useCallback } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";

// images
import thumbup from "./feedbackAssets/thumbup.png";
import thumbupToggle from "./feedbackAssets/thumbupToggle.png";
import thumbdown from "./feedbackAssets/thumbdown.png";
import thumbdownToggle from "./feedbackAssets/thumbdownToggle.png";

// css import
import "./feedbackStyles.css";

export default function QuestionOne(props) {
  //   const [feedbackPositive, setFeedbackPositive] = useState(true);
  const [feedbackGoodImage, setfeedbackGoodImage] = useState(thumbup);
  const [feedbackBadImage, setfeedbackBadImage] = useState(thumbdown);
  const [prevGood, changeColorGood] = useState(1);
  const [prevBad, changeColorBad] = useState(1);

  const toggleThumbDown = () => {
    if (feedbackBadImage === thumbdown) {
      setfeedbackBadImage(thumbdownToggle);
      changeColorGood(1);
      changeColorBad(!prevBad);
      props.setFeedback(false);
      setfeedbackGoodImage(thumbup);
    }
    // else {
    //   setfeedbackBadImage(thumbdown);
    // }
  };

  const toggleThumbUp = () => {
    if (feedbackGoodImage === thumbup) {
      setfeedbackGoodImage(thumbupToggle);
      changeColorBad(1);
      changeColorGood(!prevGood);
      setfeedbackBadImage(thumbdown);
      props.setFeedback(true);
    }

    // else {
    //   setfeedbackGoodImage(thumbup);
    // }
  };

  const pStyleGood = {
    fontWeight: prevGood === 1 ? 300 : 600,
  };

  const pStyleBad = {
    fontWeight: prevBad === 1 ? 300 : 600,
  };

  const sessionInformation = {
    date: "2022. 10. 10",
    time: "(12:00-13:00)",
    hospitalInfo: "서울 정의원",
  };

  return (
    <div className="feedback-question-one-container">
      <h1>1. 오늘의 진료는 어떠셨나요?</h1>
      <p id="feedback-session-information">
        {sessionInformation.date} {sessionInformation.time}{" "}
        {sessionInformation.hospitalInfo}
      </p>
      <div className="feedback-selection-container">
        <section>
          <button onClick={toggleThumbDown}>
            <img src={feedbackBadImage} />
            <p style={pStyleBad}>아쉬웠어요</p>
          </button>
        </section>
        <section>
          <button onClick={toggleThumbUp}>
            <img src={feedbackGoodImage} />
            <p style={pStyleGood}>좋았어요</p>
          </button>
        </section>
      </div>
    </div>
  );
}
