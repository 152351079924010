import React, { useState, useEffect } from "react";

// images
import Kit from "../dna-event-assets/KitOne.png";
import KitTwo from "../dna-event-assets/KitTwo.png";

export default function KitDescription() {

  const wrapperStyles = {
    padding: '27px',
    background: '#FFFFFF',
  }

  const containerStyles = {
    display: "flex",
    flexWrap: "no-wrap",
    flexDirection: "row",
    paddingLeft: "14px",
  };

  return (
    <div style={wrapperStyles}>
      <p id="dna-main-title">유전자 키트 구성품</p>
      <div style={containerStyles}>
        <img style={{ height: "187px", paddingRight: "25px" }} src={Kit} />
        <img style={{ width: "205px", height: "auto" }} src={KitTwo} />
      </div>
    </div>
  );
}
