import React, { useState, useEffect } from "react";
import MarsHeader from "../../components/Navbars/MarsNavbarWithLine";

// image
import heartImage from "./feedbackAssets/heartImage.png";
// css
import "./feedbackStyles.css";

export default function FeedbackConfirm() {
  const handleClick = () => {
    window.location.href = "kakaotalk://inappbrowser/close";
  };

  return (
    <div className="scrollable">
      <MarsHeader />
      <div className="feedback-confirmation-container">
        <img src={heartImage}></img>
        <h2>소중한 후기와 의견 감사합니다.</h2>
        <p>더 나은 서비스로 보답하겠습니다.<br/>감사합니다!</p>
      </div>
      <div className="feedback-button-container">
        <button id="feedback-submit-button" onClick={handleClick}>
          닫기
        </button>
      </div>
    </div>
  );
}
