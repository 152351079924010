import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

// images
import redirectSpinner from "./status-assets/noRecord.json";
import logo from "../../assets/img/marsSvgLogo.svg";

// css
import "./styling/statusNoRecordStyles.css";
import { useHistory } from "react-router";


// css originally styled as surveyredirect
// survey redirect -> statusNoRecord
export default function StatusNoRecord() {
  const history = useHistory();
  return (
    <div className="survey-redirect-container">
      <div className="survey-buffer-header">
        <img src={logo} alt="mars-logo" />
      </div>
      <div className="survey-redirect-content-container">
        <Lottie animationData={redirectSpinner}></Lottie>
        <p id="survey-buffer-subtitle">
          현재 진행중인 진료가 없습니다. <br /> 아래 버튼을 눌러 시작해 보세요!
        </p>
      </div>
      <div className="survey-intro-button-container">
        <button
          id="survey-intro-button"
          onClick={() => {
            console.log("click click");
            history.push("/survey");
          }}
        >
          비대면진료 예약하기
        </button>
      </div>
    </div>
  );
}
