import { API } from "aws-amplify";
import axios from "axios";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormGroup, Input, Label } from "reactstrap";
import { getChartByID } from "utils/data";
import { updateUser } from "utils/data";
import { getUser } from "utils/data";
import { slack } from "utils/util";
import { slack2ops } from "utils/util";
import { slack2accept } from "utils/util";
import { won } from "utils/util";
import CardConfirm from "views/status/status-components/CardConfirm";
import PaymentConfirmPopup from "views/status/status-components/PaymentConfirmPopup";
import PaymentLoader from "views/status/status-components/PaymentLoader";
import StatusPaymentButton from "views/status/StatusPaymentButton";
import * as mutations from "../../graphql/mutations";
import { slackmsg } from "utils/util";

export default function PaymentModule(props) {
  const [billingKey, setBillingKey] = useState();
  const [cards, setCards] = useState();
  const [isSavedCardSelected, setIsSavedCardSelected] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [paymentType, setPaymentType] = useState("STD_CARD"); // STD_CARD, APP_CARD, SAVED_CARD
  const [paymentPopup, setPaymentPopup] = useState(false);

  const history = useHistory();

  // props & states
  const {
    paymentFrom,
    paymentTotal,
    customer,
    kid,
    pid,
    user,
    roadAddress,
    detailAddress,
    zoneCode,
    shamp,
    extraShamp,
    name,
    email,
    phone,
    handlePaymentResult,
  } = props.props;

  useEffect(() => {
    let cards = [];
    setCards(cards);
    if (user && user.cards) {
      cards = JSON.parse(user.cards);
      if (cards.length > 0) {
        setIsSavedCardSelected(true);
        setPaymentType("SAVED_CARD");
      }
      setCards(user.cards ? JSON.parse(user.cards) : []);
    }
  }, [user]);

  // last 4 digits
  const parseCardNum = (num) => {
    return num.substr(num.length - 4);
  };

  const validateInputs = () => {
    if (roadAddress.length < 4 || detailAddress.length < 1) {
      alert(
        "배송 주소를 바르게 입력해 주세요. 상세주소가 없을경우 '없음' 이라고 적어주세요."
      );
      return false;
    }

    if (paymentFrom === "dna") {
      if (!email || email.length < 5) {
        alert(
          "이메일 주소를 바르게 입력해 주세요. 검진 결과를 이메일로 보내 드립니다."
        );
        return false;
      }
      if (phone.length < 10) {
        alert("연락처를 올바르게 입력해 주세요.");
        return false;
      }
    }
    return true; // all inputs are valid.
  };

  const clickDone = async () => {
    //save address info first..
    const userInfo = {
      id: user.id,
      addr: roadAddress,
      addr2: detailAddress,
      zip: zoneCode,
      name: name,
      email: email,
      phone: phone,
    };
    const uuser = await updateUser(userInfo);

    if (paymentType === "SAVED_CARD") {
      processSavedCardPayment(paymentFrom);
    } else {
      processCardPayment(paymentFrom);
    }
  };

  const getResult = async (res) => {
    if (res.PCD_PAY_RST === "success") {
      // check if cards object needs to be updated.
      //
      if (paymentType === "STD_CARD") {
        try {
          // update user with billing_key
          let cardObj = {
            CNAME: res.PCD_PAY_CARDNAME,
            CNUM: res.PCD_PAY_CARDNUM,
            BKEY: res.PCD_PAYER_ID,
            PTIME: res.PCD_PAY_TIME,
          };
          let curCards = cards.slice(0);
          curCards.push(cardObj);

          // update user with card obj
          let userInfo = {
            id: `user#${kid}`,
            cards: JSON.stringify(curCards),
          };
          const uuser = await updateUser(userInfo);
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      }
      handlePaymentResult(res, paymentType);
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG + " 결제를 다시 시도해 주세요.");
      await slackmsg(`[${kid}]${res.PCD_PAY_MSG}`,"payresult");
      let whereToGo = "/status";
      if (paymentFrom === "dna") {
        whereToGo = "/exogene-reservation"
      }
      history.push({
        pathname: whereToGo
      });
    }
  };

  const processSavedCardPayment = async (paymentFrom) => {
    setIsPaying(true);
    try {
      // console.log("inside process card payment");
      // partner auth simple authentication for recurring payemtns.
      let url =
        "https://n7qj9lhz1k.execute-api.ap-northeast-2.amazonaws.com/payple/partner-simple";
      const res = await axios.get(url);

      const PCD_CST_ID = res.data.cst_id;
      const PCD_CUST_KEY = res.data.custKey;
      const PCD_AUTH_KEY = res.data.AuthKey;
      const PCD_PAY_URL = res.data.return_url;
      const PCD_PAY_HOST = res.data.PCD_PAY_HOST;
      const PCD_PAY_TYPE = "card";
      const PCD_PAYER_ID = cards[0].BKEY;
      const PCD_PAY_GOODS = "마스 서비스 이용료R";
      const PCD_PAY_TOTAL = paymentTotal;
      const PCD_SIMPLE_FLAG = "Y";

      let data = {
        PCD_CST_ID,
        PCD_CUST_KEY,
        PCD_AUTH_KEY,
        PCD_PAY_TYPE,
        PCD_PAYER_ID,
        PCD_PAY_GOODS,
        PCD_PAY_TOTAL,
        PCD_SIMPLE_FLAG,
        PCD_PAY_URL,
      };

      let payURL =
        "https://n7qj9lhz1k.execute-api.ap-northeast-2.amazonaws.com/payple/partner-pay";

      const payRes = await axios.post(payURL, data);

      if (payRes && payRes.data && payRes.data.PCD_PAY_RST === "success") {
        handlePaymentResult(payRes.data, paymentType);
        return;
      } else {
        setIsPaying(false);
        window.alert(payRes.data.PCD_PAY_MSG + " 결제를 다시 시도해 주세요.");
        await slackmsg(`[${kid}]${payRes.data.PCD_PAY_MSG}`,"payresult");
        let whereToGo = "/status";
        if (paymentFrom === "dna") {
          whereToGo = "/exogene-reservation"
        }
        history.push({
          pathname: whereToGo
        });
      }

      // setIsLoading(false);
    } catch (error) {
      setIsPaying(false);
      slack2accept(
        `recurring payment error:[${user.kid}] ${JSON.stringify(error)}`
      );
      alert("결제를 실패했습니다.");
      // setIsLoading(false);
    }
  };

  const processCardPayment = async (paymentFrom) => {
    // partner auth
    let url =
      "https://n7qj9lhz1k.execute-api.ap-northeast-2.amazonaws.com/payple/partner";
    const res = await axios.get(url);
    // console.log("payple partner data:", res.data);

    const PCD_AUTH_KEY = res.data.AuthKey;
    const PCD_PAY_URL = res.data.return_url;

    var obj = new Object();

    obj.PCD_USER_DEFINE1 = pid;
    obj.PCD_PAY_TYPE = "card";
    obj.PCD_PAY_WORK = "PAY";

    /* 01 : 빌링키결제 */
    obj.PCD_CARD_VER = paymentType === "APP_CARD" ? "02" : "01"; // 01: 정기결제, 02: 앱카드
    // obj.PCD_PAYER_NO = 1234;
    // obj.PCD_PAYER_NAME = "홍길동";
    // obj.PCD_PAYER_HP = "01012345678";
    // obj.PCD_PAYER_EMAIL = "dev@payple.kr";
    obj.PCD_PAY_GOODS = "마스 서비스 이용료";
    obj.PCD_PAY_TOTAL = paymentTotal;

    /* 파트너 인증시 받은 AuthKey 값 입력  */
    obj.PCD_AUTH_KEY = PCD_AUTH_KEY;

    /* 파트너 인증시 받은 return_url 값 입력  */
    obj.PCD_PAY_URL = PCD_PAY_URL;

    /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
    obj.callbackFunction = getResult; // getResult : 콜백 함수명

    let rstURL = "/address"; // patient
    if (paymentFrom === "dna") {
      rstURL = "/exogene-reservation";
    }
    obj.PCD_RST_URL = rstURL;
    // console.log("before caloing payple cpay authcehck");

    window.PaypleCpayAuthCheck(obj);
  };

  const handleRemove = async (card) => {
    try {
      // splice card later for multiple deletion
      // this empties array for now since we will only record one
      // user.cards = [];
      let userInfo = {
        id: `user#${kid}`,
        cards: [],
      };
      await updateUser(userInfo);
      window.location.reload(false);
      setOpenConfirm(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isPaying && <PaymentLoader />}
      <div>
        {!isPaying && (
          <div
            style={{
              fontSize: "18px",
              paddingTop: "25px",
              fontWeight: 400,
              background: "white",
              flexGrow: 1,
              height: "100%",
              marginTop: "9px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                borderBottom: ".7px dotted #b3b3b3",
                display: "flex",
                flexGrow: 1,
                fontWeight: 600,
              }}
            >
              결제수단
              <p
                style={{
                  position: "relative",
                  marginLeft: "auto",
                  color: "#6A43B8",
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "11px",
                  marginTop: "13px",
                }}
              >
                총 {won(paymentTotal)} 원
              </p>
            </div>
            <div>
              {cards && cards.length > 0 && (
                <div id="saved-payment-container">
                  <FormGroup check className="form-check-radio">
                    <Label check id="payment-form-label">
                      <Input
                        checked={paymentType === "SAVED_CARD"}
                        defaultChecked={true}
                        id="exampleRadios3"
                        name="exampleRadios"
                        type="radio"
                        onClick={() => {
                          setPaymentType("SAVED_CARD");
                        }}
                      ></Input>
                      <span className="form-check-sign"></span>
                      <span style={{ fontSize: "13px" }}>{`${cards[0].CNAME
                        } ${parseCardNum(cards[0].CNUM)}`}</span>
                    </Label>
                    <a
                      onClick={() => {
                        setOpenConfirm(!openConfirm);
                      }}
                      id="payment-delete"
                    >
                      카드삭제
                    </a>
                  </FormGroup>
                </div>
              )}

              {openConfirm ? (
                <CardConfirm
                  isOpen={openConfirm}
                  setOpenConfirm={setOpenConfirm}
                  cardName={cards[0].CNAME}
                  cardNum={cards[0].CNUM}
                  handleRemove={handleRemove}
                ></CardConfirm>
              ) : (
                <></>
              )}
            </div>
            <div>
              <FormGroup
                check
                className="form-check-radio"
                id="app-card-form-group"
              >
                <Label check id="payment-form-label">
                  <Input
                    defaultChecked={paymentType === "APP_CARD"}
                    defaultValue="option2"
                    id="exampleRadios1"
                    name="exampleRadios"
                    type="radio"
                    onClick={() => {
                      setPaymentType("APP_CARD");
                    }}
                    className="card-input-label"
                  ></Input>
                  <span className="form-check-sign"></span>
                  <span style={{ fontSize: "13px" }}>
                    카드 간편결제 (앱카드)
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div>
              <FormGroup
                check
                className="form-check-radio"
                id="card-form-group"
              >
                {/* 일반결제 (카드등록) */}
                <Label check id="payment-form-label">
                  <Input
                    defaultChecked={paymentType === "STD_CARD"}
                    id="custom-radio-group"
                    name="exampleRadios"
                    type="radio"
                    onClick={() => {
                      setPaymentType("STD_CARD");
                    }}
                  ></Input>
                  <span className="form-check-sign"></span>
                  <span style={{ fontSize: "13px" }}>일반결제 (카드등록)</span>
                </Label>
              </FormGroup>
              <p id="card-payment-message">
                *계좌이체를 원하시면 카카오톡 하단메뉴의 ‘상담직원 연결'을 눌러
                문의 주세요.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mars-divider-gray" style={{ marginLeft: "-27px" }}></div>

      {paymentPopup ? (
        <PaymentConfirmPopup
          paymentPopup={paymentPopup}
          setPaymentPopup={setPaymentPopup}
          clickDone={clickDone}
        // cardName={cardName}
        // cardNum={cardNum}
        ></PaymentConfirmPopup>
      ) : (
        <></>
      )}

      <div style={{ display: isPaying ? "none" : "" }}>
        <StatusPaymentButton
          onClick={() => {
            if (validateInputs()) {
              if (paymentType === "SAVED_CARD") {
                setPaymentPopup(true);
              } else {
                clickDone();
              }
            }
          }}
          clickDone={clickDone}
          isSavedCardSelected={isSavedCardSelected}
          paymentPopup={paymentPopup}
          setPaymentPopup={setPaymentPopup}
        >
          <span style={{ fontWeight: 400 }}>결제하기</span>
        </StatusPaymentButton>
      </div>
    </>
  );
}
