import React, { useEffect, useState } from "react";
import { slack2events } from "utils/util";

// css
import "../styling/componentStyling.css";

export default function Feedback(props) {
  const { article, kid } = props;
  const [helpful, setHelpful] = useState(0); // 0 = neutral|unselected 1 = true, 2 = false

  useEffect(() => {
    // console.log("user found " + article + " helpful " + helpful);
  }, [helpful]);

  return (
    <div className="feedback-container">
      <div className="feedback-title">이 글이 도움이 되었나요?</div>
      <div className="feedback-input">
        <div
          className="feedback-circle"
          style={{
            border: helpful === 2 ? "3px solid #333D96" : "",
            background:
              helpful === 2
                ? " linear-gradient(140.19deg, #E4E6F5 22.41%, #DAECF3 99.32%) "
                : "",
          }}
          onClick={() => {
            setHelpful(2);
            slack2events(
              "[MARS Care™][쉐딩]" + `[${kid}]` + " 블로그 Unhelpful 클릭!."
            );
          }}
        >
          👎🏻
        </div>
        <div
          className="feedback-circle"
          style={{
            border: helpful === 1 ? "3px solid #333D96" : "",
            background:
              helpful === 1
                ? " linear-gradient(140.19deg, #E4E6F5 22.41%, #DAECF3 99.32%) "
                : "",
          }}
          onClick={() => {
            setHelpful(1);
            slack2events(
              "[MARS Care™][쉐딩]" + `[${kid}]` + " 블로그 Helpful 클릭!."
            );
          }}
        >
          👍🏻
        </div>
      </div>
    </div>
  );
}
