import React, { useState, useEffect } from "react";

// css
import "./feedbackStyles.css";

export default function QuestionThree(props) {


  const handleTextChange = (e) => {
    props.setFeedbackText(e.target.value)
  }

  return (
    <div className="feedback-question-three-container">
      <h1>3. 이 외에 더 전달하고 싶은 의견이 있나요? <span style={{opacity: '0.5', fontSize: '20px'}}>(선택)</span></h1>
      <div className='feedback-question-three-textarea'>
        <textarea placeholder='선택사항' value={props.feedbackText} onChange={handleTextChange}></textarea>
      </div>
    </div>
  );
}
