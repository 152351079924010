import React, { useState, useEffect } from "react";
import { PRODUCTS } from "utils/products";

// second view patient questionnaire answers
/**
 *
 * @param {answers, hairlineKey, haircenterKey, hairgeneralKey} props
 *
 */
export default function SVChartV15(props) {
  const { answers, id } = props;
  const [message, setMessage] = useState(); // extra message
  const [month, setMonth] = useState();
  const [otherCares, setOtherCares] = useState(); // other care []
  const [duration, setDuration] = useState(); // how long you've had it for
  const [pastPill, setPastPill] = useState();
  const [scalpType, setScalpType] = useState();
  const [shampoo, setShampoo] = useState();
  const [minox, setMinox] = useState();
  const [currentPill, setCurrentPill] = useState();
  const [displayExtra, setDisplayExtra] = useState(false);

  useEffect(() => {
    // console.log(answers);
    try {
      setMessage(answers["sv_message"].ans);
      setMonth(answers["sv_month"]);
      setOtherCares(answers["sv_other_cares"]);
      setDuration(answers["sv_past_how_long"]);
      setPastPill(answers["sv_past_pill"]);
      setScalpType(answers["sv_scalp_type"]);
      setShampoo(answers["sv_shampoo"]);
      setMinox(answers["sv_what_minox"].ans[0]);
      setCurrentPill(answers["sv_what_pill"].ans[0]);
    } catch (error) {
      console.log(error);
    }
    if (props.answers["sv_past_pill"]) {
      setDisplayExtra(true);
    } else {
      setDisplayExtra(false);
    }
  }, []);

  const copyItem = () => {
    var copyText = document.getElementsByClassName(id);
    var append = "";
    for (let i = 0; i < copyText.length; i++) {
      append = append.concat(" - " + copyText[i].innerText + "\n");
    }
    navigator.clipboard.writeText(append).then(
      () => {
        alert("문진 복사되었습니다");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <button id="copy-button-doctor" onClick={copyItem}>
      문진복사
      </button>
      <table className="fv-chart-table">
        {!props.answers["sv_past_pill"] ? (
          // 추가 질문 답변 하지 않은 patient
          <tbody>
            <tr>
              <li className={id}>
                기존에 탈모 처방약을
                <span id="doctor-patient-answer"> 복용한 경험이 있어요</span>
              </li>
            </tr>
            <tr>
              <li className={id} style={{ width: "max-content" }}>
                기존에 어떤 탈모약을 복용했는지 확인이 필요해요
              </li>
            </tr>
            <tr>
              <li className={id}>복용기간 및 부작용 여부도 확인이 필요해요</li>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <li
                className={id}
                style={{ wordBreak: "break-word", minWidth: "800px" }}
              >
                기존에{" "}
                <span id="doctor-patient-answer">
                  {props.answers["sv_past_pill"]}
                </span>
                을{" "}
                <span id="doctor-patient-answer">
                  {props.answers["sv_past_how_long"]}
                </span>{" "}
                복용한 경험이 있어요.
              </li>
            </tr>
            <tr>
              <li className={id}>
                {props.answers["sv_other_cares"] ||
                props.answers["sv_past_pill"] ||
                props.answers["message"]
                  ? "부작용 여부 확인이 필요해요"
                  : ""}
              </li>
            </tr>

            <tr>
              <li
                style={{
                  flexWrap: "wrap",
                  wordBreak: "break-word",
                  background: "#f7f7f7",
                  lineHeight: "20px",
                  textIndent: "-20px",
                  marginLeft: "20px",
                }}
                className={id}
              >
                현재관리:{" "}
                <span id="doctor-patient-answer">
                  {props.answers["sv_other_cares"].map((item, key) => (
                    <div style={{ display: "inline" }} key={key}>
                      {item}{', '}
                    </div>
                  ))}
                </span>
              </li>
            </tr>
          </tbody>
        )}

        {/* {pastPill ? (
         
        ) : (
          <></>
        )} */}
      </table>
      {/* ADDITIONAL INFO */}
      <div className="fv-chart-additional-info">
        <div>
          <p
            style={{
              color: "#585260",
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            추가메시지
          </p>
          <div
            style={{
              marginTop: "-15px",
              background: "#FFFFFF",
              border: "0.5px solid #B3B3B3",
              width: "300px",
              height: "122px",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <span style={{ color: "#333333", fontSize: "15px" }}>
              {message || "없음"}
            </span>
          </div>
        </div>
        {(props.hairlineKey || props.haircenterKey) && (
          <div>
            <p
              style={{
                color: "#585260",
                fontSize: "15px",
                fontWeight: "600",
                marginTop: "15px",
              }}
            >
              사진첨부:
            </p>
            <div
              style={{
                background: "red",
                width: "100%",
                marginTop: "-15px",
                background: "#FFFFFF",
                border: "0.5px solid #B3B3B3",
                width: "300px",
                minWidth: "300px",
                height: "122px",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "12px 5px 0px 5px",
                borderRadius: "4px",
              }}
            >
              <tr>
                <td>
                  <tr>
                    <td>
                      <a href={props.hairlineKey} target="_blank">
                        <img
                          src={props.hairlineKey}
                          style={{
                            width: "94px",
                            height: "94px",
                            borderRadius: "5px",
                            marginRight: "20px",
                          }}
                        />
                      </a>
                    </td>
                    <td>
                      <a href={props.haircenterKey} target="_blank">
                        <img
                          src={props.haircenterKey}
                          style={{
                            width: "94px",
                            height: "94px",
                            borderRadius: "5px",
                            marginRight: "2px",
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <a href={props.hairlineKey} target="_blank">
                        {/* 확대하기 */}
                      </a>
                    </td>
                    <td className="text-center">
                      <a href={props.haircenterKey} target="_blank">
                        {/* 확대하기 */}
                      </a>
                    </td>
                  </tr>
                </td>
              </tr>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
