import { __awaiter, __extends, __generator, __read, __rest, __spread } from "tslib";
import Observable from 'zen-observable-ts';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { MqttOverWSProvider } from './MqttOverWSProvider';
var logger = new Logger('AWSAppSyncProvider');
/**
 * @deprecated Unused, all usecases have migrated to AWSAppSyncRealtimeProvider
 */
var AWSAppSyncProvider = /** @class */ (function (_super) {
    __extends(AWSAppSyncProvider, _super);
    function AWSAppSyncProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._topicClient = new Map();
        _this._topicAlias = new Map();
        return _this;
    }
    Object.defineProperty(AWSAppSyncProvider.prototype, "endpoint", {
        get: function () {
            throw new Error('Not supported');
        },
        enumerable: true,
        configurable: true
    });
    AWSAppSyncProvider.prototype.getProviderName = function () {
        return 'AWSAppSyncProvider';
    };
    AWSAppSyncProvider.prototype.publish = function (topics, msg, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('Operation not supported');
            });
        });
    };
    AWSAppSyncProvider.prototype._cleanUp = function (clientId) {
        var _this = this;
        var topicsForClient = Array.from(this._topicClient.entries())
            .filter(function (_a) {
            var _b = __read(_a, 2), c = _b[1];
            return c.clientId === clientId;
        })
            .map(function (_a) {
            var _b = __read(_a, 1), t = _b[0];
            return t;
        });
        topicsForClient.forEach(function (t) { return _this._cleanUpForTopic(t); });
    };
    AWSAppSyncProvider.prototype._cleanUpForTopic = function (topic) {
        this._topicClient.delete(topic);
        this._topicAlias.delete(topic);
    };
    AWSAppSyncProvider.prototype.onDisconnect = function (_a) {
        var _this = this;
        var clientId = _a.clientId, errorCode = _a.errorCode, args = __rest(_a, ["clientId", "errorCode"]);
        if (errorCode !== 0) {
            var topicsForClient = Array.from(this._topicClient.entries())
                .filter(function (_a) {
                var _b = __read(_a, 2), c = _b[1];
                return c.clientId === clientId;
            })
                .map(function (_a) {
                var _b = __read(_a, 1), t = _b[0];
                return t;
            });
            topicsForClient.forEach(function (topic) {
                if (_this._topicObservers.has(topic)) {
                    _this._topicObservers.get(topic).forEach(function (obs) {
                        if (!obs.closed) {
                            obs.error(args);
                        }
                    });
                    _this._topicObservers.delete(topic);
                }
            });
            this._cleanUp(clientId);
        }
    };
    AWSAppSyncProvider.prototype.disconnect = function (clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clientsQueue.get(clientId, function () { return null; })];
                    case 1:
                        client = _a.sent();
                        return [4 /*yield*/, _super.prototype.disconnect.call(this, clientId)];
                    case 2:
                        _a.sent();
                        this._cleanUp(clientId);
                        return [2 /*return*/];
                }
            });
        });
    };
    AWSAppSyncProvider.prototype.subscribe = function (topics, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var result = new Observable(function (observer) {
            var targetTopics = [].concat(topics);
            logger.debug('Subscribing to topic(s)', targetTopics.join(','));
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var _a, mqttConnections, newSubscriptions, newAliases, map;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            // Add these topics to map
                            targetTopics.forEach(function (t) {
                                if (!_this._topicObservers.has(t)) {
                                    _this._topicObservers.set(t, new Set());
                                }
                                _this._topicObservers.get(t).add(observer);
                            });
                            _a = options.mqttConnections, mqttConnections = _a === void 0 ? [] : _a, newSubscriptions = options.newSubscriptions;
                            newAliases = Object.entries(newSubscriptions).map(function (_a) {
                                var _b = __read(_a, 2), alias = _b[0], v = _b[1];
                                return [v.topic, alias];
                            });
                            // Merge new aliases with old ones
                            this._topicAlias = new Map(__spread(Array.from(this._topicAlias.entries()), newAliases));
                            map = Object.entries(targetTopics.reduce(function (acc, elem) {
                                var connectionInfoForTopic = mqttConnections.find(function (c) { return c.topics.indexOf(elem) > -1; });
                                if (connectionInfoForTopic) {
                                    var clientId = connectionInfoForTopic.client, url = connectionInfoForTopic.url;
                                    if (!acc[clientId]) {
                                        acc[clientId] = {
                                            url: url,
                                            topics: new Set(),
                                        };
                                    }
                                    acc[clientId].topics.add(elem);
                                }
                                return acc;
                            }, {}));
                            // reconnect everything we have in the map
                            return [4 /*yield*/, Promise.all(map.map(function (_a) {
                                    var _b = __read(_a, 2), clientId = _b[0], _c = _b[1], url = _c.url, topics = _c.topics;
                                    return __awaiter(_this, void 0, void 0, function () {
                                        var client, err_1;
                                        var _this = this;
                                        return __generator(this, function (_d) {
                                            switch (_d.label) {
                                                case 0:
                                                    client = null;
                                                    _d.label = 1;
                                                case 1:
                                                    _d.trys.push([1, 3, , 4]);
                                                    return [4 /*yield*/, this.connect(clientId, {
                                                            clientId: clientId,
                                                            url: url,
                                                        })];
                                                case 2:
                                                    client = _d.sent();
                                                    return [3 /*break*/, 4];
                                                case 3:
                                                    err_1 = _d.sent();
                                                    observer.error({ message: 'Failed to connect', error: err_1 });
                                                    observer.complete();
                                                    return [2 /*return*/, undefined];
                                                case 4:
                                                    // subscribe to all topics for this client
                                                    // store topic-client mapping
                                                    topics.forEach(function (topic) {
                                                        if (client.isConnected()) {
                                                            client.subscribe(topic);
                                                            _this._topicClient.set(topic, client);
                                                        }
                                                    });
                                                    return [2 /*return*/, client];
                                            }
                                        });
                                    });
                                }))];
                        case 1:
                            // reconnect everything we have in the map
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            }); })();
            return function () {
                logger.debug('Unsubscribing from topic(s)', targetTopics.join(','));
                targetTopics.forEach(function (t) {
                    var client = _this._topicClient.get(t);
                    if (client && client.isConnected()) {
                        client.unsubscribe(t);
                        _this._topicClient.delete(t);
                        if (!Array.from(_this._topicClient.values()).some(function (c) { return c === client; })) {
                            _this.disconnect(client.clientId);
                        }
                    }
                    _this._topicObservers.delete(t);
                });
            };
        });
        return Observable.from(result).map(function (value) {
            var topic = _this.getTopicForValue(value);
            var alias = _this._topicAlias.get(topic);
            value.data = Object.entries(value.data).reduce(function (obj, _a) {
                var _b = __read(_a, 2), origKey = _b[0], val = _b[1];
                return ((obj[(alias || origKey)] = val), obj);
            }, {});
            return value;
        });
    };
    return AWSAppSyncProvider;
}(MqttOverWSProvider));
export { AWSAppSyncProvider };
