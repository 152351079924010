import { API, Storage } from "aws-amplify";
import { useEffect, useState, useRef } from "react";
import { Collapse, Button, Card, CardBody, Col, Row } from "reactstrap";
import { updateMars } from "utils/data";
import { PRODUCTS } from "utils/products";
import { calcPrice4, getDoctorInfo } from "utils/util";
import { getDoctorIdentityID } from "utils/util";
import { parseDateString } from "utils/util";
import { won, zeroPad } from "utils/util";
import * as mutations from "../../graphql/mutations";

// css
import "./pharmaStyles.css";

function PharmaCard(props) {
  const [isDetail, setIsDetail] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [signedURL, setSignedURL] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [elapsedTime, setElapsedTime] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payTime, setPayTime] = useState();
  const [rtime, setRtime] = useState(0);
  const [rday, setRday] = useState("");
  const [rdate, setRdate] = useState(0);
  const [rmonth, setRmonth] = useState(0);

  const [pillPrice, setPillPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);
  const [mediTotal, setMediTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [toggle, setToggle] = useState(false);

  const [hover, setHover] = useState(false);

  // label check
  const [checked, setChecked] = useState(false);

  const {
    patient,
    checkAll,
    setCheckAll,
    jojeReadyList,
    setJojeReadyList,
    jojeIngList,
    setJojeIngList,
    jojeDoneList,
    setJojeDoneList,
    navToggle,
  } = props;

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      if (!p.venus) {
        let a = props.patient.ans;
        setAnswers(JSON.parse(a));
        let res = calcPrice4(
          p.pill,
          p.minox,
          p.month,
          p.minoxMonth,
          null,
          p.capil,
          p.capilMonth
        );
        setPillPrice(res.pillPrice);
        setMinoxPrice(res.minoxPrice);
        setCapilPrice(res.capilPrice);
        setMediTotal(res.total - 10000 - 2900);
        setTotal(res.taotal);
      }

      if (p.filekey) {
        let id = getDoctorIdentityID(p.doctor);
        if (p.s3_identity) {
          id = p.s3_identity;
        }
        const key = await Storage.get(p.filekey, {
          level: "protected",
          identityId: id,
        });
        setSignedURL(key);
      }

      if (p.delivery_number) {
        setDeliveryNumber(p.delivery_number);
      }
      if (p.reservation_time) {
        let rt = p.reservation_time.split("/");
        setRmonth(parseInt(rt[0]));
        setRdate(parseInt(rt[1]));
        setRday(rt[2]);
        setRtime(parseInt(rt[3]));
      }

      // parse payresult
      if (p.payResult) {
        let r = JSON.parse(p.payResult);
        // let message = r.PCD_PAY_MSG || "";
        let amount = r.AMNT || "";
        let time = formatPayTime(r.PTIME) || "계좌이체";

        setPayTime(time);
        setPayAmount(amount);

        // setPayMessage(message);
      }

      // set the elapsed time
      let min = Math.round(
        (new Date() - new Date(props.patient.createdAt.toString())) /
          (60 * 1000)
      );
      if (min < 60) {
        setElapsedTime(min + " 분");
      } else {
        let hour = Math.floor(min / 60);
        let remain = min % 60;
        setElapsedTime(hour + "시간" + remain + "분");
      }
    };

    asyncWrapper();
  }, []);

  const formatPhone = (num) => {
    var cleaned = ("" + num).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return num;
  };

  const formatPayTime = (pay) => {
    var cleaned = ("" + pay).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
      return (
        match[1] +
        ". " +
        match[2] +
        ". " +
        match[3] +
        ". " +
        match[4] +
        ":" +
        match[5]
      );
    }
    return pay;
  };

  const formatMediDoneTime = (time) => {
    if (JSON.stringify(time).length > 4) {
      return JSON.stringify(time).slice(1, 11).replaceAll(["-"], ".");
    } else {
      return "Unavailable";
    }
  };

  const formatPharmaTime = (time) => {
    let yoil = ["일", "월", "화", "수", "목", "금", "토"];
    var dateObj = new Date(time);
    var date = dateObj.getDate();
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDay();
    return `${zeroPad(month, 2)}/${zeroPad(date, 2)} (${yoil[day]})`;
  };

  const formatMedsInfoVenus = (chart) => {
    try {
      let prescMedicines = chart.prescription.prescribed_medicine.items;
      let parsed = [];

      for (let med of prescMedicines) {
        // console.log(med.medicine.product_name, med.dosage, med.medicine.base, med.medicine.unit);
        let temp = `${med.medicine.product_name} ${
          med.dosage * med.medicine.base
        }${med.medicine.unit}`;
        parsed.push(temp);
      }
      return parsed.join(", ");
    } catch (error) {
      console.log(error);
    }
  };

  const formatMedsInfo = (meds) => {
    var parsed = [];
    if (meds.pill === "아니요" || undefined || null) {
      //   parsed.push("");
    } else if (PRODUCTS[meds.pill].name !== "없음") {
      let temp =
        PRODUCTS[meds.pill].name +
        " " +
        PRODUCTS[meds.pill].base * meds.month +
        PRODUCTS[meds.pill].unit;
      parsed.push(temp);
    }

    if (meds.minox === "아니요" || undefined || null) {
      //   parsed.push("");
    } else if (PRODUCTS[meds.minox].name !== "없음") {
      let temp =
        PRODUCTS[meds.minox].name +
        " " +
        meds.minoxMonth * PRODUCTS[meds.minox].base +
        PRODUCTS[meds.minox].unit;
      parsed.push(temp);
    }

    return parsed.join(", ");
  };

  const handlePrescription = () => {
    window.open(signedURL, "_blank");
  };

  // adds to list of joje-ready patients
  const handleCheck = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (checkAll) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkAll]);

  useEffect(() => {
    // joje ready
    if (patient.p2status === "joje_ready") {
      if (checked && !jojeReadyList.includes(patient)) {
        setJojeReadyList((jojeReadyList) => [...jojeReadyList, patient]);
      } else if (!checked) {
        setJojeReadyList((jojeReadyList) =>
          jojeReadyList.filter((removed) => removed !== patient)
        );
      }
    }

    if (patient.p2status === "joje_ing") {
      if (checked && !jojeIngList.includes(patient)) {
        setJojeIngList((jojeIngList) => [...jojeIngList, patient]);
      } else if (!checked) {
        setJojeIngList((jojeIngList) =>
          jojeIngList.filter((removed) => removed !== patient)
        );
      }
    }

    if (patient.p2status === "joje_done") {
      if (checked && !jojeDoneList.includes(patient)) {
        setJojeDoneList((jojeDoneList) => [...jojeDoneList, patient]);
      } else if (!checked) {
        setJojeDoneList((jojeDoneList) =>
          jojeDoneList.filter((removed) => removed !== patient)
        );
      }
    }
  }, [checked]);

  return (
    <Card
      className={
        !checked ? "pharma-card-body-class" : "pharma-card-body-class-active"
      }
      onClick={(e) => {
        setIsDetail(!isDetail);
        setToggle(!toggle);
      }}
    >
      {/* Default Row */}
      <label
        id="pharma-card-checkbox"
        style={{
          display:
            patient.p2status === "joje_done" || patient.p2status === "deli_done"
              ? "none"
              : "content",
        }}
      >
        <input
          type="checkbox"
          id="checkall"
          name="checkAll"
          checked={checked}
          value={checked}
          onChange={handleCheck}
        ></input>
        <span className="checkmark"></span>
      </label>
      <div className="pharma-card-container">
        {/* DETAILS USER DETAILS USER DETAILS */}
        {/* 1 */}
        <div
          style={{
            marginLeft: "45px",
            minWidth: "220px",
          }}
          id="pharma-card-fixed-col"
        >
          <b>{props.patient.name}</b>님 ({formatPhone(patient.phone)})
        </div>
        {/* 2 */}
        <div id="pharma-card-fixed-col" style={{ marginLeft: "60px" }}>
          {/* {props.patient.yeyak_time ? props.patiet.yeyak_time.split("T")[0] : ""} */}
          {props.patient.yeyak_time
            ? props.patient.yeyak_time.split("T")[0]
            : ""}
        </div>
        {/* 3 */}
        <div
          id="pharma-card-fixed-col"
          style={{
            minWidth: "700px",
            marginLeft: "30px",
          }}
          onMouseOut={(e) => {
            e.preventDefault();
            setHover(false);
          }}
          onMouseOver={(e) => {
            e.preventDefault();
            setHover(true);
          }}
        >
          {hover ? (
            <div className="on-hover">
              {patient.venus
                ? formatMedsInfoVenus(patient)
                : formatMedsInfo(patient)}
            </div>
          ) : (
            <></>
          )}
          {patient.venus
            ? formatMedsInfoVenus(patient)
            : formatMedsInfo(patient)}
          {/* {formatMedsInfo(patient)} */}
        </div>
        {/* 4 */}
        {patient.p2status === "deli_done" ? (
          <div
            id="pharma-card-fixed-col"
            style={{
              width: "300px",
              marginLeft: "250px",
              minWidth: "300px",
            }}
          >
            {patient.delivery_number}
          </div>
        ) : (
          <div></div>
        )}
        {/* Toggle Button */}
        <div id="pharma-detail-toggle">
          <button>
            <i className={toggle ? "arrow down" : "arrow up"}></i>
          </button>
        </div>
        {/* 5 */}
        {props.patient.p2status === "joje_done" ? (
          <div
            className="pharma-card-input-group"
            onClick={(e) => {
              // prevents click-through
              e.stopPropagation();
            }}
          >
            <input
              id="pharma-deli-input"
              value={deliveryNumber}
              type="text"
              onChange={(e) => {
                setDeliveryNumber(e.target.value);
              }}
              placeholder="운송장번호"
            ></input>
            <Button
              id="pharma-card-button-default"
              onClick={(e) => {
                if (deliveryNumber === "") {
                  alert("등기번호 미입력!");
                  return;
                }
                if (
                  window.confirm(
                    props.patient.name +
                      "님 등기번호 입력 완료: " +
                      deliveryNumber
                  )
                ) {
                  props.onDeliveryReady(props.patient, deliveryNumber);
                }
              }}
            >
              입력 완료
            </Button>
          </div>
        ) : (
          <div></div>
        )}

        {/* Button '조제 시작' */}
        <div className="pharma-card-button-container">
          {/* 배송신청 배송신청 배송신청 */}
          {props.patient.p2status === "medi_prep" && (
            <div>
              <a href={signedURL} target="_blank">
                처방전 다운로드
              </a>
              <Button
                onClick={() => {
                  if (
                    window.confirm(
                      "정말로 '" +
                        props.patient.name +
                        "'님 조제 완료 하셨습니까?"
                    )
                  ) {
                    props.onMedicationReady(
                      props.patient.id,
                      props.patient.name
                    );
                  }
                }}
              >
                조제 완료
              </Button>
            </div>
          )}
        </div>
      </div>

      {/*  If Card is Clicked/Open */}
      <Collapse
        isOpen={isDetail}
        onClick={(e) => e.stopPropagation()}
        className="pharma-card-collapse"
      >
        <div className="pharma-card-container-open">
          <div className="pharma-card-details-open">
            <div id="pharma-card-patient-details">
              <table>
                <tbody>
                  <tr id="pharma-data-tr">
                    <td id="pharma-card-bold">처방병원:</td>
                    <td id="pharma-card-data">
                      {getDoctorInfo(patient.doctor).office}
                    </td>
                  </tr>
                  <tr id="pharma-data-tr">
                    <td id="pharma-card-bold">처방날짜:</td>
                    <td id="pharma-card-data">
                      {/* {formatMediDoneTime(patient.yeyak_time)} */}
                      {formatPharmaTime(patient.yeyak_time)}
                    </td>
                  </tr>
                  <tr id="pharma-data-tr">
                    <td id="pharma-card-bold">배송주소:</td>
                    <td id="pharma-card-data">
                      {props.patient.addr && (
                        <div>{`${props.patient.addr} ${props.patient.addr2} ${props.patient.zip}`}</div>
                      )}
                      {props.patient.address && (
                        <div>{props.patient.address}</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="pharma-card-medication-details">
              {!patient.venus ? (
                <>
                  <table style={{ display: "flex" }}>
                    <thead
                      style={{
                        fontWeight: "600",
                        color: "#58595B",
                        fontSize: "13px",
                      }}
                    >
                      처방내용:
                    </thead>
                    <tbody>
                      {props.patient.pill &&
                        props.patient.pill !== "아니요" && (
                          <tr id="pharma-data-tr">
                            <td id="pharma-card-light">
                              {PRODUCTS[props.patient.pill].name} (
                              {props.patient.month *
                                PRODUCTS[props.patient.pill].base}
                              {PRODUCTS[props.patient.pill].unit}):
                            </td>
                            <td
                              style={{
                                marginLeft: "-10px",
                                width: "80px",
                                textAlign: " right",
                              }}
                              id="pharma-card-data"
                            >
                              {won(pillPrice)}원
                            </td>
                          </tr>
                        )}
                      {props.patient.minox &&
                        props.patient.minox !== "아니요" && (
                          <tr id="pharma-data-tr">
                            <td id="pharma-card-light">
                              {PRODUCTS[props.patient.minox].name} (
                              {props.patient.minoxMonth *
                                PRODUCTS[props.patient.minox].base}
                              {PRODUCTS[props.patient.minox].unit}):
                            </td>
                            <td
                              style={{
                                marginLeft: "-10px",
                                width: "80px",
                                textAlign: " right",
                                fontSize: "13px",
                              }}
                              id="pharma-card-data"
                            >
                              {won(minoxPrice)}원
                            </td>
                          </tr>
                        )}
                      {props.patient.capil && (
                        <tr id="pharma-data-tr">
                          <td id="pharma-card-light">
                            {PRODUCTS[props.patient.capil].name}(
                            {props.patient.capilMonth *
                              PRODUCTS[props.patient.capil].base}
                            {PRODUCTS[props.patient.capil].unit})
                          </td>
                          <td
                            style={{
                              marginLeft: "-10px",
                              width: "80px",
                              textAlign: " right",
                            }}
                            id="pharma-card-data"
                          >
                            {won(capilPrice)}원
                          </td>
                        </tr>
                      )}
                      <tr id="pharma-data-tr">
                        <td
                          id="pharma-card-bold"
                          style={{ marginLeft: "-46px" }}
                        ></td>
                        <td
                          id="pharma-card-data"
                          style={{
                            fontWeight: "900",
                            marginLeft: "57.5px",
                            width: "80px",
                            textAlign: " right",
                          }}
                        >
                          총 {won(mediTotal)}원
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <>{formatMedsInfoVenus(patient)}</>
              )}

              <div className="pharma-card-collapse-button-group">
                {props.patient.p2status === "joje_ing" ? (
                  <>
                    <button
                      id="pharma-card-button-default"
                      onClick={(e) => {
                        handlePrescription();
                        e.stopPropagation();
                      }}
                    >
                      처방전 보기
                    </button>
                    <button
                      style={{ width: "100%", marginTop: "10px" }}
                      onClick={() => {
                        if (
                          window.confirm("정말로 조제대기로 변경 하시겠습니까?")
                        ) {
                          props.changeToReady(props.patient);
                          setChecked(false);
                        }
                      }}
                      id="pharma-card-button-default"
                    >
                      조제대기로 되돌리기
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {props.patient.p2status === "joje_done" ? (
                  <>
                    <button
                      id="pharma-card-button-default"
                      onClick={handlePrescription}
                      style={{ marginBottom: "10px" }}
                    >
                      처방전 보기
                    </button>
                    <button
                      id="pharma-card-button-default"
                      style={{
                        width: "100%",
                        fontSize: "13px",
                        minWidth: "88px",
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "정말로 조제중 상태로 변경 하시겠습니까?"
                          )
                        ) {
                          props.jojeStart(props.patient);
                          setChecked(false);
                        }
                      }}
                    >
                      약 조제중으로 되돌리기
                    </button>
                  </>
                ) : (
                  <></>
                )}
                {props.patient.p2status === "deli_done" ? (
                  <>
                    <button
                      id="pharma-card-button-default"
                      onClick={handlePrescription}
                    >
                      처방전 보기
                    </button>
                    <button
                      id="pharma-card-button-default"
                      style={{
                        width: "100%",
                        fontSize: "13px",
                        minWidth: "88px",
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "정말로 조제완료 상태로 변경 하시겠습니까?"
                          )
                        ) {
                          props.jojeDone(props.patient);
                          setChecked(false);
                        }
                      }}
                    >
                      약 조제중으로 되돌리기
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* EXTRA BUTTONS FOR WHEN YOU COLLAPSE / UNCOLLAPSE*/}
          </div>
        </div>
      </Collapse>
    </Card>
  );
}
export default PharmaCard;
