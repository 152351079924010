import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { listPatients } from "graphql/queries";
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button } from 'reactstrap';
import { sendAnalytics } from "utils/analytics";
import { getAllChartsWithUserInfo } from "utils/data";
import { getChartsByKid } from "utils/data";
import { getPatient } from "utils/data";
import { slack2ops } from 'utils/util';
import * as mutations from "../../graphql/mutations";
import '@aws-amplify/ui-react/styles.css';
import { getAllUsersWithName } from "utils/data";
import { getChartByID } from "utils/data";
import { slack2accept } from "utils/util";
import { pushMarsAnalytics } from "utils/analytics";

function AutoRefillMessage() {
	const [patients, setPatients] = useState();
	const [candidates, setCandidates] = useState([]);
	const history = useHistory();

	useEffect(() => {
		const asyncWrapper = async () => {
			let user = await Auth.currentUserInfo();
			// console.log("user:", user);
			if (["mars1"].indexOf(user.username) === -1) {
				alert("login with operator");
				let so = await Auth.signOut();
				return;
			}

			try {
				let st = new Date();

				let patients = await getAllChartsWithUserInfo("DESC", null, 1000);

				let et = new Date();
				let diff = et - st;
				console.log("time to load:", diff);

				setPatients(patients);
			} catch (error) {
				console.log(error);
			}
		}
		asyncWrapper();
	}, [])

	const sendRefillMessage = async () => {
		if (candidates.length < 1) {
			alert("no candidates to send");
			return;
		}

		// slack2ops("[오토리필메시지] " + (new Date()).toDateString() + "\n" + "총: " + candidates.length + " 명에게 리필 메시지를 보냅니다.")
		slack2accept("[오토리필메시지] " + (new Date()).toDateString() + "\n" + "총: " + candidates.length + " 명에게 리필 메시지를 보냅니다.")
		for (let p of candidates) {
			// console.log(p.name, p.kid, p.reservation_time, p.month, p.rm);
			try {
				let res = await API.post("marsrest", "/repeat", {
					body: {
						kid: p.kid,
						message: "리필메시지",
						name: p.name
					},
				});
				if (res) {
					console.log(
						res.data.taskId + " : " + res.data.status
					);
					try {
						const patientDetails = {
							id: p.id,
							ignoreStatus: "yes",
							rm: 'y'
						};

						const updatedPatient = await API.graphql({
							query: mutations.updateMars,
							authMode: 'AMAZON_COGNITO_USER_POOLS',
							variables: { input: patientDetails },
						});
					} catch (error) {
						console.log(error);
					}

					slack2ops("[오토리필메시지] " + p.name + " [" + p.kid + "]에게 리필 메시지 보냄.\n" + "최근 진료일: " + p.reservation_time + " 처방개월: " + p.month);
					sendAnalytics({ "type": "ops", "from": "autorepill", "kid": p.kid, "event": "autorepill.send_repill_msg" });
					await pushMarsAnalytics(p.kid, 'send_repill_msg', { from: 'arm' });
				}
			} catch (error) {
				alert(error);
			}
		}
	};

	const getRefillCandidates = async () => {
		let today = new Date();
		console.log("get refill candidates");
		let count = 1;
		let candidates = [];
		for (let p of patients) {
			if (p.isPaid !== "yes") {
				continue;
			}
			let diff = today - new Date(p.createdAt);
			const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

			if (p.rm !== 'y' && diffDays <= 95 && p.month !== "" && (p.month * 30 < (diffDays + 2))) {
				candidates.push(p);
				count++;
			};
		}
		//  console.log(count, candidates);

		candidates.sort((a, b) => a.kid - b.kid);
		// console.log(candidates);

		candidates.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

		let unique = candidates.filter((v, i, a) => a.findIndex(v2 => (v2.kid === v.kid)) === i);

		// console.log(unique.length);
		for (let p of unique) {
			console.log(p.name, p.kid, p.reservation_time, p.month, p.rm);
			let patient = await getChartsByKid(p.kid);
			// console.log("patient:", patient);

			if (patient.length > 1) {
				for (let p2 of patient) {
					if (new Date(p2.createdAt) > new Date(p.createdAt)) {
						console.log(p2.reservation_time);
						try {
							const patientDetails = {
								id: p.id,
								ignoreStatus: "yes",
								rm: 'y'
							};

							const updatedPatient = await API.graphql({
								query: mutations.updateMars,
								authMode: 'AMAZON_COGNITO_USER_POOLS',
								variables: { input: patientDetails },
							});
						} catch (error) {
							console.log(error);
						}

					}
				}
			}
		}
		// unique = [{ name: "김선민", kid: "2023339027", month: "3", id: "c15f45de-a5be-44c5-bdb2-69ccf8397e24" }]
		setCandidates(unique);
	}

	const getRefillCandidate2 = async () => {
		let users = await getAllUsersWithName();
		let counter = 0;
		for (let user of users) {
			let charts = JSON.parse(user.charts);
			if (charts && charts.length > 0 && charts[0].stat === "deli_done") {
				let chart = await getChartByID(charts[0].id);
				if (chart && chart.rm !== "y") {
					if (new Date(chart.yeyak_time).getTime() < new Date("2022-11-14T09:00:00z").getTime()) {
						counter++
						console.log(chart.id, chart.yeyak_time);
					}

				}
			}
		}

		console.log("total count: ", counter);
	}

	return (
		<>
			<div className='scrollable'>
				<div>Auto Refill Message</div>

				{/* <div>{JSON.stringify(patients)}</div> */}
				<Button onClick={getRefillCandidates}>Autorefill Candidates</Button>
				<div>
					{candidates.map(c => {
						return <div>{c.name} {c.reservation_time} {c.month}개월</div>
					})}
				</div>
				<Button onClick={sendRefillMessage}>Send Auto Refill Message</Button>
{/* 
				<div>
					<button onClick={getRefillCandidate2}>Autorefill candidates2</button>
				</div> */}

				<div>
					<button onClick={async () => {
						// alert("loggin test1");
						let p = {};
						p.kid = "111222333"
						let anal = await pushMarsAnalytics(p.kid, 'send_repill_msg', { from: 'arm' });
						console.log("analytics result: ", anal);

					}}>로깅 테스트</button>
				</div>

			</div>


		</>
	)
}

export default withAuthenticator(AutoRefillMessage);