import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";

// components import
import Intro from "./dna-components/DNAIntro";
import KitDescription from "./dna-components/KitDescription";
import DNADescription from "./dna-components/DNADescription";
import DNAEventButton from "./dna-components/DNAEventButton";
import EventHeader from "components/Headers/EventHeader";
import DnaEventLanding from "./DnaEventLanding";
import DNALab from "../dnaEvent/dna-event-assets/animations/DNALabClear.json";

// questions import
import Q0 from "./dna-questions/QuestionZero";
import Q1 from "./dna-questions/FirstQuestion";
import Q2 from "./dna-questions/SecondQuestion";
import Q3 from "./dna-questions/ThirdQuestion";
import Q4 from "./dna-questions/FourthQuestion";
import Q5 from "./dna-questions/FifthQuestion";
import Q6 from "./dna-questions/SixthQuestion";

// for testing purposes
import DnaEventWinner from "./DnaEventWinner";
import DNAForm from "./dna-components/DNAForm";
import DNAFormConfirm from "./dna-components/DNAFormComplete";
import DNACountDown from "./dna-components/DNACountDown";
import DNALoader from "./dna-components/DNALoader";
import DnaEventOver from "./DnaEventOver";

// css
import "./dnaEventStyles.css";
import { slack2events } from "utils/util";

export default function DnaEventMain() {
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const containerRef = useRef();
  const history = useHistory();

  useEffect(() => {
    let hasSubmitted = localStorage.getItem("dna_event");
    if(hasSubmitted === "yes") {
      history.push("/dnaOver");
      return;
    }

    const asyncWrapper = async () => {
      let ip = localStorage.getItem("mars-ip");
      if (!ip) {
        // console.log("there's no ip!"); todo: why called 3 times?
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();
        ip = locationIp.IPv4;
        localStorage.setItem("mars-ip", ip);
      }

      slack2events(`${new Date().toISOString()}, DNA1, ${ip}, 응모페이지_방문`);
    }
    asyncWrapper();
  }, [])


  const handleRequest = () => {
    let ip = localStorage.getItem("mars-ip");
    slack2events(`${new Date().toISOString()}, DNA1, ${ip}, 응모하기_버튼_클릭!`);
    setClicked(true);
    try {
      axios
        .get(
          `https://cgitz0ldmh.execute-api.ap-northeast-2.amazonaws.com/event_submit`
        )
        .then((response) => {
          console.log(response);
          setClicked(false);
          if (JSON.stringify(response.data) === "success") {
            setSuccess(true);
          } else {
            let hasSubmitted = localStorage.getItem("dna_event");
            if (hasSubmitted !== "yes") {
              localStorage.setItem("dna_event", "yes");
              history.push("/dnaOver");
            } else if (hasSubmitted === "yes") {
              history.push("dnaExpired");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          alert("error has occurred");
          setClicked(false);
          return 0;
        });
    } finally {
      return 1;
    }
  };

  return (
    <div
      className="scrollable"
      style={{
        background: "white",
        overflowX: "hidden",
        height: "100vh",
        // scrollSnapType: "y mandatory",
        display: "flex",
        flexDirection: "column",
        placeItems: "center",
        overscrollBehavior: "none",
      }}
    >
      <EventHeader />
      <div className="dna-event-main-container">
        <div className="dna-event-header-container" ref={containerRef}>
          <Intro />
        </div>
        <div className="dna-questions-container">
          {/* <Q0 /> */}
          <Q1 />
          <div className="mars-divider-gray" />
          <KitDescription />
          {/* <div className="mars-divider-gray" /> */}
          {/* <Q2 /> */}
          {/* <div className="mars-divider-gray" /> */}
          {/* <Q3 /> */}
          <div className="mars-divider-gray" />
          <Q4 />
          <div className="mars-divider-gray" />
          <Q5 />
          <div className="mars-divider-gray" />
          <Q6 />
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          padding: "0px 20px 0px 20px",
          position: "fixed",
          bottom: "30px",
        }}
      >
        <button
          style={{
            width: "100%",
            height: "55px",
            background: "black",
            color: "white",
            border: "none",
            borderRadius: "4px",
            fontSize: "18px",
            position: "relative",
            zIndex: 99,
          }}
          onClick={() => {
            // setClicked(true);
            handleRequest();
            containerRef.current.scrollIntoView({ behavior: "smooth" });
          }}

        >
          응모하기
        </button>
      </div>
      {clicked ? <DNALoader /> : <></>}
      {fail ? <DnaEventOver></DnaEventOver> : <></>}
    </div>
  );
}
