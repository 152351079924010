import React, { useState, useEffect } from "react";
import logo from "../../../../assets/img/mars_logo_black.png";

// css
import "../styling/componentStyling.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <img src={logo} />
      <span>남성탈모 전문 비대면 진료 서비스</span>
    </div>
  );
}
