import React from "react";
import { motion } from "framer-motion";

// assets
import clipboard from "../assets/clipboard.png";
import folder from "../assets/folder.png";
import tube from "../assets/tube.png";
import form from "../assets/form.png";
import flask from "../assets/flask.png";
import wash from "../assets/wash.png";
import kit from "../assets/kit.png";

// css
import "./exogenePageStyling.css";

export default function ExogeneKit() {
  return (
    <div className="exogene-kit-container">
      <div className="exogene-content-wrapper">
        <span className="exogene-title-med" style={{ color: "#333D96" }}>
          유전자 홈키트 구성품
        </span>
        <div
          className="exogene-circle-lg"
          style={{ position: "absolute", left: "-40px", top: "25.5%" }}
        ></div>
        <div className="exogene-kit-info">
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            id="exogene-kit-info-item"
          >
            <img src={clipboard} alt="kit-one" />
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            id="exogene-kit-info-item"
          >
            <img src={folder} alt="kit-two" />
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            id="exogene-kit-info-item"
          >
            <img src={tube} alt="kit-three" />
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            id="exogene-kit-info-item"
          >
            <img src={form} alt="kit-four" />
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            id="exogene-kit-info-item"
          >
            <img src={flask} alt="kit-five" />
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.2 }}
            id="exogene-kit-info-item"
          >
            <img src={wash} alt="kit-six" />
          </motion.span>
          <img
            src={kit}
            alt="kit-kit"
            style={{
              position: "absolute",
              width: "80px",
              height: "auto",
              right: "0",
              pointerEvents: "none",
            }}
          />
        </div>
        <span className="exogene-text-desc" style={{ width: "100vw" }}>
          홈키트는 유전자 검사 전문회사인 BF 생명과학의 연구소와 제휴하여
          분석결과를 제공하고 있습니다.
        </span>
      </div>
    </div>
  );
}
