const { ClipLoader } = require("react-spinners");

function MarsLoaderSm(props) {
    return (
        <>
            <div style={{marginBottom:"20px"}}>
                <ClipLoader color={"#6A43B8"} loading={props.isLoading} size={32} />
            </div>
        </>
    )
}
export default MarsLoaderSm;