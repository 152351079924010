import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import { useHistory } from "react-router";
import { Button, Col, Row } from "reactstrap";

function MedicationDetail(props) {
  const history = useHistory();
  return (
    // overscroll behavior ; none => prevents the 'stretch' behavior on overscroll with modern mobile devices
    <div className="scrollable" style={{ overscrollBehavior: "none" }}>
      <MarsNavbarWithLine />
      <div style={{ marginTop: "80px" }}>
        {/* <img alt="..." src={require("assets/img/" + props.history.location.state)}></img> */}
        <img
          alt="..."
          src={require("./medication-images/new-" +
            props.history.location.state)}
        ></img>
      </div>

      {props.history.location.state.startsWith("medication-rogaine") && (
        <div style={{ marginTop: "-200px", marginBottom: "100px" }}>
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "22px" }}>
                    <img
                      alt="..."
                      src={require("assets/img/rogaine_howto.png")}
                      width="140px"
                    />
                  </td>
                </tr>
                <tr className="text-center">
                  {/* Warning: <a> cannot appear as a child of <tr> */}
                  <a href="https://www.youtube.com/watch?v=UrJEa9XocRQ&ab_channel=%EB%A1%9C%EA%B2%8C%EC%9D%B8%ED%8F%BC">
                    <u>사용방법 동영상 보기</u>
                  </a>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div>
        <Row
          className="justify-content-center"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            padding: 0,
            margin: 0,
            width: "100%",
          }}
        >
          <Col md="4" xs="12">
            <div style={{backgroundColor: 'white', width: '100%'}} >
              <Button
                style={{
                  width: "95%",
                  height: "60px",
                  backgroundColor: "#282C34",
                  marginBottom: "26px",
				  marginTop: '-55px'
                }}
                className="btn"
                size="lg"
                color="primary"
                disabled={false}
                onClick={() => {
                  history.push("/medication");
                }}
              >
                복약정보 페이지로 돌아가기
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default MedicationDetail;
