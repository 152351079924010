import { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import PersonalInfoPolicy from "views/agreements/PersonalInfoPolicy";
import ServiceAgreement from "views/agreements/ServiceAgreement";
import SensitivityAgreement from "views/agreements/SensitivityAgreement";

function Agreement(props) {
  const { from } = props;
  const [aAgreed, setAAgreed] = useState(true);
  const [sAgreed, setSAgreed] = useState(true);
  const [pAgreed, setPAgreed] = useState(true);
  const [mAgreed, setMAgreed] = useState(true);
  const [modalLive, setModalLive] = useState(false);
  const [type, setType] = useState("service");

  useEffect(() => {
    if (sAgreed && pAgreed) {
      setAAgreed(true);
      props.selectAgreement(true);
    }

    if (!sAgreed || !pAgreed) {
      setAAgreed(false);
      props.selectAgreement(false);
    }
  }, [sAgreed, pAgreed, mAgreed]);

  return (
    <>
      <div>
        <div className="reservation-heading">MARS 서비스 이용약관</div>
        <div className="agreement-explain">
          {from && from === "DNA"
            ? "향후 고객님께서 MARS로 비대면 진료 시 유전자 검사결과가 활용 될 수 있습니다."
            : `MARS 서비스 이용약관
            비대면진료 예약을 위해 자사 정보 제공 및 이용약관에 동의해 주세요. MARS는 고객의 편의를 위해서 검증된 
            제휴병원과 제휴약국에서만 통화진료, 
            조제 및 약 배송이 진행됩니다. 동의하시는 경우 진료예약을 진행해 주세요.`}
        </div>

        <table width={"100%"}>
          <tbody>
            <tr
              onClick={() => {
                if (!aAgreed) {
                  setPAgreed(true);
                  setSAgreed(true);
                  // setMAgreed(true);
                  props.selectAgreement(true);
                } else {
                  setPAgreed(false);
                  setSAgreed(false);
                  // setMAgreed(false);
                  props.selectAgreement(false);
                }
                setAAgreed(!aAgreed);
              }}
            >
              <td>
                {aAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-checked-purple.png")}
                    style={{ height: "10px" }}
                  />
                )}
                {!aAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-uncheck.png")}
                    style={{ height: "10px" }}
                  />
                )}
              </td>
              <td className="purple400">전체동의</td>
              <td></td>
            </tr>

            <tr
              style={{ borderTop: "1px solid grey" }}
              onClick={() => {
                setSAgreed(!sAgreed);
              }}
            >
              <td>
                {sAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-checked-purple.png")}
                    style={{ height: "10px" }}
                  />
                )}
                {!sAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-uncheck.png")}
                    style={{ height: "10px" }}
                  />
                )}
              </td>
              <td style={{ paddingTop: "8px" }}>
                <span className="purple400">(필수)</span> 서비스 이용 약관
              </td>
              <td
                style={{ paddingTop: "8px" }}
                className="right"
                onClick={(e) => {
                  setType("service");
                  setModalLive(true);
                  e.stopPropagation();
                }}
              >
                보기
              </td>
            </tr>
            <tr>
              <td
                onClick={() => {
                  setPAgreed(!pAgreed);
                }}
              >
                {pAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-checked-purple.png")}
                    style={{ height: "10px" }}
                  />
                )}
                {!pAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-uncheck.png")}
                    style={{ height: "10px" }}
                  />
                )}
              </td>
              <td>
                <span className="purple400">(필수)</span> 개인정보 처리방침
              </td>
              <td
                className="right"
                onClick={(e) => {
                  setType("personal");
                  setModalLive(true);
                  e.stopPropagation();
                }}
              >
                보기
              </td>
            </tr>
            {/* <tr>
              <td
                onClick={() => {
                  setMAgreed(!mAgreed);
                }}
              >
                {mAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-checked-purple.png")}
                    style={{ height: "10px" }}
                  />
                )}
                {!mAgreed && (
                  <img
                    alt="..."
                    src={require("assets/img/checkmark-uncheck.png")}
                    style={{ height: "10px" }}
                  />
                )}
              </td>
              <td>(필수) 개인 민감정보(건강정보) 처리방침</td>
              <td
                className="right"
                onClick={(e) => {
                  setType("mingam");
                  setModalLive(true);
                  e.stopPropagation();
                }}
              >
                보기
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLiveLabel">
            {type === "service" && <div>서비스 이용 약관</div>}
            {type === "personal" && <div>개인정보 수집 약관</div>}
            {/* {type === "mingam" && <div>개인 민감정보(건강정보) 처리방침</div>} */}
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLive(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {type === "service" && <ServiceAgreement></ServiceAgreement>}
          {type === "personal" && <PersonalInfoPolicy></PersonalInfoPolicy>}
          {/* {type === "mingam" && <SensitivityAgreement></SensitivityAgreement>} */}
        </div>
        <div className="modal-footer">
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              width: "25vw",
            }}
            type="button"
            onClick={() => setModalLive(false)}
          >
            닫기
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default Agreement;
