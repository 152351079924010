import React, { useState, useEffect } from "react";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";

// images / animations
import Logo from "../dna-event-assets/MarsExogene.png";
import Three from "../dna-event-assets/HeaderImg.png";
import DNA from "../dna-event-assets/HeaderBg.png";
import Wavy from "../dna-event-assets/animations/DNAWavy.json";
import Highlight from "../dna-event-assets/animations/DNAHighlight.json";
import DNAStrand from "../dna-event-assets/animations/DNAStrand.json";

export default function DNAIntro() {
  // TODO :  SET POSITION OF CONTAINER RELATIVE, CHANGE HIGHLIGHT LOTTIE TO ABSOLUTE -> CONSISTENT LOCATION ACROSS DEVICES

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    padding: "50px 27px 0px 27px",
    height: "100%",
    position: "relative",
    overflowX: "hidden",
    background: '#F2FFCE'
  };

  const titleStyle = {
    fontWeight: "600",
    color: "#333D96",
    fontSize: "28px",
    marginTop: "3%",
    position: "relative",
    lineHeight: "40px",
    position: "relative",
    zIndex: "2",
  };

  const lastImgStyle = {
    width: "100vw",
    height: "auto",
    marginTop: "-20px",
  };

  const bgStyle = {
    position: "absolute",
    zIndex: 0,
    right: "0",
    top: "0",
  };

  const animationOptions = {
    loop: true,
    autoplay: true,
    // animationData: Wavy,
    animationData: Highlight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={containerStyles}>
      {/* <img src={DNA} style={bgStyle} /> */}
      <div
        onClick={() => {
          let res = window.localStorage.setItem("dna_event", "no");
        }}
      >
        <h1 style={titleStyle}>
          탈모 유전자검사 <br /> 홈키트 선착순 무료!
          <Lottie
            animationData={Highlight}
            style={{
              position: "absolute",
              top: "50px",
              left: "75px",
              zIndex: "-1",
              height: "auto",
              width: "150px",
            }}
          ></Lottie>
        </h1>
        <p style={{ fontWeight: 300, fontSize: "15px", marginTop: "-20px" }}>
          검사키트, 배송비, 검사비용 모두 무료 <br />
          2023. 02. 15. 단 하루
        </p>
      </div>
      <Lottie
        animationData={DNAStrand}
        style={{
          transform: "rotate(-25deg)",
          position: "absolute",
          pointerEvents: "none",
          top: "18%",
          height: "auto",
          width: "400px",
        }}
        autoPlay={true}
      ></Lottie>

      <img
        src={Logo}
        style={{
          width: "175px",
          height: "auto",
          position: "relative",
          marginTop: "auto",
          zIndex: 99,
        }}
      />
      <p style={{ marginBottom: "120px" }}>
        <span
          style={{
            textDecoration: "line-through",
            textDecorationColor: "#339087",
            textDecorationThickness: "3px",
            size: "18px",
          }}
        >
          정상기 52,000원
        </span>
        <span style={{ color: "#339087", fontWeight: "600", fontSize: "18px" }}>
          &nbsp;&nbsp; 선착순 무료
        </span>
      </p>
    </div>
  );
}
