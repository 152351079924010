import React, { useState, useEffect } from "react";
import PhotoChartCard from "./OperationCardNew";
import ShipmentCard from "./ShipmentCard";

// img
import searchIcon from "../pharma2/Vector.png";

// css
import "./styling/viewerAcceptStyles.css";
import { Pagination } from "@aws-amplify/ui-react";

function ChartViewer(props) {
  const { userStatus, shipments, status } = props;

  const [search, setSearch] = useState("");

  // client side search
  useEffect(() => {
    console.log("chartViewerShipments:  ", shipments);
  }, []);

  return (
    <div className="viewer-accept-container-venus">
      <div className="viewer-accept-header-venus">
        <div className="operation-search-venus">
          {/* {props.status === "pay" ? (
            <span style={{ width: "165px" }}>처방완료</span>
          ) : (
            <></>
          )}
          {props.p2status === "joje_ing" ? (
            <span style={{ width: "165px" }}>조제중</span>
          ) : (
            <></>
          )}
          {props.p2status === "joje_done" ? (
            <span style={{ width: "165px" }}>배송대기</span>
          ) : (
            <></>
          )}
          {props.p2status === "deli_done" ? (
            <span style={{ width: "165px" }}>배송완료</span>
          ) : (
            <></>
          )} */}
          <div>
            <img
              src={searchIcon}
              style={{
                width: "15px",
                position: "absolute",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              alt="search-icon-pharma"
            />
            <input
              type="text"
              value={search}
              placeholder="검색"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
        {/* <div className="viewer-accept-doctor-batch">
          <span style={{ fontWeight: 600 }}>오늘의 진료배치현황</span> &nbsp;
          {doctorBatch && (
            <>
              {`서울정의원: [${
                doctorBatch["doctor1"] || 0
              }건]  |  보령이가정의원: [${
                doctorBatch["doctor2"] || 0
              }건] |  가온삼성비뇨기과: [${
                doctorBatch["gaonsamsung"] || 0
              }건] | 총 [${totalBatch}건]`}
            </>
          )}
        </div> */}
      </div>
      <div className="operation-table-header-container-venus">
        <table className="operation-table-header-venus">
          <th id="op-name">이름 (연락처)</th>
          <th id="op-time">희망진료시간</th>
          <th id="op-ssn">주민번호</th>
          <th id="op-type">환자타입</th>
          <th id="op-hospital">병원</th>
          {status === "joje_ing" || status === "joje_done" ? (
            <th id="op-pharma">약국</th>
          ) : (
            <></>
          )}
        </table>
      </div>
      <div className="viewer-accept-content-container-venus">
        {shipments.map((item, key) => {
          if (item.processor_status && item.processor_status === status) {
            return <ShipmentCard key={key} shipment={item} />;
          }
        })}

        <div
          style={{
            paddingLeft: "15px",
            height: "auto",
            lineHeight: "18px",
            fontWeight: 200,
            letterSpacing: "3px",
            whiteSpace: "pre-wrap",
            fontFamily: "aria",
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
            marginTop: "50px",
          }}
        >
          <div>{"  __( o)>"}</div>
          <div>{" <_.  )"}</div>
          <div style={{ lineHeight: 0 }}>{"---------------"}</div>
        </div>
        <div>
          <Pagination currentPage={1} totalPages={10} siblingCount={1} />
        </div>
      </div>
    </div>
  );
}
export default ChartViewer;
