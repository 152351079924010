import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

import Nav from "./components/Nav";
import Header from "./components/Header";
import Feedback from "./components/Feedback";
import KeyNotes from "./components/KeyNotes";
import Footer from "./components/Footer";

// assets
import cell from "./assets/cell.json";
import calendar from "./assets/calendar.json";
import medStart from "./assets/medStart.png";
import medDuring from "./assets/medDuring.png";
import medAfter from "./assets/medAfter.png";
import arrow from "./assets/arrow.png";
import stepChart from "./assets/stepChart.png";

// css
import "./styling/containerStyling.css";
import { useHistory, useLocation } from "react-router";
import { slack2events } from "utils/util";

// care message pretotype container
// this is for shedding effect TODO: more reusability later
export default function CareMessageShedding() {
  // set || add title later
  const [navTitle, setNavTitle] = useState("CARE");
  const [title, setTitle] = useState("탈모약 복용 중 쉐딩현상");
  const [kid, setKid] = useState();
  const location = useLocation();
  const history = useHistory();
  const [reachedBot, setReachedBot] = useState(false);

  useEffect(() => {
    let path = window.location.pathname;
    path = path.substring(1);
    if (!location.kid) {
      history.push({
        pathname: "/klogin",
        // state: "survey2",
        state: path,
      });
    } else {
      // console.log("kid: " , location.kid);
      setKid(location.kid);
      slack2events(
        "[MARS Care™][" +
          location.kid +
          "] Care Message - Shedding 페이지 방문."
      );
    }
  }, []);

  // SCROLL TRACKING
  // const handleScroll = () => {
  //   const position = window.scrollY;
  //   var element = document.getElementById("talmo-article");
  //   var top = element.getBoundingClientRect().top + position;
  //   setScrollPosition(position);
  //   setTop(top);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scrollPosition]);

  const scrollEvent = (e) => {
    // var top =
    //   e.target.querySelector("#talmo-article").getBoundingClientRect().top +
    //   window.scrollY;
    // if (top < 0) {
    //   console.log("halfway point");
    // }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop + -5 <= e.target.clientHeight;
    if (bottom) {
      console.log("reached bottom");
      setReachedBot(true);
    } else {
      setReachedBot(false);
    }
  };

  useEffect(() => {
    if (reachedBot) {
      slack2events(
        "[MARS Care™][" + location.kid + "] Care Message - Shedding | Scrolled to bottom of page!"
      );
    }
  }, [reachedBot]);

  return (
    <div className="care-message-container" onScroll={scrollEvent}>
      <Nav title={navTitle}> </Nav>
      <Header title={title}></Header>
      <div className="article-container">
        <div className="article-title">
          탈모약을 처음 써봤는데 머리가 더 빠져요!
        </div>
        <div className="article-body">
          피나스테리드, 두타스테리드 성분 탈모약, 또는 바르는 미녹시딜을 처음
          사용하시는 분들 중 오히려 머리가 더 얇아지거나 빠져서 복용을 중단하는
          분들이 있습니다.
        </div>
        <div className="article-body">
          정말 탈모약이 맞지 않아서 그런 걸까요?
        </div>
      </div>
      <KeyNotes></KeyNotes>
      <div id="talmo-article" className="article-container">
        <div className="article-title">
          탈모약, 효과를 보려면 시간이 필요해요!
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "223px auto" }}>
          <div className="article-body">
            피나/두타 탈모약은 모근에 해로운 DHT 호르몬을 억제하여 탈모 진행을
            지연시킵니다. 10년 이상 탈모약을 복용한 99%가 탈모가 더 이상
            진행되지 않았다는 연구가 있을 정도로, 장기
          </div>
          <Lottie
            animationData={cell}
            autoPlay={true}
            style={{
              height: "150px",
              padding: 0,
              margin: 0,
              pointerEvents: "none",
            }}
          ></Lottie>
        </div>
        <div className="article-body" style={{ marginTop: "-2px" }}>
          적으로는 이 약들은 효과가 매우 좋습니다. 바르는 미녹시딜의 경우,
          모낭의 혈액 공급량을 증가하여, 모발이 자라는데 필요한 영양을 공급하여
          탈모 진행을 막아줘요.
        </div>
        <div className="article-body">
          하지만 아쉽게도 탈모약의 효과를 보려면 시간이 필요해요. 눈에 띄는
          효과를 보기 위해서는 3개월 정도 꾸준히 사용해야 하며, 최종 효과는 1년
          정도 이상 지속적으로 사용해야 합니다.
        </div>
        <div className="article-image-container">
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={medStart}
          />
          <motion.img
            initial={{ width: 0 }}
            whileInView={{ width: "35px" }}
            transition={{ duration: 0.5, delay: 0.5 }}
            id="article-arrow"
            src={arrow}
          />
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            src={medDuring}
          />
          <motion.img
            initial={{ width: 0 }}
            whileInView={{ width: "35px" }}
            transition={{ duration: 0.5, delay: 1.5 }}
            id="article-arrow"
            src={arrow}
          />
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 2 }}
            src={medAfter}
          />
        </div>
        <div className="article-body">
          처음 약을 사용하는 몇 개월 동안 많은 분들이 오히려 머리가 더 빠지는
          쉐딩현상을 경험해요.
        </div>
      </div>
      <div className="mars-divider-gray"></div>
      <div className="article-container">
        <div className="article-title">쉐딩현상, 왜 발생하나요? </div>
        <div className="article-body">
          탈모약 복용 시 왜 쉐딩효과가 생기는지 명확한 이유는 아직 모르나,
          모발주기와 관련되어 있는 것으로 알려져 있어요. 모발은 성장기(2-5년),
          퇴화기(1-1.5개월), 휴지기(4-5개월)의 주기를 반복해요.
        </div>
        <div className="article-image-container">
          <img src={stepChart} style={{ width: "100%", height: "auto" }} />
        </div>
        <div className="article-body">
          성장기에 모발이 자라다가, 퇴화기에 모구부가 축소되며, 휴지기에는
          모발이 두피에 머무르기만 하다가, 모발이 빠진 후 다시 성장기로 돌아와
          주기를 반복합니다.
        </div>
        <div className="article-body">
          탈모약 사용 시 휴지기에 있던 모발이 탈락하고, 성장기로 바뀌면서
          일시적으로 오히려 머리가 더 빠지게 되는데 이를 쉐딩현상이라고 합니다.
        </div>
        <div className="article-body">
          쉐딩현상 후에는 대부분의 머리가 성장기로 들어가게 되면서, 모발수는
          이전과 같더라도, 더 풍성해 보일 수 있어요!
        </div>
      </div>
      <div className="mars-divider-gray"></div>
      <div className="article-container">
        <div className="article-title">쉐딩현상, 얼마나 지속되나요?</div>
        <div style={{ display: "grid", gridTemplateColumns: "223px auto" }}>
          <div className="article-body">
            보통 처음 1-2주 차 때 나타나게 되고, 2-3개월 정도 진행될 수 있어요.
          </div>
          <Lottie
            animationData={calendar}
            autoPlay={true}
            style={{
              height: "115px",
              width: "auto",
              padding: 0,
              margin: "0px -80px -70px -60px",
              pointerEvents: "none",
            }}
          ></Lottie>
        </div>
        <div className="article-body">
          보통 3개월 이후부터 탈모약의 효과를 느끼실 수 있을 거에요. 그때까지는
          장기적 효과를 기다리며, “이것 또한 지나가리라”라는 마음으로 조금만
          기다려 주세요!
        </div>
        <div className="article-body">
          3개월 이후에도 쉐딩이 지속되거나, 효과가 미비하면 의사와 상담을 통해
          치료방법을 논의하세요!
        </div>
      </div>
      {/* <KeyNotes></KeyNotes> */}
      <Feedback article={"마스 쉐딩"} kid={kid}></Feedback>
      <Footer></Footer>
    </div>
  );
}
