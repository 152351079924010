// import amplify authenticator ui
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth, graphqlOperation } from "aws-amplify";
// core components

import {
  FaCrosshairs,
  FaDna,
  FaGem,
  FaHeart,
  FaHospital,
  FaMoneyBill,
  FaPhotoVideo,
  FaPrescriptionBottle,
  FaProductHunt,
  FaSearch,
  FaWineBottle,
} from "react-icons/fa";

import React, { useEffect, useState, useSyncExternalStore } from "react";

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  Alert,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { getAllCharts } from "utils/data";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import ChartViewer from "./ChartViewerNew";
import ChartViewerIng from "./ChartViewerIngNew";
import ChartViewerPhoneNew from "./ChartViewerPhoneNew";
import ChartViewerPaidNew from "./ChartViewerPaidNew";
import { getAllExogenes } from "utils/data";
import ExogeneViewer from "./ExogeneViewer";
import NeshViewer from "./NeshViewer";
import ChartViewerCancel from "./ChartViewerCancelNew";
import { getAllChartsWithUserInfo } from "utils/data";
import { getAllNeshesWithUserInfo } from "utils/data";
import { getAllExogenesWithUserInfo } from "utils/data";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import PhotoChartViewer from "./PhotoChartViewerNew";
import JungsanViewer from "./JungsanViewer";

import "@aws-amplify/ui-react/styles.css";
import { getUser } from "utils/data";
import ChartViewerAccept from "./ChartViewerAcceptNew";

// img
import logo from "../../assets/img/mars_logo_black.png";
import box from "./operation-assets/box.png";
import dollar from "./operation-assets/dollar.png";
import heart from "./operation-assets/heart.png";
import microscope from "./operation-assets/microscope.png";
import rocket from "./operation-assets/rocket.png";
import search from "./operation-assets/search.png";
import shampoo from "./operation-assets/shampoo.png";

// css
import "./styling/operationMainStyles.css";
import { getAllNeshes } from "utils/data";
import ChartViewerDeliDone from "./ChartViewerDeliDone";
import { getAllUsersWithName } from "utils/data";

function OperationPage() {
  const opPath = "operation";
  const [iconPills, setIconPills] = useState("1");
  const [users, setUsers] = useState([]);
  const [patients, setPatients] = useState([]);
  const [charts, setCharts] = useState([]);
  const [allCharts, setAllCharts] = useState([]);
  const [isUsersUpdated, setIsUsersUpdated] = useState(false);
  const [isChartsUpdated, setIsChartsUpdated] = useState(false);
  const [neshes, setNeshes] = useState([]);
  const [exogenes, setExogenes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pName, setPName] = useState("");
  const [acceptAlert, setAcceptAlert] = useState(false);
  const [when, setWhen] = useState("1");
  const history = useHistory();

  const updateChart = (c) => {
    // charts.id
    // console.log("c: ", c);
    // console.log("charts: ", charts);
    // let chartsCopy = [...charts];
    // console.log("chartscopy: ", chartsCopy);

    // let obj = chartsCopy.find(o => o.id === c.id);
    // console.log("found update obj: ", obj);
    setCharts((cs) => {
      const chartsCopy = [...cs];
      // let obj = chartsCopy.find(o => o.id === c.id);
      let obj = chartsCopy.find((o, i) => {
        if (o.id === c.id) {
          chartsCopy[i] = c;
          return true; // stop searching
        }
      });
      return chartsCopy;
    });
  };
  const numCalc = (arr, attr, status) => {
    let count = 0;
    for (let a of arr) {
      if (a[attr] === status) {
        count++;
      }
    }
    return count;
  };

  const updateLocalPatient = (patient) => {
    // console.log("inside update local patient: ", patient);
    setCharts((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...patient,
            name: obj.name,
            ssn: obj.ssn,
            phone: obj.phone,
            addr: obj.addr,
            addr2: obj.addr2,
            zip: obj.zip,
            // ...obj,
            // status: patient.status,
            // dstatus: patient.dstatus,
            // pstatus: patient.pstatus
          };
        }
        return obj;
      })
    );

    setPatients((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...patient,
            name: obj.name,
            ssn: obj.ssn,
            phone: obj.phone,
            addr: obj.addr,
            addr2: obj.addr2,
            zip: obj.zip,
            // ...obj,
            // status: patient.status,
            // dstatus: patient.dstatus,
            // pstatus: patient.pstatus
          };
        }
        return obj;
      })
    );
  };

  const insertLocalPatient = async (patient) => {
    let user = await getUser(`user#${patient.kid}`);
    if (user) {
      patient.name = user.name;
      patient.ssn = user.ssn;
      patient.phone = user.phone;
      patient.addr = user.addr;
      patient.addr2 = user.addr2;
      patient.zip = user.zip;
    }

    setCharts((current) => [patient, ...current]);
  };

  async function getData() {
    setIsLoading(true);
    // let cs = await getAllChartsWithUserInfo("DESC", null, 999);
    let cs = await getAllCharts("DESC", null, 999);
    cs.sort((a, b) => new Date(a.yeyak_time) - new Date(b.yeyak_time));

    setCharts(cs);

    let ps = [...cs];
    ps.sort((a, b) => new Date(b.yeyak_time) - new Date(a.yeyak_time));
    setPatients(ps);

    setIsChartsUpdated(true);
    setIsLoading(false);
  }

  async function getShampCustomerData() {
    // let neshs = await getAllNeshesWithUserInfo("DESC");
    let neshs = await getAllNeshes("DESC");
    setNeshes(neshs);
  }

  async function getExogeneData() {
    // let exos = await getAllExogenesWithUserInfo("DESC");
    let exos = await getAllExogenes("DESC");

    setExogenes(exos);
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      // fetch data
      let user = await Auth.currentUserInfo();
      // console.log("user:", user);
      if (user.username !== "mars1") {
        alert("오퍼레이터로 로그인 해주세요!");
        let so = await Auth.signOut();
        return;
      }

      // subscription for onCreateMars
      //
      const subscription = API.graphql({
        query: subscriptions.onCreateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let mars = value.data.onCreateMars;
          if (mars.type === "chart") {
            // getData();
            insertLocalPatient(mars);
          }
          if (mars.type === "nesh") {
            getShampCustomerData();
          }
          if (mars.type === "exogene") {
            getExogeneData();
          }

          // console.log({ provider, value });
        },
        error: (error) => console.warn(error),
      });

      // subscription for onUpdateMars
      //
      const onupdateSubscription = API.graphql({
        query: subscriptions.onUpdateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          // console.log("onupdate mars!!: ", provider, value);
          let mars = value.data.onUpdateMars;
          if (mars) {
            updateLocalPatient(mars);
          }
          // if (mars.type === "chart") {
          //   if (mars.dstatus === "phone") { //ignore dstatus phone
          //     return;
          //   }
          //   if (mars.r === "n") {
          //     // find mars and update
          //     updateChart(mars);
          //     return;
          //   }
          //   getData();
          // }

          if (mars.type === "nesh") {
            getShampCustomerData();
          }
          if (mars.type === "exogene") {
            getExogeneData();
          }
        },
        error: (error) => console.warn(error),
      });

      // subscription for onDeletePatient
      //
      const onDeleteSubscription = API.graphql({
        query: subscriptions.onDeleteMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let mars = value.data.onDeleteMars;
          if (mars.type === "chart") {
            getData();
          }
          if (mars.type === "nesh") {
            getShampCustomerData();
          }
          if (mars.type === "exogene") {
            getExogeneData();
          }
        },
        error: (error) => console.warn(error),
      });

      getData();
      getShampCustomerData();
      getExogeneData();

      return () => {
        subscription.unsubscribe();
        onupdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      };
    };
    asyncWrapper();

    // clean-up unsubscribe
    //
  }, []);

  // get the all charts from background.
  useEffect(() => {
    const asyncWrapper = async () => {
      let data = await getAllChartsWithUserInfo("DESC", null, 1000);
      setAllCharts(data);
    };
    asyncWrapper();
  }, []);
  const insertUserInfo2Charts = async () => {
    if (charts.length < 1) {
      return;
    }

    let userHash = {};
    for (let user of users) {
      userHash[user.kid] = user;
    }

    // poppulate chart with user info.
    let newCharts = [...charts];

    for (let c of newCharts) {
      let user = userHash[c.kid];

      if (user && user.name) {
        c.name = user.name;
        c.phone = user.phone;
        c.ssn = user.ssn;
        c.addr = user.addr;
        c.addr2 = user.addr2;
        c.zip = user.zip;
      }
    }

    setCharts(newCharts);
  };

  useEffect(() => {
    // console.log("is update user info1!!!");
    if (isChartsUpdated && isUsersUpdated) {
      insertUserInfo2Charts();
    }
  }, [isChartsUpdated, isUsersUpdated]);

  useEffect(() => {
    // update user data
    // console.log("updating user data");
    const asyncWrapper = async () => {
      const us = await getAllUsersWithName();

      setUsers(us);
      setIsUsersUpdated(true);
    };
    asyncWrapper();
  }, []);

  const handleUpdate = (type, body) => {
    if (type === "when") {
      setWhen(body);
    }
  };

  const handleDeliveryReady = async (pid, deliveryNumber) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_done",
      status: "deli_done",
      ignoreStatus: "no",
      delivery_number: deliveryNumber,
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  const handleMedicationReady = async (pid, pname) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_prep",
      ignoreStatus: "no",
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  /* 결제확인 완료 택배예약 완료 버튼 핸들 */
  const handleVerifyPayment = async (pid, pname) => {
    // graphql update patient dstatus
    //
    const patientDetails = {
      id: pid,
      pstatus: "medi_prep",
      ignoreStatus: "no",
      r: "n",
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  // Amplify signout
  const signOut = async () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  };

  return (
    <div className="operation-main-container">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <MarsLoaderBasic isLoading={isLoading} />{" "}
        </div>
      )}
      <div id="operation-navbar">
        <ProSidebar>
          <a
            id="op-logo"
            onClick={() => {
              console.log("logo click");
            }}
          >
            <img src={logo} />
          </a>
          <SidebarContent>
            <Menu iconShape="round">
              <SubMenu
                defaultOpen={true}
                title={`진료현황 [${
                  numCalc(allCharts, "status", "pending") +
                  numCalc(allCharts, "dstatus", "phone") +
                  numCalc(allCharts, "dstatus", "accept") +
                  numCalc(allCharts, "dstatus", "cancel") +
                  numCalc(allCharts, "status", "pay")
                }]`}
                icon={<img src={heart} id="op-menu-icon" />}
                id="op-submenu"
              >
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-pending`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-pending` && (
                    <div id="op-square"></div>
                  )}
                  배정대기 [{numCalc(charts, "status", "pending")}]
                  <Link to={`/${opPath}/chart-pending`} />
                </MenuItem>
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-accept`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-accept` && (
                    <div id="op-square"></div>
                  )}
                  접수대기 [{numCalc(charts, "status", "accept")}
                  ]
                  <Link to={`/${opPath}/chart-accept`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-phone`}
                >
                  {window.location.pathname === `/${opPath}/chart-phone` && (
                    <div id="op-square"></div>
                  )}
                  통화진료중 [{numCalc(charts, "dstatus", "phone")}
                  ]
                  <Link to={`/${opPath}/chart-phone`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-pay`}
                >
                  {window.location.pathname === `/${opPath}/chart-pay` && (
                    <div id="op-square"></div>
                  )}
                  처방완료 [{numCalc(allCharts, "status", "pay")}]
                  <Link to={`/${opPath}/chart-pay`} />
                </MenuItem>

                {/* Brought this from 조제현황 -> might not be the same cancel status */}
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-cancel`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-cancel` && (
                    <div id="op-square"></div>
                  )}
                  취소 [{numCalc(allCharts, "dstatus", "cancel")}]
                  <Link to={`/${opPath}/chart-cancel`} />
                </MenuItem>
              </SubMenu>
              <SubMenu
                defaultOpen={true}
                title={`조제현황 [${
                  numCalc(allCharts, "p2status", "joje_ing") +
                  numCalc(allCharts, "p2status", "joje_done") +
                  numCalc(allCharts, "status", "deli_done") +
                  numCalc(allCharts, "p2status", "joje_ready")
                }]`}
                icon={<img src={box} id="op-menu-icon" />}
                id="op-submenu"
              >
                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-paid`}
                >
                  {window.location.pathname === `/${opPath}/chart-paid` && (
                    <div id="op-square"></div>
                  )}
                  결제완료 [{numCalc(allCharts, "p2status", "joje_ready")}]
                  <Link to={`/${opPath}/chart-paid`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-medi-prep`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-medi-prep` && <div id="op-square"></div>}
                  조제중 [{numCalc(allCharts, "p2status", "joje_ing")}]
                  <Link to={`/${opPath}/chart-medi-prep`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-deli-prep`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-deli-prep` && <div id="op-square"></div>}
                  배송대기 [{numCalc(allCharts, "p2status", "joje_done")}]
                  <Link to={`/${opPath}/chart-deli-prep`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-deli-done`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-deli-done` && <div id="op-square"></div>}
                  {/* DELIVERY_DONE - 약배송 완료 */}
                  배송완료 [{numCalc(allCharts, "status", "deli_done")}]
                  <Link to={`/${opPath}/chart-deli-done`} />
                </MenuItem>
              </SubMenu>

              <SubMenu
                defaultOpen={false}
                title={`nesh 샴푸 [${neshes.length}]`}
                icon={
                  <img
                    src={shampoo}
                    id="op-menu-icon"
                    style={{ width: "12px" }}
                  />
                }
                id="op-submenu"
              >
                <MenuItem id="op-menu-item">
                  {window.location.pathname === `/${opPath}/shamp_paid` && (
                    <div id="op-square"></div>
                  )}
                  결제완료 [{numCalc(neshes, "status", "shamp_paid")}]
                  <Link to={`/${opPath}/shamp_paid`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_order_ready` && (
                    <div id="op-square"></div>
                  )}
                  배송신청 [{numCalc(neshes, "status", "shamp_order_ready")}]
                  <Link to={`/${opPath}/shamp_order_ready`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_order_done` && <div id="op-square"></div>}
                  운송장 입력 [{numCalc(neshes, "status", "shamp_order_done")}]
                  <Link to={`/${opPath}/shamp_order_done`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_deli_done` && <div id="op-square"></div>}
                  배송완료 [{numCalc(neshes, "status", "shamp_deli_done")}]
                  <Link to={`/${opPath}/shamp_deli_done`} />
                </MenuItem>
              </SubMenu>

              <SubMenu
                defaultOpen={false}
                title={`ExoGene [${exogenes.length}]`}
                icon={
                  <img
                    src={microscope}
                    id="op-menu-icon"
                    style={{ width: "16px" }}
                  />
                }
                id="op-submenu"
              >
                <MenuItem id="op-menu-item">
                  결제완료 [{numCalc(exogenes, "status", "accept")}]
                  <Link to={`/${opPath}/exogene-accept`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  키트 배송완료 [{numCalc(exogenes, "status", "kit_sent")}]
                  <Link to={`/${opPath}/exogene-kit-sent`} />
                </MenuItem>
              </SubMenu>

              <MenuItem
                id="op-menu-item"
                icon={<img src={search} id="op-menu-icon" />}
              >
                고객 검색 [{users.length}]
              </MenuItem>

              <MenuItem id="op-menu-item" icon={<FaPhotoVideo />}>
                사진검색 []
                <Link to={`/${opPath}/photo-chart`} />
              </MenuItem>

              <MenuItem id="op-menu-item" icon={<FaMoneyBill />}>
                자동정산 []
                <Link to={`/${opPath}/jungsan`} />
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter></SidebarFooter>
          {/* currently for testing purposes */}
          <button
            id="operation-logout-button"
            onClick={() => {
              signOut();
            }}
          >
            🦆
          </button>
        </ProSidebar>
      </div>
      <div>
        <Switch>
          {/* chart routes */}
          <Route exact path={`/${opPath}`}>
            <ChartViewerAccept
              charts={charts}
              allCharts={allCharts}
              status={"pending"}
            />
          </Route>

          <Route path={`/${opPath}/chart-pending`}>
            <ChartViewerAccept
              charts={charts}
              allCharts={allCharts}
              status={"pending"}
            />
          </Route>

          <Route path={`/${opPath}/chart-accept`}>
            <ChartViewerIng charts={charts} status={"accept"} />
          </Route>

          <Route path={`/${opPath}/chart-phone`}>
            <ChartViewerPhoneNew charts={charts} dstatus={"phone"} />
          </Route>

          <Route path={`/${opPath}/chart-pay`}>
            <ChartViewer charts={patients} status={"pay"} />
          </Route>

          <Route path={`/${opPath}/chart-paid`}>
            {/* <ChartViewerPaidNew charts={patients} status={"paid"} /> */}
            <ChartViewerPaidNew charts={patients} p2status={"joje_ready"} />
          </Route>

          <Route path={`/${opPath}/chart-medi-prep`}>
            <ChartViewer charts={patients} p2status={"joje_ing"} />
          </Route>

          <Route path={`/${opPath}/chart-deli-prep`}>
            <ChartViewer charts={patients} p2status={"joje_done"} />
          </Route>

          <Route path={`/${opPath}/chart-cancel`}>
            <ChartViewerCancel charts={patients} dstatus={"cancel"} />
          </Route>

          <Route path={`/${opPath}/chart-deli-done`}>
            <ChartViewerDeliDone allCharts={allCharts} />
          </Route>

          {/* exogene routes */}
          <Route path={`/${opPath}/exogene-accept`}>
            <ExogeneViewer exogenes={exogenes} status={"accept"} />
          </Route>

          <Route path={`/${opPath}/exogene-kit-sent`}>
            <ExogeneViewer exogenes={exogenes} status={"kit_sent"} />
          </Route>

          {/* Nesh routes */}
          <Route path={`/${opPath}/shamp_paid`}>
            <NeshViewer neshes={neshes} status={"shamp_paid"} />
          </Route>

          <Route path={`/${opPath}/shamp_order_ready`}>
            <NeshViewer neshes={neshes} status={"shamp_order_ready"} />
          </Route>

          <Route path={`/${opPath}/shamp_order_done`}>
            <NeshViewer neshes={neshes} status={"shamp_order_done"} />
          </Route>

          <Route path={`/${opPath}/shamp_deli_done`}>
            <NeshViewer neshes={neshes} status={"shamp_deli_done"} />
          </Route>

          <Route path={`/${opPath}/photo-chart`}>
            <PhotoChartViewer charts={charts} />
          </Route>

          <Route path={`/${opPath}/jungsan`}>
            <JungsanViewer charts={allCharts} />
          </Route>
        </Switch>
      </div>

      {/* <DarkFooter /> */}
    </div>
  );
}

export default withAuthenticator(OperationPage);
