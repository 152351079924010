import React from "react";
import { useHistory } from "react-router";

import UserInfo from "./UserInfo";

// css
import "../styling/componentStyling.css";

export default function InfoTab(props) {
  // pass props as array preferably - plain text for display purposes only
  const { title, displayData, type, userID, kid } = props;
  const history = useHistory();

  const handleRedirect = (type) => {
    if (type === "user")
      history.push({
        pathname: "/user_info",
        state: {
          userInfo: displayData,
          userID: userID,
          kid: kid,
        },
      });
    if (type === "addr")
      history.push({
        pathname: "/user_address",
        state: { addressInfo: displayData, userID: userID, kid: kid },
      });
    if (type === "card")
      history.push({
        pathname: "/user_card",
        state: { cardInfo: displayData, userID: userID, kid: kid },
      });

    if (type === "history")
      history.push({
        pathname: "/user_history",
        state: { cardInfo: null, userID: userID, kid: kid },
      });

    if (type === "photos")
      history.push({
        pathname: "/user_photos",
        state: { cardInfo: null, userID: userID, kid: kid },
      });

    return;
  };


  return (
    <div
      className="profile-info-container"
      onClick={() => {
        handleRedirect(type);
      }}
    >
      <div className="profile-info-title">{title}</div>
      <div className="profile-redirect">
        <span
          className="arrow right"
          style={{ transform: "rotate(-45deg)", marginTop: "8px" }}
        ></span>
      </div>
      <div className="profile-data-display">
        {displayData
          ? displayData.map((item, key) => {
              return <div key={key}>{item}</div>;
            })
          : ""}
      </div>
    </div>
  );
}
