import React, { useState, useEffect } from "react";
import MarsHeader from "../../components/Navbars/MarsNavbarWithLine";

// css
import "./feedbackStyles.css";

export default function FeedbackExpire() {
  const handleClick = () => {
    window.location.href = "kakaotalk://inappbrowser/close";
  };

  return (
    <div className="scrollable">
      <MarsHeader />
      <div className="feedback-expiration-container">
        <section id="feedback-emoji-section"> 😢</section>
        <h2>
          죄송합니다
          <br /> 3일의 기한이 지났어요..
        </h2>
        <p>
          저희 MARS 팀에게 공유해 주실 의견이 있으시면 카카오 채널 하단의
          ‘상담직원 연결하기'를 눌러 문의주세요! 감사합니다🙂
        </p>
      </div>
      <div className="feedback-button-container">
        <button id="feedback-submit-button" onClick={handleClick}>
          닫기
        </button>
      </div>
    </div>
  );
}
