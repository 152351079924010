import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendAnalytics } from "utils/analytics";
import { slack } from "utils/util";
import SurveyBtnSm from "../components/SurveyBtnSm";
import SurveyNextBtn from "../components/SurveyNextBtn";
import {
  loadAnswerFromLocalStorage,
  saveAnswerToLocalStorage,
} from "../utils/LocalStorage";

function FVQWhenStart(props) {
  const history = useHistory();
  const qnum = 1;
  const qid = "fv_when_start";
  const q = "언제부터 탈모가 시작되었나요?";
  const [answer, setAnswer] = useState({
    qid: qid,
    q: q,
    qnum: qnum,
    ans: [""],
    btnState: [false, false, false, false, false, false],
  });
  const [canGoNext, setCanGoNext] = useState(false);

  useEffect(() => {
    document.title = q;
    // var dataLayer = window.dataLayer = window.dataLayer || [];
    // dataLayer.push({
    // 	'event': 'pageview',
    // 	'page_location': window.location.pathname,
    // 	'page_title': q
    // });

    sendAnalytics({
      type: "log",
      survey_version: props.surveyVersion,
      kid: props.kid,
      from: qid,
      event: `visit.${qid}`,
      msg: `${q} 방문`,
    });

    // load saved answers and populate
    let ans = loadAnswerFromLocalStorage(qid);
    if (ans) {
      setAnswer(ans);
      setCanGoNext(true);
    }
  }, []);

  const saveAns = (ans, status) => {
    let answer = { qid, q, qnum, ans: [ans], btnState: status };
    setAnswer(answer);
    saveAnswerToLocalStorage(qid, answer);
    setCanGoNext(true);
  };

  return (
    <>
      {/* questions */}
      <div className="question-section">
        <div className="question-title">{q}</div>
        {/* <div className="question-explain">탈모 관련 처음 진료하시는군요. 간단하게 탈모와 관련 된 질문 몇 가지 드릴게요!</div> */}
        {/* <div className="question-explain-orange">아래 가격은 1개월 처방 기준이에요. 3개월 처방시 할인 되요.</div> */}
      </div>

      {/* answers */}
      <div className="answers-section">
        <SurveyBtnSm
          selected={answer.btnState[0]}
          onClick={() => {
            saveAns("한달 이내", [true, false, false]);
          }}
        >
          1개월 이내
        </SurveyBtnSm>

        <SurveyBtnSm
          selected={answer.btnState[1]}
          onClick={() => {
            saveAns("1-6개월 이전", [false, true, false]);
          }}
        >
          1-6개월
        </SurveyBtnSm>

        <SurveyBtnSm
          selected={answer.btnState[2]}
          onClick={() => {
            saveAns("6개월 이상 전", [false, false, true]);
          }}
        >
          6개월 이상
        </SurveyBtnSm>
      </div>

      {/* next button */}
      <div>
        <SurveyNextBtn
          disabled={!canGoNext}
          onClick={() => {
            // gtag analytics
            // let detail = {'kid': props.kid, 'survey_version': props.surveyVersion, 'ans': answer.ans[0] };
            // window.gtag('event', 'click_next_' + qid, detail);

            sendAnalytics({
              type: "log",
              survey_version: props.surveyVersion,
              kid: props.kid,
              from: qid,
              event: `click.next.${qid}`,
              ans: answer.ans[0],
              msg: `${answer.ans[0]} 선택후 다음클릭`,
            });

            slack("[survey1.4][" + props.kid + "] " + answer.ans[0] + " 선택");
            props.changeProgress(3, 11);
            // history.push("/survey2/fv-other-care");
            history.push(
              `/${window.location.pathname.split("/")[1]}/fv-other-care`
            );
          }}
        >
          다음
        </SurveyNextBtn>
      </div>
    </>
  );
}
export default FVQWhenStart;
