/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "marsrest",
            "endpoint": "https://7fbtlwibs3.execute-api.ap-northeast-2.amazonaws.com/staging",
            "region": "ap-northeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://esyy757cprgtxen2d3itqe6kxi.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-z4iugdvboffuvhaa2fdyfym354",
    "aws_cognito_identity_pool_id": "ap-northeast-2:9f0d114d-a8b3-425d-a49e-09776dd39eea",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_PQiXWHX7y",
    "aws_user_pools_web_client_id": "3nb1qjcvurmarcfs0921gvfbhd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "marsstorage55829-staging",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
