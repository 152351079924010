import { API } from "aws-amplify";
import axios from "axios";
import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useHistory, useLocation } from "react-router";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import { updateUser } from "utils/data";
import { getUser } from "utils/data";
import { slack } from "utils/util";
import Agreement from "views/components/Agreement";
import StatusButton2 from "views/status/StatusButton2";

import * as mutations from "../../graphql/mutations";
import logo from "../../assets/img/mars_logo_black.png";

// css
import "./styling/exogeneStyles.css";
import PaymentModule from "views/payments/PaymentModule";
import { slack2accept } from "utils/util";
import { nanoid } from "nanoid";
import { pushMarsAnalytics } from "utils/analytics";

function ExogeneReservation2(props) {
  const [openAddress, setOpenAddress] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [roadAddress, setRoadAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [pid, setPid] = useState("");
  const [kid, setKid] = useState("");
  const [payResult, setPayResult] = useState("fail");
  const [user, setUser] = useState();

  const [isAgreed, setIsAgreed] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handlePaymentResult = async (res) => {
    await pushMarsAnalytics(location.kid, 'submit.exogene-reservation', { from: "/exogene-reservation", msg: "DNA검사 신청완료" });
    try {
      // populate exogene data
      await createExogenePatient(res);
      window.alert(res.PCD_PAY_MSG);  
      history.push("/exogene-submit");
    } catch (error) {
      slack2accept(`[exogene-error] ${JSON.stringify(error)}`);
    }
  };

  const paymentModuleProps = {
    paymentFrom: "dna",
    paymentTotal: 26000,
    user: user,
    kid: kid,
    pid: pid,
    name: name,
    phone: phone,
    email: email,
    roadAddress: roadAddress,
    detailAddress: detailAddress,
    zoneCode: zoneCode,
    handlePaymentResult: handlePaymentResult,
  };

  const selectAgreement = (ans) => {
    setIsAgreed(ans);
  };

  const isValid = () => {
    if (name === "") {
      alert("이름을 입력해 주세요.");
      setCanGoNext(false);
      return false;
    }
    if (phone.length < 11) {
      alert("전화번호를 입력해 주세요.");
      setCanGoNext(false);
      return false;
    }
    if (email.length < 5) {
      alert("이메일 주소를 입력해 주세요.");
      setCanGoNext(false);
      return false;
    }
    if (!isAgreed) {
      alert("서비스 이용약관에 동의해 주세요.");
      setCanGoNext(false);
      return false;
    }
    if (!roadAddress) {
      alert("주소를 입력해 주세요.");
      setCanGoNext(false);
      return false;
    }
    return true;
  };

  useEffect(() => {
    //get pid, kid, payResult
    let path = window.location.pathname;
    path = path.substring(1);

    if (!location.kid) {
      history.push({
        pathname: "/klogin",
        // state: "survey2",
        state: path,
      });
    } else {
      // console.log("kid: " , location.kid);
      setKid(location.kid);
      slack("[Exogene][" + location.kid + "] Exogene Reservation 페이지 방문.");
    }
    const asyncWrapper = async () => {
      await pushMarsAnalytics(location.kid, 'visit.exogene-reservation', { from: "/exogene-reservation", msg: "DNA검사 신청페이지 방문" });

      const user = await getUser(`user#${location.kid}`);
      // console.log(user);
      if (user) {
        setName(user.name);
        setPhone(user.phone);
        setRoadAddress(user.addr);
        setDetailAddress(user.addr2);
        setZoneCode(user.zip);
        setEmail(user.email);
      }
      setUser(user);
    };
    asyncWrapper();
  }, []);

  const getResult = async (res) => {
    if (res.PCD_PAY_RST === "success") {
      let payResult = {
        // 'PCD_PAY_MSG': res.PCD_PAY_MSG,
        AMNT: res.PCD_PAY_AMOUNT,
        CNAME: res.PCD_PAY_CARDNAME,
        CNUM: res.PCD_PAY_CARDNUM,
      };

      // update user info
      let userInfo = {
        id: `user#${location.kid}`,
        name: name,
        addr: roadAddress,
        addr2: detailAddress,
        zip: zoneCode,
        phone: phone,
        email: email,
      };
      let user = await updateUser(userInfo);

      await createExogenePatient(res);
      

      window.alert(res.PCD_PAY_MSG);
      history.push("/exogene-submit");
    } else {
      // 결제 실패일 경우 알림 메시지
      window.alert(res.PCD_PAY_MSG + " 결제를 다시 시도해 주세요.");
      history.push({
        pathname: "/exogene-reservation",
      });
    }
  };

  const createExogenePatient = async (res) => {
    const exoDetails = {
      id: `dna#${location.kid}#${nanoid(5)}`,
      type: "exogene",
      name: name,
      phone: phone,
      email: email,
      status: "accept",
      isPaid: "yes",
      addr: roadAddress,
      addr2: detailAddress,
      zip: zoneCode,
      kid: kid,
      total: 26000, //todo: for now hardcoding dna test price.
      payResult: JSON.stringify(res),
    };

    try {
      const newExogene = await API.graphql({
        query: mutations.createMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: exoDetails },
      });
      if (newExogene) {
        // console.log(newExogene);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="scrollable"
        style={{ overflowX: "hidden", background: "white" }}
      >
        {/* <MarsNavbarWithLine /> */}
        <div className="exogene-header">
          <img src={logo} />
        </div>

        {openAddress && (
          <div className="address-popup-container">
            <DaumPostcode
              style={{
                paddingTop: "50px",
                height: "100vh",
              }}
              onComplete={(data) => {
                let fullAddress = data.address;
                let extraAddress = "";

                if (data.addressType === "R") {
                  if (data.bname !== "") {
                    extraAddress += data.bname;
                  }
                  if (data.buildingName !== "") {
                    extraAddress +=
                      extraAddress !== ""
                        ? `, ${data.buildingName}`
                        : data.buildingName;
                  }
                  fullAddress +=
                    extraAddress !== "" ? ` (${extraAddress})` : "";
                }
                setRoadAddress(fullAddress);
                setZoneCode(data.zonecode);
                setOpenAddress(false);
              }}
            />
            <span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenAddress(false);
                }}
              >
                닫기
              </button>
            </span>
          </div>
        )}

        {!openAddress && (
          <Container
            style={{
              background: "white",
              padding: "0px 26px 0px 26px",
            }}
          >
            <div className="exogene-form-container">
              <div className="exogene-title" style={{ fontSize: "18px" }}>
                {/* <b className="purple-bold">유전자검사 키트</b> 받으실 배송정보를{" "}
                <br />
                입력해 주세요 */}
                배송지 입력
              </div>
              <br />
              <FormGroup>
                <span
                  id="exogene-placeholder"
                  style={{
                    marginTop: name && name.length >= 1 ? "0px" : "20px",
                  }}
                >
                  이름
                </span>
                <input
                  value={name}
                  // placeholder="이름"
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                ></input>
              </FormGroup>

              <FormGroup>
                <span
                  id="exogene-placeholder"
                  style={{
                    marginTop: phone && phone.length >= 1 ? "0px" : "20px",
                  }}
                >
                  연락처
                </span>
                <input
                  style={{ backgroundColor: "white" }}
                  value={phone}
                  // placeholder="연락처 ' - '없이 숫자만 입력"
                  maxLength={11}
                  type="tel"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                ></input>
              </FormGroup>

              <FormGroup>
                <span
                  id="exogene-placeholder"
                  style={{
                    marginTop: email && email.length >= 1 ? "0px" : "20px",
                  }}
                >
                  DNA 결과지 받으실 이메일 주소 (필수)
                </span>
                <input
                  style={{ backgroundColor: "white" }}
                  value={email}
                  // placeholder="이메일 (결과 리포트를 보내드립니다)"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
              </FormGroup>
              <div style={{ display: "flex" }}>
                <FormGroup
                  style={{ width: 120, float: "left", borderRadius: "0%" }}
                >
                  <span
                    id="exogene-placeholder"
                    style={{
                      marginTop:
                        zoneCode && zoneCode.length >= 1 ? "0px" : "20px",
                    }}
                  >
                    우편번호
                  </span>
                  <input
                    style={{ pointerEvents: "none" }}
                    // placeholder="우편번호"
                    type="text"
                    value={zoneCode}
                    onChange={(e) => {
                      // setPhone(e.target.value);
                    }}
                    unselectable={true}
                    onClick={() => {
                      setOpenAddress(true);
                    }}
                  ></input>
                </FormGroup>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAddress(true);
                  }}
                  id="exogene-address"
                >
                  우편번호 검색
                </button>
              </div>
              <FormGroup>
                <span
                  id="exogene-placeholder"
                  style={{
                    marginTop:
                      roadAddress && roadAddress.length >= 1 ? "0px" : "20px",
                  }}
                >
                  주소
                </span>
                <input
                  style={{ backgroundColor: "white" }}
                  // placeholder="주소"
                  // type="text"
                  type="text"
                  onChange={(e) => {
                    // setPhone(e.target.value);
                  }}
                  onClick={() => {
                    setOpenAddress(true);
                  }}
                  value={roadAddress}
                ></input>
              </FormGroup>

              <FormGroup>
                <span
                  id="exogene-placeholder"
                  style={{
                    marginTop:
                      detailAddress && detailAddress.length >= 1
                        ? "0px"
                        : "20px",
                  }}
                >
                  상세주소
                </span>
                <input
                  value={detailAddress}
                  // placeholder="상세주소: 예)304동 1104호"
                  type="text"
                  onChange={(e) => {
                    setDetailAddress(e.target.value);
                  }}
                ></input>
              </FormGroup>

              <div
                className="mars-divider-gray"
                style={{ marginLeft: "-26px" }}
              ></div>
            </div>

            {!openAddress && (
              <PaymentModule props={paymentModuleProps} />
            )}
          </Container>
        )}

        <div style={{ margin: "50px 26px 0px 26px", paddingBottom: "120px" }}>
          <Agreement selectAgreement={selectAgreement} from={"DNA"} />
        </div>
      </div>
    </>
  );
}

export default ExogeneReservation2;
