/**
 * @internal
 */
export var BLOCK_SIZE = 64;
/**
 * @internal
 */
export var DIGEST_LENGTH = 16;
/**
 * @internal
 */
export var INIT = [0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476];
