import { __awaiter, __generator } from "tslib";
export function createRequest(client, command) {
    return __awaiter(this, void 0, void 0, function () {
        var interceptMiddleware, clientStack, handler;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    interceptMiddleware = function (next) { return function (args) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, { output: { request: args.request }, response: undefined }];
                        });
                    }); }; };
                    clientStack = client.middlewareStack.clone();
                    // @ts-ignore: add middleware to the last of 'build' step
                    clientStack.add(interceptMiddleware, {
                        step: "build",
                        priority: "low",
                    });
                    handler = command.resolveMiddleware(clientStack, client.config, undefined);
                    return [4 /*yield*/, handler(command).then(function (output) { return output.output.request; })];
                case 1: 
                // @ts-ignore
                return [2 /*return*/, _a.sent()];
            }
        });
    });
}
