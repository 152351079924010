import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations"

// import { collapseTextChangeRangesAcrossMultipleVersions, getLineAndCharacterOfPosition } from "typescript";
import { marsByKid } from "graphql/queries";
import { insertMars, getAllCharts, updateChart } from "utils/data";
import { getAllUsersWithName } from "utils/data";
import { getChartsByKid } from "utils/data";
import { updateUser } from "utils/data";
import { Button } from "reactstrap";
import { nanoid } from "nanoid";
import { getAllUsers } from "utils/data";



function Mars(props) {
  const [name, setName] = useState("");
  const [id, setId] = useState("user#1004");
  const [cid, setCid] = useState("");
  const [status, setStatus] = useState("pending");
  const [users, setUsers] = useState();
  const [charts, setCharts] = useState();
  const [type, setType] = useState("user");
  const [kid, setKid] = useState("");
  const [pill, setPill] = useState("avodart");
  const [patients, setPatients] = useState([]);
  const [exogenes, setExogenes] = useState([]);
  const [neshes, setNeshes] = useState([]);

  useEffect(() => {

  }, [])

  const getKIDsWithName = async () => {
    const users = await getAllUsersWithName();
    console.log("num total user:", users.length);
    let csv = "앱유저아이디,이름,생년월일,지역,성별,연령";
    for (let u of users) {
      if (u.name && u.name !== "") {
        if (u.createdAt.startsWith("2021-") || u.createdAt.startsWith("2022-")) {
          csv += `\n${u.kid},${u.name},,,남,35`;
        }
      }
    }

    console.log(csv);
  }


  const getDataByKid = async (kid) => {
    try {
      const data = await API.graphql({
        query: marsByKid,
        variables: { kid: kid }
      });
      if (data.data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createChart = async (chartInfo) => {
    console.log("inside create chart: ", chartInfo);
    // 1. create chart with chart#kid#timestamp
    try {
      const newChart = await API.graphql({
        query: mutations.createMars,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: { input: chartInfo }
      })
      if (newChart) {
        console.log(newChart);
      }
    } catch (error) {
      console.log(error);
    }
    // 2. find a user and add timestamp to chartArr using set.
  }

  const createUser = async (userInfo) => {
    console.log("inside create user: ", userInfo);
    // 1. create user with user#kid
    try {
      const newUser = await API.graphql({
        query: mutations.createMars,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: { input: userInfo }
      })
      if (newUser) {
        console.log(newUser);
      }
    } catch (error) {
      console.error(error);
    }
    // 2. find a user and add timestamp to chartArr using set.
  }

  const populateUserInfo = (kid) => {
    let name = "";
    let ssn = "";
    let phone = "";
    let addr = "";
    let addr2 = "";
    let zip = "";

    // get name, ssn, phone from using kid
    for (let p of patients) {
      if (p.kid === kid) {
        name = p.name;
        ssn = p.ssn;
        phone = p.phone
        if (p.addr) {
          addr = p.addr;
          addr2 = p.addr2;
          zip = p.zip;
        }
      }
    }

    let userInfo = {
      id: `user#${kid}`,
      type: "user",
      kid: kid,
      name: name,
      ssn: ssn,
      phone: phone,
      addr: addr,
      addr2: addr2,
      zip: zip
    }

    return userInfo;
  }

  return (<>
    <div className="scrollable" style={{ padding: "30px" }}>

      <div>
        <Button color="primary" onClick={async () => {
          let charts = await getAllCharts();
          setCharts(charts);
          console.log(charts);
        }}>챠트모두 불러오기</Button>

        <Button color="primary" onClick={async () => {
          let users = await getAllUsers();
          setUsers(users);
          console.log(users);
        }}>유저모두 불러오기</Button>


        <Button color="primary" onClick={async () => {
          const kidSet1 = new Set();
          const kidSet2 = new Set();
          for (let chart of charts) {
            if (chart.rm === "y") {
              kidSet1.add(chart.kid);
            }
            if (new Date(chart.yeyak_time) > new Date('2022-11-01T01:00:00.000Z')) {
              if (chart.status !== "cancel") {
                // kidSet2.add({kid: chart.kid, time: chart.yeyak_time});
                kidSet2.add(chart.kid);
              }
            }
          }
          console.log("리필유저 보내진 kid: ", kidSet1);
          console.log("11월-1일 이후 진료본 케아이디: ", kidSet2);

          const difference = new Set([...kidSet1].filter((x) => !kidSet2.has(x)));
          console.log("difference: ", difference);
          console.log(difference.has('2403081981'));

          let csv = "앱유저아이디,이름,생년월일,지역,성별,연령";
          for (let u of difference) {
            csv += `\n${u},${nanoid(8)},,,남,35`;
          }

          console.log(csv);


        }}>리필 안한 유저 구하기</Button>

        <Button color="primary" onClick={async () => {
          const kidSet1 = new Set();
          const kidSet2 = new Set();
          for (let chart of charts) {
            if (chart.status === "pay") {
              if (
                (new Date(chart.yeyak_time) > new Date('2022-12-18T01:00:00.000Z')) &&
                (new Date(chart.yeyak_time) < new Date('2023-03-31T01:00:00.000Z'))
              ) {
                // kidSet1.add({kid: chart.kid, time: chart.yeyak_time, total: chart.total });
                kidSet1.add(chart.kid);
              }
            }
          }
          console.log("결제안하신분들: ", kidSet1);
          // console.log("11월-1일 이후 진료본 케아이디: ", kidSet2);


          let csv = "앱유저아이디,이름,생년월일,지역,성별,연령";
          for (let u of kidSet1) {
            csv += `\n${u},${nanoid(8)},,,남,35`;
          }

          console.log(csv);


        }}>문진시작하고 결제안한사람</Button>


      </div>
      {/* <div>
        <button onClick={async ()=>{
          let data = await getAllAnalytics("DESC", null, 1000);
          // console.log("data: ", data);

          for (let d of data) {
            if (d.action === "survey" && d.from === "/survey/sv-reservation") {
              await updateMarsAnalytics({ kid: d.kid, timestamp: d.timestamp, patient_type: "재진" });
            }
          }
        }}>analytics data cleanup</button>
      </div> */}


      {/* <div>
        <button onClick={getKIDsWithName}>한번이라도 예약한 유저 뽑기</button>
      </div> */}
      <div>
        <input type='input' placeholder="id" value={id} onChange={(e) => { setId(e.target.value) }} />
        <input type='input' placeholder="name" onChange={(e) => { setName(e.target.value) }} />
        <input type='input' placeholder="type" value={type} onChange={(e) => { setType(e.target.value) }} />
        <input type='input' placeholder="kid" onChange={(e) => { setKid(e.target.value) }} />
        <input type='input' placeholder="status" value={status} onChange={(e) => { setStatus(e.target.value) }} />
        <button onClick={async () => {

          // let set1 = new Set();
          // set1.add(12345);
          // set1.add(2345);

          const marsDetails = {
            id: id,
            name: name,
            kid: kid,
            status: status,
            type: type
          };

          try {
            const newMars = await API.graphql({
              query: mutations.createMars,
              authMode: 'AMAZON_COGNITO_USER_POOLS',
              variables: { input: marsDetails }
            })
          } catch (error) {
            console.log(error);
          }

        }}>add</button>
      </div>

      {/* {users && users.map((m) => {
        return (
          <div>{m.id}, {m.kid}, {m.name}, {m.chartArr} </div>
        )
      })} */}

      <br />
      <div>
        Adding Chart to User
      </div>
      <div>
        <input type='input' placeholder="kid" value={kid} onChange={(e) => { setKid(e.target.value) }} />
        <input type='input' placeholder="pill" value={pill} onChange={(e) => { setPill(e.target.value) }} />
        <input type='input' placeholder="status" value={status} onChange={(e) => { setStatus(e.target.value) }} />
        <button onClick={async () => {
          // console.log("adding mars data");
          // create new Mars
          //
          let timeStamp = new Date().getTime();
          let cid = `chart#${kid}#${timeStamp}`;
          console.log("cid: ", cid);

          const chartInfo = {
            id: cid, //chart#{kid}#{timestamp}
            type: "chart",
            pill: pill,
            kid: kid,
          };

          createChart(chartInfo);

        }}>add chart</button>
      </div>

      {/* {charts && charts.map((m) => {
        return (
          <div>{m.id}, {m.kid}, {m.name}, {m.pill} </div>
        )
      })} */}

      <br />
      <div>get data by kid</div>
      <input type='input' placeholder="kid" value={kid} onChange={(e) => { setKid(e.target.value) }} />
      <button onClick={async () => {
        getDataByKid(kid);
      }} >get by kid</button>


      <div>Migration</div>

      <button onClick={async () => {
        let cs = await getAllCharts()

        // // sort patients by createdDate
        // ps.sort(
        //   (a, b) =>
        //     new Date(b.createdAt.toString()) - new Date(a.createdAt.toString())
        // );


        // setPatients(ps.slice(0,1));
        setCharts(cs);

        console.log(cs);
      }}>1. get all charts</button>

      <button onClick={async () => {
        for (let c of charts) {
          if (c.minox && c.minox !== "minox") {
            console.log(c.minox);
          }
          //   if (c.minox && c.minox === "존슨앤존슨 로게인폼") {
          //     // console.log(c.pill);
          //     let patientInfo = {
          //       id: c.id,
          //       ignoreStatus: "yes",
          //       minox: "rogaine"
          //     }
          //     let res = await updateChart(patientInfo);
          //     console.log("updated: ", res);
          //   }

        }
      }}>2. 약 이름바꾸기</button>

      <button onClick={async () => {
        console.log("showing lists to be migrated");
        let list = [];
        for (let c of charts) {
          if (c.survey_type) {
            // if (!c.yeyak_time){
            list.push(c);

            // let chartInfo = {
            //   id: c.id,
            //   updatedAt: c.updatedAt,
            //   addr: null,
            //   addr2: null,
            //   zip: null,
            //   ignoreStatus: "yes"
            // }
            // let res = await updateChart(chartInfo);
            // if (res) {
            //   console.log("Succes: ", res);
            // }
          }
        }
        // console.log(list);
        for (let l of list) {
          // console.log(`${l.id}\n${l.createdAt}\n${getTimeObject(l.reservation_time).toISOString()}`)
          console.log(l.filekey);
          // let chartInfo = {
          //     id: l.id,
          //     updatedAt: l.updatedAt,
          //     ignoreStatus: "yes",
          //     yeyak_time: getTimeObject(l.reservation_time).toISOString()
          //   }
          //   let res = await updateChart(chartInfo);
          //   if (res) {
          //     console.log("Succes: ", res);
          //   }
        }
        console.log(list.length)

      }}>22. get charts with addr</button>


      <button onClick={async () => {
        let ukids = new Set();
        for (let p of patients) {
          ukids.add(p.kid);
        }
        console.log(ukids);

        let count = 0;
        for (let kid of ukids) {
          let userInfo = populateUserInfo(kid);
          count++;
          const res = await createUser(userInfo);
          console.log(res);
        }
        console.log("total count: ", count);
      }}>2. get patients with unique kid</button>

      <button onClick={async () => {
        for (let p of patients) {
          let ts = new Date().getTime();
          // console.log(ts);
          let chartInfo = {
            id: `chart#${p.kid}#${ts}`,
            type: "chart",
            createdAt: p.createdAt,
            updatedAt: p.updatedAt,
            kid: p.kid,
            ans: p.ans,
            status: p.status,
            dstatus: p.dstatus,
            pstatus: p.pstatus,
            when: p.when,
            filekey: p.filekey,
            delivery_number: p.delivery_number,
            payResult: p.payResult,
            isPaid: p.isPaid,
            pill: p.pill,
            minox: p.minox,
            shamp: p.shamp,
            shamp_id: p.shamp_id,
            month: p.month,
            minoxMonth: p.minoxMonth,
            pillMonth: p.pillMonth,
            total: p.total,
            survey_type: p.survey_type,
            survey_version: p.survey_version,
            prescription: p.prescription,
            ignoreStatus: 'yes',
            reservation_time: p.reservation_time,
            hairline_url: p.hairline_url,
            haircenter_url: p.haircenter_url,
            hairgeneral_url: p.hairgeneral_url,
            doctor: p.doctor,
            rm: p.rm,
            r: p.r,
            capil: p.capil,
            capilMonth: p.capilMonth
          }
          // console.log(chartInfo);
          let res = await insertMars(chartInfo);
          console.log(res);
        }
      }}>3. populate charts</button>

      <button onClick={async () => {
        for (let p of patients) {
          let ts = new Date().getTime();
          // console.log(ts);
          let status = "accept";
          if (p.isPaid === "yes") {
            status = "shamp_sent"
          }
          const neshDetails = {
            id: `nesh#${p.kid}#${ts}`,
            type: "nesh",
            name: p.name,
            phone: p.phone,
            kid: p.kid,
            status: status,
            shamp: p.shamp,
            createdAt: p.createdAt,
            updatedAt: p.updatedAt,
            delivery_number: "none"
          };
          console.log(neshDetails);

          try {
            const newNesh = await API.graphql({
              query: mutations.createMars,
              authMode: 'AMAZON_COGNITO_USER_POOLS',
              variables: { input: neshDetails },
            });
            if (newNesh) {
              // slack2ops(`[fv-shamp][${props.kid}][${name}] ${PRODUCTS[props.shamp].name} 샴푸신청 완료 🎉`);
              console.log(newNesh);
            }
          } catch (error) {
            console.log(error);
          }

        }
      }}>4. populate nesh</button>


      <div>
       

        <button onClick={async () => {
          for (let p of exogenes) {
            let userInfo = {
              id: `user#${p.kid}`,
              type: "user",
              kid: p.kid,
              name: p.name,
              phone: p.phone,
              email: p.email,
              addr: p.addr,
              addr2: p.addr2,
              zip: p.zip
            }
            // console.log(userInfo);
            const res = await createUser(userInfo);
            console.log(res);
          }
        }}>2. create user from old exogene</button>

        <button onClick={async () => {
          for (let p of exogenes) {
            let ts = new Date().getTime();
            // console.log(ts);
            const exoDetails = {
              id: `exogene#${p.kid}#${new Date().getTime()}`,
              type: "exogene",
              name: p.name,
              phone: p.phone,
              email: p.email,
              status: p.status,
              delivery_number: p.dn,
              isPaid: p.isPaid,
              addr: p.addr,
              addr2: p.addr2,
              zip: p.zip,
              kid: p.kid,
              total: p.total,
              payResult: p.payResult
            };

            console.log(exoDetails);
            let res = await insertMars(exoDetails);
            console.log(res);
          }
        }}>3. populate 엑소진 charts</button>

      </div>
      <div>
        USER Migration
        <button onClick={async () => {
          let usersArray = [];
          let users = await getAllUsers();
          for (let user of users) {
            if (user.name) {
              usersArray.push(user)
            }
          }
          setUsers(usersArray);
          console.log(usersArray);
        }}>1.Get All Users with name</button>

        <button onClick={async () => {
          let count = 0;
          for (let user of users) {
            // if (count++ > 10) { return }
            let kid = user.kid;
            let charts = await getChartsByKid(kid);
            charts.sort((a, b) => new Date(b.yeyak_time) - new Date(a.yeyak_time));
            console.log("showing yeyak time for " + kid);
            let userCharts = [];
            for (let c of charts) {
              let stat = "ing";
              if (c.status === "deli_done") {
                stat = "deli_done";
              }
              if (c.status === "cancel") {
                stat = "cancel";
              }
              userCharts.push({ "id": c.id, "stat": stat, "yt": c.yeyak_time });
            }
            // console.log(JSON.stringify(userCharts));
            let userInfo = {
              id: user.id,
              charts: JSON.stringify(userCharts)
            }
            let uuser = await updateUser(userInfo);
            console.log(uuser);


          }

        }}>2.Get charts for each user</button>
      </div>

    </div>




  </>)
}

export default Mars;