import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { updateUser } from "utils/data";
import DaumPostcode from "react-daum-postcode";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import Lottie from "lottie-react";
import arrowBack from "../assets/arrowBack.png";

// assets
import addressLottie from "../assets/profileAddress.json";

// css
import "../styling/componentStyling.css";

export default function UserAddress(props) {
  const { addressInfo, userID } = props.location.state;
  const [address, setAddress] = useState();
  const [addressDetail, setAddressDetail] = useState();
  const [openAddress, setOpenAddress] = useState(false);
  const [ID, setID] = useState();
  const [zip, setZip] = useState();
  const history = useHistory();

  useEffect(() => {
    try {
      setAddress(addressInfo[0]);
      setAddressDetail(addressInfo[1]);
      setID(userID);
      setZip(addressInfo[2]);
    } catch (error) {
      console.log("unable to retrieve address info");
    }
  }, []);

  const handleUpdateUser = async () => {
    const userInfo = {
      id: ID,
      addr: address,
      addr2: addressDetail,
      zip: zip,
    };
    const res = await updateUser(userInfo);
    if (res) {
      alert("정보가 수정되었습니다");
      history.push("/myPageNew");
    }
  };

  return (
    <div className="user-container" style={{ background: "white" }}>
      {openAddress ? (
        <div className="address-popup-container">
          <DaumPostcode
            style={{ height: "100vh" }}
            onComplete={(data) => {
              let fullAddress = data.address;
              let extraAddress = "";

              if (data.addressType === "R") {
                if (data.bname !== "") {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                  extraAddress +=
                    extraAddress !== ""
                      ? `, ${data.buildingName}`
                      : data.buildingName;
                }
                fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
              }
              setAddress(fullAddress);
              setZip(data.zonecode);
              setOpenAddress(false);
            }}
          />
          <span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenAddress(false);
              }}
            >
              닫기
            </button>
          </span>
        </div>
      ) : (
        <> </>
      )}
      <MarsNavBarMain></MarsNavBarMain>
      <button
        className="profile-return-button"
        onClick={() => {
          history.push("/myPageNew");
        }}
      >
        <img src={arrowBack} />
      </button>
      <div className="user-info-display">
        <div className="user-info-title">
          <span>주소정보</span>
          <Lottie
            animationData={addressLottie}
            autoPlay={true}
            id="profile-lottie"
          ></Lottie>
        </div>
      </div>
      <div className="user-data-form">
        <form className="user-data-input" style={{ width: "50%" }}>
          <span style={{ marginTop: address ? "-25px" : "" }}>우편번호</span>
          <input
            type="number"
            value={zip ? zip : ""}
            onChange={(e) => {
              e.stopPropagation();
            }}
            onClick={() => {
              setOpenAddress(true);
            }}
          ></input>
        </form>
        <form className="user-data-input">
          <span style={{ marginTop: address ? "-25px" : "" }}>주소</span>
          <input
            type="text"
            value={address ? address : ""}
            onChange={(e) => {
              e.stopPropagation();
            }}
            onClick={() => {
              setOpenAddress(true);
            }}
          ></input>
        </form>

        <form className="user-data-input">
          <span style={{ marginTop: addressDetail ? "-25px" : "" }}>
            상세주소
          </span>
          <input
            type="text"
            value={addressDetail ? addressDetail : ""}
            onChange={(e) => {
              e.stopPropagation();
              setAddressDetail(e.target.value);
            }}
          ></input>
        </form>
      </div>
      <div
        className="user-data-button"
        style={{ display: openAddress ? "none" : "block" }}
      >
        <button
          onClick={() => {
            handleUpdateUser();
          }}
        >
          저장하기
        </button>
      </div>
    </div>
  );
}
