// Waits for card info given user chart
// Currently retrieves first card only
// returns array
const showDisplayPhone = (phone) => {
  var output = phone;
  return output.replace(
    /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
    "$1$2$3-$4$5$6$7-$8$9$10$11"
  );
};

// const censorSSN = (ssn) => {
//   return ssn.replace(
//     /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
//     "$1$2$3$4$5$6-$7******"
//   );
// };

export function getCardInfo(user) {
  return new Promise((res, rej) => {
    let cards = [];
    let cardInfo = [];
    if(user && user.cards === null) {
      res([])
    }
    if (user && user.cards === "[]") {
      res([]);
    }
    if (user && user.cards !== "[]") {
      cards = JSON.parse(user.cards);
      let name = cards[0].CNAME;
      let num = cards[0].CNUM;
      cardInfo.push(name, num);
      // res({ name: name, num: num });
      res(cardInfo);
    } else {
      rej("fail to retrieve card info");
    }
  });
}

// requires user chart
// returns user basic info as array
export function getUserInfo(user) {
  return new Promise((res, rej) => {
    let userInfo = [];
    if (user.name === null || user.phone === null || user.ssn === null) {
      res([]);
    }
    if (user.name === "" && user.phone === "" && user.ssn === "") {
      res([]);
    }
    if (user) {
      userInfo.push(user.name, { phone: user.phone }, { ssn: user.ssn });
      res(userInfo);
    } else {
      rej("fail to retrieve user info");
    }
  });
}

// returns user address data in array
// TODO: return [key, obj]
// index 0: main, 1: detailed address 2: zip
export function getAddressInfo(user) {
  return new Promise((res, rej) => {
    let addrInfo = [];
    if (user.addr === null || user.addr2 === null) {
      res([]);
    }
    if (user.addr === "" || user.addr2 === "") {
      res([]);
    }
    if (user) {
      addrInfo.push(user.addr, user.addr2, user.zip);
      res(addrInfo);
    } else {
      rej("fail to retrieve addr info");
    }
  });
}

// censors last 6 digits of user's ssn
function censorSSN(ssn) {
  let cens = ssn.split("-");
  return `${cens[0]} - ${cens[1].replaceAll("*")}`;
}
