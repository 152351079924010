import React from "react";
import { useHistory } from "react-router";
import { parsePhoneNumber } from "utils/util";
import UserInfo from "./UserInfo";

// css
import "../styling/accordionStyling.css";

export default function InfoTabAccordion(props) {
  // pass props as array preferably - plain text for display purposes only
  const { title, displayData, type, userID, kid } = props;
  const history = useHistory();

  // jank
  function censorSSN(ssn) {
    if (ssn.length > 0 && ssn.includes('-')) {
      let cens = ssn.split("-");
      return `${cens[0]} - ${cens[1].substring(0, 1)}******`;
    } else {
      return ''
    }
    return "";
  }

  const handleRedirect = (type) => {
    if (type === "user")
      history.push({
        pathname: "/user_info",
        state: {
          userInfo: displayData,
          userID: userID,
          kid: kid,
        },
      });
    if (type === "addr")
      history.push({
        pathname: "/user_address",
        state: { addressInfo: displayData, userID: userID, kid: kid },
      });
    if (type === "card")
      history.push({
        pathname: "/user_card",
        state: { cardInfo: displayData, userID: userID, kid: kid },
      });

    if (type === "history")
      history.push({
        pathname: "/user_history",
        state: { cardInfo: null, userID: userID, kid: kid },
      });

    if (type === "photos")
      history.push({
        pathname: "/user_photos",
        state: { cardInfo: null, userID: userID, kid: kid },
      });

    return;
  };

  return (
    <div className="profile-info-container-accordion">
      <div className="profile-data-display-accordion">
        {displayData
          ? displayData.map((item, key) => {
              return (
                <div key={key}>
                  {/* if one & only object is ssn -> returns censored string of said object else map item  */}
                  {Object.keys(item).includes("ssn")
                    ? censorSSN(item["ssn"])
                    : item.length < 1
                    ? ""
                    : Object.keys(item).includes("phone")
                    ? parsePhoneNumber(item["phone"])
                    : item}
                  <div
                    className="profile-redirect-accordion"
                    onClick={() => {
                      handleRedirect(type);
                    }}
                  >
                    <span>변경하기</span>
                  </div>
                </div>
              );
            })
          : ""}
        {/* for when there's no data to display && is not card accordion */}
        {displayData.length <= 0 && type !== "card" ? (
          <div className="profile-data-display-accordion-placement">
            정보가 없어요. 입력해 주세요.
            <button
              onClick={() => {
                handleRedirect(type);
              }}
            >
              입력하기
            </button>
          </div>
        ) : // for when there's no data to display && is card
        displayData.length <= 0 && type === "card" ? (
          <div className="profile-data-display-accordion-placement">
            등록 된 결제 정보가 없습니다.
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
