/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPhotoEvent = /* GraphQL */ `
  query GetPhotoEvent($id: ID!) {
    getPhotoEvent(id: $id) {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const listPhotoEvents = /* GraphQL */ `
  query ListPhotoEvents(
    $filter: ModelPhotoEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        birth
        center
        front
        addr
        addr2
        zip
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMars = /* GraphQL */ `
  query GetMars($id: String!) {
    getMars(id: $id) {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const listMars = /* GraphQL */ `
  query ListMars(
    $id: String
    $filter: ModelMarsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMars(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cid
        type
        kid
        createdAt
        updatedAt
        name
        phone
        email
        ssn
        addr
        addr2
        zip
        charts
        cards
        ans
        status
        dstatus
        pstatus
        p2status
        when
        filekey
        delivery_number
        payResult
        isPaid
        nesh_id
        pill
        minox
        shamp
        shamp_id
        month
        minoxMonth
        pillMonth
        total
        survey_type
        survey_version
        prescription
        ignoreStatus
        reservation_time
        yeyak_time
        hairline_url
        haircenter_url
        hairgeneral_url
        doctor
        rm
        r
        capil
        capilMonth
        medi_done_time
        pharma
        extraInfo
        deliMsg
        prescription_update_time
      }
      nextToken
    }
  }
`;
export const marsByCDate = /* GraphQL */ `
  query MarsByCDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMarsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    marsByCDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cid
        type
        kid
        createdAt
        updatedAt
        name
        phone
        email
        ssn
        addr
        addr2
        zip
        charts
        cards
        ans
        status
        dstatus
        pstatus
        p2status
        when
        filekey
        delivery_number
        payResult
        isPaid
        nesh_id
        pill
        minox
        shamp
        shamp_id
        month
        minoxMonth
        pillMonth
        total
        survey_type
        survey_version
        prescription
        ignoreStatus
        reservation_time
        yeyak_time
        hairline_url
        haircenter_url
        hairgeneral_url
        doctor
        rm
        r
        capil
        capilMonth
        medi_done_time
        pharma
        extraInfo
        deliMsg
        prescription_update_time
      }
      nextToken
    }
  }
`;
export const marsByKid = /* GraphQL */ `
  query MarsByKid(
    $kid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMarsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    marsByKid(
      kid: $kid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cid
        type
        kid
        createdAt
        updatedAt
        name
        phone
        email
        ssn
        addr
        addr2
        zip
        charts
        cards
        ans
        status
        dstatus
        pstatus
        p2status
        when
        filekey
        delivery_number
        payResult
        isPaid
        nesh_id
        pill
        minox
        shamp
        shamp_id
        month
        minoxMonth
        pillMonth
        total
        survey_type
        survey_version
        prescription
        ignoreStatus
        reservation_time
        yeyak_time
        hairline_url
        haircenter_url
        hairgeneral_url
        doctor
        rm
        r
        capil
        capilMonth
        medi_done_time
        pharma
        extraInfo
        deliMsg
        prescription_update_time
      }
      nextToken
    }
  }
`;
export const getMarsUtil = /* GraphQL */ `
  query GetMarsUtil($id: ID!) {
    getMarsUtil(id: $id) {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const listMarsUtils = /* GraphQL */ `
  query ListMarsUtils(
    $filter: ModelMarsUtilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarsUtils(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarsAnalytics = /* GraphQL */ `
  query GetMarsAnalytics($kid: ID!, $timestamp: String!) {
    getMarsAnalytics(kid: $kid, timestamp: $timestamp) {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const listMarsAnalytics = /* GraphQL */ `
  query ListMarsAnalytics(
    $kid: ID
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelMarsAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMarsAnalytics(
      kid: $kid
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        kid
        id
        timestamp
        action
        chart_id
        user_pill
        doctor_pill
        from
        st
        et
        duration
        exit
        addon
        question
        answer
        patient_type
        msg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $id: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        survey_platform
        version
        type
        questions_answers {
          items {
            id
            question_id
            question_number
            question_title
            question_title_short
            question_subtitle
            question_type
            question_btn_type
            max_choice
            answers {
              items {
                id
                answer_button_type
                answer_title
                answer_value
                answer_img_id
                answer_img_url
                answer_order
                createdAt
                updatedAt
                surveyQNAAnswersId
              }
              nextToken
            }
            createdAt
            updatedAt
            surveyQuestions_answersId
          }
          nextToken
        }
        flow_chart
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurveyQNA = /* GraphQL */ `
  query GetSurveyQNA($id: ID!) {
    getSurveyQNA(id: $id) {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const listSurveyQNAS = /* GraphQL */ `
  query ListSurveyQNAS(
    $id: ID
    $filter: ModelSurveyQNAFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyQNAS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        question_id
        question_number
        question_title
        question_title_short
        question_subtitle
        question_type
        question_btn_type
        max_choice
        answers {
          items {
            id
            answer_button_type
            answer_title
            answer_value
            answer_img_id
            answer_img_url
            answer_order
            createdAt
            updatedAt
            surveyQNAAnswersId
          }
          nextToken
        }
        createdAt
        updatedAt
        surveyQuestions_answersId
      }
      nextToken
    }
  }
`;
export const getSurveyAnswer = /* GraphQL */ `
  query GetSurveyAnswer($id: ID!) {
    getSurveyAnswer(id: $id) {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const listSurveyAnswers = /* GraphQL */ `
  query ListSurveyAnswers(
    $id: ID
    $filter: ModelSurveyAnswerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyAnswers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        answer_button_type
        answer_title
        answer_value
        answer_img_id
        answer_img_url
        answer_order
        createdAt
        updatedAt
        surveyQNAAnswersId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        cards {
          items {
            id
            card_name
            card_company
            card_number
            billing_key
            last_used_time
            createdAt
            updatedAt
            userCardsId
          }
          nextToken
        }
        venus_charts {
          items {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  chart {
                    id
                    patient_name
                    user_id
                    user_info {
                      id
                      name
                      age
                      phone
                      ssn
                      addrs {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    yeyak_time
                    platform
                    kid
                    user_status
                    doctor_status
                    pharma_status
                    survey_answers
                    survey_type
                    user_msg
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    when
                    recommended_medicines {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    order_set_id
                    orderSet {
                      id
                      kid
                      status
                      order_shipments {
                        nextToken
                      }
                      chart_id
                      total
                      createdAt
                      updatedAt
                      userOrder_setsId
                    }
                    createdAt
                    updatedAt
                    userVenus_chartsId
                  }
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  total
                  orders {
                    items {
                      id
                      category
                      product_name
                      product_id
                      product_count
                      product_unit
                      product_base
                      product_quantity_str
                      product_price
                      order_total_price
                      createdAt
                      updatedAt
                      orderShipmentOrdersId
                    }
                    nextToken
                  }
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          nextToken
        }
        order_sets {
          items {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderShipment = /* GraphQL */ `
  query GetOrderShipment($id: ID!) {
    getOrderShipment(id: $id) {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const listOrderShipments = /* GraphQL */ `
  query ListOrderShipments(
    $id: ID
    $filter: ModelOrderShipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderShipments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        kid
        status
        brand
        processor
        chart_id
        chart {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        processor_status
        updatedAt
        processor_id
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        total
        orders {
          items {
            id
            category
            product_name
            product_id
            product_count
            product_unit
            product_base
            product_quantity_str
            product_price
            order_total_price
            createdAt
            updatedAt
            orderShipmentOrdersId
          }
          nextToken
        }
        road_address
        detail_address
        zip
        phone
        name
        delivery_company
        delivery_number
        createdAt
        orderSetOrder_shipmentsId
      }
      nextToken
    }
  }
`;
export const orderShipmentByProcessor = /* GraphQL */ `
  query OrderShipmentByProcessor(
    $processor: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderShipmentByProcessor(
      processor: $processor
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kid
        status
        brand
        processor
        chart_id
        chart {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        processor_status
        updatedAt
        processor_id
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        total
        orders {
          items {
            id
            category
            product_name
            product_id
            product_count
            product_unit
            product_base
            product_quantity_str
            product_price
            order_total_price
            createdAt
            updatedAt
            orderShipmentOrdersId
          }
          nextToken
        }
        road_address
        detail_address
        zip
        phone
        name
        delivery_company
        delivery_number
        createdAt
        orderSetOrder_shipmentsId
      }
      nextToken
    }
  }
`;
export const orderShipmentByUpdateTime = /* GraphQL */ `
  query OrderShipmentByUpdateTime(
    $processor_id: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderShipmentByUpdateTime(
      processor_id: $processor_id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kid
        status
        brand
        processor
        chart_id
        chart {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        processor_status
        updatedAt
        processor_id
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        total
        orders {
          items {
            id
            category
            product_name
            product_id
            product_count
            product_unit
            product_base
            product_quantity_str
            product_price
            order_total_price
            createdAt
            updatedAt
            orderShipmentOrdersId
          }
          nextToken
        }
        road_address
        detail_address
        zip
        phone
        name
        delivery_company
        delivery_number
        createdAt
        orderSetOrder_shipmentsId
      }
      nextToken
    }
  }
`;
export const getOrderSet = /* GraphQL */ `
  query GetOrderSet($id: ID!) {
    getOrderSet(id: $id) {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  chart {
                    id
                    patient_name
                    user_id
                    user_info {
                      id
                      name
                      age
                      phone
                      ssn
                      addrs {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    yeyak_time
                    platform
                    kid
                    user_status
                    doctor_status
                    pharma_status
                    survey_answers
                    survey_type
                    user_msg
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    when
                    recommended_medicines {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    order_set_id
                    orderSet {
                      id
                      kid
                      status
                      order_shipments {
                        nextToken
                      }
                      chart_id
                      total
                      createdAt
                      updatedAt
                      userOrder_setsId
                    }
                    createdAt
                    updatedAt
                    userVenus_chartsId
                  }
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  total
                  orders {
                    items {
                      id
                      category
                      product_name
                      product_id
                      product_count
                      product_unit
                      product_base
                      product_quantity_str
                      product_price
                      order_total_price
                      createdAt
                      updatedAt
                      orderShipmentOrdersId
                    }
                    nextToken
                  }
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const listOrderSets = /* GraphQL */ `
  query ListOrderSets(
    $id: ID
    $filter: ModelOrderSetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderSets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  items {
                    id
                    nick_name
                    road_address
                    detail_address
                    zip
                    is_default
                    createdAt
                    updatedAt
                    userInfoAddrsId
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  items {
                    id
                    kid
                    status
                    brand
                    processor
                    chart_id
                    chart {
                      id
                      patient_name
                      user_id
                      user_info {
                        id
                        name
                        age
                        phone
                        ssn
                        createdAt
                        updatedAt
                      }
                      yeyak_time
                      platform
                      kid
                      user_status
                      doctor_status
                      pharma_status
                      survey_answers
                      survey_type
                      user_msg
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      when
                      recommended_medicines {
                        nextToken
                      }
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      order_set_id
                      orderSet {
                        id
                        kid
                        status
                        chart_id
                        total
                        createdAt
                        updatedAt
                        userOrder_setsId
                      }
                      createdAt
                      updatedAt
                      userVenus_chartsId
                    }
                    processor_status
                    updatedAt
                    processor_id
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    total
                    orders {
                      items {
                        id
                        category
                        product_name
                        product_id
                        product_count
                        product_unit
                        product_base
                        product_quantity_str
                        product_price
                        order_total_price
                        createdAt
                        updatedAt
                        orderShipmentOrdersId
                      }
                      nextToken
                    }
                    road_address
                    detail_address
                    zip
                    phone
                    name
                    delivery_company
                    delivery_number
                    createdAt
                    orderSetOrder_shipmentsId
                  }
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        category
        product_name
        product_id
        product_count
        product_unit
        product_base
        product_quantity_str
        product_price
        order_total_price
        createdAt
        updatedAt
        orderShipmentOrdersId
      }
      nextToken
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $id: ID
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $id: ID
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAddresses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nick_name
        road_address
        detail_address
        zip
        is_default
        createdAt
        updatedAt
        userInfoAddrsId
      }
      nextToken
    }
  }
`;
export const getCard = /* GraphQL */ `
  query GetCard($id: ID!) {
    getCard(id: $id) {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const listCards = /* GraphQL */ `
  query ListCards(
    $id: ID
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCards(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        card_name
        card_company
        card_number
        billing_key
        last_used_time
        createdAt
        updatedAt
        userCardsId
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $id: ID
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDoctors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPharma = /* GraphQL */ `
  query GetPharma($id: ID!) {
    getPharma(id: $id) {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const listPharmas = /* GraphQL */ `
  query ListPharmas(
    $id: ID
    $filter: ModelPharmaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPharmas(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pharma_name
        office_name
        office_address
        office_phone
        pharma_phone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrescription = /* GraphQL */ `
  query GetPrescription($id: ID!) {
    getPrescription(id: $id) {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const listPrescriptions = /* GraphQL */ `
  query ListPrescriptions(
    $id: ID
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrescriptions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicine = /* GraphQL */ `
  query GetMedicine($id: ID!) {
    getMedicine(id: $id) {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const listMedicines = /* GraphQL */ `
  query ListMedicines(
    $id: ID
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMedicines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        medicine_info_id
        medicine {
          id
          type
          ingredient
          company
          product_name
          nick_name
          retail_price
          description
          unit
          base
          multiplier
          price_multiplier
          info_url
          display_order
          createdAt
          updatedAt
        }
        dosage
        period
        period_unit
        display_order
        prescriptionPrescribed_medicineId
        createdAt
        updatedAt
        chartRecommended_medicinesId
      }
      nextToken
    }
  }
`;
export const medicinesByDisplayOrder = /* GraphQL */ `
  query MedicinesByDisplayOrder(
    $prescriptionPrescribed_medicineId: ID!
    $display_order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    medicinesByDisplayOrder(
      prescriptionPrescribed_medicineId: $prescriptionPrescribed_medicineId
      display_order: $display_order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medicine_info_id
        medicine {
          id
          type
          ingredient
          company
          product_name
          nick_name
          retail_price
          description
          unit
          base
          multiplier
          price_multiplier
          info_url
          display_order
          createdAt
          updatedAt
        }
        dosage
        period
        period_unit
        display_order
        prescriptionPrescribed_medicineId
        createdAt
        updatedAt
        chartRecommended_medicinesId
      }
      nextToken
    }
  }
`;
export const getMedicineInfo = /* GraphQL */ `
  query GetMedicineInfo($id: ID!) {
    getMedicineInfo(id: $id) {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const listMedicineInfos = /* GraphQL */ `
  query ListMedicineInfos(
    $id: ID
    $filter: ModelMedicineInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMedicineInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChart = /* GraphQL */ `
  query GetChart($id: ID!) {
    getChart(id: $id) {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  items {
                    id
                    nick_name
                    road_address
                    detail_address
                    zip
                    is_default
                    createdAt
                    updatedAt
                    userInfoAddrsId
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  items {
                    id
                    kid
                    status
                    brand
                    processor
                    chart_id
                    chart {
                      id
                      patient_name
                      user_id
                      user_info {
                        id
                        name
                        age
                        phone
                        ssn
                        createdAt
                        updatedAt
                      }
                      yeyak_time
                      platform
                      kid
                      user_status
                      doctor_status
                      pharma_status
                      survey_answers
                      survey_type
                      user_msg
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      when
                      recommended_medicines {
                        nextToken
                      }
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      order_set_id
                      orderSet {
                        id
                        kid
                        status
                        chart_id
                        total
                        createdAt
                        updatedAt
                        userOrder_setsId
                      }
                      createdAt
                      updatedAt
                      userVenus_chartsId
                    }
                    processor_status
                    updatedAt
                    processor_id
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    total
                    orders {
                      items {
                        id
                        category
                        product_name
                        product_id
                        product_count
                        product_unit
                        product_base
                        product_quantity_str
                        product_price
                        order_total_price
                        createdAt
                        updatedAt
                        orderShipmentOrdersId
                      }
                      nextToken
                    }
                    road_address
                    detail_address
                    zip
                    phone
                    name
                    delivery_company
                    delivery_number
                    createdAt
                    orderSetOrder_shipmentsId
                  }
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const listCharts = /* GraphQL */ `
  query ListCharts(
    $id: ID
    $filter: ModelChartFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCharts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      nextToken
    }
  }
`;
export const chartsByYeyakTime = /* GraphQL */ `
  query ChartsByYeyakTime(
    $platform: String!
    $yeyak_time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chartsByYeyakTime(
      platform: $platform
      yeyak_time: $yeyak_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      nextToken
    }
  }
`;
export const getProductInfo = /* GraphQL */ `
  query GetProductInfo($id: ID!) {
    getProductInfo(id: $id) {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const listProductInfos = /* GraphQL */ `
  query ListProductInfos(
    $id: ID
    $filter: ModelProductInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        category
        ingredient
        company
        product_name
        product_img_id
        product_img_url
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
