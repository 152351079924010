export default [
    {
        "id": 0,
        "name": "프로페시아",
        "img1": "propecia.png",
        "img2": "propecia-detail.png"
    },
    {
        "id": 1,
        "name": "알로스칸",
        "img1": "aloscan.png",
        "img2": "aloscan-detail.png"
    },
    {
        "id": 2,
        "name": "피나온",
        "img1": "finaon.png",
        "img2": "finaon-detail.png"
    },
    {
        "id": 3,
        "name": "아보다트",
        "img1": "avodart.png",
        "img2": "avodart-detail.png"
    },
    
    {
        "id": 4,
        "name": "damodart",
        "img1": "damodart.png",
        "img2": "damodart-detail.png"
    },
    // {
    //     "id": 5,
    //     "name": "dutaon",
    //     "img1": "dutaon.png",
    //     "img2": "dutaon-detail.png"
    // },
    {
        "id": 5,
        "name": "dutawin",
        "img1": "dutawin.png",
        "img2": "dutawin-detail.png"
    },
    {
        "id": 6,
        "name": "minox",
        "img1": "medication-minox.png",
        "img2": "medication-minox-detail.png"
    },
    {
        "id": 7,
        "name": "rogaine",
        "img1": "medication-rogaine.png",
        "img2": "medication-rogaine-detail.png"
    },
    {
        "id": 8,
        "name": "capilus",
        "img1": "capilus.png",
        "img2": "capilus-detail.png"
    }
]
