import { __read } from "tslib";
/**
 * Validate whether a string is an ARN.
 */
export var validate = function (str) {
    return typeof str === "string" && str.indexOf("arn:") === 0 && str.split(":").length >= 6;
};
/**
 * Parse an ARN string into structure with partition, service, region, accountId and resource values
 */
export var parse = function (arn) {
    var segments = arn.split(":");
    if (segments.length < 6 || segments[0] !== "arn")
        throw new Error("Malformed ARN");
    var _a = __read(segments), 
    //Skip "arn" literal
    partition = _a[1], service = _a[2], region = _a[3], accountId = _a[4], resource = _a.slice(5);
    return {
        partition: partition,
        service: service,
        region: region,
        accountId: accountId,
        resource: resource.join(":"),
    };
};
/**
 * Build an ARN with service, partition, region, accountId, and resources strings
 */
export var build = function (arnObject) {
    var _a = arnObject.partition, partition = _a === void 0 ? "aws" : _a, service = arnObject.service, region = arnObject.region, accountId = arnObject.accountId, resource = arnObject.resource;
    if ([service, region, accountId, resource].some(function (segment) { return typeof segment !== "string"; })) {
        throw new Error("Input ARN object is invalid");
    }
    return "arn:" + partition + ":" + service + ":" + region + ":" + accountId + ":" + resource;
};
