import { API } from "aws-amplify";
import axios from "axios";
import MarsNavbar from "components/Navbars/MarsNavbar";
import { getMars } from "graphql/queries";
import React, { useEffect, useState, useRef } from "react";
import DaumPostcode from "react-daum-postcode";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  NavbarBrand,
  Navbar,
} from "reactstrap";

import { getUser } from "utils/data";
import BackArrow from "../../assets/img/back_arrow.png";
import logo from "../../assets/img/mars_logo_black.png";
import PaymentModule from "views/payments/PaymentModule";

// css
import "./styling/addressStyling.css";
import { updateChart } from "utils/data";
import { nanoid } from "nanoid";
import { getChartByID } from "utils/data";
import { insertMars } from "utils/data";
import { slack2accept } from "utils/util";

// import PaymentSelector from "utils/PaymentSelector";

export default function Address(props) {
  const [openAddress, setOpenAddress] = useState(false);
  const [zoneCode, setZoneCode] = useState("");
  const [roadAddress, setRoadAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [pid, setPid] = useState("");
  const [kid, setKid] = useState("");
  const [currentPatient, setCurrentPatient] = useState({});
  const [shamp, setShamp] = useState();
  const [extraShamp, setExtraShamp] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState();
  const location = useLocation();
  const history = useHistory();
  const [total, setTotal] = useState(0);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [deliMsg, setDeliMsg] = useState("");

  // this for popups
  const [paymentPopup, setPaymentPopup] = useState(false);

  // refs
  const inputRef = useRef(null);

  const populatePayResult = (res, paymentType) => {
    let payResult = {
      AMNT:
        paymentType === "SAVED_CARD" ? res.PCD_PAY_TOTAL : res.PCD_PAY_AMOUNT,
      CNAME: res.PCD_PAY_CARDNAME,
      CNUM: res.PCD_PAY_CARDNUM,
      PTIME: res.PCD_PAY_TIME,
      BKEY: res.PCD_PAYER_ID,
    };
    return payResult;
  };
  const handlePaymentResult = async (res, paymentType) => {
    try {
      let payResult = populatePayResult(res, paymentType);
      const chart = await getChartByID(pid);

      let sid = `nesh#${kid}#${nanoid(5)}`;
      const patientDetails = {
        id: pid,
        payResult: JSON.stringify(payResult),
        // ignoreStatus: chart.isPaid === "yes" ? "yes" : "no",
        ignoreStatus: "no",
        isPaid: "yes",
        status: "paid",
        p2status: "joje_ready",
        createdAt: new Date().toISOString(),
        r: "n",
        shamp: shamp || extraShamp,
        nesh_id: shamp || extraShamp ? sid : null,
      };
      await updateChart(patientDetails);

      // if shamp was there update nesh
      if (shamp || extraShamp) {
        const neshDetails = {
          id: sid,
          ignoreStatus: "no",
          cid: currentPatient.id,
          type: "nesh",
          name: user.name,
          phone: user.phone,
          isPaid: "yes",
          addr: roadAddress,
          addr2: detailAddress,
          zip: zoneCode,
          kid: kid,
          total: "17900",
          status: "shamp_paid",
          shamp: shamp || extraShamp,
          survey_type: currentPatient.survey_type,
        };
        await insertMars(neshDetails);
      }

      window.alert(res.PCD_PAY_MSG);
      history.push("/status");
    } catch (error) {
      slack2accept(`[payment-error] ${JSON.stringify(error)}`);
    }
  };

  // props for payemnt module
  const paymentModuleProps = {
    paymentFrom: "patient",
    paymentTotal: currentPatient.total,
    user: user,
    kid: kid,
    pid: pid,
    roadAddress: roadAddress,
    detailAddress: detailAddress,
    zoneCode: zoneCode,
    handlePaymentResult: handlePaymentResult,
  };

  useEffect(() => {
    //get pid, kid, payResult
    let res = location.state;
    setPid(res.pid);
    setKid(res.cp.kid);
    setCurrentPatient(res.cp);

    //if oroginal shamp does not exist check shamp from previous page state.
    if (res.shamp) {
      setExtraShamp(res.shamp);
    }
    if (res.cp.shamp) {
      setShamp(res.cp.shamp);
    }

    const fetchUser = async () => {
      let user = await getUser(`user#${res.cp.kid}`);
      if (user) {
        setUser(user);
        setRoadAddress(user.addr ? user.addr : "");
        setDetailAddress(user.addr2 ? user.addr2 : "");
        setZoneCode(user.zip ? user.zip : "");
        // console.log("user.cards.length: ", user.cards.length)
      }
    };
    fetchUser();
    // console.log(res)
    // rebuild
  }, []);

  const handleBackButton = () => {
    if (openAddress) {
      setOpenAddress(false);
    } else {
      history.push("/status");
    }
  };

  return (
    <div
      className="scrollable"
      style={{
        paddingBottom: "100px",
        background: "white",
        overflowX: "hidden",
      }}
    >
      {/* {isLoading && <MarsLoaderBasic isLoading={isLoading} />} */}
      <div
        style={{
          display: "flex",
          background: "white",
          width: "100%",
          position: "fixed",
          top: 0,
          height: "50px",
          borderBottom: ".5px solid #B3B3B3",
          zIndex: 9999,
        }}
      >
        <a onClick={handleBackButton}>
          <img
            style={{
              width: "18px",
              position: "absolute",
              zIndex: 9999,
              left: 0,
              top: 0,
              bottom: 0,
              margin: "auto 0",
              marginLeft: "26px",
            }}
            src={BackArrow}
          />
        </a>
        <img
          src={logo}
          style={{
            width: "auto",
            height: "20px",
            placeSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>

      <Container
        style={{
          display: isLoading ? "none" : "flex",
          flexDirection: "column",
          padding: "0px 26px 0px 26px",
          // gap: "9px",
          height: "100%",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: 400,
            background: "white",
            // paddingLeft: "26px",
            // paddingRight: "26px",
            paddingTop: "90px",
            height: "100%",
          }}
        >
          <span style={{ fontWeight: 600 }}>
            약 배송을 위해
            <br />
            <b style={{ color: "#6A43B8" }}>배송지</b>를 정확히 입력해 주세요
          </span>
          <br />
          <Row>
            <Col style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ height: "30px" }}></div>
              <FormGroup
                style={{
                  width: 120,
                  float: "left",
                  borderRadius: "0%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#B3B3B3",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      marginTop: zoneCode.length === 0 ? "8px" : "-15px",
                      pointerEvents: "none",
                    }}
                  >
                    우편번호
                  </span>
                </span>
                <Input
                  value={zoneCode}
                  style={{
                    borderRadius: "0px",
                    height: "36px",
                    background: "white",
                    borderBottom: ".5px solid #B3B3B3",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    padding: 0,
                    width: "181px",
                    fontSize: "13px",
                    marginRight: "-60px",
                    textAlign: "left",
                  }}
                  defaultValue=""
                  // placeholder="우편번호"
                  type="submit"
                  onChange={(e) => {
                    // setPhone(e.target.value);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenAddress(true);
                  }}
                ></Input>
                <button
                  onClick={() => {
                    setOpenAddress(true);
                  }}
                  id="address-search-button"
                >
                  우편변호 검색
                </button>
              </FormGroup>
              <FormGroup>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#B3B3B3",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      marginTop: roadAddress.length === 0 ? "25px" : "2px",
                      pointerEvents: "none",
                    }}
                  >
                    주소
                  </span>
                </span>
                <Input
                  style={{
                    borderRadius: "0px",
                    height: "36px",
                    background: "white",
                    marginTop: "17px",
                    borderBottom: ".5px solid #B3B3B3",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    padding: 0,
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  value={roadAddress}
                  // placeholder="주소"
                  type="submit"
                  onChange={(e) => {
                    // setPhone(e.target.value);
                  }}
                  onClick={() => {
                    setOpenAddress(true);
                  }}
                ></Input>
              </FormGroup>
              <div style={{ height: "15px" }}></div>
              <FormGroup>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#B3B3B3",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      marginTop: detailAddress.length === 0 ? "8px" : "-15px",
                      transition: "all 0.2s linear",
                      pointerEvents: "none",
                    }}
                  >
                    {detailAddress.length <= 0
                      ? "상세주소: 예) 104동 201호"
                      : "상세주소"}
                  </span>
                </span>
                <Input
                  style={{
                    borderRadius: "0px",
                    height: "36px",
                    background: "white",
                    borderBottom: ".5px solid #B3B3B3",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    padding: 0,
                    fontSize: "13px",
                    textAlign: "left",
                  }}
                  value={detailAddress}
                  defaultValue=""
                  // placeholder="상세주소: 예)304동 1104호"
                  type="text"
                  onChange={(e) => {
                    setDetailAddress(e.target.value);
                  }}
                ></Input>
              </FormGroup>
              <div style={{ height: "15px" }}></div>
            </Col>
          </Row>
        </div>
        <div className="mars-divider-gray"></div>
        {/* DELI REQUEST / MSG */}
        <div
          style={{
            fontSize: "18px",
            paddingTop: "21px",
            marginTop: "9px",
            fontWeight: 400,
            background: "white",
          }}
        >
          배송 요청사항
          <FormGroup>
            <Input
              value={deliMsg}
              style={{
                borderRadius: "0px",
                background: "white",
                width: "322px",
                fontSize: "13px",
                borderBottom: ".5px solid #B3B3B3",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                padding: "0 0 10px 0",
                marginTop: "18px",
              }}
              defaultValue=""
              placeholder="배송시 요청사항이 있으시면 적어주세요."
              type="text"
              onChange={(e) => {
                setDeliMsg(e.target.value);
              }}
            ></Input>
          </FormGroup>
        </div>

        <div className="mars-divider-gray"></div>

        {!openAddress && <PaymentModule props={paymentModuleProps} />}

        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            margin: 0,
            height: "53px",
            zIndex: 99,
          }}
          id="address-confirm-button"
        ></div>
        <div className="button-hider"></div>
      </Container>

      {/* {isLoading && <PaymentLoader />} */}
      {openAddress ? (
        <div className="address-popup">
          <DaumPostcode
            style={{ height: "100vh" }}
            onComplete={(data) => {
              let fullAddress = data.address;
              let extraAddress = "";

              if (data.addressType === "R") {
                if (data.bname !== "") {
                  extraAddress += data.bname;
                }
                if (data.buildingName !== "") {
                  extraAddress +=
                    extraAddress !== ""
                      ? `, ${data.buildingName}`
                      : data.buildingName;
                }
                fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
              }
              setRoadAddress(fullAddress);
              setZoneCode(data.zonecode);
              setOpenAddress(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
