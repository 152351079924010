import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

// import amplify
import { Amplify, AWSKinesisFirehoseProvider } from "aws-amplify";
import awsconfig from "./aws-exports";

import MyHistory from "views/myhistory/myhistory";

import FaqPage from "views/faq/faq-page";

import KakaoLogin from "views/login/KakaoLogin";
import MedicationPage from "views/medication/medication-page";
import MedicationDetail from "views/medication/medication-detail";
import AutoRefillMessage from "views/autobot/AutoRefillMessage";

import ExogeneSubmit from "views/exogene/ExogeneSubmit";
import LandingPage5 from "views/landing5/LandingPage5";
import MyPageSave from "views/MyPage/MyPageSave";
import Survey from "views/survey/Survey";
import PharmaPage2 from "views/pharma2/pharma-page2";
import ExogeneReservation2 from "views/exogene/ExogeneReservation2";
import MyInfoRepeat from "views/survey/repeat/MyInfoRepeat";
import RepeatApplicationV17 from "views/survey/repeat/RepeatApplicationV17";

import Accept from "views/operation/Accept";
// import { Analytics } from 'aws-amplify';
import { Analytics } from "aws-amplify";
import Octopus from "views/octopus/Octopus";
import PhotoEventLanding from "views/events/PhotoEvent/PhotoEventLanding";
import PhotoEventDone from "views/events/PhotoEvent/PhotoEventDone";

// Feedback page components
import Feedback from "views/feedback/FeedbackMain";
import FeedbackConfirm from "views/feedback/FeedbackConfirm";
import FeedbackExpire from "views/feedback/FeedbackExpire";

// Dna Event
import DnaEventMain from "views/dna/dnaEvent/DnaEventMain";
import DnaEventLanding from "views/dna/dnaEvent/DnaEventLanding";
import DnaEventOver from "views/dna/dnaEvent/DnaEventOver";
import DnaExpired from "views/dna/dnaEvent/DnaExpired";

// New DNA
import ExogeneContainer from "views/dna/exogeneNew/ExogeneContainer";

// Error Handling
import HTML404 from "views/errorHandling/HTML404";
import PaymentCancel from "views/errorHandling/PaymentCancel";
import NoLoad from "views/errorHandling/NoLoad";
import Mars from "views/testing/Mars";
// import { Authenticator } from "@aws-amplify/ui-react";

// loading test

import PaymentLoader from "views/status/status-components/PaymentLoader";
import CardConfirm from "views/status/status-components/CardConfirm";

// testing
import SurveyIntro from "views/survey/SurveyIntro";
import SurveyBuffer from "views/survey/SurveyBuffer";
import MarsLoaderNew from "views/components/MarsLoaderNew";
import StatusNoRecord from "views/status/StatusNoRecord";
import SchedulerPage from "views/scheduler/schedulerPage";
import OperationPage from "views/operation/OperationPage";
import Address from "views/status/Address";
import Status from "views/status/Status";
import DoctorPage from "views/doctor/DoctorPage";
import CareMessageShedding from "views/pretotype/care-message/CareMessageShedding";

// My Profile || My Page
import MyPageNew from "views/pretotype/my-page/MyPageNew";
import UserInfo from "views/pretotype/my-page/components/UserInfo";
import UserAddress from "views/pretotype/my-page/components/UserAddress";
import UserCard from "views/pretotype/my-page/components/UserCard";
import UserHistory from "views/pretotype/my-page/components/UserHistory";
import UserPhotos from "views/pretotype/my-page/components/UserPhotos";
import VenusAdminPage from "views/venus-admin/VenusAdminPage";
import OperationPageVenus from "views/operation-venus/OperationPageVenus";
import PauseNoticePage from "views/pause/PauseNoticePage";

// new landing import
// import NewLanding from 'views/landing6/components/containers/LandingContainer'
// import Partnership from 'views/landing6/components/pages/Partner'
// import PartnershipConfirm from 'views/landing6/components/pages/PartnerConfirm'

Analytics.addPluggable(new AWSKinesisFirehoseProvider());

Analytics.configure({
  AWSKinesisFirehose: {
    // OPTIONAL -  Amazon Kinesis Firehose service region
    region: "ap-northeast-2",
    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1,
    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 1,
    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 1000, // 1s
    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 2,
  },
});

Amplify.configure(awsconfig);

ReactDOM.render(
  // <Authenticator.Provider>
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            window.location.href = "https://marshealth.io";
            return null;
          }}
        />

        <Route path="/pause_notice" render={(props) => <PauseNoticePage />} />

        {/* Test Links */}
        <Route
          path="/paymentLoaderTest"
          render={(props) => <PaymentLoader />}
        />
        <Route path="/cardConfirmTest" render={(props) => <CardConfirm />} />
        <Route path="/introTest" render={(props) => <SurveyIntro />} />
        <Route path="/introBuffer" render={(props) => <SurveyBuffer />} />
        <Route path="/loaderTest" render={(props) => <MarsLoaderNew />} />
        <Route path="/noRecord" render={(props) => <StatusNoRecord />} />


        {/* <Route path="/main" render={(props) => <LandingPage5 {...props} />} />
        <Route
          path="/landing"
          render={(props) => <LandingPage5 {...props} />}
        /> */}
        {/* <Route path='/newLanding' render={()=> <NewLanding/>} />
        <Route path='/partnership' render={()=> <Partnership/>} />
        <Route path='/partnershipConfirm' render={()=> <PartnershipConfirm/>} /> */}

        <Route
          path="/blind001"
          render={(props) => <LandingPage5 {...props} />}
        />
        {/* route for naver blog landing*/}
        <Route
          path="/landing_blog001"
          render={(props) => <LandingPage5 {...props} />}
        />

        <Route path="/start" render={(props) => <LandingPage5 {...props} />} />
        <Route
          path="/offline001"
          render={(props) => <LandingPage5 {...props} />}
        />

        <Route path="/venus" render={(props) => <VenusAdminPage {...props} />} />

        <Route path="/klogin" render={(props) => <KakaoLogin {...props} />} />

        {/* ********************************************************************************************************************************* */}
        {/* Doctor Page / 의사-닥터 페이지 */}
        {/* ********************************************************************************************************************************* */}

        <Route path="/doctor" render={(props) => <DoctorPage {...props} />} />

        {/* ********************************************************************************************************************************* */}
        {/* Operation Page / 오퍼레이션 페이지 */}
        {/* ********************************************************************************************************************************* */}

        <Route path="/operation" render={(props) => <OperationPage />} />
        <Route path="/accept" render={(props) => <Accept {...props} />} />

        <Route path="/operation-venus" render={(props) => <OperationPageVenus />} />

        {/* ********************************************************************************************************************************* */}
        {/* Survey/Initial  / 진료-초진 페이지 */}
        {/* ********************************************************************************************************************************* */}

        {/* <Route path="/survey" render={(props) => <Survey {...props} />} />
        <Route path="/survey2" render={(props) => <Survey {...props} />} />
        <Route path="/survey16" render={(props) => <Survey {...props} />} /> */}

        <Route path="/survey" render={(props) => <PauseNoticePage {...props} />} />
        <Route path="/survey2" render={(props) => <PauseNoticePage {...props} />} />
        <Route path="/survey16" render={(props) => <PauseNoticePage {...props} />} />

        {/* ********************************************************************************************************************************* */}
        {/* Repeat Operation / 리필페이지 */}
        {/* ********************************************************************************************************************************* */}

        {/* <Route
          path="/repeat"
          render={(props) => <RepeatApplicationV17 {...props} />}
        /> */}

        <Route
          path="/repeat"
          render={(props) => <PauseNoticePage {...props} />}
        />

        <Route
          path="/scheduler"
          render={(props) => <SchedulerPage {...props} />}
        />

        <Route
          path="/myinfo-repeat"
          render={(props) => <MyInfoRepeat {...props} />}
        />

        <Route path="/status" render={(props) => <Status {...props} />} />

        <Route path="/myhistory" render={(props) => <MyHistory {...props} />} />
        <Route path="/address" render={(props) => <Address {...props} />} />

        <Route path="/pharma" render={(props) => <PharmaPage2 {...props} />} />

        {/* <Route path="/faq" render={(props) => <FaqPage {...props} />} /> */}
        <Route path="/faq" render={(props) => <PauseNoticePage {...props} />} />
        

        <Route
          path="/medication"
          render={(props) => <MedicationPage {...props} />}
        />
        <Route
          path="/medication-detail"
          render={(props) => <MedicationDetail {...props} />}
        />

        {/* <Route path="/dbt" render={(props) => <DBT {...props} />} /> */}
        <Route
          path="/arm"
          render={(props) => <AutoRefillMessage {...props} />}
        />

        {/* exogene */}
        <Route
          path="/exogene"
          render={(props) => <ExogeneContainer {...props} />}
        />
        <Route
          path="/exogene-reservation"
          render={(props) => <ExogeneReservation2 {...props} />}
        />
        <Route
          path="/exogene-submit"
          render={(props) => <ExogeneSubmit {...props} />}
        />

        {/* mars testing */}
        {/* <Route path="/mars" render={(props) => <Mars {...props} />} /> */}

        {/* octopus */}
        {/* <Route path="/octopus" render={(props) => <Octopus {...props} />} /> */}

        {/* photo event */}
        {/* <Route
          path="/event1"
          render={(props) => <PhotoEventLanding {...props} />}
        />
        <Route
          path="/event1_done"
          render={(props) => <PhotoEventDone {...props} />}
        /> */}

        {/* photo event - shampoo Nesh version */}
        {/* <Route
          path="/event2"
          render={(props) => <PhotoEventLanding2 {...props} />}
        />
        <Route
          path="/event2_address"
          render={(props) => <PhotoEventAddress {...props} />}
        />
        <Route
          path="/event2_done"
          render={(props) => <PhotoEventDone2 {...props} />}
        /> */}

        {/* MyPage */}
        <Route path="/mypage" render={(props) => <MyPageNew {...props} />} />
        <Route
          path="/mypagesave"
          render={(props) => <MyPageSave {...props} />}
        />

        {/* ********************************************************************************************************************************* */}
        {/* FEEDback PAGE / 피드백 페이지 */}
        {/* ********************************************************************************************************************************* */}
        <Route
          path="/userFeedback"
          render={(props) => <Feedback {...props} />}
        />
        <Route
          path="/feedbackConfirm"
          render={(props) => <FeedbackConfirm {...props} />}
        />
        <Route
          path="/feedbackExpire"
          render={(props) => <FeedbackExpire {...props} />}
        />

        {/* ********************************************************************************************************************************* */}
        {/* DNA Page */}
        {/* ********************************************************************************************************************************* */}
        <Route
          path="/dnaEvent"
          render={(props) => <DnaEventMain {...props} />}
        />
        <Route
          path="/dnaevent01"
          render={(props) => <DnaEventLanding {...props} />}
        />
        <Route
          path="/dnaOver"
          render={(props) => <DnaEventOver {...props} />}
        />
        <Route
          path="/dnaExpired"
          render={(props) => <DnaExpired {...props} />}
        />
        <Route
          path="/exogeneNew"
          render={(props) => <ExogeneContainer {...props} />}
        />
        <Route
          path="/care/shedding"
          render={(props) => <CareMessageShedding {...props} />}
        />

        {/* ********************************************************************************************************************************* */}
        {/* Profile Page */}
        {/* ********************************************************************************************************************************* */}

        <Route path="/myPageNew" render={(props) => <MyPageNew {...props} />} />
        <Route path="/user_info" render={(props) => <UserInfo {...props} />} />
        <Route
          path="/user_address"
          render={(props) => <UserAddress {...props} />}
        />
        <Route path="/user_card" render={(props) => <UserCard {...props} />} />
        <Route path="/user_history" render={(props) => <UserHistory {...props} />} />
        <Route path="/user_photos" render={(props) => <UserPhotos {...props} />} />



        {/* ********************************************************************************************************************************* */}
        {/* ERROR HANDLING */}
        {/* ********************************************************************************************************************************* */}
        <Route path="/404" render={(props) => <HTML404 {...props} />} />
        <Route path="/Error" render={(props) => <NoLoad {...props} />} />
        <Route
          path="/cancelled"
          render={(props) => <PaymentCancel {...props} />}
        />
        {/* <Redirect from="/" to="/index" /> */}
        <Redirect from="/*" to="/" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
