import { Button } from "reactstrap";
// const { Button } = require("reactstrap");

function StatusButton(props) {
  return (
    <>
      <div
        className="justify-content-center"
        style={{
          width: "100%",
        }}
      >
        <Button
          style={{
            width: "100%",
            background: "black",
          }}
          disabled={false}
          onClick={() => {
            props.clickDone();
          }}
        >
          <label style={{ paddingTop: "6px" }}>
            <b>{props.children}</b>
          </label>
        </Button>
      </div>
    </>
  );
}
export default StatusButton;
