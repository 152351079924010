import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { getMars } from "graphql/queries";
import { marsByCDate } from "graphql/queries";
import { marsByKid } from "graphql/queries";
import * as queries from "graphql/queries";
import { getMarsUtil } from "graphql/queries";

export const getAllUsers = async (sortDir) => {
  let st = new Date().getTime();
  let users = [];
  let nextToken = null;

  let filter = {
  };

  try {
    do {
      const res = await API.graphql({
        query: marsByCDate,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          type: "user",
          filter: filter,
          sortDirection: sortDir,
          limit: 1000,
          nextToken: nextToken,
        },
      });

      nextToken = res.data.marsByCDate.nextToken;

      if (res.data) {
        users.push(...res.data.marsByCDate.items);
      }
    } while (nextToken);

    let et = new Date().getTime();
    console.log("all user duration: ", et - st);
    return users;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUsersWithName = async (sortDir) => {
  let st = new Date().getTime();
  let users = [];
  let nextToken = null;

  let filter = {
    name: {
      attributeExists: true, // filter priority = 1
      // eq: "2030105474"
    },
  };

  try {
    do {
      const res = await API.graphql({
        query: marsByCDate,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          type: "user",
          filter: filter,
          sortDirection: sortDir,
          limit: 1000,
          nextToken: nextToken,
        },
      });

      nextToken = res.data.marsByCDate.nextToken;

      if (res.data) {
        users.push(...res.data.marsByCDate.items);
      }
    } while (nextToken);

    let et = new Date().getTime();
    console.log("all user duration: ", et - st);
    return users;
  } catch (error) {
    console.log(error);
  }
};


export const createUserWithKid = async (kid) => {
  const userDetails = {
    id: `user#${kid}`,
    kid: kid,
    type: "user",
  };

  try {
    const newUser = await API.graphql({
      query: mutations.createMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: userDetails },
    });
    if (newUser) {
      // mars-analytics: user_created
      const analyticsInfo = {
        kid: kid,
        action: 'user_created',
        timestamp: new Date().toISOString()
      }
      await insertMarsAnalytics(analyticsInfo);
      return newUser;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async (id) => {
  try {
    // console.log("beginning get user");
    const user = await API.graphql({
      query: getMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { id: id },
    });
    // console.log("user inside getuser: ", user);
    if (user) {
      return user.data.getMars;
    }
  } catch (error) {
    console.log("get user error: ", error);
  }
};

export const updateUser = async (userInfo) => {
  try {
    const user = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: userInfo },
    });

    if (user) {
      return user;
    }
  } catch (error) {
    console.error("update user error: ", error);
  }
};

// charts //
export const getAllCharts = async (sortDir, filter, limit) => {
  let st = new Date().getTime();
  let et;
  let charts = [];
  let nextToken = null;
  if (!limit) {
    limit = 1000;
  }

  try {
    do {
      const res = await API.graphql({
        query: marsByCDate,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          type: "chart",
          sortDirection: sortDir,
          limit: limit,
          nextToken: nextToken,
          filter: filter,
        },
      });

      nextToken = res.data.marsByCDate.nextToken;
      // console.log("nextToekn:", nextToken);
      if (res.data) {
        charts.push(...res.data.marsByCDate.items);
      }
    } while (nextToken && limit === 1000);

    et = new Date().getTime();
    let dt = et - st;
    console.log("td:", dt);

    return charts;
  } catch (error) {
    console.log(error);
  }
};

export const getAllChartsWithUserInfo = async (sortDir, filter, limit) => {
  let cs = await getAllCharts(sortDir, filter, limit);
  let users = await getAllUsersWithName(sortDir);

  let userHash = {};
  for (let user of users) {
    userHash[user.kid] = user;
  }

  // poppulate chart with user info.
  for (let c of cs) {
    let user = userHash[c.kid];
    //console.log(user);
    c.name = user.name;
    c.phone = user.phone;
    c.ssn = user.ssn;
    c.addr = user.addr;
    c.addr2 = user.addr2;
    c.zip = user.zip;
  }
  return cs;
};

export const getAllNeshesWithUserInfo = async (sortDir) => {
  let cs = await getAllNeshes(sortDir);
  let users = await getAllUsersWithName(sortDir);

  let userHash = {};
  for (let user of users) {
    userHash[user.kid] = user;
  }

  // poppulate chart with user info.
  for (let c of cs) {
    let user = userHash[c.kid];
    //console.log(user);
    c.name = user.name;
    c.phone = user.phone;
    c.ssn = user.ssn;
    c.addr = user.addr;
    c.addr2 = user.addr2;
    c.zip = user.zip;
  }
  return cs;
};

export const getAllExogenesWithUserInfo = async (sortDir) => {
  let cs = await getAllExogenes(sortDir);
  let users = await getAllUsersWithName(sortDir);

  let userHash = {};
  for (let user of users) {
    userHash[user.kid] = user;
  }

  // poppulate chart with user info.
  for (let c of cs) {
    let user = userHash[c.kid];

    if (user && user.name && user.addr) {
      c.name = user.name;
      c.phone = user.phone;
      c.ssn = user.ssn;
      c.addr = user.addr;
      c.addr2 = user.addr2;
      c.zip = user.zip;
    }
  }
  return cs;
};

export const insertMars = async (marsInfo) => {
  try {
    const newMars = await API.graphql({
      query: mutations.createMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: marsInfo },
    });
    if (newMars) {
      return newMars;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMarsByKid = async (kid) => {
  try {
    const mars = await API.graphql({
      query: marsByKid,
      variables: { kid: kid },
    });
    if (mars) {
      return mars.data.marsByKid.items;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getChartsByKid = async (kid) => {
  try {
    const mars = await API.graphql({
      query: marsByKid,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { kid: kid },
    });
    if (mars) {
      let marsArr = mars.data.marsByKid.items;
      let charts = [];
      if (marsArr.length > 0) {
        for (let m of marsArr) {
          if (m.type === "chart") {
            charts.push(m);
          }
        }
        return charts;
      }
      return mars.data.marsByKid.items;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getChartByID = async (id) => {
  try {
    const mars = await API.graphql({
      query: queries.getMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { id: id },
    });
    if (mars) {
      return mars.data.getMars;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateChart = async (chartInfo) => {
  try {
    const updatedChart = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: chartInfo },
    });

    return updatedChart;
  } catch (error) {
    console.error(error);
  }
};

// exogenes
export const getAllExogenes = async (sortDir) => {
  try {
    const exos = await API.graphql({
      query: marsByCDate,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { type: "exogene", sortDirection: sortDir, limit: 1000 },
    });

    if (exos.data) {
      return exos.data.marsByCDate.items;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateMars = async (marsInfo) => {
  try {
    const updatedMars = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: marsInfo },
    });

    return updatedMars;
  } catch (error) {
    console.error(error);
  }
};

// neshes
export const getAllNeshes = async (sortDir) => {
  try {
    const users = await API.graphql({
      query: marsByCDate,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { type: "nesh", sortDirection: sortDir, limit: 1000 },
    });

    if (users.data) {
      return users.data.marsByCDate.items;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMarsUtils = async (id) => {
  try {
    const newUtils = await API.graphql({
      query: getMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { id: id },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};

export const insertMarsUtils = async (utilInfo) => {
  try {
    const newUtils = await API.graphql({
      query: mutations.createMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: utilInfo },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateMarsUtils = async (utilInfo) => {
  try {
    const newUtils = await API.graphql({
      query: mutations.updateMarsUtil,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: utilInfo },
    });
    if (newUtils) {
      return newUtils;
    }
  } catch (error) {
    console.log(error);
  }
};

export const insertMarsAnalytics = async (analyticsInfo) => {
  try {
    const newAnalytics = await API.graphql({
      query: mutations.createMarsAnalytics,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: analyticsInfo },
    });
    if (newAnalytics) {
      return newAnalytics;
    }
  } catch (error) {
    console.log(error);
  }
};



// photo event only
export const insertPhotoEvent = async (eventInfo) => {
  try {
    const newEvent = await API.graphql({
      query: mutations.createPhotoEvent,
      // authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: { input: eventInfo },
    });
    if (newEvent) {
      return newEvent;
    }
  } catch (error) {
    console.log(error);
  }
};


// Venus
//
export async function getChartsByPlatform(platform, filter, sortDir) {
  try {
    const res = await API.graphql({
      query: queries.chartsByYeyakTime,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        platform: platform,
        filter: filter,
        sortDirection: sortDir,
        limit: 1000
      }
    });
    return res.data.chartsByYeyakTime.items

  } catch (error) {
    console.log(error);
  }
}

export const updateVenusChart = async (chartInfo) => {
  try {
    const updatedChart = await API.graphql({
      query: mutations.updateChart,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: chartInfo },
    });

    return updatedChart;
  } catch (error) {
    console.error(error);
  }
};

export async function getOrderShipmentsByProcessorID(processorID) {
  let sortDir = "DESC"
  try {
    const res = await API.graphql({
      query: queries.orderShipmentByUpdateTime,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        processor_id: processorID,
        sortDirection: sortDir,
        limit: 1000
      }
    });
    return res.data.orderShipmentByUpdateTime.items

  } catch (error) {
    console.log(error);
  }
}

export async function getOrderShipmentsByProcessor(processor) {
  let sortDir = "DESC"
  try {
    const res = await API.graphql({
      query: queries.orderShipmentByProcessor,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        processor: processor,
        sortDirection: sortDir,
        limit: 1000
      }
    });
    // console.log("res: ", res);
    return res.data.orderShipmentByProcessor.items

  } catch (error) {
    console.log(error);
  }
}

export async function updateVenusOrderShipmentDB(info) {
  try {
    const updated = await API.graphql({
      query: mutations.updateOrderShipment,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: info },
    });

    return updated.data.updateOrderShipment;
  } catch (error) {
    console.error(error);
  }
}