import React, { useState, useEffect } from "react";
import ExogeneCarousel from "../components/ExogeneCarousel";

// css
import "./exogenePageStyling.css";

export default function ExogeneFaq() {
  return (
    <div className="exogene-faq-container">
      <div className="exogene-content-wrapper">
        <span className="exogene-title-med">FAQ</span>
        <div className="exogene-carousel-container">
          <ExogeneCarousel></ExogeneCarousel>
        </div>
      </div>
    </div>
  );
}
