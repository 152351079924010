import MarsNavbar from "components/Navbars/MarsNavbar";
import { useState } from "react";
import { Col, Row, Container, Card, CardBody } from "reactstrap";

// const { Col, Row, Container, Card, CardBody } = require("reactstrap");

function MyHistory() {
  const [step, setStep] = useState(4);
  const [answers, setAnswers] = useState([]);

  const clickNext = () => {
    setStep(step + 1);
  };

  const clickDone = async () => {};

  const updateAnswer = (answer) => {
    let temp = [...answers];
    temp[answer.id - 1] = answer;
    setAnswers(temp);
    // console.log(answers);
  };

  return (
    <>
      <MarsNavbar></MarsNavbar>
      <div>
        <Container>
          <div style={{ paddingTop: "50px" }}>
            <h4>
              <b>진료 히스토리</b>
            </h4>
          </div>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto" md="5" xs="12">
              <Card style={{ marginTop: 0 }}>
                <CardBody>hi there</CardBody>
              </Card>
            </Col>
          </Row>

          <Row
            className="justify-content-center"
            style={{ position: "fixed", bottom: 0, width: "100%" }}
          >
            <Col className="ml-auto mr-auto" md="12" xs="12"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MyHistory;
