import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { updateUser } from "utils/data";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import Lottie from "lottie-react";
import { getENV } from "utils/util";
import { maskSSN } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { API } from "aws-amplify";

// assets
import profileLottie from "../assets/profileCard.json";
import arrowBack from "../assets/arrowBack.png";
import Hide from "../../../MyPage/mypageAssets/hide.png";
import Show from "../../../MyPage/mypageAssets/show.png";

// css
import "../styling/componentStyling.css";

export default function UserInfo(props) {
  const { userInfo, userID, kid } = props.location.state;
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [ssn, setSSN] = useState();
  const [ssnFirst, setSSNFirst] = useState();
  const [ssnLast, setSSNLast] = useState();
  const [ID, setID] = useState(); // user.ID
  const [showImage, setShowImage] = useState(Hide);
  const ssnFirstRef = useRef(null);
  const ssnLastRef = useRef(null);
  const contactRef = useRef(null);
  const history = useHistory();
  const regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  // to check for state change within component
  const nameRef = useRef(null);
  const ssnRefFirst = useRef(null);
  const ssnRefLast = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    // TODO move from array -> user obj
    try {
      setID(userID);
      setName(userInfo[0]);
      setPhone(userInfo[1]["phone"]);
      setSSN(userInfo[2]["ssn"]);
      setSSNFirst(userInfo[2]["ssn"].substring(0, 6));
      setSSNLast(userInfo[2]["ssn"].substring(7, 14));
      nameRef.current = name;
      ssnRefFirst.current = ssnFirst;
      ssnRefLast.current = ssnLast;
      phoneRef.current = phone;
    } catch (error) {
      console.log("unable to retrieve user info");
    }
  }, []);

  useEffect(() => {
    try {
      if (ssnFirstRef.current === document.activeElement) {
        if (ssnFirstRef.current.value.length >= 6) {
          ssnLastRef.current.focus();
        }
        // if (ssnLastRef.current.value.length >= 7) {
        //   contactRef.current.focus();
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }, [ssnFirst, ssnLast]);

  const stateChanged = () => {
    if (
      nameRef.current === name &&
      ssnRefFirst.current === ssnFirst &&
      ssnRefLast.current === ssnLast &&
      phoneRef.current === phone
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateUser = async () => {
    var ssn = `${ssnFirst}-${ssnLast}`;
    const userInfo = {
      id: ID,
      name: name,
      phone: phone,
      ssn: ssn,
    };
    const res = await updateUser(userInfo);
    if (res) {
      alert("정보가 수정되었습니다");
      history.push("/myPageNew");
    }
  };

  const showDisplayPhone = (phone) => {
    var output = phone;
    return output.replace(
      /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
      "$1$2$3-$4$5$6$7-$8$9$10$11"
    );
  };

  const handleInvalidInput = () => {
    // break loop pls
    while (ssnFirst && ssnLast && name && phone) {
      if (
        ssnFirst.length >= 6 &&
        ssnLast.length >= 7 &&
        phone.length >= 11 &&
        name.length >= 2
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div className="user-container" style={{ background: "white" }}>
      <MarsNavBarMain></MarsNavBarMain>
      <button
        className="profile-return-button"
        onClick={() => {
          history.push("/myPageNew");
        }}
      >
        <img src={arrowBack} />
      </button>
      <div className="user-info-display">
        <div className="user-info-title">
          <span>나의정보</span>
          <Lottie
            animationData={profileLottie}
            autoPlay={true}
            id="profile-lottie"
          ></Lottie>
        </div>
      </div>
      <div className="user-data-form">
        <form className="user-data-input" style={{ width: "50%" }}>
          <span style={{ marginTop: name ? "-25px" : "" }}>이름</span>
          <input
            type="text"
            value={name ? name : ""}
            onChange={(e) => {
              e.stopPropagation();
              setName(e.target.value);
            }}
          ></input>
          {name && regex.test(name) ? (
            <span style={{ display: "flex" }} id="phone-alert">
              ⚠️ 이름을 확인해 주세요!{" "}
            </span>
          ) : (
            <></>
          )}
        </form>
        <div style={{ display: "flex", borderBottom: ".7px solid #8c8c8c" }}>
          <form className="user-data-input">
            <span style={{ marginTop: ssnFirst ? "-25px" : "" }}>주민번호</span>
            <input
              style={{ width: "50%", border: "none" }}
              ref={ssnFirstRef}
              type="text"
              pattern="\d*"
              inputMode="numeric"
              value={ssnFirst ? ssnFirst : ""}
              maxLength={6}
              onChange={(e) => {
                e.stopPropagation();
                setSSNFirst(e.target.value);
              }}
            ></input>
            <span>-</span>
          </form>
          <form className="user-data-input">
            <input
              style={{ border: "none", marginLeft: "-35%" }}
              ref={ssnLastRef}
              type={showImage === Hide ? "password" : "text"}
              pattern="\d*"
              inputMode="numeric"
              value={ssnLast ? ssnLast : ""}
              maxLength={7}
              onChange={(e) => {
                e.stopPropagation();
                setSSNLast(e.target.value);
              }}
            ></input>
          </form>
          <div
            className="show-ssn"
            onClick={() => {
              showImage === Show ? setShowImage(Hide) : setShowImage(Show);
            }}
          >
            <img src={showImage} />
          </div>
        </div>
        <form className="user-data-input">
          <span style={{ marginTop: phone ? "-25px" : "" }}>연락처</span>
          <input
            ref={contactRef}
            type="text"
            maxLength={11}
            pattern="\d*"
            inputMode="numeric"
            value={phone ? showDisplayPhone(phone) : ""}
            onChange={(e) => {
              e.stopPropagation();
              setPhone(e.target.value.replaceAll("-", ""));
            }}
          ></input>
          {phone && phone.length < 11 && phone.length > 0 ? (
            <span style={{ display: "flex" }} id="phone-alert">
              ⚠️ 연락처를 올바르게 입력해 주세요!{" "}
            </span>
          ) : (
            <></>
          )}
        </form>
      </div>
      <div className="user-data-button">
        <button
          onClick={() => {
            if (!handleInvalidInput()) {
              alert("정보를 확인해주세요");
              return;
            } else {
              if (window.confirm("입력하신 내용을 저장하시겠어요?")) {
                handleUpdateUser();
              }
            }
            return;
          }}
        >
          저장하기
        </button>
      </div>
    </div>
  );
}
