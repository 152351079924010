import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { updateUser } from "utils/data";
import { getUser } from "utils/data";

// images
import BackArrow from "../../assets/img/back_arrow.png";
import Show from "./mypageAssets/show.png";
import Hide from "./mypageAssets/hide.png";

// css
import "./myPageStyles.css";
import { getENV } from "utils/util";
import { maskSSN } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { API } from "aws-amplify";

function MyPageSave(props) {
  const history = useHistory();
  const location = useLocation();

  const [kid, setKid] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [ssn, setSSN] = useState("");
  const [addr, setAddr] = useState("");
  const [user, setUser] = useState();

  const [ssnFirst, setSsnFirst] = useState("");
  const [ssnLast, setSsnLast] = useState("");
  const [showLast, setShowLast] = useState(false);

  const [stateChanged, setStateChanged] = useState(false);
  const ssnRef = useRef(null);

  //image
  const [eyeImage, setEyeImage] = useState(Hide);

  useEffect(() => {
    const asyncWrapper = async () => {
      // setIsLoading(true);
      var KakaoID;

      // check if loged-in if not redirect to klogin.
      //
      if (!location.kid) {
        history.push({
          pathname: "/klogin",
          state: "mypagesave",
        });
      } else {
        // console.log("kid: " , location.kid);
        setKid(location.kid);
        // console.log(location.kid);
      }
      // retreive userinfo using locaiton.kid
      //
      const user = await getUser(`user#${location.kid}`);
      setUser(user);
      setName(user.name);
      setPhone(user.phone);
      setSSN(user.ssn);

      setSsnFirst(user.ssn.slice(0, 6));
      setSsnLast(user.ssn.slice(7, 14));
      //   setCensored(user.ssn.slice(7, 14));
    };
    asyncWrapper();
  }, []);

  const handleBackButton = () => {
    if (ssnFirst.length < 6 || ssnLast.length < 7) {
      alert("주민번호를 확인해주세요.");
      return;
    }

    if (!name || name.replace(/\s/g, "") == "") {
      alert("이름을 확인해주세요.");
      return;
    }

    if (stateChanged) {
      var answer = window.confirm("변경된 정보를 저장하시겠어요?");
      if (answer) {
        handleSubmit();
      } else {
        // history.push("/status");
        return;
      }
    }
    history.push("/status");
    // navigate(-1)
  };
  const handleSsnFirst = (ssn) => {
    const limit = 6;
    setSsnFirst(ssn.target.value.slice(0, limit));
    setStateChanged(true);
  };

  const handleSsnLast = (ssn) => {
    const limit = 7;
    setSsnLast(ssn.target.value.slice(0, limit));
    setStateChanged(true);
  };

  const handleSubmit = async () => {
    const userInfo = {
      id: user.id,
      name: name,
      phone: phone,
      // ssn: ssn,
      ssn: ssnFirst + "-" + ssnLast,
      // addr: addr,
    };

    if (
      ssnFirst.length < 6 ||
      ssnLast.length < 7 ||
      hasSpecialChar(ssnFirst) ||
      hasSpecialChar(ssnLast)
    ) {
      alert("주민번호를 확인해주세요.");
      return;
    }

    if (!name || name.replace(/\s/g, "") == "") {
      alert("이름을 확인해주세요.");
      return;
    }

    const res = await updateUser(userInfo);
    if (res) {
      let users = [{ type: "appUserId", id: user.kid }];
      let eventName =
        "event_user_info_changed" + (getENV() !== "prod" ? "_dev" : "");
      let eventData = {
        msg: `${name}님의 정보가 변경 되었어요.\n\n[고객정보]\n이름:\t${name}\n주민번호:\t${maskSSN(
          ssn
        )}\n연락처:\t${parsePhoneNumber(phone)}`,
      };
      let res = await API.post("marsrest", "/kakao", {
        body: { users, eventName, eventData },
      });

      history.push({
        //   pathname: "/mypage",
        pathname: "/status",
        kid: kid,
      });
    }
  };

  const toggleSsn = () => {
    setShowLast(!showLast);
    if (eyeImage === Show) {
      setEyeImage(Hide);
    } else {
      setEyeImage(Show);
    }
  };

  const hasSpecialChar = (num) => {
    var regex = /[!@#$%^&*()=+_;'"<>,]/;
    return regex.test(num);
  };

  return (
    <>
      <div className="mypage-save-container">
        <div className="mypage-nav">
          <div style={{ margin: "0 auto", display: "flex" }}>
            <a onClick={handleBackButton}>
              <img style={{ width: "18px" }} src={BackArrow} />
            </a>
            <p id="myinfo-header-nav">나의정보</p>
          </div>
        </div>

        <div className="mypage-save-info-container">
          <div
            className="info-header-title"
            style={{ paddingBottom: "40px", marginTop: "55px" }}
          >
            나의정보 수정하기
          </div>
          <div className="mypage-input-container">
            <div>
              <span
                id="mypage-input-text"
                style={{ marginTop: name ? "-25px" : "-2px" }}
              >
                이름
              </span>
              <input
                type="input"
                value={name}
                className="mypage-input"
                onChange={(e) => {
                  setName(e.target.value);
                  setStateChanged(true);
                }}
              />
            </div>

            <div style={{ position: "relative" }}>
              {/* 주민번호 압자리 */}
              <span
                id="mypage-input-text"
                style={{ marginTop: ssnFirst ? "-25px" : "-2px" }}
              >
                주민번호
              </span>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "100%",
                }}
              >
                <input
                  type="number"
                  value={ssnFirst}
                  className="mypage-input"
                  onChange={handleSsnFirst}
                  maxLength="6"
                  pattern="[0-9]*"
                  style={{ marginRight: ".2rem" }}
                />
                <span>{" _ "}</span>
                {/* 주민번호 뒷자리 */}
                {!showLast ? (
                  <input
                    type="password"
                    ref={ssnRef}
                    value={ssnLast}
                    className="mypage-input"
                    onChange={handleSsnLast}
                    maxLength="7"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    style={{ marginLeft: ".2rem", paddingRight: "15px" }}
                  />
                ) : (
                  <input
                    type="number"
                    ref={ssnRef}
                    value={ssnLast}
                    className="mypage-input"
                    onChange={handleSsnLast}
                    maxLength="7"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    style={{ marginLeft: ".2rem", paddingRight: "15px" }}
                  />
                )}
              </div>
              <div className="mypage-toggle" style={{ float: "right" }}>
                <label
                  htmlFor="view-toggler"
                  style={{ position: "relative", zIndex: 999 }}
                >
                  <img src={eyeImage}></img>
                </label>
                <input
                  required
                  id="view-toggler"
                  style={{ display: "none" }}
                  onClick={toggleSsn}
                  type="checkbox"
                />
              </div>
            </div>

            <div>
              <span
                id="mypage-input-text"
                style={{ marginTop: phone ? "-25px" : "-2px" }}
              >
                연락처
              </span>
              <input
                type="number"
                value={phone.toLocaleString()}
                id="phone-input"
                inputMode="numeric"
                className="mypage-input"
                onChange={(e) => {
                  setPhone(e.target.value.slice(0, 11));
                  setStateChanged(true);
                }}
                pattern="[0-9]*"
              />
            </div>
            {/* <tr>
                <div id='mypage-input-text'>배송주소</div>
                <div>
                  <input
                    type="input"
                    value={addr}
                    className="mypage-input"
                    onChange={(e) => {
                      setAddr(e.target.value);
                    }}
                  />
                </div>
              </tr> */}
          </div>
        </div>
      </div>
      <div className="mypage-button-container">
        <button
          id="mypage-save-button"
          // onClick={async () => {
          //   const userInfo = {
          //     id: user.id,
          //     name: name,
          //     phone: phone,
          //     // ssn: ssn,
          //     ssn: ssnFirst + "-" + ssnLast,
          //     // addr: addr,
          //   };

          //   console.log(userInfo);

          //   const res = await updateUser(userInfo);
          //   console.log(res);
          //   if (res) {
          //     history.push({
          //       //   pathname: "/mypage",
          //       pathname: "/status",
          //       kid: kid,
          //     });
          //   }
          // }}
          onClick={handleSubmit}
        >
          저장하기
        </button>
      </div>
    </>
  );
}

export default MyPageSave;
