import { __awaiter, __generator } from "tslib";
import { HttpResponse } from "@aws-sdk/protocol-http";
/**
 * In case of an internal error/terminated connection, S3 operations may return 200 errors. CopyObject, UploadPartCopy,
 * CompleteMultipartUpload may return empty payload or payload with only xml Preamble.
 * @internal
 */
export var throw200ExceptionsMiddleware = function (config) { return function (next) { return function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var result, response, statusCode, body, bodyBytes, bodyString, err;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, next(args)];
            case 1:
                result = _a.sent();
                response = result.response;
                if (!HttpResponse.isInstance(response))
                    return [2 /*return*/, result];
                statusCode = response.statusCode, body = response.body;
                if (statusCode < 200 && statusCode >= 300)
                    return [2 /*return*/, result];
                return [4 /*yield*/, collectBody(body, config)];
            case 2:
                bodyBytes = _a.sent();
                return [4 /*yield*/, collectBodyString(bodyBytes, config)];
            case 3:
                bodyString = _a.sent();
                if (bodyBytes.length === 0) {
                    err = new Error("S3 aborted request");
                    err.name = "InternalError";
                    throw err;
                }
                if (bodyString && bodyString.match("<Error>")) {
                    // Set the error code to 4XX so that error deserializer can parse them
                    response.statusCode = 400;
                }
                // Body stream is consumed and paused at this point. So replace the response.body to the collected bytes.
                // So that the deserializer can consume the body as normal.
                response.body = bodyBytes;
                return [2 /*return*/, result];
        }
    });
}); }; }; };
// Collect low-level response body stream to Uint8Array.
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
// Encode Uint8Array data into string with utf-8.
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
/**
 * @internal
 */
export var throw200ExceptionsMiddlewareOptions = {
    relation: "after",
    toMiddleware: "deserializerMiddleware",
    tags: ["THROW_200_EXCEPTIONS", "S3"],
    name: "throw200ExceptionsMiddleware",
    override: true,
};
/**
 *
 * @internal
 */
export var getThrow200ExceptionsPlugin = function (config) { return ({
    applyToStack: function (clientStack) {
        clientStack.addRelativeTo(throw200ExceptionsMiddleware(config), throw200ExceptionsMiddlewareOptions);
    },
}); };
