import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// slides import
import Q1 from "../pages/faq_pages/Q1";
import Q2 from "../pages/faq_pages/Q2";
import Q3 from "../pages/faq_pages/Q3";
import Q4 from "../pages/faq_pages/Q4";

import "./exogeneSliderStyles.css";

export default function ExogeneCarousel() {
  return (
    <Carousel
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      showArrows={false}
      autoFocus={true}
      emulateTouch={true}
      className="exogene-carousel"
    >
      <div className="exogene-slide">
        <Q1></Q1>
      </div>
      <div className="exogene-slide">
        <Q2></Q2>
      </div>
      <div className="exogene-slide">
        <Q3></Q3>
      </div>
      <div className="exogene-slide">
        <Q4></Q4>
      </div>
    </Carousel>
  );
}
