function ReviewCard(props) {
  return (
    <>
      <div className="justify-content-center review-card" >
        <img
          alt="..."
          // className="rounded-circle"
          src={require("assets/img/" + props.review.img_url)}
          style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
        ></img>
        <div style={{ lineHeight: 1.3, padding: "24px", margin: "0 auto" }}>
          "{props.review.message}"
          <br />
          -{props.review.name}-
        </div>
      </div>
    </>
  );
}
export default ReviewCard;