import React, { useState, useEffect, useRef } from "react";
import { PRODUCTS } from "utils/products";
import { slack } from "utils/util";
import ReusableModal from "./ReusableModal";

// images
import soyBiome from "../surveyAssets/imgs/neshsoyButton.png";
import mintBiome from "../surveyAssets/imgs/neshmintButton.png";
import MarsNNesh from "../surveyAssets/imgs/marsnnesh.png";
import MarsNNeshBlack from "../surveyAssets/imgs/marsnneshBlack.png";

// button
import ButtonUncheck from "../surveyAssets/imgs/buttonCircle.png";
import ButtonCheck from "../surveyAssets/imgs/buttonCircleChecked.png";

// css
import "./surveyComponentStyles.css";

export default function ShampooCheckoutNew(props) {
  //   const [showList, setShowList] = useState(false);
  const [val, setVal] = useState("샴푸 선택하기");
  const [month, setMonth] = useState();
  const [w, setW] = useState(300);
  const soyRef = useRef(null);
  const mintRef = useRef(null);

  // button states
  const [clickSoy, setClickSoy] = useState(false);
  const [clickMint, setClickMint] = useState(false);

  // this for modal
  const [open, setOpen] = useState(false);
  const [shampoo, setShampoo] = useState("neshsoy");

  const handleMintClick = (e) => {
    setShampoo("neshmint");
    props.updateShamp("neshmint");
    if (clickMint) {
      props.updateShamp("");
    }
    setClickMint(!clickMint);
    if (clickSoy) {
      setClickSoy(false);
    }
    // setOpen(true);
  };

  const handleSoyClick = () => {
    setShampoo("neshsoy");
    props.updateShamp("neshsoy");
    if (clickSoy) {
      props.updateShamp("");
    }
    setClickSoy(!clickSoy);
    if (clickMint) {
      setClickMint(false);
    }
    // setOpen(true);
  };

  const handleModalClickSoy = () => {
    setShampoo("neshsoy");
    setOpen(!open);
  };

  const handleModalClickMint = () => {
    setShampoo("neshmint");
    setOpen(!open);
  };

  useEffect(() => {
    setVal(props.shamp);
    if (props.shamp === "neshsoy") {
      setClickSoy(true);
    }
    if (props.shamp === "neshmint") {
      setClickMint(true);
    }
    // let width = ref.current.offsetWidth;
    // setW(width);
  }, [props.shamp]);

  return (
    <div className="shampoo-checkout-new-container">
      {/* SOY SHAMPOO */}
      <div
        ref={soyRef}
        onClick={(e) => {
          handleSoyClick(e);
        }}
        value="neshsoy"
        className={
          clickSoy
            ? "shampoo-checkout-button-container-soy-s"
            : "shampoo-checkout-button-container-soy-ds"
        }
      >
        <div className="shampoo-checkout-button-container">
          {clickSoy ? (
            <img id="shampoo-check-img" src={ButtonCheck} />
          ) : (
            <img id="shampoo-check-img" src={ButtonUncheck} />
          )}
        </div>
        <img id="shampoo-soy-img" src={soyBiome} />
        <div className="shampoo-checkout-button-textbox">
          <img src={MarsNNeshBlack} />
          <p>
            소이바이옴 샴푸
            <span style={{ fontWeight: "200" }}>(건성두피)</span>
          </p>
          <p>
            <span style={{ textDecoration: "line-through", fontWeight: "200" }}>
              24,000원
            </span>
            <span style={{ color: "#6A43B8" }}>&nbsp; 17,900원</span>
          </p>
        </div>
      </div>
      <div>
        <button
          id="shampoo-checkout-information-button-soy"
          onClick={(e) => {
            e.stopPropagation();
            handleModalClickSoy();
          }}
        >
          자세히보기
        </button>
      </div>
      {/* MINT SHAMPOO  */}
      <div
        onClick={(e) => handleMintClick(e)}
        value="neshmint"
        month="1"
        className={
          clickMint
            ? "shampoo-checkout-button-container-mint-s"
            : "shampoo-checkout-button-container-mint-ds"
        }
      >
        <div className="shampoo-checkout-button-container">
          {clickMint ? (
            <img id="shampoo-check-img" src={ButtonCheck} />
          ) : (
            <img id="shampoo-check-img" src={ButtonUncheck} />
          )}
        </div>
        <img id="shampoo-mint-img" src={mintBiome} />
        <div className="shampoo-checkout-button-textbox">
          <img src={MarsNNeshBlack} />
          <p>
            민트바이옴 샴푸{" "}
            <span style={{ fontWeight: "200" }}>(지성두피)</span>
          </p>
          <p>
            <span style={{ textDecoration: "line-through", fontWeight: "200" }}>
              24,000원
            </span>
            <span style={{ color: "#6A43B8" }}>&nbsp; 17,900원</span>
          </p>
          <ReusableModal open={open} setOpen={setOpen} shampoo={shampoo} />
        </div>
      </div>
      <div>
        <button
          id="shampoo-checkout-information-button-mint"
          onClick={handleModalClickMint}
        >
          자세히보기
        </button>
      </div>
    </div>
  );
}
