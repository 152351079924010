import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getUser } from "utils/data";
import { parsePhoneNumber } from "utils/util";
import { getCardInfo, getUserInfo, getAddressInfo } from "./util/GetInfo";
import Lottie from "lottie-react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

// assets
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import InfoTab from "./components/InfoTab";
import InfoTabAccordion from "./components/InfoTabAccordion";
import ProfileImage from "./assets/profileUser.json";

// css
import "./styling/myPageStyling.css";
import "./styling/accordionStyling.css";

export default function MyPageNew(props) {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [kid, setKid] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState();
  const [ssn, setSsn] = useState();
  const [addressDetail, setAddressDetail] = useState();
  const [user, setUser] = useState();
  const [cards, setCards] = useState();

  // information arrays for display
  const [userID, setUserID] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const [addressInfo, setAddressInfo] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);

  // accordion state
  const [openInfo, setOpenInfo] = useState("");

  useEffect(() => {
    const asyncWrapper = async () => {
      var KakaoID;
      if (!location.kid) {
        history.push({
          pathname: "/klogin",
          state: "myPageNew",
        });
      } else {
        setKid(location.kid);
      }
      const user = await getUser(`user#${location.kid}`);
      setUser(user);
      setUserID(user.id);
      setKid(user.kid);
      getUserInfo(user).then((res) => {
        setUserInfo(res);
      });
      getCardInfo(user).then((res) => {
        setCardInfo(res);
      });
      getAddressInfo(user).then((res) => {
        setAddressInfo(res);
      });
    };
    asyncWrapper();
  }, []);

  // accordion toggle logic
  const toggle = (id) => {
    if (openInfo === id) {
      setOpenInfo("");
    } else {
      setOpenInfo(id);
    }
  };


  const accordionStyle = {
    padding: "0px 12px 0px 12px",
  };

  return (
    <div className="mypage-container">
      <MarsNavBarMain></MarsNavBarMain>
      <div className="mypage-title">
        <span>마이페이지</span>
        <div>
          <Lottie animationData={ProfileImage} autoPlay={true}></Lottie>
        </div>
      </div>
      <span className="accordion-section-title">나의정보</span>
      <Accordion id="accordion-profile" toggle={toggle} open={openInfo}>
        <AccordionItem id="accordion-item">
          <AccordionHeader id="accordion-header" targetId="1">
            <span className="accordion-title">기본정보</span>
            {/* <i className={openInfo === "1" ? "arrow down" : "arrow up"}></i> */}
          </AccordionHeader>
          <AccordionBody
            id="accordion-body-profile"
            accordionId="1"
            style={accordionStyle}
          >
            <InfoTabAccordion
              title={"나의정보"}
              displayData={userInfo}
              userID={userID}
              kid={kid}
              type="user"
            ></InfoTabAccordion>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id="accordion-item">
          <AccordionHeader id="accordion-header" targetId="2">
            <span className="accordion-title">주소정보</span>
            {/* <i className={openInfo === "2" ? "arrow down" : "arrow up"}></i> */}
          </AccordionHeader>
          <AccordionBody
            id="accordion-body-profile"
            accordionId="2"
            style={accordionStyle}
          >
            <InfoTabAccordion
              title={"주소정보"}
              displayData={addressInfo}
              userID={userID}
              kid={kid}
              type="addr"
            ></InfoTabAccordion>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id="accordion-item" style={{ border: "none" }}>
          <AccordionHeader id="accordion-header" targetId="3">
            <span className="accordion-title">결제정보</span>
            {/* <i className={openInfo === "3" ? "arrow down" : "arrow up"}></i> */}
          </AccordionHeader>
          <AccordionBody
            id="accordion-body-profile"
            accordionId="3"
            style={accordionStyle}
          >
            <InfoTabAccordion
              title={"결제정보"}
              displayData={cardInfo}
              userID={userID}
              kid={kid}
              type="card"
            ></InfoTabAccordion>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <span className="accordion-section-title">진료정보</span>
      <div className="button-style-accordion">
        <InfoTab
          title={"진료내역"}
          userID={userID}
          kid={kid}
          type="history"
        ></InfoTab>
      </div>
      {/* <span className="accordion-section-title">관리기능</span>
      <div className="button-style-accordion">
        <InfoTab
          title={"사진기록"}
          userID={userID}
          kid={kid}
          type="photos"
        ></InfoTab>
      </div> */}
      <div className="mypage-button">
        <button
          onClick={() => {
            window.location.href = "kakaotalk://inappbrowser/close";
          }}
        >
          닫기
        </button>
      </div>
    </div>
  );
}
