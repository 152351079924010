import { __assign } from "tslib";
export var GetJourneyDateRangeKpiRequest;
(function (GetJourneyDateRangeKpiRequest) {
    GetJourneyDateRangeKpiRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyDateRangeKpiRequest || (GetJourneyDateRangeKpiRequest = {}));
export var JourneyDateRangeKpiResponse;
(function (JourneyDateRangeKpiResponse) {
    JourneyDateRangeKpiResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(JourneyDateRangeKpiResponse || (JourneyDateRangeKpiResponse = {}));
export var GetJourneyDateRangeKpiResponse;
(function (GetJourneyDateRangeKpiResponse) {
    GetJourneyDateRangeKpiResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyDateRangeKpiResponse || (GetJourneyDateRangeKpiResponse = {}));
export var GetJourneyExecutionActivityMetricsRequest;
(function (GetJourneyExecutionActivityMetricsRequest) {
    GetJourneyExecutionActivityMetricsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyExecutionActivityMetricsRequest || (GetJourneyExecutionActivityMetricsRequest = {}));
export var JourneyExecutionActivityMetricsResponse;
(function (JourneyExecutionActivityMetricsResponse) {
    JourneyExecutionActivityMetricsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(JourneyExecutionActivityMetricsResponse || (JourneyExecutionActivityMetricsResponse = {}));
export var GetJourneyExecutionActivityMetricsResponse;
(function (GetJourneyExecutionActivityMetricsResponse) {
    GetJourneyExecutionActivityMetricsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyExecutionActivityMetricsResponse || (GetJourneyExecutionActivityMetricsResponse = {}));
export var GetJourneyExecutionMetricsRequest;
(function (GetJourneyExecutionMetricsRequest) {
    GetJourneyExecutionMetricsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyExecutionMetricsRequest || (GetJourneyExecutionMetricsRequest = {}));
export var JourneyExecutionMetricsResponse;
(function (JourneyExecutionMetricsResponse) {
    JourneyExecutionMetricsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(JourneyExecutionMetricsResponse || (JourneyExecutionMetricsResponse = {}));
export var GetJourneyExecutionMetricsResponse;
(function (GetJourneyExecutionMetricsResponse) {
    GetJourneyExecutionMetricsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetJourneyExecutionMetricsResponse || (GetJourneyExecutionMetricsResponse = {}));
export var GetPushTemplateRequest;
(function (GetPushTemplateRequest) {
    GetPushTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPushTemplateRequest || (GetPushTemplateRequest = {}));
export var PushNotificationTemplateResponse;
(function (PushNotificationTemplateResponse) {
    PushNotificationTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PushNotificationTemplateResponse || (PushNotificationTemplateResponse = {}));
export var GetPushTemplateResponse;
(function (GetPushTemplateResponse) {
    GetPushTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPushTemplateResponse || (GetPushTemplateResponse = {}));
export var GetRecommenderConfigurationRequest;
(function (GetRecommenderConfigurationRequest) {
    GetRecommenderConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetRecommenderConfigurationRequest || (GetRecommenderConfigurationRequest = {}));
export var GetRecommenderConfigurationResponse;
(function (GetRecommenderConfigurationResponse) {
    GetRecommenderConfigurationResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetRecommenderConfigurationResponse || (GetRecommenderConfigurationResponse = {}));
export var GetRecommenderConfigurationsRequest;
(function (GetRecommenderConfigurationsRequest) {
    GetRecommenderConfigurationsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetRecommenderConfigurationsRequest || (GetRecommenderConfigurationsRequest = {}));
export var ListRecommenderConfigurationsResponse;
(function (ListRecommenderConfigurationsResponse) {
    ListRecommenderConfigurationsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListRecommenderConfigurationsResponse || (ListRecommenderConfigurationsResponse = {}));
export var GetRecommenderConfigurationsResponse;
(function (GetRecommenderConfigurationsResponse) {
    GetRecommenderConfigurationsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetRecommenderConfigurationsResponse || (GetRecommenderConfigurationsResponse = {}));
export var GetSegmentRequest;
(function (GetSegmentRequest) {
    GetSegmentRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentRequest || (GetSegmentRequest = {}));
export var GetSegmentResponse;
(function (GetSegmentResponse) {
    GetSegmentResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentResponse || (GetSegmentResponse = {}));
export var GetSegmentExportJobsRequest;
(function (GetSegmentExportJobsRequest) {
    GetSegmentExportJobsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentExportJobsRequest || (GetSegmentExportJobsRequest = {}));
export var GetSegmentExportJobsResponse;
(function (GetSegmentExportJobsResponse) {
    GetSegmentExportJobsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentExportJobsResponse || (GetSegmentExportJobsResponse = {}));
export var GetSegmentImportJobsRequest;
(function (GetSegmentImportJobsRequest) {
    GetSegmentImportJobsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentImportJobsRequest || (GetSegmentImportJobsRequest = {}));
export var GetSegmentImportJobsResponse;
(function (GetSegmentImportJobsResponse) {
    GetSegmentImportJobsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentImportJobsResponse || (GetSegmentImportJobsResponse = {}));
export var GetSegmentsRequest;
(function (GetSegmentsRequest) {
    GetSegmentsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentsRequest || (GetSegmentsRequest = {}));
export var SegmentsResponse;
(function (SegmentsResponse) {
    SegmentsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SegmentsResponse || (SegmentsResponse = {}));
export var GetSegmentsResponse;
(function (GetSegmentsResponse) {
    GetSegmentsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentsResponse || (GetSegmentsResponse = {}));
export var GetSegmentVersionRequest;
(function (GetSegmentVersionRequest) {
    GetSegmentVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentVersionRequest || (GetSegmentVersionRequest = {}));
export var GetSegmentVersionResponse;
(function (GetSegmentVersionResponse) {
    GetSegmentVersionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentVersionResponse || (GetSegmentVersionResponse = {}));
export var GetSegmentVersionsRequest;
(function (GetSegmentVersionsRequest) {
    GetSegmentVersionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentVersionsRequest || (GetSegmentVersionsRequest = {}));
export var GetSegmentVersionsResponse;
(function (GetSegmentVersionsResponse) {
    GetSegmentVersionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSegmentVersionsResponse || (GetSegmentVersionsResponse = {}));
export var GetSmsChannelRequest;
(function (GetSmsChannelRequest) {
    GetSmsChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSmsChannelRequest || (GetSmsChannelRequest = {}));
export var GetSmsChannelResponse;
(function (GetSmsChannelResponse) {
    GetSmsChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSmsChannelResponse || (GetSmsChannelResponse = {}));
export var GetSmsTemplateRequest;
(function (GetSmsTemplateRequest) {
    GetSmsTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSmsTemplateRequest || (GetSmsTemplateRequest = {}));
export var SMSTemplateResponse;
(function (SMSTemplateResponse) {
    SMSTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SMSTemplateResponse || (SMSTemplateResponse = {}));
export var GetSmsTemplateResponse;
(function (GetSmsTemplateResponse) {
    GetSmsTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSmsTemplateResponse || (GetSmsTemplateResponse = {}));
export var GetUserEndpointsRequest;
(function (GetUserEndpointsRequest) {
    GetUserEndpointsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetUserEndpointsRequest || (GetUserEndpointsRequest = {}));
export var GetUserEndpointsResponse;
(function (GetUserEndpointsResponse) {
    GetUserEndpointsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetUserEndpointsResponse || (GetUserEndpointsResponse = {}));
export var GetVoiceChannelRequest;
(function (GetVoiceChannelRequest) {
    GetVoiceChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetVoiceChannelRequest || (GetVoiceChannelRequest = {}));
export var GetVoiceChannelResponse;
(function (GetVoiceChannelResponse) {
    GetVoiceChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetVoiceChannelResponse || (GetVoiceChannelResponse = {}));
export var GetVoiceTemplateRequest;
(function (GetVoiceTemplateRequest) {
    GetVoiceTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetVoiceTemplateRequest || (GetVoiceTemplateRequest = {}));
export var VoiceTemplateResponse;
(function (VoiceTemplateResponse) {
    VoiceTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VoiceTemplateResponse || (VoiceTemplateResponse = {}));
export var GetVoiceTemplateResponse;
(function (GetVoiceTemplateResponse) {
    GetVoiceTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetVoiceTemplateResponse || (GetVoiceTemplateResponse = {}));
export var JourneysResponse;
(function (JourneysResponse) {
    JourneysResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(JourneysResponse || (JourneysResponse = {}));
export var JourneyStateRequest;
(function (JourneyStateRequest) {
    JourneyStateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(JourneyStateRequest || (JourneyStateRequest = {}));
export var ListJourneysRequest;
(function (ListJourneysRequest) {
    ListJourneysRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListJourneysRequest || (ListJourneysRequest = {}));
export var ListJourneysResponse;
(function (ListJourneysResponse) {
    ListJourneysResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListJourneysResponse || (ListJourneysResponse = {}));
export var TemplateResponse;
(function (TemplateResponse) {
    TemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TemplateResponse || (TemplateResponse = {}));
export var TemplateVersionResponse;
(function (TemplateVersionResponse) {
    TemplateVersionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TemplateVersionResponse || (TemplateVersionResponse = {}));
export var ListTagsForResourceRequest;
(function (ListTagsForResourceRequest) {
    ListTagsForResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceRequest || (ListTagsForResourceRequest = {}));
export var TagsModel;
(function (TagsModel) {
    TagsModel.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagsModel || (TagsModel = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var ListTemplatesRequest;
(function (ListTemplatesRequest) {
    ListTemplatesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTemplatesRequest || (ListTemplatesRequest = {}));
export var TemplatesResponse;
(function (TemplatesResponse) {
    TemplatesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TemplatesResponse || (TemplatesResponse = {}));
export var ListTemplatesResponse;
(function (ListTemplatesResponse) {
    ListTemplatesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTemplatesResponse || (ListTemplatesResponse = {}));
export var ListTemplateVersionsRequest;
(function (ListTemplateVersionsRequest) {
    ListTemplateVersionsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTemplateVersionsRequest || (ListTemplateVersionsRequest = {}));
export var TemplateVersionsResponse;
(function (TemplateVersionsResponse) {
    TemplateVersionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TemplateVersionsResponse || (TemplateVersionsResponse = {}));
export var ListTemplateVersionsResponse;
(function (ListTemplateVersionsResponse) {
    ListTemplateVersionsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTemplateVersionsResponse || (ListTemplateVersionsResponse = {}));
export var MessageResult;
(function (MessageResult) {
    MessageResult.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MessageResult || (MessageResult = {}));
export var MessageRequest;
(function (MessageRequest) {
    MessageRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MessageRequest || (MessageRequest = {}));
export var MessageResponse;
(function (MessageResponse) {
    MessageResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MessageResponse || (MessageResponse = {}));
export var NumberValidateRequest;
(function (NumberValidateRequest) {
    NumberValidateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NumberValidateRequest || (NumberValidateRequest = {}));
export var NumberValidateResponse;
(function (NumberValidateResponse) {
    NumberValidateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NumberValidateResponse || (NumberValidateResponse = {}));
export var PhoneNumberValidateRequest;
(function (PhoneNumberValidateRequest) {
    PhoneNumberValidateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PhoneNumberValidateRequest || (PhoneNumberValidateRequest = {}));
export var PhoneNumberValidateResponse;
(function (PhoneNumberValidateResponse) {
    PhoneNumberValidateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PhoneNumberValidateResponse || (PhoneNumberValidateResponse = {}));
export var PutEventsRequest;
(function (PutEventsRequest) {
    PutEventsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutEventsRequest || (PutEventsRequest = {}));
export var PutEventsResponse;
(function (PutEventsResponse) {
    PutEventsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutEventsResponse || (PutEventsResponse = {}));
export var WriteEventStream;
(function (WriteEventStream) {
    WriteEventStream.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(WriteEventStream || (WriteEventStream = {}));
export var PutEventStreamRequest;
(function (PutEventStreamRequest) {
    PutEventStreamRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutEventStreamRequest || (PutEventStreamRequest = {}));
export var PutEventStreamResponse;
(function (PutEventStreamResponse) {
    PutEventStreamResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PutEventStreamResponse || (PutEventStreamResponse = {}));
export var UpdateAttributesRequest;
(function (UpdateAttributesRequest) {
    UpdateAttributesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateAttributesRequest || (UpdateAttributesRequest = {}));
export var RemoveAttributesRequest;
(function (RemoveAttributesRequest) {
    RemoveAttributesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RemoveAttributesRequest || (RemoveAttributesRequest = {}));
export var RemoveAttributesResponse;
(function (RemoveAttributesResponse) {
    RemoveAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RemoveAttributesResponse || (RemoveAttributesResponse = {}));
export var SendMessagesRequest;
(function (SendMessagesRequest) {
    SendMessagesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendMessagesRequest || (SendMessagesRequest = {}));
export var SendMessagesResponse;
(function (SendMessagesResponse) {
    SendMessagesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendMessagesResponse || (SendMessagesResponse = {}));
export var SendUsersMessageRequest;
(function (SendUsersMessageRequest) {
    SendUsersMessageRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendUsersMessageRequest || (SendUsersMessageRequest = {}));
export var SendUsersMessagesRequest;
(function (SendUsersMessagesRequest) {
    SendUsersMessagesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendUsersMessagesRequest || (SendUsersMessagesRequest = {}));
export var SendUsersMessageResponse;
(function (SendUsersMessageResponse) {
    SendUsersMessageResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendUsersMessageResponse || (SendUsersMessageResponse = {}));
export var SendUsersMessagesResponse;
(function (SendUsersMessagesResponse) {
    SendUsersMessagesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SendUsersMessagesResponse || (SendUsersMessagesResponse = {}));
export var TagResourceRequest;
(function (TagResourceRequest) {
    TagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceRequest || (TagResourceRequest = {}));
export var UntagResourceRequest;
(function (UntagResourceRequest) {
    UntagResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceRequest || (UntagResourceRequest = {}));
export var UpdateAdmChannelRequest;
(function (UpdateAdmChannelRequest) {
    UpdateAdmChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateAdmChannelRequest || (UpdateAdmChannelRequest = {}));
export var UpdateAdmChannelResponse;
(function (UpdateAdmChannelResponse) {
    UpdateAdmChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateAdmChannelResponse || (UpdateAdmChannelResponse = {}));
export var UpdateApnsChannelRequest;
(function (UpdateApnsChannelRequest) {
    UpdateApnsChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsChannelRequest || (UpdateApnsChannelRequest = {}));
export var UpdateApnsChannelResponse;
(function (UpdateApnsChannelResponse) {
    UpdateApnsChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsChannelResponse || (UpdateApnsChannelResponse = {}));
export var UpdateApnsSandboxChannelRequest;
(function (UpdateApnsSandboxChannelRequest) {
    UpdateApnsSandboxChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsSandboxChannelRequest || (UpdateApnsSandboxChannelRequest = {}));
export var UpdateApnsSandboxChannelResponse;
(function (UpdateApnsSandboxChannelResponse) {
    UpdateApnsSandboxChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsSandboxChannelResponse || (UpdateApnsSandboxChannelResponse = {}));
export var UpdateApnsVoipChannelRequest;
(function (UpdateApnsVoipChannelRequest) {
    UpdateApnsVoipChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsVoipChannelRequest || (UpdateApnsVoipChannelRequest = {}));
export var UpdateApnsVoipChannelResponse;
(function (UpdateApnsVoipChannelResponse) {
    UpdateApnsVoipChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsVoipChannelResponse || (UpdateApnsVoipChannelResponse = {}));
export var UpdateApnsVoipSandboxChannelRequest;
(function (UpdateApnsVoipSandboxChannelRequest) {
    UpdateApnsVoipSandboxChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsVoipSandboxChannelRequest || (UpdateApnsVoipSandboxChannelRequest = {}));
export var UpdateApnsVoipSandboxChannelResponse;
(function (UpdateApnsVoipSandboxChannelResponse) {
    UpdateApnsVoipSandboxChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApnsVoipSandboxChannelResponse || (UpdateApnsVoipSandboxChannelResponse = {}));
export var WriteApplicationSettingsRequest;
(function (WriteApplicationSettingsRequest) {
    WriteApplicationSettingsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(WriteApplicationSettingsRequest || (WriteApplicationSettingsRequest = {}));
export var UpdateApplicationSettingsRequest;
(function (UpdateApplicationSettingsRequest) {
    UpdateApplicationSettingsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApplicationSettingsRequest || (UpdateApplicationSettingsRequest = {}));
export var UpdateApplicationSettingsResponse;
(function (UpdateApplicationSettingsResponse) {
    UpdateApplicationSettingsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateApplicationSettingsResponse || (UpdateApplicationSettingsResponse = {}));
export var UpdateBaiduChannelRequest;
(function (UpdateBaiduChannelRequest) {
    UpdateBaiduChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateBaiduChannelRequest || (UpdateBaiduChannelRequest = {}));
export var UpdateBaiduChannelResponse;
(function (UpdateBaiduChannelResponse) {
    UpdateBaiduChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateBaiduChannelResponse || (UpdateBaiduChannelResponse = {}));
export var UpdateCampaignRequest;
(function (UpdateCampaignRequest) {
    UpdateCampaignRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateCampaignRequest || (UpdateCampaignRequest = {}));
export var UpdateCampaignResponse;
(function (UpdateCampaignResponse) {
    UpdateCampaignResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateCampaignResponse || (UpdateCampaignResponse = {}));
export var UpdateEmailChannelRequest;
(function (UpdateEmailChannelRequest) {
    UpdateEmailChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEmailChannelRequest || (UpdateEmailChannelRequest = {}));
export var UpdateEmailChannelResponse;
(function (UpdateEmailChannelResponse) {
    UpdateEmailChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEmailChannelResponse || (UpdateEmailChannelResponse = {}));
export var UpdateEmailTemplateRequest;
(function (UpdateEmailTemplateRequest) {
    UpdateEmailTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEmailTemplateRequest || (UpdateEmailTemplateRequest = {}));
export var UpdateEmailTemplateResponse;
(function (UpdateEmailTemplateResponse) {
    UpdateEmailTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEmailTemplateResponse || (UpdateEmailTemplateResponse = {}));
export var UpdateEndpointRequest;
(function (UpdateEndpointRequest) {
    UpdateEndpointRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEndpointRequest || (UpdateEndpointRequest = {}));
export var UpdateEndpointResponse;
(function (UpdateEndpointResponse) {
    UpdateEndpointResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEndpointResponse || (UpdateEndpointResponse = {}));
export var UpdateEndpointsBatchRequest;
(function (UpdateEndpointsBatchRequest) {
    UpdateEndpointsBatchRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEndpointsBatchRequest || (UpdateEndpointsBatchRequest = {}));
export var UpdateEndpointsBatchResponse;
(function (UpdateEndpointsBatchResponse) {
    UpdateEndpointsBatchResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateEndpointsBatchResponse || (UpdateEndpointsBatchResponse = {}));
export var UpdateGcmChannelRequest;
(function (UpdateGcmChannelRequest) {
    UpdateGcmChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGcmChannelRequest || (UpdateGcmChannelRequest = {}));
export var UpdateGcmChannelResponse;
(function (UpdateGcmChannelResponse) {
    UpdateGcmChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateGcmChannelResponse || (UpdateGcmChannelResponse = {}));
export var UpdateJourneyRequest;
(function (UpdateJourneyRequest) {
    UpdateJourneyRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateJourneyRequest || (UpdateJourneyRequest = {}));
export var UpdateJourneyResponse;
(function (UpdateJourneyResponse) {
    UpdateJourneyResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateJourneyResponse || (UpdateJourneyResponse = {}));
export var UpdateJourneyStateRequest;
(function (UpdateJourneyStateRequest) {
    UpdateJourneyStateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateJourneyStateRequest || (UpdateJourneyStateRequest = {}));
export var UpdateJourneyStateResponse;
(function (UpdateJourneyStateResponse) {
    UpdateJourneyStateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateJourneyStateResponse || (UpdateJourneyStateResponse = {}));
export var UpdatePushTemplateRequest;
(function (UpdatePushTemplateRequest) {
    UpdatePushTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdatePushTemplateRequest || (UpdatePushTemplateRequest = {}));
export var UpdatePushTemplateResponse;
(function (UpdatePushTemplateResponse) {
    UpdatePushTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdatePushTemplateResponse || (UpdatePushTemplateResponse = {}));
export var UpdateRecommenderConfigurationShape;
(function (UpdateRecommenderConfigurationShape) {
    UpdateRecommenderConfigurationShape.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateRecommenderConfigurationShape || (UpdateRecommenderConfigurationShape = {}));
export var UpdateRecommenderConfigurationRequest;
(function (UpdateRecommenderConfigurationRequest) {
    UpdateRecommenderConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateRecommenderConfigurationRequest || (UpdateRecommenderConfigurationRequest = {}));
export var UpdateRecommenderConfigurationResponse;
(function (UpdateRecommenderConfigurationResponse) {
    UpdateRecommenderConfigurationResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateRecommenderConfigurationResponse || (UpdateRecommenderConfigurationResponse = {}));
export var UpdateSegmentRequest;
(function (UpdateSegmentRequest) {
    UpdateSegmentRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSegmentRequest || (UpdateSegmentRequest = {}));
export var UpdateSegmentResponse;
(function (UpdateSegmentResponse) {
    UpdateSegmentResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSegmentResponse || (UpdateSegmentResponse = {}));
export var SMSChannelRequest;
(function (SMSChannelRequest) {
    SMSChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SMSChannelRequest || (SMSChannelRequest = {}));
export var UpdateSmsChannelRequest;
(function (UpdateSmsChannelRequest) {
    UpdateSmsChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSmsChannelRequest || (UpdateSmsChannelRequest = {}));
export var UpdateSmsChannelResponse;
(function (UpdateSmsChannelResponse) {
    UpdateSmsChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSmsChannelResponse || (UpdateSmsChannelResponse = {}));
export var UpdateSmsTemplateRequest;
(function (UpdateSmsTemplateRequest) {
    UpdateSmsTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSmsTemplateRequest || (UpdateSmsTemplateRequest = {}));
export var UpdateSmsTemplateResponse;
(function (UpdateSmsTemplateResponse) {
    UpdateSmsTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateSmsTemplateResponse || (UpdateSmsTemplateResponse = {}));
export var TemplateActiveVersionRequest;
(function (TemplateActiveVersionRequest) {
    TemplateActiveVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TemplateActiveVersionRequest || (TemplateActiveVersionRequest = {}));
export var UpdateTemplateActiveVersionRequest;
(function (UpdateTemplateActiveVersionRequest) {
    UpdateTemplateActiveVersionRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTemplateActiveVersionRequest || (UpdateTemplateActiveVersionRequest = {}));
export var UpdateTemplateActiveVersionResponse;
(function (UpdateTemplateActiveVersionResponse) {
    UpdateTemplateActiveVersionResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateTemplateActiveVersionResponse || (UpdateTemplateActiveVersionResponse = {}));
export var VoiceChannelRequest;
(function (VoiceChannelRequest) {
    VoiceChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VoiceChannelRequest || (VoiceChannelRequest = {}));
export var UpdateVoiceChannelRequest;
(function (UpdateVoiceChannelRequest) {
    UpdateVoiceChannelRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateVoiceChannelRequest || (UpdateVoiceChannelRequest = {}));
export var UpdateVoiceChannelResponse;
(function (UpdateVoiceChannelResponse) {
    UpdateVoiceChannelResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateVoiceChannelResponse || (UpdateVoiceChannelResponse = {}));
export var UpdateVoiceTemplateRequest;
(function (UpdateVoiceTemplateRequest) {
    UpdateVoiceTemplateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateVoiceTemplateRequest || (UpdateVoiceTemplateRequest = {}));
export var UpdateVoiceTemplateResponse;
(function (UpdateVoiceTemplateResponse) {
    UpdateVoiceTemplateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UpdateVoiceTemplateResponse || (UpdateVoiceTemplateResponse = {}));
