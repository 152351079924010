// import React, { useEffect, useState } from "react";
// import { PRODUCTS } from "utils/products";
// import { getDoctorIdentityID } from "utils/util";
// import { slack } from "utils/util";
// import { won } from "utils/util";
import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { PRODUCTS } from "utils/products";
import { won } from "utils/util";
import { calcPrice4 } from "utils/util";
import { getDoctorIdentityID } from "utils/util";
import { slack } from "utils/util";
import StatusStatusBar from "./status-components/StatusStatusBar";

function StatusStep4(props) {
  // const [minox, setMinox] = useState("");
  // const [pill, setPill] = useState("");
  // const [month, setMonth] = useState(0);
  // const [signedURL, setSignedURL] = useState("");
  // const [pillMonth, setPillMonth] = useState();
  // const [minoxMonth, setMinoxMonth] = useState();

  const [minox, setMinox] = useState("minox");

  const [pill, setPill] = useState("propecia");
  const [capil, setCapil] = useState(null);
  const [shamp, setShamp] = useState(null);

  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [pillMonth, setPillMonth] = useState();
  const [minoxMonth, setMinoxMonth] = useState();
  const [capilMonth, setCapilMonth] = useState(1);
  const [pillCount, setPillCount] = useState(30);
  const [minoxCount, setMinoxCount] = useState(1);
  const [doctorInfo, setDoctorInfo] = useState(null);
  const [total, setTotal] = useState(0);

  const [signedURL, setSignedURL] = useState("");

  const statusProps = {
    step: props.step,
    user: props.user,
  };

  // useEffect(() => {
  //   const asyncWrapper = async () => {
  //     let p = props.patient;
  //     setMinox(p.minox);
  //     setPill(p.pill);
  //     setMonth(p.month);
  //     setMinoxMonth(p.month);;
  //     setPillMonth(p.month);

  //     // if pill and minox month exists update it.
  //     if (p.pillMonth) {
  //       setPillMonth(p.pillMonth);
  //     }
  //     if (p.minoxMonth) {
  //       setMinoxMonth(p.minoxMonth);
  //     }

  //     //get the signedulr using filekey
  //     if (p.filekey) {
  //       let id = getDoctorIdentityID(p.doctor);
  //       const key = await Storage.get(p.filekey, {
  //         level: "protected",
  //         identityId: id,
  //       });
  //       setSignedURL(key);
  //     }
  //     slack("[status/step4(약배송중)] 방문: " + props.user.name);
  //   }
  //   asyncWrapper();
  // }, []);

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      let pmonth, mmonth;

      setMinox(p.minox);
      setPill(p.pill);
      setCapil(p.capil);
      setShamp(p.shamp);
      setMonth(p.month);
      setPillMonth(p.month);
      setCapilMonth(p.capilMonth);
      setMinoxMonth(p.month);

      // if pill and minox month exists update it.
      if (p.pillMonth) {
        pmonth = p.pillMonth;
        setPillMonth(p.pillMonth);
      }
      if (p.minoxMonth) {
        mmonth = p.minoxMonth;
        setMinoxMonth(p.minoxMonth);
      }

      let res = calcPrice4(
        p.pill,
        p.minox,
        pmonth,
        mmonth,
        p.shamp,
        p.capil,
        p.capilMonth
      );
      setPillPrice(res.pillPrice);
      setCapilPrice(res.capilPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);

      //get the signedulr using filekey
      if (p.filekey) {
        let id = getDoctorIdentityID(p.doctor);
        const key = await Storage.get(p.filekey, {
          level: "protected",
          identityId: id,
        });
        setSignedURL(key);
      }
      slack("[status/step3(약준비중)] 방문: " + props.user.name);
    };
    asyncWrapper();
  }, []);

  const parseTracking = (num) => {
    return num.match(/\d+/g).join("");
  };

  const formatPrescTime = (time) => {
    let timeObj = new Date(time);
    let year = timeObj.getFullYear();
    let month = timeObj.getMonth() + 1;
    let date = timeObj.getDate();
    return `${year}. ${month}. ${date}.`;
  };

  return (
    <>
      <div
        className="status-section-container"
        style={{ background: "#F7F7F7" }}
      >
        {/* questions */}
        {/* <div className="question-section">
          <div
            className="question-title"
            style={{ paddingBottom: "12px", paddingTop: "68px" }}
          >
            약 배송 중 입니다
          </div>
          <div className="question-explain">
            약 배송이 시작 되었어요. CJ대한통운 택배로 안전하게 배송해 드릴게요. 샴푸 구매시 약과 별도로 배송됩니다.
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "18px",
              marginBottom: "-6px",
            }}
          >
            <img
              alt="..."
              src={require("assets/img/status4.png")}
              width="100%"
              style={{ maxWidth: "320px" }}
            ></img>
          </div>
        </div> */}
        <StatusStatusBar props={statusProps} />
        <div id="status-fake-curve"></div>

        {/* 접수정보 세션 */}
        <div
          style={{
            marginBottom: "90px",
            paddingTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            background: "white",
          }}
        >
          {/* 처방 내용 */}
          <div
            style={{
              paddingBottom: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table width={"100%"}>
              <tbody>
                <tr>
                  <td>
                    <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                      {/* <b>{month}개월 처방내용</b> */}
                      <b>나의 처방전</b>
                    </div>
                    {props.patient.yeyak_time ? (
                      <div className='status-title'>{formatPrescTime(props.patient.yeyak_time)}</div>
                    ) : (
                      <></>
                    )}
                  </td>

                  <td>
                    <div
                      style={{
                        fontSize: "13px",
                        marginBottom: "25px",
                      }}
                    >
                      <div className="status-prescription-button">
                        <a href={signedURL} download="처방전">
                          <u className="gray-underline">확인하기</u>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <table width={"100%"}>
              <tbody>
                {pill !== "아니요" && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base} {PRODUCTS[pill].unit})
                    </td>
                  </tr>
                )}
                {minox !== "아니요" && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[minox].name} ({minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit})
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
          <div className="line-orange-status" />

          {/* 세부내역 */}

          <div className="" style={{ paddingBottom: "15px" }}>
            <table width={"100%"}>
              <tbody className='status-title'>
                <tr>
                  <td>
                    <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                      <b>결제 세부내역</b>
                      <td style={{ float: "right", marginRight: "-5px" }}>
                        <b>총 {won(total)}원</b>
                      </td>
                    </div>
                  </td>
                  <td className="text-right"></td>
                </tr>
              </tbody>
            </table>

            <table width={"100%"}>
              <tbody className='status-title'>
                {pill && pill !== "아니요" && (
                  <tr>
                    <td>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base}{" "}
                      {PRODUCTS[pill].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(pillPrice)}원
                    </td>
                  </tr>
                )}

                {minox && minox !== "아니요" && (
                  <tr>
                    <td>
                      {PRODUCTS[minox].name} (
                      {minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit}
                      ){/* {minox} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(minoxPrice)}원
                    </td>
                  </tr>
                )}

                {shamp && (
                  <tr>
                    <td>
                      {PRODUCTS[shamp].name} ({PRODUCTS[shamp].base}{" "}
                      {PRODUCTS[shamp].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(shampPrice)}원
                    </td>
                  </tr>
                )}

                {capil && (
                  <tr>
                    <td>
                      {PRODUCTS[capil].name} (
                      {capilMonth * PRODUCTS[capil].base} {PRODUCTS[capil].unit}
                      ){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(capilPrice)}원
                    </td>
                  </tr>
                )}

                <tr>
                  <td>진료비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    10,000원
                  </td>
                </tr>
                <tr>
                  <td>배송비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    2,900원
                  </td>
                </tr>
                {/* <tr>
                  <td />
                  <td />
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    <b>총 {won(total)}원</b>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="line-orange-status" />

          {/* 배송정보 */}
          <div style={{ paddingBottom: "15px" }}>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>배송 정보</b>
            </div>
            <div className='status-title'>{props.patient.delivery_number}</div>
            {/* <a
              href={`https://tracker.delivery/#/kr.cjlogistics/${parseTracking(
                props.patient.delivery_number
              )}`}
              target="_blank"
            >
              배송조회
            </a> */}
          </div>

          <div className="line-orange-status" />

          {/* 배송주소 */}
          <div style={{ paddingBottom: "50px" }}>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>배송 주소</b>
            </div>
            {props.user.addr && (
              <div className='status-title'>{`${props.user.addr} ${props.user.addr2} ${props.user.zip}`}</div>
            )}
          </div>
        </div>
        <div id="status-fake-curve-bottom"></div>
      </div>
    </>
  );
}
export default StatusStep4;
