import { __assign, __awaiter, __generator } from "tslib";
import { HttpRequest } from "@aws-sdk/protocol-http";
export function addExpectContinueMiddleware(options) {
    var _this = this;
    return function (next) { return function (args) { return __awaiter(_this, void 0, void 0, function () {
        var request;
        return __generator(this, function (_a) {
            request = args.request;
            if (HttpRequest.isInstance(request) && request.body && options.runtime === "node") {
                request.headers = __assign(__assign({}, request.headers), { Expect: "100-continue" });
            }
            return [2 /*return*/, next(__assign(__assign({}, args), { request: request }))];
        });
    }); }; };
}
export var addExpectContinueMiddlewareOptions = {
    step: "build",
    tags: ["SET_EXPECT_HEADER", "EXPECT_HEADER"],
    name: "addExpectContinueMiddleware",
    override: true,
};
export var getAddExpectContinuePlugin = function (options) { return ({
    applyToStack: function (clientStack) {
        clientStack.add(addExpectContinueMiddleware(options), addExpectContinueMiddlewareOptions);
    },
}); };
