import React, { useState } from "react";

function ServiceAgreement() {
  const [displayYear, setDisplayYear] = useState("2023");

  const handleYearClick = (year) => {
    setDisplayYear(year);
  };

  return (
    <>
      <div
        style={{
          height: "60vh",
          overflow: "scroll",
          lineHeight: "1.1",
          textAlign: "justify",
        }}
      >
        {displayYear && displayYear === "2021" ? (
          <>
            <p>
              <strong>서비스 이용약관</strong>
            </p>
            <p>
              ‘마스앤비너스’ 서비스의 이용과 관련하여 필요한 사항을 규정합니다.
              마스앤비너스의 회원약관은 다음과 같은 내용을 담고 있습니다.
              마스앤비너스 서비스는 보건의료기본법 제39조, 제40조 및 제44조,
              의료법 제59조1항, 감염병예방법 제4조에 따라, 보건복지부 공고
              제2020-177호에 의거하여{" "}
              <em>&#39;전화상담 처방 및 대리처방 한시적 허용방안&#39;</em> 및{" "}
              <em>&#39;약사협의를 통한 수령방식 결정&#39;</em>을 근거로 코로나19
              확산을 막기 위한 한시적으로 허용되는 합법적 서비스입니다.
            </p>
            <p>
              전화처방서비스는 정부 정책의 변경에 따라 예고 없이 중단될 수
              있으며, 회사는 이에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>
              회사는 정부가 한시적으로 허용한 통신진료를 보조하는 시스템을
              제공할 뿐,회원과 의료진(의사 및 병원)간 진료 행위 및 회원 본인의
              건강에 관한 의사 결정에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>
              <em>
                (중요 안내 사항 : 이용자가 겪고 있는 증상이 경미한 증상이 아닌
                경우, 반드시 가까운 병원/응급실에 내원하거나 119를 호출하시기
                바랍니다.)
              </em>
            </p>
            <p>
              <strong>[마스앤비너스 운영원칙]</strong>
            </p>
            <p>
              ‘마스앤비너스&#39;는 ‘올바른’ 온라인 진료-처방-관리 플랫폼의
              기준이 되는 것을 목표로 하고 있습니다. 1) ‘온라인 진료&#39;에
              적합한 증상을 찾고, 2) 증상별로 최적화된 모바일 진료방식을
              설계하여, 유저, 의료진 모두에게 효율적인 의료경험을 제공하고자
              합니다. 추가적으로 유저의 건강증진을 위해 필요한 증상 관리 기능,
              올바른 정보와 제품을 함께 제공할 수 있도록 항상 최선을
              다하겠습니다.
            </p>
            <p>
              <strong>[중개사업자 면책 및 책임범위]</strong>
            </p>
            <p>
              사이버몰 내 판매되는 상품 중에는 마스앤비너스가 등록한 개별
              판매자가 판매하는 상품이 포함되어 있습니다. 개별판매자가 판매하는
              상품의 경우 마스앤비너스는 통신판매중개자이며 통신판매의 당사자가
              아닙니다. 마스앤비너스는 개별판매자의 상품, 거래정보 및 거래 등에
              대하여 책임을 지지 않습니다. 마스앤비너스는 소비자 보호와
              안전거래를 위해 고객센터를 운영하고 있으며, 관련 분쟁이 발생할
              경우 별도의 분쟁처리절차에 의거 분쟁이 처리됩니다.
            </p>
            <p>
              <strong>[이용약관]</strong>
            </p>
            <p>
              <strong>제1조(목적)</strong>
            </p>
            <p>표준약관 제10023호</p>
            <p>
              이 약관은 주식회사 마스앤비너스(이하 ‘회사&#39;라 한다)가 운영하는
              마스앤비너스 플랫폼(이하 &quot;플랫폼&quot;이라 한다)에서 제공하는
              인터넷 관련 서비스(이하 &quot;서비스&quot;라 한다)를 이용함에 있어
              플랫폼과 이용자의 권리·의무 및 책임사항을 규정함을 목적으로
              합니다.
            </p>
            <p>
              ※ 「PC통신등을 이용하는 전자거래에 대해서도 그 성질에 반하지
              않는한 이 약관을 준용합니다」
            </p>
            <p>
              <strong>제2조(정의)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;이란 주식회사 마스앤비너스가 재화 또는 용역을
              이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화
              또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러
              플랫폼을 운영하는 사업자의 의미로도 사용합니다.
            </p>
            <p>
              ② “서비스”라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종
              유무선 장치를 포함)와 상관없이 “회원”이 이용할 수 있는
              마스앤비너스와 관련 제반 서비스를 의미합니다.
            </p>
            <p>
              ③&quot;이용자&quot;란 &quot;플랫폼&quot;에 접속하여 이 약관에 따라
              &quot;플랫폼&quot;이 제공하는 서비스를 받는 회원 및 비회원을
              말합니다.
            </p>
            <p>
              ④ ‘회원’이라 함은 &quot;플랫폼&quot;에 개인정보를 제공하여
              회원등록을 한 자로서, &quot;플랫폼&quot;의 정보를 지속적으로
              제공받으며, &quot;플랫폼&quot;이 제공하는 서비스를 계속적으로
              이용할 수 있는 자를 말합니다.
            </p>
            <p>
              ⑤ ‘비회원’이라 함은 회원에 가입하지 않고 &quot;플랫폼&quot;이
              제공하는 서비스를 이용하는 자를 말합니다.
            </p>
            <p>
              ⑥ ”의료진”이라 함은 의사 회원가입 양식에 따라 서비스 가입을
              완료하고, 회원들을 위한 원격진료를 할 수 있는 권한을 가진 자를
              말합니다.
            </p>
            <p>
              ⑦ “아이디(ID)”라 함은 회원의 식별, 정보 제공 및 서비스 이용을
              위하여 회원이 설정하고 회사가 승인하여 사이트에 등록된 휴대폰
              전화번호를 말합니다.
            </p>
            <p>
              ⑧ “비밀번호”라 함은 회원이 부여 받은 아이디와 일치되는 회원임을
              확인하고 비밀 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을
              의미합니다.
            </p>
            <p>
              ⑨ “인증번호”라 함은 “회원”이 부여 받은 “아이디(휴대폰 전화번호)”를
              통해 부여 받은 인증번호를 활용해 일치 되는 ”회원“임을 확인하는
              번호를 의미합니다.
            </p>
            <p>
              ⑩ “단말기”’라 함은 서비스에 접속하기 위해 회원이 이용하는 개인용
              컴퓨터, PDA, 휴대전화, 태블릿PC 등의 전산장치를 말합니다.
            </p>
            <p>
              ⑪ “해지”라 함은 회사 또는 회원이 이용계약을 해약하는 것을
              말합니다.
            </p>
            <p>
              ⑫ ”게시물“이라 함은 ”회원“이 ”서비스“를 이용함에 있어 ”서비스상”에
              게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글,
              사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
            </p>
            <p>
              <strong>제3조(약관등의 명시와 설명 및 개정)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이 약관의 내용과 상호 및 대표자 성명,
              영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를
              포함), 전화번호·모사전송번호·전자우편주소, 사업자등록번호,
              통신판매업신고번호, 개인정보 보호책임자 등을 이용자가 쉽게 알 수
              있도록 &quot;플랫폼&quot;의 초기 서비스화면(전면)에 게시합니다.
              다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
              있습니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 이용자가 약관에 동의하기에 앞서 약관에
              정하여져 있는 내용 중 청약철회·배송책임·환불조건 등과 같은 중요한
              내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면
              등을 제공하여 이용자의 확인을 구하여야 합니다.
            </p>
            <p>
              ③ &quot;플랫폼&quot;은 전자상거래등에서의소비자보호에관한법률,
              약관의규제에관한법률, 전자거래기본법, 전자서명법,
              정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법
              등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </p>
            <p>
              ④ &quot;플랫폼&quot;이 약관을 개정할 경우에는 적용일자 및
              개정사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그
              적용일자 7일이전부터 적용일자 전일까지 공지합니다.
            </p>
            <p>
              다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일
              이상의 사전 유예기간을 두고 공지합니다. 이 경우
              &quot;플랫폼&quot;은 개정전 내용과 개정후 내용을 명확하게 비교하여
              이용자가 알기 쉽도록 표시합니다.
            </p>
            <p>
              ⑤ &quot;플랫폼&quot;이 약관을 개정할 경우에는 그 개정약관은 그
              적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된
              계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미
              계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을
              제3항에 의한 개정약관의 공지기간내에 &quot;플랫폼&quot;에 송신하여
              &quot;플랫폼&quot;의 동의를 받은 경우에는 개정약관 조항이
              적용됩니다.
            </p>
            <p>
              ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
              전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한
              법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침
              및 관계법령 또는 상관례에 따릅니다.
            </p>
            <p>
              <strong>제4조(서비스의 제공 및 변경)</strong>
            </p>
            <p>① &quot;플랫폼&quot;은 다음과 같은 업무를 수행합니다.</p>
            <ol>
              <li>
                <p>재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</p>
              </li>
              <li>
                <p>구매계약이 체결된 재화 또는 용역의 배송</p>
              </li>
              <li>
                <p>건강정보와 관련된 내용</p>
              </li>
              <li>
                <p>기타 &quot;플랫폼&quot;이 정하는 업무</p>
              </li>
            </ol>
            <p>
              ② &quot;플랫폼&quot;은 재화 또는 용역의 품절 또는 기술적 사양의
              변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는
              용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는
              용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을
              게시한 곳에 즉시 공지합니다.
            </p>
            <p>
              ③ &quot;플랫폼&quot;이 제공하기로 이용자와 계약을 체결한 서비스의
              내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할
              경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </p>
            <p>
              ④ 전항의 경우 &quot;플랫폼&quot;은 이로 인하여 이용자가 입은
              손해를 배상합니다. 다만, &quot;플랫폼&quot;이 고의 또는 과실이
              없음을 입증하는 경우에는 그러하지 아니합니다.
            </p>
            <p>
              ⑤ 건강정보와 관련된 내용은 대한민국 의료법과 보건복지부의
              가이드라인에 따른 전문가의 지식의 기반한 의견 또는 개인적 생각으로
              이것이 진단 등과 같은 의료적 행위가 아닌 전문가의 조언이므로 참고
              목적으로만 사용 되어야 하며, 마스앤비너스는 그 책임을 지지
              않습니다. 이용자가 병원을 직접 방문하여 의사와 진찰과정을 통한
              진료결과나 건강진단결과, 처방결과를 대신하지 못합니다.
            </p>
            <p>
              <strong>제5조(서비스의 중단)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 컴퓨터 등 정보통신설비의 보수점검·교체 및
              고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
              일시적으로 중단할 수 있습니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 제1항의 사유로 서비스의 제공이 일시적으로
              중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여
              배상합니다. 단, &quot;플랫폼&quot;이 고의 또는 과실이 없음을
              입증하는 경우에는 그러하지 아니합니다.
            </p>
            <p>
              ③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를
              제공할 수 없게 되는 경우에는 &quot;플랫폼&quot;은 제8조에 정한
              방법으로 이용자에게 통지하고 당초 &quot;플랫폼&quot;에서 제시한
              조건에 따라 소비자에게 보상합니다. 다만, &quot;플랫폼&quot;이
              보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는
              적립금 등을 &quot;플랫폼&quot;에서 통용되는 통화가치에 상응하는
              현물 또는 현금으로 이용자에게 지급합니다.
            </p>
            <p>
              <strong>제6조(회원가입)</strong>
            </p>
            <p>
              ① 이용자는 &quot;플랫폼&quot;이 정한 가입 양식에 따라 회원정보를
              기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을
              신청합니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 제1항과 같이 회원으로 가입할 것을 신청한
              이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
            </p>
            <ol>
              <li>
                <p>
                  가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을
                  상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실후
                  3년이 경과한 자로서 &quot;플랫폼&quot;의 회원재가입 승낙을
                  얻은 경우에는 예외로 한다.
                </p>
              </li>
              <li>
                <p>등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
              </li>
              <li>
                <p>
                  기타 회원으로 등록하는 것이 &quot;플랫폼&quot;의 기술상 현저히
                  지장이 있다고 판단되는 경우
                </p>
              </li>
            </ol>
            <p>
              ③ 회원가입계약의 성립시기는 &quot;플랫폼&quot;의 승낙이 회원에게
              도달한 시점으로 합니다.
            </p>
            <p>
              ④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시
              전자우편 기타 방법으로 &quot;플랫폼&quot;에 대하여 그 변경사항을
              알려야 합니다.
            </p>
            <p>
              <strong>제7조(회원 탈퇴 및 자격 상실 등)</strong>
            </p>
            <p>
              ① 회원은 &quot;플랫폼&quot;에 언제든지 탈퇴를 요청할 수 있으며
              &quot;플랫폼&quot;은 즉시 회원탈퇴를 처리합니다.
            </p>
            <p>
              ② 회원이 다음 각호의 사유에 해당하는 경우, &quot;플랫폼&quot;은
              회원자격을 제한 및 정지시킬 수 있습니다.
            </p>
            <ol>
              <li>
                <p>가입 신청시에 허위 내용을 등록한 경우</p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot;을 이용하여 구입한 재화등의 대금, 기타
                  &quot;플랫폼&quot;이용에 관련하여 회원이 부담하는 채무를
                  기일에 지급하지 않는 경우
                </p>
              </li>
              <li>
                <p>
                  다른 사람의 &quot;플랫폼&quot; 이용을 방해하거나 그 정보를
                  도용하는 등 전자상거래 질서를 위협하는 경우
                </p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot;을 이용하여 법령 또는 이 약관이 금지하거나
                  공서양속에 반하는 행위를 하는 경우
                </p>
              </li>
            </ol>
            <p>
              ③ &quot;플랫폼&quot;이 회원 자격을 제한·정지 시킨후, 동일한 행위가
              2회이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우
              &quot;플랫폼&quot;은 회원자격을 상실시킬 수 있습니다.
            </p>
            <p>
              ④ &quot;플랫폼&quot;이 회원자격을 상실시키는 경우에는 회원등록을
              말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에
              최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </p>
            <p>
              <strong>제8조(회원에 대한 통지)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;이 회원에 대한 통지를 하는 경우, 회원이
              &quot;플랫폼&quot;과 미리 약정하여 지정한 전자우편 주소로 할 수
              있습니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 불특정다수 회원에 대한 통지의 경우
              1주일이상 &quot;플랫폼&quot; 게시판에 게시함으로서 개별 통지에
              갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한
              영향을 미치는 사항에 대하여는 개별통지를 합니다.
            </p>
            <p>
              <strong>제9조(구매신청)</strong>
            </p>
            <p>
              &quot;플랫폼&quot;이용자는 &quot;플랫폼&quot;상에서 다음 또는 이와
              유사한 방법에 의하여 구매를 신청하며, &quot;플랫폼&quot;은
              이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게
              제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의 적용을
              제외할 수 있습니다.
            </p>
            <ol>
              <li>
                <p>재화등의 검색 및 선택</p>
              </li>
              <li>
                <p>
                  성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의
                  입력
                </p>
              </li>
              <li>
                <p>
                  약관내용, 청약철회권이 제한되는 서비스, 배송료·설치비 등의
                  비용부담과 관련한 내용에 대한 확인
                </p>
              </li>
              <li>
                <p>
                  이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는
                  표시(예, 마우스 클릭)
                </p>
              </li>
              <li>
                <p>
                  재화등의 구매신청 및 이에 관한 확인 또는 &quot;플랫폼&quot;의
                  확인에 대한 동의
                </p>
              </li>
              <li>
                <p>결제방법의 선택</p>
              </li>
            </ol>
            <p>
              <strong>제10조 (계약의 성립)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 제9조와 같은 구매신청에 대하여 다음 각호에
              해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을
              체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인
              또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야
              합니다.
            </p>
            <ol>
              <li>
                <p>신청 내용에 허위, 기재누락, 오기가 있는 경우</p>
              </li>
              <li>
                <p>
                  미성년자가 담배, 주류등 청소년보호법에서 금지하는 재화 및
                  용역을 구매하는 경우
                </p>
              </li>
              <li>
                <p>
                  기타 구매신청에 승낙하는 것이 &quot;플랫폼&quot; 기술상 현저히
                  지장이 있다고 판단하는 경우
                </p>
              </li>
            </ol>
            <p>
              ② &quot;플랫폼&quot;의 승낙이 제12조제1항의 수신확인통지형태로
              이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
            </p>
            <p>
              ③ &quot;플랫폼&quot;의 승낙의 의사표시에는 이용자의 구매 신청에
              대한 확인 및 판매가능 여부, 구매신청의 정정 취소등에 관한 정보등을
              포함하여야 합니다.
            </p>
            <p>
              <strong>제11조(지급방법)</strong>
            </p>
            <p>
              &quot;플랫폼&quot;에서 구매한 재화 또는 용역에 대한 대금지급방법은
              다음 각호의 방법중 가용한 방법으로 할 수 있습니다. 단,
              &quot;플랫폼&quot;은 이용자의 지급방법에 대하여 재화 등의 대금에
              어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
            </p>
            <ol>
              <li>
                <p>폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
              </li>
              <li>
                <p>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</p>
              </li>
              <li>
                <p>온라인무통장입금</p>
              </li>
              <li>
                <p>전자화폐에 의한 결제</p>
              </li>
              <li>
                <p>수령시 대금지급</p>
              </li>
              <li>
                <p>
                  마일리지 등 &quot;플랫폼&quot;이 지급한 포인트에 의한 결제
                </p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot;과 계약을 맺었거나 &quot;플랫폼&quot;이
                  인정한 상품권에 의한 결제
                </p>
              </li>
              <li>
                <p>기타 전자적 지급 방법에 의한 대금 지급 등</p>
              </li>
            </ol>
            <p>
              <strong>제12조(수신확인통지·구매신청 변경 및 취소)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이용자의 구매신청이 있는 경우 이용자에게
              수신확인통지를 합니다.
            </p>
            <p>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치등이 있는 경우에는
              수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고
              &quot;플랫폼&quot;은 배송전에 이용자의 요청이 있는 경우에는
              지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한
              경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
            </p>
            <p>
              <strong>제13조(재화등의 공급)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이용자와 재화등의 공급시기에 관하여 별도의
              약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을
              배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
              다만, &quot;플랫폼&quot;이 이미 재화 등의 대금의 전부 또는 일부를
              받은 경우에는 대금의 전부 또는 일부를 받은 날부터 2영업일 이내에
              조치를 취합니다. 이때 &quot;플랫폼&quot;은 이용자가 재화등의 공급
              절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 이용자가 구매한 재화에 대해 배송수단,
              수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약
              &quot;플랫폼&quot;이 약정 배송기간을 초과한 경우에는 그로 인한
              이용자의 손해를 배상하여야 합니다. 다만 &quot;플랫폼&quot;이
              고의·과실이 없음을 입증한 경우에는 그러하지 아니합니다.
            </p>
            <p>
              <strong>제14조(환급)</strong>
            </p>
            <p>
              &quot;플랫폼&quot;은 이용자가 구매신청한 재화등이 품절 등의 사유로
              인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게
              통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터
              2영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
            </p>
            <p>
              <strong>제15조(청약철회 등)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;과 재화등의 구매에 관한 계약을 체결한 이용자는
              수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수
              있습니다.
            </p>
            <p>
              ② 이용자는 재화등을 배송받은 경우 다음 각호의 1에 해당하는
              경우에는 반품 및 교환을 할 수 없습니다.
            </p>
            <ol>
              <li>
                <p>
                  이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
                  경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한
                  경우에는 청약철회를 할 수 있습니다)
                </p>
              </li>
              <li>
                <p>
                  이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히
                  감소한 경우
                </p>
              </li>
              <li>
                <p>
                  시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가
                  현저히 감소한 경우
                </p>
              </li>
              <li>
                <p>
                  같은 성능을 지닌 재화등으로 복제가 가능한 경우 그 원본인 재화
                  등의 포장을 훼손한 경우
                </p>
              </li>
            </ol>
            <p>
              ③ 제2항제2호 내지 제4호의 경우에 &quot;플랫폼&quot;이 사전에
              청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에
              명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의
              청약철회등이 제한되지 않습니다.
            </p>
            <p>
              ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해
              재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수
              있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            </p>
            <p>
              <strong>제16조(청약철회 등의 효과)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이용자로부터 재화 등을 반환받은 경우
              3영업일 이내에 이미 지급받은 재화등의 대금을 환급합니다. 이 경우
              &quot;플랫폼&quot;이 이용자에게 재화등의 환급을 지연한 때에는 그
              지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을
              곱하여 산정한 지연이자를 지급합니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 위 대금을 환급함에 있어서 이용자가 신용카드
              또는 전자화폐 등의 결제수단으로 재화등의 대금을 지급한 때에는
              지체없이 당해 결제수단을 제공한 사업자로 하여금 재화등의 대금의
              청구를 정지 또는 취소하도록 요청합니다.
            </p>
            <p>
              ③ 청약철회등의 경우 공급받은 재화등의 반환에 필요한 비용은
              이용자가 부담합니다. &quot;플랫폼&quot;은 이용자에게 청약철회등을
              이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화등의
              내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어
              청약철회등을 하는 경우 재화등의 반환에 필요한 비용은
              &quot;플랫폼&quot;이 부담합니다.
            </p>
            <p>
              ④ 이용자가 재화등을 제공받을때 발송비를 부담한 경우에
              &quot;플랫폼&quot;은 청약철회시 그 비용을 누가 부담하는지를
              이용자가 알기 쉽도록 명확하게 표시합니다.
            </p>
            <p>
              <strong>제17조(개인정보보호)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이용자의 정보수집시 구매계약 이행에 필요한
              최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외
              사항은 선택사항으로 합니다.
            </p>
            <ol>
              <li>
                <p>성명</p>
              </li>
              <li>
                <p>주소</p>
              </li>
              <li>
                <p>전화번호</p>
              </li>
              <li>
                <p>희망ID(회원의 경우)</p>
              </li>
              <li>
                <p>비밀번호(회원의 경우)</p>
              </li>
              <li>
                <p>전자우편주소(또는 이동전화번호)</p>
              </li>
            </ol>
            <p>
              ② &quot;플랫폼&quot;이 이용자의 개인식별이 가능한 개인정보를
              수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
            </p>
            <p>
              ③ 제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나
              제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 플랫폼이 집니다.
              다만, 다음의 경우에는 예외로 합니다.
            </p>
            <ol>
              <li>
                <p>
                  배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의
                  정보(성명, 주소, 전화번호)를 알려주는 경우
                </p>
              </li>
              <li>
                <p>
                  통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
                  개인을 식별할 수 없는 형태로 제공하는 경우
                </p>
              </li>
              <li>
                <p>재화등의 거래에 따른 대금정산을 위하여 필요한 경우</p>
              </li>
              <li>
                <p>도용방지를 위하여 본인확인에 필요한 경우</p>
              </li>
              <li>
                <p>
                  법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는
                  경우
                </p>
              </li>
            </ol>
            <p>
              ④ &quot;플랫폼&quot;이 제2항과 제3항에 의해 이용자의 동의를 받아야
              하는 경우에는 개인정보 보호책임자의 신원(소속, 성명 및 전화번호,
              기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
              관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
              정보통신망이용촉진등에관한법률 제22조제2항이 규정한 사항을 미리
              명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
              있습니다.
            </p>
            <p>
              ⑤ 이용자는 언제든지 &quot;플랫폼&quot;이 가지고 있는 자신의
              개인정보에 대해 열람 및 오류정정을 요구할 수 있으며
              &quot;플랫폼&quot;은 이에 대해 지체없이 필요한 조치를 취할 의무를
              집니다. 이용자가 오류의 정정을 요구한 경우에는
              &quot;플랫폼&quot;은 그 오류를 정정할 때까지 당해 개인정보를
              이용하지 않습니다.
            </p>
            <p>
              ⑥ &quot;플랫폼&quot;은 개인정보 보호를 위하여 관리자를 한정하여 그
              수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의
              분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든
              책임을 집니다.
            </p>
            <p>
              ⑦ &quot;플랫폼&quot; 또는 그로부터 개인정보를 제공받은 제3자는
              개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
              개인정보를 지체없이 파기합니다.
            </p>
            <p>
              <strong>제18조(&quot;회사&quot;의 의무)</strong>
            </p>
            <p>
              ① &quot;회사&quot;는 법령과 이 약관이 금지하거나 공서양속에 반하는
              행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
              안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.
            </p>
            <p>
              ② &quot;회사&quot;는 이용자가 안전하게 인터넷 서비스를 이용할 수
              있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
              갖추어야 합니다.
            </p>
            <p>
              ③ &quot;회사&quot;가 상품이나 용역에 대하여
              「표시·광고의공정화에관한법률」 제3조 소정의 부당한
              표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할
              책임을 집니다.
            </p>
            <p>
              ④ &quot;회사&quot;는 이용자가 원하지 않는 영리목적의 광고성
              전자우편을 발송하지 않습니다.
            </p>
            <p>
              <strong>제19조(회원의 ID 및 비밀번호에 대한 의무)</strong>
            </p>
            <p>
              ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
              있습니다.
            </p>
            <p>
              ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
              안됩니다.
            </p>
            <p>
              ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
              있음을 인지한 경우에는 바로 &quot;플랫폼&quot;에 통보하고
              &quot;플랫폼&quot;의 안내가 있는 경우에는 그에 따라야 합니다.
            </p>
            <p>
              <strong>제20조(이용자의 의무)</strong>
            </p>
            <p>이용자는 다음 행위를 하여서는 안됩니다.</p>
            <ol>
              <li>
                <p>신청 또는 변경시 허위 내용의 등록</p>
              </li>
              <li>
                <p>타인의 정보 도용</p>
              </li>
              <li>
                <p>&quot;플랫폼&quot;에 게시된 정보의 변경</p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot;이 정한 정보 이외의 정보(컴퓨터 프로그램 등)
                  등의 송신 또는 게시
                </p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot; 기타 제3자의 저작권 등 지적재산권에 대한
                  침해
                </p>
              </li>
              <li>
                <p>
                  &quot;플랫폼&quot; 기타 제3자의 명예를 손상시키거나 업무를
                  방해하는 행위
                </p>
              </li>
              <li>
                <p>
                  외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
                  정보를 플랫폼에 공개 또는 게시하는 행위
                </p>
              </li>
            </ol>
            <p>
              <strong>
                제21조(연결&quot;플랫폼&quot;과 피연결&quot;플랫폼&quot; 간의
                관계)
              </strong>
            </p>
            <p>
              ① 상위 &quot;플랫폼&quot;과 하위 &quot;플랫폼&quot;이 하이퍼
              링크(예: 하이퍼 링크의 대상에는 문자, 이미지 및 동영상 등이
              포함됨) 방식 등으로 연결된 경우, 전자를 연결 &quot;플랫폼&quot;(웹
              사이트)이라고 하고 후자를 피연결
              &quot;플랫폼&quot;(웹사이트)이라고 합니다.
            </p>
            <p>
              ② 연결&quot;플랫폼&quot;은 피연결&quot;플랫폼&quot;이 독자적으로
              제공하는 재화등에 의하여 이용자와 행하는 거래에 대해서 보증책임을
              지지 않는다는 뜻을 연결&quot;플랫폼&quot;의 초기화면 또는 연결되는
              시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지
              않습니다.
            </p>
            <p>
              <strong>
                제22조(&quot;플랫폼&quot; 제공 컨텐츠의 저작권 보호)
              </strong>
            </p>
            <p>
              ① 마스앤비너스에서 제공하는 각종 서비스의 내용을 보호하고, 저작권
              등 타인의 권리를 침해하지 않도록 하기 위하여 다음과 같은 저작권
              정책을 운영하고 있으니, 회원 및 마스앤비너스 이용 고객 분들은 이를
              살펴보시고 숙지하시어 타인의 권리를 침해하지 않도록 하시기
              바랍니다.
            </p>
            <ol>
              <li>
                <p>게시물의 저작권 귀속 및 책임</p>
              </li>
              <li>
                <p>
                  회원이 작성하여 서비스에 게재 또는 등록한 답변, 자료, 사진
                  등에 대한 저작권은 회원 본인에게 있는 것으로 추정되며,
                  게시물이 타인의 저작권 등 지적재산권을 침해하는 경우 그에 대한
                  책임은 회원 본인이 부담합니다.
                </p>
              </li>
              <li>
                <p>
                  회사가 저작권을 가지고 있는 게시물을 이용하는 경우에는 학술,
                  연구 등 개인적 목적으로만 이용하여야 하며, 회사의 명시적 동의
                  없이 게시물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로
                  이용하거나 영리적 목적으로 이용하는 경우에는 민사상의 책임뿐만
                  아니라 형사처벌을 받을 수도 있습니다.
                </p>
              </li>
              <li>
                <p>회원 및 제3자의 저작권 보호</p>
              </li>
              <li>
                <p>
                  저작권자의 명시적 동의 없이 타인의 저작물(컴퓨터프로그램 포함.
                  이하 같습니다)의 전부 또는 일부를 복제, 배포, 전송 등의
                  방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위이므로
                  복제/배포/전송 등의 방법으로 타인의 저작권을 침해하는 게시물을
                  서비스에 게재 또는 등록하여서는 아니 됩니다.
                </p>
              </li>
              <li>
                타인의 저작물을 인용한 게시물인 경우 그 인용부분이 회원 개인의
                의견을 뒷받침하기 위해 일부 인용된 것이 아니라 게시물의 핵심적인
                부분을 구성하고 있다면 출처를 밝히더라도 저작권 침해에
                해당되므로 저작권자의 명시적 동의 없이 이러한 게시물을 서비스에
                게재 또는 등록하여서는 아니 됩니다.
              </li>
              <li>
                사이트 서비스에 게재 또는 등록된 회원의 게시물을 제3자가
                사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤
                사용하여야 합니다.
              </li>
              <li>
                사이트는 회원이 서비스 이용과정에서 타인의 저작권, 상표권,
                의장권 등 지적 재산권을 침해하는 경우 어떠한 책임도 부담하지
                않습니다.
              </li>
              <li>
                서비스상의 게시물은 회원들간의 지식공유를 위해 회원이 게재한
                것이므로 회사는 게시물에 대한 정확성에 대해서는 보증을 하지
                않습니다.
              </li>
              <li>
                <p>
                  사이트는 회원들의 게시물 이용에 따른 동의절차를 중개하거나 그
                  과정에 개입 하지 않습니다.
                </p>
              </li>
              <li>
                <p>게시물의 중단 및 재개 요청</p>
              </li>
              <li>
                <p>
                  저작권 침해신고에 관한 사항은 고객센터 또는 메일을 통해 연락
                  주시기 바랍니다.
                </p>
              </li>
            </ol>
            <p>
              <strong>제 23조(서비스이용료)</strong>
            </p>
            <p>
              회사는 회원과 의료진 간의 자유로운 상담서비스 이용에 필요한 각종
              서비스를 제공하고, 회사의 내부 정책에 따라 그에 대한 이용료(서비스
              이용료) 또는 상담촉진서비스이용료 등을 부과할 수 있습니다.
            </p>
            <p>
              <strong>제 24조(비대면 진료 대금의 정산)</strong>
            </p>
            <p>
              의료진이 마스앤비너스를 통하여 수행한 원격진료 용역 대금(이하
              “비대면진료 대금”이라 함)에 대한 정산은 원격진료서비스 가격에서
              제14조의 서비스 이용료 및 비대면 진료촉진서비스이용료 등을 제외한
              금액을 기준으로 산정되며, 회사가 비대면진료 서비스 가격에 대한
              가이드를 제공하되, 의료진은 서비스 이용료 등을 고려하여 비대면진료
              서비스 가격을 자유롭게 정할 수 있습니다.
            </p>
            <p>
              정산주기는 금월 진료 완료 건에 대한 비대면진료 대금을 익월 10일에
              지급하는 것을 원칙으로 하며, 회사와 의료진이 별도로 정산주기를
              정한 경우에는 이 기준에 따릅니다. 단, 회사는 비대면 진료 대금을
              지급하기 이전에 회원이 취소 또는 환불의 의사를 표시한 경우 비대면
              진료 대금의 지급을 보류할 수 있습니다.
            </p>
            <p>
              회사는 의료진의 귀책사유로 인해 발생한 비용을 비대면진료 대금에서
              공제할 수 있습니다.
            </p>
            <p>
              회사는 아래의 사유가 발생하는 경우 비대면 진료 대금의 정산을
              유보할 수 있습니다.
            </p>
            <p>
              ① 회원이 신용카드로 결제한 경우, [여신전문금융업법]상의 규정에
              따라 회사는 신용카드 부정사용을 통한 허위거래여부를 판단하기 위해
              최고 60일까지 비대면진료 대금에 대한 송금을 보류할 수 있습니다. 이
              경우, 회사는 비대면진료 사실 확인을 위한 증빙을 의료진에 요구할 수
              있으며, 회사는 사실 여부 확인 후 비대면진료 대금을 지급할 수
              있습니다.
            </p>
            <p>
              ② 법원 등 제3자가 자격을 갖추어 비대면 진료 대금의 지급보류를
              요청한 경우 회사는 보류요청이 해제될 때까지 관련 비대면 진료
              대금의 송금을 보류하거나 정당한 채권자에게 지급할 수 있습니다
            </p>
            <p>
              ③ 회사는 의료진이 상담서비스 제공 부적격자로 적발되거나, 회원
              클레임의 다수 발생으로 인한 환불 등의 요청이 염려되는 경우 일정
              기간 정산을 보류할 수 있습니다.
            </p>
            <p>
              ④ 의료진과 회원 간에 동일한 유형의 클레임이 지속적으로 발생하는
              경우, 회원 클레임에 대비하여 일정기간 비대면 진료 대금의 정산을
              유보할 수 있습니다.
            </p>
            <p>
              본 조에 정한 외에도 법률의 규정에 의하거나 합리적인 사유가 있는
              경우에는 회사는 의료진에게 통지하고 비대면 진료 대금의 전부 또는
              일부에 대한 정산을 일정 기간 유보할 수 있습니다.
            </p>
            <p>
              <strong>제 25조(정보의 제공 및 광고의 계약)</strong>
            </p>
            <p>
              ① “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한
              정보를 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수
              있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의
              등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신
              거절을 할 수 있습니다.
            </p>
            <p>
              ② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는
              경우에는 “회원”의 사전 동의를 받아서 전송합니다. 다만, “회원”의
              거래관련 정보, 상담하기, 혜택받기 서비스로 인한 이메일 발송 및
              문자, 통화와 관련된 내용은 제외됩니다.
            </p>
            <p>
              ③ “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지,
              어플리케이션, 전자우편 등에 광고를 게재할 수 있습니다. 광고가
              게재된 전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수
              있습니다.
            </p>
            <p>
              ④ “이용자(회원, 비회원 포함)”는 회사가 제공하는 서비스와 관련하여
              게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지
              않습니다.
            </p>
            <p>
              ⑤ ”회사”는 회원가입 하신 “이용자(의사 및 약사)”분들을 위해 온,
              오프라인으로 무료 광고를 대행할 수 있습니다.
            </p>
            <p>
              <strong>제26조(분쟁해결)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;은 이용자가 제기하는 정당한 의견이나 불만을
              반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
              설치·운영합니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;은 이용자로부터 제출되는 불만사항 및 의견은
              우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
              경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </p>
            <p>
              ③ &quot;플랫폼&quot;과 이용자간에 발생한 전자상거래 분쟁과
              관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
              시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </p>
            <p>
              <strong>제27조(재판권 및 준거법)</strong>
            </p>
            <p>
              ① &quot;플랫폼&quot;과 이용자간에 발생한 전자상거래 분쟁에 관한
              소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는
              거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시
              이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
              민사소송법상의 관할법원에 제기합니다.
            </p>
            <p>
              ② &quot;플랫폼&quot;과 이용자간에 제기된 전자상거래 소송에는
              한국법을 적용합니다.
            </p>
            <p>
              <strong>제 28조(책임제한)</strong>
            </p>
            <p>
              ① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여
              “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이
              면제됩니다.
            </p>
            <p>
              ② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에
              대하여는 책임을 지지 않습니다.
            </p>
            <p>
              ③ “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의
              신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
            </p>
            <p>
              ④ “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를
              매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
            </p>
            <p>
              ⑤ “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
              규정이 없는 한 책임을 지지 않습니다.
            </p>
            <p>
              <strong>제 24조(준거법 및 재판관할)</strong>
            </p>
            <p>
              ① “회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.
            </p>
            <p>
              ② “회사”와 “회원”간 발생한 분쟁에 관한 소송은 제소 당시의 “회원”의
              주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
              전속관할로 합니다. 단, 제소 당시 “회원”의 주소 또는 거소가
              명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
            </p>
            <p>
              <strong>부칙</strong>
            </p>
            <ol>
              <li>이 약관은 2021년 12월 15일부터 적용됩니다.</li>
            </ol>
          </>
        ) : (
          ""
        )}

        {/* NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW NEW */}

        {displayYear && displayYear === "2023" ? (
          <>
            {" "}
            <p>
              <strong>서비스 이용약관</strong>
            </p>
            <p>
              <strong></strong>
            </p>
            <p>
              ‘마스앤비너스’ 서비스의 이용과 관련하여 필요한 사항을 규정합니다.
              마스앤비너스의 회원약관은 다음과 같은 내용을 담고 있습니다.
              마스앤비너스 서비스는 보건의료기본법 제39조, 제40조 및 제44조,
              의료법 제59조1항, 감염병예방법 제4조에 따라, 보건복지부 공고
              제2020-177호에 의거하여 '전화상담 처방 및 대리처방 한시적
              허용방안' 및 '약사협의를 통한 수령방식 결정'을 근거로 코로나19
              확산을 막기 위한 한시적으로 허용되는 합법적 서비스입니다.
              전화처방서비스는 정부 정책의 변경에 따라 예고 없이 중단될 수
              있으며, 회사는 이에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>
              회사는 정부가 한시적으로 허용한 통신진료를 보조하는 시스템을
              제공할 뿐, 회원과 의료진(의사 및 병원)간 진료 행위 및 회원 본인의
              건강에 관한 의사 결정에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>
              <strong>[마스앤비너스 운영원칙]</strong>
            </p>
            <p>
              ‘마스앤비너스'는 ‘올바른’ 온라인 진료-처방-관리 플랫폼의 기준이
              되는 것을 목표로 하고 있습니다. 1) ‘온라인 진료'에 적합한 증상을
              찾고, 2) 증상별로 최적화된 모바일 진료방식을 설계하여, 유저,
              의료진 모두에게 효율적인 의료경험을 제공하고자 합니다. 추가적으로
              유저의 건강증진을 위해 필요한 증상 관리 기능, 올바른 정보와 제품을
              함께 제공할 수 있도록 항상 최선을 다하겠습니다.
            </p>
            <p>
              <strong>[중개사업자 면책 및 책임범위]</strong>
            </p>
            <p>
              사이버몰 내 판매되는 상품 중에는 마스앤비너스가 등록한 개별
              판매자가 판매하는 상품이 포함되어 있습니다. 개별판매자가 판매하는
              상품의 경우 마스앤비너스는 통신판매중개자이며 통신판매의 당사자가
              아닙니다. 마스앤비너스는 개별판매자의 상품, 거래정보 및 거래 등에
              대하여 책임을 지지 않습니다. 마스앤비너스는 소비자 보호와
              안전거래를 위해 고객센터를 운영하고 있으며, 관련 분쟁이 발생할
              경우 별도의 분쟁처리절차에 의거 분쟁이 처리됩니다.
            </p>
            <p>
              <strong>[이용약관]</strong>
            </p>
            <p>
              <strong></strong>
              <br />
            </p>
            <p>제1조(목적)</p>
            <p>표준약관 제10023호</p>
            <p>
              이 약관은 주식회사 마스앤비너스(이하 ‘회사'라 한다)가 운영하는
              마스앤비너스 플랫폼(이하 "플랫폼"이라 한다)에서 제공하는 인터넷
              관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 플랫폼과
              이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
            </p>
            <p>
              ※ 「PC통신등을 이용하는 전자거래에 대해서도 그 성질에 반하지
              않는한 이 약관을 준용합니다」
            </p>
            <p>제2조(정의)</p>
            <p>
              ① "플랫폼"이란 주식회사 마스앤비너스가 재화 또는 용역을 이용자에게
              제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화 또는 용역을
              거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 플랫폼을
              운영하는 사업자의 의미로도 사용합니다.
            </p>
            <p>
              ② “서비스”라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종
              유무선 장치를 포함)와 상관없이 “회원”이 이용할 수 있는
              마스앤비너스와 관련 제반 서비스를 의미합니다.
            </p>
            <p>
              ③"이용자"란 "플랫폼"에 접속하여 이 약관에 따라 "플랫폼"이 제공하는
              서비스를 받는 회원 및 비회원을 말합니다.
            </p>
            <p>
              ④ ‘회원’이라 함은 "플랫폼"에 개인정보를 제공하여 회원등록을 한
              자로서, "플랫폼"의 정보를 지속적으로 제공받으며, "플랫폼"이
              제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
            </p>
            <p>
              ⑤ ‘비회원’이라 함은 회원에 가입하지 않고 "플랫폼"이 제공하는
              서비스를 이용하는 자를 말합니다.
            </p>
            <p>
              ⑥ ”의료진”이라 함은 의사 회원가입 양식에 따라 서비스 가입을
              완료하고, 회원들을 위한 원격진료를 할 수 있는 권한을 가진 자를
              말합니다.
            </p>
            <p>
              ⑦ “아이디(ID)”라 함은 회원의 식별, 정보 제공 및 서비스 이용을
              위하여 회원이 설정하고 회사가 승인하여 사이트에 등록된 휴대폰
              전화번호를 말합니다.
            </p>
            <p>
              ⑧ “비밀번호”라 함은 회원이 부여 받은 아이디와 일치되는 회원임을
              확인하고 비밀 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을
              의미합니다.
            </p>
            <p>
              ⑨ “인증번호”라 함은 “회원”이 부여 받은 “아이디(휴대폰 전화번호)”를
              통해 부여 받은 인증번호를 활용해 일치 되는 ”회원“임을 확인하는
              번호를 의미합니다.
            </p>
            <p>
              ⑩ “단말기”’라 함은 서비스에 접속하기 위해 회원이 이용하는 개인용
              컴퓨터, PDA, 휴대전화, 태블릿PC 등의 전산장치를 말합니다.
            </p>
            <p>
              ⑪ “해지”라 함은 회사 또는 회원이 이용계약을 해약하는 것을
              말합니다.
            </p>
            <p>
              ⑫ ”게시물“이라 함은 ”회원“이 ”서비스“를 이용함에 있어 ”서비스상”에
              게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글,
              사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
            </p>
            <p>제3조(약관등의 명시와 설명 및 개정)</p>
            <p>
              ① "플랫폼"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
              주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
              전화번호·모사전송번호·전자우편주소, 사업자등록번호,
              통신판매업신고번호, 개인정보 보호책임자 등을 이용자가 쉽게 알 수
              있도록 "플랫폼"의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의
              내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </p>
            <p>
              ② "플랫폼"은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는
              내용 중 청약철회·배송책임·환불조건 등과 같은 중요한 내용을
              이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
              제공하여 이용자의 확인을 구하여야 합니다.
            </p>
            <p>
              ③ "플랫폼"은 전자상거래등에서의소비자보호에관한법률,
              약관의규제에관한법률, 전자거래기본법, 전자서명법,
              정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법
              등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </p>
            <p>
              ④ "플랫폼"이 약관을 개정할 경우에는 적용일자 및 개정사유를
              명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용일자
              7일이전부터 적용일자 전일까지 공지합니다.
            </p>
            <p>
              다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일
              이상의 사전 유예기간을 두고 공지합니다. 이 경우 "플랫폼"은 개정전
              내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록
              표시합니다.
            </p>
            <p>
              ⑤ "플랫폼"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자
              이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에
              대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을
              체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에
              의한 개정약관의 공지기간내에 "플랫폼"에 송신하여 "플랫폼"의 동의를
              받은 경우에는 개정약관 조항이 적용됩니다.
            </p>
            <p>
              ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
              전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한
              법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침
              및 관계법령 또는 상관례에 따릅니다.
            </p>
            <p>제4조(서비스의 제공 및 변경)</p>
            <p>① "플랫폼"은 다음과 같은 업무를 수행합니다.</p>
            <p>1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</p>
            <p>2. 구매계약이 체결된 재화 또는 용역의 배송</p>
            <p>3. 건강정보와 관련된 내용</p>
            <p>4. 기타 "플랫폼"이 정하는 업무</p>
            <p>
              ② "플랫폼"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
              경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을
              변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
              제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에
              즉시 공지합니다.
            </p>
            <p>
              ③ "플랫폼"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을
              재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는
              그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </p>
            <p>
              ④ 전항의 경우 "플랫폼"은 이로 인하여 이용자가 입은 손해를
              배상합니다. 다만, "플랫폼"이 고의 또는 과실이 없음을 입증하는
              경우에는 그러하지 아니합니다.
            </p>
            <p>
              ⑤ 건강정보와 관련된 내용은 대한민국 의료법과 보건복지부의
              가이드라인에 따른 전문가의 지식의 기반한 의견 또는 개인적 생각으로
              이것이 진단 등과 같은 의료적 행위가 아닌 전문가의 조언이므로 참고
              목적으로만 사용 되어야 하며, 마스앤비너스는 그 책임을 지지
              않습니다. 이용자가 병원을 직접 방문하여 의사와 진찰과정을 통한
              진료결과나 건강진단결과, 처방결과를 대신하지 못합니다.
            </p>
            <p>제5조(서비스의 중단)</p>
            <p>
              ① "플랫폼"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장,
              통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로
              중단할 수 있습니다.
            </p>
            <p>
              ② "플랫폼"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
              인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
              "플랫폼"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
              아니합니다.
            </p>
            <p>
              ③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를
              제공할 수 없게 되는 경우에는 "플랫폼"은 제8조에 정한 방법으로
              이용자에게 통지하고 당초 "플랫폼"에서 제시한 조건에 따라
              소비자에게 보상합니다. 다만, "플랫폼"이 보상기준 등을 고지하지
              아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "플랫폼"에서
              통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게
              지급합니다.
            </p>
            <p>제6조(회원가입)</p>
            <p>
              ① 이용자는 "플랫폼"이 정한 가입 양식에 따라 회원정보를 기입한 후
              이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
            </p>
            <p>
              ② "플랫폼"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중
              다음 각호에 해당하지 않는 한 회원으로 등록합니다.
            </p>
            <p>
              1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을
              상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실후
              3년이 경과한 자로서 "플랫폼"의 회원재가입 승낙을 얻은 경우에는
              예외로 한다.
            </p>
            <p>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
            <p>
              3. 만14세 미만 아동이 법정대리인의 동의 없이 가입한 것으로 확인된
              경우
            </p>
            <p>
              4. 기타 회원으로 등록하는 것이 "플랫폼"의 기술상 현저히 지장이
              있다고 판단되는 경우
            </p>
            <p>
              ③ 회원가입계약의 성립시기는 "플랫폼"의 승낙이 회원에게 도달한
              시점으로 합니다.
            </p>
            <p>
              ④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시
              전자우편 기타 방법으로 "플랫폼"에 대하여 그 변경사항을 알려야
              합니다.
            </p>
            <p>
              ⑤ “플랫폼”은 회원가입시 이용자로부터 상품 정보 및 이벤트와 관련된
              광고성 앱푸시 메시지 수신, 재화나 서비스를 홍보하거나 판매를
              권유하기 위한 개인정보처리에 대한 동의를 받을 수 있고, 그러한
              동의를 받은 경우에는 해당 서비스를 제공할 수 있습니다.
            </p>
            <p>제7조(회원 탈퇴 및 자격 상실 등)</p>
            <p>
              ① 회원은 "플랫폼"에 언제든지 탈퇴를 요청할 수 있으며 "플랫폼"은
              즉시 회원탈퇴를 처리합니다.
            </p>
            <p>
              ② 회원이 다음 각호의 사유에 해당하는 경우, "플랫폼"은 회원자격을
              제한 및 정지시킬 수 있습니다.
            </p>
            <p>1. 가입 신청시에 허위 내용을 등록한 경우</p>
            <p>
              2. "플랫폼"을 이용하여 구입한 재화 등의 대금, 기타 "플랫폼"이용에
              관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
            </p>
            <p>
              3. 다른 사람의 "플랫폼" 이용을 방해하거나 그 정보를 도용하는 등
              전자상거래 질서를 위협하는 경우
            </p>
            <p>
              4. "플랫폼"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
              반하는 행위를 하는 경우
            </p>
            <p>
              ③ "플랫폼"이 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회이상
              반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 "플랫폼"은
              회원자격을 상실 시킬 수 있습니다.
            </p>
            <p>
              ④ "플랫폼"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다.
              이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일
              이상의 기간을 정하여 소명할 기회를 부여합니다.
            </p>
            <p>제8조(회원에 대한 통지)</p>
            <p>
              ① "플랫폼"이 회원에 대한 통지를 하는 경우, 회원이 "플랫폼"과 미리
              약정하여 지정한 전자우편 주소로 할 수 있습니다.
            </p>
            <p>
              ② "플랫폼"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "플랫폼"
              게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원
              본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
              개별통지를 합니다.
            </p>
            <p>제9조(구매신청)</p>
            <p>
              "플랫폼"이용자는 "플랫폼"상에서 다음 또는 이와 유사한 방법에
              의하여 구매를 신청하며, "플랫폼"은 이용자가 구매신청을 함에 있어서
              다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우
              제2호 내지 제4호의 적용을 제외할 수 있습니다.
            </p>
            <p>1. 재화 등의 검색 및 선택</p>
            <p>
              2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
            </p>
            <p>
              3. 약관내용, 청약철회권이 제한되는 서비스, 배송료·설치비 등의
              비용부담과 관련한 내용에 대한 확인
            </p>
            <p>
              4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는
              표시(예, 마우스 클릭)
            </p>
            <p>
              5. 재화 등의 구매신청 및 이에 관한 확인 또는 "플랫폼"의 확인에
              대한 동의
            </p>
            <p>6. 결제방법의 선택</p>
            <p>제10조 (계약의 성립)</p>
            <p>
              ① "플랫폼"은 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면
              승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는
              경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
              법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
            </p>
            <p>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</p>
            <p>
              2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및
              용역을 구매하는 경우
            </p>
            <p>
              3. 기타 구매신청에 승낙하는 것이 "플랫폼" 기술상 현저히 지장이
              있다고 판단하는 경우
            </p>
            <p>
              ② "플랫폼"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게
              도달한 시점에 계약이 성립한 것으로 봅니다.
            </p>
            <p>
              ③ "플랫폼"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및
              판매가능 여부, 구매신청의 정정 취소등에 관한 정보등을 포함하여야
              합니다.
            </p>
            <p>제11조(지급방법)</p>
            <p>
              "플랫폼"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음
              각호의 방법중 가용한 방법으로 할 수 있습니다. 단, "플랫폼"은
              이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도
              추가하여 징수할 수 없습니다.
            </p>
            <p>1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</p>
            <p>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</p>
            <p>3. 온라인무통장입금</p>
            <p>4. 전자화폐에 의한 결제</p>
            <p>5. 수령시 대금지급</p>
            <p>6. 마일리지 등 "플랫폼"이 지급한 포인트에 의한 결제</p>
            <p>
              7. "플랫폼"과 계약을 맺었거나 "플랫폼"이 인정한 상품권에 의한 결제
            </p>
            <p>8. 기타 전자적 지급 방법에 의한 대금 지급 등</p>
            <p>제12조(수신확인통지·구매신청 변경 및 취소)</p>
            <p>
              ① "플랫폼"은 이용자의 구매신청이 있는 경우 이용자에게
              수신확인통지를 합니다.
            </p>
            <p>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치등이 있는 경우에는
              수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고
              "플랫폼"은 배송전에 이용자의 요청이 있는 경우에는 지체없이 그
              요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는
              제15조의 청약철회 등에 관한 규정에 따릅니다.
            </p>
            <p>제13조(재화등의 공급)</p>
            <p>
              ① "플랫폼"은 이용자와 재화등의 공급시기에 관하여 별도의 약정이
              없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할
              수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만,
              "플랫폼"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는
              대금의 전부 또는 일부를 받은 날부터 2영업일 이내에 조치를
              취합니다. 이때 "플랫폼"은 이용자가 재화등의 공급 절차 및 진행
              사항을 확인할 수 있도록 적절한 조치를 합니다.
            </p>
            <p>
              ② "플랫폼"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용
              부담자, 수단별 배송기간 등을 명시합니다. 만약 "플랫폼"이 약정
              배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야
              합니다. 다만 "플랫폼"이 고의·과실이 없음을 입증한 경우에는
              그러하지 아니합니다.
            </p>
            <p>제14조(환급)</p>
            <p>
              "플랫폼"은 이용자가 구매신청한 재화등이 품절 등의 사유로 인도 또는
              제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고
              사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 2영업일
              이내에 환급하거나 환급에 필요한 조치를 취합니다.
            </p>
            <p>제15조(청약철회 등)</p>
            <p>
              ① "플랫폼"과 재화등의 구매에 관한 계약을 체결한 이용자는
              수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수
              있습니다.
            </p>
            <p>
              ② 이용자는 재화등을 배송받은 경우 다음 각호의 1에 해당하는
              경우에는 반품 및 교환을 할 수 없습니다.
            </p>
            <p>
              1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
              경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한
              경우에는 청약철회를 할 수 있습니다)
            </p>
            <p>
              2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히
              감소한 경우
            </p>
            <p>
              3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가
              현저히 감소한 경우
            </p>
            <p>
              4. 같은 성능을 지닌 재화등으로 복제가 가능한 경우 그 원본인 재화
              등의 포장을 훼손한 경우
            </p>
            <p>
              5. 용역 또는 문화산업진흥기본법 제2조 제5호의 디지털콘텐츠의
              제공이 게시된 경우(다만 가분적 용역 및 가분적 디지털콘텐츠로
              구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는
              그러하지 아니하다)
            </p>
            <p>
              6. 주문에 따라 개별적으로 생산되는 물품 등 사전에 해당 거래에
              대하여 별도로 고지하고 이용자의 서면(전자문서포함) 동의를 받은
              경우
            </p>
            <p>
              ③ 제2항제2호 내지 제4호의 경우에 "플랫폼"이 사전에 청약철회 등이
              제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나
              시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의
              청약철회등이 제한되지 않습니다.
            </p>
            <p>
              ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해
              재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수
              있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            </p>
            <p>제16조(청약철회 등의 효과)</p>
            <p>
              ① "플랫폼"은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에
              이미 지급받은 재화등의 대금을 환급합니다. 이 경우 "플랫폼"이
              이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여
              전자상거래 등에서의 소비자보호에 관한 법률 시행령 제21조의 3에서
              정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
            </p>
            <p>
              ② "플랫폼"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는
              전자화폐 등의 결제수단으로 재화등의 대금을 지급한 때에는 지체없이
              당해 결제수단을 제공한 사업자로 하여금 재화등의 대금의 청구를 정지
              또는 취소하도록 요청합니다.
            </p>
            <p>
              ③ 청약철회등의 경우 공급받은 재화등의 반환에 필요한 비용은
              이용자가 부담합니다. "플랫폼"은 이용자에게 청약철회등을 이유로
              위약금 또는 손해배상을 청구하지 않습니다. 다만 재화등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회등을
              하는 경우 재화등의 반환에 필요한 비용은 "플랫폼"이 부담합니다.
            </p>
            <p>
              ④ 이용자가 재화등을 제공받을때 발송비를 부담한 경우에 "플랫폼"은
              청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록
              명확하게 표시합니다.
            </p>
            <p>제17조(개인정보보호)</p>
            <p>
              ① "플랫폼"은 이용자의 정보수집시 구매계약 이행에 필요한 최소한의
              정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은
              선택사항으로 합니다.
            </p>
            <p>1. 성명</p>
            <p>2. 주소</p>
            <p>3. 전화번호</p>
            <p>4. 카카오 회원번호</p>
            <p>5. 희망ID(회원의 경우)</p>
            <p>6. 비밀번호(회원의 경우)</p>
            <p>7. 전자우편주소(또는 이동전화번호)</p>
            <p>
              ② "플랫폼"이 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는
              반드시 당해 이용자의 동의를 받습니다.
            </p>
            <p>
              ③ 제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나
              제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 플랫폼이 집니다.
              다만, 다음의 경우에는 예외로 합니다.
            </p>
            <p>
              1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의
              정보(성명, 주소, 전화번호)를 알려주는 경우
            </p>
            <p>
              2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
              개인을 식별할 수 없는 형태로 제공하는 경우
            </p>
            <p>3. 재화등의 거래에 따른 대금정산을 위하여 필요한 경우</p>
            <p>4. 도용방지를 위하여 본인확인에 필요한 경우</p>
            <p>
              5. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
            </p>
            <p>
              ④ "플랫폼"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는
              경우에는 개인정보 보호책임자의 신원(소속, 성명 및 전화번호, 기타
              연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
              관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
              개인정보보호법 제15조 제2항이 규정한 사항을 미리 명시하거나
              고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
            </p>
            <p>
              ⑤ 이용자는 언제든지 "플랫폼"이 가지고 있는 자신의 개인정보에 대해
              열람 및 오류정정을 요구할 수 있으며 "플랫폼"은 이에 대해 지체없이
              필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한
              경우에는 "플랫폼"은 그 오류를 정정할 때까지 당해 개인정보를
              이용하지 않습니다.
            </p>
            <p>
              ⑥ "플랫폼"은 개인정보 보호를 위하여 관리자를 한정하여 그 수를
              최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의
              분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든
              책임을 집니다.
            </p>
            <p>
              ⑦ "플랫폼" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의
              수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를
              지체없이 파기합니다.
            </p>
            <p>제18조("회사"의 의무)</p>
            <p>
              ① "회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를
              하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
              재화·용역을 제공하는데 최선을 다하여야 합니다.
            </p>
            <p>
              ② "회사"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록
              이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야
              합니다.
            </p>
            <p>
              ③ "회사"가 상품이나 용역에 대하여 「표시·광고의공정화에관한법률」
              제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은
              때에는 이를 배상할 책임을 집니다.
            </p>
            <p>
              ④ "회사"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
              발송하지 않습니다.
            </p>
            <p>제19조(회원의 ID 및 비밀번호에 대한 의무)</p>
            <p>
              ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
              있습니다.
            </p>
            <p>
              ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
              안됩니다.
            </p>
            <p>
              ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
              있음을 인지한 경우에는 바로 "플랫폼"에 통보하고 "플랫폼"의 안내가
              있는 경우에는 그에 따라야 합니다.
            </p>
            <p>
              ④ “플랫폼”은 “플랫폼”의 과실 없이 회원이 상기 제1항 내지 제3항을
              위반하여 회원에게 발생한 손해에 대하여 아무런 책임을 부담하지
              않습니다.
            </p>
            <p>제20조(이용자의 의무)</p>
            <p>이용자는 다음 행위를 하여서는 안됩니다.</p>
            <p>1. 신청 또는 변경시 허위 내용의 등록</p>
            <p>2. 타인의 정보 도용</p>
            <p>3. "플랫폼"에 게시된 정보의 변경</p>
            <p>
              4. "플랫폼"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
              또는 게시
            </p>
            <p>5. "플랫폼" 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
            <p>
              6. "플랫폼" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </p>
            <p>
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
              정보를 플랫폼에 공개 또는 게시하는 행위
            </p>
            <p>제21조(연결"플랫폼"과 피연결"플랫폼" 간의 관계)</p>
            <p>
              ① 상위 "플랫폼"과 하위 "플랫폼"이 하이퍼 링크(예: 하이퍼 링크의
              대상에는 문자, 이미지 및 동영상 등이 포함됨) 방식 등으로 연결된
              경우, 전자를 연결 "플랫폼"(웹 사이트)이라고 하고 후자를 피연결
              "플랫폼"(웹사이트)이라고 합니다.
            </p>
            <p>
              ② 연결"플랫폼"은 피연결"플랫폼"이 독자적으로 제공하는 재화등에
              의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을
              연결"플랫폼"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
              경우에는 그 거래에 대한 보증책임을 지지 않습니다.
            </p>
            <p>제22조("플랫폼" 제공 컨텐츠의 저작권 보호)</p>
            <p>
              ① 마스앤비너스에서 제공하는 각종 서비스의 내용을 보호하고, 저작권
              등 타인의 권리를 침해하지 않도록 하기 위하여 다음과 같은 저작권
              정책을 운영하고 있으니, 회원 및 마스앤비너스 이용 고객 분들은 이를
              살펴보시고 숙지하시어 타인의 권리를 침해하지 않도록 하시기
              바랍니다.
            </p>
            <p>1. 게시물의 저작권 귀속 및 책임</p>
            <p>
              - 회원이 작성하여 서비스에 게재 또는 등록한 답변, 자료, 사진 등에
              대한 저작권은 회원 본인에게 있는 것으로 추정되며, 게시물이 타인의
              저작권 등 지적재산권을 침해하는 경우 그에 대한 책임은 회원 본인이
              부담합니다.
            </p>
            <p>
              - 회사가 저작권을 가지고 있는 게시물을 이용하는 경우에는 학술,
              연구 등 개인적 목적으로만 이용하여야 하며, 회사의 명시적 동의 없이
              게시물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로
              이용하거나 영리적 목적으로 이용하는 경우에는 민사상의 책임뿐만
              아니라 형사처벌을 받을 수도 있습니다.
            </p>
            <p>2. 회원 및 제3자의 저작권 보호</p>
            <p>
              - 저작권자의 명시적 동의 없이 타인의 저작물(컴퓨터프로그램 포함.
              이하 같습니다)의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로
              이용하는 것은 저작권자의 권리를 침해하는 행위이므로 복제/배포/전송
              등의 방법으로 타인의 저작권을 침해하는 게시물을 서비스에 게재 또는
              등록하여서는 아니 됩니다.
            </p>
            <p>
              - 타인의 저작물을 인용한 게시물인 경우 그 인용부분이 회원 개인의
              의견을 뒷받침하기 위해 일부 인용된 것이 아니라 게시물의 핵심적인
              부분을 구성하고 있다면 출처를 밝히더라도 저작권 침해에 해당되므로
              저작권자의 명시적 동의 없이 이러한 게시물을 서비스에 게재 또는
              등록하여서는 아니 됩니다.
            </p>
            <p>
              - 사이트 서비스에 게재 또는 등록된 회원의 게시물을 제3자가
              사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤
              사용하여야 합니다.
            </p>
            <p>
              - 사이트는 회원이 서비스 이용과정에서 타인의 저작권, 상표권,
              의장권 등 지적 재산권을 침해하는 경우 어떠한 책임도 부담하지
              않습니다.
            </p>
            <p>
              - 서비스상의 게시물은 회원들간의 지식공유를 위해 회원이 게재한
              것이므로 회사는 게시물에 대한 정확성에 대해서는 보증을 하지
              않습니다.
            </p>
            <p>
              - 사이트는 회원들의 게시물 이용에 따른 동의절차를 중개하거나 그
              과정에 개입 하지 않습니다.
            </p>
            <p>3. 게시물의 중단 및 재개 요청</p>
            <p>
              - 저작권 침해신고에 관한 사항은 고객센터 또는 메일을 통해 연락
              주시기 바랍니다.
            </p>
            <p>제 23조(서비스이용료)</p>
            <p>
              회사는 회원과 의료진 간의 자유로운 상담서비스 이용에 필요한 각종
              서비스를 제공하고, 회사의 내부 정책에 따라 그에 대한 이용료(서비스
              이용료) 또는 상담촉진서비스이용료 등을 부과할 수 있습니다.
            </p>
            <p>제 24조(비대면 진료 대금의 정산)</p>
            <p>
              의료진이 마스앤비너스를 통하여 수행한 원격진료 용역 대금(이하
              “비대면진료 대금”이라 함)에 대한 정산은 원격진료서비스 가격에서
              제14조의 서비스 이용료 및 비대면 진료촉진서비스이용료 등을 제외한
              금액을 기준으로 산정되며, 회사가 비대면진료 서비스 가격에 대한
              가이드를 제공하되, 의료진은 서비스 이용료 등을 고려하여 비대면진료
              서비스 가격을 자유롭게 정할 수 있습니다.
            </p>
            <p>
              정산주기는 금월 진료 완료 건에 대한 비대면진료 대금을 익월 10일에
              지급하는 것을 원칙으로 하며, 회사와 의료진이 별도로 정산주기를
              정한 경우에는 이 기준에 따릅니다. 단, 회사는 비대면 진료 대금을
              지급하기 이전에 회원이 취소 또는 환불의 의사를 표시한 경우 비대면
              진료 대금의 지급을 보류할 수 있습니다.
            </p>
            <p>
              회사는 의료진의 귀책사유로 인해 발생한 비용을 비대면진료 대금에서
              공제할 수 있습니다.
            </p>
            <p>
              회사는 아래의 사유가 발생하는 경우 비대면 진료 대금의 정산을
              유보할 수 있습니다.
            </p>
            <p>
              ① 회원이 신용카드로 결제한 경우, [여신전문금융업법]상의 규정에
              따라 회사는 신용카드 부정사용을 통한 허위거래여부를 판단하기 위해
              최고 60일까지 비대면진료 대금에 대한 송금을 보류할 수 있습니다. 이
              경우, 회사는 비대면진료 사실 확인을 위한 증빙을 의료진에 요구할 수
              있으며, 회사는 사실 여부 확인 후 비대면진료 대금을 지급할 수
              있습니다.
            </p>
            <p>
              ② 법원 등 제3자가 자격을 갖추어 비대면 진료 대금의 지급보류를
              요청한 경우 회사는 보류요청이 해제될 때까지 관련 비대면 진료
              대금의 송금을 보류하거나 정당한 채권자에게 지급할 수 있습니다
            </p>
            <p>
              ③ 회사는 의료진이 상담서비스 제공 부적격자로 적발되거나, 회원
              클레임의 다수 발생으로 인한 환불 등의 요청이 염려되는 경우 일정
              기간 정산을 보류할 수 있습니다.
            </p>
            <p>
              ④ 의료진과 회원 간에 동일한 유형의 클레임이 지속적으로 발생하는
              경우, 회원 클레임에 대비하여 일정기간 비대면 진료 대금의 정산을
              유보할 수 있습니다.
            </p>
            <p>
              본 조에 정한 외에도 법률의 규정에 의하거나 합리적인 사유가 있는
              경우에는 회사는 의료진에게 통지하고 비대면 진료 대금의 전부 또는
              일부에 대한 정산을 일정 기간 유보할 수 있습니다.
            </p>
            <p>제 25조(정보의 제공 및 광고의 계약)</p>
            <p>
              ① “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한
              정보를 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수
              있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의
              등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신
              거절을 할 수 있습니다.
            </p>
            <p>
              ② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는
              경우에는 “회원”의 사전 동의를 받아서 전송합니다. 다만, “회원”의
              거래관련 정보, 상담하기, 혜택받기 서비스로 인한 이메일 발송 및
              문자, 통화와 관련된 내용은 제외됩니다.
            </p>
            <p>
              ③ “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지,
              어플리케이션, 전자우편 등에 광고를 게재할 수 있습니다. 광고가
              게재된 전자우편을 수신한 “회원”은 수신거절을 “회사”에게 할 수
              있습니다.
            </p>
            <p>
              ④ “이용자(회원, 비회원 포함)”는 회사가 제공하는 서비스와 관련하여
              게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지
              않습니다.
            </p>
            <p>
              ⑤ ”회사”는 회원가입 하신 “이용자(의사 및 약사)”분들을 위해 온,
              오프라인으로 무료 광고를 대행할 수 있습니다.
            </p>
            <p>제26조(분쟁해결)</p>
            <p>
              ① "플랫폼"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그
              피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.
            </p>
            <p>
              ② "플랫폼"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로
              그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
              이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </p>
            <p>
              ③ "플랫폼"과 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의
              피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가
              의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </p>
            <p>제27조(재판권 및 준거법)</p>
            <p>
              ① "플랫폼"과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소
              당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를
              관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의
              주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
              민사소송법상의 관할법원에 제기합니다.
            </p>
            <p>
              ② "플랫폼"과 이용자간에 제기된 전자상거래 소송에는 한국법을
              적용합니다.
            </p>
            <p>제 28조(책임제한)</p>
            <p>
              ① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여
              “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이
              면제됩니다.
            </p>
            <p>
              ② “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에
              대하여는 책임을 지지 않습니다.
            </p>
            <p>
              ③ “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의
              신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
            </p>
            <p>
              ④ “회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를
              매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
            </p>
            <p>
              ⑤ “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
              규정이 없는 한 책임을 지지 않습니다.
            </p>
            <p>
              ⑥ 전화처방서비스는 정부 정책의 변경에 따라 예고 없이 중단될 수
              있으며, “회사”는 이에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>
              ⑦ “회사”는 정부가 한시적으로 허용한 통신진료를 보조하는 시스템을
              제공할 뿐, 회원과 의료진(의사 및 병원)간 진료 행위 및 회원 본인의
              건강에 관한 의사 결정에 대해 아무런 책임을 지지 않습니다.
            </p>
            <p>제 24조(준거법 및 재판관할)</p>
            <p>
              ① “회사”와 “회원” 간 제기된 소송은 대한민국법을 준거법으로 합니다.
            </p>
            <p>
              ② “회사”와 “회원”간 발생한 분쟁에 관한 소송은 제소 당시의 “회원”의
              주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
              전속관할로 합니다. 단, 제소 당시 “회원”의 주소 또는 거소가
              명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
            </p>
            <p>부칙</p>
            <p>1. 현행 서비스이용약관 시행일자 : 2023년 2월24일</p>
            <p>2. 이전의 서비스이용약관은 아래에서 확인하실 수 있습니다.</p>
            <p>[구 서비스이용약관 링크제공]</p>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="agreement-buttons">
        <button
          onClick={() => {
            handleYearClick("2021");
          }}
          style={{
            background: displayYear === "2021" ? "#6A43B8" : "",
            color: displayYear === "2021" ? "white" : "",
          }}
        >
          2021
        </button>
        <button
          onClick={() => {
            handleYearClick("2023");
          }}
          style={{
            background: displayYear === "2023" ? "#6A43B8" : "",
            color: displayYear === "2023" ? "white" : "",
          }}
        >
          2023
        </button>
      </div>
    </>
  );
}

export default ServiceAgreement;
