import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { updateMars } from "utils/data";
import { PRODUCTS } from "utils/products";
import getAvailTimes from "utils/scheduler";
import { getDoctorInfo } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { parseSurvey, slack2ops, won } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import * as mutations from "../../graphql/mutations";


function ExoCustomerCard(props) {
  const [isDetail, setIsDetail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [ssn, setSSN] = useState("");

  const [cancelReason, setCancelReason] = useState("");
  const [answers, setAnswers] = useState([]);
  const [signedURL, setSignedURL] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [shampDeliveryNumber, setShampDeliveryNumber] = useState("");
  const [elapsedTime, setElapsedTime] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payMessage, setPayMessage] = useState("");
  const [message, setMessage] = useState("");
  const [payResult, setPayResult] = useState(null);
  const [type, setType] = useState("재진");
  const [doctor, setDoctor] = useState("doctor2");
  const [rtime, setRtime] = useState(0);
  const [rday, setRday] = useState("");
  const [rdate, setRdate] = useState(0);
  const [rmonth, setRmonth] = useState(0);
  const [reservationColor, setReservationColor] = useState("white");
  const [items, setItems] = useState([]);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);


  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      //set name, ssn, phone
      setDeliveryNumber(p.delivery_number);
      setName(props.patient.name);
      setPhone(props.patient.phone);
    }
    asyncWrapper();
  }, []);

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  return (
    <>
      <div>
        <Card>
          <CardBody>
            {/* Default Row */}
            <Row>
              <Col md="2" style={{ paddingLeft: 20 }}>
                <img
                  alt="..."
                  className="rounded-circle"
                  src={require("assets/img/somebody.jpg")}
                  height="64"
                ></img>
              </Col>
              <Col
                onClick={() => {
                  setIsDetail(!isDetail);
                }}
                md="8"
                style={{
                  padding: "14px 4px 0px 0px",
                  textAlign: "left",
                }}
              >
                <div style={{ lineHeight: 1.2 }}>
                  <b>{props.patient.name}</b>님 ({parsePhoneNumber(props.patient.phone)})
                </div>
                <div>
                  키트신청날짜(결제): {new Date(props.patient.createdAt).toLocaleString()}
                </div>




              </Col>

              <Col
                md="2"
                style={{
                  textAlign: "left",
                  marginLeft: "-8px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                
                  <div>
                    <label>키트 등기번호</label>
                    <input
                      value={deliveryNumber}
                      type="text"
                      onChange={(e) => {
                        setDeliveryNumber(e.target.value);
                      }}
                    ></input>
                    <Button
                      onClick={async () => {
                        if (deliveryNumber === "") {
                          alert("등기번호 미입력!");
                          return;
                        }
                        if (window.confirm(props.patient.name + "님 키트 등기번호 입력 완료: " + deliveryNumber)) {
                          const marsInfo = {
                            id: props.patient.id,
                            status: "kit_sent",
                            delivery_number: deliveryNumber,
                          };
                          const res = await updateMars(marsInfo);
                        }
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "#444444",
                        margin: "4px 0px 0px 0px",
                      }}
                      className="btn-sm btn-primary"
                    >
                      입력 완료
                    </Button>

                  </div>
                
              </Col>

            </Row>

            {isDetail && (
              <div style={{ paddingTop: 20, marginTop: "-0px" }}>
                <Row>

                  <Col md="6">
                    <div
                      style={{
                        border: "1px solid #ececec",
                        padding: "14px",
                        paddingBottom: 0,
                        minHeight: 180,
                      }}
                    >


                      <div>
                        <h5>
                          <b>유전자의 마법사</b>
                        </h5>
                        <div>결제여부: {props.patient.isPaid}</div>
                        
                        <div>배송주소: {`${props.patient.addr} ${props.patient.addr2} ${props.patient.zip}`}</div>
                        <div>이메일: {props.patient.email}</div>


                      </div>

                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
export default ExoCustomerCard;
