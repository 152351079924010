import React from "react";
import Lottie from "lottie-react";

// assets
import Lab from "../assets/ExogeneLab.json";

// css
import "./exogenePageStyling.css";

export default function ExogeneSummary() {
  return (
    <div className="exogene-summary-container">
      <div className="exogene-content-wrapper">
        <span className="exogene-title-med" style={{ color: "white" }}>
          탈모 유전자 검사란?
        </span>
        <Lottie
          animationData={Lab}
          autoPlay={true}
          style={{
            width: "100%",
            placeSelf: "center",
          }}
        ></Lottie>
        <span className="exogene-text-default" style={{ marginTop: "50px" }}>
          MARS x EXOGENE “DNA 탈모 검사’는 과학적인 유전자 검사를 통해
          남성탈모의 가능성을 파악합니다. 간편하게 홈키트를 통해 ① 남성형 탈모와
          ② 원형탈모를 일으키는 유전자의 유무 여부를 확인하실 수 있습니다.
        </span>
        &nbsp;
        <span className="exogene-text-default">
          유전 탈모 조기 발견으로 빠르게 대처하여 탈모치료에 대한 비용을
          절감하세요!
        </span>
      </div>
    </div>
  );
}
