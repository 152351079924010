import React, { useState, useEffect } from "react";
import { PRODUCTS } from "utils/products";
import { parseDateString } from "utils/util";
import { getUser } from "utils/data";

/**
 *
 * @param {answers, hairlineKey, haircenterKey, hairgeneralKey} props
 *
 */
export default function RepeatChartV15(props) {
  const { patient, answers, id } = props;
  const [pastPill, setPastPill] = useState("");
  const [pastMinox, setPastMinox] = useState("");
  const [minoxMonth, setMinoxMonth] = useState(""); // unavailable
  const [newPill, setNewPill] = useState("");
  const [newMinox, setNewMinox] = useState("");
  const [sideEffects, setSideEffects] = useState("");
  const [lastAppt, setLastAppt] = useState();
  const [displayYeyak, setDisplayYeyak] = useState();

  useEffect(() => {
    console.log(patient)
  }, []);

  // 최근 처방 minox month -> unavailable

  useEffect(() => {
    try {
      setPastMinox(answers[0]["r_last_minox"]);
      setPastPill(answers[0]["r_last_pill"]);
      setNewMinox(answers[0]["r_new_minox"]);
      setNewPill(answers[0]["r_new_pill"]);
      setSideEffects(JSON.parse(patient.extraInfo).sideEffect);
      setLastAppt(patient.yeyak_time);
      if (pastMinox === "아니요") {
        // console.log('yes')
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const parseDate = (time) => {
    var dateArray = time.split("-");
    var year = dateArray[0].substring(2, dateArray[0].length);
    var month = dateArray[1];
    var day = dateArray[2].substring(0, 2);
    return year + "년 " + month + "월 " + day + "일";
  };

  const copyItem = () => {
    var copyText = document.getElementsByClassName(id);
    var append = "";
    for (let i = 0; i < copyText.length; i++) {
      append = append.concat(" - " + copyText[i].innerText + "\n");
    }
    navigator.clipboard.writeText(append).then(
      () => {
        alert("문진 복사되었습니다");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <button id="copy-button-doctor" onClick={copyItem}>
        문진복사
      </button>
      <table className="fv-chart-table">
        <tbody>
          <tr>
            <li className={id}>
              최근진료:{" "}
              <span id="doctor-patient-answer">
                {parseDate(patient.yeyak_time)}
              </span>
            </li>
          </tr>
          <tr>
            <li className={id}>
              최근처방:{" "}
              <span id="doctor-patient-answer">
                {PRODUCTS[pastPill].name ? PRODUCTS[pastPill].name : ""}
                {/* {answers[0]["r_last_minox"] !== "아니요"
                  ? " &" + JSON.stringify(pastMinox)
                  : ""} */}
                {/* {pastMinox !== "아니요" ? " & " + PRODUCTS[pastMinox].name : ""} */}

                {pastMinox !== "" && pastMinox !== "아니요"
                  ? " & " + PRODUCTS[pastMinox].name
                  : ""}

                {/* {patient.pill !== "" ? PRODUCTS[patient.pill].name : "없음"}
                {patient.minox !== "아니요"
                  ? " &" + PRODUCTS[patient.minox].name
                  : ""} */}
              </span>
              {/* {PRODUCTS[props.answers[0]["r_last_minox"]].name ? 'aaa': 'sdf'} */}
            </li>
          </tr>
          <tr>
            <li className={id}>
              부작용:{" "}
              <span id="doctor-patient-answer">
                {!sideEffects.includes("없음") && sideEffects !== "" ? (
                  <mark style={{ background: "#E6FFA0" }}>{sideEffects}</mark>
                ) : (
                  <>없음</>
                )}
              </span>
            </li>
          </tr>

          {props.answers[0]["r_msg"] && (
            <tr>
              <td>개인 메시지</td>
              <td>
                {props.answers[0]["r_msg"]
                  ? props.answers[0]["r_msg"].ans
                  : "없음"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
