import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";


// core components

function Review(props) {
  return (
    <>
      <div className="landing4 landing4-background">

        <Row className="justify-content-center">
          <Col md="6" xs="12" >
            <Row className="text-center justify-content-center">
              <Col md="10" xs="12">
                {/* <img width="100" alt="..." src={require("assets/img/capsules-solid.svg")}></img> */}
                <div className="heading2" style={{ textAlign: "left", paddingBottom: "29px" }}>
                  진료받은 <b className="orange-bold">95.9%</b> 가<br />
                  추천합니다

                </div>
                <div className="line1-white" />
                <div className="left heading2-sub">
                  진료받은 분들의 <b>99%</b>가 계속 서비스를 이용하실 계획이며, <b>96%</b>가 지인에게 추천합니다. (고객만족 조사결과 2022년 2분기)
                </div>
              </Col>
            </Row>
            <br /><br />
            <Row className="justify-content-center">
              <Col md={10} xs={12}>
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td className="left">
                        <img alt="..." src={require("assets/img/review1.png")} style={{ width: "310px", paddingBottom: "12px" }}></img>
                      </td>
                    </tr>
                    <tr>
                      <td className="right">
                        <img alt="..." src={require("assets/img/review2.png")} style={{ width: "310px", paddingBottom: "12px" }}></img>
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        <img alt="..." src={require("assets/img/review3.png")} style={{ width: "310px", paddingBottom: "12px" }}></img>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <img alt="..." src={require("assets/img/rating-white.png")} style={{ width: "96px" }}></img>
                      </td>
                      <td className="text-center">
                        <img alt="..." src={require("assets/img/retention-white.png")} style={{ width: "96px" }}></img>
                      </td>
                      <td className="text-center">
                        <img alt="..." src={require("assets/img/reco-white.png")} style={{ width: "96px" }}></img>
                      </td>
                    </tr>
                    <tr className="text-center">
                      <td>고객평점</td>
                      <td>재구매율</td>
                      <td>추천의향</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>




          </Col>
        </Row>

      </div>
    </>
  );
}
export default Review;
