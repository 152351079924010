import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { updateChart } from "utils/data";
import { parseSurvey, slack2ops, won } from "utils/util";
import { sendAnalytics } from "utils/analytics";
import { getDoctorInfo } from "utils/util";
import * as mutations from "../../../graphql/mutations";
import { parsePhoneNumber } from "utils/util";
import { maskSSN } from "utils/util";
import { API, Storage } from "aws-amplify";
import { getENV } from "utils/util";

// css
import "./styling/profileEditorStyles.css";

/**
 * FOR VENUS
 * @param {*} props
 * @returns
 */
export default function ProfileEditor(props) {
  const { openProfileEdit, setOpenProfileEdit, profileEditor } = props;
  const toggle = () => setOpenProfileEdit(!openProfileEdit);

  // useEffect(() => {
  //   console.log(props);
  // }, []);

  return (
    <Modal isOpen={openProfileEdit} toggle={toggle}>
      <ModalBody className="op-profile-info-container-venus">
        <p style={{ placeSelf: "center", fontSize: "15px", fontWeight: 600 }}>
          기본정보 수정
        </p>
        <div id="edit-name-venus">
          <div id="edit-info-venus">이름: </div>
          <input
            type="text"
            value={profileEditor.patient.user_info.name}
            onChange={(e) => {
              profileEditor.setName(e.target.value);
            }}
          />
        </div>
        <div id="edit-ssn-venus">
          <div id="edit-info-venus">주민번호:</div>
          <input
            type="text"
            value={profileEditor.patient.user_info.ssn}
            onChange={(e) => {
              profileEditor.setSSN(e.target.value);
            }}
          />
        </div>
        <div id="edit-phone-venus" style={{ marginBottom: "0" }}>
          <div id="edit-info-venus">연락처:</div>
          <input
            type="text"
            value={profileEditor.patient.user_info.phone}
            onChange={(e) => {
              profileEditor.setPhone(e.target.value);
            }}
          />
        </div>

        <div id="edit-addr-venus" style={{ display: "none" }}>
          <div id="edit-info-venus">주소:</div>
          <textarea
            rows={4}
            type="textarea"
            value={profileEditor.addr}
            onChange={(e) => {
              profileEditor.setAddr(e.target.value);
            }}
            disabled={true}
          />
        </div>

        <div id="edit-addr2-venus" style={{ display: "none" }}>
          <div id="edit-info-venus">상세주소:</div>
          <input
            type="text"
            value={profileEditor.addr2}
            onChange={(e) => {
              profileEditor.setAddr2(e.target.value);
            }}
            disabled={true}
          />
        </div>

        <div id="edit-zip-venus" style={{ display: "none" }}>
          <div id="edit-info-venus">우편번호</div>
          <input
            type="text"
            value={profileEditor.zip}
            onChange={(e) => {
              profileEditor.setZip(e.target.value);
            }}
            disabled={true}
          />
        </div>
      </ModalBody>
      <ModalFooter className="op-profile-footer-venus">
        <button
          onClick={() => {
            setOpenProfileEdit(false);
          }}
          id="profile-edit-cancel-button-venus"
        >
          취소
        </button>
        <button
          onClick={async () => {
            if (
              window.confirm(
                "정말로 " +
                  profileEditor.patient.user_info.name +
                  "님의 환자 정보를 수정 하시겠습니까?"
              )
            ) {
              const patientDetails = {
                id: `user#${profileEditor.patient.kid}`,
                name: profileEditor.name,
                ssn: profileEditor.ssn,
                phone: profileEditor.phone,
                ignoreStatus: "refresh",
              };

              try {
                let updatedPatient = await API.graphql({
                  query: mutations.updateMars,
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                  variables: { input: patientDetails },
                });

                if (updatedPatient) {
                  //send kakao message
                  let users = [
                    { type: "appUserId", id: profileEditor.patient.kid },
                  ];
                  let eventName =
                    "event_user_info_changed" +
                    (getENV() !== "prod" ? "_dev" : "");
                  let eventData = {
                    msg: `${
                      profileEditor.name
                    }님의 정보가 변경 되었어요.\n\n[고객정보]\n이름:\t${
                      profileEditor.name
                    }\n주민번호:\t${maskSSN(
                      profileEditor.ssn
                    )}\n연락처:\t${parsePhoneNumber(profileEditor.phone)}`,
                  };
                  let res = await API.post("marsrest", "/kakao", {
                    body: { users, eventName, eventData },
                  });

                  alert(
                    profileEditor.patient.name + "님 환자정보 변경 완료!\n"
                  );
                  slack2ops(
                    `[운영][${profileEditor.name}] 환자정보 변경\n변경이름:${profileEditor.name} 변경번호:${profileEditor.phone}`
                  );
                }
              } catch (error) {
                alert("에러발생 다시 시도해 주세요: " + error);
              }
            }
            setOpenProfileEdit(false);
          }}
          id="profile-edit-submit-button-venus"
        >
          환자정보 수정
        </button>
      </ModalFooter>
    </Modal>
  );
}
