import { API, Storage } from "aws-amplify";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import { PRODUCTS } from "utils/products";
import { calcPrice4, parseSurvey, won } from "utils/util";
import * as mutations from "../../graphql/mutations";

// css
import "./doctorPDFStyles.css";

export default function PDFPreviewNew(props) {
  const [isPDF, setIsPDF] = useState(false);
  const [pill, setPill] = useState("");
  const [capil, setCapil] = useState();
  const [minox, setMinox] = useState("");
  const [shamp, setShamp] = useState(null);
  const [pillPrice, setPillPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);

  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [pillMonth, setPillMonth] = useState();
  const [capilMonth, setCapilMonth] = useState(1);
  const [minoxMonth, setMinoxMonth] = useState();
  const [month, setMonth] = useState(3);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [fileKey, setFileKey] = useState("");

  useEffect(() => {
    if (props.patient.filekey && props.patient.filekey !== "") {
      setIsUploaded(true);
      setFileKey(props.patient.filekey);

      let extension = props.patient.filekey.split(".").slice(-1);
      if (extension[0] === "pdf") {
        setIsPDF(true);
      }
    }

    let p = props.patient;
    let a = props.patient.ans;
    let ans = JSON.parse(a);

    let r = parseSurvey(
      props.patient.ans,
      props.patient.survey_type,
      props.patient.survey_version
    );
    
    let pill, minox, month, message, pmonth, mmonth;
    if (p.pill !== "" || p.minox !== "") {
      // doctor prescription is there use the values
      pill = p.pill;
      minox = p.minox;
      month = p.month;
      pmonth = p.pillMonth;
      mmonth = p.minoxMonth;
    } else {
      pill = r.pill;
      minox = r.minox;
      month = r.pillMonth;
      pmonth = r.pillMonth;
      mmonth = r.minoxMonth;
    }
    message = r.message;

    // set answers
    setPill(pill);
    setMinox(minox);
    setMonth(month);
    setPillMonth(pmonth);
    setMinoxMonth(mmonth);
    setShamp(p.shamp);
    setCapil(p.capil);

    // setMessage(message);

    let res = calcPrice4(
      pill,
      minox,
      pmonth,
      mmonth,
      null,
      p.capil,
      capilMonth
    );

    setPillPrice(res.pillPrice);
    setCapilPrice(res.capilPrice);
    setMinoxPrice(res.minoxPrice);
    setShampPrice(res.shampPrice);
    setTotal(res.total);
  }, []);

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        className="modal-xl"
        style={{
          width: "100vw",
          display: "flex",
        }}
        id="pdf-modal"
      >
        <div className="modal-header" style={{ marginLeft: "10px" }}>
          <h5 className="modal-title" id="pdfpreviewmodal">
            <b
              className="orange"
              style={{ fontWeight: 600 }}
            >
              {props.patient.name}
            </b>
            님의 처방전
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => props.onClose()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className="modal-body"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            // flexDirection: "row",
            // margin: "auto",
          }}
        >
          <div>
            <div>
              <label
                htmlFor={props.patient.id}
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6A43B8",
                  width: "150px",
                  fontWeight: 600,
                  marginTop: "-30px",
                }}
              >
                처방전 파일 선택
              </label>

              <input
                id={props.patient.id}
                style={{ visibility: "hidden" }}
                type="file"
                accept="application/pdf"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) {
                    // console.log("file: ", file.type);
                    // let filename = "mars_" + Date.now() + file.name;
                    let filename = `p_${nanoid(4)}_${file.name}`;

                    try {
                      let res = await Storage.put(filename, file, {
                        level: "protected",
                        // contentType: "application/pdf",
                        contentType: file.type,
                      });
                      if (res) {
                        // alert(props.patient.name + "님 처방전이 업로드 되었습니다.\n" + "오른쪽");
                        const patientDetails = {
                          id: props.patient.id,
                          filekey: filename,
                          ignoreStatus: "yes",
                        };
                        setFileKey(filename);

                        try {
                          const updatedPatient = await API.graphql({
                            query: mutations.updateMars,
                            authMode: "AMAZON_COGNITO_USER_POOLS",
                            variables: { input: patientDetails },
                          });
                          if (updatedPatient) {
                            props.onUpdateURL(filename);
                            setIsUploaded(true);

                            let extension = filename.split(".").slice(-1);

                            if (extension[0] === "pdf") {
                              setIsPDF(true);
                            } else {
                              setIsPDF(false);
                            }
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }
                    } catch (error) {
                      console.log(error);
                      alert("Error uploading file: ", error);
                    }
                  }
                }}
              />
            </div>
            <div
              style={{
                height: "600px",
                width: "100%",
                minWidth: "500px",
                padding: "5px",
                borderRadius: "5px",
                filter: "blur(0.5px)",
              }}
            >
              {isPDF && (
                <iframe
                  style={{ width: "100%", height: "600px" }}
                  src={
                    props.url +
                    "#page=1&zoom=90&toolbar=0&navpanes=0&statusbar=0&messages=0"
                  }
                />
              )}

              {!isPDF && (
                <img
                  src={props.url}
                  style={{ width: "100%", height: "600px" }}
                ></img>
                // <div style={{background:'red', height: '600px', width:'100%'}}></div>
              )}
            </div>
          </div>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              placeItems: "center",
            }}
          >
            <div
              style={{
                paddingTop: "48px",
                fontSize: "16px",
                margin: "auto",
                marginTop: "-5px",
                marginLeft: "45px",
                width: "100%",
              }}
            >
              <div style={{ marginLeft: '40px' }}>
                처방전 (환자에게 보내지는 처방내역입니다)
                <br />
                {/* <b>
                  처방전 업로드 후 아래 처방내역과 동일 한지 확인 해 주세요!
                </b> */}
              </div>
              <div
                style={{
                  marginTop: "36px",
                }}
              >
                <table className="doctor-pdf-table">
                  <tbody>
                    <tr>
                      <td id="doctor-pdf-category">처방 복용약:</td>
                      <td>
                        <select
                          className="doctor-pdf-table-pill-select"
                          onChange={(e) => {
                            setPill(e.target.value);
                            let res = calcPrice4(
                              e.target.value,
                              minox,
                              pillMonth,
                              minoxMonth,
                              null,
                              capil,
                              capilMonth
                            );
                            setPillPrice(res.pillPrice);
                            setMinoxPrice(res.minoxPrice);
                            setTotal(res.total);
                          }}
                        >
                          <option value="아니요">없음</option>

                          <option
                            selected={
                              pill === "프로페시아" ||
                              pill === PRODUCTS.propecia.id
                            }
                            value={PRODUCTS.propecia.id}
                          >
                            {PRODUCTS.propecia.name}
                          </option>
                          <option
                            selected={
                              pill === "프로페시아 카피약" ||
                              pill === "경동 알로스칸" ||
                              pill === PRODUCTS.aloscan.id
                            }
                            value={PRODUCTS.aloscan.id}
                          >
                            {PRODUCTS.aloscan.name}
                          </option>
                          <option
                            selected={
                              pill === "피나온정" || pill === PRODUCTS.finaon.id
                            }
                            value={PRODUCTS.finaon.id}
                          >
                            {PRODUCTS.finaon.name}
                          </option>
                          <option
                            selected={
                              pill === "아보다트" ||
                              pill === PRODUCTS.avodart.id
                            }
                            value={PRODUCTS.avodart.id}
                          >
                            {PRODUCTS.avodart.name}
                          </option>
                          <option
                            selected={
                              pill === "아보다트 카피약" ||
                              pill === PRODUCTS.damodart.id
                            }
                            value={PRODUCTS.damodart.id}
                          >
                            {PRODUCTS.damodart.name}
                          </option>
                          <option
                            selected={
                              pill === "두타온" || pill === PRODUCTS.dutaon.id
                            }
                            value={PRODUCTS.dutaon.id}
                          >
                            {PRODUCTS.dutaon.name}
                          </option>
                          <option
                            selected={
                              pill === "카필러스 캡슐" ||
                              pill === PRODUCTS.capillus.id
                            }
                            value={PRODUCTS.capillus.id}
                          >
                            {PRODUCTS.capillus.name}
                          </option>
                        </select>
                      </td>
                      <td>
                        {pill && pill !== "아니요" && (
                          <select
                            className="doctor-pdf-table-month-select"
                            onChange={(e) => {
                              setMonth(e.target.value);
                              setPillMonth(e.target.value);
                              let res = calcPrice4(
                                pill,
                                minox,
                                e.target.value,
                                minoxMonth,
                                null,
                                capil,
                                capilMonth
                              );
                              setPillPrice(res.pillPrice);
                              setMinoxPrice(res.minoxPrice);
                              setTotal(res.total);
                            }}
                          >
                            <option
                              selected={String(pillMonth) === "6" && pill !== "아니요"}
                              value="6"
                            >
                              6개월
                            </option>
                            <option
                              selected={String(pillMonth) === "5" && pill !== "아니요"}
                              value="5"
                            >
                              5개월
                            </option>
                            <option
                              selected={String(pillMonth) === "4" && pill !== "아니요"}
                              value="4"
                            >
                              4개월
                            </option>
                            <option
                              selected={String(pillMonth) === "3" && pill !== "아니요"}
                              value="3"
                            >
                              3개월
                            </option>
                            <option
                              selected={String(pillMonth) === "2" && pill !== "아니요"}
                              value="2"
                            >
                              2개월
                            </option>
                            <option
                              selected={String(pillMonth) === "1" && pill !== "아니요"}
                              value="1"
                            >
                              1개월
                            </option>
                          </select>
                        )}
                      </td>
                      <td id="doctor-pdf-price" style={{ textAlign: "right" }}>
                        {pill !== "아니요" && (
                          <>
                            ({pillMonth * PRODUCTS[pill].base}{" "}
                            {PRODUCTS[pill].unit})
                          </>
                        )}{" "}
                        {won(pillPrice)}원
                      </td>
                    </tr>
                    <tr>
                      <td id="doctor-pdf-category">바르는 약:</td>
                      <td>
                        <select
                          className="doctor-pdf-table-pill-select"
                          onChange={(e) => {
                            setMinox(e.target.value);
                            let res = calcPrice4(
                              pill,
                              e.target.value,
                              pillMonth,
                              minoxMonth,
                              null,
                              capil,
                              capilMonth
                            );
                            setPillPrice(res.pillPrice);
                            setMinoxPrice(res.minoxPrice);
                            setTotal(res.total);
                          }}
                        >
                          <option
                            selected={minox === "" || minox === "아니요"}
                            value="아니요"
                          >
                            없음
                          </option>
                          <option
                            selected={
                              // minox === "미녹시딜: 로게인폼" ||
                              // minox === "존슨앤존슨 로게인폼" ||
                              // minox === "로게인폼" ||
                              minox === PRODUCTS.rogaine.id
                            }
                            value={PRODUCTS.rogaine.id}
                          >
                            {PRODUCTS.rogaine.name}
                          </option>
                          <option
                            selected={
                              minox === "현대 마이녹실 5%" ||
                              minox === "현대 마이녹실" ||
                              minox === PRODUCTS.minox.id
                            }
                            value={PRODUCTS.minox.id}
                          >
                            {PRODUCTS.minox.name}
                          </option>
                        </select>
                      </td>
                      <td>
                        {minox && minox !== "아니요" && (
                          <select
                            className="doctor-pdf-table-month-select"
                            onChange={(e) => {
                              // setMonth(e.target.value);
                              setMinoxMonth(e.target.value);
                              let res = calcPrice4(
                                pill,
                                minox,
                                pillMonth,
                                e.target.value,
                                null,
                                capil,
                                capilMonth
                              );
                              setPillPrice(res.pillPrice);
                              setMinoxPrice(res.minoxPrice);
                              setTotal(res.total);
                            }}
                          >
                            <option
                              selected={
                                minoxMonth === "6" && minox !== "아니요"
                              }
                              value="6"
                            >
                              6개월
                            </option>
                            {minox && minox === "rogaine" && (
                              <>
                                <option
                                  selected={
                                    minoxMonth === "5" && minox !== "아니요"
                                  }
                                  value="5"
                                >
                                  5개월
                                </option>
                                <option
                                  selected={
                                    minoxMonth === "4" && minox !== "아니요"
                                  }
                                  value="4"
                                >
                                  4개월
                                </option>
                              </>
                            )}
                            <option
                              selected={
                                minoxMonth === "3" && minox !== "아니요"
                              }
                              value="3"
                            >
                              3개월
                            </option>
                            <option
                              selected={
                                minoxMonth === "2" && minox !== "아니요"
                              }
                              value="2"
                            >
                              2개월
                            </option>
                            <option
                              selected={
                                minoxMonth === "1" && minox !== "아니요"
                              }
                              value="1"
                            >
                              1개월
                            </option>
                          </select>
                        )}
                      </td>
                      <td id="doctor-pdf-price" style={{ textAlign: "right" }}>
                        {minox !== "아니요" ? (
                          <>
                            ({minoxMonth * PRODUCTS[minox].base}
                            {PRODUCTS[minox].unit})
                          </>
                        ) : (
                          <>{""}</>
                        )}
                        {won(minoxPrice)}원
                      </td>
                    </tr>

                    {/* capilus */}
                    <tr>
                      <td id="doctor-pdf-category">영양제:</td>
                      <td>
                        <select
                          className="doctor-pdf-table-pill-select"
                          onChange={(e) => {
                            setCapil(e.target.value);
                            let res = calcPrice4(
                              pill,
                              minox,
                              pillMonth,
                              minoxMonth,
                              null,
                              e.target.value,
                              capilMonth
                            );
                            setPillPrice(res.pillPrice);
                            setMinoxPrice(res.minoxPrice);
                            setShampPrice(res.shampPrice);
                            setCapilPrice(res.capilPrice);
                            setTotal(res.total);
                          }}
                        >
                          <option selected={capil === null} value="">
                            없음
                          </option>
                          <option
                            selected={capil === PRODUCTS.capillus.id}
                            value={PRODUCTS.capillus.id}
                          >
                            {PRODUCTS.capillus.name}
                          </option>
                        </select>
                      </td>
                      <td>
                        {capil && (
                          <select
                            className="doctor-pdf-table-month-select"
                            onChange={(e) => {
                              setCapilMonth(e.target.value);
                              let res = calcPrice4(
                                pill,
                                minox,
                                pillMonth,
                                minoxMonth,
                                null,
                                capil,
                                e.target.value
                              );
                              setPillPrice(res.pillPrice);
                              setCapilPrice(res.capilPrice);
                              setMinoxPrice(res.minoxPrice);
                              setTotal(res.total);
                            }}
                          >
                            <option
                              selected={capil && capilMonth === "1"}
                              value="1"
                            >
                              1개월
                            </option>
                            <option
                              selected={capil && capilMonth === "2"}
                              value="2"
                            >
                              2개월
                            </option>
                            <option
                              selected={capil && capilMonth === "3"}
                              value="3"
                            >
                              3개월
                            </option>
                            <option
                              selected={capil && capilMonth === "4"}
                              value="4"
                            >
                              4개월
                            </option>
                            <option
                              selected={capil && capilMonth === "5"}
                              value="5"
                            >
                              5개월
                            </option>
                            <option
                              selected={capil && capilMonth === "6"}
                              value="6"
                            >
                              6개월
                            </option>
                          </select>
                        )}
                      </td>
                      <td id="doctor-pdf-price" style={{ textAlign: "right" }}>
                        {won(capilPrice)}원
                      </td>
                    </tr>

                    <tr>
                      <td id="doctor-pdf-category">진료비:</td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>10,000원</td>
                    </tr>
                    <tr>
                      <td id="doctor-pdf-category">배송비:</td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>2,900원</td>
                    </tr>
                    <tr style={{ borderTop: "1px solid #aaaaaa" }}>
                      <td />
                      <td />
                      <td />
                      <td style={{ textAlign: "right" }}>총 {won(total)}원</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                style={{ textAlign: "center" }}
                className="doctor-pdf-confirm-container"
              >
                실제 처방전과 내용이 같나요?
                <br />
                <div className="doctor-pdf-input-check">
                  <input
                    type="checkbox"
                    checked={isVerified}
                    onChange={() => {
                      setIsVerified(!isVerified);
                    }}
                  />
                  <label style={{ paddingLeft: "8px", paddingTop: "8px" }}>
                    네, 실제 처방전과 환자에게 보내는 처방내용이 같아요.{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer" style={{ paddingBottom: "20px" }}>
          <Button
            id="doctor-pdf-close-button"
            type="button"
            onClick={() => props.onClose()}
          >
            취소
          </Button>
          <Button
            id="doctor-pdf-complete-button"
            onClick={async () => {
              if (props.patient.status !== "phone") {
                if (
                  prompt(
                    `${props.patient.name} 님은 이미 처방 완료 되셨습니다.\n처방전만 변경하시려면 왼쪽상단 '처방전 파일 선택' 버튼을 이용해 업로드 해주세요.\n현재Status:[${props.patient.status}]`
                  ) !== "marsmars"
                ) {
                  alert("Wrong Password");
                  return;
                }
              }

              const patientDetails = {
                id: props.patient.id,
                status: "pay",
                dstatus: "done",
                pstatus: "reserve",
                isPaid: "0",
                ignoreStatus: "no",
                filekey: fileKey,
                pill: pill,
                minox: minox,
                shamp: shamp,
                capil: capil,
                month: month,
                pillMonth: pillMonth,
                capilMonth: capilMonth,
                minoxMonth: minoxMonth,
                total: total + (shamp ? PRODUCTS[shamp].price : 0),
                prescription_update_time: new Date().toISOString()
              };

              try {
                const updatedPatient = await API.graphql({
                  query: mutations.updateMars,
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                  variables: { input: patientDetails },
                });
                alert("처방전 업로드가 완료 되었습니다!");
              } catch (error) {
                alert(JSON.stringify(error));
              }
            }}
            disabled={!isUploaded || !isVerified}
            className="btn-lg btn-primary"
          >
            처방 완료
          </Button>
        </div>
      </Modal>
    </>
  );
}
