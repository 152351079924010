import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  sendAnalytics,
  pushMarsAnalytics,
  updateMarsAnalytics,
} from "utils/analytics";
import { slack } from "utils/util";
import MonthPriceCard from "../components/MonthPriceCard";
import MonthPriceCardSV from "../components/MonthPriceCardSV";
import SurveyBtnSm from "../components/SurveyBtnSm";
import SurveyBtnSmSV from "../components/SurveyBtnSmSV";
import SurveyNextBtn from "../components/SurveyNextBtn";
import {
  loadAnswerFromLocalStorage,
  saveAnswerToLocalStorage,
} from "../utils/LocalStorage";

function SVMonth(props) {
  const { minox } = props;
  const history = useHistory();
  const qnum = 3;
  const qid = "sv_month";
  const q = "몇 개월 처방을 희망하세요?";
  const [pillMonthAnswer, setPillMonthAnswer] = useState({
    qid: qid,
    q: q,
    qnum: qnum,
    ans: [""],
    btnState: [false, true, false],
  });
  const [minoxMonthAnswer, setMinoxMonthAnswer] = useState({
    qid: qid,
    q: q,
    qnum: qnum,
    ans: [""],
    btnState: [false, true, false],
  });
  const [pillMonth, setPillMonth] = useState(3);
  const [minoxMonth, setMinoxMonth] = useState(3);
  const [canGoNext, setCanGoNext] = useState(false);

  const [startTime, setStartTime] = useState();
  const [analTimeStamp, setAnalTimeStamp] = useState();

  // new for preventing re render
  const [svMonth, setSvMonth] = useState();

  // const [minoxUnit, setMinoxUnit] = useState("");
  const [showMulti, setShowMulti] = useState(false);

  useEffect(() => {
    if (minox === "아니요" || minox === "") {
      setShowMulti(false);
    } else {
      setShowMulti(true);
    }
  }, []);

  useEffect(() => {
    const analyticsWrapper = async () => {
      let st = new Date().getTime();
      setStartTime(st);
      let timestamp = await pushMarsAnalytics(props.kid, "survey", {
        st: st,
        et: null,
        duration: null,
        exit: "yes",
        question: q,
        patient_type: "재진",
      });
      setAnalTimeStamp(timestamp);
    };
    analyticsWrapper();

    document.title = q;

    // load saved answers and populate
    let pillAns = loadAnswerFromLocalStorage(qid);
    if (pillAns) {
      setPillMonthAnswer(pillAns);
      setPillMonth(pillAns.ans[0]);
      setSvMonth(pillAns.ans[0]);
      setCanGoNext(true);
    } else {
      //save default 3month value
      savePillMonthAns(3, [false, true, false]);
    }

    let minoxAns = loadAnswerFromLocalStorage(qid + "_minox");
    if (minoxAns) {
      setMinoxMonthAnswer(minoxAns);
      setMinoxMonth(minoxAns.ans[0]);
      setCanGoNext(true);
    } else {
      //save default 3month value
      saveMinoxMonthAns(3, [false, true, false]);
    }

  }, []);

  const savePillMonthAns = (ans, status) => {
    setPillMonth(ans);
    let answer = { qid, q, qnum, ans: [ans], btnState: status };
    setPillMonthAnswer(answer);
    saveAnswerToLocalStorage(qid, answer);
    // props.selectMonth(ans);
    setSvMonth(ans);
    setCanGoNext(true);
  };

  const saveMinoxMonthAns = (ans, status) => {
    setMinoxMonth(ans);
    let answer = { qid: qid + "_minox", q, qnum, ans: [ans], btnState: status };
    setMinoxMonthAnswer(answer);
    saveAnswerToLocalStorage(qid + "_minox", answer);
    setCanGoNext(true);
  };

  return (
    <>
      {/* questions */}
      <div className="">
        <div className="question-title" style={{ paddingBottom: "6px" }}>
          {q}
        </div>
        {/* <div className="question-explain">바르는 약(미녹시딜 계열)은 특히 정수리 탈모에 효과적이에요. 폼 타입은 바르기 편하고, 스프레이 타입은 가성비가 좋아요.</div> */}
        <div className="question-explain">
          처방은 의사 통화진료 시 변경 가능해요.
        </div>
      </div>
      {/* card expected price */}
      <div
        style={{
          paddingTop: "20px",
        }}
      >
        <MonthPriceCardSV pill={props.pill} minox={props.minox} pillMonth={pillMonth} minoxMonth={minoxMonth} />
      </div>

      {/* answers */}
      {/* <div className="answers-section" style={{ paddingTop: "0px" }}>
        <SurveyBtnSm
          selected={answer.btnState[0]}
          onClick={() => {
            savePillMonthAns("6", [true, false, false]);
          }}
        >
          6 개월
        </SurveyBtnSm>

        <SurveyBtnSm
          selected={answer.btnState[1]}
          onClick={() => {
            savePillMonthAns("3", [false, true, false]);
          }}
        >
          3 개월
        </SurveyBtnSm>

        <SurveyBtnSm
          selected={answer.btnState[2]}
          onClick={() => {
            savePillMonthAns("1", [false, false, true]);
          }}
        >
          1 개월
        </SurveyBtnSm>
      </div> */}

      {/* * * * * * * * * * * * * * * * New Answers * * * * * * * * * * * * * * * * * */}
      {showMulti ? (
        <>
          <div className="answers-title">복용약</div>
          <div className="smaller-button-answers">
            <SurveyBtnSmSV
              selected={pillMonthAnswer.btnState[0]}
              onClick={() => {
                savePillMonthAns("6", [true, false, false]);
              }}
            >
              6 개월
            </SurveyBtnSmSV>

            <SurveyBtnSmSV
              selected={pillMonthAnswer.btnState[1]}
              onClick={() => {
                savePillMonthAns("3", [false, true, false]);
              }}
            >
              3 개월
            </SurveyBtnSmSV>

            <SurveyBtnSmSV
              selected={pillMonthAnswer.btnState[2]}
              onClick={() => {
                savePillMonthAns("1", [false, false, true]);
              }}
            >
              1 개월
            </SurveyBtnSmSV>
          </div>

          {/* * * * * * * * * * * * * */}

          <div className="answers-title">바르는 약</div>
          <div
            className="smaller-button-answers"
            style={{ paddingBottom: "100px" }}
          >
            <SurveyBtnSmSV
              selected={minoxMonthAnswer.btnState[0]}
              onClick={() => {
                saveMinoxMonthAns("6", [true, false, false]);
              }}
            >
              6 개월
            </SurveyBtnSmSV>

            <SurveyBtnSmSV
              selected={minoxMonthAnswer.btnState[1]}
              onClick={() => {
                saveMinoxMonthAns("3", [false, true, false]);
              }}
            >
              3 개월
            </SurveyBtnSmSV>

            <SurveyBtnSmSV
              selected={minoxMonthAnswer.btnState[2]}
              onClick={() => {
                saveMinoxMonthAns("1", [false, false, true]);
              }}
            >
              1 개월
            </SurveyBtnSmSV>
          </div>
          {/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */}
        </>
      ) : (
        <>
          {" "}
          <div className="answers-section" style={{ paddingTop: "0px" }}>
            <SurveyBtnSm
              selected={pillMonthAnswer.btnState[0]}
              onClick={() => {
                savePillMonthAns("6", [true, false, false]);
              }}
            >
              6 개월
            </SurveyBtnSm>

            <SurveyBtnSm
              selected={pillMonthAnswer.btnState[1]}
              onClick={() => {
                savePillMonthAns("3", [false, true, false]);
              }}
            >
              3 개월
            </SurveyBtnSm>

            <SurveyBtnSm
              selected={pillMonthAnswer.btnState[2]}
              onClick={() => {
                savePillMonthAns("1", [false, false, true]);
              }}
            >
              1 개월
            </SurveyBtnSm>
          </div>
        </>
      )}

      {/* next button */}
      <SurveyNextBtn
        disabled={!canGoNext}
        onClick={async () => {
          let et = new Date().getTime();
          let duration = et - startTime;
          await updateMarsAnalytics({
            kid: props.kid,
            timestamp: analTimeStamp,
            et: et,
            duration: duration,
            exit: null,
            answer: svMonth + "개월",
          });

          sendAnalytics({
            type: "log",
            survey_version: props.surveyVersion,
            kid: props.kid,
            from: "sv_month",
            event: `click.next.${qid}`,
            ans: pillMonthAnswer.ans[0],
            msg: `${pillMonthAnswer.ans[0]}개월 선택 완료`,
          });
          slack(
            "[survey1.4][" + props.kid + "] " + pillMonthAnswer.ans[0] + "개월 선택"
          );
          props.changeProgress(4, 5);
          props.selectMonth(pillMonth, minoxMonth);
          history.push(`/${window.location.pathname.split("/")[1]}/sv-photo`);
          // history.push(`/${window.location.pathname.split("/")[1]}/sv-reservation`);
        }}
      >
        다음
      </SurveyNextBtn>
    </>
  );
}
export default SVMonth;
