import React, { useState, useEffect, useCallback } from "react";

// css
import "./feedbackStyles.css";

export default function QuestionTwo(props) {
  // good stuff
  const [goodDoctor, setGoodDoctor] = useState(false); // good doctor | 좋은 의사
  const [goodProf, setGoodProf] = useState(false); // good professionalism | 전문적인 진료
  const [goodExp, setGoodExp] = useState(false); // good experience/explanation | 충분한 설명
  const [goodApp, setGoodApp] = useState(false); // good appointment/scheduling | 정확한 약속시간
  const [goodOther, setGoodOther] = useState(false); // good other | 기타
  //   const [goodList, setGoodList] = useState([]);

  // bad stuff
  const [badDoctor, setBadDoctor] = useState(false); // bad doctor | 불친절함
  const [badExp, setBadExp] = useState(false); // bad explanation/service | 부족한 설명
  const [badProc, setBadProc] = useState(false); // bad diagnosis/procedure | 아쉬운 진료내용
  const [badApp, setBadApp] = useState(false); // bad appointment timing/scheduling | 약속시간
  const [badOther, setBadOther] = useState(false); // bad other | 기타
  //   const [badList, setBadList] = useState([]); //

  // other
  const [disableCheck, setDisableCheck] = useState(true);

  // button options - good
  const goodButtons = [
    {
      id: "one-good",
      text: "친절한 의사선생님",
      onClick: "setGoodDoctor(true)",
      buttonState: "goodDoctor",
    },
    {
      id: "three-good",
      text: "충분한 설명",
      onClick: "setGoodExp(true)",
      buttonState: "goodExp",
    },
    {
      id: "two-good",
      text: "전문적인 진료",
      onClick: "setGoodProf(true)",
      buttonState: "goodProf",
    },
    {
      id: "four-good",
      text: "정확한 약속시간",
      onClick: "setGoodApp(true)",
      buttonState: "goodApp",
    },
    {
      id: "six-good",
      text: "간결한 진료",
      onClick: "setGoodFast(true)",
      buttonState: "goodFast",
    },
    {
      id: "five-good",
      text: "기타",
      onClick: "setGoodOther(true)",
      buttonState: "goodOther",
    },
  ];

  // button options - bad
  const badButtons = [
    {
      id: "one-bad",
      text: "불친절함",
      onClick: "setBadDoctor(true)",
      buttonState: "badDoctor",
    },
    {
      id: "two-bad",
      text: "부족한 설명",
      onClick: "setBadExp(true)",
      buttonState: "badExp",
    },
    {
      id: "three-bad",
      text: "아쉬운 진료내용",
      onClick: "setBadProc(true)",
      buttonState: "badProc",
    },
    {
      id: "four-bad",
      text: "약속시간",
      onClick: "setBadApp(true)",
      buttonState: "badApp",
    },

    {
      id: "six-bad",
      text: "짧은 진료",
      onClick: "setBadFast(true)",
      buttonState: "badFast",
    },
    {
      id: "five-bad",
      text: "기타",
      onClick: "setBadOther(true)",
      buttonState: "badOther",
    },
  ];

  useEffect(() => {
    if (props.goodList.length >= 1 || props.badList.length >= 1) {
      props.setToggleLast(true);
    } else if (props.goodList.length < 1 || props.badList.length < 1) {
      props.setToggleLast(false);
    }
  }, [props.goodList, props.badList]);

  // reset all states if user feedback changes to good/bad
  useEffect(() => {
    props.setGoodList([]);
    props.setBadList([]);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  }, [props.feedback]);

  useEffect(() => {
    if (props.goodList.length >= 4 || props.badList.length >= 4) {
      document
        .querySelectorAll("input[type=checkbox]:not(:checked)")
        .forEach((el) => (el.disabled = disableCheck));
    } else {
      document
        .querySelectorAll("input[type=checkbox]:not(:checked)")
        .forEach((el) => (el.disabled = !disableCheck));
    }
  }, [props.badList.length, props.goodList.length]);

  // adds to list of commendations
  const goodButtonStateChange = (item) => {
    let i = props.goodList.indexOf(item);
    if (props.goodList.length >= 3) {
      setDisableCheck(true);
    }
    if (props.goodList.includes(item)) {
      //   goodList.splice(i, 1);
      props.setGoodList((current) => current.filter((rem) => rem !== item));
    } else {
      props.setGoodList((current) => [...current, item]);
    }
  };

  // adds to list of criticisms
  const badButtonStateChange = (item) => {
    let i = props.badList.indexOf(item);

    if (props.badList.length >= 3) {
      setDisableCheck(true);
    }
    if (props.badList.includes(item)) {
      //   badList.splice(i, 1);
      props.setBadList((current) => current.filter((rem) => rem !== item));
    } else {
      props.setBadList((current) => [...current, item]);
    }
  };

  //   console.log(goodList);

  return (
    <div className="feedback-question-two-container">
      {props.feedback ? (
        <div className="feedback-question-two-good">
          <h1>2. 어떤점이 좋으셨나요? </h1>
          <p>4개 선택가능</p>
          <div className="feedback-question-two-button-container">
            {goodButtons.map((item, key) => {
              return (
                <div key={key}>
                  <input
                    type="checkbox"
                    // disabled={disableCheck}
                    id={item.id}
                    onClick={() => {
                      goodButtonStateChange(item.buttonState);
                    }}
                  ></input>
                  <label htmlFor={item.id}> {item.text}</label>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="feedback-question-two-bad">
          <h1>2. 어떤점이 아쉬웠나요?</h1>
          <p>4개 선택가능</p>
          <div className="feedback-question-two-button-container">
            {badButtons.map((item, key) => {
              return (
                <div key={key}>
                  <input
                    type="checkbox"
                    id={item.id}
                    onClick={() => {
                      badButtonStateChange(item.buttonState);
                    }}
                  ></input>
                  <label htmlFor={item.id}> {item.text}</label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
