import React, { useState, useEffect } from "react";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";


// assets
import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import AlertAnimation from "./errorAssets/animations/AlertAnimation.json";

export default function PaymentCancel() {
  const alertAnimation = {
    loop: true,
    autoplay: true,
    animationData: AlertAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <MarsNavbarWithLine />
      <div className="app-cancel-container">
        <Lottie
          options={alertAnimation}
          width={"106px"}
          height={"auto"}
          style={{ paddingBottom: "51px", pointerEvents: "none" }}
        />
        <p id="error-title">
          장기 미결제로 인해 진료내역이 취소되었어요. <br /> <br /> 해당
          진료건과 관련해서 문의가 있으시다면 맨프롬마스 카카오채널 하단에
          <span style={{ fontWeight: "bold" }}>‘상담직원 연결하기'</span>를 눌러
          말씀해 주세요🙏
        </p>
        <button
          onClick={() => {
            // window.location.href = "kakaotalk://inappbrowser/close";
            console.log("close button click");
          }}
        >
          닫기
        </button>
      </div>
    </div>
  );
}
