import React from "react";
import Lottie from "lottie-react";

// assets
import certified from "../assets/certified.png";
import doctor from "../assets/doctor.json";

// css
import "../styling/componentStyling.css";

export default function Header(props) {
  const { title } = props;
  return (
    <div className="care-message-header">
      <div className="care-message-title">
        {title}
        <div className="care-message-certified">
          <img src={certified} />
        </div>
      </div>

      <div className="care-message-title-lottie">
        <Lottie
          animationData={doctor}
          style={{ background: "transparent", pointerEvents: "none" }}
          autoPlay={true}
        ></Lottie>
      </div>
    </div>
  );
}
