import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// img
import ErrorPic from "./errorAssets/error404.png";

// css
import "./errorStyles.css";

export default function HTML404() {
  const history = useHistory();
  const handleClick = () => {
    // history.push("/index");
    window.open("https://marshealth.io", "_self");
  };

  return (
    <div className="scrollable">
      <div className="error-404-main-container">
        <div className="error-404-content-container">
          <img id="error-404-pic" src={ErrorPic} alt="404-page-not-found" />
          <h2>페이지를 찾을 수 없습니다.</h2>
        </div>
        <div className="error-404-button-container">
          <button id="error-404-button" onClick={handleClick}>
            메인으로 돌아가기
          </button>
        </div>
      </div>
    </div>
  );
}
