/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePhotoEvent = /* GraphQL */ `
  subscription OnCreatePhotoEvent {
    onCreatePhotoEvent {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePhotoEvent = /* GraphQL */ `
  subscription OnUpdatePhotoEvent {
    onUpdatePhotoEvent {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePhotoEvent = /* GraphQL */ `
  subscription OnDeletePhotoEvent {
    onDeletePhotoEvent {
      id
      name
      phone
      birth
      center
      front
      addr
      addr2
      zip
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMars = /* GraphQL */ `
  subscription OnCreateMars {
    onCreateMars {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const onUpdateMars = /* GraphQL */ `
  subscription OnUpdateMars {
    onUpdateMars {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const onDeleteMars = /* GraphQL */ `
  subscription OnDeleteMars {
    onDeleteMars {
      id
      cid
      type
      kid
      createdAt
      updatedAt
      name
      phone
      email
      ssn
      addr
      addr2
      zip
      charts
      cards
      ans
      status
      dstatus
      pstatus
      p2status
      when
      filekey
      delivery_number
      payResult
      isPaid
      nesh_id
      pill
      minox
      shamp
      shamp_id
      month
      minoxMonth
      pillMonth
      total
      survey_type
      survey_version
      prescription
      ignoreStatus
      reservation_time
      yeyak_time
      hairline_url
      haircenter_url
      hairgeneral_url
      doctor
      rm
      r
      capil
      capilMonth
      medi_done_time
      pharma
      extraInfo
      deliMsg
      prescription_update_time
    }
  }
`;
export const onCreateMarsUtil = /* GraphQL */ `
  subscription OnCreateMarsUtil {
    onCreateMarsUtil {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarsUtil = /* GraphQL */ `
  subscription OnUpdateMarsUtil {
    onUpdateMarsUtil {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarsUtil = /* GraphQL */ `
  subscription OnDeleteMarsUtil {
    onDeleteMarsUtil {
      id
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMarsAnalytics = /* GraphQL */ `
  subscription OnCreateMarsAnalytics {
    onCreateMarsAnalytics {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMarsAnalytics = /* GraphQL */ `
  subscription OnUpdateMarsAnalytics {
    onUpdateMarsAnalytics {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMarsAnalytics = /* GraphQL */ `
  subscription OnDeleteMarsAnalytics {
    onDeleteMarsAnalytics {
      kid
      id
      timestamp
      action
      chart_id
      user_pill
      doctor_pill
      from
      st
      et
      duration
      exit
      addon
      question
      answer
      patient_type
      msg
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurvey = /* GraphQL */ `
  subscription OnCreateSurvey {
    onCreateSurvey {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSurvey = /* GraphQL */ `
  subscription OnUpdateSurvey {
    onUpdateSurvey {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSurvey = /* GraphQL */ `
  subscription OnDeleteSurvey {
    onDeleteSurvey {
      id
      name
      survey_platform
      version
      type
      questions_answers {
        items {
          id
          question_id
          question_number
          question_title
          question_title_short
          question_subtitle
          question_type
          question_btn_type
          max_choice
          answers {
            items {
              id
              answer_button_type
              answer_title
              answer_value
              answer_img_id
              answer_img_url
              answer_order
              createdAt
              updatedAt
              surveyQNAAnswersId
            }
            nextToken
          }
          createdAt
          updatedAt
          surveyQuestions_answersId
        }
        nextToken
      }
      flow_chart
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurveyQNA = /* GraphQL */ `
  subscription OnCreateSurveyQNA {
    onCreateSurveyQNA {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const onUpdateSurveyQNA = /* GraphQL */ `
  subscription OnUpdateSurveyQNA {
    onUpdateSurveyQNA {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const onDeleteSurveyQNA = /* GraphQL */ `
  subscription OnDeleteSurveyQNA {
    onDeleteSurveyQNA {
      id
      question_id
      question_number
      question_title
      question_title_short
      question_subtitle
      question_type
      question_btn_type
      max_choice
      answers {
        items {
          id
          answer_button_type
          answer_title
          answer_value
          answer_img_id
          answer_img_url
          answer_order
          createdAt
          updatedAt
          surveyQNAAnswersId
        }
        nextToken
      }
      createdAt
      updatedAt
      surveyQuestions_answersId
    }
  }
`;
export const onCreateSurveyAnswer = /* GraphQL */ `
  subscription OnCreateSurveyAnswer {
    onCreateSurveyAnswer {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const onUpdateSurveyAnswer = /* GraphQL */ `
  subscription OnUpdateSurveyAnswer {
    onUpdateSurveyAnswer {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const onDeleteSurveyAnswer = /* GraphQL */ `
  subscription OnDeleteSurveyAnswer {
    onDeleteSurveyAnswer {
      id
      answer_button_type
      answer_title
      answer_value
      answer_img_id
      answer_img_url
      answer_order
      createdAt
      updatedAt
      surveyQNAAnswersId
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cards {
        items {
          id
          card_name
          card_company
          card_number
          billing_key
          last_used_time
          createdAt
          updatedAt
          userCardsId
        }
        nextToken
      }
      venus_charts {
        items {
          id
          patient_name
          user_id
          user_info {
            id
            name
            age
            phone
            ssn
            addrs {
              items {
                id
                nick_name
                road_address
                detail_address
                zip
                is_default
                createdAt
                updatedAt
                userInfoAddrsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          yeyak_time
          platform
          kid
          user_status
          doctor_status
          pharma_status
          survey_answers
          survey_type
          user_msg
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          when
          recommended_medicines {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          order_set_id
          orderSet {
            id
            kid
            status
            order_shipments {
              items {
                id
                kid
                status
                brand
                processor
                chart_id
                chart {
                  id
                  patient_name
                  user_id
                  user_info {
                    id
                    name
                    age
                    phone
                    ssn
                    addrs {
                      items {
                        id
                        nick_name
                        road_address
                        detail_address
                        zip
                        is_default
                        createdAt
                        updatedAt
                        userInfoAddrsId
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  yeyak_time
                  platform
                  kid
                  user_status
                  doctor_status
                  pharma_status
                  survey_answers
                  survey_type
                  user_msg
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  when
                  recommended_medicines {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  order_set_id
                  orderSet {
                    id
                    kid
                    status
                    order_shipments {
                      items {
                        id
                        kid
                        status
                        brand
                        processor
                        chart_id
                        processor_status
                        updatedAt
                        processor_id
                        prescription_id
                        total
                        road_address
                        detail_address
                        zip
                        phone
                        name
                        delivery_company
                        delivery_number
                        createdAt
                        orderSetOrder_shipmentsId
                      }
                      nextToken
                    }
                    chart_id
                    total
                    createdAt
                    updatedAt
                    userOrder_setsId
                  }
                  createdAt
                  updatedAt
                  userVenus_chartsId
                }
                processor_status
                updatedAt
                processor_id
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                total
                orders {
                  items {
                    id
                    category
                    product_name
                    product_id
                    product_count
                    product_unit
                    product_base
                    product_quantity_str
                    product_price
                    order_total_price
                    createdAt
                    updatedAt
                    orderShipmentOrdersId
                  }
                  nextToken
                }
                road_address
                detail_address
                zip
                phone
                name
                delivery_company
                delivery_number
                createdAt
                orderSetOrder_shipmentsId
              }
              nextToken
            }
            chart_id
            total
            createdAt
            updatedAt
            userOrder_setsId
          }
          createdAt
          updatedAt
          userVenus_chartsId
        }
        nextToken
      }
      order_sets {
        items {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderShipment = /* GraphQL */ `
  subscription OnCreateOrderShipment {
    onCreateOrderShipment {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const onUpdateOrderShipment = /* GraphQL */ `
  subscription OnUpdateOrderShipment {
    onUpdateOrderShipment {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const onDeleteOrderShipment = /* GraphQL */ `
  subscription OnDeleteOrderShipment {
    onDeleteOrderShipment {
      id
      kid
      status
      brand
      processor
      chart_id
      chart {
        id
        patient_name
        user_id
        user_info {
          id
          name
          age
          phone
          ssn
          addrs {
            items {
              id
              nick_name
              road_address
              detail_address
              zip
              is_default
              createdAt
              updatedAt
              userInfoAddrsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        yeyak_time
        platform
        kid
        user_status
        doctor_status
        pharma_status
        survey_answers
        survey_type
        user_msg
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        when
        recommended_medicines {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescription_id
        prescription {
          id
          pharma_id
          doctor_id
          doctor {
            id
            doctor_name
            office_name
            office_address
            office_phone
            doctor_phone
            createdAt
            updatedAt
          }
          s3_key
          s3_identity
          status
          prescribed_medicine {
            items {
              id
              medicine_info_id
              medicine {
                id
                type
                ingredient
                company
                product_name
                nick_name
                retail_price
                description
                unit
                base
                multiplier
                price_multiplier
                info_url
                display_order
                createdAt
                updatedAt
              }
              dosage
              period
              period_unit
              display_order
              prescriptionPrescribed_medicineId
              createdAt
              updatedAt
              chartRecommended_medicinesId
            }
            nextToken
          }
          prescribed_time
          createdAt
          updatedAt
        }
        order_set_id
        orderSet {
          id
          kid
          status
          order_shipments {
            items {
              id
              kid
              status
              brand
              processor
              chart_id
              chart {
                id
                patient_name
                user_id
                user_info {
                  id
                  name
                  age
                  phone
                  ssn
                  addrs {
                    items {
                      id
                      nick_name
                      road_address
                      detail_address
                      zip
                      is_default
                      createdAt
                      updatedAt
                      userInfoAddrsId
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                yeyak_time
                platform
                kid
                user_status
                doctor_status
                pharma_status
                survey_answers
                survey_type
                user_msg
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                when
                recommended_medicines {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescription_id
                prescription {
                  id
                  pharma_id
                  doctor_id
                  doctor {
                    id
                    doctor_name
                    office_name
                    office_address
                    office_phone
                    doctor_phone
                    createdAt
                    updatedAt
                  }
                  s3_key
                  s3_identity
                  status
                  prescribed_medicine {
                    items {
                      id
                      medicine_info_id
                      medicine {
                        id
                        type
                        ingredient
                        company
                        product_name
                        nick_name
                        retail_price
                        description
                        unit
                        base
                        multiplier
                        price_multiplier
                        info_url
                        display_order
                        createdAt
                        updatedAt
                      }
                      dosage
                      period
                      period_unit
                      display_order
                      prescriptionPrescribed_medicineId
                      createdAt
                      updatedAt
                      chartRecommended_medicinesId
                    }
                    nextToken
                  }
                  prescribed_time
                  createdAt
                  updatedAt
                }
                order_set_id
                orderSet {
                  id
                  kid
                  status
                  order_shipments {
                    items {
                      id
                      kid
                      status
                      brand
                      processor
                      chart_id
                      chart {
                        id
                        patient_name
                        user_id
                        yeyak_time
                        platform
                        kid
                        user_status
                        doctor_status
                        pharma_status
                        survey_answers
                        survey_type
                        user_msg
                        doctor_id
                        when
                        prescription_id
                        order_set_id
                        createdAt
                        updatedAt
                        userVenus_chartsId
                      }
                      processor_status
                      updatedAt
                      processor_id
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      total
                      orders {
                        nextToken
                      }
                      road_address
                      detail_address
                      zip
                      phone
                      name
                      delivery_company
                      delivery_number
                      createdAt
                      orderSetOrder_shipmentsId
                    }
                    nextToken
                  }
                  chart_id
                  total
                  createdAt
                  updatedAt
                  userOrder_setsId
                }
                createdAt
                updatedAt
                userVenus_chartsId
              }
              processor_status
              updatedAt
              processor_id
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              total
              orders {
                items {
                  id
                  category
                  product_name
                  product_id
                  product_count
                  product_unit
                  product_base
                  product_quantity_str
                  product_price
                  order_total_price
                  createdAt
                  updatedAt
                  orderShipmentOrdersId
                }
                nextToken
              }
              road_address
              detail_address
              zip
              phone
              name
              delivery_company
              delivery_number
              createdAt
              orderSetOrder_shipmentsId
            }
            nextToken
          }
          chart_id
          total
          createdAt
          updatedAt
          userOrder_setsId
        }
        createdAt
        updatedAt
        userVenus_chartsId
      }
      processor_status
      updatedAt
      processor_id
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      total
      orders {
        items {
          id
          category
          product_name
          product_id
          product_count
          product_unit
          product_base
          product_quantity_str
          product_price
          order_total_price
          createdAt
          updatedAt
          orderShipmentOrdersId
        }
        nextToken
      }
      road_address
      detail_address
      zip
      phone
      name
      delivery_company
      delivery_number
      createdAt
      orderSetOrder_shipmentsId
    }
  }
`;
export const onCreateOrderSet = /* GraphQL */ `
  subscription OnCreateOrderSet {
    onCreateOrderSet {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  chart {
                    id
                    patient_name
                    user_id
                    user_info {
                      id
                      name
                      age
                      phone
                      ssn
                      addrs {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    yeyak_time
                    platform
                    kid
                    user_status
                    doctor_status
                    pharma_status
                    survey_answers
                    survey_type
                    user_msg
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    when
                    recommended_medicines {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    order_set_id
                    orderSet {
                      id
                      kid
                      status
                      order_shipments {
                        nextToken
                      }
                      chart_id
                      total
                      createdAt
                      updatedAt
                      userOrder_setsId
                    }
                    createdAt
                    updatedAt
                    userVenus_chartsId
                  }
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  total
                  orders {
                    items {
                      id
                      category
                      product_name
                      product_id
                      product_count
                      product_unit
                      product_base
                      product_quantity_str
                      product_price
                      order_total_price
                      createdAt
                      updatedAt
                      orderShipmentOrdersId
                    }
                    nextToken
                  }
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const onUpdateOrderSet = /* GraphQL */ `
  subscription OnUpdateOrderSet {
    onUpdateOrderSet {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  chart {
                    id
                    patient_name
                    user_id
                    user_info {
                      id
                      name
                      age
                      phone
                      ssn
                      addrs {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    yeyak_time
                    platform
                    kid
                    user_status
                    doctor_status
                    pharma_status
                    survey_answers
                    survey_type
                    user_msg
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    when
                    recommended_medicines {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    order_set_id
                    orderSet {
                      id
                      kid
                      status
                      order_shipments {
                        nextToken
                      }
                      chart_id
                      total
                      createdAt
                      updatedAt
                      userOrder_setsId
                    }
                    createdAt
                    updatedAt
                    userVenus_chartsId
                  }
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  total
                  orders {
                    items {
                      id
                      category
                      product_name
                      product_id
                      product_count
                      product_unit
                      product_base
                      product_quantity_str
                      product_price
                      order_total_price
                      createdAt
                      updatedAt
                      orderShipmentOrdersId
                    }
                    nextToken
                  }
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const onDeleteOrderSet = /* GraphQL */ `
  subscription OnDeleteOrderSet {
    onDeleteOrderSet {
      id
      kid
      status
      order_shipments {
        items {
          id
          kid
          status
          brand
          processor
          chart_id
          chart {
            id
            patient_name
            user_id
            user_info {
              id
              name
              age
              phone
              ssn
              addrs {
                items {
                  id
                  nick_name
                  road_address
                  detail_address
                  zip
                  is_default
                  createdAt
                  updatedAt
                  userInfoAddrsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            yeyak_time
            platform
            kid
            user_status
            doctor_status
            pharma_status
            survey_answers
            survey_type
            user_msg
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            when
            recommended_medicines {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            order_set_id
            orderSet {
              id
              kid
              status
              order_shipments {
                items {
                  id
                  kid
                  status
                  brand
                  processor
                  chart_id
                  chart {
                    id
                    patient_name
                    user_id
                    user_info {
                      id
                      name
                      age
                      phone
                      ssn
                      addrs {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    yeyak_time
                    platform
                    kid
                    user_status
                    doctor_status
                    pharma_status
                    survey_answers
                    survey_type
                    user_msg
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    when
                    recommended_medicines {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    order_set_id
                    orderSet {
                      id
                      kid
                      status
                      order_shipments {
                        nextToken
                      }
                      chart_id
                      total
                      createdAt
                      updatedAt
                      userOrder_setsId
                    }
                    createdAt
                    updatedAt
                    userVenus_chartsId
                  }
                  processor_status
                  updatedAt
                  processor_id
                  prescription_id
                  prescription {
                    id
                    pharma_id
                    doctor_id
                    doctor {
                      id
                      doctor_name
                      office_name
                      office_address
                      office_phone
                      doctor_phone
                      createdAt
                      updatedAt
                    }
                    s3_key
                    s3_identity
                    status
                    prescribed_medicine {
                      items {
                        id
                        medicine_info_id
                        dosage
                        period
                        period_unit
                        display_order
                        prescriptionPrescribed_medicineId
                        createdAt
                        updatedAt
                        chartRecommended_medicinesId
                      }
                      nextToken
                    }
                    prescribed_time
                    createdAt
                    updatedAt
                  }
                  total
                  orders {
                    items {
                      id
                      category
                      product_name
                      product_id
                      product_count
                      product_unit
                      product_base
                      product_quantity_str
                      product_price
                      order_total_price
                      createdAt
                      updatedAt
                      orderShipmentOrdersId
                    }
                    nextToken
                  }
                  road_address
                  detail_address
                  zip
                  phone
                  name
                  delivery_company
                  delivery_number
                  createdAt
                  orderSetOrder_shipmentsId
                }
                nextToken
              }
              chart_id
              total
              createdAt
              updatedAt
              userOrder_setsId
            }
            createdAt
            updatedAt
            userVenus_chartsId
          }
          processor_status
          updatedAt
          processor_id
          prescription_id
          prescription {
            id
            pharma_id
            doctor_id
            doctor {
              id
              doctor_name
              office_name
              office_address
              office_phone
              doctor_phone
              createdAt
              updatedAt
            }
            s3_key
            s3_identity
            status
            prescribed_medicine {
              items {
                id
                medicine_info_id
                medicine {
                  id
                  type
                  ingredient
                  company
                  product_name
                  nick_name
                  retail_price
                  description
                  unit
                  base
                  multiplier
                  price_multiplier
                  info_url
                  display_order
                  createdAt
                  updatedAt
                }
                dosage
                period
                period_unit
                display_order
                prescriptionPrescribed_medicineId
                createdAt
                updatedAt
                chartRecommended_medicinesId
              }
              nextToken
            }
            prescribed_time
            createdAt
            updatedAt
          }
          total
          orders {
            items {
              id
              category
              product_name
              product_id
              product_count
              product_unit
              product_base
              product_quantity_str
              product_price
              order_total_price
              createdAt
              updatedAt
              orderShipmentOrdersId
            }
            nextToken
          }
          road_address
          detail_address
          zip
          phone
          name
          delivery_company
          delivery_number
          createdAt
          orderSetOrder_shipmentsId
        }
        nextToken
      }
      chart_id
      total
      createdAt
      updatedAt
      userOrder_setsId
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      category
      product_name
      product_id
      product_count
      product_unit
      product_base
      product_quantity_str
      product_price
      order_total_price
      createdAt
      updatedAt
      orderShipmentOrdersId
    }
  }
`;
export const onCreateUserInfo = /* GraphQL */ `
  subscription OnCreateUserInfo {
    onCreateUserInfo {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserInfo = /* GraphQL */ `
  subscription OnUpdateUserInfo {
    onUpdateUserInfo {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserInfo = /* GraphQL */ `
  subscription OnDeleteUserInfo {
    onDeleteUserInfo {
      id
      name
      age
      phone
      ssn
      addrs {
        items {
          id
          nick_name
          road_address
          detail_address
          zip
          is_default
          createdAt
          updatedAt
          userInfoAddrsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress {
    onCreateAddress {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress {
    onUpdateAddress {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress {
    onDeleteAddress {
      id
      nick_name
      road_address
      detail_address
      zip
      is_default
      createdAt
      updatedAt
      userInfoAddrsId
    }
  }
`;
export const onCreateCard = /* GraphQL */ `
  subscription OnCreateCard {
    onCreateCard {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const onUpdateCard = /* GraphQL */ `
  subscription OnUpdateCard {
    onUpdateCard {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const onDeleteCard = /* GraphQL */ `
  subscription OnDeleteCard {
    onDeleteCard {
      id
      card_name
      card_company
      card_number
      billing_key
      last_used_time
      createdAt
      updatedAt
      userCardsId
    }
  }
`;
export const onCreateDoctor = /* GraphQL */ `
  subscription OnCreateDoctor {
    onCreateDoctor {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDoctor = /* GraphQL */ `
  subscription OnUpdateDoctor {
    onUpdateDoctor {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDoctor = /* GraphQL */ `
  subscription OnDeleteDoctor {
    onDeleteDoctor {
      id
      doctor_name
      office_name
      office_address
      office_phone
      doctor_phone
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePharma = /* GraphQL */ `
  subscription OnCreatePharma {
    onCreatePharma {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePharma = /* GraphQL */ `
  subscription OnUpdatePharma {
    onUpdatePharma {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePharma = /* GraphQL */ `
  subscription OnDeletePharma {
    onDeletePharma {
      id
      pharma_name
      office_name
      office_address
      office_phone
      pharma_phone
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrescription = /* GraphQL */ `
  subscription OnCreatePrescription {
    onCreatePrescription {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePrescription = /* GraphQL */ `
  subscription OnUpdatePrescription {
    onUpdatePrescription {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePrescription = /* GraphQL */ `
  subscription OnDeletePrescription {
    onDeletePrescription {
      id
      pharma_id
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      s3_key
      s3_identity
      status
      prescribed_medicine {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescribed_time
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMedicine = /* GraphQL */ `
  subscription OnCreateMedicine {
    onCreateMedicine {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const onUpdateMedicine = /* GraphQL */ `
  subscription OnUpdateMedicine {
    onUpdateMedicine {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const onDeleteMedicine = /* GraphQL */ `
  subscription OnDeleteMedicine {
    onDeleteMedicine {
      id
      medicine_info_id
      medicine {
        id
        type
        ingredient
        company
        product_name
        nick_name
        retail_price
        description
        unit
        base
        multiplier
        price_multiplier
        info_url
        display_order
        createdAt
        updatedAt
      }
      dosage
      period
      period_unit
      display_order
      prescriptionPrescribed_medicineId
      createdAt
      updatedAt
      chartRecommended_medicinesId
    }
  }
`;
export const onCreateMedicineInfo = /* GraphQL */ `
  subscription OnCreateMedicineInfo {
    onCreateMedicineInfo {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMedicineInfo = /* GraphQL */ `
  subscription OnUpdateMedicineInfo {
    onUpdateMedicineInfo {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMedicineInfo = /* GraphQL */ `
  subscription OnDeleteMedicineInfo {
    onDeleteMedicineInfo {
      id
      type
      ingredient
      company
      product_name
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChart = /* GraphQL */ `
  subscription OnCreateChart {
    onCreateChart {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  items {
                    id
                    nick_name
                    road_address
                    detail_address
                    zip
                    is_default
                    createdAt
                    updatedAt
                    userInfoAddrsId
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  items {
                    id
                    kid
                    status
                    brand
                    processor
                    chart_id
                    chart {
                      id
                      patient_name
                      user_id
                      user_info {
                        id
                        name
                        age
                        phone
                        ssn
                        createdAt
                        updatedAt
                      }
                      yeyak_time
                      platform
                      kid
                      user_status
                      doctor_status
                      pharma_status
                      survey_answers
                      survey_type
                      user_msg
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      when
                      recommended_medicines {
                        nextToken
                      }
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      order_set_id
                      orderSet {
                        id
                        kid
                        status
                        chart_id
                        total
                        createdAt
                        updatedAt
                        userOrder_setsId
                      }
                      createdAt
                      updatedAt
                      userVenus_chartsId
                    }
                    processor_status
                    updatedAt
                    processor_id
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    total
                    orders {
                      items {
                        id
                        category
                        product_name
                        product_id
                        product_count
                        product_unit
                        product_base
                        product_quantity_str
                        product_price
                        order_total_price
                        createdAt
                        updatedAt
                        orderShipmentOrdersId
                      }
                      nextToken
                    }
                    road_address
                    detail_address
                    zip
                    phone
                    name
                    delivery_company
                    delivery_number
                    createdAt
                    orderSetOrder_shipmentsId
                  }
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const onUpdateChart = /* GraphQL */ `
  subscription OnUpdateChart {
    onUpdateChart {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  items {
                    id
                    nick_name
                    road_address
                    detail_address
                    zip
                    is_default
                    createdAt
                    updatedAt
                    userInfoAddrsId
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  items {
                    id
                    kid
                    status
                    brand
                    processor
                    chart_id
                    chart {
                      id
                      patient_name
                      user_id
                      user_info {
                        id
                        name
                        age
                        phone
                        ssn
                        createdAt
                        updatedAt
                      }
                      yeyak_time
                      platform
                      kid
                      user_status
                      doctor_status
                      pharma_status
                      survey_answers
                      survey_type
                      user_msg
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      when
                      recommended_medicines {
                        nextToken
                      }
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      order_set_id
                      orderSet {
                        id
                        kid
                        status
                        chart_id
                        total
                        createdAt
                        updatedAt
                        userOrder_setsId
                      }
                      createdAt
                      updatedAt
                      userVenus_chartsId
                    }
                    processor_status
                    updatedAt
                    processor_id
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    total
                    orders {
                      items {
                        id
                        category
                        product_name
                        product_id
                        product_count
                        product_unit
                        product_base
                        product_quantity_str
                        product_price
                        order_total_price
                        createdAt
                        updatedAt
                        orderShipmentOrdersId
                      }
                      nextToken
                    }
                    road_address
                    detail_address
                    zip
                    phone
                    name
                    delivery_company
                    delivery_number
                    createdAt
                    orderSetOrder_shipmentsId
                  }
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const onDeleteChart = /* GraphQL */ `
  subscription OnDeleteChart {
    onDeleteChart {
      id
      patient_name
      user_id
      user_info {
        id
        name
        age
        phone
        ssn
        addrs {
          items {
            id
            nick_name
            road_address
            detail_address
            zip
            is_default
            createdAt
            updatedAt
            userInfoAddrsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      yeyak_time
      platform
      kid
      user_status
      doctor_status
      pharma_status
      survey_answers
      survey_type
      user_msg
      doctor_id
      doctor {
        id
        doctor_name
        office_name
        office_address
        office_phone
        doctor_phone
        createdAt
        updatedAt
      }
      when
      recommended_medicines {
        items {
          id
          medicine_info_id
          medicine {
            id
            type
            ingredient
            company
            product_name
            nick_name
            retail_price
            description
            unit
            base
            multiplier
            price_multiplier
            info_url
            display_order
            createdAt
            updatedAt
          }
          dosage
          period
          period_unit
          display_order
          prescriptionPrescribed_medicineId
          createdAt
          updatedAt
          chartRecommended_medicinesId
        }
        nextToken
      }
      prescription_id
      prescription {
        id
        pharma_id
        doctor_id
        doctor {
          id
          doctor_name
          office_name
          office_address
          office_phone
          doctor_phone
          createdAt
          updatedAt
        }
        s3_key
        s3_identity
        status
        prescribed_medicine {
          items {
            id
            medicine_info_id
            medicine {
              id
              type
              ingredient
              company
              product_name
              nick_name
              retail_price
              description
              unit
              base
              multiplier
              price_multiplier
              info_url
              display_order
              createdAt
              updatedAt
            }
            dosage
            period
            period_unit
            display_order
            prescriptionPrescribed_medicineId
            createdAt
            updatedAt
            chartRecommended_medicinesId
          }
          nextToken
        }
        prescribed_time
        createdAt
        updatedAt
      }
      order_set_id
      orderSet {
        id
        kid
        status
        order_shipments {
          items {
            id
            kid
            status
            brand
            processor
            chart_id
            chart {
              id
              patient_name
              user_id
              user_info {
                id
                name
                age
                phone
                ssn
                addrs {
                  items {
                    id
                    nick_name
                    road_address
                    detail_address
                    zip
                    is_default
                    createdAt
                    updatedAt
                    userInfoAddrsId
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              yeyak_time
              platform
              kid
              user_status
              doctor_status
              pharma_status
              survey_answers
              survey_type
              user_msg
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              when
              recommended_medicines {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescription_id
              prescription {
                id
                pharma_id
                doctor_id
                doctor {
                  id
                  doctor_name
                  office_name
                  office_address
                  office_phone
                  doctor_phone
                  createdAt
                  updatedAt
                }
                s3_key
                s3_identity
                status
                prescribed_medicine {
                  items {
                    id
                    medicine_info_id
                    medicine {
                      id
                      type
                      ingredient
                      company
                      product_name
                      nick_name
                      retail_price
                      description
                      unit
                      base
                      multiplier
                      price_multiplier
                      info_url
                      display_order
                      createdAt
                      updatedAt
                    }
                    dosage
                    period
                    period_unit
                    display_order
                    prescriptionPrescribed_medicineId
                    createdAt
                    updatedAt
                    chartRecommended_medicinesId
                  }
                  nextToken
                }
                prescribed_time
                createdAt
                updatedAt
              }
              order_set_id
              orderSet {
                id
                kid
                status
                order_shipments {
                  items {
                    id
                    kid
                    status
                    brand
                    processor
                    chart_id
                    chart {
                      id
                      patient_name
                      user_id
                      user_info {
                        id
                        name
                        age
                        phone
                        ssn
                        createdAt
                        updatedAt
                      }
                      yeyak_time
                      platform
                      kid
                      user_status
                      doctor_status
                      pharma_status
                      survey_answers
                      survey_type
                      user_msg
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      when
                      recommended_medicines {
                        nextToken
                      }
                      prescription_id
                      prescription {
                        id
                        pharma_id
                        doctor_id
                        s3_key
                        s3_identity
                        status
                        prescribed_time
                        createdAt
                        updatedAt
                      }
                      order_set_id
                      orderSet {
                        id
                        kid
                        status
                        chart_id
                        total
                        createdAt
                        updatedAt
                        userOrder_setsId
                      }
                      createdAt
                      updatedAt
                      userVenus_chartsId
                    }
                    processor_status
                    updatedAt
                    processor_id
                    prescription_id
                    prescription {
                      id
                      pharma_id
                      doctor_id
                      doctor {
                        id
                        doctor_name
                        office_name
                        office_address
                        office_phone
                        doctor_phone
                        createdAt
                        updatedAt
                      }
                      s3_key
                      s3_identity
                      status
                      prescribed_medicine {
                        nextToken
                      }
                      prescribed_time
                      createdAt
                      updatedAt
                    }
                    total
                    orders {
                      items {
                        id
                        category
                        product_name
                        product_id
                        product_count
                        product_unit
                        product_base
                        product_quantity_str
                        product_price
                        order_total_price
                        createdAt
                        updatedAt
                        orderShipmentOrdersId
                      }
                      nextToken
                    }
                    road_address
                    detail_address
                    zip
                    phone
                    name
                    delivery_company
                    delivery_number
                    createdAt
                    orderSetOrder_shipmentsId
                  }
                  nextToken
                }
                chart_id
                total
                createdAt
                updatedAt
                userOrder_setsId
              }
              createdAt
              updatedAt
              userVenus_chartsId
            }
            processor_status
            updatedAt
            processor_id
            prescription_id
            prescription {
              id
              pharma_id
              doctor_id
              doctor {
                id
                doctor_name
                office_name
                office_address
                office_phone
                doctor_phone
                createdAt
                updatedAt
              }
              s3_key
              s3_identity
              status
              prescribed_medicine {
                items {
                  id
                  medicine_info_id
                  medicine {
                    id
                    type
                    ingredient
                    company
                    product_name
                    nick_name
                    retail_price
                    description
                    unit
                    base
                    multiplier
                    price_multiplier
                    info_url
                    display_order
                    createdAt
                    updatedAt
                  }
                  dosage
                  period
                  period_unit
                  display_order
                  prescriptionPrescribed_medicineId
                  createdAt
                  updatedAt
                  chartRecommended_medicinesId
                }
                nextToken
              }
              prescribed_time
              createdAt
              updatedAt
            }
            total
            orders {
              items {
                id
                category
                product_name
                product_id
                product_count
                product_unit
                product_base
                product_quantity_str
                product_price
                order_total_price
                createdAt
                updatedAt
                orderShipmentOrdersId
              }
              nextToken
            }
            road_address
            detail_address
            zip
            phone
            name
            delivery_company
            delivery_number
            createdAt
            orderSetOrder_shipmentsId
          }
          nextToken
        }
        chart_id
        total
        createdAt
        updatedAt
        userOrder_setsId
      }
      createdAt
      updatedAt
      userVenus_chartsId
    }
  }
`;
export const onCreateProductInfo = /* GraphQL */ `
  subscription OnCreateProductInfo {
    onCreateProductInfo {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductInfo = /* GraphQL */ `
  subscription OnUpdateProductInfo {
    onUpdateProductInfo {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductInfo = /* GraphQL */ `
  subscription OnDeleteProductInfo {
    onDeleteProductInfo {
      id
      type
      category
      ingredient
      company
      product_name
      product_img_id
      product_img_url
      nick_name
      retail_price
      description
      unit
      base
      multiplier
      price_multiplier
      info_url
      display_order
      createdAt
      updatedAt
    }
  }
`;
