import { API } from "aws-amplify";
import { PROD_HOST } from "config";
import { getMarsUtils, getUser } from "./data";
import { PRODUCTS } from "./products";
import getAvailTimes2 from "./scheduler2";

// const price = {
//   propecia: {
//     origin: [0, 55000, 110000, 160000],
//     copy: [0, 23300, 46600, 55490],
//     finaon: [0, 14900, 29800, 39900]
//   },
//   avodart: {
//     origin: [0, 31000, 62000, 78800],
//     copy: [0, 26000, 52000, 63600],
//     dutaon: [0, 19600, 39200, 49800]
//   },
//   minox: {
//     rogaine: [0, 27000, 54000, 75000],
//     mynox: [0, 22000, 44000, 50000],
//   },
//   pansidil: {
//     cap: [0, 20000, 0, 60000]
//   },
//   capillus: {
//     cap: [0, 30000, 60000, 90000]
//   },
//   nesh: {
//     mint: [0, 17900, 35800, 53700],
//     soy: [0, 17900, 35800, 53700]
//   }
// };

export function calcPrice3(pill, minox, pillMonth, minoxMonth, shamp) {
  let pillPrice = 0;
  let minoxPrice = 0;
  let total = 0;
  let medicalFee = 10000; // 진료비 만원
  let shippingCost = 2900; // 태배비 무료
  let pillCount = 30;
  let minoxCount = 1;
  let shampPrice = 0;

  if (shamp) {
    shampPrice = PRODUCTS.neshmint.price;
  }

  if (pill === "아니요" || pill === "") {
    pillPrice = 0;
  }
  if (pill === "프로페시아" || pill === PRODUCTS.propecia.id) {
    // pillPrice = price.propecia.origin[pillMonth];
    pillPrice = PRODUCTS.propecia.price[pillMonth];
    pillCount = 28;
  }
  if (
    pill === "알로스칸" ||
    pill === "경동 알로스칸" ||
    pill === PRODUCTS.aloscan.id
  ) {
    // pillPrice = price.propecia.copy[pillMonth];
    pillPrice = PRODUCTS.aloscan.price[pillMonth];
  }
  if (pill === "피나온정" || pill === "피나온" || pill === PRODUCTS.finaon.id) {
    // pillPrice = price.propecia.finaon[pillMonth];
    pillPrice = PRODUCTS.finaon.price[pillMonth];
  }

  if (pill === "아보다트" || pill === PRODUCTS.avodart.id) {
    // pillPrice = price.avodart.origin[pillMonth];
    pillPrice = PRODUCTS.avodart.price[pillMonth];
  }
  if (
    pill === "아보다트 카피약" ||
    pill === "다모다트" ||
    pill === PRODUCTS.damodart.id
  ) {
    // pillPrice = price.avodart.copy[pillMonth];
    pillPrice = PRODUCTS.damodart.price[pillMonth];
  }
  if (
    pill === "두타온" ||
    pill === "라마파온 두타온" ||
    pill === PRODUCTS.dutaon.id
  ) {
    // pillPrice = price.avodart.dutaon[pillMonth];
    pillPrice = PRODUCTS.dutaon.price[pillMonth];
  }

  if (pill === "카필러스 캡슐" || pill === PRODUCTS.capillus.id) {
    // pillPrice = price.capillus.cap[pillMonth];
    pillPrice = PRODUCTS.capillus.price[pillMonth];
  }

  if (minox === "0" || minox === "" || minox === "아니요") {
    minoxPrice = 0;
  }
  if (
    minox === "현대 마이녹실 5%" ||
    minox === "현대 마이녹실" ||
    minox === PRODUCTS.minox.id
  ) {
    // minoxPrice = price.minox.mynox[minoxMonth];
    minoxPrice = PRODUCTS.minox.price[minoxMonth];
  }
  if (
    minox === "미녹시딜: 로게인폼" ||
    minox === "존슨앤존슨 로게인폼" ||
    minox === "로게인폼" ||
    minox === PRODUCTS.rogaine.id
  ) {
    // minoxPrice = price.minox.rogaine[minoxMonth]
    minoxPrice = PRODUCTS.rogaine.price[minoxMonth];
  }

  pillCount = pillCount * pillMonth;
  minoxCount = minoxCount * minoxMonth;
  total = pillPrice + minoxPrice + medicalFee + shippingCost + shampPrice;

  let result = {
    pillPrice,
    minoxPrice,
    total,
    pillCount,
    minoxCount,
    shampPrice,
  };
  return result;
}

export function calcPrice4(
  pill,
  minox,
  pillMonth,
  minoxMonth,
  shamp,
  capil,
  capilMonth
) {
  let pillPrice = 0;
  let minoxPrice = 0;
  let capilPrice = 0;
  let total = 0;
  let medicalFee = 10000; // 진료비 만원
  let shippingCost = 2900; // 태배비 무료
  let pillCount = 30;
  let capilCount = 30;
  let minoxCount = 1;
  let shampPrice = 0;

  if (shamp) {
    shampPrice = PRODUCTS.neshmint.price;
  }
  if (capil) {
    // capilPrice = price.capillus.cap[capilMonth];
    capilPrice = PRODUCTS.capillus.price[capilMonth];
  }

  if (!pill || pill === "") {
    pillPrice = 0;
  }
  if (pill === "프로페시아" || pill === PRODUCTS.propecia.id) {
    // pillPrice = price.propecia.origin[pillMonth];
    pillPrice = PRODUCTS.propecia.price[pillMonth];
    pillCount = 28;
  }
  if (
    pill === "알로스칸" ||
    pill === "경동 알로스칸" ||
    pill === PRODUCTS.aloscan.id
  ) {
    // pillPrice = price.propecia.copy[pillMonth];
    pillPrice = PRODUCTS.aloscan.price[pillMonth];
  }
  if (pill === "피나온정" || pill === "피나온" || pill === PRODUCTS.finaon.id) {
    // pillPrice = price.propecia.finaon[pillMonth];
    pillPrice = PRODUCTS.finaon.price[pillMonth];
  }

  if (pill === "아보다트" || pill === PRODUCTS.avodart.id) {
    // pillPrice = price.avodart.origin[pillMonth];
    pillPrice = PRODUCTS.avodart.price[pillMonth];
  }
  if (
    pill === "아보다트 카피약" ||
    pill === "다모다트" ||
    pill === PRODUCTS.damodart.id
  ) {
    // pillPrice = price.avodart.copy[pillMonth];
    pillPrice = PRODUCTS.damodart.price[pillMonth];
  }
  if (
    pill === "두타온" ||
    pill === "라마파온 두타온" ||
    pill === PRODUCTS.dutaon.id
  ) {
    // pillPrice = price.avodart.dutaon[pillMonth];
    pillPrice = PRODUCTS.dutaon.price[pillMonth];
  }

  if (pill === "카필러스 캡슐" || pill === PRODUCTS.capillus.id) {
    // pillPrice = price.capillus.cap[pillMonth];
    pillPrice = PRODUCTS.capillus.price[pillMonth];
  }

  if (minox === "0" || minox === "" || minox === "아니요") {
    minoxPrice = 0;
  }
  if (
    minox === "현대 마이녹실 5%" ||
    minox === "현대 마이녹실" ||
    minox === PRODUCTS.minox.id
  ) {
    // minoxPrice = price.minox.mynox[minoxMonth];
    minoxPrice = PRODUCTS.minox.price[minoxMonth];
  }
  if (
    minox === "미녹시딜: 로게인폼" ||
    minox === "존슨앤존슨 로게인폼" ||
    minox === "로게인폼" ||
    minox === PRODUCTS.rogaine.id
  ) {
    // minoxPrice = price.minox.rogaine[minoxMonth];
    minoxPrice = PRODUCTS.rogaine.price[minoxMonth];
  }

  pillCount = pillCount * pillMonth;
  minoxCount = minoxCount * minoxMonth;
  total =
    pillPrice +
    minoxPrice +
    medicalFee +
    shippingCost +
    shampPrice +
    capilPrice;

  let result = {
    pillPrice,
    minoxPrice,
    total,
    pillCount,
    minoxCount,
    shampPrice,
    capilPrice,
    capilMonth,
  };
  return result;
}

export function parsePhoneNumber(num) {
  if (!num) {
    return "";
  }
  let na = num.split("");
  na.splice(3, 0, "-");
  na.splice(8, 0, "-");
  return na.join("");
}
export function calcExpectation(baldType, isWantCopy) {
  let pill = "";
  let minox = "";
  let month = "1";
  let pillDescription = "";
  let minoxDescription = "";

  if (baldType === "M자형 탈모") {
    pill = isWantCopy ? "피나온정" : "프로페시아";
    minox = "아니요";
  }
  if (baldType === "정수리 탈모") {
    pill = "아니요";
    minox = isWantCopy ? "현대 마이녹실" : "존슨앤존슨 로게인폼";
  }
  if (baldType === "전반적으로 탈모") {
    pill = isWantCopy ? "피나온정" : "프로페시아";
    minox = isWantCopy ? "현대 마이녹실" : "존슨앤존슨 로게인폼";
  }

  if (pill === PRODUCTS.propecia.id) {
    pillDescription =
      "프로페시아는 피나스테리드 0.5mg 성분으로 M자형 탈모에 효과가 있는 것으로 알려져 있어요. 하루 1번 복용하면, 탈모증의 원인이 되는 호르몬인 DHT가 현저히 감소되어, 탈모를 정지시키거나 발모를 촉진하게 됩니다.";
  }
  if (pill === "피나온정") {
    pillDescription =
      "피나온정은 피나스테리드 0.5mg 성분으로 M자형 탈모에 효과가 있는 것으로 알려져 있어요. 하루 1번 복용하면, 탈모증의 원인이 되는 호르몬인 DHT가 현저히 감소되어, 탈모를 정지시키거나 발모를 촉진하게 됩니다.";
  }
  if (minox === "현대 마이녹실") {
    minoxDescription =
      "마이녹실은 미녹시딜 성분(5%)으로 두피에 도포하는 형식이며, 정수리 탈모에 효과가 있는 것으로 알려져 있어요. 아침, 저녁으로 하루 2번 도포하면 모발성장에 도움을 줍니다.";
  }
  if (minox === "존슨앤존슨 로게인폼") {
    minoxDescription =
      "로게인은 미녹시딜 성분(5%)으로 두피에 도포하는 형식이며, 정수리 탈모에 효과가 있는 것으로 알려져 있어요. 아침, 저녁으로 하루 2번 도포하면 모발성장에 도움을 줍니다.";
  }

  return { pill, minox, month, pillDescription, minoxDescription };
}
export function calcExpectation2(expectation) {
  let pill = "";
  let minox = "";

  if (
    expectation.startsWith("c") ||
    expectation.startsWith("an") ||
    expectation.startsWith("mn")
  ) {
    // 정수리탈모 =>
    pill = PRODUCTS.capillus.id;
    minox = PRODUCTS.rogaine.id;
  }
  if (expectation === "ayo") {
    pill = PRODUCTS.propecia.id;
    minox = PRODUCTS.rogaine.id;
  }
  if (expectation === "ayc") {
    pill = PRODUCTS.finaon.id;
    minox = PRODUCTS.minox.id;
  }
  if (expectation === "myo") {
    pill = PRODUCTS.propecia.id;
    minox = "아니요";
  }
  if (expectation === "myc") {
    pill = PRODUCTS.finaon.id;
    minox = "아니요";
  }

  return { pill, minox };
}

export function parseSurvey(survey, type, version) {
  let ans;
  if (version) {
    if (version === "1.2") {
      ans = parseAnswers2(survey, type);
    }
    if (version === "1.4") {
      ans = parseAnswers14(survey, type);
    }
    if (version === "1.5") {
      ans = parseAnswers15(survey, type);
    }
    if (version === "1.6") {
      ans = parseAnswers15(survey, type);
    }
  } else {
    // original no version
    ans = parseAnswers(survey, type);
  }
  return ans;
}

export function parseRepeat(pill, minox) {
  let pillAns = pill;
  let minoxAns = minox;

  if (pill === "프로페시아" || pill === PRODUCTS.propecia.id) {
    pillAns = PRODUCTS.propecia.id;
  }
  if (
    pill === "알로스칸" ||
    pill === "경동 알로스칸" ||
    pill === "프로페시아 카피약" ||
    pill === PRODUCTS.aloscan.id
  ) {
    pillAns = PRODUCTS.aloscan.id;
  }
  if (pill === "피나온정" || pill === "피나온" || pill === PRODUCTS.finaon.id) {
    pillAns = PRODUCTS.finaon.id;
  }
  if (pill === "아보다트" || pill === PRODUCTS.avodart.id) {
    pillAns = PRODUCTS.avodart.id;
  }
  if (
    pill === "아보다트 카피약" ||
    pill === "다모다트" ||
    pill === PRODUCTS.damodart.id
  ) {
    pillAns = PRODUCTS.damodart.id;
  }
  if (
    pill === "두타온" ||
    pill === "라마파온 두타온" ||
    pill === PRODUCTS.dutaon.id
  ) {
    pillAns = PRODUCTS.dutaon.id;
  }

  if (pill === "카필러스 캡슐" || pill === PRODUCTS.capillus.id) {
    pillAns = PRODUCTS.capillus.id;
  }
  if (
    minox === "현대 마이녹실 5%" ||
    minox === "현대 마이녹실" ||
    minox === PRODUCTS.minox.id
  ) {
    minoxAns = PRODUCTS.minox.id;
  }
  if (
    minox === "미녹시딜: 로게인폼" ||
    minox === "존슨앤존슨 로게인폼" ||
    minox === "로게인폼" ||
    minox === PRODUCTS.rogaine.id
  ) {
    minoxAns = PRODUCTS.rogaine.id;
  }

  if (pillAns === "" || pillAns === "아니요" || pillAns == "없음") {
    pillAns = "";
  }
  if (minoxAns === "") {
    minoxAns = "아니요";
  }
  return { pillAns, minoxAns };
}

export function parseAnswers(survey, type) {
  let answers = JSON.parse(survey);
  let pill = "";
  let minox = "";
  let month = "";
  let message = "";
  let pillMonth = "";
  let minoxMonth = "";

  // check for repeat
  if (type && (type === "repeat" || type === "재진")) {
    // console.log("repeat parse answer!!!!");
    /*
    { id: 1, q: "마지막으로 복용한 약", a: previousPill },
    { id: 2, q: "마지막으로 사용한 바르는약", a: previousMinox },
    { id: 3, q: "새로원하는 먹는약", a: pill },
    { id: 4, q: "새로원하는 바르는약", a: minox },
    { id: 5, q: "먹는약 희망 개월수", a: pillMonth },
    { id: 6, q: "바른는약 희망 개월수", a: minoxMonth },
    */
    pill = answers[2].a;
    minox = answers[3].a;
    month = answers[4].a;
    pillMonth = answers[4].a;
    minoxMonth = answers[5].a;
    return { pill, minox, month, message, pillMonth, minoxMonth };
  }

  if (answers.length < 5) {
    //초진
    let es = calcExpectation(answers[0].a, answers[2].a === "카피");
    // console.log("es:", es);
    pill = es.pill;
    minox = es.minox;
    month = es.month;
    message = answers[3].a;
  } else {
    //재진
    pill = answers[0].a;
    minox = answers[1].a;
    month = answers[2].a;
    if (answers[5]) {
      message = answers[5].a;
    }
  }
  minoxMonth = month;
  pillMonth = month;
  return { pill, minox, month, message, pillMonth, minoxMonth };
}

export function parseAnswers2(survey, type) {
  let answers = JSON.parse(survey);
  let pill = "";
  let minox = "";
  let month = "";
  let message = "";
  let pillMonth = "";
  let minoxMonth = "";

  // check for repeat
  if (type && type === "repeat") {
    // console.log("repeat parse answer!!!!");
    /*
    { id: 1, q: "마지막으로 복용한 약", a: previousPill },
    { id: 2, q: "마지막으로 사용한 바르는약", a: previousMinox },
    { id: 3, q: "새로원하는 먹는약", a: pill },
    { id: 4, q: "새로원하는 바르는약", a: minox },
    { id: 5, q: "먹는약 희망 개월수", a: pillMonth },
    { id: 6, q: "바른는약 희망 개월수", a: minoxMonth },
    */
    pill = answers[2].a;
    minox = answers[3].a;
    month = answers[4].a;
    pillMonth = answers[4].a;
    minoxMonth = answers[5].a;
    return { pill, minox, month, message, pillMonth, minoxMonth };
  }

  if (type === "초진") {
    //초진
    let es = calcExpectation(answers[0].a, answers[2].a === "카피");
    // console.log("es:", es);
    pill = es.pill;
    minox = es.minox;
    month = es.month;
    message = answers[3].a;
  } else {
    //재진
    pill = answers[0].a;
    minox = answers[1].a;
    month = answers[2].a;
    if (answers[5]) {
      message = answers[5].a;
    }
  }
  minoxMonth = month;
  pillMonth = month;
  return { pill, minox, month, message, pillMonth, minoxMonth };
}

export function parseAnswers14(survey, type) {
  let answers = JSON.parse(survey);
  let pill = "";
  let minox = "";
  let month = "";
  let message = "";
  let pillMonth = "";
  let minoxMonth = "";
  let shamp = "";

  // check for repeat
  if (type && type === "재진") {
    pill = answers["sv_what_pill"].ans[0];
    minox = answers["sv_what_minox"].ans[0];
    month = answers["sv_month"].ans[0];
    pillMonth = month;
    minoxMonth = month;
    return { pill, minox, month, message, pillMonth, minoxMonth };
  }

  if (type === "초진") {
    //초진
    // let es = calcExpectation(answers[0].a, answers[2].a === "카피");
    let exp = answers["fv_exp"] && answers["fv_exp"].ans;
    let es = calcExpectation2(exp);

    // console.log("es:", es);
    pill = es.pill;
    minox = es.minox;
    month = answers["fv_month"].ans[0];
    // message = answers["fv_message"].a;
  }

  if (type === "repeat") {
    pill = answers[0]["r_new_pill"];
    minox = answers[0]["r_new_minox"];
    pillMonth = answers[0]["r_new_pill_month"];
    minoxMonth = answers[0]["r_new_minox_month"];
    month = pillMonth;
    message = answers[0]["r_msg"];
    if (answers[0]["r_new_shamp"]) {
      shamp = answers[0]["r_new_shamp"];
    }
  }
  return { pill, minox, month, message, pillMonth, minoxMonth, shamp };
}

export function parseAnswers15(survey, type) {
  let answers = JSON.parse(survey);
  let pill = "";
  let minox = "";
  let shamp = "";
  let month = "";
  let message = "";
  let pillMonth = "";
  let minoxMonth = "";

  // check for repeat
  if (type && type === "재진") {
    pill = answers["sv_what_pill"].ans[0];
    minox = answers["sv_what_minox"].ans[0];
    pillMonth = answers["sv_month"].ans[0];
    minoxMonth = pillMonth;
    if (answers.sv_month_minox) {
      minoxMonth = answers["sv_month_minox"].ans[0];
    }

    return { pill, minox, month, message, pillMonth, minoxMonth };
  }

  if (type === "초진") {
    //초진
    // let es = calcExpectation(answers[0].a, answers[2].a === "카피");

    month = answers["fv_month"].ans[0];
    pillMonth = month;
    minoxMonth = month;

    let basket = answers["fv_basket"].ans;

    for (let item of basket) {
      if (item.pill) {
        pill = item.pill;
      }
      if (item.minox) {
        minox = item.minox;
      }
      if (item.shamp) {
        shamp = item.shamp;
      }
    }

    return { pill, minox, month, message, pillMonth, minoxMonth, shamp };
  } /* end of chojin */

  if (type === "repeat") {
    pill = answers[0]["r_new_pill"];
    minox = answers[0]["r_new_minox"];
    pillMonth = answers[0]["r_new_pill_month"];
    minoxMonth = answers[0]["r_new_minox_month"];
    month = pillMonth;
    message = answers[0]["r_msg"];
    return { pill, minox, month, message, pillMonth, minoxMonth, shamp };
  }
}

export const won = (number) => {
  return new Intl.NumberFormat("ko-kr").format(number);
  // return new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(number);
};
export const getENV = () => {
  let host = window.location.host;

  let env = "prod";
  if (host.startsWith("dev")) {
    env = "dev";
  }
  if (host.startsWith("localhost") || host.startsWith("10.")) {
    env = "local";
  }
  // console.log("env: ", env);
  return env;
};

export const getDoctorIdentityID = (doctor) => {
  let env = getENV();
  let id = "";
  if (env === "prod") {
    switch (doctor) {
      case "doctor1":
        id = "ap-northeast-2:74dcc767-0894-4769-b167-249a11ad8cce";
        break;
      case "doctor2":
        id = "ap-northeast-2:2f7c85ef-f1ad-4ca6-bdaf-5532447c1cc0";
        break;
      case "doctor77":
        id = "ap-northeast-2:900d97e8-60ab-4711-93e3-cd83ee60c02d";
        break;
      case "gaonsamsung":
        id = "ap-northeast-2:6079d19a-7ebf-4715-a92e-26f829a56f0a";
        break;
    }
  } else {
    //dev and local
    switch (doctor) {
      case "doctor1":
        id = "ap-northeast-2:80c597b0-644c-4de9-a32f-b0f476e2fccf";
        break;
      case "doctor2":
        id = "ap-northeast-2:49fac6cf-74a9-4eda-85c1-637a871b7165";
        break;
      case "doctor77":
        id = "ap-northeast-2:f66d9a11-0f1b-4aa1-9fa0-5f18ab086341";
        break;
      case "gaonsamsung":
        id = "ap-northeast-2:0828b22f-4554-45d2-8f3a-56ccf6fc5bf2";
        break;
    }
  }
  return id;
};

export const slack = async (text) => {
  try {
    let host = window.location.host;
    // let env = IS_PRODUCTION ? "" : "[DEV] ";
    let env = "[Prod] ";
    if (host.startsWith("dev")) {
      env = "[Dev] ";
    }
    if (host.startsWith("localhost") || host.startsWith("10.")) {
      env = "[Local] ";
    }

    let res = await API.post(
      "marsrest",
      env === "[Prod] " ? "/slack" : "/user-logs-dev",
      { body: { text: env + text } }
    );
    // let res = await API.post('marsrest', '/user-logs-dev', {body:{"text": env + text}});
    // user-logs-dev
  } catch (err) {
    console.log(err);
  }
};

export const slack2ops = async (text) => {
  try {
    let host = window.location.host;
    let env = "[Prod] ";
    if (host.startsWith("dev")) {
      env = "[Dev] ";
    }
    if (host.startsWith("localhost") || host.startsWith("10.")) {
      env = "[Local] ";
    }

    let res = await API.post(
      "marsrest",
      env === "[Prod] " ? "/slack2ops-prod" : "/slack2ops-dev",
      { body: { text: text } }
    );
    // let res = await API.post('marsrest', '/user-logs-dev', {body:{"text": env + text}});
    // user-logs-dev
  } catch (err) {
    console.log(err);
  }
};

export const slack2accept = async (text) => {
  try {
    let host = window.location.host;
    let env = "[Prod] ";
    if (host.startsWith("dev")) {
      env = "[Dev] ";
    }
    if (host.startsWith("localhost") || host.startsWith("10.")) {
      env = "[Local] ";
    }

    let res = await API.post("marsrest", "/slack2accept", {
      body: { text: text },
    });
    // let res = await API.post('marsrest', '/user-logs-dev', {body:{"text": env + text}});
    // user-logs-dev
  } catch (err) {
    console.log(err);
  }
};

export const getSlackURL = (to, env) => {
  let url =
    "https://hooks.slack.com/services/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";
  switch (to) {
    case "payresult":
      url =
        "https://hooks.slack.com/services/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";
      break;
  }

  return url;
};

export const slackmsg = async (text, to) => {
  try {
    let host = window.location.host;
    let env = "prod";
    if (host.startsWith("dev")) {
      env = "dev";
    }
    if (host.startsWith("localhost") || host.startsWith("10.")) {
      env = "local";
    }
    let url = getSlackURL(to, env);

    let res = await API.post("marsrest", "/slackmsg", {
      body: { text: text, url: url },
    });
  } catch (err) {
    console.log(err);
  }
};

export const slack2events = async (text) => {
  try {
    let host = window.location.host;
    let env = "[Prod] ";
    if (host.startsWith("dev")) {
      env = "[Dev] ";
    }
    if (host.startsWith("localhost") || host.startsWith("10.")) {
      env = "[Local] ";
    }

    let res = await API.post("marsrest", "/slack2events", {
      body: { text: text },
    });
    // let res = await API.post('marsrest', '/user-logs-dev', {body:{"text": env + text}});
    // user-logs-dev
  } catch (err) {
    console.log(err);
  }
};

export const getDoctorInfo = (doctor) => {
  let name = "";
  let phone = "";
  let office = "";
  switch (doctor) {
    case "doctor1":
      name = "윤정연";
      phone = "010-2901-8008";
      office = "서울 정의원";
      break;
    case "doctor2":
      name = "김영찬";
      phone = "041-402-7581";
      office = "보령이가정의원";
      break;
    case "doctor77":
      name = "김마스";
      phone = "777-777-7777";
      office = "맨프롬마스이원";
      break;
    case "gaonsamsung":
      name = "유재호";
      phone = "02-2294-8275";
      office = "가온삼성비뇨의학과의원";
      break;
    case "eroon":
      name = "강유진";
      phone = "070-4322-5111";
      office = "이룬의원";
    default:
      name = "강유진";
      phone = "070-4322-5111";
      office = "이룬의원";
  }
  return { name, phone, office };
};

export const getPharmaInfo = (pharma) => {
  let name = "";
  let phone = "";
  let office = "";
  let addr = "";
  switch (pharma) {
    case "pharma1":
      name = "조윤민";
      phone = "053-951-1082";
      office = "튼튼약국";
      addr = "대구광역시 동구 아양로 202(효목동) 현대빌딩 1층 (41167)";
      break;
    case "totheworld":
      name = "신설아";
      phone = "02-471-4700";
      office = "둔촌세계로약국";
      addr = "서울 강동구 양재대로 1319 106호";
      break;
    case "pharma77":
      name = "김마스약사";
      phone = "777-777-7777";
      office = "화성약국";
      addr = "화성시 토성면 우주7층";
      break;
  }
  return { name, phone, office };
};

export const parseDateString = (time) => {
  let rt = time.split("/");
  let year = new Date().getFullYear();
  let timeobj = new Date(
    year,
    parseInt(rt[0]) - 1,
    parseInt(rt[1]),
    parseInt(rt[3])
  );
  // setReservationTime(reservation_time);
  let ans = timeobj.getTime();

  return ans;
};

export const getTimeObject = (time) => {
  let rt = time.split("/");
  let year = new Date().getFullYear();
  let timeobj = new Date(
    year,
    parseInt(rt[0]) - 1,
    parseInt(rt[1]),
    parseInt(rt[3])
  );
  // setReservationTime(reservation_time);

  return timeobj;
};

export const getShampoo = (shampoo, scalpType) => {
  let shamp = null;
  if (shampoo === "yes") {
    if (scalpType === "잘 모르겠어요") {
      shamp = PRODUCTS.neshmint.id;
    }
    if (scalpType === "지성 두피") {
      shamp = PRODUCTS.neshmint.id;
    }
    if (scalpType === "건성 두피") {
      shamp = PRODUCTS.neshsoy.id;
    }
    if (scalpType === "민감성 두피") {
      shamp = PRODUCTS.neshsoy.id;
    }
  }
  return shamp;
};

export const getShampExplain = (scalpType) => {
  let explain = "";

  if (scalpType === "잘 모르겠어요") {
    explain = "두피와 탈모에 효과적인 샴푸";
  }
  if (scalpType === "지성 두피") {
    explain = "지성 두피와 탈모에 효과적인 샴푸";
  }
  if (scalpType === "건성 두피") {
    explain = "건성 두피와 탈모에 효과적인 샴푸";
  }
  if (scalpType === "민감성 두피") {
    explain = "민감성 두피와 탈모에 효과적인 샴푸";
  }

  return explain;
};

export const translateAnswer = (ans, surveyType, surveyVersion, kid) => {
  //  console.log(ans, surveyType, surveyVersion);
  let answer = {};
  answer.id = kid;

  if (surveyVersion === "1.6") {
    if (surveyType === "재진") {
      let qna = [];

      if (ans.sv_past_pill) {
        qna.push({
          q: "기존에는 어떤 탈모약을 복용하고 계셨었나요?",
          a: ans.sv_past_pill[0],
        });
      }

      if (ans.sv_past_how_long) {
        qna.push({ q: "얼마나 복용 하셨나요?", a: ans.sv_past_how_long[0] });
      }

      if (ans.sv_scalp_type) {
        qna.push({ q: "두피타입을 선택해 주세요", a: ans.sv_scalp_type[0] });
      }

      if (ans.sv_other_cares) {
        qna.push({
          q: "현재 탈모관리를 위해 어떠한 것들을 하고 있나요?",
          a: ans.sv_other_cares.join(),
        });
      }

      if (ans.sv_what_pill && ans.sv_what_pill.ans) {
        qna.push({
          q: "어떤 탈모약을 처방받기 원하시나요?",
          a: PRODUCTS[ans.sv_what_pill.ans[0]].name,
        });
      }
      if (ans.sv_what_minox && ans.sv_what_minox.ans) {
        qna.push({
          q: "바르는 탈모약(미녹시딜 5%)도 같이 받아보시겠어요?",
          a:
            ans.sv_what_minox.ans[0] !== "아니요"
              ? PRODUCTS[ans.sv_what_minox.ans[0]].name
              : "아니요",
        });
      }
      if (ans.sv_month && ans.sv_month.ans) {
        qna.push({
          q: "몇개월 처방을 희망하세요?",
          a: ans.sv_month.ans[0] + " 개월",
        });
      }
      if (ans.sv_message && ans.sv_message.ans) {
        qna.push({ q: "추가 메시지", a: ans.sv_message.ans });
      }

      answer.survey = qna;
      return answer;
    }

    if (surveyType === "초진") {
      let qna = [];

      if (ans.fv_baldness && ans.fv_baldness.ans) {
        qna.push({
          q: "현재 모발상태는 어떠한가요?",
          a: ans.fv_baldness.ans[0],
        });
      }
      if (ans.fv_want_medication && ans.fv_want_medication.ans) {
        qna.push({
          q: "효과가 있다면 처방약을 드실 의향이 있으신가요?",
          a: ans.fv_want_medication.ans[0],
        });
      }
      if (ans.fv_when_start && ans.fv_when_start.ans) {
        qna.push({
          q: "언제부터 탈모가 시작되었나요?",
          a: ans.fv_when_start.ans[0],
        });
      }
      if (ans.fv_other_care && ans.fv_other_care.ans) {
        qna.push({
          q: "현재 탈모관리를 위해 어떠한 것들을 하고 있나요?",
          a: ans.fv_other_care.ans.join(),
        });
      }
      if (ans.fv_scalp_type && ans.fv_scalp_type.ans) {
        qna.push({
          q: "두피타입을 선택해 주세요",
          a: ans.fv_scalp_type.ans[0],
        });
      }
      if (ans.fv_stress && ans.fv_stress.ans) {
        qna.push({
          q: "최근에 평소보다 유독 심한 스트레스를 받은 적이 있나요?",
          a: ans.fv_stress.ans[0],
        });
      }
      if (ans.fv_heredity && ans.fv_heredity.ans) {
        qna.push({ q: "탈모 가족력이 있으신가요?", a: ans.fv_heredity.ans[0] });
      }
      if (ans.fv_want_copy && ans.fv_want_copy.ans) {
        qna.push({
          q: "처방 시 무엇을 더 선호하세요?",
          a: ans.fv_want_copy.ans[0],
        });
      }
      if (ans.fv_month && ans.fv_month.ans) {
        qna.push({
          q: "몇개월 처방을 희망하세요?",
          a: ans.fv_month.ans[0] + " 개월",
        });
      }
      if (ans.fv_message && ans.fv_message.ans) {
        qna.push({ q: "추가 메시지", a: ans.fv_message.ans });
      }

      answer.survey = qna;
      return answer;
    }
  }
};

export const maskSSN = (ssn) => {
  let masked = ssn.substring(0, 8) + "******";
  return masked;
};

export const getUserChartHistoryByKID = async (kid) => {
  const user = await getUser(`user#${kid}`);
  if (!user) {
    return false;
  }
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const formatDisplayTime = (time) => {
  let timeObj = new Date(time);
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let hour = timeObj.getHours();

  return `${zeroPad(hour, 2)}:00 - ${zeroPad(hour + 1, 2)}:00`;
};

export const formatDisplayDate = (time) => {
  let yoil = ["일", "월", "화", "수", "목", "금", "토"];

  let timeObj = new Date(time);
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let day = timeObj.getDay();
  let hour = timeObj.getHours();

  return `${zeroPad(month, 2)}월${zeroPad(date, 2)}일 ${yoil[day]}요일`;
};

export const formatDisplayDateHistory = (time) => {
  let yoil = ["일", "월", "화", "수", "목", "금", "토"];

  let timeObj = new Date(time);
  let year = timeObj.getFullYear();
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let day = timeObj.getDay();
  let hour = timeObj.getHours();

  return `${year}. ${zeroPad(month, 2)}.  ${zeroPad(date, 2)}`;
};

export const formatDisplayDateTime = (time) => {
  return `${formatDisplayDate(time)} ${formatDisplayTime(time)}`;
};

export const formatOperationDate = (time) => {
  let yoil = ["일", "월", "화", "수", "목", "금", "토"];

  let timeObj = new Date(time);
  let year = timeObj.getFullYear();
  let month = timeObj.getMonth() + 1;
  let date = timeObj.getDate();
  let day = timeObj.getDay();
  let hour = timeObj.getHours();

  return `${zeroPad(month, 2)}. ${zeroPad(date, 2)}`;
};

export const formatOperationPayTime = (time) => {
  var year = String(time).substring(0, 4);
  var month = String(time).substring(4, 6);
  var date = String(time).substring(6, 8);
  var hour = String(time).substring(8, 10);
  var minutes = String(time).substring(10, 12);
  return `${year}. ${month}. ${date}. ${hour}:${minutes}`;
};

export const getScheduleFromServer = async () => {
  // fetch doctor scheduels from server:
  const res = await getMarsUtils("mars_doctor_schedules");

  let data;
  if (res) {
    data = res.data.getMarsUtil;
    let doctorSchedules = JSON.parse(data.value);

    let doctor1TimeArray = doctorSchedules.doctor1;
    let doctor2TimeArray = doctorSchedules.doctor2;
    let gaonTimeArray = doctorSchedules.gaonsamsung;

    // get compiled schedule
    let compiledTimeSlots = [];

    for (let i = 0; i < doctor1TimeArray.length; i++) {
      // console.log(doctor1TimeArray[i].time, doctor2TimeArray[i].time, gaonTimeArray[i].time);

      let avail = false;
      if (
        doctor1TimeArray[i].isAvail ||
        doctor2TimeArray[i].isAvail ||
        gaonTimeArray[i].isAvail
      ) {
        avail = true;
      }

      compiledTimeSlots.push({
        time: doctor1TimeArray[i].time,
        isAvail: avail,
      });
    }

    // get avail times using compiled timeslot
    let availTimes = getAvailTimes2(compiledTimeSlots);
    return availTimes;
  } else {
    slack2accept("스케쥴러 에러발생!! 김선민을 찾아라!!");
  }
};
