import React, { useState, useEffect } from "react";

// css
import "./headerStyles.css";

export default function EventHeader() {
  const titleStyle = {
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "33px",
    textAlign: "center",
    position: "relative",
    zIndex: 99,
  };

  return (
    <>
      <div className="event-header-container">
        {/* add link to previous page */}
        {/* <a href="#" class="previous round">
          &#8249;
        </a> */}
        <h1 style={titleStyle}>탈모유전자검사 이벤트</h1>
      </div>
    </>
  );
}
