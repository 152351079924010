import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

import loader from "../status-assets/paymentLoader.json";
import logo from "../../../assets/img/mars_logo_black.png";

export default function PaymentLoader() {
  return (
    <div
      // style={{
      //   display: "grid",
      //   height: "100vh",
      //   placeItems: "center",
      //   alignContent: "center",
      //   position: "relative",
      //   marginTop: "-100px",
      // }}
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        top: 0,
        marginLeft: "-26px",
        display: "grid",
        placeItems: "center",
        alignContent: "center",
      }}
    >
      <Lottie
        animationData={loader}
        id="payment-animation"
        style={{ width: "96px", height: "auto" }}
      ></Lottie>
      <p
        style={{
          fontFamily: "NanumBarunGothic",
          fontSize: "13px",
          fontWeight: "300",
        }}
      >
        결제 중 입니다. 잠시만 기다려주세요.
      </p>
      <img
        src={logo}
        style={{
          position: "absolute",
          bottom: "50px",
          placeSelf: "center",
          width: "64px",
          height: "auto",
        }}
        alt="mars-logo"
      />
    </div>
  );
}
