import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

function ExogeneSubmit(props) {
  const history = useHistory();
  return (
    <div className="scrollable">
      <MarsNavbarWithLine />
      <div style={{ padding: "14px", marginTop: "150px" }}>
        <img alt="..." src={require("assets/img/exogene_done.png")}></img>
      </div>

      <div>
        <Row
          className="justify-content-center"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            padding: 0,
            margin: 0,
            width: "100%",
          }}
        >
          <Col md="4" xs="12">
            <Button
              style={{
                width: "100%",
                height: "60px",
                backgroundColor: "#000000",
                fontSize: "18px",
              }}
              className="btn"
              size="lg"
              color="primary"
              disabled={false}
              onClick={() => {
                // history.push("/exogene-reservation");
                window.location.href = "kakaotalk://inappbrowser/close";
              }}
            >
              닫기
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ExogeneSubmit;
