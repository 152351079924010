import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "reactstrap";

// images
import DownArrow from "../dna-event-assets/DownArrow.png";
import UpArrow from "../dna-event-assets/UpArrow.png";
import TypeOne from "../dna-event-assets/DNATypeOne.png";
import TypeTwo from "../dna-event-assets/DNATypeTwo.png";

// css
import "../dnaEventStyles.css";

export default function FourthQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [buttonArrow, setButtonArrow] = useState(DownArrow);
  const [titleStyle, setTitleStyle] = useState();
  const titleRef = useRef();

  const itemToView = document.getElementById("collapse-content-fourth");

  const toggle = () => {
    setCollapse(!collapse);
    setTitleStyle(titleRef.current.style.fontWeight);
    if (buttonArrow === DownArrow) {
      setButtonArrow(UpArrow);
    } else {
      setButtonArrow(DownArrow);
    }
  };

  useEffect(() => {
    if (titleStyle === "400") {
      titleRef.current.style.fontWeight = "600";
    } else {
      titleRef.current.style.fontWeight = "400";
    }

    if (collapse) {
      setTimeout(() => {
        itemToView.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 260);
    }
  }, [collapse]);

  const imgStyle = {
    width: "auto",
    height: "80px",
    marginBottom: "5px",
    marginLeft: "-1px",
  };

  return (
    <div className="dna-question-wrapper">
      <button id="dna-toggle-button" onClick={toggle}>
        <p ref={titleRef} id="dna-main-title">
          어떤 유전자를 검사하나요?
        </p>
        <img id="dna-button-image" src={buttonArrow} />
      </button>
      <Collapse isOpen={collapse}>
        <div id="collapse-content-fourth">
          <div id="child-no-padding" className="dna-list-text-container">
            <ol>
              <h6 style={{ fontSize: "15px" }}>1.남성형 탈모</h6>
              <img style={imgStyle} src={TypeOne}></img>
              <p id="dna-text-list-body">
                모근세포를 파괴하여 남성형 탈모를 일으키는 유전자
              </p>
            </ol>
            <ol>
              <h6 style={{ fontSize: "15px" }}>2. 원형 탈모</h6>
              <img style={imgStyle} src={TypeTwo}></img>
              <p id="dna-text-list-body">
                모근세포를 파괴하여 남성형 탈모를 일으키는 유전자
              </p>
            </ol>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
