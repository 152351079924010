import React, { useState, useEffect } from "react";
import PhotoChartCard from "./OperationCardNew";
import { getDoctorInfo } from "utils/util";

// img
import searchIcon from "../pharma2/Vector.png";

// css
import "./styling/viewerAcceptStyles.css";

export default function ChartViewerPay(props) {
  const [search, setSearch] = useState("");
  const [doctorBatch, setDoctorBatch] = useState();
  const [totalBatch, setTotalBatch] = useState(0);

  const getLastAppointedDoctor = (chart) => {
    if (!chart) {
      return;
    }

    for (let c of props.charts) {
      if (c.id !== chart.id && c.kid === chart.kid && c.doctor) {
        return getDoctorInfo(c.doctor).office;
      }
    }

    return "";
  };
  useEffect(() => {
    let doctorBatch = {};
    let today = new Date();
    setTotalBatch(0);
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    let todayString = `${today.getFullYear()}-${zeroPad(
      today.getMonth() + 1,
      2
    )}-${zeroPad(today.getDate(), 2)}`;
    for (let chart of props.charts) {
      if (chart.yeyak_time.startsWith(todayString)) {
        doctorBatch[chart.doctor] =
          (doctorBatch[chart.doctor] ? doctorBatch[chart.doctor] : 0) + 1;
      }
    }
    setDoctorBatch(doctorBatch);
    // console.log(doctorBatch);
    for (const [key, value] of Object.entries(doctorBatch)) {
      if (key !== "") {
        setTotalBatch((totalBatch) => totalBatch + parseInt(value));
      }
    }
  }, [props.charts]);

  return (
    <div className="viewer-accept-container-venus">
      <div className="viewer-accept-header-venus">
        <div className="operation-search-venus">
          {props.dstatus === "phone" ? (
            <span style={{ width: "165px" }}>진료중</span>
          ) : (
            <></>
          )}
          <div>
            <img
              src={searchIcon}
              style={{
                width: "15px",
                position: "absolute",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              alt="search-icon-pharma"
            />
            <input
              type="text"
              value={search}
              placeholder="검색"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="viewer-accept-doctor-batch-venus">
          {/* {JSON.stringify(doctorBatch)} */}
          <span style={{ fontWeight: 600 }}>진행현황</span> &nbsp;
          {doctorBatch && (
            <>
              {`서울정의원: [${
                doctorBatch["doctor1"] || 0
              }건]  |  보령이가정의원: [${
                doctorBatch["doctor2"] || 0
              }건] |  가온삼성비뇨기과: [${
                doctorBatch["gaonsamsung"] || 0
              }건] | 총 [${totalBatch}건]`}
            </>
          )}
        </div>
      </div>
      <div className="operation-table-header-container-venus">
        <table className="operation-table-header-venus">
          <th id="op-name">이름 (연락처)</th>
          <th id="op-time">희망진료시간</th>
          <th id="op-ssn">주민번호</th>
          <th id="op-type">환자타입</th>
          <th id="op-hospital">병원</th>
        </table>
      </div>
      <div className="viewer-accept-content-container-venus">
        {props.charts.map((c) => {
          if (c.user_status === "pay") {
            return (
              <PhotoChartCard
                key={c.id}
                patient={c}
                // onVerifyPayment={handleVerifyPayment}
                // onUpdate={handleUpdate}
              />
            );
          }
        })}
        <div
          style={{
            paddingLeft: "15px",
            height: "auto",
            lineHeight: "18px",
            fontWeight: 200,
            letterSpacing: "3px",
            whiteSpace: "pre-wrap",
            fontFamily: "aria",
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
            marginTop: "50px",
          }}
        >
          <div>{"  __( o)>"}</div>
          <div>{" <_.  )"}</div>
          <div style={{ lineHeight: 0 }}>{"---------------"}</div>
        </div>
      </div>
    </div>
  );
}
