/*eslint-disable*/
import React, { useState } from "react";
import * as config from "../../config";

// reactstrap components
import { Button, Container, Modal } from "reactstrap";
import ServiceAgreement from "views/agreements/ServiceAgreement";
import PersonalInfoPolicy from "views/agreements/PersonalInfoPolicy";

function LightFooter() {
  const [modalLive, setModalLive] = useState(false);
  const [type, setType] = useState("service");
  return (
    <div>
      <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLiveLabel">
            {type === "service" &&
              <div>서비스 이용 약관</div>
            }
            {type === "personal" &&
              <div>개인정보 수집 약관</div>
            }
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setModalLive(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {type === "service" &&
            <ServiceAgreement></ServiceAgreement>
          }
          {type === "personal" &&
            <PersonalInfoPolicy></PersonalInfoPolicy>
          }
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={() => setModalLive(false)}
          >
            닫기
          </Button>
        </div>
      </Modal>

      <footer className="footer">
        <Container>
          <div style={{ border: "", textAlign: "left", fontSize: "0.7rem", color:"grey" }}>
            <img height="42px" alt="..." src={require("assets/img/mnv_logo.png")}></img>
            <br />
            (주)마스앤비너스 | 대표: 김승규 | contact@marsnvenus.net<br />
            서울시 강남구 테헤란로 151 역삼하이츠빌딩 423<br />
            사업자등록번호: 673-87-02092<br />
            통신판매번호: 2021-서울강남-06224<br />
          </div>
          <br />
          <div>
          <div style={{ fontSize: "0.7rem", float:"left" }}
            onClick={() => {
              setType("service");
              setModalLive(true)
            }}>
            서비스 이용 약관 |
          </div>
          <div style={{ fontSize: "0.7rem", float:"left", marginLeft:"2px" }}
            onClick={() => {
              setType("personal");
              setModalLive(true)
            }}>
            {" "}개인정보 처리 방침
          </div>
          </div>
          <br />
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, (주)마스앤비너스 All rights reserved {config.VERSION_STRING}
          </div>
        </Container>
      </footer>
    </div>
  );
}

export default LightFooter;
