import { Button, Modal } from "reactstrap";
import React, { useRef, useEffect, useState } from "react";
import Picker from "rmc-picker";

function TimeSlotModal(props) {
  const [disableButton, setDisableButton] = useState(true);
  const ref = useRef(null);

  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setIsTimeSlotOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

  return (
    <Modal
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: 0,
        width: "100%",
        animation: "slideUp 1s",
      }}
      isOpen={props.isOpen}
    >
      <div
        style={{
          backgroundColor: "white",
          zIndex: 4,
          borderRadius: "20px 20px 0px 0px",
          padding: "14px",
        }}
        ref={ref}
      >
        {/* todo:find a better way to disable button */}
        <Picker
          selectedValue={1}
          onValueChange={(e) => {
            props.onUpdate(e);
            let split = e.split("T");
            if (split.length === 1) {
              setDisableButton(true);
            } else {
              setDisableButton(false);
            }
          }}
        >
          {props.items}
        </Picker>
        <Button
          style={{
            width: "100%",
            fontSize: "14px",
            color: "white",
            height: "42px",
            background: "black",
          }}
          disabled={disableButton}
          onClick={() => {
            props.onClose();
            try {
              props.setSelectedTime(true);
            } catch (error) {
              console.log("no time prop");
            }
          }}
        >
          선택 완료
        </Button>
      </div>
    </Modal>
  );
}
export default TimeSlotModal;
