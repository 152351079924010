// css
import "./styling/operationMainStyles.css";

// imports
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import { FaMoneyBill, FaPhotoVideo } from "react-icons/fa";
import React, { useEffect, useState, useSyncExternalStore } from "react";

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import ChartViewerAccept from "./ChartViewerAcceptNew";
import ChartViewerIng from "./ChartViewerIngNew";
import ChartViewerPhoneNew from "./ChartViewerPhoneNew";
import ChartViewerPaidNew from "./ChartViewerPaidNew";
import NeshViewer from "./NeshViewer";
import ExogeneViewer from "./ExogeneViewer";
import ChartViewerCancel from "./ChartViewerCancelNew";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import JungsanViewer from "./JungsanViewer";

import "@aws-amplify/ui-react/styles.css";

// img
import logo from "./operation-assets/venus_logo.png";
import box from "./operation-assets/box.png";
import heart from "./operation-assets/heart.png";
import microscope from "./operation-assets/microscope.png";
import search from "./operation-assets/search.png";
import shampoo from "./operation-assets/shampoo.png";

import ChartViewerDeliDone from "./ChartViewerDeliDone";
import { getChartsByPlatform } from "utils/data";
import ChartViewer from "./ChartViewer";
import ChartViewerPay from "./ChartViewerPay";
import { getOrderShipmentsByProcessor } from "utils/data";
import { m } from "framer-motion";

function OperationPageVenus() {
  const opPath = "operation-venus";
  const [users, setUsers] = useState([]);
  const [patients, setPatients] = useState([]);
  const [charts, setCharts] = useState([]);

  const [neshes, setNeshes] = useState([]);
  const [exogenes, setExogenes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [when, setWhen] = useState("1");

  // commercial || pharmaceuticals
  const [commShipments, setCommShipments] = useState([]);
  const [pharmShipments, setPharmShipments] = useState([]);

  const history = useHistory();

  const numCalc = (arr, attr, status) => {
    let count = 0;
    for (let a of arr) {
      if (a[attr] === status) {
        count++;
      }
    }
    return count;
  };

  const updateLocalChart = (chart) => {
    setCharts((current) =>
      current.map((obj) => {
        if (obj.id === chart.id) {
          return {
            ...chart,
          };
        }
        return obj;
      })
    );
  };

  const insertLocalChart = async (chart) => {
    setCharts((current) => [chart, ...current]);
  };

  async function getData() {
    setIsLoading(true);
    let charts_ = await getChartsByPlatform("venus", null, "DESC");
    setCharts(charts_);
    setPatients(charts_);
    setIsLoading(false);
  }

  async function getShipmentData(processor) {
    setIsLoading(true);
    let shipments_ = await getOrderShipmentsByProcessor(processor);

    // separate commerce && pharma shipments
    for (const shipment of shipments_) {
      if (shipment.processor === "pharma") {
        setPharmShipments((pharmShipments) => [...pharmShipments, shipment]);
      } else if (shipment.processor === "commerce") {
        setCommShipments((pharmShipments) => [...pharmShipments, shipment]);
      }
    }

    setIsLoading(false);
    return shipments_;
  }

  // ****************************** For Operation Testing Purposes ******************************
  // useEffect(() => {
  //   console.log("pharm: ", pharmShipments);
  //   console.log("comm: ", commShipments);
  // }, [pharmShipments, commShipments]);

  useEffect(() => {
    const asyncWrapper = async () => {
      // fetch data
      let user = await Auth.currentUserInfo();
      if (user.username !== "mars1") {
        alert("오퍼레이터로 로그인 해주세요!");
        let so = await Auth.signOut();
        return;
      }

      let pharmaShipments_ = await getShipmentData("pharma");
      let commereShipments_ = await getShipmentData("commerce");

      // subscription for onCreateMars
      //
      const subscription = API.graphql({
        query: subscriptions.onCreateChart,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let chart = value.data.onCreateChart;
          // insertLocalPatient(mars);
          insertLocalChart(chart);
        },
        error: (error) => console.warn(error),
      });

      // subscription for onUpdateMars
      //
      const onupdateSubscription = API.graphql({
        query: subscriptions.onUpdateChart,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let chart = value.data.onUpdateChart;
          if (chart) {
            updateLocalChart(chart);
          }
        },
        error: (error) => console.warn(error),
      });

      // subscription for onDeletePatient
      //
      const onDeleteSubscription = API.graphql({
        query: subscriptions.onDeleteChart,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let chart = value.data.onDeleteChart;
          getData();
        },
        error: (error) => console.warn(error),
      });

      getData();

      // clean-up unsubscribe
      //
      return () => {
        subscription.unsubscribe();
        onupdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      };
    };
    asyncWrapper();
  }, []);

  const handleUpdate = (type, body) => {
    if (type === "when") {
      setWhen(body);
    }
  };
  const handleDeliveryReady = async (pid, deliveryNumber) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_done",
      status: "deli_done",
      ignoreStatus: "no",
      delivery_number: deliveryNumber,
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  const handleMedicationReady = async (pid, pname) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_prep",
      ignoreStatus: "no",
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  /* 결제확인 완료 택배예약 완료 버튼 핸들 */
  const handleVerifyPayment = async (pid, pname) => {
    // graphql update patient dstatus
    //
    const patientDetails = {
      id: pid,
      pstatus: "medi_prep",
      ignoreStatus: "no",
      r: "n",
    };

    const updatedPatient = await API.graphql({
      query: mutations.updateMars,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: patientDetails },
    });
    // console.log(updatedPatient);
    getData();
  };

  // Amplify signout
  const signOut = async () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  };

  return (
    <div className="operation-main-container-venus">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <MarsLoaderBasic isLoading={isLoading} />{" "}
        </div>
      )}
      <div id="operation-navbar-venus">
        <ProSidebar>
          <a
            id="op-logo"
            onClick={() => {
              console.log("logo click");
            }}
          >
            <img src={logo} />
          </a>
          <SidebarContent>
            <Menu iconShape="round">
              <SubMenu
                defaultOpen={true}
                title={`진료현황 [${
                  numCalc(charts, "user_status", "pending") +
                  numCalc(charts, "doctor_status", "phone") +
                  numCalc(charts, "doctor_status", "accept") +
                  numCalc(charts, "doctor_status", "cancel") +
                  numCalc(charts, "user_status", "pay")
                }]`}
                icon={<img src={heart} id="op-menu-icon" />}
                id="op-submenu"
              >
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-pending`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-pending` && (
                    <div id="op-square"></div>
                  )}
                  배정대기 [{numCalc(charts, "user_status", "pending")}]
                  <Link to={`/${opPath}/chart-pending`} />
                </MenuItem>
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-accept`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-accept` && (
                    <div id="op-square"></div>
                  )}
                  접수대기 [{numCalc(charts, "user_status", "accept")}
                  ]
                  <Link to={`/${opPath}/chart-accept`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-phone`}
                >
                  {window.location.pathname === `/${opPath}/chart-phone` && (
                    <div id="op-square"></div>
                  )}
                  통화진료중 [{numCalc(charts, "doctor_status", "phone")}
                  ]
                  <Link to={`/${opPath}/chart-phone`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-pay`}
                >
                  {window.location.pathname === `/${opPath}/chart-pay` && (
                    <div id="op-square"></div>
                  )}
                  처방완료 [{numCalc(charts, "user_status", "pay")}]
                  <Link to={`/${opPath}/chart-pay`} />
                </MenuItem>

                {/* Brought this from 조제현황 -> might not be the same cancel status */}
                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-cancel`
                  }
                >
                  {window.location.pathname === `/${opPath}/chart-cancel` && (
                    <div id="op-square"></div>
                  )}
                  취소 [{numCalc(charts, "doctor_status", "cancel")}]
                  <Link to={`/${opPath}/chart-cancel`} />
                </MenuItem>
              </SubMenu>
              <SubMenu
                defaultOpen={true}
                title={`조제현황 [${
                  numCalc(charts, "user_status", "paid") +
                  numCalc(pharmShipments, "processor_status", "joje_ing") +
                  numCalc(pharmShipments, "processor_status", "joje_done") +
                  numCalc(pharmShipments, "processor_status", "deli_start") +
                  numCalc(pharmShipments, "processor_status", "deli_done")
                }]`}
                icon={<img src={box} id="op-menu-icon" />}
                id="op-submenu"
              >
                <MenuItem
                  id="op-menu-item"
                  active={window.location.pathname === `/${opPath}/chart-paid`}
                >
                  {window.location.pathname === `/${opPath}/chart-paid` && (
                    <div id="op-square"></div>
                  )}
                  결제완료 [{numCalc(charts, "user_status", "paid")}]
                  <Link to={`/${opPath}/chart-paid`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-medi-prep`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-medi-prep` && <div id="op-square"></div>}
                  조재중 [
                  {numCalc(pharmShipments, "processor_status", "joje_ing")}]
                  <Link to={`/${opPath}/chart-medi-prep`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-deli-prep`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-deli-prep` && <div id="op-square"></div>}
                  배송대기 [
                  {numCalc(pharmShipments, "processor_status", "joje_done")}]
                  <Link to={`/${opPath}/chart-deli-prep`} />
                </MenuItem>

                <MenuItem
                  id="op-menu-item"
                  active={
                    window.location.pathname === `/${opPath}/chart-deli-done`
                  }
                >
                  {window.location.pathname ===
                    `/${opPath}/chart-deli-done` && <div id="op-square"></div>}
                  {/* DELIVERY_DONE - 약배송 완료 */}
                  배송완료 [
                  {numCalc(pharmShipments, "processor_status", "deli_start")}]
                  <Link to={`/${opPath}/chart-deli-done`} />
                </MenuItem>
              </SubMenu>

              <SubMenu
                defaultOpen={false}
                title={`nesh 샴푸 [${commShipments.length}]`}
                icon={
                  <img
                    src={shampoo}
                    id="op-menu-icon"
                    style={{ width: "12px" }}
                  />
                }
                id="op-submenu"
              >
                <MenuItem id="op-menu-item">
                  {window.location.pathname === `/${opPath}/shamp_paid` && (
                    <div id="op-square"></div>
                  )}
                  결제완료 [{numCalc(commShipments, "processor_status", "paid")}
                  ]
                  <Link to={`/${opPath}/shamp_paid`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_order_ready` && (
                    <div id="op-square"></div>
                  )}
                  배송신청 [
                  {numCalc(commShipments, "processor_status", "order_ready")}]
                  <Link to={`/${opPath}/shamp_order_ready`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_order_done` && <div id="op-square"></div>}
                  운송장 입력 [
                  {numCalc(commShipments, "processor_status", "order_processed")}]
                  {/* deli_start????? */}
                  <Link to={`/${opPath}/shamp_order_done`} />
                </MenuItem>

                <MenuItem id="op-menu-item">
                  {window.location.pathname ===
                    `/${opPath}/shamp_deli_done` && <div id="op-square"></div>}
                  배송시작 [{numCalc(commShipments, "processor_status", "deli_start")}]
                  <Link to={`/${opPath}/shamp_deli_done`} />
                </MenuItem>
              </SubMenu>

              <MenuItem
                id="op-menu-item"
                icon={<img src={search} id="op-menu-icon" />}
              >
                고객 검색 [{users.length}]
              </MenuItem>

              <MenuItem id="op-menu-item" icon={<FaPhotoVideo />}>
                사진검색 []
                <Link to={`/${opPath}/photo-chart`} />
              </MenuItem>

              <MenuItem id="op-menu-item" icon={<FaMoneyBill />}>
                자동정산 []
                <Link to={`/${opPath}/jungsan`} />
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter></SidebarFooter>
          {/* currently for testing purposes */}
          <button
            id="operation-logout-button"
            onClick={() => {
              signOut();
            }}
          >
            🦆
          </button>
        </ProSidebar>
      </div>
      <div>
        <Switch>
          {/* chart routes */}
          <Route exact path={`/${opPath}`}>
            <ChartViewerAccept charts={charts} status={"accept"} />
          </Route>

          <Route path={`/${opPath}/chart-pending`}>
            <ChartViewerAccept charts={charts} status={"pending"} />
          </Route>

          <Route path={`/${opPath}/chart-accept`}>
            <ChartViewerIng charts={charts} status={"accept"} />
          </Route>

          <Route path={`/${opPath}/chart-phone`}>
            <ChartViewerPhoneNew charts={charts} dstatus={"phone"} />
          </Route>

          <Route path={`/${opPath}/chart-pay`}>
            <ChartViewerPay charts={charts} status={"pay"} />
          </Route>

          {/* ------------- 조제현황 ------------- */}

          {/* 결제완료 */}
          <Route path={`/${opPath}/chart-paid`}>
            {/* <ChartViewerPaidNew charts={patients} status={"paid"} /> */}
            <ChartViewerPaidNew charts={charts} status={"paid"} />
          </Route>

          {/* 조재중 */}
          <Route path={`/${opPath}/chart-medi-prep`}>
            <ChartViewer
              charts={patients}
              shipments={pharmShipments}
              status={"joje_ing"}
            />
          </Route>

          {/* 배송대기 */}
          <Route path={`/${opPath}/chart-deli-prep`}>
            <ChartViewer
              charts={charts}
              shipments={pharmShipments}
              status={"joje_done"}
            />
          </Route>

          {/* 취소 */}
          <Route path={`/${opPath}/chart-cancel`}>
            <ChartViewerCancel charts={patients} dstatus={"cancel"} />
          </Route>

          {/* 배송완료 */}
          <Route path={`/${opPath}/chart-deli-done`}>
            <ChartViewerDeliDone
              allCharts={charts}
              shipments={pharmShipments}
              commShipments={commShipments}
              status={"deli_done"}
            />
          </Route>

          {/* exogene routes */}
          <Route path={`/${opPath}/exogene-accept`}>
            <ExogeneViewer exogenes={exogenes} status={"accept"} />
          </Route>

          <Route path={`/${opPath}/exogene-kit-sent`}>
            <ExogeneViewer exogenes={exogenes} status={"kit_sent"} />
          </Route>

          <Route path={`/${opPath}/jungsan`}>
            <JungsanViewer charts={charts} />
          </Route>

          {/* Nesh routes */}
          <Route path={`/${opPath}/shamp_paid`}>
            <NeshViewer
              neshes={neshes}
              status={"paid"}
              shipments={commShipments}
            />
          </Route>
          <Route path={`/${opPath}/shamp_order_ready`}>
            <NeshViewer
              neshes={neshes}
              shipments={commShipments}
              status={"order_ready"}
            />
          </Route>
          <Route path={`/${opPath}/shamp_order_done`}>
            <NeshViewer
              neshes={neshes}
              shipments={commShipments}
              status={"order_processed"}
            />
          </Route>
          <Route path={`/${opPath}/shamp_deli_done`}>
            <NeshViewer
              neshes={neshes}
              shipments={commShipments}
              status={"deli_start"}
            />
          </Route>
          {/* <Route path={`/${opPath}/photo-chart`}>
            <PhotoChartViewer charts={charts} />
          </Route> */}
        </Switch>
      </div>
    </div>
  );
}
export default withAuthenticator(OperationPageVenus);
