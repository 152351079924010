import "./tableStyling.css";

// return medication info table in standard mobile format
export const getMedTableMobile = (medication) => {
  try {
    return (
      <table className="venus-med-table">
        <tbody>
          {medication &&
            medication.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.medicine.product_name}</td>
                  <td>
                    {item.medicine.base}
                    {item.medicine.unit}
                  </td>
                  <td>{item.medicine.retail_price.toLocaleString()}원</td>
                </tr>
              );
            })}
          <tr>
            <td>진료비</td>
            <td></td>
            <td id="table-price">10,000원</td>
          </tr>
          <tr>
            <td>배송비</td>
            <td></td>
            <td id="table-price">2,900원</td>
          </tr>
          <tr id="total-price">
            <td>{""}</td>
            <td></td>
            <td id="table-price">총 🦆🦆,🦆🦆🦆원</td>
          </tr>
        </tbody>
      </table>
    );
  } catch (error) {
    return <>NULL</>;
  }
};
