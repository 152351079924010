import { __assign, __awaiter, __generator, __rest } from "tslib";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { SHA256_HEADER, UNSIGNED_PAYLOAD } from "./constants";
var S3RequestPresigner = /** @class */ (function () {
    function S3RequestPresigner(options) {
        var resolvedOptions = __assign({ 
            // Allow `signingName` because we want to support usecase of supply client's resolved config
            // directly. Where service equals signingName.
            service: options.signingName || options.service || "s3", uriEscapePath: options.uriEscapePath || false }, options);
        this.signer = new SignatureV4(resolvedOptions);
    }
    S3RequestPresigner.prototype.presign = function (requestToSign, _a) {
        if (_a === void 0) { _a = {}; }
        var _b = _a.unsignableHeaders, unsignableHeaders = _b === void 0 ? new Set() : _b, _c = _a.unhoistableHeaders, unhoistableHeaders = _c === void 0 ? new Set() : _c, options = __rest(_a, ["unsignableHeaders", "unhoistableHeaders"]);
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_d) {
                unsignableHeaders.add("content-type");
                // S3 requires SSE headers to be signed in headers instead of query
                // See: https://github.com/aws/aws-sdk-js-v3/issues/1576
                Object.keys(requestToSign.headers)
                    .map(function (header) { return header.toLowerCase(); })
                    .filter(function (header) { return header.startsWith("x-amz-server-side-encryption"); })
                    .forEach(function (header) {
                    unhoistableHeaders.add(header);
                });
                requestToSign.headers[SHA256_HEADER] = UNSIGNED_PAYLOAD;
                if (!requestToSign.headers["host"]) {
                    requestToSign.headers.host = requestToSign.hostname;
                }
                return [2 /*return*/, this.signer.presign(requestToSign, __assign({ expiresIn: 900, unsignableHeaders: unsignableHeaders,
                        unhoistableHeaders: unhoistableHeaders }, options))];
            });
        });
    };
    return S3RequestPresigner;
}());
export { S3RequestPresigner };
