import { Auth } from "aws-amplify";
import axios from "axios";
import MarsNavbar from "components/Navbars/MarsNavbar";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { sendAnalytics } from "utils/analytics";
import { slack } from "utils/util";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import MarsLoaderNew from "views/components/MarsLoaderNew";
import { Col, Row, Container } from "reactstrap";
import { delay } from "lodash";
import { pushMarsAnalytics } from "utils/analytics";
import { nanoid } from "nanoid";
import { getUser } from "utils/data";
import { createUserWithKid } from "utils/data";

// const { Col, Row, Container } = require("reactstrap");
function KakaoLogin() {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [kid, setKid] = useState("");
  const [pid, setPid] = useState("");
  const [ktoken, setKtoken] = useState("");
  const [currentPatient, setCurrentPatient] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setIsLoading(true);
    var kakaoID;
    let from = location.state || "repeat";

    // check for code parameter and get access token and userid.
    let search = location.search;

    let red_uri =
      window.location.protocol + "//" + window.location.host + "/klogin";

    // no code run kakao auth
    if (search === "") {
      window.Kakao.Auth.authorize({
        redirectUri: red_uri,
        state: from,
      });
    }

    // check if code exists
    if (search.indexOf("code") !== -1) {
      // get accessToken and userid from lambda:
      //
      let url =
        "https://n7qj9lhz1k.execute-api.ap-northeast-2.amazonaws.com/kakao/auth";
      // "https://n7qj9lhz1k.execute-api.ap-northeast-2.amazonaws.com/login";

      //get the code
      // let code = search.split("code=")[1];
      const params = new URLSearchParams(search);
      let code = params.get("code");
      let from = params.get("state");

      const klogin = async () => {
        try {
          let res = await axios.post(url, { code, red_uri });
          // set toke nand kid
          setKtoken(res.data.t);
          kakaoID = res.data.info;
          setKid(kakaoID);
          let ampUser = await Auth.signIn("user1", "user1user1");
          let info = await Auth.currentUserInfo();

          // slack(`[klogin] login success: ${kakaoID}`);
          let sid = nanoid(12);
          localStorage.setItem("mars_session_id", sid);
          await pushMarsAnalytics(kakaoID, 'user_login', { from: from});
          sendAnalytics({
            type: "log",
            from: "klogin",
            kid: kakaoID,
            event: "login.success",
            msg: `[klogin] login success: ${kakaoID}`,
          });

          // attempt to get user and if no user, create user:
          let user = await getUser(`user#${kakaoID}`);
          if (!user) { // create user
            await createUserWithKid(kakaoID);
          }

          history.push({
            pathname: "/" + from,
            kid: kakaoID,
            uid: info.id,
          });
        } catch (error) {
          // sendAnalytics({
          //   type: "error",
          //   from: "klogin",
          //   kid: kakaoID,
          //   error: JSON.stringify(error),
          //   msg: "klogin error",
          // });
          slack(`[klogin][error][${kakaoID}] ${JSON.stringify(error)}`);
          if (kakaoID) {
            history.push({
              pathname: "/" + from,
              kid: kakaoID,
            });
          }
        }
      };
      klogin();
    }
  }, []);

  return (
    <>
      {/* {isLoading && <MarsLoaderBasic isLoading={isLoading} />} */}
      {isLoading && <MarsLoaderNew />}

      {!isLoading && (
        <div>
          <MarsNavbar></MarsNavbar>

        </div>
      )}
    </>
  );
}

export default KakaoLogin;
