const { PulseLoader } = require("react-spinners");

function MarsLoaderBasic(props) {
    return (
        <>
            <div className="loader-div">
                <PulseLoader color={"#6A43B8"} loading={props.isLoading} size={18} />
            </div>
        </>
    )
}
export default MarsLoaderBasic;