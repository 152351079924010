import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Collapse } from "reactstrap";
import { reduceEachTrailingCommentRange } from "typescript";
import { sendAnalytics } from "utils/analytics";
import { getUser } from "utils/data";
import { updateUser } from "utils/data";

import { PRODUCTS } from "utils/products";
import getAvailTimes from "utils/scheduler";
import { getENV } from "utils/util";
import { getDoctorInfo, getPharmaInfo } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { maskSSN, zeroPad, formatOperationPayTime } from "utils/util";
import { getTimeObject } from "utils/util";
import { calcPrice4 } from "utils/util";
import { formatDisplayDateTime } from "utils/util";
import { formatDisplayDate } from "utils/util";
import { parseSurvey, slack2ops, won } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import * as mutations from "../../graphql/mutations";
import FVChartV15 from "views/doctor/FVChartV15";
import SVChartV15 from "views/doctor/SVChartV15";
import RepeatChartV15 from "views/doctor/RepeatChartV15";
import ScheduleChanger from "./components/ScheduleChanger";
import HospitalChanger from "./components/HospitalChanger";
import ProfileEditor from "./components/ProfileEditor";
import AddressEditor from "./components/AddressEditor";
import PDFPreviewNew from "views/doctor/PDFPreviewNew";

// css
import "./styling/operationCardStyles.css";

// img
import cancel from "./operation-assets/cancel.png";
import rewind from "./operation-assets/return.png";
import copy from "../doctor/doctor-assets/copy.png";
import { slack2events } from "utils/util";
import { updateVenusChart } from "utils/data";

export default function OperationCardNew(props) {
  const { status, lastAppointedDoctor } = props;
  // use states
  const [isDetail, setIsDetail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [ssn, setSSN] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [zip, setZip] = useState("");

  const [cancelReason, setCancelReason] = useState("");
  const [answers, setAnswers] = useState([]);
  const [signedURL, setSignedURL] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [shampDeliveryNumber, setShampDeliveryNumber] = useState("");
  const [elapsedTime, setElapsedTime] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payMessage, setPayMessage] = useState("");
  const [message, setMessage] = useState("");
  const [payResult, setPayResult] = useState(null);
  const [type, setType] = useState("재진");
  const [doctor, setDoctor] = useState("eroon");
  const [yeyakTime, setYeyakTime] = useState("");
  const [items, setItems] = useState([]);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [pill, setPill] = useState("propecia");
  const [minox, setMinox] = useState("minox");
  const [capil, setCapil] = useState(null);
  const [shamp, setShamp] = useState(null);
  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [pillMonth, setPillMonth] = useState();
  const [minoxMonth, setMinoxMonth] = useState();
  const [capilMonth, setCapilMonth] = useState(1);
  const [total, setTotal] = useState(0);
  const [userTotal, setUserTotal] = useState(0);
  const [pharma, setPharma] = useState();
  const [extraMessage, setExtraMessage] = useState();

  const [userPill, setUserPill] = useState();
  const [userPillMonth, setUserPillMonth] = useState(1);
  const [userPillPrice, setUserPillPrice] = useState(0);

  const [userMinox, setUserMinox] = useState();
  const [userMinoxMonth, setUserMinoxMonth] = useState(1);
  const [userMinoxPrice, setUserMinoxPrice] = useState(0);

  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const [openAddressEdit, setOpenAddressEdit] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openHospitalModal, setOpenHospitalModal] = useState(false);
  const [PDFPreviewOpen, setPDFPreviewOpen] = useState(false);

  const [fvPill, setFvPill] = useState();
  const [fvMinox, setFvMinox] = useState();
  const [svPill, setSvPill] = useState();
  const [svMinox, setSvMinox] = useState();

  const [displayReal, setDisplayReal] = useState(false);

  const profileEditor = {
    ssn: ssn,
    setSSN: setSSN,
    phone: phone,
    setPhone: setPhone,
    addr: addr,
    setAddr: setAddr,
    addr2: addr2,
    setAddr2: setAddr2,
    zip: zip,
    setZip: setZip,
    name: name,
    setName: setName,
    patient: props.patient,
  };

  const handleDeliveryReady = async (pid, deliveryNumber) => {
    const patientDetails = {
      id: pid,
      pstatus: "deli_done",
      status: "deli_done",
      // pstatus: "deli_prep",
      // status: "deli_prep",
      ignoreStatus: "no",
      delivery_number: deliveryNumber,
      r: "n", //refresh
    };

    try {
      let updatedPatient = await API.graphql({
        query: mutations.updateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: patientDetails },
      });
      if (updatedPatient) {
        // slack2ops("[pharmacy] 우편 등기번호입력 완료: " + deliveryNumber);
        // getData();
        // console.log("등기번호 입력 성공!: ", updatedPatient);
      }
    } catch (error) {
      alert("등기번호 입력중 에러발생. 다시 시도해 주세요: " + error);
    }
  };

  useEffect(() => {
    if (
      props.patient.status !== "pending" &&
      props.patient.status !== "accept" &&
      props.patient.dstatus !== "phone" &&
      props.patient.dstatus !== "cancel"
    ) {
      setDisplayReal(true);
    }
  }, [props.patient.status, props.patient.p2status]);

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;

      let min = Math.round(
        (new Date() - new Date(props.patient.createdAt.toString())) /
          (60 * 1000)
      );
      if (min < 60) {
        setElapsedTime(min + " 분");
      } else {
        let hour = Math.floor(min / 60);
        let remain = min % 60;
        setElapsedTime(hour + "시간" + remain + "분");
      }

      //set name, ssn, phone
      setName(props.patient.name);
      setSSN(props.patient.ssn);
      setPhone(props.patient.phone);
      setAddr(props.patient.addr);
      setAddr2(props.patient.addr2);
      setZip(props.patient.zip);

      // set the reservation time and logic
      if (p.yeyak_time) {
        // set items for time slots
        setItems(getAvailTimes());
        setYeyakTime(p.yeyak_time);
      }
    };
    asyncWrapper();
  }, []);

  const populateUserRequestedPrescriptionInfo = () => {
    let p = props.patient;

    if (p.survey_type && p.survey_type === "초진") {
      setType("초진");
    }
    if (p.survey_type && p.survey_type === "repeat") {
      setType("리필");
    }

    let sv = props.patient.survey_version;
    let r = parseSurvey(props.patient.ans, props.patient.survey_type, sv);

    let pill, minox, month, message, pmonth, mmonth;
    pill = r.pill;
    minox = r.minox;
    month = r.pillMonth;
    pmonth = r.pillMonth;
    mmonth = r.minoxMonth;
    message = r.message;

    // set answers
    setUserPill(pill);
    setUserMinox(minox);
    // setMonth(month);
    setUserPillMonth(pmonth);
    setUserMinoxMonth(mmonth);
    // setMessage(message);

    let res = calcPrice4(pill, minox, pmonth, mmonth, shamp, null, null);

    setUserPillPrice(res.pillPrice);
    setUserMinoxPrice(res.minoxPrice);
    // setShampPrice(res.shampPrice);
    setUserTotal(res.total);
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      let pmonth, mmonth;

      if (p.doctor && p.doctor !== "") {
        setDoctor(p.doctor);
      }
      setPharma(p.pharma);

      // populateUserRequestedPrescriptionInfo();

      // set doctor prescried pill and minox information
      setMinox(p.minox);
      setPill(p.pill);
      setCapil(p.capil);
      setShamp(p.shamp);
      setMonth(p.month);
      setPillMonth(p.month);
      setCapilMonth(p.capilMonth);
      setMinoxMonth(p.month);

      if (p.pillMonth) {
        pmonth = p.pillMonth;
        setPillMonth(p.pillMonth);
      }
      if (p.minoxMonth) {
        mmonth = p.minoxMonth;
        setMinoxMonth(p.minoxMonth);
      }

      let res = calcPrice4(
        p.pill,
        p.minox,
        pmonth,
        mmonth,
        p.shamp,
        p.capil,
        p.capilMonth
      );
      setPillPrice(res.pillPrice);
      setCapilPrice(res.capilPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);
      setDeliveryNumber(p.delivery_number);
    };
    asyncWrapper();
  }, []);

  const handlePDFPreviewClose = () => {
    setPDFPreviewOpen(false);
  };

  const handleUpdatePDFURL = async (url) => {
    const key = await Storage.get(url, {
      level: "protected",
      download: false,
    });
    setSignedURL(key);
    props.patient.filekey = url;
    // alert(url);
  };

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  const handleUpdate = (time) => {
    setYeyakTime(time);
  };

  const formatSSN = (ssn) => {
    if (ssn.legnth < 13) {
      return ssn;
    }
    return `${ssn.substring(0, 6)}-${ssn.substring(6, ssn.length)}`;
  };

  // returns age calculated using kssn
  const calcAge = (ssn) => {
    if (!ssn) {
      return 0;
    }
    try {
      var year = new Date().getFullYear();
      var array = ssn.split("-");
      // var age = array[0].substring(0, 2);
      if (
        array[1].startsWith("1") ||
        array[1].startsWith("2") ||
        array[1].startsWith("5")
      ) {
        return year - (1900 + parseInt(array[0].substring(0, 2)));
      } else {
        return year - (2000 + parseInt(array[0].substring(0, 2)));
      }
    } catch (error) {
      console.log("invalid ssn");
    }
    return 0;
  };

  const formatOpTime = (time) => {
    let yoil = ["일", "월", "화", "수", "목", "금", "토"];
    var dateObj = new Date(time);
    var date = dateObj.getDate();
    var year = dateObj.getFullYear() % 100;
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDay();
    return `'${year}. ${zeroPad(month, 2)}. ${zeroPad(date, 2)} (${yoil[day]})`;
  };

  const formatResTime = (time) => {
    var resv = formatDisplayDateTime(time).split(" ");
    return resv[2] + " - " + resv[4];
  };

  const getPayTime = (time) => {
    if (!time) {
      return 0;
    }
    try {
      return String(time);
    } catch (error) {
      console.log("invalid pay time");
    }
  };

  function copyText(containerid) {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }

    if (document.selection) {
      var range = document.body.createTextRange();
      range.moveToElementText(document.getElementById(containerid));
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(document.getElementById(containerid));
      window.getSelection().addRange(range);
      document.execCommand("copy");
    }
  }


  return (
    <>
      {props.patient ? (
        <Card style={{ boxShadow: "none", margin: "0px 0px 10px 0px" }}>
          <div
            className="operation-card-face-venus"
            onClick={() => {
              setIsDetail(!isDetail);
            }}
          >
            <span id="op-name">
              {props.patient.user_info.name || "loading.."} (
              {parsePhoneNumber(props.patient.user_info.phone)})
            </span>
            <span id="op-time">
              {formatOpTime(yeyakTime)}{" "}
              <span style={{ color: "rgba(255, 32, 179, 1)", fontWeight: 600 }}>
                {formatResTime(yeyakTime)}
              </span>
            </span>
            <span id="op-ssn" style={{position:'relative'}}>
              <div id={`copyable-${props.patient.user_info.ssn}`}>
                {formatSSN(props.patient.user_info.ssn)}
              </div>
              {/* <img
                onClick={(e) => {
                  e.stopPropagation();
                  copyText(`copyable-${props.patient.user_info.ssn}`);
                }}
                id='operation-venus-copy'
                src={copy}
              ></img> */}
            </span>
            <span id="op-type">
              {/* patient type in same span */}
              {props.patient.survey_type === "repeat"
                ? "리필"
                : props.patient.survey_type}
              {/* last appointed doctor in same span*/}
              {/* {lastAppointedDoctor &&
          props.patient.status === "pending" &&
          props.patient.survey_type === "repeat" ? (
            <span id="op-last-doc">{lastAppointedDoctor} </span>
          ) : (
            ""
          )} */}
            </span>

            {/* <p>{props.patient.isPaid === "yes" ? "[결제완료]" : ""}</p> */}
            <div id="op-hospital">
              {props.patient.status !== "pending" ? (
                <span id="op-hospital-display">
                  {getDoctorInfo(props.patient.doctor).office}
                </span>
              ) : (
                ""
              )}
              <TimeSlotModal
                isOpen={isTimeSlotOpen}
                items={items}
                onClose={handleClose}
                onUpdate={handleUpdate}
                setIsTimeSlotOpen={setIsTimeSlotOpen}
              />
            </div>
            {props.patient.pstatus === "medi_prep" ? (
              <div id="op-pharma">
                {getPharmaInfo(props.patient.pharma).office}
              </div>
            ) : (
              <div id="op-pharma"></div>
            )}

            {/* 환자취소 */}
            <div className="op-actions">
              <div
                className="cancel-return-buttons"
                style={{
                  position:
                    props.patient.user_status !== "pending" ? "absolute" : "",
                  right: props.patient.user_status !== "pending" ? "60px" : "",
                }}
              >
                {props.patient.dstatus !== "cancel" &&
                  props.patient.status !== "pay" &&
                  props.patient.status !== "deli_done" && (
                    <div>
                      <a
                        id="op-cancel-button"
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (
                            window.confirm(
                              props.patient.name + "님 접수취소 하시겠습니까??"
                            )
                          ) {
                            try {
                              let chartInfo = {
                                id: props.patient.id,
                                status: "cancel",
                                dstatus: "cancel",
                                ignoreStatus: "no",
                              };
                              const res = await updateVenusChart(chartInfo);
                              if (res) {
                                let user = await getUser(
                                  `user#${props.patient.kid}`
                                );

                                if (user && user.charts) {
                                  let userCharts = JSON.parse(user.charts);
                                  // console.log("userCharts:", userCharts);
                                  let chart = userCharts.find(
                                    (chart) => chart.id === props.patient.id
                                  );
                                  chart.stat = "cancel";
                                  let userInfo = {
                                    id: user.id,
                                    charts: JSON.stringify(userCharts),
                                  };
                                  const uuser = await updateUser(userInfo);
                                  // console.log("updated user: ", uuser);
                                }
                                slack2ops(
                                  `[예약취소][운영][${user.name}][${user.kid}]`
                                );
                                alert("예약이 취소 되었습니다.");
                              }
                            } catch (error) {
                              alert(error);
                              //console.log(error);
                            }
                          }
                        }}
                      >
                        {/* <b>'{props.patient.name}'</b>님 접수취소 */}
                        <img
                          src={cancel}
                          style={{ width: "17px", height: "auto" }}
                        />
                      </a>
                    </div>
                  )}

                {props.patient.doctor_status === "pending" ||
                props.patient.doctor_status === "accept" ||
                props.patient.doctor_status === "phone" ||
                props.patient.doctor_status === "re_accept" ? (
                  <div>
                    <a
                      id="op-rewind-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenScheduleModal(true);
                      }}
                    >
                      <img
                        src={rewind}
                        style={{ width: "17px", height: "auto" }}
                      />
                    </a>
                    <ScheduleChanger
                      yeyakTime={yeyakTime}
                      setYeyakTime={setYeyakTime}
                      openScheduleModal={openScheduleModal}
                      setOpenScheduleModal={setOpenScheduleModal}
                      patient={props.patient}
                    ></ScheduleChanger>
                  </div>
                ) : (
                  <></>
                )}

                {props.patient.dstatus === "phone" ? (
                  <div
                    id="op-text-button"
                    style={{ color: "transparent", cursor: "default" }}
                  >
                    병원변경
                  </div>
                ) : (
                  <></>
                )}

                {props.patient.dstatus === "accept" ? (
                  <div>
                    <a
                      id="op-text-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenHospitalModal(true);
                      }}
                    >
                      병원변경
                    </a>
                    <HospitalChanger
                      openHospitalModal={openHospitalModal}
                      setOpenHospitalModal={setOpenHospitalModal}
                      doctor={doctor}
                      setDoctor={setDoctor}
                      patient={props.patient}
                    ></HospitalChanger>
                  </div>
                ) : (
                  <></>
                )}

                {props.patient.dstatus === "cancel" && (
                  <div>
                    <Button
                      id="op-revert-button"
                      onClick={async (e) => {
                        e.stopPropagation();
                        const patientDetails = {
                          id: props.patient.id,
                          status: "accept",
                          dstatus: "accept",
                        };
                        if (
                          window.confirm(
                            props.patient.name + "님 다시 접수 하시겠습니까?"
                          )
                        ) {
                          try {
                            const updatedPatient = await API.graphql({
                              query: mutations.updateMars,
                              authMode: "AMAZON_COGNITO_USER_POOLS",
                              variables: { input: patientDetails },
                            });
                            if (updatedPatient) {
                              slack2ops(
                                `[운영][${props.patient.name}] 접수취소 완료`
                              );
                              alert(props.patient.name + "님 접수취소 완료!");
                            }
                          } catch (error) {
                            alert(error);
                            //console.log(error);
                          }
                        }
                      }}
                    >
                      {/* <b>'{props.patient.name}'</b>님 다시 접수 */}
                      되돌리기
                    </Button>
                  </div>
                )}
              </div>
              <div className="operation-dropdown">
                {props.patient.user_status === "pending" ? (
                  <div>
                    <select
                      id="operation-select"
                      value={doctor}
                      onChange={(e) => {
                        setDoctor(e.target.value);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <option
                        value="eroon"
                        // selected={props.patient.doctor === "doctor2"}
                      >
                        이룬의원
                      </option>
                      <option
                        value="doctor77"
                        // selected={props.patient.doctor === "doctor77"}
                      >
                        마스의원(TEST)
                      </option>
                    </select>

                    {/* disable confirm alert */}
                    <Button
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (
                          window.confirm(
                            "정말로 병원선택을 완료 하시겠습니까2??"
                          )
                        ) {
                          const chartInfo = {
                            id: props.patient.id,
                            // ignoreStatus: "no",
                            user_status: "accept",
                            doctor_status: "accept",
                            doctor_id: doctor,
                          };

                          const updatedChart = await updateVenusChart(
                            chartInfo
                          );
                          if (updatedChart) {
                            slack2ops(
                              `[운영][${
                                props.patient.user_info.name
                              }] 병원 선택완료[${getDoctorInfo(doctor).office}]`
                            );
                            sendAnalytics({
                              type: "ops",
                              kid: props.patient.kid,
                              from: "operation",
                              event: `select.doctor.${doctor}`,
                              msg: `병원 ${doctor} 선택 완료`,
                            });
                            // alert(
                            //   `${props.patient.name}님 ${
                            //     getDoctorInfo(doctor).office
                            //   } 선택완료!`
                            // );
                          }
                        }
                      }}
                      // className="btn-sm btn-primary"
                      id="operation-hosp-button-venus"
                    >
                      확인
                    </Button>
                  </div>
                ) : (
                  <></>
                )}

                {props.patient.pstatus === "deli_prep" ||
                props.patient.pstatus === "deli_done" ? (
                  <div className="operation-delivery-buttons">
                    {/* <label>등기번호</label> */}
                    <input
                      value={deliveryNumber}
                      type="text"
                      onChange={(e) => {
                        setDeliveryNumber(e.target.value);
                      }}
                    ></input>
                    <Button
                      id="operation-deli-button"
                      onClick={() => {
                        if (deliveryNumber === "") {
                          alert("등기번호 미입력!");
                          return;
                        }
                        if (
                          window.confirm(
                            props.patient.name +
                              "님 등기번호 입력 완료: " +
                              deliveryNumber
                          )
                        ) {
                          handleDeliveryReady(props.patient.id, deliveryNumber);
                        }
                      }}
                    >
                      확인
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* {isDetail && ( */}
          <Collapse isOpen={isDetail}>
            <div className="operation-card-detail-body-venus">
              <div className="operation-patient-info-venus">
                <div className="operation-divider-venus">
                  <b>ID 정보</b>
                  <Button
                    id="operation-patient-delete-button-venus"
                    onClick={async () => {
                      const patientDetails = {
                        id: props.patient.id,
                      };

                      if (
                        window.confirm(
                          "!!!! 테스트 데이타만 지우세요 !!!!\n" +
                            props.patient.name +
                            "님 을 DB에서 삭제 하시겠습니까?"
                        )
                      ) {
                        try {
                          const deletedChart = await API.graphql({
                            query: mutations.deleteMars,
                            authMode: "AMAZON_COGNITO_USER_POOLS",
                            variables: { input: patientDetails },
                          });
                          if (deletedChart) {
                            // also delete charthistory from user.
                            //
                            let userCharts = [];
                            let user = await getUser(
                              `user#${props.patient.kid}`
                            );
                            if (user && user.charts) {
                              userCharts = JSON.parse(user.charts);
                              // console.log("userCharts: ", userCharts);

                              // remove the matching patient id from the history charts.
                              // console.log("props.patient.id: ", props.patient.id);
                              for (var i = 0; i < userCharts.length; i++) {
                                // console.log(userCharts[i].id);
                                if (userCharts[i].id == props.patient.id) {
                                  userCharts.splice(i, 1);
                                  break;
                                }
                              }

                              let userInfo = {
                                id: user.id,
                                charts: JSON.stringify(userCharts),
                              };
                              let updatedUser = await updateUser(userInfo);
                              // console.log("updatedUser: ", updatedUser);
                            }

                            sendAnalytics({
                              type: "ops",
                              kid: props.patient.kid,
                              from: "operation",
                              event: `delete.chart`,
                              msg: `${props.patient.name} 차트DB 삭제 완료`,
                            });
                            slack2ops(
                              `${props.patient.name}님 차트가 DB에서 삭제 되었습니다.`
                            );

                            if (props.patient.nesh_id) {
                              const deletedNesh = await API.graphql({
                                query: mutations.deleteMars,
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                variables: {
                                  input: { id: props.patient.nesh_id },
                                },
                              });
                              if (deletedNesh) {
                                slack2ops(
                                  `${props.patient.name}님 NESH도 DB에서 삭제 되었습니다.`
                                );
                              }
                            }

                            alert(props.patient.name + "님 삭제완료!");
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }}
                  >
                    <span>차트 삭제</span>
                  </Button>

                  <table>
                    <tbody>
                      <tr>
                        <td id="op-data-venus">KID: {props.patient.kid} </td>
                      </tr>
                      <tr>
                        <td id="op-data-venus">ChartID: {props.patient.id}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    id="operation-edit-patient-venus"
                    onClick={() => {
                      setOpenProfileEdit(true);
                    }}
                  >
                    기본정보 수정
                  </button>
                  {openProfileEdit ? (
                    <ProfileEditor
                      isOpen={openProfileEdit}
                      openProfileEdit={openProfileEdit}
                      setOpenProfileEdit={setOpenProfileEdit}
                      profileEditor={profileEditor}
                    ></ProfileEditor>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <div className="operation-address-venus">
                    <b>배송주소</b>
                    <div
                      id="op-data-venus"
                      style={{
                        marginTop: "15px",
                        width: "280px",
                      }}
                    >
                      {props.patient.user_info.addrs.items.length > 0 ? (
                        <>{props.patient.user_info.addrs.items}</>
                      ) : (
                        <>주소 미등록</>
                      )}
                    </div>
                    <button
                      id="operation-edit-patient-venus"
                      onClick={() => {
                        setOpenAddressEdit(true);
                      }}
                    >
                      주소정보 수정
                    </button>
                    <div className="operation-divider-venus"></div>
                  </div>
                  {openAddressEdit ? (
                    <AddressEditor
                      isOpen={openAddressEdit}
                      openAddressEdit={openAddressEdit}
                      setOpenAddressEdit={setOpenAddressEdit}
                      profileEditor={profileEditor}
                    ></AddressEditor>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {/* {props.patient.status === "deli_done" ? ( */}
                  {payResult ? (
                    <div className="operation-address-venus">
                      <b>결제정보</b>
                      <div id="op-data-venus" style={{ marginTop: "15px" }}>
                        {/* {getPayTime(payResult.PTIME)} */}
                        {payResult && payResult.PTIME ? (
                          <>
                            결제시간: {formatOperationPayTime(payResult.PTIME)}
                          </>
                        ) : (
                          <>결제시간 없음</>
                        )}
                        {payResult ? (
                          <div>
                            <b>
                              {payResult.AMNT
                                ? parseInt(payResult.AMNT).toLocaleString() +
                                  "원 결제완료"
                                : "결제금액 확인 필요"}
                            </b>{" "}
                            <br /> {payResult.CNAME} : {payResult.CNUM || "---"}
                          </div>
                        ) : (
                          <div>미결제</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* ) : (
                  <></>
                )} */}
                </div>
              </div>
              {/* borrowed from doc */}
              <div className="operation-pricing-table-venus">
                {/* {props.patient.doctor_status} */}
                <b>
                  {props.patient.doctor_status !== "done"
                    ? `VENUS 예상처방`
                    : `VENUS 처방`}
                </b>
                {props.patient.recommended_medicines &&
                props.patient.doctor_status !== "done" ? (
                  <table className="venus-med-table">
                    <tbody>
                      {props.patient.recommended_medicines.items.map(
                        (item, key) => {
                          return (
                            <tr key={key}>
                              <td style={{ minWidth: "150px" }}>
                                {item.medicine.product_name}
                              </td>
                              <td>{`${item.medicine.base * item.dosage}[${
                                item.medicine.unit
                              }]`}</td>
                              <td>
                                {`${item.medicine.retail_price.toLocaleString()}`}
                                원
                              </td>
                            </tr>
                          );
                        }
                      )}
                      {/* TODO: PRODUCT DATA */}
                      <tr>
                        <td>진료비</td>
                        <td></td>
                        <td>{"15,000원"}</td>
                      </tr>
                      <tr>
                        <td>배송비</td>
                        <td></td>
                        <td>{"2,900원"}</td>
                      </tr>
                      <tr>
                        <td style={{ marginLeft: "auto" }}>
                          총: {`total here`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <></>
                )}

                {props.patient.prescription &&
                props.patient.prescription.prescribed_medicine &&
                props.patient.doctor_status === "done" ? (
                  <table className="venus-med-table">
                    <tbody>
                      {props.patient.prescription.prescribed_medicine.items.map(
                        (item, key) => {
                          return (
                            <tr key={key}>
                              <td style={{ minWidth: "150px" }}>
                                {item.medicine.product_name}
                              </td>
                              <td>{`${item.medicine.base * item.dosage}[${
                                item.medicine.unit
                              }]`}</td>
                              <td>
                                {`${item.medicine.retail_price.toLocaleString()}`}
                                원
                              </td>
                            </tr>
                          );
                        }
                      )}
                      {/* TODO: PRODUCT DATA */}
                      <tr>
                        <td>진료비</td>
                        <td></td>
                        <td>{"15,000원"}</td>
                      </tr>
                      <tr>
                        <td>배송비</td>
                        <td></td>
                        <td>{"2,900원"}</td>
                      </tr>
                      <tr>
                        <td style={{ marginLeft: "auto" }}>
                          총: {`total here`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <></>
                )}
              </div>
              <div className="operation-message-content-venus">
                <div className="operation-extra-message-venus">
                  <b>추가메시지</b>
                  <br />
                  <section className="venus-msg">
                    {props.patient.user_msg ? (
                      <>{props.patient.user_msg}</>
                    ) : (
                      <>없음</>
                    )}
                  </section>
                </div>
                {/* <div className="operation-kakao-venus">
              <p id="op-title">카카오톡 보내기</p>
              <textarea
                rows={8}
                cols={40}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              <button
                onClick={async () => {
                  if (
                    !window.confirm(
                      props.patient.name +
                        "님에게 정말로 메시지를 보내시겠습니까?"
                    )
                  ) {
                    return;
                  }
                  try {
                    let users = [{ type: "appUserId", id: props.patient.kid }];
                    let res = await API.post("marsrest", "/message", {
                      body: {
                        users: users,
                        message: message,
                      },
                    });
                    if (res) {
                      slack2ops(
                        `[운영][${props.patient.name}] 카톡메시지 전송\n${message}`
                      );
                      setMessage("");
                      alert(res.data.taskId + " : " + res.data.status);
                    }
                  } catch (error) {
                    alert(error);
                  }
                }}
              >
                메세지 전송
              </button>
            </div> */}
                {/* <div>
              <button
                onClick={async () => {
                  if (
                    window.confirm(
                      `${name} 님에게 쉐딩 케어메시지를 정말로 보내시겠어요?`
                    )
                  ) {
                    let users = [{ type: "appUserId", id: props.patient.kid }];
                    let eventName =
                      "event_care_shedding" +
                      (getENV() !== "prod" ? "_dev" : "");
                    let msg = `탈모약 복용한지 2주 차, 혹시 머리가 더 빠지시는 느낌이 드나요? 탈모 약 복용 2개월까지는 자연스러운 현상이에요! 약한 모발이 빠지고, 강한 모발로 채워지는 과정이에요.\n\n자세한 내용은 ‘쉐딩  현상 더 알아보기’ 버튼을 눌러 확인해 주세요🙌🏻\n\n3개월 이후에도 나아지지 않는다면 재진 시 담당 의사에게 말씀해 주시면 ${props.patient.name}님께 맞는 방안을 제안해 주실 거예요👩🏻‍⚕️`;

                    let eventData = { msg: `${msg}`, name: name };
                    let res = await API.post("marsrest", "/kakao", {
                      body: { users, eventName, eventData },
                    });
                    if (res) {
                      alert("메시지를 성공적으로 보냈습니당~^_^");
                    }
                    slack2events(
                      `[케어메시지]${name}[${props.patient.kid}]님에게 쉐딩 케어메시지를 보냈습니다.`
                    );
                  }
                }}
              >
                케어메시지 보내기
              </button>
            </div> */}
              </div>
            </div>
          </Collapse>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
