import React, { useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API, Storage } from "aws-amplify";
import * as mutations from "../../graphql/mutations";

export default function CancelPopup(props) {
  const { openCancel, setOpenCancel, cancelReason, setCancelReason } = props;
  const toggle = () => setOpenCancel(!openCancel);
  const cancelRef = useRef();

  useEffect(() => {
    setCancelReason("");
  }, []);

  useEffect(() => {
    console.log(cancelReason);
  }, [cancelReason]);

  const modalStyle = {
    position: "relative",
    top: "25%",
    display: "flex",
    placeItems: "center",
    flexDirection: "column",
    borderRadius: "23.5px",
  };

  const buttonStyleInActive = {
    background: "#F7F7F7",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "23.5px",
    width: "400px",
    color: "black",
    margin: "auto",
    marginBottom: "10px",
  };

  const buttonStyleActive = {
    background: "#6A43B8",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "23.5px",
    width: "400px",
    color: "white",
    margin: "auto",
    marginBottom: "10px",
    fontWeight: 600,
  };

  const footerStyle = {
    display: "flex",
    flexDirection: "row",
    borderTop: " 0.5px solid #8C8C8C",
    marginTop: "25px",
    padding: 0,
    borderBottomLeftRadius: "23.5px",
    borderBottomRightRadius: "23.5px",
  };

  const cancelButton = {
    width: "100%",
    background: "#FFFFFF",
    color: "black",
    height: "55px",
    border: "none",
    borderBottomLeftRadius: "15.5px",
    borderRight: "0.5px solid #8C8C8C",
  };

  const submitButton = {
    width: "100%",
    background: "#FFFFFF",
    color: "black",
    height: "55px",
    border: "none",
    borderBottomRightRadius: "15.5px",
    filter: cancelReason === "" ? "opacity(0.2)" : "opacity(1.0)",
  };

  const textStyle = {
    fontSize: "15px",
    // fontWeight: 400
  };

  return (
    <Modal
      style={modalStyle}
      isOpen={openCancel}
      height="538px"
      toggle={toggle}
    >
      <ModalBody
        style={{
          textAlign: "center",
          fontSize: "20px",
          background: "#FFFFFF",
          borderRadius: "23.5px",
          marginBottom: "-15px",
        }}
      >
        취소 사유를 선택해주세요.
        <div style={{ marginTop: "26px" }}></div>
        <Button
          style={
            cancelReason === "cannot_reach"
              ? buttonStyleActive
              : buttonStyleInActive
          }
          onClick={() => {
            setCancelReason("cannot_reach");
          }}
        >
          <span style={textStyle}>통화 불가능</span>
        </Button>
        <Button
          style={
            cancelReason === "deny_prescription"
              ? buttonStyleActive
              : buttonStyleInActive
          }
          onClick={() => {
            setCancelReason("deny_prescription");
          }}
        >
          <span style={textStyle}> 처방 거부</span>
        </Button>
        {/* <Button
          style={
            cancelReason === "other" ? buttonStyleActive : buttonStyleInActive
          }
          onClick={() => {
            setCancelReason("other");
          }}
        >
          <span style={textStyle}> 기타</span>
        </Button> */}
      </ModalBody>

      <ModalFooter style={footerStyle}>
        <button
          // id="hover-cancel-button"
          style={cancelButton}
          onClick={() => {
            setCancelReason("");
            setOpenCancel(false);
          }}
        >
          취소
        </button>
        <button
          // id="hover-submit-button"
          style={submitButton}
          onClick={async () => {
            const patientDetails = {
              id: props.patient.id,
              ignoreStatus: "no",
              dstatus: "cancel",
              status: cancelReason,
            };
            try {
              const updatedPatient = await API.graphql({
                query: mutations.updateMars,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { input: patientDetails },
              });
              if (updatedPatient) {
                // slack2ops(
                //   `[닥터][${props.patient.name}] 진료취소[${cancelReason}]`
                // );
                alert(
                  props.patient.name + "님 " + cancelReason + " 처리 완료!"
                );
              }
            } catch (error) {
              console.error(error);
            }
          }}
          disabled={cancelReason === ""}
        >
          확인
        </button>
      </ModalFooter>
    </Modal>
  );
}
