import MarsNavbar from "components/Navbars/MarsNavbar";
import MarsNavbarLanding from "components/Navbars/MarsNavbarLanding";
import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { slack } from "utils/util";
import StatusButton from "views/components/StatusButton";
import FAQOverview from "./FAQOverview";

import Faq from "./faq";
import content from "./faq.json";

function FaqPage() {
  useEffect(() => {
    slack("[FAQ] faq page 방문!");
  }, []);

  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const clickDone = () => {
    window.location.href = "kakaotalk://inappbrowser/close";
  };

  const buttonStyle = {
    width: "100%",
    borderRadius: "4px",
    background: "black",
    color: "white",
    margin: 0,
    height: "100%",
    fontSize: "18px",
    height: "53px",
    display: "grid",
    placeItems: "center",
    fontWeight: "200",
    lineHeight: "18px",
  };

  return (
    <>
      <div className="scrollable" style={{overflowX:'hidden', width: '100vw'}}>
        <div>
          <MarsNavbarWithLine />
        </div>
        <Container
          style={{
            paddingBottom: "80px",
            backgroundColor: "#F7F7F7",
            margin: 0,
            padding: '50px 0 0 0'
          }}
        >
          <FAQOverview></FAQOverview>
          <Row className="justify-content-center" style={{padding: '26px'}}>
            <Col xs="12" md="5">
              <div style={{ fontSize: "24px", paddingBottom: "10px", marginTop:'20px' }}>
                <b>FAQ</b>
              </div>
              <div
                style={{
                  marginTop: "-8px",
                  paddingBottom: "8px",
                  marginBottom: "8px",
                }}
              >
                자주묻는 질문
              </div>

              {content.map((faq) => {
                return (
                  <Faq
                    key={faq.number}
                    question={faq.question}
                    answer={faq.answer}
                    number={faq.number}
                  />
                );
              })}

              <div
                style={{
                  lineHeight: "18px",
                  fontSize: "13px",
                  fontWeight: 300,
                  marginTop: "25px",
                  marginBottom: "40px",
                }}
              >
                *이외에 서비스 이용에 문제가 있는 부분은 contact@marsnvenus.net
                으로 메일을 주시거나, 카카오톡 ‘맨프롬마스' 채널에서 상담직원
                연결로 문의 주시면 빠르게 처리해 드릴게요!
              </div>
            </Col>
          </Row>
          {/* 닫기 버튼 */}
          <div id="faq-close-container">
            {/* <StatusButton style={buttonStyle} clickDone={clickDone}> */}
            <button
            id='faq-close'
              style={{ fontWeight: "400" }}
              onClick={() => {
                clickDone();
              }}
            >
              닫기
            </button>
            {/* </StatusButton> */}
          </div>
        </Container>
      </div>
    </>
  );
}

export default FaqPage;
