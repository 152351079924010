import Picker from "rmc-picker";

export const getAvailTimes = () => {
  let today = new Date();
  let day = today.getDay();
  let hour = today.getHours();
  let min = today.getMinutes();
  if (min > 30) {
    hour = hour + 1;
  }
  let month = today.getMonth();
  let date = today.getDate();

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const monday = new Date(tomorrow);
  monday.setDate(monday.getDate() + 1);

  let yoil = ["일", "월", "화", "수", "목", "금", "토"];
  let slot = [];
  let counter = 1;


  // Mon ~ Thursday
  if (day > 0 && day < 5) {
    let h = 9;
    if (hour < 22) {
      slot.push(
        <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate()} key={counter++}>
          [ {month + 1 + "월" + date + "일 " + yoil[day] + "요일"} ]
        </Picker.Item>
      );
      if (hour < 9) {
        hour = 9;
      }
      for (let t = hour; t < 18; t++) {
        slot.push(
          <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate() + "/" + yoil[today.getDay()] + "/" + t} key={counter++}>
            {t}:00 - {t + 1}:00
          </Picker.Item>
        );
      }
    }

    // add tomorrow's time
    slot.push(
      <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate()} key={counter++}>
        [{" "}
        {tomorrow.getMonth() +
          1 +
          "월" +
          tomorrow.getDate() +
          "일 " +
          yoil[tomorrow.getDay()] +
          "요일"}{" "}
        ]
      </Picker.Item>
    );

    for (let t = 9; t < 18; t++) {
      slot.push(
        <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate() + "/" + yoil[tomorrow.getDay()] + "/" + t} key={counter++}>
          {t}:00 - {t + 1}:00
        </Picker.Item>
      );
    }


  }

  // Friday
  if (day === 5) {
    //Adding Fridy time slot
    let h = 9;
    if (hour < 22) {
      slot.push(
        <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate()} key={counter++}>
          [ {month + 1 + "월" + date + "일 " + yoil[day] + "요일"} ]
        </Picker.Item>
      );
      if (hour < 9) {
        hour = 9;
      }
      for (let t = hour; t < 18; t++) {
        slot.push(
          <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate() + "/" + yoil[today.getDay()] + "/" + t} key={counter++}>
            {t}:00 - {t + 1}:00
          </Picker.Item>
        );
      }
    }
    // add Saturday time slot
    slot.push(
      <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate()} key={counter++}>
        [{" "}
        {tomorrow.getMonth() +
          1 +
          "월" +
          tomorrow.getDate() +
          "일 " +
          yoil[tomorrow.getDay()] +
          "요일"}{" "}
        ]
      </Picker.Item>
    );

    for (let t = 9; t < 14; t++) {
      slot.push(
        <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate() + "/" + yoil[tomorrow.getDay()] + "/" + t} key={counter++}>
          {t}:00 - {t + 1}:00
        </Picker.Item>
      );
    }

  } // end of Friday

  // Saturday
  if (day === 6) {
    //Adding Saturday time slot
    let h = 9;
    if (hour < 22) {
      slot.push(
        <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate()} key={counter++}>
          [ {month + 1 + "월" + date + "일 " + yoil[day] + "요일"} ]
        </Picker.Item>
      );
      if (hour < 9) {
        hour = 9;
      }
      for (let t = hour; t < 14; t++) {
        slot.push(
          <Picker.Item value={today.getMonth() + 1 + "/" + today.getDate() + "/" + yoil[today.getDay()] + "/" + t} key={counter++}>
            {t}:00 - {t + 1}:00
          </Picker.Item>
        );
      }
    }

    slot.push(
      <Picker.Item value={monday.getMonth() + 1 + "/" + monday.getDate()} key={counter++}>
        [{" "}
        {monday.getMonth() +
          1 +
          "월" +
          monday.getDate() +
          "일 " +
          yoil[monday.getDay()] +
          "요일"}{" "}
        ]
      </Picker.Item>
    );
    //adding monday time
    for (let t = 9; t < 18; t++) {
      slot.push(
        <Picker.Item value={monday.getMonth() + 1 + "/" + monday.getDate() + "/" + yoil[monday.getDay()] + "/" + t} key={counter++}>
          {t}:00 - {t + 1}:00
        </Picker.Item>
      );
    }
    
  } // End of Saturday

  // Sunday - Monday 9 to 22
  if (day === 0) {

    // sunday starts from 3pm
    let h = 15;



    // Today is Sunday jsut add Monday time slot
    slot.push(
      <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate()} key={counter++}>
        [{" "}
        {tomorrow.getMonth() +
          1 +
          "월" +
          tomorrow.getDate() +
          "일 " +
          yoil[tomorrow.getDay()] +
          "요일"}{" "}
        ]
      </Picker.Item>
    );

    for (let t = 9; t < 18; t++) {
      slot.push(
        <Picker.Item value={tomorrow.getMonth() + 1 + "/" + tomorrow.getDate() + "/" + yoil[tomorrow.getDay()] + "/" + t} key={counter++}>
          {t}:00 - {t + 1}:00
        </Picker.Item>
      );
    }
  }
  return slot;
};
export default getAvailTimes;