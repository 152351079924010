import React, { useState, useEffect } from "react";

import Logo from "../../../../assets/img/mars_logo_black.png";

export default function DNALandingHeader() {
  const logoStyle = {
    width: "63px",
    height: "21px",
    marginRight: "auto",
    marginLeft: "22px",
  };

  const textStyle = {
    marginRight: "auto",
    marginTop: '7px',
    paddingRight: 'auto',
    width: '100%',
    marginLeft:'auto',
    fontWeight: '300',
    fontSize:'11px',
  };

  return (
    <div className="dna-event-header">
      <img style={logoStyle} src={Logo} />
      <p style={textStyle}>남성탈모 전문 비대면진료 서비스</p>
    </div>
  );
}
