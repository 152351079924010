import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "reactstrap";
import $ from "jquery";

// images
import DownArrow from "../dna-event-assets/DownArrow.png";
import UpArrow from "../dna-event-assets/UpArrow.png";
import DNATimeLine from "../dna-event-assets/DNATimeline.png";

export default function SixthQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [buttonArrow, setButtonArrow] = useState(DownArrow);
  const [titleStyle, setTitleStyle] = useState();
  const titleRef = useRef();

  const itemToView = document.getElementById("collapse-content");

  const toggle = () => {
    setCollapse(!collapse);
    setTitleStyle(titleRef.current.style.fontWeight);
    if (buttonArrow === DownArrow) {
      setButtonArrow(UpArrow);
    } else {
      setButtonArrow(DownArrow);
    }
  };

  useEffect(() => {
    if (titleStyle === "400") {
      titleRef.current.style.fontWeight = "600";
    } else {
      titleRef.current.style.fontWeight = "400";
    }

    if (collapse) {
      setTimeout(() => {
        itemToView.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 260);
    }
  }, [collapse]);

  const imgStyle = {
    marginLeft: "-27px",
    marginBottom: "15px",
  };

  return (
    <div className="dna-question-wrapper">
      <button id="dna-toggle-button" onClick={toggle}>
        <p ref={titleRef} id="dna-main-title">
          유전자검사는 얼마나 걸리나요?
        </p>
        <img id="dna-button-image" src={buttonArrow} />
      </button>
      <Collapse id="dna-collapse" isOpen={collapse}>
        <div id="collapse-content">
          <p
            style={{ marginBottom: "5px", paddingBottom: "15px" }}
            id="dna-text-list-body"
          >
            상품 주문부 검사 결과지를 받아보시는데는 평균12일 정도의 시간이
            소요됩니다.
          </p>
          <img src={DNATimeLine} style={imgStyle} />
        </div>
      </Collapse>
    </div>
  );
}
