export const PRODUCTS = {
  "": {
    id: "없음",
    name: "없음",
    unit: "",
    base: 0,
  },

  propecia: {
    id: "propecia",
    name: "프로페시아 1mg",
    company: "",
    unit: "정",
    base: 28,
    price: [0, 52900, 105800, 156900, 209200, 261500, 313800],
    tag: "피나스테리드",
    prediscount: 52300,
    sub: "프로페시아",
  },
  aloscan: {
    id: "aloscan",
    name: "알로스칸 1mg",
    company: "경동",
    unit: "정",
    base: 30,
    price: [0, 18800, 37600, 51400, 68600, 85700, 102800],
    tag: "피나스테리드",
    prediscount: 18800,
    sub: "알로스칸",
  },
  finaon: {
    id: "finaon",
    name: "피나온 1mg",
    unit: "정",
    base: 30,
    price: [0, 12900, 25800, 36000, 48000, 60000, 72000],
    tag: "피나스테리드",
    prediscount: 12000,
    sub: "피나온",
  },
  avodart: {
    id: "avodart",
    name: "아보다트 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 26900, 53800, 73900, 98600, 123200, 147800],
    tag: "두타스테리드",
    prediscount: 26900,
    sub: "아보다트 연질캡슐",
  },
  damodart: {
    id: "damodart",
    name: "다모다트 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 20000, 40000, 58000, 77400, 96700, 116000],
    tag: "두타스테리드",
    prediscount: 20000,
    sub: "다모다트 연질캡슐",
  },
  dutaon: {
    id: "dutaon",
    name: "두타윈 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 15900, 31800, 46700, 62300, 77900, 93400],
    tag: "두타스테리드",
    prediscount: 15900,
    sub: "두타윈 연질캡슐",
  },
  minox: {
    id: "minox",
    name: "마이녹실 5%",
    unit: "ml",
    base: 60,
    price: [0, 21000, 42000, 49000, 0, 0, 98000],
    tag: "미녹시딜",
    prediscount: 21000,
    sub: "마이녹실",
  },
  rogaine: {
    id: "rogaine",
    name: "로게인폼 5% 60g",
    unit: "캔",
    base: 1,
    price: [0, 25900, 51800, 72900, 97200, 121500, 145800],
    tag: "미녹시딜",
    prediscount: 25900,
    sub: "로게인폼",
  },
  capillus: {
    id: "capillus",
    name: "카필러스 캡슐",
    unit: "정",
    base: 90,
    price: [0, 30000, 60000, 90000, 120000, 150000, 180000],
    tag: "판토텐산칼슘",
    prediscount: 30000,
    alt: "카필러스캡슐 (탈모영양제)",
    sub: "카필러스",
  },
  biotin: {
    id: "biotin",
    name: "비오틴 젤리",
    unit: "구미",
    base: 60,
    price: 12900,
    tag: "비오틴",
    prediscount: 15610,
    alt: "비오틴 젤리 (60구미)",
    sub: "비오틴",
    temp: "biotin",
  },
  neshsoy: {
    id: "neshsoy",
    name: "소이바이옴 샴푸",
    unit: "ml",
    base: 480,
    price: 17900,
    tag: "소이바이옴",
    prediscount: 26000,
    alt: "nesh 소이바이옴 샴푸",
    sub: "소이바이옴",
    temp: "neshsoy",
  },
  neshmint: {
    id: "neshsoy",
    name: "민트바이옴 샴푸",
    unit: "ml",
    base: 480,
    price: 17900,
    tag: "소이바이옴",
    prediscount: 26000,
    alt: "nesh 소이바이옴 샴푸 ",
    sub: "소이바이옴",
    temp: "neshmint",
  },
};

export const PRODUCTS_OLD = {
  "": {
    id: "없음",
    name: "없음",
    unit: "",
    base: 0,
  },

  propecia: {
    id: "propecia",
    name: "프로페시아 1mg",
    company: "",
    unit: "정",
    base: 28,
    price: [0, 55000, 110000, 160000],
  },
  aloscan: {
    id: "aloscan",
    name: "알로스칸 1mg",
    company: "경동",
    unit: "정",
    base: 30,
    price: [0, 23300, 46600, 55490],
  },
  finaon: {
    id: "finaon",
    name: "피나온 1mg",
    unit: "정",
    base: 30,
    price: [0, 14900, 29800, 39900],
  },
  avodart: {
    id: "avodart",
    name: "아보다트 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 31000, 62000, 78800],
  },
  damodart: {
    id: "damodart",
    name: "다모다트 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 26000, 52000, 63600],
  },
  dutaon: {
    id: "dutaon",
    name: "두타온 연질캡슐 0.5mg",
    unit: "정",
    base: 30,
    price: [0, 19600, 39200, 49800],
  },
  minox: {
    id: "minox",
    name: "마이녹실 5%",
    unit: "ml",
    base: 60,
    price: [0, 22000, 44000, 50000],
  },
  rogaine: {
    id: "rogaine",
    name: "로게인폼 5% 60g",
    unit: "캔",
    base: 1,
    price: [0, 27000, 54000, 75000],
  },
  capillus: {
    id: "capillus",
    name: "카필러스 캡슐",
    unit: "정",
    base: 90,
    price: [0, 30000, 60000, 90000],
  },
  neshsoy: {
    id: "neshsoy",
    name: "소이바이옴 샴푸",
    unit: "ml",
    base: 480,
    price: 17900,
  },
  neshmint: {
    id: "neshmint",
    name: "민트바이옴 샴푸",
    unit: "ml",
    base: 480,
    price: 17900,
  },
};
