import React, { useState, useEffect } from "react";
import PhotoChartCard from "./OperationCardNew";

// img
import searchIcon from "../pharma2/Vector.png";

// css
import "./styling/viewerAcceptStyles.css";

export default function ChartViewerCancelNew(props) {
  const [search, setSearch] = useState("");
  const [doctorBatch, setDoctorBatch] = useState();
  const [totalBatch, setTotalBatch] = useState(0);

  // client side search
  const checkSearch = (patient) => {
    if (
      JSON.stringify(patient.name).match(search) ||
      JSON.stringify(patient.phone).match(search) ||
      JSON.stringify(patient.ssn).match(search) ||
      JSON.stringify(patient.survey_type).match(search)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="viewer-accept-container-venus">
      <div className="viewer-accept-header-venus">
        <div className="operation-search-venus">
          <span style={{ width: "165px" }}>전체 취소건</span>
          <div>
            <img
              src={searchIcon}
              style={{
                width: "15px",
                position: "absolute",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              alt="search-icon-pharma"
            />
            <input
              type="text"
              value={search}
              placeholder="검색"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="operation-table-header-container-venus">
        <table className="operation-table-header-venus">
          <th id="op-name">이름 (연락처)</th>
          <th id="op-time">희망진료시간</th>
          <th id="op-ssn">주민번호</th>
          <th id="op-type">환자타입</th>
          <th id="op-hospital">병원</th>
        </table>
      </div>
      <div className="viewer-accept-content-container-venus">
        {props.charts.map((c) => {
          if (c.dstatus === "cancel") {
            // console.log(c.ans)
            if (checkSearch(c)) {
              return <PhotoChartCard key={c.id} patient={c} />;
            }
          }
        })}
        <div
          style={{
            paddingLeft: "15px",
            height: "auto",
            lineHeight: "18px",
            fontWeight: 200,
            letterSpacing: "3px",
            whiteSpace: "pre-wrap",
            fontFamily: "aria",
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
            marginTop: "50px",
          }}
        >
          <div>{"  __( o)>"}</div>
          <div>{" <_.  )"}</div>
          <div style={{ lineHeight: 0 }}>{"---------------"}</div>
        </div>
      </div>
    </div>
  );
}
