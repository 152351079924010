/*eslint-disable*/
import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { slack } from "utils/util";

// core components

function LandingHeader(props) {

  return (
    <>
      <div className="landing4 landing4-background">
        <Row className="justify-content-center">
          <Col md="5" xs="12">

            <div className="left heading2">
              남성형 탈모,
              <br />
              <b style={{ color: "#FA5724", fontWeight: "600" }}>통화진료</b>에서
              <br />
              <b style={{ color: "#FA5724", fontWeight: "600" }}>약 배송</b>까지 한번에!
            </div>

          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="6" xs="12">
            <Row className="justify-content-center">
              <Col md="6" xs="12">
                <div
                  style={{
                    width: "100%", maxWidth: "420px"
                  }}
                >
                  <img
                    alt="..."
                    src={require("assets/img/landing4-header.png")}
                  ></img>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* 카카오로 시작하기 버튼 */}
        <Row className="justify-content-center">
          <Col md={4}>
            {/* 카카오 시작하기 버튼 */}
            <div
              className="kakao-btn"
              onClick={() => {
                if (props.isIndex) {
                  slack("index start button clicked");
                  alert("서비스 준비 중  입니다.")
                  return;
                }
                slack("[landing4/what] 시작버튼 클릭");
                window.Kakao.Channel.addChannel({
                  channelPublicId: "_fMNCb",
                });
              }}
            >
              <img alt="..." src={require("assets/img/kakao_logo.png")} style={{ width: "20px", marginRight: "8px" }}></img>
              카카오로 시작하기
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LandingHeader;
