import { PRODUCTS, PRODUCTS_OLD } from "./products";

/**
 *
 * @param chart object
 * @returns <table> user pricing history information in order </table>
 * requires separate table th tr td styling
 */
export const GetPaymentHistory = (chart) => {
  try {
    const data = chart.chart;
    var cutLine = isOldPrice(chart.yeyak_time);
    var pricingTable = cutLine ? PRODUCTS_OLD : PRODUCTS;
    // var delivery = isOldDelivery ? "0" : "2900";
    var totalPrice = calcPrice5(
      data.pill,
      data.minox,
      data.pillMonth,
      data.minoxMonth,
      data.shamp,
      data.capil,
      data.capilMonth,
      data.yeyak_time
    );

    if (!data.pillMonth) {
      return <div></div>
    }

    // 리필유저 미녹스 미선택시 '아니요' 라고 뜨내요
    if (data && data.status === "deli_done") {
      return (
        <table>
          {data.pill &&
            data.pill !== "" &&
            data.pill !== "아니요" &&
            data.pill !== null ? (
            <tr>
              <td>{pricingTable[data.pill].name}</td>
              <td>{Number(data.pillMonth * pricingTable[data.pill].base)}정</td>
              <td>{totalPrice.pillPrice.toLocaleString()}원</td>
            </tr>
          ) : (
            ""
          )}

          {data.minox && data.minox !== "" && data.minox !== "아니요" ? (
            <tr>
              <td>{pricingTable[data.minox].name}</td>
              <td>
                {Number(data.minoxMonth * pricingTable[data.minox].base)}
                {pricingTable[data.minox].unit}
              </td>
              <td>{totalPrice.minoxPrice.toLocaleString()}원</td>
            </tr>
          ) : (
            ""
          )}
          {data.shamp && data.shamp !== "" && data.shamp !== "아니요" ? (
            <tr>
              <td>{pricingTable[data.shamp].name}</td>
              <td>
                {pricingTable[data.shamp].base}
                {pricingTable[data.shamp].unit}
              </td>
              <td>{totalPrice.shampPrice.toLocaleString()}원</td>
            </tr>
          ) : (
            ""
          )}
          <tr>
            <td>진료비</td>
            <td>{"10,000"}원</td>
          </tr>
          <tr>
            <td>배송비</td>
            <td>{totalPrice.shippingCost.toLocaleString()}원</td>
          </tr>
          <tr>
            <td></td>
            <td className="purple400" style={{ marginTop: "5px" }}>
              총 {totalPrice.total.toLocaleString()}원
            </td>
          </tr>
        </table>
      );
    } else {
      <div>데이터를 불러올 수 없습니다.</div>;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} time (yeyak_time)
 * @returns boolean (to use or not to use old PRODUCTS info)
 */
export const isOldPrice = (time) => {
  try {
    // isOldPrice flag is set to true when the yeyak_time is older than November 1st, 2022
    //
    const NOVEMBER_FIRST_2022 = new Date('2022-11-01T00:00:00z');
    let yeyak_time = new Date(time);

    if (yeyak_time < NOVEMBER_FIRST_2022) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} time (yeyak_time)
 * @returns boolean (to use old delivery price or new)
 * 0원 vs 2,900원
 */
export const isOldDelivery = (time) => {
  try {
    // isOldDelivery flag is true when the yeyak_time is older than September 15, 2022
    //
    const SEPTEMBER_15_2022 = new Date('2022-09-15T00:00:00z');
    let yeyak_time = new Date(time);

    if (yeyak_time < SEPTEMBER_15_2022) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * MAGIC
 * @param {*} pill
 * @param {*} minox
 * @param {*} pillMonth
 * @param {*} minoxMonth
 * @param {*} shamp
 * @param {*} capil
 * @param {*} capilMonth
 * @param {*} yeyak_time
 *
 * @returns total pricing obj {pill, minox, shamp, etc}
 * @Requires yeyak_time
 */
export function calcPrice5(
  pill,
  minox,
  pillMonth,
  minoxMonth,
  shamp,
  capil,
  capilMonth,
  yeyak_time
) {
  try {
    var cutLine = isOldPrice(yeyak_time);
    var pricingTable = cutLine ? PRODUCTS_OLD : PRODUCTS;

    let pillPrice = 0;
    let minoxPrice = 0;
    let capilPrice = 0;
    let total = 0;
    let medicalFee = 10000; // 진료비 만원
    let shippingCost = isOldDelivery(yeyak_time) ? 0 : 2900; 
    let pillCount = 30;
    let capilCount = 30;
    let minoxCount = 1;
    let shampPrice = 0;
    if (shamp) {
      shampPrice = pricingTable.neshmint.price;
    }
    if (capil) {
      // capilPrice = price.capillus.cap[capilMonth];
      capilPrice = pricingTable.capillus.price[capilMonth];
    }

    if (!pill || pill === "") {
      pillPrice = 0;
    }
    if (pill === "프로페시아" || pill === pricingTable.propecia.id) {
      // pillPrice = price.propecia.origin[pillMonth];
      pillPrice = pricingTable.propecia.price[pillMonth];
      pillCount = 28;
    }
    if (
      pill === "알로스칸" ||
      pill === "경동 알로스칸" ||
      pill === pricingTable.aloscan.id
    ) {
      // pillPrice = price.propecia.copy[pillMonth];
      pillPrice = pricingTable.aloscan.price[pillMonth];
    }
    if (
      pill === "피나온정" ||
      pill === "피나온" ||
      pill === pricingTable.finaon.id
    ) {
      // pillPrice = price.propecia.finaon[pillMonth];
      pillPrice = pricingTable.finaon.price[pillMonth];
    }

    if (pill === "아보다트" || pill === pricingTable.avodart.id) {
      // pillPrice = price.avodart.origin[pillMonth];
      pillPrice = pricingTable.avodart.price[pillMonth];
    }
    if (
      pill === "아보다트 카피약" ||
      pill === "다모다트" ||
      pill === pricingTable.damodart.id
    ) {
      // pillPrice = price.avodart.copy[pillMonth];
      pillPrice = pricingTable.damodart.price[pillMonth];
    }
    if (
      pill === "두타온" ||
      pill === "라마파온 두타온" ||
      pill === pricingTable.dutaon.id
    ) {
      // pillPrice = price.avodart.dutaon[pillMonth];
      pillPrice = pricingTable.dutaon.price[pillMonth];
    }

    if (pill === "카필러스 캡슐" || pill === pricingTable.capillus.id) {
      // pillPrice = price.capillus.cap[pillMonth];
      pillPrice = pricingTable.capillus.price[pillMonth];
    }

    if (minox === "0" || minox === "" || minox === "아니요") {
      minoxPrice = 0;
    }
    if (
      minox === "현대 마이녹실 5%" ||
      minox === "현대 마이녹실" ||
      minox === pricingTable.minox.id
    ) {
      // minoxPrice = price.minox.mynox[minoxMonth];
      minoxPrice = pricingTable.minox.price[minoxMonth];
    }
    if (
      minox === "미녹시딜: 로게인폼" ||
      minox === "존슨앤존슨 로게인폼" ||
      minox === "로게인폼" ||
      minox === pricingTable.rogaine.id
    ) {
      // minoxPrice = price.minox.rogaine[minoxMonth];
      minoxPrice = pricingTable.rogaine.price[minoxMonth];
    }

    pillCount = pillCount * pillMonth;
    minoxCount = minoxCount * minoxMonth;
    total =
      pillPrice +
      minoxPrice +
      medicalFee +
      shippingCost +
      shampPrice +
      capilPrice;

    let result = {
      pillPrice,
      minoxPrice,
      total,
      pillCount,
      minoxCount,
      shampPrice,
      capilPrice,
      capilMonth,
      shippingCost,
    };
    return result;
  } catch (error) {
    console.log(error);
  }
}
