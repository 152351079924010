// import amplify authenticator ui
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth, graphqlOperation } from "aws-amplify";
// core components
import MarsNavbar from "components/Navbars/MarsNavbar.js";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import {
  Alert,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap";
import { getAllChartsWithUserInfo } from "utils/data";
import { updateChart, getChartByID } from "utils/data";
import { getAllCharts } from "utils/data";
import { getDoctorInfo } from "utils/util";
import { parseDateString, slack } from "utils/util";
import MarsLoaderBasic from "views/components/MarsLoaderBasic";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import PatientCardNew from "./PatientCardNew";

// images
import logo from "../../assets/img/mars_logo_black.png";
// nav icons
import form from "./doctor-assets/form.png";
import stetho from "./doctor-assets/stetho.png";
import done from "./doctor-assets/done.png";
import cancel from "./doctor-assets/cancel.png";
import searchIcon from "../pharma2/Vector.png";

// css
import "./doctorStyles.css";
import "./doctorTableStyles.css";
import { getUser } from "utils/data";

function DoctorPage() {
  const [iconPills, setIconPills] = useState("1");
  const [patients, setPatients] = useState([]);
  const [pName, setPName] = useState("");
  const [acceptAlert, setAcceptAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doctor, setDoctor] = useState("");
  const history = useHistory();
  const [acceptPatients, setAcceptPatients] = useState([]);
  const [toggle, setToggle] = useState(true); // navigation toggle

  const [search, setSearch] = useState(""); // filter out by search keyword
  const [currentPatients, setCurrentPatients] = useState();
  const [prevPatients, setPrevPatients] = useState();
  const patientRef = useRef(currentPatients);

  const updateLocalPatient = (patient) => {
    setAcceptPatients((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...patient,
            name: obj.name,
            ssn: obj.ssn,
            phone: obj.phone,
            addr: obj.addr,
            addr2: obj.addr2,
            zip: obj.zip,
          };
        }
        return obj;
      })
    );

    setPatients((current) =>
      current.map((obj) => {
        if (obj.id === patient.id) {
          return {
            ...patient,
            name: obj.name,
            ssn: obj.ssn,
            phone: obj.phone,
            addr: obj.addr,
            addr2: obj.addr2,
            zip: obj.zip,
            // ...obj,
            // status: patient.status,
            // dstatus: patient.dstatus,
            // pstatus: patient.pstatus
          };
        }
        return obj;
      })
    );
    // setPatients((current) => {
    //   if (current.find(o => o.id === patient.id)) {
    //     console.log("found the id update it!");
    //     current.map((obj) => {
    //       if (obj.id === patient.id) {
    //         return {
    //           ...patient,
    //           name: obj.name,
    //           ssn: obj.ssn,
    //           phone: obj.phone,
    //         };
    //       }
    //       return obj;
    //     })
    //   } else {
    //     console.log("this is a new object insert one")
    //   }
    // }
    // );

    // setAcceptPatients((current) => {
    //   if (current.find(o => o.id === patient.id)) {
    //     console.log("found the id update it!");
    //     current.map((obj) => {
    //       if (obj.id === patient.id) {
    //         return {
    //           ...patient,
    //           name: obj.name,
    //           ssn: obj.ssn,
    //           phone: obj.phone,
    //         };
    //       }
    //       return obj;
    //     })
    //   } else {
    //     console.log("this is a new accept patient object insert one")
    //   }
    // }
    // );

    // console.log("it's a new item!");
    // let kid = patient.kid;
    // let uid = `user#${kid}`;
    // console.log("uid: ", uid);
    // let user = getUser(uid);
    // console.log("user", user);
    // if (user) {
    //   patient.name = user.name;
    //   patient.ssn = user.ssn;
    //   patient.phone = user.phone;
    // }

    // setAcceptPatients(acceptPatients => {
    //   return [...acceptPatients, patient];
    // });

    // setPatients(patients => {
    //   return [...patients, patient];
    // });
  };

  const insertLocalPatient = async (patient) => {
    let user = await getUser(`user#${patient.kid}`);
    if (user) {
      patient.name = user.name;
      patient.ssn = user.ssn;
      patient.phone = user.phone;
      patient.addr = user.addr;
      patient.addr2 = user.addr2;
      patient.zip = user.zip;
    }

    setAcceptPatients((current) => [patient, ...current]);
    setPatients((current) => [...current, patient]);
  };

  const useNotification = (title, options) => {
    if (!("Notification" in window)) {
      return;
    }

    const fireNotif = () => {
      /* 권한 요청 부분 */
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            /* 권한을 요청받고 nofi를 생성해주는 부분 */
            new Notification(title, options);
          } else {
            return;
          }
        });
      } else {
        /* 권한이 있을때 바로 noti 생성해주는 부분 */
        new Notification(title, options);
      }
    };
    return fireNotif;
  };

  const triggerNotif = useNotification("[MARS 접수확인 요청]", {
    body: "희망진료 시간이 가까운 환자가 있습니다. ‘접수확인’ 부탁 드립니다!",
  });

  // patient information based on assigned doctor
  // returns chart
  async function getData(doc) {
    setIsLoading(true);

    let filter = {
      doctor: {
        eq: doc, // filter priority = 1
      },
    };

    const charts = await getAllChartsWithUserInfo("DESC", filter, 400);
    setPatients(charts);

    // sort patients by yeyak time for accept view
    let ps = [...charts];
    ps.sort((a, b) => new Date(a.yeyak_time) - new Date(b.yeyak_time));
    setAcceptPatients(ps);
    // console.log("accept patients: ", acceptPatients);
    setIsLoading(false);
  }

  useEffect(() => {
    patientRef.current = currentPatients;
    setPrevPatients(patientRef.current);
  }, [calcPatients("accept")]);

  useEffect(() => {
    // calc current patients
    setCurrentPatients(calcPatients("accept"));

    let doc = "";
    const asyncWrapper = async () => {
      let user = await Auth.currentUserInfo();
      // console.log("user:", user);
      if (
        ["doctor1", "doctor2", "doctor77", "gaonsamsung"].indexOf(
          user.username
        ) === -1
      ) {
        alert("의사로 로그인 해주세요!");
        let so = await Auth.signOut();
        return;
      }
      // set the doctor
      doc = user.username;
      setDoctor(doc);

      // subscription for onUpdateMars
      //
      const onupdateSubscription = API.graphql({
        query: subscriptions.onUpdateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let mars = value.data.onUpdateMars;
          // console.log("onupdatemars: ", mars);

          if (mars.r === "n") {
            return;
          }
          if (
            mars.type === "chart" &&
            mars.doctor === doc &&
            mars.ignoreStatus !== "yes"
          ) {
            // getData(doc);
            if (mars.dstatus === "accept") {
              // console.log("this is a new insertion!!");
              insertLocalPatient(mars);
            } else {
              updateLocalPatient(mars);
            }
          }
        },
        error: (error) => console.warn(error),
      });

      // subscription for onDeletePatient
      //
      const onDeleteSubscription = API.graphql({
        query: subscriptions.onDeleteMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          getData(doc);
        },
        error: (error) => console.warn(error),
      });

      // fetch data
      getData(doc);

      // cealn-up unsubscribe
      //
      return () => {
        onupdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      };
    };
    asyncWrapper();
  }, []);

  const handleOnTime = () => {
    triggerNotif();
  };

  /* 접수완료 버튼 핸들 */
  const handleAccept = async (pid, pname, rtime, when) => {
    // slack("[의사NEW] " + pname + " 접수완료 버튼 누름!");
    // check if rtime is < currenttime,
    let now = new Date();
    let diff = rtime - now;
    // alert(diff);
    let bufferMin = 30;
    if (diff > 60000 * bufferMin) {
      // 30min

      let min = diff / (60 * 1000);
      let hour = Math.floor(min / 60);
      let remain = Math.ceil(min % 60);

      slack(
        "[의사] " +
          pname +
          " 희망진료시간 " +
          bufferMin +
          "분전에 누를수 있습니다!\n희망진료시간 까지 남은시간: " +
          hour +
          "시간 " +
          remain +
          "분"
      );
      alert(
        "희망진료시간 " +
          bufferMin +
          "분전에 누를수 있습니다!\n희망진료시간 까지 남은시간: " +
          hour +
          "시간 " +
          remain +
          "분"
      );

      return;
    }

    const p = await getChartByID(pid);
    if (p && p.dstatus === "phone") {
      return;
    }

    const patientDetails = {
      id: pid,
      dstatus: "phone",
      status: "phone",
      ignoreStatus: "no",
      when: when,
    };
    const updatedChart = await updateChart(patientDetails);
    if (updatedChart) {
      setPName(pname);
      setAcceptAlert(true);
    }
  };

  // changed to function cus of declaration order
  // calc num of patients by type
  function calcPatients(type) {
    let counter = 0;
    for (let p of acceptPatients) {
      // console.log(p);
      if (p.dstatus === type) counter++;
    }
    return counter;
  }

  // Nav Toggle setToggle(!toggle)
  const handleNavButton = () => {
    if (toggle === false) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const checkSearch = (patient) => {
    return true;

    if (
      // 'includes.()'
      JSON.stringify(patient.name).match(search) ||
      JSON.stringify(patient.phone).match(search) ||
      JSON.stringify(patient.ssn).match(search)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // console.log("current: " + currentPatients);
    // console.log("prev : " + prevPatients);
  }, []);

  return (
    <>
      {/* <IndexNavbar /> */}
      {/* <div style={{ background: "gold", width: "100vw", height: "100px" }}>
        <MarsNavbar />
      </div> */}
      {/* MAYBE: New Desktop Loader/Splash */}
      {isLoading && <MarsLoaderBasic isLoading={isLoading} />}

      {/* Done Loading */}
      {!isLoading && (
        <div>
          <Alert
            color=""
            style={{
              backgroundColor: "#444444",
              marginTop: "-16px",
              position: "absolute",
            }}
            isOpen={acceptAlert}
          >
            <Container>
              <div className="alert-icon">
                <i className="now-ui-icons ui-2_like"></i>
              </div>
              <b>{pName}님</b> 접수확인 완료. <b>전화 진료중</b> 목록에서 확인
              해주세요.
              <button
                type="button"
                className="close"
                onClick={() => setAcceptAlert(false)}
              >
                <span aria-hidden="true">
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </span>
              </button>
            </Container>
          </Alert>

          {/* MAIN CONTAINER -> Maps Patient Cards, Displays header, Displays Tabs */}
          {/* Excludes Mars LOGO */}
          <div className="doctor-main-container">
            {/* NAVIGATION/HEADER CONTAINER */}
            <Nav
              role="tablist"
              tabs
              className={
                toggle
                  ? "doctor-navigation-container"
                  : "doctor-navigation-container-closed"
              }
            >
              <img src={logo} alt="mars-logo" id="doctor-logo" />
              <NavItem id="doctor-navitem">
                <NavLink
                  className={iconPills === "1" ? "doctor-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("1");
                    setSearch("");
                  }}
                >
                  {/* 접수중 [{calcPatients("accept")}] */}
                  <img src={form} />
                  접수중
                  {/* <mark style={{background:'#724FB6'}} id="pharma-notification-mark">
                    {calcPatients("accept")}
                  </mark> */}
                  <span>{`[${calcPatients("accept")}]`}</span>
                  {/* <span>{Number(prevPatients)}</span> */}
                </NavLink>
              </NavItem>

              <NavItem id="doctor-navitem">
                <NavLink
                  className={iconPills === "2" ? "doctor-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("2");
                    setSearch("");
                  }}
                >
                  {/* 전화 진료중 [{calcPatients("phone")}] */}
                  <img src={stetho} />
                  진료중
                  {/* <mark
                    style={{ background: "#724FB6" }}
                    id="pharma-notification-mark"
                  >
                    {calcPatients("phone")}
                  </mark> */}
                  <span>{`[${calcPatients("phone")}]`}</span>
                </NavLink>
              </NavItem>

              <NavItem id="doctor-navitem">
                <NavLink
                  className={iconPills === "3" ? "doctor-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("3");
                    setSearch("");
                  }}
                >
                  {/* 완료 [{calcPatients("uploaded") + calcPatients("done")}] */}
                  <img src={done} />
                  완료
                </NavLink>
              </NavItem>

              <NavItem id="doctor-navitem">
                <NavLink
                  className={iconPills === "4" ? "doctor-navlink-active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPills("4");
                    setSearch("");
                  }}
                >
                  {/* 취소 [{calcPatients("cancel")}] */}
                  <img src={cancel} />
                  취소
                </NavLink>
              </NavItem>
              <div className="doctor-information-nav">
                <img
                  alt="..."
                  className="rounded-circle"
                  src={require("assets/img/somebody.jpg")}
                ></img>
                {getDoctorInfo(doctor).office}
              </div>
            </Nav>
            {/* TOGGLE TOGGLE - LATER IF NEEDED*/}
            {/* <button
              id="pharma-nav-bar-toggle"
              onClick={handleNavButton}
              style={{ marginLeft: toggle ? "175px" : 0 }}
            >
              <i className={toggle ? "arrow in" : "arrow out"}></i>
            </button> */}

            {/* CONTENT CONTAINER BASED ON ACTIVE TAB/LINK */}

            <TabContent
              className="doctor-page-content-container"
              activeTab={"iconPills" + iconPills}
            >
              <div className="doctor-page-header-container">
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    marginBottom: "50px",
                    marginLeft: "45px",
                  }}
                >
                  <div
                    className="pharma-tab-search"
                    style={{
                      display: "inline",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "black",
                    }}
                  >
                    안녕하세요 {getDoctorInfo(doctor).name} 원장님! &nbsp;
                    &nbsp;
                    <img
                      src={searchIcon}
                      style={{
                        width: "15px",
                        position: "absolute",
                        marginTop: "8px",
                        marginLeft: "8px",
                      }}
                      // alt="search-icon-pharma"
                    />
                    <input
                      type="text"
                      value={search}
                      // placeholder="검색"
                      style={{
                        width: "426px",
                        height: "36px",
                        fontWeight: 300,
                        fontSize: "13px",
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="doctor-page-table-container">
                <table className="doctor-page-table">
                  <th id="category-name">이름 (나이)</th>
                  <th id="category-time">희망진료시간</th>
                  {/* <th id="category-type">환자타입</th> */}
                  <th id="category-contact">연락처</th>
                  <th id="category-ssn">주민번호</th>
                </table>
              </div>
              <TabPane tabId="iconPills1" className="doctor-page-tabpane">
                {acceptPatients.map((p) => {
                  if (p.dstatus === "accept") {
                    if (checkSearch(p)) {
                      return (
                        <>
                          <PatientCardNew
                            key={p.id}
                            patient={p}
                            onAccept={handleAccept}
                            onTime={handleOnTime}
                          />
                        </>
                      );
                    }
                  }
                })}
              </TabPane>
              <TabPane tabId="iconPills2" className="doctor-page-tabpane">
                <div md="12" xs="12">
                  {acceptPatients.map((p) => {
                    if (p.dstatus === "phone") {
                      if (checkSearch(p)) {
                        return (
                          <PatientCardNew
                            key={p.id}
                            patient={p}
                            onAccept={handleAccept}
                          />
                        );
                      }
                    }
                  })}
                </div>
              </TabPane>
              <TabPane tabId="iconPills3" className="doctor-page-tabpane">
                <div>
                  {patients.map((p) => {
                    if (p.dstatus === "uploaded" || p.dstatus === "done") {
                      if (checkSearch(p)) {
                        return (
                          <PatientCardNew
                            key={p.id}
                            patient={p}
                            onAccept={handleAccept}
                          />
                        );
                      }
                    }
                  })}
                </div>
              </TabPane>

              {/* 취소 */}
              <TabPane tabId="iconPills4" className="doctor-page-tabpane">
                <div>
                  {patients.map((p) => {
                    if (p.dstatus === "cancel") {
                      if (checkSearch(p)) {
                        return (
                          <PatientCardNew
                            key={p.id}
                            patient={p}
                            onAccept={handleAccept}
                          />
                        );
                      }
                    }
                  })}
                </div>
              </TabPane>
            </TabContent>
            {/* <div>({getDoctorInfo(doctor).office})</div> */}
            {/* TOGGLE TOGGLE */}
            {/* <button
              id="pharma-nav-bar-toggle"
              onClick={handleNavButton}
              style={{ marginLeft: toggle ? "215px" : 0 }}
            >
              <i className={toggle ? "arrow in" : "arrow out"}></i>
            </button> */}
            <Row
              className="justify-content-center"
              style={{ paddingBottom: "100px" }}
            >
              <Col md="3">{/* <AmplifySignOut /> */}</Col>
            </Row>
          </div>
        </div>
      )}

      {/* <DarkFooter /> */}
    </>
  );
}
export default withAuthenticator(DoctorPage);
