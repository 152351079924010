import React from "react";
// reactstrap components
import { Col, Row } from "reactstrap";
import { sendAnalytics } from "utils/analytics";
import { slack } from "utils/util";

function First(props) {
  return (
    <>
      <div className="landing4 landing4-background-white">
        <Row className="justify-content-center">
          <Col md="6" xs="12">
            <div className="left heading2">
              탈모 진료가
              <br />
              <b style={{ color: "#FA5724" }}>처음</b>이신가요?
            </div>

            <div className="line1-white" />

            <div className="heading2-sub">
              MARS 에서 나에게 적합한 <b>탈모 솔루션</b>을<br />
              찾아보세요!
            </div>

          </Col>
        </Row>


        <Row className="justify-content-center">
          <Col md="4" xs="12">
            <div className="right">
              <img alt="..." src={require("assets/img/landing4-first.png")} style={{ width: "250px", paddingBottom:"25px" }}></img>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center text-center">
          <Col md="6" xs="12">

            <div
              className="kakao-btn"
              onClick={() => {
                if (props.isIndex) {
                  slack("index start button clicked");
                  alert("서비스 준비 중  입니다.")
                  return;
                }
                slack("[landing4/탈모솔루션] 탈모솔루션 찾아보기 클릭");
                sendAnalytics({ "type": "log", "from": "landing5", "event": "landing.btn_click.solutions", "msg": "탈모솔루션 찾아보기 버튼 클릭" });
                window.Kakao.Channel.addChannel({
                  channelPublicId: "_fMNCb",
                });
              }}
            >
              <img alt="..." src={require("assets/img/kakao_logo.png")} style={{ width: "20px", marginRight: "8px" }}></img>
              나의 탈모솔루션 찾아보기
            </div>
          </Col>
        </Row>
        <br />

      </div>
    </>
  );
}
export default First;
