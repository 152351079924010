import React, { useState, useRef, useEffect } from "react";
import { Collapse } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";

// images
import iconOne from "../dna-event-assets/Q1Icon1.png";
import iconTwo from "../dna-event-assets/Q1Icon2.png";
import iconThree from "../dna-event-assets/Q1Icon3.png";
import iconFour from "../dna-event-assets/Q1Icon4.png";
import iconFive from "../dna-event-assets/Q1Icon5.png";
import DownArrow from "../dna-event-assets/DownArrow.png";
import UpArrow from "../dna-event-assets/UpArrow.png";

// css
import "../dnaEventStyles.css";

export default function FirstQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [buttonArrow, setButtonArrow] = useState(DownArrow);
  const [titleStyle, setTitleStyle] = useState();
  const titleRef = useRef();

  const itemToView = document.getElementById("collapse-content-first");

  const toggle = () => {
    setCollapse(!collapse);
    setTitleStyle(titleRef.current.style.fontWeight);
    if (buttonArrow === DownArrow) {
      setButtonArrow(UpArrow);
    } else {
      setButtonArrow(DownArrow);
    }
  };

  // useEffect(() => {
  //   if (titleStyle === "400") {
  //     titleRef.current.style.fontWeight = "600";
  //   } else {
  //     titleRef.current.style.fontWeight = "400";
  //   }

  //   if (collapse) {
  //     setTimeout(() => {
  //       itemToView.scrollIntoView({ behavior: "smooth", block: "center" });
  //     }, 260);
  //   }
  // }, [collapse]);

  const headerStyles = {
    marginLeft: "-15px",
    marginTop: "3.5px",
    paddingRight: "24px",
    fontSize: "25px",
  };

  const iconStyles = {
    width: "20px",
    height: "20px",
    alignSelf: "center",
    marginRight: "5px",
  };

  const textStyles = {
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "15px",
    marginTop: "13px",
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        type: "spring",
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const itemSlide = {
    hidden: { x: -500 },
    show: { x: 0 },
  };

  return (
    <div className="dna-question-wrapper">
      {/* <div id="dna-toggle-button"> */}
      <p ref={titleRef} id="dna-main-title" style={{ fontWeight: 600 }}>
        탈모 유전자 검사란?
      </p>
      {/* <img id="dna-button-image" src={buttonArrow} /> */}
      {/* </div> */}
      <span style={{ fontSize: "15px", marginBottom: "30px" }}>
        MARS x EXOGENE <span style={{ fontWeight: 600 }}>“DNA 탈모검사"</span>는
        과학적인 유전자 검사를 통해 탈모의 가능성을 파악합니다.{" "}
        <span style={{ fontWeight: 600 }}>1) 남성형 탈모</span>와{" "}
        <span style={{ fontWeight: 600 }}>2)원형 탈모</span>를 일으키는 유전자
        유무여부를 확인하실 수 있습니다.
      </span>
      {/* <Collapse isOpen={collapse}> */}
      <div
        id="collapse-content-first"
        style={{
          paddingTop: "15px",
        }}
      >
        <span id="dna-main-title" style={{ fontWeight: 600 }}>
          유전자검사 절차는 어떻게 되나요?
        </span>
        <motion.div
          className="dna-list-container"
          variants={container}
          initial="hidden"
          whileInView="show"
        >
          <motion.ul variants={item} id="dna-question-list">
            <h3 style={headerStyles}>1</h3>
            <img style={iconStyles} src={iconOne} />
            <p style={textStyles}>MARS에서 접수</p>
          </motion.ul>
          <motion.ul variants={item} id="dna-question-list">
            <h3 style={headerStyles}>2</h3>
            <img style={iconStyles} src={iconTwo} />
            <p style={textStyles}>유전자 검사 KIT 택배로 수령 (1-2일)</p>
          </motion.ul>{" "}
          <motion.ul variants={item} id="dna-question-list">
            <h3 style={headerStyles}>3</h3>
            <img style={iconStyles} src={iconThree} />
            <p style={textStyles}>시료 채취 및 반송 (1-2일)</p>
          </motion.ul>{" "}
          <motion.ul variants={item} id="dna-question-list">
            <h3 style={headerStyles}>4</h3>
            <img style={iconStyles} src={iconFour} />
            <p style={textStyles}>유전자 검사 및 분석</p>
          </motion.ul>{" "}
          <motion.ul variants={item} id="dna-question-list">
            <h3 style={headerStyles}>5</h3>
            <img style={iconStyles} src={iconFive} />
            <p style={textStyles}>MARS에서 검사결과 확인</p>
          </motion.ul>
        </motion.div>
      </div>
      {/* </Collapse> */}
    </div>
  );
}
