import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// reactstrap components
import { Col, Row } from "reactstrap";
import ReviewCard from "./ReviewCard";
import reviews from "./reviews.json";


function When() {
  const items = [
    <ReviewCard review={reviews[0]} />,
    <ReviewCard review={reviews[1]} />,
    // <ReviewCard review={reviews[2]} />,
    // <ReviewCard review={reviews[3]} />,
  ];

  return (
    <>
      <div className="landing4 landing4-background-white">

        <Row className="justify-content-center ">
          <Col
            md="6"
            xs="12"
          >
            <Row className="text-center justify-content-center">
              <Col md="10">
                <div className="left heading2">
                  병원과 약국에 갈 필요없이<b style={{ color: "#FA5724" }}> 집으로 배송</b>
                </div>

                <div className="line1-black" />

                <div className="left heading2-sub">
                  MARS로 간단한 설문진료와 의사 1분통화로
                  <br />
                  탈모약을 집으로 배송 받아 보세요.
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center pt18">
              <Col md="7" xs="11">
                <AliceCarousel
                  mouseTracking
                  items={items}
                  disableButtonsControls
                  autoPlay
                  infinite
                  autoPlayInterval={2700}
                />
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}
export default When;
