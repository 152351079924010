import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useHistory } from "react-router";
import MarsNavBarMain from "components/Navbars/MarsNavBarMain";
import { getChartsByKid } from "utils/data";
import { GetPaymentHistory } from "utils/pricing";
import {
  formatDisplayDateHistory,
  getDoctorInfo,
  getPharmaInfo,
} from "utils/util";

// assets
import historyLottie from "../assets/profileHistory.json";
import arrowBack from "../assets/arrowBack.png";

// css
import "../styling/componentStyling.css";

export default function UserHistory(props) {
  const { userID, kid } = props.location.state;
  const [KID, setKID] = useState();
  const [chartHistory, setChartHistory] = useState([]);
  const history = useHistory();

  // on component mount only
  useEffect(() => {
    // retrieve user history here
    setKID(kid);
    // get all user charts by KiD
    const asyncWrapper = async () => {
      let results = await getChartsByKid(kid);
      for (let chart of results) {
        if (chart.status === "deli_done") {
          setChartHistory((current) =>
            // append to array && sort by most recent
            [...current, chart].sort(
              (a, b) => new Date(b.yeyak_time) - new Date(a.yeyak_time)
            )
          );
        }
      }
    };

    asyncWrapper();
  }, []);

  return (
    <div className="user-container">
      <MarsNavBarMain></MarsNavBarMain>
      <button
        className="profile-return-button"
        onClick={() => {
          history.push("/myPageNew");
        }}
      >
        <img src={arrowBack} />
      </button>
      <div className="user-info-display">
        <div className="user-info-title">
          <span>진료내역</span>
          <Lottie
            animationData={historyLottie}
            autoPlay={true}
            id="profile-lottie"
          ></Lottie>
        </div>
      </div>
      <div className="user-history-container">
        {chartHistory && chartHistory.length > 0 ? (
          <div>
            {chartHistory.map((item, key) => {
              return (
                <div className="user-history-card" key={key}>
                  <div className="history-date">
                    {formatDisplayDateHistory(item.yeyak_time)}
                  </div>
                  <div className="history-status">
                    {item.status === "deli_done" ? "배송완료" : "--"}
                  </div>
                  <div className="history-pharma-doc">
                    <span>
                      처방 병원: {getDoctorInfo(item.doctor).office} |{" "}
                    </span>
                    <span>제조 약국: {getPharmaInfo(item.pharma).office}</span>
                  </div>
                  <div className="history-order-info">
                    <p>상세내역</p>
                    <GetPaymentHistory chart={item}></GetPaymentHistory>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="user-history-placeholder">과거 진료내역이 없어요</div>
        )}
      </div>
      <div className="user-data-button">
        <button
          onClick={() => {
            history.push("/myPageNew");
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
}
