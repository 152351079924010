// import amplify authenticator ui
import { withAuthenticator } from "@aws-amplify/ui-react";
import { API, Auth, graphqlOperation } from "aws-amplify";
// core components
import MarsNavbar from "components/Navbars/MarsNavbar.js";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Alert, Col, Container, Nav, NavItem,
  NavLink, Row, TabContent,
  TabPane
} from "reactstrap";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import PhotoChartCard from "./OperationCardNew";
import '@aws-amplify/ui-react/styles.css';
import { getAllCharts } from "utils/data";
import { getUser } from "utils/data";

function Accept() {
  const [iconPills, setIconPills] = useState("1");
  const [patients, setPatients] = useState([]);
  const [shampCustomers, setShampCustomers] = useState([]);
  const [exogeneCustomers, setExogeneCustomers] = useState([]);
  const [pName, setPName] = useState("");
  const [acceptAlert, setAcceptAlert] = useState(false);
  const [when, setWhen] = useState("1");
  const history = useHistory();

  async function getData() {
    let filter = {
      status: {
        eq: "pending",
      },
    };
    let ps = await getAllCharts("DESC", filter, 100);
    for (let p of ps) {
      let user = await getUser(`user#${p.kid}`);
      if (user && user.name) {
        p.name = user.name;
        p.phone = user.phone;
        p.ssn = user.ssn;
      }

    }
    setPatients(ps);
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      // fetch data
      let user = await Auth.currentUserInfo();
      // console.log("user:", user);
      if (user.username !== "mars1") {
        alert("오퍼레이터로 로그인 해주세요!");
        let so = await Auth.signOut();
        history.push("/logout");
        return;
      }

      // subscription for onCreateMars
      //
      const subscription = API.graphql({
        query: subscriptions.onCreateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let mars = value.data.onCreateMars;
          if (mars.type === "chart") {
            getData(); 
          }
        },
        error: (error) => console.warn(error),
      });
      // subscription for onupdatepatient
      //
      const onupdateSubscription = API.graphql({
        query: subscriptions.onUpdateMars,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          // console.log("onupdate mars!!: ", provider, value);
          let mars = value.data.onUpdateMars;
          if (mars) {
            getData();
          }
        },
        error: (error) => console.warn(error),
      });

      // subscription for onDeletePatient
      //
      const onDeleteSubscription = API.graphql(
        graphqlOperation(subscriptions.onDeleteMars)
      ).subscribe({
        next: ({ provider, value }) => {

          // history.go(0);
          getData();
        },
        error: (error) => console.warn(error),
      });

      getData();

      return () => {
        subscription.unsubscribe();
        onupdateSubscription.unsubscribe();
        onDeleteSubscription.unsubscribe();
      }
    }
    asyncWrapper();

    // clean-up unsubscribe
    //

  }, []);

  const handleUpdate = (type, body) => {
    if (type === "when") {
      setWhen(body);
    }
  };


  

  const calcPatients = (type, status) => {
    let counter = 0;
    for (let p of patients) {
      // console.log(p);
      if (p[type] === status) counter++;
    }
    return counter;
  };

  return (
    <>
      {/* <IndexNavbar /> */}
      <MarsNavbar />
      <div style={{ paddingTop: "80px" }} className="scrollable">
        <Alert
          color=""
          style={{ backgroundColor: "#444444", marginTop: "-16px" }}
          isOpen={acceptAlert}
        >
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons ui-2_like"></i>
            </div>
            <b>{pName}님</b> 접수확인 완료. <b>전화 진료중</b> 목록에서 확인
            해주세요.
            <button
              type="button"
              className="close"
              onClick={() => setAcceptAlert(false)}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </Container>
        </Alert>

        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Nav className="justify-content-center" role="tablist" tabs>
                {/* pending patient tab */}
                <NavItem>
                  <NavLink
                    className={iconPills === "1" ? "active" : ""}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setIconPills("1");
                    }}
                  >
                    접수대기 [{calcPatients("status", "pending")}]
                  </NavLink>
                </NavItem>

                

              </Nav>


              <TabContent className="" activeTab={"iconPills" + iconPills}>


                {/* 1. 접수대기 */}
                <TabPane tabId="iconPills1">
                  <Row
                    className="justify-content-center"
                    style={{ paddingTop: 24 }}
                  >
                    <Col md="11" xs="12">
                      {patients.map((p) => {
                        if (p.status === "pending") {
                          return (
                            <PhotoChartCard
                              key={p.id}
                              patient={p}
                              // onVerifyPayment={handleVerifyPayment}
                              // onUpdate={handleUpdate}
                            />
                          );
                        }
                      })}
                    </Col>
                  </Row>
                </TabPane>

               

              </TabContent>
            </Col>
          </Row>

          <Row
            className="justify-content-center"
            style={{ paddingBottom: "100px" }}
          >
            <Col md="3">
              {/* <AmplifySignOut /> */}
            </Col>
          </Row>
        </Container>
      </div>
      {/* <DarkFooter /> */}
    </>
  );
}
export default withAuthenticator(Accept);
