export function blobReader(blob, onChunk, chunkSize) {
    if (chunkSize === void 0) { chunkSize = 1024 * 1024; }
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.addEventListener("error", reject);
        fileReader.addEventListener("abort", reject);
        var size = blob.size;
        var totalBytesRead = 0;
        function read() {
            if (totalBytesRead >= size) {
                resolve();
                return;
            }
            fileReader.readAsArrayBuffer(blob.slice(totalBytesRead, Math.min(size, totalBytesRead + chunkSize)));
        }
        fileReader.addEventListener("load", function (event) {
            var result = event.target.result;
            onChunk(new Uint8Array(result));
            totalBytesRead += result.byteLength;
            // read the next block
            read();
        });
        // kick off the read
        read();
    });
}
