import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Collapse } from "reactstrap";
import { reduceEachTrailingCommentRange } from "typescript";
import { sendAnalytics } from "utils/analytics";
import { getUser } from "utils/data";
import { updateUser } from "utils/data";

import { PRODUCTS } from "utils/products";
import getAvailTimes from "utils/scheduler";
import { getENV } from "utils/util";
import { getDoctorInfo, getPharmaInfo } from "utils/util";
import { parsePhoneNumber } from "utils/util";
import { maskSSN, zeroPad, formatOperationPayTime } from "utils/util";
import { getTimeObject } from "utils/util";
import { calcPrice4 } from "utils/util";
import { formatDisplayDateTime } from "utils/util";
import { formatDisplayDate } from "utils/util";
import { parseSurvey, slack2ops, won } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import * as mutations from "../../graphql/mutations";
import FVChartV15 from "views/doctor/FVChartV15";
import SVChartV15 from "views/doctor/SVChartV15";
import RepeatChartV15 from "views/doctor/RepeatChartV15";
import ScheduleChanger from "./components/ScheduleChanger";
import HospitalChanger from "./components/HospitalChanger";
import ProfileEditor from "./components/ProfileEditor";
import AddressEditor from "./components/AddressEditor";
import PDFPreviewNew from "views/doctor/PDFPreviewNew";

// util
import { getMedTableMobile } from "./util/Tables";

// css
import "./styling/operationCardStyles.css";

export default function ShipmentCard(props) {
  const { shipment } = props;
  const [isDetail, setIsDetail] = useState(false);
  const [yeyakTime, setYeyakTime] = useState("");
  const [chart, setChart] = useState();

  useEffect(() => {
    const asyncWrapper = async () => {
      setChart(shipment.chart);
    };
    // console.log(shipment);
    asyncWrapper();

    console.log("chart : ", chart);
  }, []);

  const formatSSN = (ssn) => {
    if (ssn.legnth < 13) {
      return ssn;
    }
    return `${ssn.substring(0, 6)}-${ssn.substring(6, ssn.length)}`;
  };

  const formatOpTime = (time) => {
    let yoil = ["일", "월", "화", "수", "목", "금", "토"];
    var dateObj = new Date(time);
    var date = dateObj.getDate();
    var year = dateObj.getFullYear() % 100;
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDay();
    return `'${year}. ${zeroPad(month, 2)}. ${zeroPad(date, 2)} (${yoil[day]})`;
  };

  return (
    <>
      {chart && shipment ? (
        <Card style={{ boxShadow: "none", margin: "0px 0px 10px 0px" }}>
          <div
            className="operation-card-face-venus"
            onClick={() => {
              setIsDetail(!isDetail);
            }}
          >
            <span id="op-name">
              {/* {props.patient.user_info.name || "loading.."} (
        {parsePhoneNumber(props.patient.user_info.phone)}) */}
              {shipment.name ? shipment.name : <>정보를 불러올 수 없습니다.</>}
              <>
                {parsePhoneNumber(shipment.phone)
                  ? `(${parsePhoneNumber(shipment.phone)})`
                  : shipment.phone}
              </>
            </span>
            <span id="op-time">
              {/* {formatOpTime(yeyakTime)}{" "}
        <span style={{ color: "rgba(255, 32, 179, 1)", fontWeight: 600 }}>
          {formatResTime(yeyakTime)}
        </span> */}
              {formatOpTime(yeyakTime) ? (
                formatOpTime(yeyakTime)
              ) : (
                <>NO YEYAK TIME :(</>
              )}
            </span>
            <span id="op-ssn">
              {formatSSN(chart.user_info.ssn) && chart ? (
                formatSSN(chart.user_info.ssn)
              ) : (
                <>NO SSN :(</>
              )}
            </span>
            <span id="op-type">
              {chart.survey_type ? chart.survey_type : <></>}
            </span>

            <div id="op-hospital">
              {chart.doctor_id ? chart.doctor_id : <></>}
            </div>
            <div id="op-pharma">
              {/* {shipment.processor_status === "medi_prep" ? (
                <div id="op-pharma">
                  {getPharmaInfo(shipment.processor_id).office ? (
                    getPharmaInfo(shipment.processor_id).office
                  ) : (
                    <>NO PHARMA INFO :(</>
                  )}
                </div>
              ) : (
                <></>
              )} */}
              세계로 약국
            </div>
            {/* 환자취소 */}
            {/* <div className="op-actions">
        <div
          className="cancel-return-buttons"
          style={{
            position:
              shipment.processor_status !== "pending" ? "absolute" : "",
            right: shipment.processor_status !== "pending" ? "60px" : "",
          }}
        >
          {shipment.processor_status !== "cancel" &&
            shipment.processor_status !== "pay" &&
            shipment.processor_status !== "deli_done" && (
              <div>
                <a
                  id="op-cancel-button"
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (
                      window.confirm(
                        props.patient.name + "님 접수취소 하시겠습니까??"
                      )
                    ) {
                      try {
                        let chartInfo = {
                          id: props.patient.id,
                          status: "cancel",
                          dstatus: "cancel",
                          ignoreStatus: "no",
                        };
                        const res = await updateVenusChart(chartInfo);
                        if (res) {
                          let user = await getUser(
                            `user#${props.patient.kid}`
                          );

                          if (user && user.charts) {
                            let userCharts = JSON.parse(user.charts);
                            // console.log("userCharts:", userCharts);
                            let chart = userCharts.find(
                              (chart) => chart.id === props.patient.id
                            );
                            chart.stat = "cancel";
                            let userInfo = {
                              id: user.id,
                              charts: JSON.stringify(userCharts),
                            };
                            const uuser = await updateUser(userInfo);
                            // console.log("updated user: ", uuser);
                          }
                          slack2ops(
                            `[예약취소][운영][${user.name}][${user.kid}]`
                          );
                          alert("예약이 취소 되었습니다.");
                        }
                      } catch (error) {
                        alert(error);
                        //console.log(error);
                      }
                    }
                  }}
                >
                  <img
                    src={cancel}
                    style={{ width: "17px", height: "auto" }}
                  />
                </a>
              </div>
            )}

          {props.patient.doctor_status === "pending" ||
          props.patient.doctor_status === "accept" ||
          props.patient.doctor_status === "phone" ||
          props.patient.doctor_status === "re_accept" ? (
            <div>
              <a
                id="op-rewind-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenScheduleModal(true);
                }}
              >
                <img src={rewind} style={{ width: "17px", height: "auto" }} />
              </a>
              <ScheduleChanger
                yeyakTime={yeyakTime}
                setYeyakTime={setYeyakTime}
                openScheduleModal={openScheduleModal}
                setOpenScheduleModal={setOpenScheduleModal}
                patient={props.patient}
              ></ScheduleChanger>
            </div>
          ) : (
            <></>
          )}

          {props.patient.dstatus === "phone" ? (
            <div
              id="op-text-button"
              style={{ color: "transparent", cursor: "default" }}
            >
              병원변경
            </div>
          ) : (
            <></>
          )}

          {props.patient.dstatus === "accept" ? (
            <div>
              <a
                id="op-text-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenHospitalModal(true);
                }}
              >
                병원변경
              </a>
              <HospitalChanger
                openHospitalModal={openHospitalModal}
                setOpenHospitalModal={setOpenHospitalModal}
                doctor={doctor}
                setDoctor={setDoctor}
                patient={props.patient}
              ></HospitalChanger>
            </div>
          ) : (
            <></>
          )}

          {props.patient.dstatus === "cancel" && (
            <div>
              <Button
                id="op-revert-button"
                onClick={async (e) => {
                  e.stopPropagation();
                  const patientDetails = {
                    id: props.patient.id,
                    status: "accept",
                    dstatus: "accept",
                  };
                  if (
                    window.confirm(
                      props.patient.name + "님 다시 접수 하시겠습니까?"
                    )
                  ) {
                    try {
                      const updatedPatient = await API.graphql({
                        query: mutations.updateMars,
                        authMode: "AMAZON_COGNITO_USER_POOLS",
                        variables: { input: patientDetails },
                      });
                      if (updatedPatient) {
                        slack2ops(
                          `[운영][${props.patient.name}] 접수취소 완료`
                        );
                        alert(props.patient.name + "님 접수취소 완료!");
                      }
                    } catch (error) {
                      alert(error);
                      //console.log(error);
                    }
                  }
                }}
              >
                되돌리기
              </Button>
            </div>
          )}
        </div>
        <div className="operation-dropdown">
          {props.patient.user_status === "pending" ? (
            <div>
              <select
                id="operation-select"
                value={doctor}
                onChange={(e) => {
                  setDoctor(e.target.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <option
                  value="eroon"
                >
                  이룬의원
                </option>
                <option
                  value="doctor77"
                >
                  마스의원(TEST)
                </option>
              </select>

              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  if (
                    window.confirm("정말로 병원선택을 완료 하시겠습니까2??")
                  ) {
                    const chartInfo = {
                      id: props.patient.id,
                      // ignoreStatus: "no",
                      user_status: "accept",
                      doctor_status: "accept",
                      doctor_id: doctor,
                    };

                    const updatedChart = await updateVenusChart(chartInfo);
                    if (updatedChart) {
                      slack2ops(
                        `[운영][${
                          props.patient.user_info.name
                        }] 병원 선택완료[${getDoctorInfo(doctor).office}]`
                      );
                      sendAnalytics({
                        type: "ops",
                        kid: props.patient.kid,
                        from: "operation",
                        event: `select.doctor.${doctor}`,
                        msg: `병원 ${doctor} 선택 완료`,
                      });
                      // alert(
                      //   `${props.patient.name}님 ${
                      //     getDoctorInfo(doctor).office
                      //   } 선택완료!`
                      // );
                    }
                  }
                }}
                // className="btn-sm btn-primary"
                id="operation-hosp-button-venus"
              >
                확인
              </Button>
            </div>
          ) : (
            <></>
          )}

          {props.patient.pstatus === "deli_prep" ||
          props.patient.pstatus === "deli_done" ? (
            <div className="operation-delivery-buttons">
              <input
                value={deliveryNumber}
                type="text"
                onChange={(e) => {
                  setDeliveryNumber(e.target.value);
                }}
              ></input>
              <Button
                id="operation-deli-button"
                onClick={() => {
                  if (deliveryNumber === "") {
                    alert("등기번호 미입력!");
                    return;
                  }
                  if (
                    window.confirm(
                      props.patient.name +
                        "님 등기번호 입력 완료: " +
                        deliveryNumber
                    )
                  ) {
                    handleDeliveryReady(props.patient.id, deliveryNumber);
                  }
                }}
              >
                확인
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div> */}
          </div>

          {/* {isDetail && ( */}
          <Collapse isOpen={isDetail}>
            <div className="operation-card-detail-body-venus">
              <div className="operation-patient-info-venus">
                <div className="operation-divider-venus">
                  <b>ID 정보</b>
                  <Button
                    id="operation-patient-delete-button-venus"
                    // onClick={async () => {
                    //   const patientDetails = {
                    //     id: props.patient.id,
                    //   };

                    //   if (
                    //     window.confirm(
                    //       "!!!! 테스트 데이타만 지우세요 !!!!\n" +
                    //         props.patient.name +
                    //         "님 을 DB에서 삭제 하시겠습니까?"
                    //     )
                    //   ) {
                    //     try {
                    //       const deletedChart = await API.graphql({
                    //         query: mutations.deleteMars,
                    //         authMode: "AMAZON_COGNITO_USER_POOLS",
                    //         variables: { input: patientDetails },
                    //       });
                    //       if (deletedChart) {
                    //         // also delete charthistory from user.
                    //         //
                    //         let userCharts = [];
                    //         let user = await getUser(`user#${props.patient.kid}`);
                    //         if (user && user.charts) {
                    //           userCharts = JSON.parse(user.charts);
                    //           // console.log("userCharts: ", userCharts);

                    //           // remove the matching patient id from the history charts.
                    //           // console.log("props.patient.id: ", props.patient.id);
                    //           for (var i = 0; i < userCharts.length; i++) {
                    //             // console.log(userCharts[i].id);
                    //             if (userCharts[i].id == props.patient.id) {
                    //               userCharts.splice(i, 1);
                    //               break;
                    //             }
                    //           }

                    //           let userInfo = {
                    //             id: user.id,
                    //             charts: JSON.stringify(userCharts),
                    //           };
                    //           let updatedUser = await updateUser(userInfo);
                    //           // console.log("updatedUser: ", updatedUser);
                    //         }

                    //         sendAnalytics({
                    //           type: "ops",
                    //           kid: props.patient.kid,
                    //           from: "operation",
                    //           event: `delete.chart`,
                    //           msg: `${props.patient.name} 차트DB 삭제 완료`,
                    //         });
                    //         slack2ops(
                    //           `${props.patient.name}님 차트가 DB에서 삭제 되었습니다.`
                    //         );

                    //         if (props.patient.nesh_id) {
                    //           const deletedNesh = await API.graphql({
                    //             query: mutations.deleteMars,
                    //             authMode: "AMAZON_COGNITO_USER_POOLS",
                    //             variables: {
                    //               input: { id: props.patient.nesh_id },
                    //             },
                    //           });
                    //           if (deletedNesh) {
                    //             slack2ops(
                    //               `${props.patient.name}님 NESH도 DB에서 삭제 되었습니다.`
                    //             );
                    //           }
                    //         }

                    //         alert(props.patient.name + "님 삭제완료!");
                    //       }
                    //     } catch (error) {
                    //       console.log(error);
                    //     }
                    //   }
                    // }}
                  >
                    <span>차트 삭제</span>
                  </Button>

                  <table>
                    <tbody>
                      <tr>
                        <td id="op-data-venus">KID: {shipment.kid} </td>
                      </tr>
                      <tr>
                        <td id="op-data-venus">ChartID: {shipment.chart_id}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    id="operation-edit-patient-venus"
                    // onClick={() => {
                    //   setOpenProfileEdit(true);
                    // }}
                  >
                    기본정보 수정
                  </button>
                  {/* PROFILE EDITOR */}
                  {/* {openProfileEdit ? (
              <ProfileEditor
                isOpen={openProfileEdit}
                openProfileEdit={openProfileEdit}
                setOpenProfileEdit={setOpenProfileEdit}
                profileEditor={profileEditor}
              ></ProfileEditor>
            ) : (
              <></>
            )} */}
                  <div></div>
                </div>
                <div>
                  <div className="operation-address-venus">
                    <b>배송주소</b>
                    <div
                      id="op-data-venus"
                      style={{
                        marginTop: "15px",
                        width: "280px",
                      }}
                    >
                      {shipment.delivery_address ? (
                        <>
                          {shipment.delivery_address}{" "}
                          {shipment.road_address ? (
                            shipment.road_address
                          ) : (
                            <>NO ROAD ADDRESS :(</>
                          )}
                          {shipment.zip ? shipment.zip : <>NO ZIP :(</>}
                        </>
                      ) : (
                        <>주소 미등록</>
                      )}
                    </div>
                    <button
                      id="operation-edit-patient-venus"
                      // onClick={() => {
                      //   setOpenAddressEdit(true);
                      // }}
                    >
                      주소정보 수정
                    </button>
                    <div className="operation-divider-venus"></div>
                  </div>
                  {/* ADDRESS EDITOR */}
                  {/* {openAddressEdit ? (
              <AddressEditor
                isOpen={openAddressEdit}
                openAddressEdit={openAddressEdit}
                setOpenAddressEdit={setOpenAddressEdit}
                profileEditor={profileEditor}
              ></AddressEditor>
            ) : (
              <></>
            )} */}
                </div>
                <div>
                  {/* {payResult ? (
              <div className="operation-address-venus">
                <b>결제정보</b>
                <div id="op-data-venus" style={{ marginTop: "15px" }}>
                  {payResult && payResult.PTIME ? (
                    <>결제시간: {formatOperationPayTime(payResult.PTIME)}</>
                  ) : (
                    <>결제시간 없음</>
                  )}
                  {payResult ? (
                    <div>
                      <b>
                        {payResult.AMNT
                          ? parseInt(payResult.AMNT).toLocaleString() +
                            "원 결제완료"
                          : "결제금액 확인 필요"}
                      </b>{" "}
                      <br /> {payResult.CNAME} : {payResult.CNUM || "---"}
                    </div>
                  ) : (
                    <div>미결제</div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )} */}
                </div>
              </div>
              {/* <div className="operation-pricing-table-venus">
          <b>
            {shipment.processor_status !==
            ("pay" || "paid" || "deli_done" || "done")
              ? `VENUS 예상처방`
              : `VENUS 처방`}
          </b>
          {props.patient.recommended_medicines &&
          props.patient.user_status !==
            ("pay" || "paid" || "deli_done" || "done") ? (
            <table className="venus-med-table">
              <tbody>
                {props.patient.recommended_medicines.items.map(
                  (item, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ minWidth: "150px" }}>
                          {item.medicine.product_name}
                        </td>
                        <td>{`${item.medicine.base * item.dosage}[${
                          item.medicine.unit
                        }]`}</td>
                        <td>{`${item.medicine.retail_price}`}원</td>
                      </tr>
                    );
                  }
                )}
                <tr>
                  <td>진료비</td>
                  <td></td>
                  <td>{"15,000원"}</td>
                </tr>
                <tr>
                  <td>배송비</td>
                  <td></td>
                  <td>{"2,900원"}</td>
                </tr>
                <tr>
                  <td style={{ marginLeft: "auto" }}>총: {`total here`}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <></>
          )}

          {props.patient.prescription &&
          props.patient.prescription.prescribed_medicine &&
          props.patient.user_status === ("pay" || "paid") ? (
            <table className="venus-med-table">
              <tbody>
                {props.patient.prescription.prescribed_medicine.items.map(
                  (item, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ minWidth: "150px" }}>
                          {item.medicine.product_name}
                        </td>
                        <td>{`${item.medicine.base * item.dosage}[${
                          item.medicine.unit
                        }]`}</td>
                        <td>{`${item.medicine.retail_price}`}원</td>
                      </tr>
                    );
                  }
                )}
                <tr>
                  <td>진료비</td>
                  <td></td>
                  <td>{"15,000원"}</td>
                </tr>
                <tr>
                  <td>배송비</td>
                  <td></td>
                  <td>{"2,900원"}</td>
                </tr>
                <tr>
                  <td style={{ marginLeft: "auto" }}>총: {`total here`}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div> */}
              {/* <div className="operation-message-content-venus">
          <div className="operation-extra-message-venus">
            <b>추가메시지</b>
            <br />
            <section className="venus-msg">
              {props.patient.user_msg ? (
                <>{props.patient.user_msg}</>
              ) : (
                <>없음</>
              )}
            </section>
          </div>
        </div> */}
              <div>
                {shipment.prescription.prescribed_medicine ? (
                  getMedTableMobile(
                    shipment.prescription.prescribed_medicine.items
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="chuuga-message" style={{ width: "100%" }}>
                <b>추가메시지</b>
                <br />
                <section className="venus-msg">
                  {chart.user_msg && chart.user_msg !== "" ? (
                    chart.user_msg
                  ) : (
                    <>없음</>
                  )}
                </section>
                <div className="operation-divider-venus" />
                <div style={{ marginTop: "15px" }}>
                  <b>카카오톡 메시지 기능 HERE</b>
                </div>
              </div>
            </div>
          </Collapse>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
