import React, { useState, useEffect } from "react";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";

// assets
import Header from "./dna-components/DNALandingHeader";
import DNAStrand from "./dna-event-assets/animations/DNAStrand.json";
import MarsExogene from "./dna-event-assets/MarsExogene.png";
import DNALandingInfo from "./dna-event-assets/DNALandingInfo.png";
import KakaoMessage from "./dna-event-assets/KakaoMessage.png";
import DNAClick from "./dna-event-assets/animations/DNAClick.json";
import DNALab from "./dna-event-assets/animations/DNALab.json";
import MarsProfile from "./dna-event-assets/MarsProfile.png";
import KakaoChat from "./dna-event-assets/KakaoChat.png";

// img
import kakao from "./dna-event-assets/kakao.png";

import "./dnaEventStyles.css";
import { slack2events } from "utils/util";
import { useHistory } from "react-router";

export default function DnaEventLanding() {
  const history = useHistory();

  useEffect(() => {
    const asyncWrapper = async () => {
      let ip = localStorage.getItem("mars-ip");
      if (!ip) {
        // console.log("there's no ip!"); todo: why called 3 times?
        const ipData = await fetch("https://geolocation-db.com/json/");
        const locationIp = await ipData.json();
        ip = locationIp.IPv4;
        localStorage.setItem("mars-ip", ip);
      }

      slack2events(`${new Date().toISOString()}, DNA1, ${ip}, 랜딩_방문`);
    };
    asyncWrapper();
  }, []);
  // lottie animation properties
  const animationOptionsOne = {
    loop: true,
    autoplay: true,
    animationData: DNAStrand,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationOptionsTwo = {
    loop: true,
    autoplay: true,
    animationData: DNAClick,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationOptionsThree = {
    loop: true,
    autoplay: true,
    animationData: DNALab,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // inline styling
  const titleStyle = {
    fontWeight: "600",
    color: "#333D96",
    fontSize: "30px",
    position: "relative",
    lineHeight: "40px",
    position: "relative",
    zIndex: "2",
  };

  const titleStyleDark = {
    fontWeight: "600",
    color: "white",
    fontSize: "30px",
    position: "relative",
    lineHeight: "40px",
    position: "relative",
    zIndex: "2",
  };

  const clickStyle = {
    transform: "rotate(-25deg)",
    height: "auto",
    width: "180px",
    position: "relative",
    left: "150px",
    pointerEvents: "none",
  };

  const labStyle = {
    width: "350px",
    height: "auto",
    pointerEvents: "none",
    placeSelf: "center",
    marginTop: "25px",
  };

  const buttonStyle = {
    width: "100%",
    height: "51px",
    border: "none",
    outline: "none",
    background: "#FEE500",
    color: "black",
    fontSize: "18px",
    borderRadius: "8px",
    marginBottom: "15px",
  };

  return (
    <div className="wrapper-dna">
      <Header />
      <div className="dna-landing-container">
        <div id="dna-landing-section-one">
          <h1 style={titleStyle}>
            탈모 유전자검사 <br /> 홈키트 선착순 무료!
          </h1>
          <p id="dna-text-list-body" style={{ marginTop: "-22px" }}>
            검사키트, 배송비, 검사비용 모두 무료! <br /> 2023. 02. 15. 단 하루
            이벤트{" "}
          </p>
          <Lottie
            animationData={DNAStrand}
            style={{
              transform: "rotate(-25deg)",
              position: "absolute",
              pointerEvents: "none",
              top: "15vh",
              right: -45,
              maxWidth: "1000px",
            }}
            autoPlay={true}
          ></Lottie>
          <div
            style={{
              marginTop: "280px",
            }}
          >
            <img
              style={{
                width: "175px",
                height: "auto",
              }}
              src={MarsExogene}
            ></img>
            <p id="dna-text-list-body">
              남성탈모 전문 비대면 진료 서비스{" "}
              <span style={{ fontWeight: 600 }}>맨프롬마스</span>에서
              <br /> 무료 유전자 검사 이벤트에 참여해 보세요!
            </p>
          </div>
        </div>
        <div id="dna-landing-section-three">
          <h1 style={titleStyleDark}>탈모 유전자검사란?</h1>
          <p
            id="dna-text-list-body"
            style={{
              marginTop: "-22px",
              marginRight: "22px",
              fontWeight: 300,
              fontSize: "15px",
              color: "white",
            }}
          >
            집으로 보내드리는 검사키트로 시료를 채취하여 연구소로 보내주시면,
            &nbsp;
            <span style={{ fontWeight: 600 }}>남성형탈모</span>와{" "}
            <span style={{ fontWeight: 600 }}>원형탈모</span>를 일으키는 유전자
            유무여부를 알려 드려요!
          </p>
          <Lottie animationData={DNALab} style={labStyle}></Lottie>
          <p
            style={{
              fontWeight: "400",
              fontSize: "15px",
              textAlign: "center",
              marginTop: "85px",
              color: "white",
            }}
          >
            {/* 지금 바로 확인해 보세요! */}
          </p>
        </div>
        <div id="dna-landing-section-two">
          <h1 style={titleStyle}>
            탈모 유전자검사 <br />
            무료로 신청하는 법
          </h1>
          <p id="dna-text-list-body" style={{ marginTop: "-22px" }}>
            아래 버튼을 눌러 카카오톡{" "}
            <span style={{ fontWeight: "bold" }}>채널 추가 후</span> 전송된
            메세지의 <span style={{ fontWeight: 600 }}>'참여하기'</span>{" "}
            눌러주세요!
          </p>
          <div id="dna-image-container">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={MarsProfile}
                style={{
                  width: "96px",
                  height: "auto",
                  float: "left",
                  pointerEvents: "none",
                }}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={KakaoChat}
                style={{
                  width: "195px",
                  height: "auto",
                  marginTop: "5px",
                  position: "absolute",
                  pointerEvents: "none",
                }}
              />
            </motion.div>
            <motion.div
              initial={{ y: 350 }}
              whileInView={{ y: 220 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <Lottie animationData={DNAClick} style={clickStyle}></Lottie>
            </motion.div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "22px",
          paddingRight: "22px",
          position: "fixed",
          bottom: 5,
          width: "100vw",
          zIndex: 999,
          maxWidth: "800px",
        }}
      >
        <button
          style={buttonStyle}
          onClick={() => {
            let ip = localStorage.getItem("mars-ip");

            window.Kakao.Channel.addChannel({
              channelPublicId: "_fMNCb",
            });

            slack2events(
              `${new Date().toISOString()}, DNA1, ${ip}, 랜딩_채널추가버튼_클릭`
            );
          }}
        >
          <img
            src={kakao}
            style={{ width: "21px", height: "auto", marginRight: "11px" }}
          />
          카카오로 시작하기
        </button>
      </div>
    </div>
  );
}
