import React, { useState, useEffect } from "react";
// components & func
import { getTimeObject } from "utils/util";
import { API, Storage } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import { parseSurvey, slack2ops, won } from "utils/util";
import { getENV } from "utils/util";
import { Modal, ModalBody } from "reactstrap";

// css
import "./styling/scheduleStyles.css";
import { formatDisplayDateTime } from "utils/util";
import TimeSlotModal from "views/components/TimeSlotModal";
import OperationTimeModal from "./OperationTimeModal";
import { getScheduleFromServer } from "utils/util";

export default function ScheduleChanger(props) {
  const {
    yeyakTime,
    setYeyakTime,
    openScheduleModal,
    setOpenScheduleModal,
    patient,
  } = props;
  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [availTimes, setAvailTimes] = useState([]);

  const toggle = () => setOpenScheduleModal(!openScheduleModal);

  const handleClose = () => {
    setIsTimeSlotOpen(false);
  };

  const handleUpdate = (time) => {
    setYeyakTime(time);
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      let schedules = await getScheduleFromServer();
      setAvailTimes(schedules);
    };
    fetchSchedule();
  }, []);

  return (
    <Modal
      isOpen={openScheduleModal}
      toggle={toggle}
      className="schedule-changer-modal"
    >
      <ModalBody className="schedule-modal">
        <button
          id="schedule-selector"
          onClick={(e) => {
            e.stopPropagation();
            setIsTimeSlotOpen(true);
          }}
        >
          {formatDisplayDateTime(yeyakTime)}
        </button>
        <button
          id="schedule-change-confirm"
          onClick={async (e) => {
            e.stopPropagation();

            if (
              window.confirm(
                "정말로 " + patient.name + "님의 희망진료시간을 바꾸시겠습니까?"
              )
            ) {
              const patientDetails = {
                id: patient.id,
                yeyak_time: yeyakTime,
                status: "pending",
                dstatus: "pending",
                ignoreStatus: "refresh",
              };

              try {
                let updatedPatient = await API.graphql({
                  query: mutations.updateMars,
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                  variables: { input: patientDetails },
                });
                if (updatedPatient) {
                  let users = [
                    {
                      type: "appUserId",
                      id: patient.kid,
                    },
                  ];
                  let eventName =
                    "event_reservation_time_changed" +
                    (getENV() !== "prod" ? "_dev" : "");
                  console.log("event Name: ", eventName);
                  let eventData = {
                    msg: formatDisplayDateTime(yeyakTime),
                  };
                  let res = await API.post("marsrest", "/kakao", {
                    body: {
                      users,
                      eventName,
                      eventData,
                    },
                  });
                  alert(patient.name + "님 진료희망시간 변경 완료!\n");
                  slack2ops(
                    `[운영][${
                      patient.name
                    }] 희망진료시간 변경 완료: ${formatDisplayDateTime(
                      yeyakTime
                    )}`
                  );
                }
              } catch (error) {
                alert("에러발생 다시 시도해 주세요: " + JSON.stringify(error));
              }
            }
            setOpenScheduleModal(false);
          }}
        >
          확인
        </button>
      </ModalBody>
      <OperationTimeModal
        id="operation-timeslot"
        isOpen={isTimeSlotOpen}
        items={availTimes}
        onClose={handleClose}
        onUpdate={handleUpdate}
        setIsTimeSlotOpen={setIsTimeSlotOpen}
      />
    </Modal>
  );
}
