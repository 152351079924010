import { Analytics, API } from "aws-amplify";
import { insertMarsAnalytics } from "./data";
import { getENV } from "./util";
import * as mutations from "../graphql/mutations";
import * as queries from '../graphql/queries';
// Analytics.addPluggable(new AWSKinesisFirehoseProvider());

export const sendAnalytics = async (data) => {

  let ip = localStorage.getItem("mars-ip");
  if (!ip) {
    // console.log("there's no ip!"); todo: why called 3 times?
    const ipData = await fetch('https://geolocation-db.com/json/');
    const locationIp = await ipData.json();
    ip = locationIp.IPv4;
    localStorage.setItem("mars-ip", ip);
  }

  data.ip = ip;
  data.env = getENV();
  data.url = `${window.location.host}${window.location.pathname}`;
  data.timestamp = new Date().toISOString();
  return Analytics.record({
    data: data,
    streamName: 'PUT-OPS-sdFer',
    immediate: true
  }, 'AWSKinesisFirehose');
}

export const pushMarsAnalytics = async (kid, action, data) => {
  let session_id = localStorage.getItem("mars_session_id");
  const analyticsInfo = {
    kid: kid,
    action: action,
    timestamp: new Date().toISOString(),
    from: window.location.pathname,
    id: session_id ? session_id : "",
    st: (data && data.st) ? data.st : "",
    et: (data && data.et) ? data.et : "",
    duration: (data && data.et && data.st) ? (parseInt(data.et) - parseInt(data.st)) : "",
    exit: (data && data.exit) ? data.exit : "",
    addon: "",
    question: (data && data.question) ? data.question : "",
    answer: (data && data.answer) ? data.answer : "",
    patient_type: (data && data.patient_type) ? data.patient_type : "",
    msg: (data && data.msg) ? data.msg : "",
  }

  let res = await insertMarsAnalytics(analyticsInfo);
  if (res) {
    return res.data.createMarsAnalytics.timestamp;
  }
}

export const updateMarsAnalytics = async (analyticsInfo) => {
  try {
    const newAnalytics = await API.graphql({
      query: mutations.updateMarsAnalytics,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: analyticsInfo },
    });
    if (newAnalytics) {
      return newAnalytics;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllAnalytics = async (sortDir, filter, limit) => {
  let st = new Date().getTime();
  let et;
  let charts = [];
  let nextToken = null;
  if (!limit) {
    limit = 1000;
  }

  try {
    do {
      const res = await API.graphql({
        query: queries.listMarsAnalytics,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          // sortDirection: sortDir,
          limit: limit,
          nextToken: nextToken,
          filter: filter,
        },
      });
      
      nextToken = res.data.listMarsAnalytics.nextToken;
      // console.log("nextToekn:", nextToken);
      if (res.data) {
        charts.push(...res.data.listMarsAnalytics.items);
      }
    } while (nextToken && limit === 1000);

    et = new Date().getTime();
    let dt = et - st;
    console.log("td:", dt);

    return charts;
  } catch (error) {
    console.log(error);
  }
};