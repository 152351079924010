import React from "react";
import EventHeader from "components/Headers/EventHeader";
import Lottie from "lottie-react";

// assets
import DNAOver from "./dna-event-assets/animations/DNAOver.json";
import DNAFail from "./dna-event-assets/animations/DNAFail.json";
import DNARain from "./dna-event-assets/animations/DNARain.json";
import logo from "../../../assets/img/mars_logo_black.png";

// css
import "./dnaEventStyles.css";

export default function DnaEventOver() {
  return (
    <div className="dna-over-container">
      <EventHeader />
      <div className="dna-over-content">
        <span
          style={{
            fontSize: "18px",
            fontWeight: 600,
            marginBottom: "20px",
            marginTop: "100px",
          }}
        >
          아쉽게도 선착순 마감되었습니다
        </span>
        <Lottie
          animationData={DNARain}
          autoPlay={true}
          style={{ width: "150px", height: "auto" }}
        ></Lottie>
        <span style={{ textAlign: "center", fontSize: "13px", width: "250px" }}>
          곧 2차 이벤트 오픈 예정이니 <br/> 조금만 기다려 주세요!{" "}
        </span>
        <img
          src={logo}
          style={{ width: "48px", height: "auto", marginTop: "19px" }}
        />
      </div>
      <div className="dna-over-button">
        <button
          onClick={() => {
            window.location.href = "kakaotalk://inappbrowser/close";
          }}
        >
          닫기
        </button>
      </div>
    </div>
  );
}
