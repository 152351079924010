import { useEffect, useState } from "react";
import PhotoChartCard from "./OperationCardNew";

// img
import searchIcon from "../pharma2/Vector.png";

// css
import "./styling/viewerAcceptStyles.css";

export default function ChartViewerAcceptNew(props) {
  //search
  const [search, setSearch] = useState("");
  const [doctorBatch, setDoctorBatch] = useState();
  const [totalBatch, setTotalBatch] = useState(0);

  const getLastAppointedDoctor = (chart) => {
    if (!chart) {
      return;
    }
    return "";
  };

  useEffect(() => {
    let doctorBatch = {};
    let today = new Date();
    setTotalBatch(0);
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    let todayString = `${today.getFullYear()}-${zeroPad(
      today.getMonth() + 1,
      2
    )}-${zeroPad(today.getDate(), 2)}`;
    // console.log(todayString);
    for (let chart of props.charts) {
      if (chart.yeyak_time.startsWith(todayString)) {
        doctorBatch[chart.doctor] =
          (doctorBatch[chart.doctor] ? doctorBatch[chart.doctor] : 0) + 1;
      }
    }
    setDoctorBatch(doctorBatch);
    for (const [key, value] of Object.entries(doctorBatch)) {
      if (key !== "") {
        setTotalBatch((totalBatch) => totalBatch + parseInt(value));
      }
    }
  }, [props.charts]);

  // client side search
  const checkSearch = (patient) => {
    if (
      JSON.stringify(patient.name).match(search) ||
      JSON.stringify(patient.phone).match(search) ||
      JSON.stringify(patient.ssn).match(search) ||
      JSON.stringify(patient.survey_type).match(search)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="viewer-accept-container-venus">
      <div className="viewer-accept-header-venus">
        <div className="operation-search-venus">
          {props.status === "pending" ? (
            <span style={{ width: "165px" }}>고객-병원 배정대기</span>
          ) : (
            <></>
          )}
          <div>
            <img
              src={searchIcon}
              style={{
                width: "15px",
                position: "absolute",
                marginTop: "8px",
                marginLeft: "8px",
              }}
              alt="search-icon-pharma"
            />
            <input
              type="text"
              value={search}
              placeholder="검색"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="viewer-accept-doctor-batch-venus">
          {/* {JSON.stringify(doctorBatch)} */}
          <span style={{ fontWeight: 600 }}>오늘의 진료배치현황</span> &nbsp;
          {doctorBatch && (
            <>
              {`서울정의원: [${
                doctorBatch["doctor1"] || 0
              }건]  |  보령이가정의원: [${
                doctorBatch["doctor2"] || 0
              }건] |  가온삼성비뇨기과: [${
                doctorBatch["gaonsamsung"] || 0
              }건] | 총 [${totalBatch}건]`}
            </>
            // <div>
            //   <mark style={{ background: "skyblue", borderRadius: "5px" }}>
            //     서울정의원
            //   </mark>
            //   {doctorBatch["doctor1"]}
            // </div>
          )}
        </div>
      </div>
      <div className="operation-table-header-container-venus">
        <table className="operation-table-header-venus">
          <thead>
            <tr>
              <th id="op-name">이름 (연락처)</th>
              <th id="op-time">희망진료시간</th>
              <th id="op-ssn">주민번호</th>
              <th id="op-type">환자타입</th>
              {props.status !== "pending" ? (
                <th id="op-hospital">병원</th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
        </table>
      </div>
      <div className="viewer-accept-content-container-venus">
        {props.charts.map((c) => {
          if (c.user_status === "pending") {
            // if (checkSearch(c)) {
            return (
              <>
                <PhotoChartCard
                  key={c.id}
                  patient={c}
                  lastAppointedDoctor={getLastAppointedDoctor(c)}
                />
              </>
            );
          }
          // }
        })}
        <div
          style={{
            paddingLeft: "15px",
            height: "auto",
            lineHeight: "18px",
            fontWeight: 200,
            letterSpacing: "3px",
            whiteSpace: "pre-wrap",
            fontFamily: "aria",
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
            marginTop: "50px",
          }}
        >
          <div>{"  __( o)>"}</div>
          <div>{" <_.  )"}</div>
          <div style={{ lineHeight: 0 }}>{"---------------"}</div>
        </div>
      </div>
    </div>
  );
}
