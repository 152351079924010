import MarsNavbarWithLine from "components/Navbars/MarsNavbarWithLine";
import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { slack } from "utils/util";
import MedicationCard from "./medication-card";
import medications from "./medications";

function MedicationPage() {
  useEffect(() => {
    slack("[Medication] 복약정보 페이지 방문!");
  }, []);
  return (
    <div style={{ backgroundColor: "#F7F7F7" }} className="scrollable">
      <MarsNavbarWithLine />
      <Container style={{ marginTop: "64px", overflowX: "hidden" }}>
        <Row>
          <Col xs={8}>
            <div className="heading3" style={{ paddingTop: "15px" }}>
              <b
                style={{
                  fontFamily: "NanumBarunGothic",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "25px",
                  lineHeight: "29px",
                }}
              >
                복약정보
              </b>
            </div>
            <br />
            <div
              style={{
                width: "100vw",
                overflowX: "hidden",
                fontFamily: "NanumBarunGothic",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: "18px",
                marginTop: "-15px",
                marginBottom: "15px",
              }}
            >
              해당 약에 따른 복약지도 및 부작용을 참고 해 주세요.
            </div>
          </Col>

          {/* image removed per design changes */}
          {/* <Col xs={4}>
						<div><img alt="..." src={require("assets/img/medication.png")} style={{ width: "86px" }}></img></div>
					</Col> */}
        </Row>
        <br />

        <Row
          className="pr-3 pl-1"
          style={{ width: "100vw", paddingLeft: "0px", gap: "0px" }}
        >
          {medications.map((m) => {
            return (
              <Col className="pb--7" xs={6}>
                <MedicationCard medication={m} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
export default MedicationPage;
