import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { PRODUCTS } from "utils/products";
import { calcPrice4 } from "utils/util";
import { getDoctorIdentityID } from "utils/util";
import { formatDisplayDateTime } from "utils/util";
import { formatDisplayDate } from "utils/util";
import { getDoctorInfo, slack, won } from "utils/util";
import StatusPrescriptionModal from "views/components/StatusPrescriptionModal";
import ShampooCheckoutNew from "views/survey/components/ShampooCheckoutNew";

// is shampoo
import MarsSelectBoxShampoo from "../survey/components/MarsSelectBoxShampoo";
import ShampooInformationCheckout from "../survey/components/ShampooInformationCheckout";

// status status
import StatusStatusBar from "./status-components/StatusStatusBar";

// css
import "./styling/statusStyles.css";

function StatusStep2(props) {
  const [btnStatusList, setBtnStatusList] = useState([]);
  const question = "처방 완료";
  const [signedURL, setSignedURL] = useState("");
  const [minox, setMinox] = useState("minox");

  const [pill, setPill] = useState("propecia");
  const [capil, setCapil] = useState(null);
  const [shamp, setShamp] = useState(null);

  const [month, setMonth] = useState(0);
  const [pillPrice, setPillPrice] = useState(0);
  const [capilPrice, setCapilPrice] = useState(0);
  const [minoxPrice, setMinoxPrice] = useState(0);
  const [shampPrice, setShampPrice] = useState(0);
  const [pillMonth, setPillMonth] = useState();
  const [minoxMonth, setMinoxMonth] = useState();
  const [capilMonth, setCapilMonth] = useState(1);
  const [pillCount, setPillCount] = useState(30);
  const [minoxCount, setMinoxCount] = useState(1);
  const [doctorInfo, setDoctorInfo] = useState(null);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShampSelected, setIsShampSelected] = useState(false);
  const date = new Date();
  const [displayTime, setDisplayTime] = useState();

  const statusProps = {
    step: props.step,
    user: props.user,
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let p = props.patient;
      let pmonth, mmonth;

      setMinox(p.minox);
      setPill(p.pill);
      setCapil(p.capil);
      setShamp(p.shamp);
      props.setShamp(p.shamp); // do not remove!
      setMonth(p.month);
      setPillMonth(p.month);
      setCapilMonth(p.capilMonth);
      setMinoxMonth(p.month);

      if (p.shamp !== null) {
        setIsShampSelected(true);
      }

      if (p.pillMonth) {
        pmonth = p.pillMonth;
        setPillMonth(p.pillMonth);
      }
      if (p.minoxMonth) {
        mmonth = p.minoxMonth;
        setMinoxMonth(p.minoxMonth);
      }

      let res = calcPrice4(
        p.pill,
        p.minox,
        pmonth,
        mmonth,
        p.shamp,
        p.capil,
        p.capilMonth
      );
      setPillPrice(res.pillPrice);
      setCapilPrice(res.capilPrice);
      setMinoxPrice(res.minoxPrice);
      setShampPrice(res.shampPrice);
      setTotal(res.total);

      //get the signedulr using filekey
      if (p.filekey) {
        let id = getDoctorIdentityID(p.doctor);
        const key = await Storage.get(p.filekey, {
          level: "protected",
          identityId: id,
        });
        setSignedURL(key);
      }

      if (props.patient.doctor && props.patient.doctor !== "") {
        let doctor_info = getDoctorInfo(props.patient.doctor);
        setDoctorInfo(doctor_info);
      }

      slack("[status/step2(처방완료)] 방문: " + props.user.name);
    };
    asyncWrapper();

    setDisplayTime(formatDisplayDate(props.patient.yeyak_time));
  }, []);

  const updateShamp = (shamp) => {
    setShamp(shamp);
    props.setShamp(shamp);
    let res = calcPrice4(
      pill,
      minox,
      pillMonth,
      minoxMonth,
      shamp,
      capil,
      capilMonth
    );
    setPillPrice(res.pillPrice);
    setCapilPrice(res.capilPrice);
    setMinoxPrice(res.minoxPrice);
    setShampPrice(res.shampPrice);
    setTotal(res.total);
  };

  return (
    <>
      <StatusPrescriptionModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        url={signedURL}
      />
      <div
        style={{ background: "#F7F7F7" }}
        className="status-section-container"
      >
        {/* questions */}
        {/* <div className="question-section">
          <div
            className="question-title"
            style={{ paddingBottom: "12px", paddingTop: "68px" }}
          >
            처방 완료
          </div>
          <div className="question-explain">
            통화진료 후 처방이 확정 되었습니다. 확인 후 결제 해 주세요
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "18px",
              marginBottom: "-6px",
            }}
          >
            <img
              alt="..."
              src={require("assets/img/status2.png")}
              width="100%"
              style={{ maxWidth: "400px" }}
            ></img>
          </div>
        </div> */}
        <StatusStatusBar props={statusProps} />
        <div id="status-fake-curve"></div>

        {/* 접수정보 세션 */}
        <div
          style={{
            marginBottom: "90px",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "20px",
            background: "white",
          }}
        >
          {/* 처방 내용 */}
          <div
            style={{
              paddingBottom: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table width={"100%"}>
              <tr>
                <td>
                  <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                    {/* <b>{month}개월 처방 내용</b> */}
                    <b>나의 처방전</b>
                  </div>
                </td>
                <td className="text-right">
                  <div
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    style={{ fontSize: "13px" }}
                    className="status-prescription-button"
                  >
                    {/* <a href={signedURL} download="처방전"> */}
                    <u className="purple400">확인하기</u> {'>'}
                    {/* </a> */}
                  </div>
                </td>
              </tr>
            </table>
            {displayTime}
            {/* Medication Info */}
            {/* <table width={"100%"}>
              <tbody>
                {pill && pill !== "아니요" && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base}{" "}
                      {PRODUCTS[pill].unit})
                    </td>
                  </tr>
                )}

                {minox && minox !== "아니요" && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[minox].name} (
                      {minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit}
                      )
                    </td>
                  </tr>
                )}

                {capil && (
                  <tr>
                    <td></td>
                    <td>
                      {PRODUCTS[capil].name} (
                      {capilMonth * PRODUCTS[capil].base} {PRODUCTS[capil].unit}
                      )
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
          </div>
          <div className="line-orange-status" />
          {/* 진료 및 약 비용 */}
          <div
            className="status-payment-summary"
            style={{ paddingBottom: "15px" }}
          >
            <table width={"100%"}>
              <tr>
                <td>
                  <div style={{ fontSize: "18px", marginBottom: "8px" }}>
                    <b>결제금액</b>
                  </div>
                </td>
                <td className="text-right">
                  <div
                    style={{
                      color: "#6A43B8",
                      fontSize: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    <b>총 {won(total)}원</b>
                  </div>
                </td>
              </tr>
            </table>

            <table width={"100%"}>
              <tbody className="status-payment-summary-body">
                {pill && pill !== "아니요" && (
                  <tr className='status-title'>
                    <td>
                      {PRODUCTS[pill].name} ({pillMonth * PRODUCTS[pill].base}{" "}
                      {PRODUCTS[pill].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(pillPrice)}원
                    </td>
                  </tr>
                )}

                {minox && minox !== "아니요" && (
                  <tr className='status-title'>
                    <td>
                      {PRODUCTS[minox].name} (
                      {minoxMonth * PRODUCTS[minox].base} {PRODUCTS[minox].unit}
                      ){/* {minox} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(minoxPrice)}원
                    </td>
                  </tr>
                )}

                {shamp && (
                  <tr>
                    <td>
                      {PRODUCTS[shamp].name} ({PRODUCTS[shamp].base}{" "}
                      {PRODUCTS[shamp].unit}){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(shampPrice)}원
                    </td>
                  </tr>
                )}

                {capil && (
                  <tr>
                    <td>
                      {PRODUCTS[capil].name} (
                      {capilMonth * PRODUCTS[capil].base} {PRODUCTS[capil].unit}
                      ){/* {pill} */}
                    </td>
                    <td style={{ paddingLeft: 8, textAlign: "right" }}>
                      {won(capilPrice)}원
                    </td>
                  </tr>
                )}

                <tr className='status-title'>
                  <td>진료비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    10,000원
                  </td>
                </tr>
                <tr className='status-title'>
                  <td>배송비</td>
                  <td style={{ paddingLeft: 8, textAlign: "right" }}>
                    2,900원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="line-orange-status" />

          {/* <div className="">
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>담당의사 정보</b>
            </div>
            {doctorInfo && (
              <>
                <div>
                  의사 {doctorInfo.name} ({doctorInfo.office})
                </div>
                <div></div>
                <div>{doctorInfo.phone}</div>
              </>
            )}
            {!doctorInfo && <div>담당의사 배정중 입니다.</div>}
          </div>
          <div className="line-orange-status" />
          
          <div>
            <div style={{ fontSize: "18px", marginBottom: "8px" }}>
              <b>담당약사 정보</b>
            </div>
            <div>약사 조윤민 (튼튼약국)</div>
          </div> */}

          {/* {!isShampSelected && (
            <div >
              <div className="status-step2-isShampoo-container" >
                <ShampooInformationCheckout  />
                <MarsSelectBoxShampoo shamp={shamp} updateShamp={updateShamp} />
              </div>
            </div>
          )} */}
          <div style={{ paddingBottom: "50px" }}>
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              관리제품 추가하기
            </span>
            <span
              style={{
                color: "#333333",
                fontWeight: "200",
                fontSize: "18px",
              }}
            >
              (탈모샴푸)
            </span>
            <ShampooCheckoutNew shamp={shamp} updateShamp={updateShamp} />
          </div>
        </div>
        <div id="status-fake-curve-bottom"></div>
      </div>
    </>
  );
}
export default StatusStep2;
